import React, { useState, useCallback } from "react";
import clsx from "clsx";
import { InputBase, Box, Typography, InputBaseProps } from "@material-ui/core";
import { ValidationEnum } from "types/constants";
import { urlValidate } from "helpers/validationHelper";

import useStyles from "./styles";

interface InputProps extends InputBaseProps {
    placeholder: string;
    onChangeWithError: (text: string, error?: boolean) => void;
    validationType?: ValidationEnum
    errorMessage?: string;
}

const validation = {
    [ValidationEnum.url]: urlValidate,
}

function Input({ placeholder, onChangeWithError, validationType, errorMessage, ...props }: InputProps) {
    const classes = useStyles();
    const [error, setError] = useState(false);

    const onChange = useCallback(({ currentTarget }) => {
        const { value } = currentTarget;
        const error = !(validationType ? validation[validationType](value) : true);
        setError(error);
        onChangeWithError(value, error);
    }, [onChangeWithError, validationType]);

    return (
        <>
            <InputBase
                fullWidth
                autoFocus
                inputProps={{ maxLength: 100 }}
                error={error}
                placeholder={placeholder}
                className={clsx(classes.input, error && classes.inputError)}
                onChange={onChange}
                {...props}
            />
            {error &&
                <Box pt={1.5}>
                    <Typography className={classes.redText}>{errorMessage}</Typography>
                </Box>
            }
        </>
    )
}

export default Input;
