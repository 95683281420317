import { Box, Button, Dialog, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { FormInput } from "components/Form";
import LoadingButton from "components/LoadingButton";
import { useFormik, FormikProvider, Form } from "formik";
import React from "react";
import { CompanyLevel, CompanyLevelLabel } from "types";

import useStyles from "./styles";

interface CreateCompanyModalProps {
    isOpen: boolean;
    onSubmit: (name: string) => Promise<void>;
    onClose: () => void;
    parentCompanyLevel?: CompanyLevel;
    companyLevelLabel: CompanyLevelLabel;
}

function CreateCompanyLevelModal({ isOpen, onSubmit, onClose, parentCompanyLevel, companyLevelLabel }: CreateCompanyModalProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();

    const formik = useFormik({
        initialValues: { name: "" },
        onSubmit(values, { setErrors, setSubmitting }) {
            onSubmit(values.name)
                .catch((error) => setErrors({ name: error.response.data }))
                .finally(() => setSubmitting(false));
        },
    });

    return (
        <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
            <FormikProvider value={formik}>
                <Form>
                    <Box px={4} py={4}>
                        <Typography variant="h1">Add New {companyLevelLabel.name}</Typography>
                    </Box>
                    <Box px={4} py={4} bgcolor={theme.colors.structure.pageBackground}>
                        {parentCompanyLevel && (
                            <Box pb={3}>
                                <Typography variant="h2">{parentCompanyLevel.name}</Typography>
                            </Box>
                        )}
                        <FormInput
                            name="name"
                            label={`Enter new ${companyLevelLabel.name} label`}
                            classes={classes}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        px={4} py={3}
                    >
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            disabled={!formik.values.name}
                            disableElevation
                            loading={formik.isSubmitting}
                            loadingLabel="Creating..."
                        >
                            Create
                        </LoadingButton>
                        <Box pl={2} color="text.secondary">
                            <Button
                                variant="outlined" color="inherit" size="large"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}

export default CreateCompanyLevelModal;