import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    body: {
        cursor: "pointer",
        position: "relative",
        zIndex: 0,
        display: "block",
        textDecoration: "none",
        transition: theme.transitions.create("background-color", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
        },
    },
    lvl: {
        [theme.breakpoints.up("md")]: {
            flexBasis: "15%",
            flexGrow: 0,
            flexShrink: 0,
        },
        "$bodyInactive &": {
            textDecoration: "line-through",
            textDecorationColor: theme.colors.text.mediumGray,
        },
    },
    lvlArrow: {
        color: theme.colors.structure.fieldsOutline,
        transform: "translateY(-50%)",
        position: "absolute",
        top: "50%",
        left: "-12px",
        "& svg": {
            display: "block",
        },
    },
    lvlLabel: {
        color: theme.colors.text.mediumGray,
    },
    missing: {
        background: theme.colors.error,
    },
    bodyInactive: {
        opacity: 0.6,
    },
    inactiveActions: {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        right: theme.spacing(2),
        color: theme.colors.text.mediumGray,
        zIndex: 9999,

        "& button": {
            [theme.breakpoints.up("md")]: {
                transition: theme.transitions.create("opacity", {
                    duration: 300,
                    delay: 100,
                    easing: "linear",
                }),
            },
        },
    },
    hide: {
        position: "absolute",
        opacity: 0,
        [theme.breakpoints.up("md")]: {
            opacity: 1,
            "$inactiveActions:hover &": {
                opacity: 0,
            },
        },
    },
    actions: {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        color: theme.colors.text.mediumGray,
        right: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            opacity: 0,
            transition: theme.transitions.create("opacity", {
                duration: 300,
                delay: 100,
                easing: "linear",
            }),
            "$body:hover &": {
                opacity: 1,
                zIndex: 9,
            },
        },
    },
}), {
    name: "User",
});

export default useStyles;