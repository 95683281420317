import React, { useContext } from "react";
import { Paper, List } from "@material-ui/core";
import { CertificationUser } from "types/Certification";
import { UserProfile } from "types";
import { CompaniesContext } from "context";

import CustomListItem from "components/TransferList/CustomList/CustomListItem";

import useStyles from "./styles";

interface MultiselectUserListProps {
    users: CertificationUser[];
    checked: string[];
    onToggle: (userId: string) => void;
}

export default function MultiselectUserList({ users, checked, onToggle }: MultiselectUserListProps) {
    const classes = useStyles();

    const { companyLevels } = useContext(CompaniesContext);

    const handleToggle = (userId: string) => {
        onToggle(userId);
    };

    return (
        <Paper className={classes.paper}>
            <List dense component="div" role="list" className={classes.list}>
                {users?.map((user, index) => (
                    <CustomListItem
                        key={`${index}${user.id}`}
                        user={user as UserProfile}
                        checked={checked}
                        handleToggle={handleToggle}
                        companyLevels={companyLevels}
                    />
                ))}
            </List>
        </Paper>
    );
}
