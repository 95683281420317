import React, { useEffect, useCallback, ReactElement } from "react";
import { useHistory } from "react-router-dom";

import { ScrollContext } from "context";

interface ScrollRestorationProps {
    appRef: React.RefObject<HTMLDivElement>;
    children: ReactElement;
}

const ScrollRestoration = ({ appRef, children }: ScrollRestorationProps) => {
    const history = useHistory();

    const scrollToTop = useCallback((smooth: boolean) => {
        appRef.current?.scroll({ top: 0, left: 0, behavior: smooth ? "smooth" : "auto" });
    }, [appRef]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            appRef.current && appRef.current.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        });

        return () => {
            unlisten();
        }
    }, [history, appRef]);

    return (
        <ScrollContext.Provider value={{ scrollToTop, scrollContainerRef: appRef }}>
            {children}
        </ScrollContext.Provider>
    );
};

export default ScrollRestoration;