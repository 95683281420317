import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Box, Button, Dialog, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography, Tooltip, Checkbox } from "@material-ui/core";
import { Form, useFormik, FormikProvider } from "formik";
import LoadingButton from "components/LoadingButton";
import { DeleteIcon, TickIcon } from "svgComponents";
import { FormInput, FormMultilineInput } from "components/Form";
import { ComponentTypeEvaluation } from "types/constants";
import { EvaluationItemConfiguration, EvaluationItemValue, QuestionBasedForm } from "types/Certification";
import { getEvaluationItemFormData, TYPES_DATA, buildEvaluationItemValue, ERROR_MESSAGES } from "helpers/certificationHelper";

import EvaluationItemValues from "./EvaluationItemValues";
import useStyles from "./styles";

interface EvaluationItemModalProps {
    item: EvaluationItemConfiguration | null;
    evaluationConfigurationId: string;
    open: boolean;
    evaluationInUse: boolean;
    questionBasedForm: QuestionBasedForm;
    onClose: () => void;
    onCreate: (payload: EvaluationItemConfiguration) => Promise<unknown>;
    onSuccess?: () => unknown;
    onDelete: (evaluationItemId: EvaluationItemConfiguration["id"]) => Promise<unknown>;
}

const validationSchema = (questionBasedForm: QuestionBasedForm) =>
    Yup.object().shape({
        elementHeader: Yup.string().required("Required"),
        evaluationItemValues: Yup.array().when("evaluationItemTypeId", (evaluationItemTypeId: ComponentTypeEvaluation, schema) => {
            if (
                evaluationItemTypeId === ComponentTypeEvaluation.checkboxList ||
                evaluationItemTypeId === ComponentTypeEvaluation.radioButtonList ||
                evaluationItemTypeId === ComponentTypeEvaluation.dropdownList
            ) {
                return schema
                    .compact((evaluationItemValue: EvaluationItemValue) => !evaluationItemValue.name)
                    .min(2, ERROR_MESSAGES[evaluationItemTypeId])
                    .test(
                        "evaluationItemValues",
                        "Value text field is required",
                        (items: EvaluationItemValue[], context: Yup.TestContext) => {
                            const hasNoValue: EvaluationItemValue | undefined = items.find(({ value }) => value === undefined);
                            return hasNoValue ? context.createError() : true;
                        }
                    )
                    .test(
                        "evaluationItemValues",
                        ERROR_MESSAGES.evaluationItemValues,
                        (items: EvaluationItemValue[], context: Yup.TestContext) => {
                            if (questionBasedForm === QuestionBasedForm.Flagged) {
                                const hasNoBinaryValue: EvaluationItemValue | undefined = items.find(
                                    ({ value }) => ![0, 1].includes(Number(value))
                                );
                                return hasNoBinaryValue ? context.createError() : true;
                            }
                            return true;
                        }
                    );
            }
            if (evaluationItemTypeId === ComponentTypeEvaluation.textEntry) {
                return schema.of(
                    Yup.object().shape({
                        value: Yup.string().required("Required"),
                    })
                );
            }
            return schema;
        }),
    });

export default function EvaluationItemModal({
    evaluationConfigurationId,
    item,
    open,
    evaluationInUse,
    questionBasedForm,
    onClose,
    onCreate,
    onDelete,
}: EvaluationItemModalProps) {
    const classes = useStyles();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const disabled = useMemo(() => loadingSubmit || loadingDelete, [loadingDelete, loadingSubmit]);

    const initialValues = useMemo(() => getEvaluationItemFormData(item, evaluationConfigurationId), [item, evaluationConfigurationId]);

    const handleSubmit = useCallback(
        async (values: EvaluationItemConfiguration) => {
            if (disabled) return;
            setLoadingSubmit(true);

            const payload: EvaluationItemConfiguration = {
                ...values,
                evaluationItemValues: values.evaluationItemValues
                    .filter(({ name }) => name)
                    .map((itemValue, idx) => ({ ...itemValue, order: idx + 1, evaluationItemConfigurationId: values.id })),
            };

            onCreate(payload).finally(() => {
                onClose();
                setLoadingSubmit(false);
            });
        },
        [disabled, onClose, onCreate]
    );

    const formik = useFormik<EvaluationItemConfiguration>({
        enableReinitialize: true,
        initialValues,
        validationSchema: validationSchema(questionBasedForm),
        onSubmit: handleSubmit,
    });

    const { values, setValues, resetForm } = formik;

    const handleDelete = useCallback(async () => {
        if (loadingDelete) return;
        setLoadingDelete(true);
        onDelete(values.id).finally(() => {
            onClose();
            setLoadingSubmit(false);
        });
    }, [loadingDelete, onClose, onDelete, values]);

    useEffect(() => {
        if (!open) resetForm();
    }, [open, resetForm]);

    const handleItemTypeChange = useCallback(
        (e) => {
            const updatedValues = new Map();
            updatedValues.set("evaluationItemTypeId", e.target.value);

            if (e.target.value === item?.evaluationItemTypeId) {
                updatedValues.set("evaluationItemValues", item?.evaluationItemValues);
            } else if (e.target.value === ComponentTypeEvaluation.textEntry) {
                updatedValues.set("evaluationItemValues", [buildEvaluationItemValue({ order: 1 })]);
            } else {
                updatedValues.set("evaluationItemValues", [buildEvaluationItemValue({ order: 1 }), buildEvaluationItemValue({ order: 2 })]);
            }

            setValues({ ...values, ...Object.fromEntries(updatedValues.entries()) });
        },
        [item, values, setValues]
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <FormikProvider value={formik}>
                <Form>
                    <Box className={classes.window} width="100%">
                        <Box px={4} py={3}>
                            <Typography variant="h1">{item ? "Edit Evaluation Element" : "Create New Evaluation Element"}</Typography>
                        </Box>
                        <Box className={classes.form} px={3} py={3}>
                            <Typography variant="h2">
                                <Box component="span" fontWeight={500}>
                                    Element Details
                                </Box>
                            </Typography>
                            <Box className={classes.field} mt={3}>
                                <FormInput fullWidth name="elementHeader" placeholder="Title" />
                            </Box>
                            <Box className={classes.bodyText} mt={2}>
                                <FormMultilineInput name="elementBody" placeholder="Body text..." />
                            </Box>
                            <Box mt={4}>
                                <Divider />
                            </Box>
                            <Box mt={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="h2">
                                            <Box component="span" fontWeight={500}>
                                                Type
                                            </Box>
                                        </Typography>
                                        <Box mt={1}>
                                            <RadioGroup name="evaluationItemTypeId">
                                                {TYPES_DATA.map(([key, label, tooltip], idx) => (
                                                    <Tooltip key={idx} arrow placement="top" title={tooltip}>
                                                        <FormControlLabel
                                                            control={<Radio color="primary" />}
                                                            value={key}
                                                            disabled={evaluationInUse}
                                                            label={label}
                                                            onChange={evaluationInUse ? undefined : handleItemTypeChange}
                                                            checked={key === values.evaluationItemTypeId}
                                                        />
                                                    </Tooltip>
                                                ))}
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {values.evaluationItemTypeId !== ComponentTypeEvaluation.staticText && (
                                            <Typography variant="h2">
                                                <Box component="span" fontWeight={500}>
                                                    Value(s)
                                                </Box>
                                            </Typography>
                                        )}
                                        <Box mt={2}>
                                            <EvaluationItemValues evaluationInUse={evaluationInUse} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box px={3} py={3}>
                            <Grid alignItems="center" container spacing={2}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="isRequired"
                                                color="primary"
                                                onChange={formik.handleChange}
                                                checked={formik.values.isRequired}
                                            />
                                        }
                                        label="Required"
                                    />
                                </Grid>
                                <Grid className={classes.alignRight} item>
                                    <LoadingButton
                                        color="primary"
                                        disabled={disabled}
                                        disableElevation
                                        loading={loadingSubmit}
                                        loadingLabel={item ? "Saving..." : "Creating..."}
                                        size="large"
                                        startIcon={item ? <TickIcon /> : null}
                                        variant="contained"
                                        type="submit">
                                        <Box component="span" lineHeight="30px">
                                            {item ? "Save" : "Create"}
                                        </Box>
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <Button disabled={disabled} size="large" variant="outlined" onClick={onClose}>
                                        <Box component="span" lineHeight="30px">
                                            Cancel
                                        </Box>
                                    </Button>
                                </Grid>
                                {item && (
                                    <Grid item>
                                        <LoadingButton
                                            color="default"
                                            disabled={evaluationInUse || disabled}
                                            disableElevation
                                            loading={loadingDelete}
                                            loadingLabel={"Deleting..."}
                                            size="large"
                                            startIcon={null}
                                            variant="outlined"
                                            type="submit"
                                            onClick={handleDelete}>
                                            <Box alignItems="center" display="flex" component="span" height="30px">
                                                <DeleteIcon height={20} width={20} />
                                            </Box>
                                        </LoadingButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Box>
                </Form>
            </FormikProvider>
        </Dialog>
    );
}
