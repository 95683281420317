import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { CoachingSession, FcrResult } from "types";
import { FcrStateEnum, SizeEnum } from "types/constants";
import { Calendar } from "svgComponents";
import { formatShortDate } from "helpers/dateHelpers";
import { getUserFullName } from "helpers/userProfileHelper";
import StatusLabel from "components/StatusLabel";
import AvatarWithScore from "components/AvatarWithScore";
import CircleIcon from "components/CircleIcon";

import useStyles from "./style";

interface CoachingReportItemProps {
    session: CoachingSession;
    hasAccess: boolean;
    className?: string;
    showAssigneeCell?: boolean;
    onClick: (session: CoachingSession) => void;
}

const CoachingReportItem = ({ session, hasAccess, className, showAssigneeCell, onClick }: CoachingReportItemProps) => {
    const classes = useStyles();

    const changeDateColumn = useCallback((fcrResult: FcrResult) => {
        switch (fcrResult.fcrResultState) {
            case FcrStateEnum.new:
                return (
                    <div>
                        <Typography>
                            {formatShortDate(fcrResult.createdDate!)}
                        </Typography>
                        <Typography variant="caption">
                            Received
                        </Typography>
                    </div>
                );
            case FcrStateEnum.draft:
                return (
                    <div>
                        <Typography>
                            {formatShortDate(fcrResult.modifiedDate!)}
                        </Typography>
                        <Typography variant="caption">
                            Edited
                        </Typography>
                    </div>
                );
            case FcrStateEnum.completed:
            case FcrStateEnum.readByCoachee:
            case FcrStateEnum.readByCoach:
            case FcrStateEnum.acknowledged:
            case FcrStateEnum.acknowledgedWithComment:
                return (
                    <div>
                        <Typography>
                            {formatShortDate(fcrResult.completedDate!)}
                        </Typography>
                        <Typography variant="caption">
                            Completed
                        </Typography>
                    </div>
                );
        }
    }, []);

    const skillNames = useMemo(() => {
        if (!showAssigneeCell) return "";
        return session.fcrResult?.fcrCoachingSkillResults
            ?.map((result) => result.fcrCoachingSkillName)
            ?.filter((name) => !!name)
            ?.join(", ") || "No skills yet";
    }, [showAssigneeCell, session]);

    return (
        <Tooltip
            arrow
            title="You must have read permissions to view the contents of this report."
            disableHoverListener={hasAccess}
        >
            <Grid
                container
                spacing={2}
                onClick={() => hasAccess && onClick(session)}
                className={clsx(classes.item, className, { [classes.itemNotAllowed]: !hasAccess })}
            >
                <Grid item container alignItems="center" wrap="nowrap" md={6} lg>
                    <AvatarWithScore
                        user={session.coach}
                        size={SizeEnum.small}
                    />
                    <div className={classes.itemText}>
                        <Typography variant="h2">
                            {getUserFullName(session.coach)}
                        </Typography>
                        <Typography variant="caption">Coached By</Typography>
                    </div>
                </Grid>
                {showAssigneeCell && (
                    <Grid item container alignItems="center" wrap="nowrap" md={6} lg>
                        <AvatarWithScore
                            user={session.assignee}
                            size={SizeEnum.small}
                        />
                        <div className={classes.itemText}>
                            <Typography variant="h2">
                                {getUserFullName(session.assignee)}
                            </Typography>
                            <Typography variant="caption">{skillNames}</Typography>
                        </div>
                    </Grid>
                )}
                <Grid item container alignItems="center" md={showAssigneeCell ? 4 : 6} lg>
                    <CircleIcon>
                        <Calendar />
                    </CircleIcon>
                    <div className={classes.itemText}>
                        <Typography variant="h2">
                            {session.fcrResult?.createdDate ?
                                formatShortDate(new Date(session.scheduleStartDate)) : "-"}
                        </Typography>
                        <Typography variant="caption">Session Date</Typography>
                    </div>
                </Grid>
                <Grid item container alignItems="center" md={showAssigneeCell ? 4 : 6} lg>
                    <StatusLabel
                        status={session.fcrResult?.fcrResultState || FcrStateEnum.new}
                        fcrResult={session.fcrResult}
                        fontWeight={500}
                    />
                </Grid>
                <Grid item container alignItems="center" md={showAssigneeCell ? 4 : 6} lg>
                    {session.fcrResult ? changeDateColumn(session.fcrResult) : "-"}
                </Grid>
            </Grid>
        </Tooltip>
    );
}

export default React.memo(CoachingReportItem);