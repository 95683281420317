import React, { useState, useRef } from "react";
import clamp from "lodash/clamp";
import truncate from "lodash/truncate";
import { Box, Card, Typography, CircularProgress, Tooltip } from "@material-ui/core";
import { ProductSalesLabelValue } from "types";
import { ProductSalesLabelValueTypes } from "types/constants";
import Trophy from "svgComponents/Trophy";
import Graph from "svgComponents/Graph";
import useResizeObserver from "hooks/useResizeObserver";
import { PerformanceMetricsVariant } from "routes/Dashboard/DashboardContent/PerformanceMetrics";

import useStyles from "./styles";

const VALUE_LENGTH = 20;
const VALUE_FONT_SIZE_MAX = 32;
const VALUE_FONT_SIZE_MIN = 19;
const LABEL_LENGTH = 45;

interface ProductSalesLabelProps {
    value: ProductSalesLabelValue;
    variant?: PerformanceMetricsVariant;
}

const calculateValueFontSize = (fontSize: number, width: number, containerWidth: number) => {
    const resizeCoefficient = (containerWidth / width);
    return clamp(resizeCoefficient * fontSize, VALUE_FONT_SIZE_MIN, VALUE_FONT_SIZE_MAX);
};

const isPercentage = (value: string, productSalesLabelValueType: ProductSalesLabelValueTypes) => 
    !Number.isNaN(value) && !Number.isInteger(value) && productSalesLabelValueType === ProductSalesLabelValueTypes.Percentage;

export default function ProductSalesLabel({ value, variant = PerformanceMetricsVariant.Default }: ProductSalesLabelProps) {
    const { label, parsedValue, productSalesLabelValueType } = value;
    const isPercentageValue = parsedValue ? isPercentage(parsedValue, productSalesLabelValueType!) : false;
    const valueForShow = isPercentageValue ? `${Number.parseFloat(parsedValue!)}%` : parsedValue;
    const classes = useStyles();
    const valueRef = useRef<HTMLElement>(null);
    const valueContainerRef = useRef<HTMLElement>(null);
    const [valueFontSize, setValueFontSize] = useState(VALUE_FONT_SIZE_MAX);
    useResizeObserver(valueContainerRef, (width) => {
        if (width && valueRef.current) setValueFontSize(
            calculateValueFontSize(valueFontSize, valueRef.current.getBoundingClientRect().width, width)
        );
    });

    const renderTruncatedText = (text: string, length: number) => {
        const truncatedText = truncate(text, { length: length });
        const isTruncated = !(truncatedText.length === text.length);

        return isTruncated ? <Tooltip title={text} arrow><Box>{truncatedText}</Box></Tooltip> : text;
    };

    const renderContent = () => (
        <>
            {/* eslint-disable-next-line */}
            {/* @ts-ignore because of the MUI issue (ref is exists, but missed in the type declaration): https://github.com/mui/material-ui/issues/17010 */}
            <Box ref={valueContainerRef} display="flex" alignItems="center" flexDirection="column" textAlign="center" minWidth={0}>
                <Box display="flex" alignItems="center" justifyContent="center" width={56} height={56} mb={1.5} mx="auto">
                    {(parsedValue && isPercentageValue) ? (
                        <CircularProgress className={classes.progress} variant="determinate" value={Number.parseFloat(parsedValue)} thickness={10} size={44} />
                    ) : (
                        <Box className={classes.iconWrap}>
                            {(productSalesLabelValueType === ProductSalesLabelValueTypes.Trophy) ? <Trophy/> : <Graph/>}
                        </Box>
                    )}
                </Box>
                {valueForShow && (
                    <Typography className={classes.value} variant="h4" ref={valueRef}>
                        <Box fontSize={valueFontSize}>
                            {renderTruncatedText(valueForShow, VALUE_LENGTH)}
                        </Box>
                    </Typography>
                )}
                {label && (
                    <Typography className={classes.label} variant="caption">
                        {renderTruncatedText(label, LABEL_LENGTH)}
                    </Typography>
                )}
            </Box>
        </>
    );

    if (variant === PerformanceMetricsVariant.Paper) {
        return renderContent();
    } else {
        return (
            <Card elevation={1}>
                <Box px={4} py={6}>{renderContent()}</Box>
            </Card>
        );
    }
}
