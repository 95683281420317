import React, { useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import { Box, Button, Dialog, Divider, FormControlLabel, FormGroup, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useFormik, FormikProvider } from "formik";
import { DeleteIcon, TickIcon } from "svgComponents";
import { FcrCoachingSkill } from "types";
import LoadingButton from "components/LoadingButton";
import { FormInput, FormMultilineInput, FormSwitcher } from "components/Form";
import { CompaniesContext } from "context";
import { getSkillFormData } from "helpers/skillHelper";
import { deleteFcrCoachingSkill, updateFcrCoachingSkill } from "services/fcrCoachingSkillServices";

import useStyles from "./styles";
import SkillRatings from "./SkillRatings";

interface SkillFormModalProps {
    isOpen: boolean;
    isDeletionEnabled?: boolean;
    skill: FcrCoachingSkill | null;
    nextOrder: number;
    onSuccess: (skillId?: string) => Promise<void>;
    onClose: () => void;
}

function SkillFormModal({ isOpen, isDeletionEnabled = false, skill, nextOrder, onSuccess, onClose }: SkillFormModalProps) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { currentUser, currentCompany } = useContext(CompaniesContext);
    const [isDeleting, setDeleting] = useState(false);

    const getOrder = (isActive: boolean) => {
        if (!isActive) return -1;

        if (skill?.isActive && isActive) return skill.order;

        return nextOrder;
    };

    const initialValues = useMemo(
        () => getSkillFormData(
            skill,
            currentUser?.id ?? "",
            currentCompany?.id ?? ""
        ),
        [skill, currentUser, currentCompany]
    );

    const formik = useFormik<FcrCoachingSkill>({
        enableReinitialize: true,
        initialValues,
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Required"),
        }),
        onSubmit(values, { setSubmitting }) {
            updateFcrCoachingSkill({ ...values, name: values.title, order: getOrder(values.isActive) })
                .then(() => onSuccess(skill ? undefined : values.id))
                .then(() => skill ? onClose() : null)
                .finally(() => setSubmitting(false));
        },
    });

    const handleDelete = () => {
        if (skill) {
            setDeleting(true);
            deleteFcrCoachingSkill(skill.id)
                .then(onSuccess)
                .then(onClose)
                .finally(() => setDeleting(false));
        }
    };

    const { values, submitForm } = formik;

    return (
        <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth="md">
            <FormikProvider value={formik}>
                <Box p={4}>
                    <Typography variant="h1">
                        {skill ? "Edit Skill" : "Create New Skill"}
                    </Typography>
                </Box>
                <Box px={4} py={4} bgcolor={theme.colors.structure.pageBackground}>
                    <Box mb={3} fontWeight={600}>
                        <Typography variant="h2">
                            Skill Details
                        </Typography>
                    </Box>
                    <FormGroup>
                        <Box mb={2}>
                            <FormInput name="title" label="Title" classes={{ root: classes.formInput }} />
                        </Box>
                        <Box borderRadius={theme.spacing(0.75)} bgcolor={theme.palette.common.white}>
                            <FormMultilineInput placeholder="Description" name="description" />
                        </Box>
                    </FormGroup>
                    {skill && (
                        <>
                            <Box py={3}>
                                <Divider />
                            </Box>
                            <SkillRatings skill={skill} onSuccess={onSuccess} />
                        </>
                    )}
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" p={4}>
                    <Box ml="11px">
                        <FormControlLabel
                            control={<FormSwitcher name="isActive" />}
                            label={<Typography>{values.isActive ? "Active" : "Inactive"}</Typography>}
                        />
                    </Box>
                    <Box
                        display="flex"
                        alignItems="stretch"
                        height="46px"
                        gridGap={theme.spacing(1.75)}
                    >
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            loading={formik.isSubmitting}
                            onClick={submitForm}
                            loadingLabel={skill ? "Saving..." : "Creating..."}
                            startIcon={skill ? <TickIcon /> : null}
                        >
                            {skill ? "Save" : "Create"}
                        </LoadingButton>
                        <Button variant="outlined" size="large" onClick={onClose}>
                            Cancel
                        </Button>
                        {(skill && isDeletionEnabled) && (
                            <LoadingButton
                                variant="outlined"
                                color="default"
                                size="large"
                                disableElevation
                                loading={isDeleting}
                                onClick={handleDelete}
                                loadingLabel="Deleting..."
                            >
                                <DeleteIcon
                                    width="20"
                                    height="20"
                                    color={theme.palette.text.secondary}
                                />
                            </LoadingButton>
                        )}
                    </Box>
                </Box>
            </FormikProvider>
        </Dialog>
    )
}

export default SkillFormModal;