import React, { useCallback, useContext, useMemo, useReducer, useState } from "react";
import { Box, Paper, Typography, Button, CircularProgress } from "@material-ui/core";
import { CompaniesContext } from "context";
import TreeView from "@material-ui/lab/TreeView";
import { CompanyLevel, CompanyLevelLabel, TreeSet } from "types";
import { v4 as uuidv4 } from "uuid";
import { saveCompanyLevel, getCompanyLevelsTree, deleteCompanyLevel } from "services/companyServices";
import { AddIcon, ChevronLeft } from "svgComponents";
import { AdminRoutings } from "types/constants";
import NavigationLink from "components/NavigationLink";

import EditCompanyLevelModal from "./EditCompanyLevelModal";
import TreeNode from "./TreeNode";
import CreateCompanyLevelModal from "./CreateCompanyLevelModal";

const CompanyStructure = () => {
    const { companyLevelsTree, companyLevelLabels, setCompanyLevelsTree, isFetchingCompanyData } = useContext(CompaniesContext);
    const maxLevelLabel = companyLevelLabels.length === 0 ? null : companyLevelLabels.reduce((prev, current) => prev.level > current.level ? prev : current);
    const [isEditCompanyLevelModalOpen, toggleEditCompanyLevelModal] = useReducer(state => !state, false);
    const [isCreateCompanyLevelModalOpen, toggleCreateCompanyLevelModal] = useReducer(state => !state, false);
    const [currentEditableCompanyLevel, setCurrentEditableCompanyLevel] = useState<TreeSet>();
    const [currentEditableCompanyLevelLabel, setCurrentEditableCompanyLevelLabel] = useState<CompanyLevelLabel>();
    const [currentParentCompanyLevel, setCurrentParentCompanyLevel] = useState<CompanyLevel>();
    const [currentCreatedCompanyLevelLabel, setCurrentCreatedCompanyLevelLabel] = useState<CompanyLevelLabel>();

    const levelLabels = useMemo(() => new Map<number, CompanyLevelLabel>(
        companyLevelLabels.map(companyLevelLabel => ([companyLevelLabel.level, companyLevelLabel]))
    ), [companyLevelLabels]);

    const editCompanyLevel = useCallback((companyLevelTreeSet: TreeSet) => {
        setCurrentEditableCompanyLevel(companyLevelTreeSet);
        setCurrentEditableCompanyLevelLabel(levelLabels.get(companyLevelTreeSet.value.level));
        toggleEditCompanyLevelModal();
    }, [levelLabels]);

    const createCompanyLevel = useCallback((parentCompanyLevel?: CompanyLevel) => {
        setCurrentParentCompanyLevel(parentCompanyLevel);
        setCurrentCreatedCompanyLevelLabel(
            levelLabels.get(parentCompanyLevel ? parentCompanyLevel.level + 1 : 1)
        );
        toggleCreateCompanyLevelModal();
    }, [levelLabels]);

    const onEditCompanyLevelSubmit = useCallback(async (name: string) => {
        if (currentEditableCompanyLevel) {
            return saveCompanyLevel({ ...currentEditableCompanyLevel.value, name: name }).then(async () => {
                const companyLevelsTree = await getCompanyLevelsTree(currentEditableCompanyLevel.value.companyId);
                setCompanyLevelsTree(companyLevelsTree);
                toggleEditCompanyLevelModal();
            });
        }
    }, [currentEditableCompanyLevel, setCompanyLevelsTree]);

    const onCreateCompanyLevelSubmit = useCallback(async (name: string) => {
        if (currentCreatedCompanyLevelLabel) {
            const newCompanyLevel = {
                id: uuidv4(),
                name: name,
                companyId: currentCreatedCompanyLevelLabel.companyId,
                parentLevelId: currentParentCompanyLevel?.id || null,
                companyLevelLabelId: currentCreatedCompanyLevelLabel.id,
                level: currentCreatedCompanyLevelLabel.level,
                levelName: currentCreatedCompanyLevelLabel.name,
                users: [],
            };

            return saveCompanyLevel(newCompanyLevel).then(async () => {
                const companyLevelsTree = await getCompanyLevelsTree(newCompanyLevel.companyId);
                setCompanyLevelsTree(companyLevelsTree);
                toggleCreateCompanyLevelModal();
            });
        }
    }, [currentCreatedCompanyLevelLabel, currentParentCompanyLevel, setCompanyLevelsTree]);

    const onDeleteCompanyLevelSubmit = useCallback(async () => {
        if (currentEditableCompanyLevel) {
            return deleteCompanyLevel(currentEditableCompanyLevel.value.id).then(async () => {
                const companyLevelsTree = await getCompanyLevelsTree(currentEditableCompanyLevel.value.companyId);
                setCompanyLevelsTree(companyLevelsTree);
                toggleEditCompanyLevelModal();
            });
        }
    }, [currentEditableCompanyLevel, setCompanyLevelsTree]);

    const firstLevelLabel = levelLabels.get(1);

    return (
        <>
            <Box mb={3}>
                <NavigationLink hoverUnderline={false} href={AdminRoutings.company}>
                    <Typography variant="h1">
                        <Box display="flex" alignItems="center" gridGap={12}>
                            <ChevronLeft display="block" height={24} width={24} />
                            Company Organizational Structure
                        </Box>
                    </Typography>
                </NavigationLink>
            </Box>
            <Paper>
                {isFetchingCompanyData && (
                    <Box p={4} textAlign="center">
                        <CircularProgress size={40} />
                    </Box>
                )}
                {!isFetchingCompanyData && (
                    <Box p={2}>
                        {companyLevelsTree && maxLevelLabel && (
                            <Box mb={firstLevelLabel && !companyLevelsTree ? 1 : 0}>
                                <TreeView aria-label="Company Organizational Structure">
                                    <TreeNode
                                        element={companyLevelsTree}
                                        maxLevelLabel={maxLevelLabel}
                                        levelLabels={levelLabels}
                                        onEditCompanyLevel={editCompanyLevel}
                                        onCreateCompanyLevel={createCompanyLevel}
                                    />
                                </TreeView>
                            </Box>
                        )}
                        {firstLevelLabel && !companyLevelsTree && (
                            <Box pl={2}>
                                <Button
                                    size="large"
                                    color="primary"
                                    startIcon={<AddIcon width="24" height="24" />}
                                    onClick={() => createCompanyLevel()}
                                >
                                    Add new L1: {firstLevelLabel.name}
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
                {isEditCompanyLevelModalOpen && currentEditableCompanyLevel && currentEditableCompanyLevelLabel && (
                    <EditCompanyLevelModal
                        isOpen={isEditCompanyLevelModalOpen}
                        companyLevelTreeSet={currentEditableCompanyLevel}
                        companyLevelLabel={currentEditableCompanyLevelLabel}
                        onSubmit={onEditCompanyLevelSubmit}
                        onClose={toggleEditCompanyLevelModal}
                        onDelete={onDeleteCompanyLevelSubmit}
                    />
                )}
                {isCreateCompanyLevelModalOpen && currentCreatedCompanyLevelLabel && (
                    <CreateCompanyLevelModal
                        isOpen={isCreateCompanyLevelModalOpen}
                        parentCompanyLevel={currentParentCompanyLevel}
                        companyLevelLabel={currentCreatedCompanyLevelLabel}
                        onSubmit={onCreateCompanyLevelSubmit}
                        onClose={toggleCreateCompanyLevelModal}
                    />
                )}
            </Paper>
        </>
    )
}

export default CompanyStructure;
