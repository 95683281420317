import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    ratingMeaning: {
        background: theme.palette.info.main,
        border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        boxShadow: "0px 0px 5px rgba(39, 71, 110, 0.06)",
    },
}));

export default useStyles;
