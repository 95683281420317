import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, CircularProgress, Divider, Grid, Typography } from "@material-ui/core";

import PaperSection from "components/PaperSection";
import NoDataMessage from "components/NoDataMessage";
import CircleIcon from "components/CircleIcon";
import { EvaluationResultStatusEnum, EvaluationStatusText } from "types/constants";
import { ClassTraineesRouteParams } from "types/interfaces";
import { ClassTraineesInformation, TraineeClassInformation } from "types/Certification";
import { getClassTraineeList, getClassTraineesInformation } from "services/certificationService";
import { formatShortDate } from "helpers/dateHelpers";
import IconPersonCheck from "svgComponents/IconPersonCheck";

import ClassTraineesFilters from "./ClassTraineesFilters";
import ClassTraineesItem from "./ClassTraineesItem";

import useStyles from "./styles";

export default function ClassTraineesPage() {
    const classes = useStyles();
    const { classId } = useParams<ClassTraineesRouteParams>();
    const [isFetched, setIsFetched] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [сlassTraineesInfo, setСlassTraineesInfo] = useState<ClassTraineesInformation | null>(null);
    const [classTraineeList, setClassTraineeList] = useState<TraineeClassInformation[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageTotalAmount, setPageTotalAmount] = useState<number>(0);

    const [filteredTrainees, setFilteredTrainees] = useState<TraineeClassInformation[]>([]);

    const totalLabel = useMemo(() => (classTraineeList.length === 1 ? "item" : "items"), [classTraineeList.length]);

    const fetchClassTraineeListData = useCallback(
        (nextPage = 1) =>
            getClassTraineeList(classId, nextPage)
                .then((traineeList) => {
                    setClassTraineeList([...classTraineeList, ...traineeList.results]);
                    setPageTotalAmount(traineeList.totalAmount);
                })
                .finally(() => {
                    setPageNumber(nextPage);
                }),
        [classId, classTraineeList]
    );

    const fetchData = useCallback(
        () =>
            Promise.all([getClassTraineesInformation(classId), fetchClassTraineeListData()])
                .then(([traineesInformation]) => setСlassTraineesInfo(traineesInformation))
                .finally(() => {
                    setIsFetched(true);
                    setIsFetching(false);
                }),
        [classId, fetchClassTraineeListData]
    );

    const onShowMore = useCallback(() => {
        fetchClassTraineeListData(pageNumber + 1);
    }, [fetchClassTraineeListData, pageNumber]);

    useEffect(() => {
        if (!isFetched && !isFetching) {
            setIsFetching(true);
            fetchData();
        }
    }, [fetchData, isFetched, isFetching, pageNumber]);

    return (
        <>
            {isFetched && сlassTraineesInfo && !!classTraineeList.length && (
                <>
                    <Box bgcolor="common.white" px={8} pt={4.5} pb={2}>
                        <Grid container justifyContent="space-between" className={classes.title}>
                            <Typography variant="h1">{сlassTraineesInfo?.className} Roster</Typography>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item> {formatShortDate(new Date(сlassTraineesInfo?.classDate))}</Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="center" pt={6} mb={5}>
                        <PaperSection
                            px={6}
                            width={1}
                            title={
                                <Box display="flex" alignItems="center" flexWrap="wrap" width={1}>
                                    <Box fontWeight={600} whiteSpace="nowrap" mr={"auto"}>
                                        <Typography variant="h2">All Trainees</Typography>
                                    </Box>

                                    <Box mr={3} textAlign="center">
                                        {сlassTraineesInfo.isSelfEvaluated ? (
                                            <CircleIcon>
                                                <IconPersonCheck />
                                            </CircleIcon>
                                        ) : null}
                                    </Box>
                                    <Box mr={3} textAlign="center">
                                        <Typography variant="h3">{EvaluationStatusText[EvaluationResultStatusEnum.new]}</Typography>
                                        <Typography variant="caption">
                                            {сlassTraineesInfo.evaluationsByStatusAmounts[EvaluationResultStatusEnum.new]}
                                        </Typography>
                                    </Box>
                                    <Box mr={3} textAlign="center">
                                        <Typography variant="h3">{EvaluationStatusText[EvaluationResultStatusEnum.inProgress]}</Typography>
                                        <Typography variant="caption">
                                            {сlassTraineesInfo.evaluationsByStatusAmounts[EvaluationResultStatusEnum.inProgress]}
                                        </Typography>
                                    </Box>
                                    <ClassTraineesFilters trainees={classTraineeList} onFilter={setFilteredTrainees} />
                                </Box>
                            }>
                            <Box px={5} py={2}>
                                <Typography variant="caption">
                                    {classTraineeList.length} {totalLabel}
                                </Typography>
                            </Box>
                            <Divider />
                            <InfiniteScroll
                                scrollableTarget="app"
                                dataLength={classTraineeList.length}
                                next={onShowMore}
                                hasMore={classTraineeList.length < pageTotalAmount}
                                loader={
                                    <Box p={4} textAlign="center">
                                        <CircularProgress size={40} />
                                    </Box>
                                }
                                hasChildren>
                                {filteredTrainees.map((trainee) => (
                                    <ClassTraineesItem key={trainee.traineeId} trainee={trainee} />
                                ))}
                            </InfiniteScroll>

                            {!filteredTrainees.length && (
                                <Box py={4}>
                                    <NoDataMessage />
                                </Box>
                            )}
                        </PaperSection>
                    </Box>
                </>
            )}

            {isFetched && !classTraineeList.length && (
                <Box py={4}>
                    <NoDataMessage />
                </Box>
            )}
            {!isFetched && (
                <Box py={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            )}
        </>
    );
}
