import React, { useCallback } from "react";

import { Box, Typography, Divider, Grid, CircularProgress } from "@material-ui/core";
import { FcrCoachingSkillObservation, FcrCoachingSkill, FcrCoachingSkillHistory, FcrConfiguration } from "types";

import { v4 as uuidv4 } from "uuid";

import ObservationsSkillButton from "./ObservationsSkillButton";
import CompetencyCard from "./CompetencyCard";
import useStyles from "./styles";

interface ObservationsProps {
    fcrCoachingSkills: Array<FcrCoachingSkill>;
    fcrCoachingSkillObservations: Array<FcrCoachingSkillObservation>;
    isCoachingSkillObservationsFetching?: boolean;
    fcrCoachingSkillsHistory: FcrCoachingSkillHistory[];
    fcrConfiguration: FcrConfiguration;
    onChange: (value: FcrCoachingSkillObservation) => void;
    addFcrCoachingSkillObservation: (value: FcrCoachingSkillObservation) => void;
    removeFcrCoachingSkillObservation: (id: string) => void;
}

function Observations({
    fcrCoachingSkills,
    fcrCoachingSkillObservations,
    isCoachingSkillObservationsFetching,
    fcrCoachingSkillsHistory,
    fcrConfiguration,
    onChange,
    addFcrCoachingSkillObservation,
    removeFcrCoachingSkillObservation,
}: ObservationsProps) {
    const classes = useStyles();

    const competencyChange = useCallback((value: FcrCoachingSkillObservation) => {
        onChange(value);
    }, [onChange]);

    const handleClick = useCallback((isActive: boolean, index: number, id?: string) => {
        if (isActive) {
            id && removeFcrCoachingSkillObservation(id);
        } else {
            const newFcrCoachingSkillObservation = Object.assign({
                id: uuidv4(),
                fcrCoachingSkill: fcrCoachingSkills[index],
                fcrCoachingSkillId: fcrCoachingSkills[index].id,
                fcrCoachingSkillName: fcrCoachingSkills[index].name,
                rating: fcrCoachingSkills[index].minRating,
                observation: "",
                resultDate: new Date(),
            });
            addFcrCoachingSkillObservation(newFcrCoachingSkillObservation);
        }
    }, [addFcrCoachingSkillObservation, fcrCoachingSkills, removeFcrCoachingSkillObservation]);

    return (
        <Box>
            <Divider />
            <Box py={3} fontWeight="600">
                <Typography variant="h2">Competencies</Typography>
            </Box>
            <Box pb={5} color="text.secondary" display="flex">
                <Grid container spacing={3}>
                    {fcrCoachingSkills.map(({ id, name }, index) => {
                        const fcrCoachingSkillObservation = fcrCoachingSkillObservations.find(c => c.fcrCoachingSkillId === id);
                        const isActive = !!fcrCoachingSkillObservation;

                        return (
                            <Grid key={index} item md={3} xs={6}>
                                <ObservationsSkillButton
                                    text={name}
                                    isActiveByDefault={isActive}
                                    className={classes.button}
                                    disabled={isCoachingSkillObservationsFetching}
                                    onClick={() => handleClick(
                                        isActive,
                                        index,
                                        fcrCoachingSkillObservation && fcrCoachingSkillObservation.id
                                    )}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <Divider />
            {(isCoachingSkillObservationsFetching) ? (
                <Box display="flex" justifyContent="center" py={4}>
                    <CircularProgress size={32} />
                </Box>
            ) : (
                <Box pt={5}>
                    {fcrCoachingSkillObservations.map((fcrCoachingSkillObservation) => (
                        <CompetencyCard
                            key={fcrCoachingSkillObservation.id}
                            fcrCoachingSkillObservation={fcrCoachingSkillObservation}
                            fcrCoachingSkillsHistory={fcrCoachingSkillsHistory}
                            onChange={competencyChange}
                            fcrCoachingSkills={fcrCoachingSkills}
                            fcrConfiguration={fcrConfiguration}
                        />))
                    }
                </Box>
            )}
        </Box>
    )
}

export default Observations;