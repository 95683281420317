import * as React from "react";

function SvgTune(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.2 8.98V0H0.95V8.98C0.39 9.22 0 9.78 0 10.43C0 11.08 0.39 11.64 0.95 11.88V13.66H2.2V11.88C2.76 11.64 3.15 11.08 3.15 10.43C3.16 9.78 2.76 9.22 2.2 8.98Z" fill="currentColor" />
      <path d="M8.05156 2.86V0H6.80156V2.86C6.24156 3.1 5.85156 3.66 5.85156 4.31C5.85156 4.96 6.24156 5.52 6.80156 5.76V13.65H8.05156V5.76C8.61156 5.52 9.00156 4.96 9.00156 4.31C9.00156 3.66 8.61156 3.11 8.05156 2.86Z" fill="currentColor" />
      <path d="M14.8592 7.3C14.8592 6.65 14.4692 6.09 13.9092 5.85V0H12.6592V5.85C12.0992 6.09 11.6992 6.65 11.6992 7.3C11.6992 7.95 12.0892 8.51 12.6492 8.75V13.66H13.8992V8.75C14.4692 8.5 14.8592 7.95 14.8592 7.3Z" fill="currentColor" />
    </svg>
  );
}

export default SvgTune;
