import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        minWidth: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        "& .MuiButton-startIcon": {
            margin: 0,
        },

        "& svg": {
            width: "16px",
            height: "24px",
        },
    },
}));

export default useStyles;