import * as React from "react";

function SvgActionable(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Add" fill="currentColor">
                    <path
                        d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,1 C4.13400675,1 1,4.13400675 1,8 C1,11.8659932 4.13400675,15 8,15 C11.8659932,15 15,11.8659932 15,8 C15,4.13400675 11.8659932,1 8,1 Z M8.5,5 L8.5,7.5 L11,7.5 L11,8.5 L8.5,8.5 L8.5,11 L7.5,11 L7.5,8.5 L5,8.5 L5,7.5 L7.5,7.5 L7.5,5 L8.5,5 Z"
                        id="Combined-Shape"
                    ></path>
                </g>
            </g>
        </svg>

    );
}

export default SvgActionable;
