import React, { useCallback, useEffect, useReducer, useState, useContext, ReactNode } from "react";
import { Box, Slide } from "@material-ui/core";
import { ConfigurationContext } from "context";

import {
    UserProfile,
    FcrCoachingSkillHistory,
    MessageType,
    CoachingPlanType,
    Team,
    FcrCoachingSkill,
} from "types";
import { logEvent } from "helpers/analyticsHelper";
import { getCoachingSkillsForFcrConfiguration, getUserSkillHistory } from "services/wizardServices";
import { getRecentMessages } from "services/messageServices";
import { getTeamByMemberId } from "services/teamServices";
import CoachingReportSelector from "components/CoachingReportSelector";
import MessageModal from "components/MessageModal";
import useAsync, { PromiseStatus } from "hooks/useAsync";

import TeamProfileSidebar from "./TeamProfileSidebar";
import TeamProfileContent from "./TeamProfileContent";

interface TeamProfileProps {
    user: UserProfile;
    navigation?: ReactNode;
}

const TeamProfile = ({ user, navigation }: TeamProfileProps) => {
    const { fcrConfiguration, currentUser } = useContext(ConfigurationContext);
    const [coachingSkills, setCoachingSkills] = useState<FcrCoachingSkill[]>([]);
    const [fcrCoachingSkillHistory, setFcrCoachingSkillHistory] = useState<FcrCoachingSkillHistory[]>();
    const [isCoachingReportSelectorOpen, toggleCoachingReportSelector] = useReducer(state => !state, false);
    const [isNewMessageModalOpen, toggleNewMessageModal] = useReducer(state => !state, false);
    const [isRecentMessagesFetching, setIsRecentMessagesFetching] = useState(false);
    const [isFcrCoachingSkillHistoryFetching, setIsFcrCoachingSkillHistoryFetching] = useState(false);
    const [isCoachingSkillsFetching, setIsCoachingSkillsFetching] = useState(false);
    const [recentMessagesData, setRecentMessagesData] = useState<MessageType[]>([]);
    const [isSidebarVisible, toggleIsSidebarVisible] = useReducer((state) => !state, true);
    const { status: teamAsForMemberFetchStatus, value: teamAsForMember } = useAsync<Team | null>(() => (
        user.id ? getTeamByMemberId(user.id) : Promise.resolve(null)
    ), [user.id]);

    const loadRecentMessages = useCallback(async () => {
        setIsRecentMessagesFetching(true);
        setRecentMessagesData([]);
        setRecentMessagesData(await getRecentMessages(user!.id));
        setIsRecentMessagesFetching(false);
    }, [user]);

    const updateCoachingPlan = useCallback((newCoachingPlan: CoachingPlanType) => {
        const coachingPlans = (fcrCoachingSkillHistory ?? []).map(coachingSkill => ({
            ...coachingSkill,
            fcrCoachingPlans: coachingSkill.fcrCoachingPlans.map(oldCoachingPlan =>
                oldCoachingPlan.id === newCoachingPlan.id ? newCoachingPlan : oldCoachingPlan),
        }))
        setFcrCoachingSkillHistory(coachingPlans ?? []);
    }, [fcrCoachingSkillHistory]);

    useEffect(() => {
        const getFcrSkillHistory = async () => {
            setIsFcrCoachingSkillHistoryFetching(true);
            setFcrCoachingSkillHistory(undefined);
            setFcrCoachingSkillHistory(await getUserSkillHistory(user!.id, false));
            setIsFcrCoachingSkillHistoryFetching(false);
        }

        if (user) {
            getFcrSkillHistory();
            loadRecentMessages();
        }

        user && logEvent("team_profile_opened", { userId: user.id });
    }, [user, loadRecentMessages]);

    useEffect(() => {
        const fetchCoachingSkills = async (fcrConfigurationId: string) => {
            setIsCoachingSkillsFetching(true);
            const coachingSkills = await getCoachingSkillsForFcrConfiguration(fcrConfigurationId);
            setCoachingSkills(coachingSkills);
            setIsCoachingSkillsFetching(false);
        };

        fcrConfiguration && fetchCoachingSkills(fcrConfiguration.id)
    }, [fcrConfiguration]);

    return (
        <>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                <>
                    <Slide
                        direction="right"
                        in={isSidebarVisible}
                        timeout={500}
                        mountOnEnter
                        appear={false}
                    >
                        <Box>
                            <TeamProfileSidebar
                                user={user}
                                teamAsForMember={teamAsForMember}
                                isTeamAsForMemberFetching={teamAsForMemberFetchStatus === PromiseStatus.PENDING}
                                isCoachingSkillsFetching={isCoachingSkillsFetching}
                                openCoachingReportSelector={toggleCoachingReportSelector}
                                openNewMessageModal={toggleNewMessageModal}
                                onSidebarCollapse={toggleIsSidebarVisible}
                            />
                        </Box>
                    </Slide>
                    <TeamProfileContent
                        user={user}
                        isFcrCoachingSkillHistoryFetching={isFcrCoachingSkillHistoryFetching}
                        isRecentMessagesFetching={isRecentMessagesFetching}
                        recentMessages={recentMessagesData}
                        coachingSkills={coachingSkills}
                        fcrCoachingSkillHistory={fcrCoachingSkillHistory}
                        onCoachingPlanChange={updateCoachingPlan}
                        isSidebarVisible={isSidebarVisible}
                        onSidebarCollapse={toggleIsSidebarVisible}
                        navigation={navigation}
                    />
                </>
            </Box>
            {isCoachingReportSelectorOpen && (
                <CoachingReportSelector
                    user={user}
                    isOpen={isCoachingReportSelectorOpen}
                    onClose={toggleCoachingReportSelector}
                    fcrConfiguration={fcrConfiguration}
                />
            )}
            {isNewMessageModalOpen && (
                <MessageModal
                    user={user.id !== currentUser?.id ? user : undefined}
                    isOpen={isNewMessageModalOpen}
                    onSuccess={loadRecentMessages}
                    onClose={toggleNewMessageModal}
                />
            )}
        </>
    );
};

export default TeamProfile;