import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    field: {
        display: "none",
    },
    previewBox: {
        transform: "translateX(-50%)",
    },
    previewImg: {
        borderRadius: "50%",
        cursor: "pointer",
        display: "block",
        height: theme.spacing(27.5),
        objectFit: "cover",
        width: theme.spacing(27.5),
    },
}));

export default useStyles;
