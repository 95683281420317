import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        width: 32,
        height: 32,
        fontSize: 16,
        backgroundColor: (props: { id: string }) => theme.colors.avatar[parseInt(props.id[0], 16) % theme.colors.avatar.length],
    },
    traineeItemWrap: {
        padding: theme.spacing(2),
    },
    traineeItem: {
        borderRadius: 4,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
        },
    },
    traineeItemCell: {
        display: "flex",
        alignItems: "center",
    },
    traineeItemCellTextBold: {
        paddingLeft: theme.spacing(3),
        fontWeight: 500,
    },
    traineeItemStatus: {
        display: "inline-block",
        fontWeight: 500,
        borderRadius: 4,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        color: theme.palette.text.secondary,
        [theme.breakpoints.up("sm")]: {
            "$sessionItem:hover &": {
                display: "none",
            },
        },
    },
}));

export default useStyles;
