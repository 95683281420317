import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import orderBy from "lodash/orderBy";

import CoachingPlan from "components/CoachingPlan";
import LabelScoreBar from "components/LabelScoreBar";
import RichText from "components/RichText";
import SvgCalendar from "svgComponents/Calendar";
import { CoachingPlanType, UpcomingCoachingSession } from "types";
import CoachingPlanModal from "components/CoachingPlanModal";
import useCoachingPlanModal from "hooks/useCoachingPlanModal";

interface UpcomingSessionContentProps {
    upcomingSession: UpcomingCoachingSession;
    onCoachingPlanChange: (newCoachingPlan: CoachingPlanType) => void;
}

const UpcomingSessionContent = ({ upcomingSession, onCoachingPlanChange }: UpcomingSessionContentProps) => {
    const { assigneeProfile, lastFcrResult } = upcomingSession;
    const { data, isShown, open, close } = useCoachingPlanModal();

    const sortedPlans = useMemo(() => {
        const plans = lastFcrResult?.fcrCoachingPlans ?? [];
        return orderBy(plans, "dueDate", "desc");
    }, [ lastFcrResult?.fcrCoachingPlans ]);

    return (
        <Box py={4}>
            <Grid container>
                <Grid item xs={12} md={9}>
                    <Box display="flex" flexDirection="column" px={5}>
                        <Box fontWeight={600} mb={2}>
                            <Typography variant="h3" component="span">
                                Development Goal
                            </Typography>
                        </Box>
                        <Typography variant="body2" component="div">
                            {lastFcrResult && lastFcrResult.fcrDevelopmentGoal && lastFcrResult.fcrDevelopmentGoal.developmentGoals ?
                                <RichText text={lastFcrResult.fcrDevelopmentGoal.developmentGoals}/> :
                                "Nothing to show here yet"
                            }
                        </Typography>
                        <Box fontWeight={600} mt={5} mb={2}>
                            <Typography variant="h3" component="span">
                                Latest Coaching Plans
                            </Typography>
                        </Box>
                        {sortedPlans.map((coachingPlan) => (
                            <Box
                                key={coachingPlan.id}
                                borderRadius={10}
                                bgcolor="info.main"
                                px={2.5}
                                mb={2}
                            >
                                <CoachingPlan
                                    coachingPlan={coachingPlan}
                                    darkStatusBackground
                                    onDetailOpen={open}
                                    my={2.5}
                                />
                            </Box>
                        ))}
                        {!!sortedPlans.length
                            ? (
                                <CoachingPlanModal
                                    data={data}
                                    isShown={isShown}
                                    onClose={close}
                                    onCoachingPlanChange={onCoachingPlanChange}
                                />
                            )
                            : (
                                <Typography variant="body2">
                                    Nothing to show here yet
                                </Typography>
                            )
                            
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Box display="flex" flexDirection="column" pl={{ xs: 5, md: 0 }} pr={5}>
                        <Box fontWeight={600} mb={3}>
                            <Typography variant="h3" component="span">
                                Team Member Details
                            </Typography>
                        </Box>
                        {assigneeProfile.highestSkillName && (
                            <>
                                <LabelScoreBar
                                    score={assigneeProfile.highestSkillRating}
                                    label={assigneeProfile.highestSkillName}
                                    minRating={assigneeProfile.minRating}
                                    maxRating={assigneeProfile.maxRating}
                                />
                                <Box mt={1} mb={3}>
                                    <Typography variant="caption">
                                        Highest Score
                                    </Typography>
                                </Box>
                            </>
                        )}
                        {assigneeProfile.lowestSkillName && (
                            <>
                                <LabelScoreBar
                                    score={assigneeProfile.lowestSkillRating}
                                    label={assigneeProfile.lowestSkillName}
                                    minRating={assigneeProfile.minRating}
                                    maxRating={assigneeProfile.maxRating}
                                />
                                <Box mt={1} mb={3}>
                                    <Typography variant="caption">
                                        Lowest Score
                                    </Typography>
                                </Box>
                            </>
                        )}
                        {assigneeProfile.skillInFocus && (
                            <Box mb={3}>
                                <Typography variant="body1">
                                    {assigneeProfile.skillInFocus}
                                </Typography>
                                <Typography variant="caption">
                                    Skill in Focus
                                </Typography>
                            </Box>
                        )}
                        <Box display="flex" alignItems="center">
                            <Box mr={1} display="flex">
                                <SvgCalendar/>
                            </Box>
                            <Typography variant="h3">
                                {assigneeProfile.timesCoached || 0} times this year
                            </Typography>
                        </Box>
                        <Typography variant="caption">
                            Times Coached
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default UpcomingSessionContent;