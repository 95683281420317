import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    progress: {
        position: "relative",
        "&::before": {
            content: "''",
            display: "block",
            boxSizing: "border-box",
            borderRadius: "50%",
            position: "absolute",
            border: "10px solid #EEF0F3",
            width: "100%",
            height: "100%",
        },
        "& $svg": {
            zIndex: 1,
            position: "relative",
        },
    },
    iconWrap: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        background: "#EEF6F8",
    },
    valueWrap: {
        display: "flex",
        alignItems: "center",
    },
    value: {
        fontWeight: 600,
        lineHeight: "48px",
        whiteSpace: "nowrap",
    },
    label: {
        fontSize: "13px",
        lineHeight: 1.5,
    },
}));

export default useStyles;
