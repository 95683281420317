import firebase from "../initializeFirebase";

import API from "./apiService";

const notificationsDB = firebase.ref(process.env.REACT_APP_FIREBASE_NOTIFICATIONS);

export const notificationsByUser = (userId: string) => notificationsDB.child(userId)

export const removeAllNotifications = (userId: string) => notificationsDB.child(userId).remove()

export const removeNotification = (userId: string, notificationaId: string) => notificationsDB.child(userId).child(notificationaId).remove()

export const saveDeviceToken = async (token: string) => {
    const { data } = await API.post("/Notification/add", { token });
    return data;
}