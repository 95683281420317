import * as React from "react";

function SvgCompletedTask(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={15}
            height={19}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.49 3.07023H10.76C10.59 2.33023 9.93 1.77023 9.16 1.77023H9.13C8.88 1.03023 8.17 0.490234 7.34 0.490234C6.51 0.490234 5.8 1.03023 5.55 1.77023H5.54C4.75 1.77023 4.09 2.33023 3.92 3.07023H2.19C1.27 3.07023 0.519997 3.82023 0.519997 4.74023V17.0602C0.519997 17.9802 1.27 18.7302 2.19 18.7302H12.49C13.41 18.7302 14.16 17.9802 14.16 17.0602V4.74023C14.15 3.82023 13.41 3.07023 12.49 3.07023ZM5.12 3.44023C5.12 3.21023 5.31 3.02023 5.54 3.02023H6.02C6.05 3.02023 6.08 3.02023 6.12 3.01023C6.17 3.00023 6.21 2.99023 6.25 2.97023C6.51 2.89023 6.7 2.64023 6.7 2.37023C6.7 2.02023 6.99 1.73023 7.34 1.73023C7.69 1.73023 7.98 2.02023 7.98 2.37023C7.98 2.57023 8.08 2.75023 8.24 2.87023C8.35 2.95023 8.47 2.96023 8.62 2.99023L9.14 3.01023C9.37 3.01023 9.56 3.20023 9.56 3.43023V3.90023H5.12V3.44023ZM12.9 17.0602C12.9 17.2902 12.71 17.4802 12.48 17.4802H2.18C1.95 17.4802 1.76 17.2902 1.76 17.0602V4.74023C1.76 4.51023 1.95 4.32023 2.18 4.32023H3.86V4.54023C3.86 4.88023 4.14 5.16023 4.48 5.16023H10.17C10.51 5.16023 10.79 4.88023 10.79 4.54023V4.32023H12.47C12.7 4.32023 12.89 4.51023 12.89 4.74023V17.0602H12.9Z" fill="currentColor" />
            <path d="M6.66 11.7705L5.29 10.4105L4.41 11.3005L6.27 13.1505C6.39 13.2705 6.55 13.3305 6.71 13.3305C6.72 13.3305 6.73 13.3305 6.74 13.3305C6.92 13.3205 7.08 13.2405 7.19 13.1005L10.31 9.31051L9.35 8.52051L6.66 11.7705Z" fill="currentColor" />
        </svg>
    );
}

export default SvgCompletedTask;