import React from "react";
import { Box, Card, Divider, Typography, CircularProgress, useTheme } from "@material-ui/core";
import { UserProfile, Team } from "types";
import InfiniteScroll from "react-infinite-scroll-component";

import NoDataMessage from "components/NoDataMessage";

interface ItemsListProps {
    children?: React.ReactNode,
    filter?: React.ReactNode,
    isHasMore: boolean,
    isLoading?: boolean,
    quantity: number,
    totalLabel: string,
    filteredUsers?: UserProfile[] | Team[],
    warning?: string | React.ReactNode;
    onNext: () => void,
}

export default function ItemsList({
    children,
    filter,
    quantity,
    isHasMore,
    totalLabel,
    isLoading,
    warning,
    filteredUsers,
    onNext,
}: ItemsListProps) {
    const theme = useTheme();
    
    return (
        <Card>
            {!isLoading && (
                <>
                    {filter}
                    <Divider />
                    <Box display="flex" py={2} px={4}>
                        <Box flexShrink={0}>
                            <Typography color="textSecondary" variant="body1">{`${quantity} ${isHasMore && filteredUsers ? `from ${filteredUsers.length} ` : ""}${totalLabel}`}</Typography>
                        </Box>
                        {!!warning && (
                            <Box borderLeft="1px solid" borderColor={theme.palette.info.dark} pl={2} ml={2}>
                                <Typography color="error" variant="body1">{warning}</Typography>
                            </Box>
                        )}
                    </Box>
                    {quantity === 0 && <Divider />}
                    <InfiniteScroll
                        scrollableTarget="app"
                        dataLength={quantity}
                        next={onNext}
                        hasMore={isHasMore}
                        loader={<></>}
                    >
                        {React.Children.map(children, child => (
                            <>
                                <Divider />
                                {child}
                            </>
                        ))}
                    </InfiniteScroll>
                </>
            )}
            {!isLoading && quantity === 0 && (
                <Box px={6} py={4}>
                    <NoDataMessage />
                </Box>
            )}
            {isLoading && (
                <Box px={6} py={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            )}
        </Card>
    )
}
