import React from "react";
import { Box, Typography } from "@material-ui/core";

import RichText from "components/RichText";

import useStyles from "./styles";

interface RightSideProps {
    lastCoachingPlan?: string;
}

function RightSide({ lastCoachingPlan = "" }: RightSideProps) {
    const classes = useStyles();
    return (
        <Box fontWeight={600}>
            <Box pb={2.5}>
                <Typography variant="h3">Last Coaching Plan</Typography>
            </Box>
            <Box fontWeight={400} className={classes.text}>
                <Typography variant="h3">
                    <RichText text={lastCoachingPlan}/>
                </Typography>
            </Box>
        </Box>
    )
}

export default RightSide;
