import { EvalResultsSaveOptionsEnum } from "helpers/certificationHelper";
import React, { createRef, Dispatch, SetStateAction } from "react";
import { AppDialogProps, initialAppDialogProps } from "routes/MainComponent/AppDialog";
import { GetCertificationEvaluationResultInputs } from "services/certificationService";

import {
    CoachingSession,
    CoachingSessionFilter,
    Company,
    CompanyLevel,
    CompanyLevelLabel,
    FcrCoachingSkill,
    FcrConfiguration,
    FcrSectionConfiguration,
    MessageThread,
    MessageType,
    NotificationType,
    Team,
    TreeSet,
    User,
    UserProfile,
} from "types";
import {
    CertClassesPreviewForUserDto,
    CertificationSectionConfiguration,
    EvaluatorClassDto,
    TraineeClassInfoDto,
} from "types/Certification";
import { AlertSeverity, SortDirectionEnum } from "types/constants";
import { EvaluationResultSaveDto, FullEvaluationDto } from "types/Evaluation.dto";

type ConfigurationContextProps = {
    currentUser?: UserProfile;
    currentUserDto?: User;
    currentCompany?: Company;
    currentTeam: UserProfile[];
    allCompanyTeams: Team[];
    companyUsers?: UserProfile[];
    companyLevels: CompanyLevel[];
    fcrConfiguration?: FcrConfiguration;
    fcrSectionConfiguration?: Partial<FcrSectionConfiguration>;
    coacheeTeam?: Team;
    coachedTeam?: Team;
    isConfigurationFetching: boolean;
    isConfigurationFetched: boolean;
    companyCertificationConfiguration: CertificationSectionConfiguration | null;
    hasCoachingReportAccess: (coachingSession: CoachingSession) => boolean;
    patchCurrentUser: (currentUserPatch: { [key in keyof UserProfile]?: UserProfile[key] }) => void;
    patchCurrentUserDto: (currentUserDtoPatch: { [key in keyof User]?: User[key] }) => void;
};

export const ConfigurationContext = React.createContext<ConfigurationContextProps>({
    currentTeam: [],
    companyLevels: [],
    allCompanyTeams: [],
    isConfigurationFetching: false,
    isConfigurationFetched: false,
    companyCertificationConfiguration: null,
    hasCoachingReportAccess: () => false,
    patchCurrentUser: () => null,
    patchCurrentUserDto: () => null,
});

type NotificationsContextProps = {
    notifications: NotificationType[];
    isUnread: boolean;
    setIsUnread: (isUnread: boolean) => void;
};

export const NotificationsContext = React.createContext<NotificationsContextProps>({
    notifications: [],
    isUnread: false,
    setIsUnread: () => null,
});

type ScrollContextProps = {
    scrollToTop: (smooth: boolean) => void;
    scrollContainerRef: React.RefObject<HTMLDivElement>;
};

export const ScrollContext = React.createContext<ScrollContextProps>({
    scrollToTop: () => null,
    scrollContainerRef: createRef(),
});

type SnackbarContextProps = {
    openSnackbar: (text: string, severity?: AlertSeverity) => void;
};

export const SnackbarContext = React.createContext<SnackbarContextProps>({
    openSnackbar: () => null,
});

type CompaniesContextProps = {
    currentUser?: UserProfile;
    isFetchingCompanyData: boolean;
    allCompanies: Company[];
    getAllCompaniesList: (currentCompanyId?: string) => void;
    handleCompanyUpdated: (companyId: string) => void;
    currentCompany: Company | null;
    setCurrentCompany: (company: Company | null) => void;
    companyLevels: CompanyLevel[];
    companyUsers: UserProfile[];
    fetchCompanyUsers: () => Promise<void>;
    isFetchingCompanyUsers: boolean;
    companyLeaders: UserProfile[];
    fcrCoachingSkillsForCompany: FcrCoachingSkill[];
    setFcrCoachingSkillsForCompany: (fcrCoachingSkillsData: FcrCoachingSkill[]) => void;
    companyTeams: Team[];
    setCompanyTeams: (companyTeams: Team[]) => void;
    companyLevelsTree: TreeSet | null;
    setCompanyLevelsTree: (companyLevelsTree: TreeSet) => void;
    companyLevelLabels: CompanyLevelLabel[];
    setCompanyLevelLabels: (companyLevelLabels: CompanyLevelLabel[]) => void;
    companyCertificationConfiguration: CertificationSectionConfiguration | null;
    fcrSectionConfiguration: FcrSectionConfiguration | null;
    setFcrSectionConfiguration: (fcrSectionConfiguration: FcrSectionConfiguration) => void;
    setOnBeforeCompanyChange: (callback: (() => boolean) | undefined) => void;
    appDialogProps: AppDialogProps;
    setAppDialogProps: (props: AppDialogProps) => void;
};

export const CompaniesContext = React.createContext<CompaniesContextProps>({
    isFetchingCompanyData: false,
    allCompanies: [],
    getAllCompaniesList: () => null,
    handleCompanyUpdated: () => null,
    currentCompany: null,
    setCurrentCompany: () => null,
    companyLevels: [],
    fetchCompanyUsers: () => Promise.resolve(),
    isFetchingCompanyUsers: false,
    companyUsers: [],
    companyLeaders: [],
    fcrCoachingSkillsForCompany: [],
    setFcrCoachingSkillsForCompany: () => null,
    companyTeams: [],
    setCompanyTeams: () => null,
    companyLevelsTree: null,
    setCompanyLevelsTree: () => null,
    companyLevelLabels: [],
    setCompanyLevelLabels: () => null,
    companyCertificationConfiguration: null,
    fcrSectionConfiguration: null,
    setFcrSectionConfiguration: () => null,
    setOnBeforeCompanyChange: () => null,
    appDialogProps: initialAppDialogProps,
    setAppDialogProps: () => null,
});

type CoachingHubContext = {
    coachingSessions?: CoachingSession[] | null;
    isFetchingByFilters: boolean;
    setIsFetchingByFilters: (isFetchingByFilters: boolean) => void;
    clearCoachingSessions: () => void;
    fetchCoachingSessions: (pageNumber: number, filters?: CoachingSessionFilter) => void;
    handleCoachingSessionSaved: (coachingSession: CoachingSession) => void;
    handleCoachingSessionDeleted: (coachingSession: CoachingSession) => void;
    messages?: MessageType[];
    fetchMessages: () => void;
    handleMessagesSaved: (messages: MessageType[]) => void;
    handleMarkThreadAsRead: (threadId: string) => void;
    isFetchingMessages: boolean;
    totalFilteredAmount: number;
    coachingHubFilters?: CoachingSessionFilter | null;
    setCoachingHubFilters: (filters: CoachingSessionFilter | null) => void;
    messageThreadList?: MessageThread[] | null;
    fetchMessagesThreads: (pageNumber: number) => void;
    totalMessageThreads: number;
    clearMessagesThreads: () => void;
};

export const CoachingHubContext = React.createContext<CoachingHubContext>({
    fetchCoachingSessions: () => null,
    isFetchingByFilters: false,
    setIsFetchingByFilters: () => null,
    clearCoachingSessions: () => null,
    handleCoachingSessionSaved: () => null,
    handleCoachingSessionDeleted: () => null,
    fetchMessages: () => null,
    handleMessagesSaved: () => null,
    handleMarkThreadAsRead: () => null,
    isFetchingMessages: false,
    totalFilteredAmount: 0,
    setCoachingHubFilters: () => null,
    fetchMessagesThreads: () => null,
    totalMessageThreads: 0,
    clearMessagesThreads: () => null,
});

/** Defines all the functions and properties needed to serve the classes pages`
 */
export interface IClassesContext {
    //Main Classes Page previewPreview
    fetchCertificationClassesPreview?: () => void;
    allMainClassesPreview?: CertClassesPreviewForUserDto | null;
    setAllMainClassesPreview: (preview: CertClassesPreviewForUserDto | undefined) => void;
    isFetchingAllMainClassesPreview?: boolean;

    //All scheduled classes

    //Dto
    fetchAllSchedClasses?: () => void;
    isFetchingAllSchedClasses?: boolean;
    postSortFilterAllSchedClasses?: EvaluatorClassDto[];
    setAllSchedClasses?: (classes: EvaluatorClassDto[] | undefined) => void;
    resetAllSchedClassesState?: () => void;

    //Sorting
    allSchedClassesSorting?: SortDirectionEnum;
    setAllSchedClassesSorting?: Dispatch<SetStateAction<SortDirectionEnum>>;

    //Filters
    allSchedClassToShowCountFilter?: number;
    setAllSchedClassToShowCountFilter?: Dispatch<SetStateAction<number>>;
    allSchedClassNameFilter?: string;
    setAllSchedClassNameFilter?: Dispatch<SetStateAction<string>>;

    //All My Classes

    //Dto
    fetchAllMyClasses?: () => void;
    isFetchingAllMyClasses?: boolean;
    postSortFilterAllMyClasses?: TraineeClassInfoDto[];
    setAllMyClasses?: (classes: TraineeClassInfoDto[] | undefined) => void;
    resetAllMyClassesState?: () => void;

    //Sorting
    allMyClassesSorting?: SortDirectionEnum;
    setAllMyClassesSorting?: Dispatch<SetStateAction<SortDirectionEnum>>;

    //Filters
    allMyClassToShowCountFilter?: number;
    setAllMyClassToShowCountFilter?: Dispatch<SetStateAction<number>>;

    allMyClassNameFilter?: string;
    setAllMyClassNameFilter?: Dispatch<SetStateAction<string>>;

    //Trainee Evaluation
    // get
    fetchFullEvaluation?: (inputs: GetCertificationEvaluationResultInputs, setState: React.Dispatch<FullEvaluationDto | undefined>) => void;
    isFetchingFullEvalDto?: boolean;
    fullEvalDtoError?: string;
    // post
    postEvaluationResult?: boolean;
    isAwaitingPostEvaluationResultSubmit: boolean;
    isAwaitingPostEvaluationResultSave?: boolean;
    postEvaluationResults?: (
        evaluationResult: EvaluationResultSaveDto,
        action: EvalResultsSaveOptionsEnum
    ) => Promise<EvaluationResultSaveDto>;
    setRequiredDataValidaitonError?: (error: string | null) => void;
    showError: boolean;
    setShowError?: (showError: boolean) => void;
    resetEvaluationState?: () => void;
}
export const ClassesContext = React.createContext<IClassesContext>({
    isAwaitingPostEvaluationResultSubmit: false,
    showError: false,
    setAllMainClassesPreview: () => null,
});
