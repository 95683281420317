import React, { useState } from "react";
import { Box, Paper, Grid, Button, Typography } from "@material-ui/core";

import { FcrCoachingSkillRating } from "types";
import { DeleteIcon, EditIcon } from "svgComponents";
import RichText from "components/RichText";

import useStyles from "./styles";

export interface SkillRatingsItemProps {
    skillRating: FcrCoachingSkillRating;
    onEdit: (skillRating: FcrCoachingSkillRating) => void;
    onDelete: (skillRating: FcrCoachingSkillRating) => Promise<void>;
}

function SkillRatingPreview({ skillRating, onEdit, onDelete }: SkillRatingsItemProps) {
    const classes = useStyles();
    const { min, max, fcrCoachingSkillRatingLeadingInfos, label } = skillRating;
    const [isDeleting, setDeleting] = useState<boolean>(false);

    const handleDelete = () => {
        setDeleting(true);

        onDelete(skillRating)
            .finally(() => setDeleting(true));
    };

    return (
        <Paper className={classes.skillRatingItem}>
            <Box p={2}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={3}>
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            px={1}
                            minHeight="65px"
                        >
                            <Box
                                display="flex"
                                fontSize="25px"
                                fontWeight={500}
                                lineHeight="38px"
                            >
                                <span>{min}</span>
                                <Box px={1}>-</Box>
                                <span>{max}</span>
                            </Box>
                            {label && <Typography>{label}</Typography>}
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        {!!fcrCoachingSkillRatingLeadingInfos?.length && (
                            <Box>
                                {fcrCoachingSkillRatingLeadingInfos[0].title && (
                                    <Typography variant="body2">
                                        {fcrCoachingSkillRatingLeadingInfos[0].title}
                                    </Typography>
                                )}
                                {fcrCoachingSkillRatingLeadingInfos[0].body && (
                                    <Typography variant="caption">
                                        <RichText text={fcrCoachingSkillRatingLeadingInfos[0].body} />
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            display="flex"
                            alignItems="flex-end"
                            flexDirection="column"
                            p={0}
                        >
                            <Box width="32px" height="32px" display="flex">
                                <Button
                                    className={classes.btn}
                                    variant="text"
                                    size="large"
                                    type="button"
                                    onClick={() => onEdit(skillRating)}
                                >
                                    <EditIcon />
                                </Button>
                            </Box>

                            <Box width="32px" height="32px" display="flex">
                                <Button
                                    className={classes.btn}
                                    variant="text"
                                    size="large"
                                    type="button"
                                    disabled={isDeleting}
                                    onClick={handleDelete}
                                >
                                    <DeleteIcon />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}

export default SkillRatingPreview;
