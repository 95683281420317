import React, { useCallback } from "react";
import clsx from "clsx";
import { Box, Grid, IconButton, Typography, BoxProps } from "@material-ui/core";
import CircleIcon from "components/CircleIcon";
import RichText from "components/RichText";
import { Calendar, EditIcon } from "svgComponents";
import { formatShortDate } from "helpers/dateHelpers";

import { CertificationClassInformation } from "types/Certification";

import useStyles from "./styles";

interface CertificationClassListItemProps {
    certificationClass: CertificationClassInformation;
    icon?: React.ReactNode;
    loading?: boolean;
    bodyProps?: BoxProps;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onEdit?: (e: React.MouseEvent<HTMLElement>) => void;
}

const CertificationClassListItem = ({ certificationClass, icon, loading, bodyProps, onClick, onEdit }: CertificationClassListItemProps) => {
    const classes = useStyles();

    const formatCountItems = useCallback((count: number, label: string) => `${count} ${label}${count === 1 ? "" : "s"}`, []);

    return (
        <Box
            onClick={!loading ? onClick : undefined}
            className={clsx(classes.body, { [classes.bodyInactive]: !certificationClass.isActive })}
            px={4}
            py={2}
            {...(bodyProps ?? {})}>
            <Grid alignItems="center" container spacing={3} wrap="nowrap">
                {icon && (
                    <Grid item>
                        <CircleIcon className={classes.icon}>{icon}</CircleIcon>
                    </Grid>
                )}
                <Grid className={classes.content} item>
                    <Typography variant="body1">
                        <Box component="span" fontWeight={500}>
                            {certificationClass.title}
                        </Box>
                    </Typography>
                    {certificationClass.description && (
                        <Typography component="div" variant="body2">
                            <Box color="#7c8ea3" component="div" mt={0.5}>
                                <RichText text={certificationClass.description} />
                            </Box>
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    <Typography variant="caption">{formatCountItems(certificationClass.numberOfEvaluators, "Evaluator")}</Typography>
                    <br />
                    <Typography variant="caption">{formatCountItems(certificationClass.numberOfTrainees, "Trainee")}</Typography>
                </Grid>
                <Grid item>
                    <CircleIcon>
                        <Calendar />
                    </CircleIcon>
                </Grid>
                <Grid item>
                    <Typography>{formatShortDate(certificationClass.classDate)}</Typography>
                    <Typography variant="caption">Class Date</Typography>
                </Grid>
                <Grid item>
                    <Typography>{formatShortDate(certificationClass.releaseDate)}</Typography>
                    <Typography variant="caption">Release</Typography>
                </Grid>
                <Grid item>
                    <Typography>{formatShortDate(certificationClass.createdDate)}</Typography>
                    <Typography variant="caption">Created</Typography>
                </Grid>
                <Grid className={classes.actions} item>
                    <Grid container spacing={1} wrap="nowrap">
                        {onEdit && (
                            <Grid item>
                                <IconButton aria-label="edit" disabled={loading} onClick={onEdit}>
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CertificationClassListItem;
