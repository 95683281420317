import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    alignRight: {
        marginLeft: "auto",
    },
    bodyText: {
        "& .ql-toolbar, & .ql-container": {
            backgroundColor: "#fff",
        },
    },
    field: {
        "& .MuiInputBase-root": {
            backgroundColor: "#fff",
        },
        "&.MuiOutlinedInput-adornedEnd": {
            paddingRight: 0,
        },
    },
    flexFull: {
        flexGrow: 1,
    },
    form: {
        backgroundColor: theme.colors.structure.pageBackground,
    },
    window: {
        maxWidth: theme.spacing(90),
        [theme.breakpoints.up("md")]: {
            width: theme.spacing(90),
        },
    },
}));

export default useStyles;
