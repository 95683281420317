import React from "react";

import { Box, Paper, Divider, Grid } from "@material-ui/core";

interface WizardPaperProps {
    contentLeft: React.ReactFragment,
    contentRight: React.ReactFragment
}

function WizardPaper({ contentLeft, contentRight }: WizardPaperProps) {
    return (
        <Box pb={5}>
            <Paper>
                <Grid container>
                    <Grid item xs={12} lg={8}>
                        <Box
                            pl={{ xs: 3, sm: 7 }}
                            pr={{ xs: 3, sm: 8 }}
                            pt={{ xs: 3, sm: 6 }}
                            pb={{ xs: 6, sm: 8, lg: 12 }}
                        >
                            {contentLeft}
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4} style={{ background: "rgba(248, 249, 250, 0.5)" }}>
                        <Divider orientation="vertical" flexItem />
                        <Box
                            px={{ xs: 3, sm: 6 }}
                            pt={{ xs: 2.5, sm: 5 }}
                            pb={{ xs: 6, sm: 8, lg: 14 }}
                        >
                            {contentRight}
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default WizardPaper