import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => createStyles({
    encircledIcon: {
        color: theme.palette.text.primary,
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        backgroundColor: theme.colors.structure.pageBackground,
        marginTop: "-4px",
        marginBottom: "-4px",
    },
}));

export default useStyles;
