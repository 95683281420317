import React, { useMemo, useCallback } from "react";
import sortBy from "lodash/sortBy";
import { EVALUATIONS_FILTER_LABEL_DEFAULT } from "types/constants";
import { AutocompleteInputProps } from "components/Form/FormAutocomplete";
import { EvaluationConfiguration, CertificationClassInformation } from "types/Certification";

type ExcludedAutocompleteInputProps = "options" | "isPopupIconFixed" | "getOptionLabel";

type Item = {
    id: string;
    label: string;
};
export interface UseEvaluationsAutocompleteProps<Multiple extends boolean | undefined>
    extends Omit<
        AutocompleteInputProps<EvaluationConfiguration["id"] | CertificationClassInformation["id"], Multiple, false, false>,
        ExcludedAutocompleteInputProps
    > {
    items: Item[];
}

export default function useEvaluationsAutocomplete<Multiple extends boolean | undefined = undefined>({
    label,
    items,
    multiple,
    ...props
}: UseEvaluationsAutocompleteProps<Multiple>) {
    const sortedItems = useMemo(() => sortBy(items, (item) => item.label), [items]);

    const getOptionLabel = useCallback(
        (itemId: EvaluationConfiguration["id"] | CertificationClassInformation["id"]) => {
            const item = sortedItems.find(({ id }) => id === itemId);
            return item ? item.label : "";
        },
        [sortedItems]
    );

    const options = useMemo(() => sortedItems.map(({ id }) => id), [sortedItems]);

    return {
        ...props,
        multiple,
        getOptionLabel,
        options,
        label: label ?? EVALUATIONS_FILTER_LABEL_DEFAULT,
    };
}
