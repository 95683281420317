import React, { useEffect, useState, useMemo, useContext } from "react";
import orderBy from "lodash/orderBy";
import { Box, Typography, Grid, Paper, Divider, CircularProgress, Hidden } from "@material-ui/core";

import BarGraph from "components/BarGraph";
import NoDataMessage from "components/NoDataMessage";
import PaperSection from "components/PaperSection";
import { Calendar } from "svgComponents";
import { getTeamSkillHistoryForCoach, getTeamSkillHistoryForHighLevelLead } from "services/wizardServices";
import { FcrCoachingSkillHistory, UserProfile } from "types";
import { isUnfinishedContentShown, TeamSkillsRouting } from "types/constants";
import { isHighLevel } from "helpers/companyHelper";
import { ConfigurationContext } from "context";
import { getFcrRatingDescription } from "helpers/fcrHelper";

import useStyles from "./styles";

interface SkillsOverviewProps {
    user: UserProfile;
    teamMode: boolean;
    onDataIsFetched: () => void;
}

function SkillsOverview({ user, teamMode, onDataIsFetched }: SkillsOverviewProps) {
    const classes = useStyles();
    const [skills, setSkills] = useState<FcrCoachingSkillHistory[]>();
    const { companyLevels, fcrConfiguration } = useContext(ConfigurationContext)
    const isHighLevelTeamMode = teamMode && isHighLevel(user.level, companyLevels);
    const getRatingDescription = (skill: FcrCoachingSkillHistory) => getFcrRatingDescription(skill?.fcrCoachingSkill, skill.averageScore)?.title || "—"

    useEffect(() => {
        const loadTeamSkillHistory = async (userId: string) => {
            setSkills(undefined);
            const skillHistory = isHighLevelTeamMode
                ? await getTeamSkillHistoryForHighLevelLead(userId)
                : await getTeamSkillHistoryForCoach(userId);
            setSkills(skillHistory);
            onDataIsFetched();
        };

        user.id && loadTeamSkillHistory(user.id);
    }, [user.id, isHighLevelTeamMode, onDataIsFetched]);

    const skillsOrdered = useMemo(() => (
        orderBy(
            skills,
            [({ averageTeamScore }) => averageTeamScore, ({ fcrCoachingSkill }) => fcrCoachingSkill.order],
            ["desc", "asc"]
        )
    ), [skills]);

    return (
        <Box display="flex" justifyContent="center" flexDirection="column" pb={8}>
            <Box pb={5}><Typography variant="h1">Skills Overview</Typography></Box>
            {skills && skills.length && companyLevels.length ? (
                <Grid container spacing={3}>
                    {skillsOrdered.map(skill =>
                        <Grid item key={skill.fcrCoachingSkill.id} lg={6} xs={12}>
                            <PaperSection
                                title={
                                    <Box fontWeight={600}>
                                        <Typography variant="h2">{skill.fcrCoachingSkill.name}</Typography>
                                    </Box>
                                }
                                href={isUnfinishedContentShown && `${isHighLevelTeamMode ? TeamSkillsRouting.highLevel : TeamSkillsRouting.personal}/${user.id}/${skill.fcrCoachingSkill.id}`}
                                label="See Team History"
                                divider={false}
                                height={1}
                            >
                                <Box
                                    px={{ xs: 2, sm: 4 }}
                                    pb={{ xs: 2, sm: 5 }}
                                >
                                    <Paper className={classes.graphPaper}>
                                        <Box
                                            display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between"
                                            px={{ xs: 2, sm: 4 }}
                                            pt={{ xs: 2, sm: 3 }}
                                            pb={{ xs: 2, sm: 5 }}
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection={{ xs: "row", md: "column" }}
                                                justifyContent="space-between"
                                                alignItems={{ xs: "flex-end", sm: "flex-start" }}
                                            >
                                                <Box display="flex" flexDirection="column" mb={8}>
                                                    {!fcrConfiguration?.hideSkillRatingNumbers ?
                                                        <Typography variant="subtitle1">
                                                            {skill.teamResultsScores?.length ? skill.averageTeamScore.toFixed(1) : "—"}
                                                        </Typography> : 
                                                        <Typography variant="subtitle2">{getRatingDescription(skill) }</Typography>
                                                    }
                                                    <Typography variant="body2" className={classes.label}>Avg. Team Score</Typography>
                                                </Box>
                                                <Box display="flex" flexDirection="column">
                                                    <Box display="flex" justifyItems="center" alignItems="center" color="primary.main">
                                                        <Box pr={1}>
                                                            <Typography variant="subtitle2" className={classes.timesCoached}>{skill.timesCoached}</Typography>
                                                        </Box>
                                                        <Calendar width="21px" height="21px" viewBox="0 0 15 15" />
                                                    </Box>
                                                    <Typography variant="body2" className={classes.label}>Times Coached</Typography>
                                                </Box>
                                            </Box>
                                            <Hidden xsDown>
                                                <Box py={1}><Divider /></Box>
                                            </Hidden>
                                            <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="space-between">
                                                <Typography variant="body2" className={classes.label}>
                                                    {skill.teamResultsScores?.length > 0 ? "Last 12 months" : "No previous scores."}
                                                </Typography>
                                                <Box pt={0.5}>
                                                    <BarGraph scores={skill.teamResultsScores} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Box>
                            </PaperSection>
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Box
                    display="flex" justifyContent="center"
                    px={4} pt={3.5} pb={5}
                >
                    {!skills || !companyLevels.length ? (
                        <CircularProgress size={24} />
                    ) : (
                        <NoDataMessage message="No Data" />
                    )}
                </Box>
            )}
        </Box>
    )
}

export default React.memo(SkillsOverview);
