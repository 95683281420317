import * as React from "react";

function SvgRightArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Chevron-/-Right" fill="#253A54">
                    <polygon
                        fill="currentColor"
                        id="Path-2"
                        transform="translate(8.000000, 8.000000) scale(1, -1) rotate(-90.000000) translate(-8.000000, -8.000000) "
                        points="11.8072643 5.50039227 12.5 6.16529832 8.00509673 10.5 3.5 6.16569059 4.19192119 5.5 8.00371047 9.16738563"
                    ></polygon>
                </g>
            </g>
        </svg>
    );
}

export default SvgRightArrow;
