import * as React from "react";

function SvgPreformance(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={19}
            height={20}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#preformance_svg__clip0)" fill="currentColor">
                <path d="M15.55 13.97c-.35 0-.62.28-.62.62v2.19c0 .67-.54 1.21-1.21 1.21H2.46c-.35 0-.62.28-.62.62 0 .34.28.62.62.62h11.26c1.36 0 2.46-1.1 2.46-2.46V14.6c0-.35-.28-.63-.63-.63zM1.25 10.77V2.46c0-.67.54-1.21 1.21-1.21h11.26c.67 0 1.21.54 1.21 1.21 0 .34.28.62.62.62.34 0 .62-.28.62-.62 0-1.36-1.1-2.46-2.46-2.46H2.46C1.1 0 0 1.1 0 2.46v8.31c0 .34.28.62.62.62.34 0 .63-.28.63-.62zM18.01 5.87h-3.2c-.35 0-.62.28-.62.62 0 .34.28.62.62.62h1.67l-4 4.06L9.97 8.7a.623.623 0 00-.81-.06L.53 15.1c-.28.21-.33.6-.13.87a.63.63 0 00.87.13l8.19-6.13 2.56 2.54c.12.12.28.18.44.18.17-.01.33-.07.44-.19l4.46-4.53V9.7c0 .34.28.62.62.62.34 0 .62-.28.62-.62V6.5a.583.583 0 00-.59-.63z" />
            </g>
            <defs>
                <clipPath id="preformance_svg__clip0">
                    <path fill="currentColor" d="M0 0h18.63v19.25H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgPreformance;
