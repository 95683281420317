import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Button, Tooltip } from "@material-ui/core";

import { EvaluationsAutocomplete } from "components/EvaluationsAutocomplete";

import { CertificationUser } from "types/Certification";
import { CertificationClassEntity } from "types/constants";

import MultiselectUserList from "../MultiselectUserList";

interface ClassUsersColumnProps {
    title: string;
    entity: CertificationClassEntity;
    users: CertificationUser[];
    filteredByLevelUsers?: CertificationUser[];
    checked: string[];
    setChecked: (ids: string[]) => void;
    onFilterChanged: () => void;
}

export default function ClassUsersColumn({
    title,
    entity,
    users,
    filteredByLevelUsers,
    checked,
    setChecked,
    onFilterChanged,
}: ClassUsersColumnProps) {
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [filtredUsers, setFiltredUsers] = useState<CertificationUser[]>(filteredByLevelUsers ?? users);

    const handleUserFilter = useCallback(
        (userId: CertificationUser["id"] | null) => {
            const filtred = userId ? (filteredByLevelUsers ?? users).filter(({ id }) => id === userId) : filteredByLevelUsers ?? users;
            setFiltredUsers(filtred);
            setSelectedUserId(userId);
        },
        [filteredByLevelUsers, users]
    );

    const handleToggle = useCallback(
        (userId: string) => {
            if (checked.includes(userId)) {
                const userIds = checked.filter((id) => id !== userId);
                setChecked(userIds);
            } else {
                setChecked([...checked, userId]);
            }
        },
        [checked, setChecked]
    );

    const handleToggleAll = useCallback(() => {
        setChecked(checked.length === filtredUsers.length ? [] : filtredUsers.map(({ id }) => id));
    }, [checked.length, setChecked, filtredUsers]);

    useEffect(() => {
        handleUserFilter(selectedUserId);
    }, [handleUserFilter, selectedUserId]);

    useEffect(() => {
        setSelectedUserId(null);
    }, [filteredByLevelUsers]);

    return (
        <>
            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
                <Typography noWrap>{title}</Typography>
            </Box>
            <Box mb={1}>
                <EvaluationsAutocomplete
                    label={`Select ${entity}s`}
                    items={users.map(({ id, firstName, lastName }) => ({
                        id,
                        label: `${firstName} ${lastName}`,
                    }))}
                    value={selectedUserId}
                    onChange={handleUserFilter}
                    onClickCapture={onFilterChanged}
                />
            </Box>
            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
                <Typography color="textSecondary" variant="body1">{`${filtredUsers.length} ${
                    filtredUsers.length === 1 ? "user" : "users"
                }`}</Typography>
                <Tooltip arrow placement="top" title="When using Select All, this will only select those that are visible in the list.">
                    <Button color="primary" onClick={handleToggleAll}>
                        <Box whiteSpace="nowrap">Select all</Box>
                    </Button>
                </Tooltip>
            </Box>
            <MultiselectUserList users={filtredUsers} checked={checked} onToggle={handleToggle} />
        </>
    );
}
