import React from "react";
import { Box, Typography, Paper, CircularProgress } from "@material-ui/core";
import { UserProfile, CompanyLevel } from "types";

import TransferList from "./TransferList";
import useStyles from "./styles";

interface AssignTeamMembersProps {
    membersLevelIds: Array<CompanyLevel["id"]>;
    distributionMembers: UserProfile[] | null;
    potentialMembers?: UserProfile[];
    companyLevels: CompanyLevel[];
    setUserIds: (ids: UserProfile[]) => void;
    description?: string | React.ReactNode;
    leftTitle?: string;
    rightTitle?: string;
}

function AssignTeamMembers({
    membersLevelIds,
    distributionMembers,
    companyLevels,
    setUserIds,
    description,
    leftTitle,
    rightTitle,
    potentialMembers,
}: AssignTeamMembersProps) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Box display="flex" flexDirection="column" py={2} px={3}>
                <Box mb={2} fontWeight={600}>
                    <Typography variant="h3">Assign Team Members</Typography>
                </Box>
                {description}
                {distributionMembers ?
                    <TransferList
                        leftTitle={leftTitle}
                        rightTitle={rightTitle}
                        setUserIds={setUserIds}
                        membersLevelIds={membersLevelIds}
                        distributionMembers={distributionMembers}
                        companyLevels={companyLevels}
                        potentialMembers={potentialMembers}
                    />
                    : <Box display="flex" justifyContent="center" py={10}>
                        <CircularProgress size={24} />
                    </Box>
                }
            </Box>
        </Paper>
    );
}

export default AssignTeamMembers;