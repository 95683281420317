import { v4 as uuidv4 } from "uuid";
import { FcrCoachingSkill, FcrCoachingSkillRating } from "types";

export const getSkillFormData = (skill: FcrCoachingSkill | null, userId: string, companyId: string): FcrCoachingSkill => ({
    id: skill?.id ?? uuidv4(),
    companyId: skill?.companyId ?? companyId,
    isActive: skill?.isActive ?? true,
    name: skill?.name ?? "",
    title: skill?.title ?? "",
    description: skill?.description ?? "",
    order: skill?.order ?? 0,
    minRating: skill?.minRating ?? 0,
    maxRating: skill?.maxRating ?? 0,
    fcrCoachingSkillRatings: skill?.fcrCoachingSkillRatings ?? [],
    createdById: skill?.createdById ?? userId,
    createdDate: skill?.createdDate ?? (new Date).toISOString(),
    modifiedById: userId,
    modifiedDate: (new Date).toISOString(),
    fcrCoachingSkillCategory: skill?.fcrCoachingSkillCategory ?? undefined,
});

export const getSkillRatingFormData = (skillRating: FcrCoachingSkillRating | null, skillId: string): FcrCoachingSkillRating => {
    const skillRatingId = skillRating?.id ?? uuidv4();

    return {
        id: skillRatingId,
        fcrCoachingSKillId: skillRating?.fcrCoachingSKillId ?? skillId,
        min: skillRating?.min ?? 0,
        max: skillRating?.max ?? 0,
        label: skillRating?.label ?? "",
        fcrCoachingSkillRatingLeadingInfos: skillRating?.fcrCoachingSkillRatingLeadingInfos ?? [{
            id: uuidv4(),
            fcrCoachingSkillRatingId: skillRatingId,
            title: "",
            body: "",
            order: 0,
        }],
    };
};
