import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";

import CircleIcon from "components/CircleIcon";
import { formatShortDate } from "helpers/dateHelpers";
import { Calendar } from "svgComponents";
import { Routings } from "types/constants";

import { EvaluatorClassDto, EvaluatorClassDtoProps } from "types/Certification";

import * as CoachingSessionTypeIcons from "svgComponents/CoachingSessionTypeIcons";

import IconPeople from "svgComponents/IconPeople";
import IconPersonCheck from "svgComponents/IconPersonCheck";

import IconReportAnalytics from "svgComponents/ReportAnalytics";

import useStyles from "./styles";

export interface ScheduledClassItemProps {
    scheduledClass: EvaluatorClassDto;
}

function ScheduledClassItem({ scheduledClass }: ScheduledClassItemProps) {
    const classes = useStyles();
    const linkToEvaluation = `${Routings.classes}/${scheduledClass.id}`;

    return (
        <Link to={linkToEvaluation} className={classes.link}>
            <div className={classes.classItemWrap}>
                <Grid container spacing={2} className={clsx(classes.classItem)}>
                    <Grid xs={12} sm={6} lg={2} className={classes.classItemCell}>
                        <CircleIcon>
                            <Calendar />
                        </CircleIcon>
                        <div className={classes.classItemCellText}>
                            {scheduledClass.classDate ? (
                                <div className={classes.bold}>
                                    <Typography variant="h2">{formatShortDate(new Date(scheduledClass.classDate))}</Typography>
                                </div>
                            ) : (
                                <Typography variant="h2">-</Typography>
                            )}
                        </div>
                    </Grid>
                    <Grid xs={12} sm={6} lg={2} className={classes.classItemCell}>
                        <CircleIcon>
                            <CoachingSessionTypeIcons.BusinessPlanningIcon />
                        </CircleIcon>
                        <div className={classes.classItemCellTextBold}>
                            <Typography variant="h2">{scheduledClass.title}</Typography>
                            <Typography variant="caption">Class</Typography>
                        </div>
                    </Grid>
                    <Grid item xs className={clsx([classes.classItemCell, classes.classItemJustIcon])}>
                        {scheduledClass.allowSelfEvaluation ? (
                            <CircleIcon>
                                <IconPersonCheck />
                            </CircleIcon>
                        ) : null}
                    </Grid>
                    <Grid xs={12} sm={6} lg={2} className={classes.classItemCell}>
                        <CircleIcon>
                            <IconPeople />
                        </CircleIcon>
                        <div className={classes.classItemCellTextBold}>
                            <Typography variant="h2">{scheduledClass.numberOfTrainees}</Typography>
                            <Typography variant="caption">Members</Typography>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={6} lg={2} className={classes.classItemCell}>
                        <CircleIcon>
                            <IconPeople />
                        </CircleIcon>
                        <div className={classes.classItemCellTextBold}>
                            <Typography variant="h2">{scheduledClass.numberOfCompleteEvaluations}</Typography>
                            <Typography variant="caption">Complete</Typography>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={6} lg={2} className={classes.classItemCell}>
                        <CircleIcon>
                            <IconPeople />
                        </CircleIcon>
                        <div className={classes.classItemCellTextBold}>
                            <Typography variant="h2">{scheduledClass.numberOfIncompleteEvaluations}</Typography>
                            <Typography variant="caption">Not Complete</Typography>
                        </div>
                    </Grid>

                    <Grid xs={12} sm={6} lg={1} className={classes.classItemReportCell}>
                        {scheduledClass.reportingUrl && (
                            <Link
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                to={{ pathname: scheduledClass.reportingUrl }}
                                target={"_blank"}>
                                <CircleIcon>
                                    <IconReportAnalytics />
                                </CircleIcon>
                            </Link>
                        )}
                    </Grid>
                </Grid>
            </div>
        </Link>
    );
}

export default React.memo(ScheduledClassItem);
