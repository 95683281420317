import {
    CertClassesPreviewForUserDto,
    CertificationClass,
    CertificationClassEvaluationAssignment,
    CertificationClassEvaluationAssignments,
    CertificationClassInformation,
    CertificationClassRoster,
    CertificationClassUser,
    CertificationUser,
    EvaluationConfiguration,
    EvaluationItemConfiguration,
    EvaluatorClassDto,
    TraineeClassInfoDto,
    TraineeClassInfoDtoProps,
    ClassTraineesInformation,
    ClassTraineeList,
} from "types/Certification";
import { NULL_GUID } from "types/constants";
import { EvaluationResultSaveDto, FullEvaluationDto } from "types/Evaluation.dto";

import API from "./apiService";

export const getEvaluationConfigurationForCompany = async (companyId: string): Promise<EvaluationConfiguration[]> => {
    const response = await API.get(`certification/evaluationConfiguration/getForCompany/${companyId}`);
    const data: EvaluationConfiguration[] = response.data.map((item: EvaluationConfiguration) => ({ ...item, label: item.name }));
    return data;
};

export const getEvaluationById = async (evaluationId: string): Promise<EvaluationConfiguration> => {
    const response = await API.get(`certification/evaluationConfiguration/getFull/${evaluationId}`);
    return response.data;
};

export const saveEvaluationConfiguration = async (
    payload: { [key in keyof EvaluationConfiguration]?: unknown }
): Promise<EvaluationConfiguration> => {
    const response = await API.post(`certification/EvaluationConfiguration/${payload.id}`, payload);
    return response.data;
};

export const deleteEvaluationConfiguration = async (evaluationId: EvaluationConfiguration["id"]): Promise<EvaluationConfiguration> => {
    const response = await API.delete(`certification/EvaluationConfiguration/${evaluationId}`);
    return response.data;
};

export const saveEvaluationItemConfiguration = async (
    payload: { [key in keyof EvaluationItemConfiguration]?: unknown }
): Promise<EvaluationItemConfiguration> => {
    const response = await API.post(`certification/EvaluationConfiguration/Item/${payload.id}`, payload);
    return response.data;
};

export const deleteEvaluationItemConfiguration = async (id: EvaluationItemConfiguration["id"]): Promise<EvaluationItemConfiguration> => {
    const response = await API.delete(`certification/EvaluationConfiguration/Item/${id}`);
    return response.data;
};

export const copyEvaluationConfiguration = async (evaluationId: string): Promise<EvaluationConfiguration> => {
    const response = await API.post(`certification/EvaluationConfiguration/copy/${evaluationId}`, {});
    return response.data;
};

// Certification Classes
export const getClassesForCompany = async (companyId: string): Promise<CertificationClassInformation[]> => {
    const response = await API.get(`certification/class/getForCompany/${companyId}`);
    const data: CertificationClassInformation[] = response.data.map((item: CertificationClassInformation) => ({
        ...item,
        label: item.title,
    }));
    return data;
};

export const getClassById = async (classId: string): Promise<CertificationClass> => {
    const response = await API.get(`certification/class/${classId}`);
    return response.data;
};

export const getEvaluationAssignmentsByClassId = async (classId: string): Promise<CertificationClassEvaluationAssignment[]> => {
    const response = await API.get(`certification/class/GetEvaluationConfigurations/${classId}`);
    return response.data;
};

export const getClassRosterByClassId = async (classId: string): Promise<CertificationClassUser[]> => {
    const response = await API.get(`certification/class/GetRoster/${classId}`);
    return response.data;
};

export const getClassEvaluatorsByСompanyId = async (companyId: string): Promise<CertificationUser[]> => {
    const response = await API.get(`certification/class/GetEvaluators/${companyId}`);
    return response.data;
};

export const getClassTraineesByСompanyId = async (companyId: string): Promise<CertificationUser[]> => {
    const response = await API.get(`certification/class/GetTrainees/${companyId}`);
    return response.data;
};

export const saveClass = async (payload: { [key in keyof CertificationClass]?: unknown }): Promise<CertificationClass> => {
    const response = await API.post(`certification/class/${payload.id}`, payload);
    return response.data;
};

export const updateEvaluationAssignments = async (
    payload: CertificationClassEvaluationAssignments
): Promise<CertificationClassEvaluationAssignments> => {
    const response = await API.post("certification/class/UpdateEvaluationConfigurationAssignments", payload);
    return response.data;
};

export const updateClassRoster = async (payload: CertificationClassRoster): Promise<CertificationClass> => {
    const response = await API.post("certification/class/UpdateRoster", payload);
    return response.data;
};

export const copyClass = async (classId: string): Promise<CertificationClass> => {
    const response = await API.post(`certification/class/copy/${classId}`, {});
    return response.data;
};

export const getClassesForCompanyByEvaluator = async (evaluatorId: string): Promise<Array<EvaluatorClassDto>> => {
    const response = await API.get(`certification/class/GetForEvaluator/${evaluatorId}`);
    return response.data;
};

export const getClassesForCompanyByTrainee = async (traineeId: string): Promise<Array<TraineeClassInfoDto>> => {
    const response = await API.get(`/certification/class/GetForTrainee/${traineeId}`);
    return response.data;
};

export const getCertificationClassesPreviewForUser = async (
    userId: string,
    numberOfPreviewItems: number
): Promise<CertClassesPreviewForUserDto> => {
    const response = await API.get<CertClassesPreviewForUserDto>(
        `certification/class/getForUser/${userId}?numberOfRecordsToReturn=${numberOfPreviewItems}`
    );
    return response.data;
};

export type GetCertificationEvaluationResultInputs = {
    evaluationResultId: string | null;
    evaluationConfigurationId: string | null;
    classId: string;
    traineeId: string;
    evaluatorId: string;
};

export const getCertificationEvaluationResultFull = async ({
    evaluationResultId,
    evaluationConfigurationId,
    classId,
    traineeId,
    evaluatorId,
}: GetCertificationEvaluationResultInputs): Promise<FullEvaluationDto> => {
    const params = {
        evaluationConfigurationId,
        traineeId,
        classId,
        evaluatorId,
    };

    const conditionalParams = evaluationConfigurationId !== "null" ? params : { traineeId, classId, evaluatorId };
    const response =
        evaluationResultId !== NULL_GUID
            ? await API.get<FullEvaluationDto>(`certification/EvaluationResult/GetFull/${evaluationResultId}`)
            : await API.get<FullEvaluationDto>(`certification/EvaluationResult/GetFull/${evaluationResultId}/`, {
                  params: conditionalParams,
              });

    return response.data;
};

export const postEvaluationResultSaveDto = async (evaluationResultSaveDto: EvaluationResultSaveDto): Promise<EvaluationResultSaveDto> => {
    const result = await API.post(`certification/evaluationresult/${evaluationResultSaveDto.id}`, evaluationResultSaveDto);
    return result.data;
};

export const getClassTraineesInformation = async (classId: string): Promise<ClassTraineesInformation> => {
    const response = await API.get(`certification/class/GetClassTraineesInformation/${classId}`);
    return response.data;
};

export const getClassTraineeList = async (classId: string, pageNumber = 1, recordsAmount = 150): Promise<ClassTraineeList> => {
    const response = await API.get(`certification/class/GetClassTraineeList/${classId}`, {
        params: {
            pageNumber,
            recordsAmount,
        },
    });
    return response.data;
};

export const resendEmailForEvaluationResult = async (evaluationResultId: string): Promise<boolean> => {
    const result = await API.post(`certification/EvaluationResult/${evaluationResultId}/email`);
    return result.data;
};

export const sendCertificationClassEmail = async (classId: string): Promise<unknown> => new Promise((resolve, reject) => {
    API.post(`certification/class/sendEmail/${classId}`)
        .then((result) => {
            if (result?.data?.errors) return reject();
            resolve(result);
        })
        .catch(reject);
});