import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    alignRight: {
        marginLeft: "auto",
    },
    body: {
        marginTop: theme.spacing(5),
    },
}));

export default useStyles;
