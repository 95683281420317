import { useContext, useEffect, useRef } from "react";
import { ScrollContext } from "context";

import useLocationStateParam from "./useLocationStateParam";

const HEADER_HEIGHT = 65;

export default function useScrollToItem<T extends { id: string }>(
    items: T[],
    idParamName: string,
    itemsRef: React.RefObject<HTMLDivElement>,
    shownCount: number,
    setShownCount: (shownCount: number) => void
) {
    const { current: initialShownCount } = useRef<number>(shownCount);
    const isScrolled = useRef<boolean>(false);
    const idParam = useLocationStateParam(idParamName);
    const { scrollContainerRef } = useContext(ScrollContext);

    const index = items.findIndex((item) => item.id === idParam);
    
    useEffect(() => {
        if (index < 0) return;
        
        if (index + 1 + initialShownCount > shownCount) {
            setShownCount(index + 1 + shownCount);
        }
    }, [index, initialShownCount, shownCount, setShownCount]);

    useEffect(() => {
        if (index < 0 || !scrollContainerRef.current || !itemsRef.current?.children[index]) return;

        if (shownCount >= index + 1 + initialShownCount && !isScrolled.current) {
            scrollContainerRef.current.scroll({
                top: (itemsRef.current.children[index] as HTMLDivElement).offsetTop - HEADER_HEIGHT,
                behavior: "smooth",
            });
            isScrolled.current = true;
        }
    }, [index, initialShownCount, shownCount, itemsRef, scrollContainerRef]);
}