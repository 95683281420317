import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { ChevronRight } from "svgComponents";
import { CompanyLevel } from "types";
import { buildLevelHierarchyObjectsToLevel, COMPANY_LEVELS, LevelHierarchy } from "helpers/companyHelper";

import useStyles from "./styles";

interface LevelHierarchyProps {
    companyLevelId?: string | null;
    companyLevels: CompanyLevel[];
    customClasses?: { [key: string]: string };
}

export default function LevelHierarchyComponent({ companyLevelId, companyLevels, customClasses }: LevelHierarchyProps) {
    const classes = useStyles();

    const lvlClassName = customClasses?.lvl || classes.lvl;
    const lvlArrowClassName = customClasses?.lvlArrow || classes.lvlArrow;
    const lvlLabelClassName = customClasses?.lvlLabel || classes.lvlLabel;

    const levelHierarchy: LevelHierarchy<CompanyLevel | null> = useMemo(() => (
        companyLevelId ? buildLevelHierarchyObjectsToLevel(companyLevelId, companyLevels) : {}
    ), [companyLevelId, companyLevels]);

    return (
        <>
            {COMPANY_LEVELS.map((level, idx) => levelHierarchy[level] && (
                <Grid key={idx} className={lvlClassName} item>
                    <Box pl={2} position="relative">
                        {idx > 0 && (
                            <Box className={lvlArrowClassName}>
                                <ChevronRight height="12px" width="12px" />
                            </Box>
                        )}
                        <Typography className={lvlLabelClassName} variant="body1">
                            {levelHierarchy[level]?.name}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </>
    );
}