import React, { useCallback, useState, useContext, useMemo } from "react";
import { Popover, Box, Typography, Button, ButtonBase, CircularProgress } from "@material-ui/core";
import Input from "components/Input";
import { CloseCross } from "svgComponents";
import { FcrResource, FcrResourceCategory } from "types";
import { v4 as uuidv4 } from "uuid";
import { ResourceTypeEnum, ValidationEnum } from "types/constants";
import { ConfigurationContext } from "context";

interface AddLinkModalProps {
    closeModal: () => void;
    anchorEl: HTMLElement | null;
    saveResources: (resources: Array<FcrResource>) => Promise<void>;
    updateResource: (resources: FcrResource) => Promise<void>;
    resourceForEdit?: FcrResource;
    defaultCategory?: FcrResourceCategory;
}

function AddLinkModal({ saveResources, updateResource, closeModal, anchorEl, resourceForEdit, defaultCategory }: AddLinkModalProps) {
    const { currentUser } = useContext(ConfigurationContext);

    const [isLoading, setIsLoading] = useState(false);
    const [resource, setResource] = useState<FcrResource>(
        resourceForEdit ||
        {
            id: uuidv4(),
            name: "",
            url: "",
            type: ResourceTypeEnum.link,
            contentType: "link",
            createdById: currentUser?.id || "",
            modifiedById: currentUser?.id || "",
            companyId: currentUser?.companyId || "",
            documentBytes: null,
        }
    );
    const [error, setError] = useState(false);

    const onNameChange = useCallback((value: string) => setResource(prevResource => ({ ...prevResource, name: value })), []);
    const onUrlChange = useCallback((value: string, isError: boolean | undefined) => {
        setError(!!isError);
        setResource(prevResource => ({ ...prevResource, url: value }));
    }, []);

    const saveLinkResource = useCallback(async () => {
        setIsLoading(true);
        resourceForEdit ?
            await updateResource(resource) :
            await saveResources([{
                ...resource,
                resourceCategoryId: defaultCategory?.id,
            }]);
        closeModal();
        setIsLoading(false);
    }, [resourceForEdit, resource, closeModal, updateResource, saveResources, defaultCategory]);

    const buttonText = useMemo(() => resourceForEdit ? "Edit Link" : "Add Link", [resourceForEdit]);

    return (
        <Popover
            open={true}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        >
            <Box px={4} pt={3} pb={5}>
                <Box fontWeight="600" pb={1}
                    display="flex" justifyContent="space-between"
                >
                    <Typography variant="h2">Add Link Resource</Typography>
                    <ButtonBase
                        aria-label="close"
                        onClick={closeModal}
                        disableRipple
                    >
                        <CloseCross />
                    </ButtonBase>
                </Box>
                <Typography variant="h3">Add a resource by providing a link to the file or webpage.</Typography>
                <Box pt={3.5}>
                    <Box pb={1} fontWeight="600">
                        <Typography>Text to display</Typography>
                    </Box>
                    <Input
                        placeholder="Text goes here..."
                        defaultValue={resource.name}
                        onChangeWithError={onNameChange} />
                </Box>
                <Box pt={2.5}>
                    <Box pb={1} fontWeight="600">
                        <Typography>URL</Typography>
                    </Box>
                    <Input
                        placeholder="http://..."
                        errorMessage="Enter a valid URL"
                        validationType={ValidationEnum.url}
                        defaultValue={resource.url}
                        onChangeWithError={onUrlChange}
                    />
                </Box>
                <Box pt={5}>
                    <Button
                        disabled={!(resource.name && resource.url) || error || isLoading}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={saveLinkResource}
                    >
                        {isLoading ?
                            <CircularProgress size={24} /> :
                            buttonText
                        }
                    </Button>
                </Box>
            </Box>
        </Popover>
    )
}

export default AddLinkModal;
