import { makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
    circleIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.text.primary,
        backgroundColor: theme.colors.structure.pageBackground,
        borderRadius: "50%",
    },
}));
