import React, { useMemo, useContext } from "react";
import clsx from "clsx";
import { Avatar, BoxProps } from "@material-ui/core";
import { ConfigurationContext } from "context";

import { SizeEnum } from "types/constants";
import { UserProfile, UserProfileOverview } from "types";
import { getAcronym } from "helpers/stringHelpers";

import ScoreBar, { ScoreBarMode } from "./ScoreBar";
import useStyles from "./styles";

interface AvatarWithScoreProps extends BoxProps {
    user: UserProfile;
    overview?: UserProfileOverview | null;
    score?: number;
    scoreCaption?: string;
    size?: SizeEnum;
    scoreMode?: ScoreBarMode;
    displayNumericalScore?: boolean
}

const AvatarWithScore = ({ user, overview, score, scoreCaption, size = SizeEnum.large, scoreMode, displayNumericalScore = false, ...props }: AvatarWithScoreProps) => {
    const { fcrConfiguration } = useContext(ConfigurationContext);
    const { id, avatar } = user;
    const { firstName, lastName, minRating, maxRating } = overview ?? user;
    const classes = useStyles({ id });
    const userName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);
    const shortName = useMemo(() => getAcronym(userName), [userName]);
    const isDisplayNumericalScore = useMemo(() => displayNumericalScore || (!(fcrConfiguration?.hideSkillRatingNumbers ?? true) && size === SizeEnum.large), [displayNumericalScore, fcrConfiguration?.hideSkillRatingNumbers, size]);

    return (
        <div {...props}>
            {!!score ?
                <ScoreBar
                    score={score}
                    scoreCaption={scoreCaption}
                    size={size}
                    displayNumericalScore={isDisplayNumericalScore}
                    minRating={minRating}
                    maxRating={maxRating}
                    mode={scoreMode}
                >
                    <div className={classes.avatarWrapper}>
                        <Avatar alt={userName} src={avatar} className={clsx(classes.avatar, classes[size])}>
                            {shortName}
                        </Avatar>
                    </div>
                </ScoreBar>: 
                <div className={classes.avatarWrapper}>
                    <Avatar alt={userName} src={avatar} className={clsx(classes.avatar, classes[size])}>
                        {shortName}
                    </Avatar>
                </div>
            }
        </div>
    );
};

export default React.memo(AvatarWithScore);