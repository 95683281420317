import React from "react";
import SvgPlans from "svgComponents/Plans";
import clsx from "clsx";

import useStyles from "./styles";

const PlansIcon = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
    const classes = useStyles()

    return <SvgPlans className={clsx(classes.root, className)} {...props} />
} 

export default PlansIcon;
