import React, { useCallback, useMemo, useRef } from "react";
import { Box, Button, IconButton, Link, Typography } from "@material-ui/core";

import DeleteIcon from "svgComponents/DeleteIcon";
import { toBase64 } from "helpers/fileHelper";

import useStyles from "./styles";

interface FileUploaderProps {
    initialFilename?: string;
    initialUrl?: string;
    file?: File;
    btnText?: string;
    btnVariant?: "contained" | "text" | "outlined";
    allowedExtensions?: string;
    onChange: (file?: File, fileBase64?: string) => void;
}

const FileUploader = ({
    initialFilename,
    initialUrl,
    file,
    btnText = "Upload File",
    btnVariant = "contained",
    allowedExtensions = ".pdf,.doc,.docx",
    onChange,
}: FileUploaderProps) => {
    const classes = useStyles();
    const ref = useRef<HTMLInputElement>(null);

    const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const { base64 } = await toBase64(file);
            base64 && onChange(file, base64);
        }
    }, [onChange]);

    const handleClear = useCallback(() => {
        if (ref.current) ref.current.value = "";
        onChange();
    }, [onChange]);

    const url = useMemo(() => file ? URL.createObjectURL(file) : initialUrl, [file, initialUrl]);
    const filename = useMemo(() => file ? file.name : initialFilename, [file, initialFilename]);

    return (
        <>
            <Box display="flex" alignItems="center" minWidth={0}>
                <Box flexShrink={0}>
                    <Button
                        variant={btnVariant}
                        color="primary"
                        component="label"
                        disableElevation
                    >
                        <Box component="span" lineHeight="30px">{btnText}</Box>
                        <input
                            ref={ref}
                            type="file"
                            accept={allowedExtensions}
                            onChange={handleChange}
                            hidden
                        />
                    </Button>
                </Box>
                <Box ml={3}>
                    {url ? (
                        <Link
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                        >
                            <Typography className={classes.fileName} variant="body1">
                                {filename}
                            </Typography>
                        </Link>
                    ) : (
                        <Typography className={classes.fileName} variant="body1">
                            {filename}
                        </Typography>
                    )}

                </Box>
            </Box>
            {file && (
                <Box ml={3}>
                    <IconButton color="secondary" onClick={handleClear}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )}
        </>
    );
};

export default FileUploader;