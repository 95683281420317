import React, { useCallback, useContext, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { Box, Button, Typography } from "@material-ui/core";
import {
    CoachingPlanType,
    CoachingSession,
    FcrCoachingSkill,
    FcrCoachingSkillObservation,
    FcrResource,
} from "types";
import { CoachingPlanStatusEnum } from "types/constants";
import { ConfigurationContext } from "context";

import CoachingPlanData from "./CoachingPlanData";

interface LeftSideProps {
    fcrCoachingSkills: FcrCoachingSkill[];
    coachingPlans: Array<CoachingPlanType>,
    fcrCoachingSkillObservation: FcrCoachingSkillObservation,
    fcrResultId?: string,
    onChange: (cpId: string, field: string, value: string | number | boolean | FcrResource[] ) => void;
    addNewCoachingPlan: (observationCoachingPlan: CoachingPlanType) => void;
    coachingSession: CoachingSession;
}

function LeftSide({
    fcrCoachingSkills,
    coachingPlans,
    fcrCoachingSkillObservation,
    fcrResultId,
    onChange,
    addNewCoachingPlan,
    coachingSession,
}: LeftSideProps) {
    const { currentUser } = useContext(ConfigurationContext);

    const fcrCoachingSkill = useMemo(
        () => fcrCoachingSkills.find(e => e.id === fcrCoachingSkillObservation.fcrCoachingSkillId) || {} as FcrCoachingSkill,
        [fcrCoachingSkillObservation.fcrCoachingSkillId, fcrCoachingSkills]
    );

    const handleAddPlan = useCallback(() => {
        const newCoachingPlan: CoachingPlanType = {
            assignedDate: null,
            coachingPlanData: "",
            completedDate: null,
            createdDate: (new Date).toISOString(),
            dueDate: (new Date).toISOString(),
            fcrCoachingPlanStatusId: CoachingPlanStatusEnum.assigned,
            fcrCoachingSkillName: fcrCoachingSkill.title,
            fcrCoachingSkillResultId: fcrCoachingSkillObservation.id,
            fcrResultId: fcrResultId ?? fcrCoachingSkillObservation.fcrResultId,
            id: uuidv4(),
            inProgressDate: null,
            modifiedDate: (new Date).toISOString(),
            reminderDate: null,
            resources: [],
            sendReminder: false,
            createdById: currentUser!.id,
            modifiedById: currentUser!.id,
            coachingSessionId: coachingSession.id,
        };
        addNewCoachingPlan(newCoachingPlan);
    }, [
        addNewCoachingPlan, currentUser, fcrCoachingSkill.title, coachingSession,
        fcrCoachingSkillObservation.fcrResultId, fcrCoachingSkillObservation.id, fcrResultId,
    ]);

    return (
        <Box color="primary.main">
            <Box pb={coachingPlans.length ? 3 : 5}>
                <Typography variant="h1">
                    {fcrCoachingSkill.title}
                </Typography>
            </Box>
            {
                coachingPlans.map(coachingPlan => (
                    <CoachingPlanData key={coachingPlan.id} coachingPlan={coachingPlan} assigneeId={coachingSession.assigneeId} onChange={onChange} />
                ))
            }
            <Box>
                <Button variant="outlined" size="large" color="inherit" onClick={handleAddPlan}>
                    Add Coaching Plan
                </Button>
            </Box>
        </Box>
    );
}

export default LeftSide;
