import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { BAR_WIDTH } from "types/constants";

const useStyles = makeStyles((theme: Theme) => createStyles({
    app: {
        ...theme.text as React.CSSProperties,
        backgroundColor: theme.colors.structure.pageBackground,
        overflowX: "auto",
        height: "100vh",
    },
    main: {
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up("xs")]: {
            minWidth: "100vw",
        },
        [theme.breakpoints.up("md")]: {
            minWidth: "720px",
        },
    },
    mainOpened: {
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingLeft: BAR_WIDTH,
    },
}));

export default useStyles;
