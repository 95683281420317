export const BAR_WIDTH = 255;
export const UI_FLICKER_DELAY = 1000;
export const HEADER_HEIGHT = 65;

export const AUTH_STATE_CHANGE_ACTION_KEY = "AUTH_STATE_CHANGE_ACTION_KEY";

export enum CrossBrowserStateIndicator {
    LoggingOut = "loggingOut",
    FullyAuthenticated = "fullyAuthed",
    Unknown = "unknown"
}

export const SESSION_STORAGE_INITIAL_REQUEST = "SESSION_STORAGE_INITIAL_REQUEST";


export const LOCAL_STORAGE_AUTH_SESSION_EXPIRES_AT = "LOCAL_STORAGE_AUTH_SESSION_EXPIRES_AT";
export const LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW = "LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW";
export const LOCAL_STORAGE_LAST_SELECTED_COMPANY = "LOCAL_STORAGE_LAST_SELECTED_COMPANY";
export const LOCAL_STORAGE_EMAIL = "LOCAL_STORAGE_EMAIL";
export const LOCAL_STORAGE_SCOPE = "LOCAL_STORAGE_SCOPE";

export const LEAVE_PAGE_CONFIRMATION = "You have unsaved changes.\nAre you sure you want to leave this page?";

export const LEVEL_FILTER_TITLE = "Filter";
export const LEVEL_FILTER_CAPTION = "Filter by level to view team member availability and assignments.";

export const USERS_FILTER_LABEL = "Search all members";
export const TEAM_USERS_FILTER_LABEL = "Search all teams";
export const EVALUATIONS_FILTER_LABEL_DEFAULT = "Search all items";

export const REGEX = {
    URL: /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?((?:\/\w+)|(?:-\w+))*\/?(?![^<]*(?:<\/\w+>|\/?>))$/gm,
};

export enum SizeEnum {
    xs = "xs",
    small = "small",
    mediumSmaller = "mediumSmaller",
    mediumSmall = "mediumSmall",
    medium = "medium",
    large = "large",
}

export enum ValidationEnum {
    url = "url",
}

export enum StatusEnum {
    assigned = "assigned",
    completed = "completed",
    inProgress = "inProgress",
}

export enum AuthProviderEnum {
    // OpenId = "OpenId",//This won't be an option going forward, it'll always be B2C, remove this concept from the codebase
    B2C = "Default",
}

export enum Routings {

    //Auth Routes
    userNotFound = "/userNotFound",
    signedout = "/signedout",
    signin = "/signin",

    //App Routes
    dashboard = "/",
    coaching = "/coaching",
    teamDevelopment = "/team-development",
    skills = "/skills",
    teamProfile = "/team-profile",
    sessions = "/sessions",
    reports = "/coaching-reports",
    allReports = "/all-coaching-reports",
    messages = "/messages",
    allMessages = "/all-messages",
    resources = "/resources",
    coachingHub = "/coaching-hub",
    classes = "/classes",
    fcr = "/fcr",
    coachingPlans = "/coaching-plans",
    coachingDevelopment = "/coaching-development",
}
export enum EvaluatorClassesViewAllRouting {
    mainClassesView = "/classes",
    allScheduledClasses = "/classes/scheduled",
    allMyClasses = "/classes/my",
    traineeClasses = "/classes/trainee",
}

export enum ClassesRouting {
    roster = "roster",
    evaluations = "evaluations",
}

export enum TeamSkillsRouting {
    highLevel = "/team-skills/high-level",
    personal = "/team-skills/personal",
}

export enum AdminRoutings {
    admin = "/admin",
    company = "/admin/company",
    companyStructure = "/admin/company-structure",
    companies = "/admin/companies",
    skillsManagement = "/admin/skills-management",
    skillsDistribution = "/admin/skills-distribution",
    certification = "/admin/certification",
    certificationEvaluations = "/admin/certification/evaluations",
    addCertificationEvaluation = "/admin/certification/evaluations/add",
    certificationClasses = "/admin/certification/classes",
    addCertificationClass = "/admin/certification/classes/add",
    resourceManagement = "/admin/resource/management",
    resourceDistribution = "/admin/resource/distribution",
    members = "/admin/members",
    addMember = "/admin/members/add",
    teams = "/admin/teams",
    addTeam = "/admin/teams/add",
    fcrSettings = "/admin/fcrSettings",
    productImport = "/admin/product-import",
    emailConfiguration = "/admin/email-configuration",
}

export enum ComponentType {
    singleCheckbox = "12118d2f-d57e-4851-aafd-5ecd2807d88d",
    multiselectCheckbox = "776bc3af-a784-424e-9706-dfa66ed3fd35",
    radioButtons = "21e367ac-8f01-4ed3-8733-8ab0cc1e6867",
    freeInput = "3b10ef7e-dd43-4ddd-b585-a1c9f1756723",
    compliance = "4adaae6e-1315-44d6-82a0-a411030b317a",
    staticText = "5d3cb339-82d4-41b0-996d-bc0591e3e2c2",
    dropdownList = "6776e1f8-044c-4d36-978e-be85b9924b5c",
}

/** This is also evaluationItemTypeId in the evaluation full dto */
export enum ComponentTypeEvaluation {
    textEntry = "9518cbca-2f35-480b-a11f-373ea235b9c9",
    checkboxList = "37a9159b-74f2-40e1-b2ea-4f663b04056e",
    dropdownList = "c53976f4-5182-4681-865b-7e19695358f5",
    radioButtonList = "e8cca38d-3892-4909-b170-dd177c7af427",
    staticText = "3f83548c-20cf-4190-998b-d02aacce1cf0",
}

export enum FcrSimpleCheckboxItemValueEnum {
    checked = "Yes",
    unchecked = "No",
}

export enum FcrComplianceItemValueEnum {
    checked = "True",
    unchecked = "False",
}

export enum FcrStatusEnum {
    new = "1f158845-262b-45a2-ba66-568ff20ba58d",
    development_goals = "2be4c036-b6a2-40e7-b3f8-fa233b754f4e",
    observations = "34874899-99aa-47cd-98c0-d7c412ea4d9f",
    coaching_plans = "48bba01e-2916-4651-a053-9d431584f99c",
    review = "52f5d9d2-925f-4b0b-badb-958c8ab1bf32",
    completed = "6636148c-4460-41a5-854e-41c15b9365b5",
    failed = "76bca3ce-0968-4102-8b72-ba6ba3e6e8a6",
}

export enum FcrStateEnum {
    new = "New",
    draft = "Draft",
    completed = "Completed",
    failed = "Failed",
    readByCoachee = "ReadByCoachee",
    acknowledged = "Acknowledged",
    acknowledgedWithComment = "AcknowledgedWithComment",
    readByCoach = "ReadByCoach",
}

export enum CoachingPeriodEnum {
    monthToDate = "MonthToDate",
    yearToDate = "YearToDate",
    rolling12Months = "Rolling12Months",
}

export enum CoachingPlanStatusEnum {
    assigned = "14c0b878-e25d-40fb-bec8-a1fc4c9169d2",
    completed = "320decd9-8e21-40e5-9e80-7ca614758ce0",
    inProgress = "2da2bb57-5a7e-4de0-9299-b3a41625094c",
}

export enum AuthenticationTypeEnum {
    default = "55425ad6-c76a-42eb-903e-ae094b9dc7f8",
    openId = "cc965ac3-34ba-4bc8-815a-7c2bd9d4b26f",
}

export enum EvaluationResultStatusEnum {
    new = "5aa957e2-dc2e-46d0-bdeb-03c8e055d73d",
    inProgress = "7aea206a-7a9f-478a-9794-26c9732f5382",
    completed = "e12f58b4-1c0d-4b20-b703-5df02e893b84",
}

export const EvaluationStatusText = {
    [EvaluationResultStatusEnum.new]: "New",
    [EvaluationResultStatusEnum.inProgress]: "In Progress",
    [EvaluationResultStatusEnum.completed]: "Completed",
};

export enum ResourceTypeEnum {
    document = "Document",
    link = "Link",
}

export enum ResourceContentTypeEnum {
    doc = "doc",
    pdf = "pdf",
    link = "link",
}

export enum UserRoles {
    globalAdmin = "Global Admin",
}

export enum AlertSeverity {
    info = "info",
    error = "error",
    success = "success",
    warning = "warning",
}

export enum SortDirectionEnum {
    desc = "desc",
    asc = "asc",
}

export enum CoachingSessionStatusEnum {
    upcoming = "upcoming",
    completed = "completed",
    incomplete = "incomplete",
}

export const isUnfinishedContentShown: true | undefined = !process.env.REACT_APP_HIDE_UNFINISHED_UI || undefined;

export enum ImportType {
    members,
    avatars,
}

export enum ProductSalesLabelValueTypes {
    Graph = "Graph",
    Trophy = "Trophy",
    Percentage = "Percentage",
}

export enum CoachingSessionTypeIconsEnum {
    rideAlong = "rideAlong",
    virtual = "virtual",
    businessPlanning = "businessPlanning",
    achievement = "achievement",
    calendarList = "calendarList",
    completedTask = "completedTask",
    graph = "graph",
    presenter = "presenter",
    star = "star",
    target = "target",
    trophy = "trophy",
}

export enum CertificationClassEntity {
    Evaluator = "Evaluator",
    Trainee = "Trainee",
}

export const NUMBER_OF_ITEMS_IN_PREVIEW = 6;
export const NULL_GUID = "00000000-0000-0000-0000-000000000000";
export const AUTOSAVE_DELAY = 3000;
