import * as React from "react";

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={15}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#calendar_svg__clip0)" fill="currentColor">
                <path d="M12.582.532h-1.555V-.513A.484.484 0 0010.54-1a.49.49 0 00-.487.487V.524h-5.45v-.998a.484.484 0 00-.487-.487.496.496 0 00-.495.487V.532H2.058A2.065 2.065 0 000 2.589v10.517a2.06 2.06 0 002.058 2.058h10.516a2.06 2.06 0 002.058-2.058V2.589a2.048 2.048 0 00-2.05-2.057zm1.076 12.582c0 .597-.487 1.084-1.084 1.084H2.058a1.086 1.086 0 01-1.084-1.084V2.589c0-.597.487-1.084 1.084-1.084h1.555v1.179c0 .274.22.487.487.487s.502-.205.502-.48V1.513h5.443V2.66c0 .275.22.487.487.487s.495-.22.495-.487V1.513h1.555c.597 0 1.084.487 1.084 1.084v10.517h-.008z" />
                <path d="M10.375 5.338h-.039a.672.672 0 00-.668.691c0 .385.33.691.707.691a.692.692 0 000-1.382zM4.304 6.712a.692.692 0 00.691-.69.685.685 0 00-.691-.684h-.04c-.376 0-.667.306-.667.691 0 .385.33.683.707.683zM7.344 5.338h-.04c-.377 0-.667.306-.667.691 0 .385.33.691.707.691a.692.692 0 000-1.382zM10.375 7.953h-.039a.672.672 0 00-.668.691c0 .385.33.691.707.691a.692.692 0 000-1.382zM4.304 9.328a.692.692 0 00.691-.692.685.685 0 00-.691-.683h-.04c-.376 0-.667.306-.667.691 0 .385.33.684.707.684zM4.32 10.568h-.04a.672.672 0 00-.667.691c0 .385.33.692.707.692a.692.692 0 000-1.383zM7.344 10.498h-.04c-.377 0-.667.306-.667.691 0 .385.33.691.707.691a.692.692 0 000-1.382zM7.344 7.953h-.04c-.377 0-.667.306-.667.691 0 .385.33.691.707.691a.692.692 0 000-1.382z" />
            </g>
            <defs>
                <clipPath id="calendar_svg__clip0">
                    <path fill="#fff" d="M0 0h14.64v16.172H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgCalendar;
