import React, { useCallback } from "react";

import {
    CoachingPlanType, CoachingSession,
    FcrCoachingSkill,
    FcrCoachingSkillHistory,
    FcrCoachingSkillObservation,
    FcrResource,
} from "types";

import WizardPaper from "../WizardPaper";

import RightSide from "./RightSide";
import LeftSide from "./LeftSide";

interface CoachingPlansProps {
    fcrCoachingSkills: FcrCoachingSkill[];
    coachingPlans: Array<CoachingPlanType>;
    fcrCoachingSkillObservations: Array<FcrCoachingSkillObservation>;
    fcrResultId?: string;
    fcrCoachingSkillsHistory: FcrCoachingSkillHistory[];
    onChange: (value: Array<CoachingPlanType>) => void;
    coachingSession: CoachingSession;
}

function CoachingPlans({
    fcrCoachingSkills,
    coachingPlans,
    fcrCoachingSkillObservations,
    fcrResultId,
    fcrCoachingSkillsHistory,
    onChange,
    coachingSession,
}: CoachingPlansProps) {
    const coachingPlanChange = (coachingPlansToChange: Array<CoachingPlanType>) => (cpId: string, field: string, value: string | number | boolean | FcrResource[]) => {
        const newCoachingPlans = coachingPlans.map(cp => {
            if (cpId === cp.id) {
                return {
                    ...cp,
                    dueDate: (field === "dueDate" ? value : cp.dueDate) as string,
                    coachingPlanData: (field === "coachingPlanData" ? value : cp.coachingPlanData) as string,
                    sendReminder: (field === "sendReminder" ? value : cp.sendReminder) as boolean,
                    resources: (field === "resources" ? value : cp.resources) as FcrResource[],
                }
            } else return cp;
        });

        coachingPlansToChange.forEach(observationCoachingPlan => {
            const addedCp = newCoachingPlans.find(x => x.id == observationCoachingPlan?.id);
            if (!addedCp && observationCoachingPlan) newCoachingPlans.push(observationCoachingPlan);
        });

        onChange(newCoachingPlans);
    };

    const lastCoachingPlanForSkill = useCallback((skillId: string) =>
        fcrCoachingSkillsHistory.find(e => e.fcrCoachingSkill.id === skillId)?.fcrCoachingPlans[0]?.coachingPlanData,
    [fcrCoachingSkillsHistory]
    );

    const addNewCoachingPlan = (observationCoachingPlan: CoachingPlanType) => onChange([...coachingPlans, observationCoachingPlan]);

    return (
        <>
            {
                fcrCoachingSkillObservations.map(fcrCoachingSkillObservation => {
                    const observationCoachingPlans = coachingPlans.filter(x => x.fcrCoachingSkillResultId == fcrCoachingSkillObservation.id);

                    return fcrCoachingSkillObservation &&
                        <WizardPaper
                            key={fcrCoachingSkillObservation.id}
                            contentLeft={
                                <LeftSide
                                    fcrCoachingSkills={fcrCoachingSkills}
                                    coachingPlans={observationCoachingPlans}
                                    fcrCoachingSkillObservation={fcrCoachingSkillObservation}
                                    fcrResultId={fcrResultId}
                                    onChange={coachingPlanChange(observationCoachingPlans)}
                                    addNewCoachingPlan={addNewCoachingPlan}
                                    coachingSession={coachingSession}
                                />
                            }
                            contentRight={
                                <RightSide
                                    lastCoachingPlan={lastCoachingPlanForSkill(fcrCoachingSkillObservation.fcrCoachingSkillId)}
                                />
                            }
                        />
                }
                )
            }
        </>
    );
}

export default CoachingPlans;
