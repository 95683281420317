import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    input: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.colors.structure.fieldsOutline,
        borderRadius: "6px",
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    borderExtendedStyle: {
        marginBottom: 0,
    },
    inputError: {
        borderColor: theme.colors.error,
    },
    redText: {
        color: theme.colors.error,
    },
}));

export default useStyles;
