import React, { useContext, useEffect, useState, useMemo } from "react";
import { Box, Paper, CircularProgress, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import { CompaniesContext } from "context";
import { EmailConfiguration as EmailConfigurationType, EmailConfigurationForm } from "types";
import LoadingButton from "components/LoadingButton";
import PaperCard from "components/PaperCard";
import { FormSwitcher } from "components/Form";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import { getEmailConfigurationForCompany, saveEmailConfiguration } from "services/emailConfiguration";
import {
    EMAIL_CONFIGURATION,
    EVALUATOR_LED_CONFIGURATION,
    getEmailConfigurationFormData,
    SELF_EVALUATOR_CONFIGURATION,
} from "helpers/emailConfigurationHelper";

import BccEmails from "./BccEmails";
import OtherFormSwitcher from "./OtherFormSwitcher";

const emailListSchema = Yup.array()
    .required("Required")
    .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
            return value;
        }
        return originalValue ? originalValue.split(/[\s;]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`));

const validationSchema = Yup.object().shape({
    evaluationCompletionEvaluatorLedToOther: Yup.array()
        .nullable()
        .when("evaluationCompletionEvaluatorLedToOtherChecked", {
            is: (checked: boolean) => checked,
            then: emailListSchema,
        }),
    evaluationCompletionSelfLedToOther: Yup.array()
        .nullable()
        .when("evaluationCompletionSelfLedToOtherChecked", {
            is: (checked: boolean) => checked,
            then: emailListSchema,
        }),
});

export default function EmailConfiguration() {
    const { currentCompany } = useContext(CompaniesContext);
    const [emailConfiguration, setEmailConfiguration] = useState<EmailConfigurationType>();
    const [bccEmails, setBccEmails] = useState<Array<string> | null>(null);
    const [startedBccEmails, setStartedBccEmails] = useState<Array<string> | null>(null);

    useEffect(() => {
        const fetchEmailConfiguration = (companyId: string) => {
            setEmailConfiguration(undefined);
            getEmailConfigurationForCompany(companyId).then((emailConfiguration) => {
                setEmailConfiguration(emailConfiguration);
                setBccEmails(emailConfiguration.bccRegistrationEmails);
                setStartedBccEmails(emailConfiguration.bccRegistrationEmails);
            });
        };
        currentCompany?.id && fetchEmailConfiguration(currentCompany.id);
    }, [currentCompany?.id]);

    const initialValues = useMemo(() => getEmailConfigurationFormData(emailConfiguration), [emailConfiguration]);

    const formik = useFormik<EmailConfigurationForm>({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit(values, { setSubmitting }) {
            if (!currentCompany || !emailConfiguration) return;
            saveEmailConfiguration(currentCompany.id, { ...emailConfiguration, ...values, bccRegistrationEmails: bccEmails ?? [] })
                .then((emailConfiguration) => {
                    setEmailConfiguration(emailConfiguration);
                    setBccEmails(emailConfiguration.bccRegistrationEmails);
                    setStartedBccEmails(emailConfiguration.bccRegistrationEmails);
                })
                .finally(() => setSubmitting(false));
        },
    });

    const isFetching = !currentCompany || !emailConfiguration;

    const isEmailBccUpdated = useMemo(() => !isEqual(sortBy(bccEmails), sortBy(startedBccEmails)), [bccEmails, startedBccEmails]);

    return (
        <FormikProvider value={formik}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h1">Email Configuration</Typography>
                <LoadingButton
                    color="primary"
                    size="large"
                    disableElevation
                    component="label"
                    loading={formik.isSubmitting}
                    loadingLabel="Saving..."
                    disabled={isFetching || !(formik.dirty || isEmailBccUpdated)}
                    onClick={!formik.isSubmitting ? formik.submitForm : undefined}>
                    Save Email Configuration
                </LoadingButton>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={8}>
                    <Box mb={3}>
                        <PaperCard>
                            <Box fontWeight={600} display="flex" alignItems="center">
                                <Typography variant="h2">Notification emails</Typography>
                            </Box>

                            {isFetching && (
                                <Box px={6} pt={4} pb={3} textAlign="center">
                                    <CircularProgress size={40} />
                                </Box>
                            )}

                            {!isFetching && (
                                <Box m={1.5}>
                                    <Grid container spacing={3}>
                                        {EMAIL_CONFIGURATION.map(({ key, label }) => (
                                            <Grid item xs={12} md={12} lg={6} key={key}>
                                                <FormControlLabel control={<FormSwitcher name={key} />} label={label} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            )}
                        </PaperCard>
                    </Box>
                    <Box mb={3}>
                        <PaperCard>
                            <Box fontWeight={600} display="flex" alignItems="center">
                                <Typography variant="h2">Evaluation emails</Typography>
                            </Box>

                            {isFetching && (
                                <Box px={6} pt={4} pb={3} textAlign="center">
                                    <CircularProgress size={40} />
                                </Box>
                            )}

                            {!isFetching && (
                                <Box m={1.5}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h3">Evaluator-led Evaluation completion</Typography>
                                            <Box m={1.5}>
                                                <Grid container spacing={3}>
                                                    {EVALUATOR_LED_CONFIGURATION.map(({ key, label }) => (
                                                        <Grid item xs={12} key={key}>
                                                            <FormControlLabel control={<FormSwitcher name={key} />} label={label} />
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs={12}>
                                                        <OtherFormSwitcher name="evaluationCompletionEvaluatorLedToOther" />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h3">Self-Evaluation completion</Typography>
                                            <Box m={1.5}>
                                                <Grid container spacing={3}>
                                                    {SELF_EVALUATOR_CONFIGURATION.map(({ key, label }) => (
                                                        <Grid item xs={12} key={key}>
                                                            <FormControlLabel control={<FormSwitcher name={key} />} label={label} />
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs={12}>
                                                        <OtherFormSwitcher name="evaluationCompletionSelfLedToOther" />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </PaperCard>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <Paper>
                        <BccEmails bccEmails={bccEmails} onSuccess={setBccEmails} isFetching={isFetching} />
                    </Paper>
                </Grid>
            </Grid>
        </FormikProvider>
    );
}
