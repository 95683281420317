import { ComponentTypeEvaluation, EvaluationResultStatusEnum } from "./constants";

export enum QuestionBasedForm {
    Flagged = "flagged",
    Aggregated = "aggregated",
}

export type EvaluationConfiguration = {
    id: string;
    name: string;
    description: string;
    companyId: string;
    isActive: boolean;
    isInUse: boolean;
    version: number;
    versionName: string;
    createdDate: string;
    modifiedDate: string;
    evaluationItemConfigurations: EvaluationItemConfiguration[];
    itemsOrder?: ItemOrder[];
    label: EvaluationConfiguration["name"];
    isQuestionBasedForm: boolean;
};
export interface EvalConfigInFullEvalDto extends EvaluationConfiguration {
    passThreshold: number;
}

export type ItemOrder = {
    id: string;
    order: number;
};

export type EvaluationItemConfiguration = {
    id: string;
    evaluationConfigurationId: string;
    evaluationItemTypeId: ComponentTypeEvaluation;
    elementHeader: string;
    elementBody: string;
    evaluationItemValues: EvaluationItemValue[];
    evaluationItemRatings?: EvaluationItemRating[];
    order?: number;
    isRequired: boolean;
};

export type EvaluationItemValue = {
    id: string;
    name: string;
    /** If value is 1 it's correct, if it's 0 it's incorrect. If it's something else there's no right answer */
    value: number;
    order?: number;
    evaluationItemConfigurationId?: EvaluationItemConfiguration["id"];
};

type EvaluationItemRating = {
    id: string;
    evaluationItemConfigurationId: EvaluationItemConfiguration["id"];
    min: number;
    max: number;
    label: string;
};

export type EvaluationConfigurationInformation = Omit<EvaluationConfiguration, "evaluationItemConfigurations">;

export type EvaluationFormProps = Partial<EvaluationConfiguration> & {
    questionBasedForm: QuestionBasedForm;
};

export type CertificationClass = {
    id: string;
    companyId: string;
    classStatusId: string;
    title: string;
    description: string;
    allowSelfEvaluation: boolean;
    isActive: boolean;
    allowMultipleSubmissions: boolean;
    classDate: string;
    releaseDate: string;
    createdDate: string;
    modifiedDate: string;
    reportingUrl: string;
};

export type CertificationClassInformation = Omit<
    CertificationClass,
    "companyId" | "classStatusId" | "allowSelfEvaluation" | "allowMultipleSubmissions" | "modifiedDate"
> & {
    numberOfEvaluators: number;
    numberOfTrainees: number;
    label: CertificationClassInformation["title"];
};

export interface AssignUsersRef {
    getAssignments: () => {
        availableUsers: CertificationUser[];
        assignedUsers: CertificationUser[];
    };
}

export type CertificationUser = {
    id: string;
    userName: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    level: number;
    levelLabel: string;
    companyLevelId: string;
    isActive: boolean;
    title: string;
};

export type CertificationClassRoster = {
    classId: CertificationClass["id"];
    userIds: Array<CertificationUser["id"]>;
    assign: boolean;
    areEvaluators: boolean;
};

export type CertificationClassUser = Omit<CertificationUser, "id"> & {
    id: string;
    classId: string;
    userId: CertificationUser["id"];
    isEvaluator: boolean;
};

export type CertificationClassEvaluationAssignments = {
    classId: CertificationClass["id"];
    evaluationConfigurationIds: Array<EvaluationConfiguration["id"]>;
    assign: boolean;
};

export type CertificationClassEvaluationAssignment = {
    id: string;
    classId: CertificationClass["id"];
    evaluationConfigurationId: EvaluationConfiguration["id"];
    evaluationConfigurationName: EvaluationConfiguration["name"];
};

export type IClassBaseForm = Omit<CertificationClass, "classStatusId" | "createdDate" | "modifiedDate"> & {
    evaluationForm: string;
};

export enum TraineeClassInfoDtoProps {
    classDate = "classDate",
    className = "className",

    evaluationConfigurationId = "evaluationConfigurationId",
    evaluationResultId = "evaluationResultId",

    evaluationStatusDate = "evaluationStatusDate",
    evaluationStatusId = "evaluationStatusId",

    id = "id",
    isSelfEvaluated = "isSelfEvaluated",
    traineeAvatar = "traineeAvatar",

    traineeId = "traineeId",
    traineeName = "traineeName",
    classEvaluatorIds = "classEvaluatorIds",
}

export interface TraineeClassInfoDto {
    [TraineeClassInfoDtoProps.classDate]: string;
    [TraineeClassInfoDtoProps.className]: string;

    [TraineeClassInfoDtoProps.evaluationConfigurationId]: string;
    [TraineeClassInfoDtoProps.evaluationResultId]: string;

    [TraineeClassInfoDtoProps.evaluationStatusDate]: string;
    [TraineeClassInfoDtoProps.evaluationStatusId]: EvaluationResultStatusEnum;

    [TraineeClassInfoDtoProps.id]: string;
    [TraineeClassInfoDtoProps.traineeAvatar]: string | null;

    [TraineeClassInfoDtoProps.isSelfEvaluated]: boolean;
    [TraineeClassInfoDtoProps.traineeId]: string;
    [TraineeClassInfoDtoProps.traineeName]: string;
    [TraineeClassInfoDtoProps.classEvaluatorIds]: string[];
}

export enum EvaluatorClassDtoProps {
    id = "id",
    title = "title",
    description = "description",
    allowSelfEvaluation = "allowSelfEvaluation",
    classDate = "classDate",
    releaseDate = "releaseDate",
    createdDate = "createdDate",
    isActive = "isActive",
    numberOfEvaluators = "numberOfEvaluators",
    numberOfTrainees = "numberOfTrainees",
    numberOfCompleteEvaluations = "numberOfCompleteEvaluations",
    numberOfIncompleteEvaluations = "numberOfIncompleteEvaluations",
    reportingUrl = "reportingUrl",
}

export interface EvaluatorClassDto {
    [EvaluatorClassDtoProps.id]: string;
    [EvaluatorClassDtoProps.title]: string;
    [EvaluatorClassDtoProps.description]: string;
    [EvaluatorClassDtoProps.allowSelfEvaluation]: boolean;
    [EvaluatorClassDtoProps.classDate]: string;
    [EvaluatorClassDtoProps.releaseDate]: string;
    [EvaluatorClassDtoProps.createdDate]: string;
    [EvaluatorClassDtoProps.isActive]: boolean;
    [EvaluatorClassDtoProps.numberOfEvaluators]: number;
    [EvaluatorClassDtoProps.numberOfTrainees]: number;
    [EvaluatorClassDtoProps.numberOfCompleteEvaluations]: number;
    [EvaluatorClassDtoProps.numberOfIncompleteEvaluations]: number;
    [EvaluatorClassDtoProps.reportingUrl]: string;
}

export enum CertClassesPreviewForUserProps {
    userId = "userId",
    traineeClasses = "traineeClasses",
    evaluatorClasses = "evaluatorClasses",
}
export interface CertClassesPreviewForUserDto {
    [CertClassesPreviewForUserProps.userId]: string;
    [CertClassesPreviewForUserProps.traineeClasses]: TraineeClassInfoDto[];
    [CertClassesPreviewForUserProps.evaluatorClasses]: EvaluatorClassDto[];
}

enum EvaluationResultStatus {
    New = "New",
    InProgress = "In Progress",
    Completed = "Completed",
}
export interface IEvaluationResultStatusType {
    order: number;
    name: string;
    id: string;
}

export const EvaluationStatusResultOptions: Record<EvaluationResultStatus, IEvaluationResultStatusType> = {
    [EvaluationResultStatus.New]: {
        order: 1,
        name: EvaluationResultStatus.New,
        id: EvaluationResultStatusEnum.new,
    },
    [EvaluationResultStatus.InProgress]: {
        order: 3,
        name: EvaluationResultStatus.InProgress,
        id: EvaluationResultStatusEnum.inProgress,
    },
    [EvaluationResultStatus.Completed]: {
        order: 2,
        name: EvaluationResultStatus.Completed,
        id: EvaluationResultStatusEnum.completed,
    },
};
/** Functionally created lookup object to map status guids to display strings, copied from db table,
 * toLocaleLowerCase used because for some reason guids are uppercase in db while returned in lower case. */
export type EvaluationStatusLookup = { [K in EvaluationResultStatusEnum]: EvaluationResultStatus };

/** Resolves db mirrored object as an easy and strongly typed lookup object */
export const EvaluationStatusIdToNameLookup: EvaluationStatusLookup = Object.values(EvaluationStatusResultOptions).reduce(
    (accum, curr) => ({
        ...accum,
        [curr.id]: curr.name,
    }),
    {} as EvaluationStatusLookup
);
/**
 * Domain logic as communicated in https://productiveedge.atlassian.net/browse/PLEVAL-30
 * omments, status is nullabel hence default return of ""
 */
export const computedSecondaryStatusBasedOnStatus = (status?: EvaluationResultStatus): string => {
    if (status === EvaluationResultStatus.New) {
        return "";
    } else if (status === EvaluationResultStatus.InProgress) {
        return "Edited";
    } else if (status === EvaluationResultStatus.Completed) {
        return EvaluationResultStatus.Completed;
    } else {
        return "";
    }
};

export type ClassTraineesInformation = {
    id: string;
    className: string;
    classDate: string;
    isSelfEvaluated: boolean;
    evaluationsByStatusAmounts: EvaluationsByStatusAmounts;
    // trainees: TraineeClassInformation[];
};

export type ClassTraineeList = {
    results: TraineeClassInformation[];
    totalAmount: number;
    totalFilteredAmount: number;
};

export type TraineeClassInformation = {
    id: string;
    className: string;
    classDate: string;
    isSelfEvaluated: boolean;
    evaluationsByStatusAmounts: EvaluationsByStatusAmounts;
    traineeId: string;
    traineeName: string;
    traineeAvatar: string;
    evaluationConfigurationId: string;
    evaluationResultId: string;
    evaluationStatusDate: string;
    evaluationStatusId: EvaluationResultStatusEnum;
};

export type CertificationSectionConfiguration = {
    companyId: string;
    id?: string;
    section1Label: string;
    section2Label?: string;
    section3Label?: string;
    section4Label?: string;
};

export type EvaluationsByStatusAmounts = Record<EvaluationResultStatusEnum, number>;
