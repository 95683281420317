import React from "react";
import { Box, BoxProps, Typography } from "@material-ui/core";
import SvgNoData from "svgComponents/NoData";

interface NoDataMessageProps extends BoxProps {
    icon?: React.ReactNode;
    message?: string;
    title?: string;
}

const NoDataMessage = ({
    icon = <SvgNoData />,
    message = "There are no scores to report here yet.",
    title = "No Data",
    ...props
}: NoDataMessageProps) => (
    <Box display="flex" flexDirection="column" alignItems="center" {...props}>
        {icon && (
            <Box mb={2} color="text.secondary">
                {icon}
            </Box>
        )}
        {title && (
            <Box mb={1} fontWeight={600}>
                <Typography variant="h3" component="p" color="textSecondary">
                    {title}
                </Typography>
            </Box>
        )}
        {message && (
            <Typography variant="caption">
                {message}
            </Typography>
        )}
    </Box>
);

export default NoDataMessage;