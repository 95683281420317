import * as React from "react";

function SvgSessions(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg height="26" width="23" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M20.16 2.71H18.06V0.62C18.06 0.27 17.78 0 17.44 0C17.1 0 16.82 0.28 16.82 0.62V2.7H5.97V0.68C5.97 0.33 5.69 0.06 5.35 0.06C5.01 0.06 4.72 0.34 4.72 0.68V2.71H2.62C1.18 2.71 0 3.89 0 5.33V22.87C0 24.32 1.18 25.49 2.62 25.49H20.16C21.61 25.49 22.78 24.31 22.78 22.87V5.33C22.79 3.89 21.61 2.71 20.16 2.71ZM21.54 22.87C21.54 23.63 20.92 24.25 20.16 24.25H2.62C1.86 24.25 1.24 23.63 1.24 22.87V5.33C1.24 4.57 1.86 3.95 2.62 3.95H4.72V5.67C4.72 6.02 5 6.3 5.35 6.3C5.7 6.3 5.97 6.02 5.97 5.68V3.96H16.82V5.62C16.82 5.97 17.1 6.24 17.44 6.24C17.78 6.24 18.06 5.96 18.06 5.62V3.96H20.16C20.92 3.96 21.54 4.58 21.54 5.34V22.87Z" />
            <path d="M17.5204 10.7598H5.27039C4.92039 10.7598 4.65039 11.0398 4.65039 11.3798C4.65039 11.7198 4.93039 11.9998 5.27039 11.9998H17.5204C17.8704 11.9998 18.1404 11.7198 18.1404 11.3798C18.1404 11.0398 17.8704 10.7598 17.5204 10.7598Z" />
            <path d="M17.5204 14.7305H5.27039C4.92039 14.7305 4.65039 15.0105 4.65039 15.3505C4.65039 15.6905 4.93039 15.9705 5.27039 15.9705H17.5204C17.8704 15.9705 18.1404 15.6905 18.1404 15.3505C18.1404 15.0105 17.8704 14.7305 17.5204 14.7305Z" />
            <path d="M14.5304 18.6895H5.27039C4.92039 18.6895 4.65039 18.9695 4.65039 19.3095C4.65039 19.6495 4.93039 19.9295 5.27039 19.9295H14.5304C14.8804 19.9295 15.1504 19.6495 15.1504 19.3095C15.1504 18.9695 14.8704 18.6895 14.5304 18.6895Z" />
        </svg>
    );
}

export default SvgSessions;
