import { v4 as uuidv4 } from "uuid";
import { mapValues } from "lodash";
import { Company, CompanyFormProps, CompanyLevel, CompanyLevelLabel, UserProfile, FcrSectionConfiguration } from "types";
import { AuthenticationTypeEnum } from "types/constants";

import { CertificationSectionConfiguration } from "types/Certification";

import { DEFAULT_FCR_SECTION_CONFIGURATION } from "./fcrHelper";

export interface LevelHierarchy<T = CompanyLevel["id"] | null> {
    [level: number]: T;
}

export const COMPANY_LEVELS: number[] = [1, 2, 3, 4, 5];

export const DEFAULT_LEVEL_HIERARCHY: LevelHierarchy<null> = COMPANY_LEVELS.reduce(
    (levelHierarchy, levelNum) => ({
        ...levelHierarchy,
        [levelNum]: null,
    }),
    {}
);

export const getLastLevel = (levels: CompanyLevel[]) =>
    levels.reduce((lastLevel, levelData) => (levelData.level > lastLevel ? levelData.level : lastLevel), COMPANY_LEVELS[0]);

export const isHighLevel = (level: UserProfile["level"], levels: CompanyLevel[]) =>
    Number.isFinite(level) && getLastLevel(levels) - level! >= 2;

export const getCompanyFormData = (
    company: Company | null,
    companyLevelLabels: CompanyLevelLabel[],
    fcrSectionConfiguration: FcrSectionConfiguration | null,
    companyCertificationConfiguration: CertificationSectionConfiguration | null
): CompanyFormProps => {
    const companyLevelLabelsMap = new Map(companyLevelLabels.map((label) => [label.level, label]));

    const levelLabels: CompanyLevelLabel[] = COMPANY_LEVELS.map((level) => ({
        id: companyLevelLabelsMap.get(level)?.id || uuidv4(),
        name: companyLevelLabelsMap.get(level)?.name || "",
        companyId: companyLevelLabelsMap.get(level)?.companyId || "",
        level,
    }));

    const companyId = company?.id || uuidv4();

    return {
        id: companyId,
        authenticationTypeId: company?.authenticationTypeId || AuthenticationTypeEnum.default,
        logoFile: null,
        logo: company?.logo || "",
        name: company?.name || "",
        isActive: company?.isActive ?? true,
        address1: company?.address1 || "",
        address2: company?.address2 || "",
        city: company?.city || "",
        state: company?.state || "",
        zip: company?.zip || "",
        phoneNumber: company?.phoneNumber || "",
        contactEmail: company?.contactEmail || "",
        levelLabels,
        fcrSectionConfiguration: fcrSectionConfiguration || {
            id: uuidv4(),
            companyId,
            ...DEFAULT_FCR_SECTION_CONFIGURATION,
        },
        certificationSectionConfiguration: companyCertificationConfiguration ?? {
            companyId,
            section1Label: "Classes",
        },
    };
};

export const extractLabelFromLevel = (level: CompanyLevel): CompanyLevelLabel => ({
    id: level.companyLevelLabelId,
    companyId: level.companyId,
    level: level.level,
    name: level.levelName,
});

export const getLevelsChildrenIds = (levels: CompanyLevel[]) =>
    levels.reduce((innerLevelsIds, level) => {
        if (level.parentLevelId) {
            !innerLevelsIds.has(level.parentLevelId) && innerLevelsIds.set(level.parentLevelId, new Set<CompanyLevel["id"]>());
            innerLevelsIds.get(level.parentLevelId)!.add(level.id);
        }

        !innerLevelsIds.has(level.id) && innerLevelsIds.set(level.id, new Set<CompanyLevel["id"]>());

        return innerLevelsIds;
    }, new Map<CompanyLevel["id"], Set<CompanyLevel["id"]>>());

export const getLevelsIdsByLevelNum = (levels: CompanyLevel[]) =>
    levels.reduce((levelsIdsByNum, level) => {
        !levelsIdsByNum.has(level.level) && levelsIdsByNum.set(level.level, new Set<CompanyLevel["id"]>());
        levelsIdsByNum.get(level.level)!.add(level.id);

        return levelsIdsByNum;
    }, new Map<CompanyLevel["level"], Set<CompanyLevel["id"]>>());

export const getLevelsById = (levels: CompanyLevel[]) => new Map<CompanyLevel["id"], CompanyLevel>(levels.map((item) => [item.id, item]));

export const getLabelsByLevelNum = (levels: CompanyLevel[]) =>
    new Map<number, CompanyLevelLabel>(levels.map((item) => [item.level, extractLabelFromLevel(item)]));

export const isEmptyLevelHierarchy = (levelHierarchy: LevelHierarchy) =>
    !Object.values(levelHierarchy).filter((levelId) => !!levelId).length;

export const buildLevelHierarchyObjectsToLevel = (
    levelId: CompanyLevel["id"],
    levels: CompanyLevel[]
): LevelHierarchy<CompanyLevel | null> => {
    const levelsById = getLevelsById(levels);

    const build = (
        levelId: string,
        levelHierarchy: LevelHierarchy<CompanyLevel | null> = DEFAULT_LEVEL_HIERARCHY
    ): LevelHierarchy<CompanyLevel | null> => {
        const level = levelsById.get(levelId);

        if (!level) return levelHierarchy;

        const currentLevelHierarchy = {
            ...levelHierarchy,
            [level.level]: level ?? null,
        };

        return level.parentLevelId ? build(level.parentLevelId, currentLevelHierarchy) : currentLevelHierarchy;
    };

    return build(levelId);
};

export const buildLevelHierarchyToLevel = (levelId: CompanyLevel["id"], levels: CompanyLevel[]): LevelHierarchy =>
    mapValues(buildLevelHierarchyObjectsToLevel(levelId, levels), (level) => level?.id ?? null);

export const US_STATES = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
];

export const ACCEPTED_LOGO_FORMATS = ".png,.jpeg";

export const getCompanyByIdWithFirstByDefault = (companies: Company[], companyId: string | null): Company | null => {
    if (!companyId) return companies[0] || null;

    return companies.find((company) => company.id === companyId) || companies[0] || null;
};

export const buildLevelPath = (
    levelHierarchy: LevelHierarchy<CompanyLevel | null>,
    checkLevelShown: (companyLevel: CompanyLevel | null) => boolean = () => true
): string =>
    COMPANY_LEVELS.reduce(
        (path, level) =>
            levelHierarchy[level] && checkLevelShown(levelHierarchy[level])
                ? path + `${path ? " > " : ""}${levelHierarchy[level]?.name}`
                : path,
        ""
    );
