import * as React from "react";

export default function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height="16px"
            width="17px"
            viewBox="0 0 17 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M8.5,3 L8.5,7.5 L13,7.5 L13,8.5 L8.5,8.5 L8.5,13 L7.5,13 L7.5,8.5 L3,8.5 L3,7.5 L7.5,7.5 L7.5,3 L8.5,3 Z"
            />
        </svg>
    );
}
