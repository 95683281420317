import React from "react";
import { Tooltip } from "@material-ui/core";

export interface ConditionalTooltipProps {
    condition?: boolean;
    textContent: string;
    children: React.ReactElement;
}

/** Use to make it easier to reuse conditional rendering for a material ui tooltip component, if text context passed assumed to be displayed, with optional override condition input */
export default function ConditionalTooltip(props: ConditionalTooltipProps) {
    const { children, condition = true, textContent } = props;
    return condition && textContent ? (
        <Tooltip arrow title={textContent}>
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );
}
