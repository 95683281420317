import React, { useCallback, useReducer } from "react";
import { Box, Button, ButtonBase, Typography } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import clsx from "clsx";

import { RearrageIcon, Folder, FolderOpened, EditIcon, DeleteIcon } from "svgComponents";
import { FcrResourceCategory } from "types";

import useStyles from "./styles";
import EditCategory from "../EditCategory";
import DeleteCategory from "../DeleteCategory";

interface CategoryListItemProps {
    category: FcrResourceCategory;
    isSelected: boolean;
    isDraggingOver: boolean;
    index: number;
    onSelect: (category: FcrResourceCategory) => void;
    onEditCategory: (category: FcrResourceCategory) => void;
    onDeleteCategory: (category: FcrResourceCategory) => void;
}

const CategoryListItem = ({
    category,
    isSelected,
    isDraggingOver,
    index,
    onSelect,
    onEditCategory,
    onDeleteCategory,
}: CategoryListItemProps) => {
    const classes = useStyles();
    const [isEditOpen, toggleIsEditOpen] = useReducer(state => !state, false);
    const [isDeleteOpen, toggleIsDeleteOpen] = useReducer(state => !state, false);

    const handleSelect = useCallback(() => {
        onSelect(category);
    }, [onSelect, category]);

    const handleEdit = useCallback((e) => {
        e.stopPropagation();
        toggleIsEditOpen();
    }, []);

    const handleDelete = useCallback((e) => {
        e.stopPropagation();
        toggleIsDeleteOpen();
    }, []);

    return (
        <>
            <Draggable
                key={category.id}
                draggableId={category.id}
                index={index}
            >
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                        <Box
                            className={clsx(classes.categoryItem, {
                                [classes.selected]: isSelected,
                                [classes.categoryItemHover]: !isDraggingOver,
                                [classes.categoryItemDragging]: snapshot.isDragging,
                            })}
                            onClick={handleSelect}
                            py={1.5} px={5}
                        >
                            <Button className={classes.dragHandleBtn} {...provided.dragHandleProps}>
                                <RearrageIcon />
                            </Button>
                            <Box display="flex" alignItems="center">
                                <Box display="flex" className="folder-icon" width={20} mr={1.5}>
                                    {isSelected ? <FolderOpened /> : <Folder />}
                                </Box>
                                <Typography variant="body1" className="category-name">
                                    {category.value}
                                </Typography>
                            </Box>
                            <Box display="flex" color="text.secondary">
                                <ButtonBase className="control-icon" onClick={handleEdit}>
                                    <EditIcon />
                                </ButtonBase>
                                <ButtonBase className="control-icon" onClick={handleDelete}>
                                    <DeleteIcon />
                                </ButtonBase>
                            </Box>
                        </Box>
                    </div>
                )}
            </Draggable>
            {isEditOpen && (
                <EditCategory
                    category={category}
                    isOpen={isEditOpen}
                    onSubmit={onEditCategory}
                    onClose={toggleIsEditOpen}
                    onDelete={toggleIsDeleteOpen}
                />
            )}
            {isDeleteOpen && (
                <DeleteCategory
                    category={category}
                    isOpen={isDeleteOpen}
                    onSubmit={onDeleteCategory}
                    onClose={toggleIsDeleteOpen}
                />
            )}
        </>
    );
};

export default CategoryListItem;