import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    alignRight: {
        marginLeft: "auto",
    },
    link: {
        textDecoration: "none",
    },
    titleGap: {
        marginLeft: theme.spacing(1.5),
    },
    body: {
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row-reverse",
        },
    },
    avatarColumn: {
        order: 3,
        [theme.breakpoints.up("md")]: {
            order: 4,
        },
    },
    mainColumn: {
        order: 4,
        [theme.breakpoints.up("md")]: {
            order: 3,
        },
    },
    openIdInfo: {
        marginBottom: "-4px",

        "& .Mui-error.Mui-required": {
            height: 0,
        },
    },
}), {
    name: "MemberPage",
});

export default useStyles;