import * as React from "react";

function SvgActionable(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Edit" fill="currentColor">
                    <path
                        d="M16,15 L16,16 L0,16 L0,15 L16,15 Z M13.4150832,1.58491678 C14.1949723,2.36480582 14.1949723,3.62925579 13.4150832,4.40914483 L3.88331356,13.9409145 L3.87625299,13.9338539 C3.76605704,14.0198784 3.64364329,14.0790792 3.51268711,14.112178 L0,15 L0.887822016,11.4873129 C0.921332509,11.3547279 0.981598292,11.2308992 1.06442097,11.1232196 L1.05908552,11.1166864 L10.5908552,1.58491678 C11.3707442,0.80502774 12.6351942,0.80502774 13.4150832,1.58491678 Z M1.81950892,11.8771098 L1.37822329,13.6217767 L3.12289016,13.1804911 L1.81950892,11.8771098 Z M11.3840035,2.21338279 L11.2969122,2.29097379 L2.47119954,11.1166864 L3.88331356,12.5288005 L12.7090262,3.70308781 C13.0989707,3.31314329 13.0989707,2.68091831 12.7090262,2.29097379 C12.3469349,1.92888245 11.7759384,1.90301878 11.3840035,2.21338279 Z"
                        id="Combined-Shape"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgActionable;
