import React from "react";
import { Box, Paper, Typography, Divider, CircularProgress, Link } from "@material-ui/core";
import NoDataMessage from "components/NoDataMessage";
import { FcrResource } from "types";
import ResourceIcon from "components/ResourceIcon";
import { Redirect } from "svgComponents";

import useStyles from "./styles";

interface ResourcePaperProps {
    resources: FcrResource[];
    isLoading: boolean;
}

function ResourcePaper({ resources, isLoading }: ResourcePaperProps) {
    const classes = useStyles();
    return (
        <Box width="100%" px={{ xs: 2, md: 7 }} pb={{ xs: 2, md: 6 }}>
            {resources.length > 0 ?
                <>
                    <Box py={4}>
                        <Typography variant="h1">{resources[0].resourceCategory?.value}</Typography>
                    </Box>
                    <Paper>
                        <Box pt={2.5} pb={2} px={4}>
                            <Typography variant="caption">
                                {resources.length} items
                            </Typography>
                        </Box>
                        <Divider />
                        {resources.map(resource => (
                            <Box key={resource.id}>
                                <Link
                                    href={resource.url || ""}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    underline="none"
                                >
                                    <Box display="flex" alignItems="center" py={2} px={4} fontWeight="500" className={classes.resourceItem}>
                                        <Box display="flex" justifyContent="center" alignItems="center"
                                            width="48px" height="48px" mr={3.5} borderRadius="90px" bgcolor="#F6F8F9">
                                            <ResourceIcon type={resource.type} color="#253A54"/>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body1">
                                                {resource.name}
                                            </Typography>
                                            <Box pl={1}>
                                                <Redirect />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Link>
                                <Divider />
                            </Box>
                        ))}
                    </Paper>
                </> :
                <Box display="flex" justifyContent="center" pt={12}>
                    {isLoading ?
                        <CircularProgress size={60}/> :
                        <NoDataMessage message="No Data" />
                    }
                </Box>
            }
        </Box>
    )
}

export default ResourcePaper;
