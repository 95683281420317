import React from "react";
import { get } from "lodash";
import { Button, FormHelperText, Grid, IconButton } from "@material-ui/core";
import { FieldArray, useFormikContext, ErrorMessage } from "formik";
import { AddIcon, DeleteIcon } from "svgComponents";
import { FormInput, FormMultilineInput } from "components/Form";
import { ComponentType } from "types/constants";
import { buildIdValue } from "helpers/fcrConfigurationHelper";
import { FcrItemConfiguration } from "types";

import useStyles from "../styles";

export default function FcrItemIdValues() {
    const classes = useStyles();
    const { values, errors, touched, setFieldTouched } = useFormikContext<FcrItemConfiguration>();

    const disabled = [ComponentType.singleCheckbox, ComponentType.compliance].includes(values.fcrItemTypeId);

    return (
        <FieldArray name="itemValues">
            {({ push, remove }) => (
                <Grid alignItems="center" container spacing={2}>
                    {values.fcrItemTypeId === ComponentType.freeInput ? (
                        <Grid className={classes.bodyText} item xs={12}>
                            <FormMultilineInput name="itemValues[0].value" placeholder="Value text" />
                            <ErrorMessage name="itemValues[0].value">
                                {(message) => <FormHelperText error>{message}</FormHelperText>}
                            </ErrorMessage>
                        </Grid>
                    ) : (
                        <>
                            {values.itemValues.map((itemValue, idx) => (
                                <React.Fragment key={itemValue.id}>
                                    <Grid item xs={1}>
                                        {idx + 1}.
                                    </Grid>
                                    <Grid item xs={11}>
                                        <FormInput
                                            className={classes.field}
                                            disabled={disabled}
                                            fullWidth
                                            name={`itemValues[${idx}].value`}
                                            placeholder="Value text"
                                            type="text"
                                            onBlur={() => setFieldTouched("itemValues")}
                                            error={!!(errors.itemValues && touched.itemValues && !get(values, `itemValues[${idx}].value`))}
                                            InputProps={{
                                                endAdornment: values.itemValues.length > 2 && (
                                                    <IconButton onClick={() => remove(idx)} disabled={disabled}>
                                                        <DeleteIcon height={20} width={20} />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ))}
                            <ErrorMessage name="itemValues">
                                {(message) =>
                                    typeof message === "string" ? (
                                        <>
                                            <Grid item xs={1}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={11}>
                                                <FormHelperText error>{message}</FormHelperText>
                                            </Grid>
                                        </>
                                    ) : null
                                }
                            </ErrorMessage>
                            <Grid item xs={1}>
                                &nbsp;
                            </Grid>
                            {!disabled && (
                                <Grid item xs={11}>
                                    <Button
                                        color={disabled ? "default" : "primary"}
                                        disabled={disabled}
                                        startIcon={<AddIcon />}
                                        onClick={() => push(buildIdValue({ order: values.itemValues.length + 1 }))}>
                                        Add Value
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            )}
        </FieldArray>
    );
}
