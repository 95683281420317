import * as React from "react";

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={19}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M18.87 6.73C18.72 6.28 18.33 5.95 17.86 5.88L12.94 5.16L10.74 0.7C10.53 0.27 10.09 0 9.61999 0C9.14999 0 8.70999 0.27 8.49999 0.7L6.3 5.16L1.38 5.88C0.909998 5.95 0.519988 6.28 0.369988 6.73C0.219988 7.18 0.349995 7.68 0.689995 8.01L4.24999 11.48L3.41 16.38C3.33 16.85 3.52 17.32 3.91 17.6C4.13 17.76 4.38999 17.84 4.63999 17.84C4.83999 17.84 5.03999 17.79 5.21999 17.7L9.61999 15.38L14.02 17.7C14.2 17.8 14.4 17.84 14.6 17.84C14.86 17.84 15.12 17.76 15.33 17.6C15.71 17.32 15.91 16.85 15.83 16.38L14.99 11.48L18.55 8.01C18.89 7.67 19.01 7.18 18.87 6.73ZM13.64 11.04L14.59 16.59L9.6 13.97L4.60999 16.59L5.55999 11.04L1.52999 7.11L7.1 6.3L9.58999 1.25L12.08 6.3L17.65 7.11L13.64 11.04Z" fill="currentColor" />
        </svg>
    );
}

export default SvgStar;