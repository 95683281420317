import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonProps } from "@material-ui/core";

interface LinkButtonProps extends ButtonProps {
    href: string;
}

const LinkButton = ({ href, children, onClick, ...props }: LinkButtonProps) => {
    const history = useHistory();

    const handleClick = useCallback((event) => {
        event.preventDefault();
        onClick && !props.disabled && onClick(event);
        history.push(href);
    }, [onClick, props.disabled, history, href]);

    return (
        <Button {...props} href={href} onClick={handleClick}>
            {children}
        </Button>
    );
};

export default LinkButton;