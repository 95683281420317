import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => createStyles({
    closeButton: {
        padding: 0,
        width: 40,
        height: 40,
    },
    logo: {
        marginLeft: 24,
        marginRight: 20,
    },
}));

export default useStyles;
