import { Box, MenuItem, Select, SortDirection, TextField } from "@material-ui/core";
import React from "react";

import { defaultAllClassesSortDirection, DEFAULT_CLASS_NAME_FILTER_VALUE } from "routes/MainClassListingsPage/shared-classes.data";
import { SortDirectionEnum } from "types/constants";

import { ClassesContext } from "context";

import { TuneIcon } from "svgComponents";

import useStyles from "./styles";

export interface AllMyClassesFiltersProps {
    allMyClassNameFilter: string | undefined;
    dateSortDirection: SortDirection | undefined;
    isFetchingAllMyClasses: boolean | undefined;
    sortEnabled: boolean | undefined;
}
const TuneFilterIcon = (props: unknown) => <TuneIcon height={24} {...props} />;

function AllMyClassesFilters({ allMyClassNameFilter, dateSortDirection, isFetchingAllMyClasses, sortEnabled }: AllMyClassesFiltersProps) {
    const classes = useStyles();
    const { setAllMyClassNameFilter, setAllMyClassesSorting } = React.useContext(ClassesContext);

    /** Update sort by date direction */
    const handleSortDirectionChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (!e?.target?.value || !setAllMyClassesSorting) {
            return;
        }
        setAllMyClassesSorting((e.target.value as SortDirectionEnum) ?? defaultAllClassesSortDirection.dateSortDirection);
    };

    /** Update the class name to filter by or revert to all class names */
    const handleClassNameFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined): void => {
        if (!setAllMyClassNameFilter) {
            return;
        }
        setAllMyClassNameFilter(event?.target?.value ?? DEFAULT_CLASS_NAME_FILTER_VALUE);
    };
    return (
        <Box className={classes.filtersContainer}>
            <Box minWidth={220}>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Filter By Class Name"
                    value={allMyClassNameFilter ?? ""}
                    onChange={handleClassNameFilterChange}
                    disabled={isFetchingAllMyClasses}
                />
            </Box>
            {dateSortDirection && (
                <Select
                    variant="outlined"
                    value={dateSortDirection}
                    onChange={handleSortDirectionChange}
                    IconComponent={TuneFilterIcon}
                    disabled={isFetchingAllMyClasses || !sortEnabled}
                    className={classes.filter}>
                    <MenuItem value={SortDirectionEnum.desc}>Most Recent</MenuItem>
                    <MenuItem value={SortDirectionEnum.asc}>Oldest</MenuItem>
                </Select>
            )}
        </Box>
    );
}

export default React.memo(AllMyClassesFilters);
