import React, { useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { ConfigurationContext } from "context";
import { UserProfile } from "types";
import { getUserFullName } from "helpers/userProfileHelper";
import { Routings } from "types/constants";

import useStyles from "./styles";

interface CoachedByProps {
    user: UserProfile;
}

export default function CoachedBy({ user }: CoachedByProps) {
    const classes = useStyles();
    const { currentUser } = useContext(ConfigurationContext);
    const fullName = useMemo(() => getUserFullName(user), [user]);
    const href = (currentUser?.id === user?.id) ? Routings.dashboard : `/team-profile/${user.id}`;

    return (
        <Typography variant="caption" align="center">
            Coached by <Link className={classes.link} to={href}>{fullName}</Link>
        </Typography>
    );
}