import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    classItemWrap: {
        padding: theme.spacing(2),
    },
    classItem: {
        borderRadius: 4,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
        },
    },
    classItemClickable: {
        cursor: "pointer",
    },
    classItemCell: {
        display: "flex",
        alignItems: "center",
    },
    classItemJustIcon: {
        justifyContent: "center",
        maxWidth: "170px",
    },
    classItemCellText: {
        paddingLeft: theme.spacing(3),
    },
    classItemCellTextBold: {
        paddingLeft: theme.spacing(3),
        fontWeight: 500,
    },
    link: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        "&, &:hover": {
            textDecoration: "underline",
        },
    },
    bold: {
        fontWeight: 500,
    },
}));

export default useStyles;
