import orderBy from "lodash/orderBy";
import intersectionWith from "lodash/intersectionWith";
import { UserProfile } from "types";

export const orderArrayUsers = <T>(
    array: T[]
): T[] => orderBy(array, ["isActive", "firstName", "lastName"], ["desc"])

export const getUsersByIds = (users: UserProfile[], ids: UserProfile["id"][]): UserProfile[] => (
    intersectionWith(users, ids, (user, id) => user.id === id)
);