import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography, useTheme } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import { CompanyLevel, CompanyLevelLabel, TreeSet } from "types";
import { AddIcon, EditIcon, Folder, FolderOpened, Checkmark } from "svgComponents";

import useStyles from "./styles";

export interface TreeNodeProps {
    element: TreeSet;
    maxLevelLabel: CompanyLevelLabel;
    levelLabels: Map<number, CompanyLevelLabel>;
    onEditCompanyLevel: (companyLevelTreeSet: TreeSet) => void;
    onCreateCompanyLevel: (parentCompanyLevel: CompanyLevel) => void;
}

function TreeNode({ element, maxLevelLabel, levelLabels, onEditCompanyLevel, onCreateCompanyLevel }: TreeNodeProps) {
    const [showActions, setShowActions] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const classes = useStyles({ backgroundColor: theme.colors.organizationalStructure[element.value.level - 1] });

    useEffect(() => {
        const rootNode = ref.current;

        const handleShowActions = () => setShowActions(true);
        const handleHideActions = () => setShowActions(false);

        rootNode?.children[0].addEventListener("mouseenter", handleShowActions);
        rootNode?.children[0].addEventListener("mouseleave", handleHideActions);

        return () => {
            rootNode?.children[0].removeEventListener("mouseenter", handleShowActions);
            rootNode?.children[0].removeEventListener("mouseleave", handleHideActions);
        };
    }, []);

    const childLevelLabel = levelLabels.get(element.value.level + 1);

    const handleEditCompanyLevel = (e: React.SyntheticEvent) => {
        e.preventDefault();
        onEditCompanyLevel(element);
    };

    const handleCreateCompanyLevel = (e: React.SyntheticEvent) => {
        e.preventDefault();
        onCreateCompanyLevel(element.value);
    };

    return (
        <TreeItem
            ref={ref}
            classes={classes}
            nodeId={element.value.id}
            expandIcon={<Folder />}
            collapseIcon={<FolderOpened />}
            endIcon={element.value.level === maxLevelLabel.level ? <Checkmark /> : <Folder />}
            label={
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box>
                        <Typography variant="h2">
                            {`L${element.value.level}: ${element.value.name}`}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                            {childLevelLabel
                                ? `${element.nodes.length} ${childLevelLabel.name}(s)`
                                : maxLevelLabel.name
                            }
                        </Typography>
                    </Box>
                    <Box display="flex" gridGap="15px">
                        {showActions && (
                            <Box display="flex" gridGap="15px">
                                <Button
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    onClick={handleEditCompanyLevel}
                                >
                                    Edit
                                </Button>
                            </Box>
                        )}
                        {childLevelLabel?.name && showActions && (
                            <Box display="flex" gridGap="15px">
                                <Button
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleCreateCompanyLevel}
                                >
                                    Add {childLevelLabel.name}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            }
        >
            {element.nodes.map((node) => (
                <TreeNode
                    key={node.value.id}
                    element={node}
                    maxLevelLabel={maxLevelLabel}
                    levelLabels={levelLabels}
                    onEditCompanyLevel={onEditCompanyLevel}
                    onCreateCompanyLevel={onCreateCompanyLevel}
                />
            ))}
        </TreeItem>
    );
}

export default TreeNode;