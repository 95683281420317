import React, { useRef, useCallback, useContext, useEffect, useMemo } from "react";
import { CompaniesContext } from "context";
import { useHistory } from "react-router-dom";
import { AdminRoutings } from "types/constants";
import clsx from "clsx";
import Slider from "react-slick";
import {
    AppBar,
    IconButton,
    Box,
    Button,
    Typography,
    Grid,
} from "@material-ui/core";
import { Menu, AddIcon, ChevronRight, ChevronLeft } from "svgComponents";
import { Company, HeaderProps } from "types";

import { CompaniesListSkeleton } from "./CompaniesListSkeleton";
import { CompaniesSlider } from "./CompaniesSlider";
import useStyles from "./styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CompaniesHeader({ isBarOpen, navBarOpen }: HeaderProps) {
    const history = useHistory();
    const classes = useStyles();
    const {
        allCompanies,
        currentCompany,
        setCurrentCompany,
    } = useContext(CompaniesContext);
    const sliderRef = useRef<Slider>(null);

    const onPrevHandler = () => {
        sliderRef.current?.slickPrev();
    };

    const onNextHandler = () => {
        sliderRef.current?.slickNext();
    };

    const handleCompany = useCallback(
        (company: Company) => {
            setCurrentCompany(company);
        },
        [setCurrentCompany]
    );

    const onCreateNewCompany = useCallback(
        () => {
            setCurrentCompany(null);
            history.push(AdminRoutings.company)
        },
        [history, setCurrentCompany]
    );

    const activeCompanies = useMemo(() => allCompanies.filter(({ isActive }) => isActive), [allCompanies]);

    const currentCompanyIndex = activeCompanies.findIndex((company) => company.id === currentCompany?.id);

    useEffect(() => {
        if (currentCompanyIndex < 0) return;

        const goToIndex = currentCompanyIndex + 3 > activeCompanies.length
            ? currentCompanyIndex + 3 - activeCompanies.length
            : currentCompanyIndex + 3;

        sliderRef.current?.slickGoTo(goToIndex);
    }, [currentCompanyIndex, activeCompanies.length]);

    return (
        <AppBar color="inherit" position="sticky" className={classes.header}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={8}
                mb={2.5}
            >
                {!isBarOpen && (
                    <IconButton edge="start" disableRipple onClick={() => navBarOpen()}>
                        <Menu />
                    </IconButton>
                )}
                <Typography variant="h1">
                    {currentCompany ? currentCompany.name : "Select Company"}
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    flexGrow="1"
                >
                    <Button color="primary" onClick={onCreateNewCompany}>
                        <Box
                            mr={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            color="primary.main"
                        >
                            <AddIcon />
                        </Box>
                        New Company
                    </Button>
                    {activeCompanies.length > 6 && (
                        <Box ml={3} display="flex" alignItems="center">
                            <Button
                                onClick={onPrevHandler}
                                className={clsx(classes.sliderBtn, classes.sliderLeftBtn)}
                            >
                                <ChevronLeft />
                            </Button>
                            <Button
                                onClick={onNextHandler}
                                className={clsx(classes.sliderBtn, classes.sliderRightBtn)}
                            >
                                <ChevronRight />
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box>
                {activeCompanies.length > 6 ? (
                    <CompaniesSlider
                        sliderRef={sliderRef}
                        companiesList={activeCompanies}
                        currentCompanyIndex={currentCompanyIndex}
                        handleCompany={handleCompany}
                    />
                ) : (
                    <Box px={8}>
                        <Grid container spacing={2}>
                            {!!activeCompanies.length ? (
                                activeCompanies.map((company, index) => (
                                    <Grid
                                        item
                                        xs={2}
                                        key={company.id}
                                        className={clsx(
                                            {
                                                [classes.slideActive]: index === currentCompanyIndex,
                                            },
                                            classes.slide
                                        )}
                                    >
                                        <Button
                                            className={classes.content}
                                            onClick={() =>
                                                handleCompany(company)
                                            }
                                        >
                                            {company.logo ? (
                                                <img src={company.logo} alt={company.name} />
                                            ) : (
                                                company.name
                                            )}
                                        </Button>
                                    </Grid>
                                ))
                            ) : (
                                <CompaniesListSkeleton />
                            )}
                        </Grid>
                    </Box>
                )}
            </Box>
        </AppBar>
    );
}

export default CompaniesHeader;
