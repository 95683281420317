import React, { useCallback, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Grid, Typography, useTheme, Avatar } from "@material-ui/core";
import { EvaluationResultStatusEnum, EvaluationStatusText } from "types/constants";
import { TraineeClassInformation } from "types/Certification";
import { formatShortDate } from "helpers/dateHelpers";
import { getAcronym } from "helpers/stringHelpers";
import { getEvaluationStatusDateCaption, getEvaluationUrl } from "helpers/certificationHelper";

import ConditionalLink from "components/shared/ConditionalLink";
import ConditionalTooltip from "components/shared/ConditionalTooltip";

import { ConfigurationContext } from "context";

import useStyles from "./styles";

interface ClassTraineesItemProps {
    trainee: TraineeClassInformation;
}

function ClassTraineesItem({ trainee }: ClassTraineesItemProps) {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles({ id: trainee.traineeId });
    const { currentUser } = useContext(ConfigurationContext);
    const shortName = useMemo(() => getAcronym(trainee.traineeName), [trainee.traineeName]);

    const statusColor: string = useMemo(() => {
        switch (trainee.evaluationStatusId) {
            case EvaluationResultStatusEnum.new:
                return theme.colors.statusLabel.background.green;
            case EvaluationResultStatusEnum.inProgress:
                return theme.colors.structure.pageBackground;
            case EvaluationResultStatusEnum.completed:
                return theme.colors.structure.pageBackground;
            default:
                return theme.colors.statusLabel.background.green;
        }
    }, [trainee, theme]);

    const showLink: boolean = useMemo(() => {
        if (trainee.evaluationStatusId !== EvaluationResultStatusEnum.completed) {
            return !trainee.isSelfEvaluated;
        }
        return true;
    }, [trainee.evaluationStatusId, trainee.isSelfEvaluated]);

    const getToolTipText: string = useMemo(() => (showLink ? "" : "This self-evaluation has not yet been completed."), [showLink]);

    const handleClickClass = useCallback(async () => {
        if (currentUser) {
            const url = await getEvaluationUrl(trainee, currentUser.id);
            history.push(url);
        }
    }, [currentUser, history, trainee]);

    return (
        <ConditionalLink onClick={handleClickClass} condition={showLink}>
            <ConditionalTooltip textContent={getToolTipText} condition={!!getToolTipText}>
                <div className={classes.traineeItemWrap}>
                    <Grid container spacing={3} className={clsx(classes.traineeItem)}>
                        <Grid item md={5} className={clsx(classes.traineeItemCell)}>
                            <Avatar alt={trainee.traineeName} src={trainee.traineeAvatar} className={clsx(classes.avatar)}>
                                {shortName}
                            </Avatar>
                            <div className={classes.traineeItemCellTextBold}>
                                <Typography variant="h2">{trainee.traineeName}</Typography>
                                <Typography variant="caption">Trainee</Typography>
                            </div>
                        </Grid>
                        <Grid item md={2} className={classes.traineeItemCell}>
                            <div className={classes.traineeItemStatus} style={{ backgroundColor: statusColor }}>
                                {EvaluationStatusText[trainee.evaluationStatusId]}
                            </div>
                        </Grid>
                        {trainee.evaluationStatusId !== EvaluationResultStatusEnum.new && (
                            <Grid item>
                                <Typography>{formatShortDate(trainee.evaluationStatusDate)}</Typography>
                                <Typography variant="caption">{getEvaluationStatusDateCaption(trainee.evaluationStatusId)}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </ConditionalTooltip>
        </ConditionalLink>
    );
}

export default React.memo(ClassTraineesItem);
