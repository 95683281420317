import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import Header from "components/Header";
import NavigationLink from "components/NavigationLink";
import NoDataMessage from "components/NoDataMessage";
import PaperSection from "components/PaperSection";
import { ClassesContext, ConfigurationContext } from "context";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import BackArrow from "svgComponents/BackArrow";
import { Routings } from "types/constants";

import { HeaderProps } from "types";

import { CLASSES_STEP_SIZE, THROTTLE_CLIENT_SIDE_SCROLL_LOADING_SIMULATION } from "../shared-classes.data";
import AllScheduledClassesFilters from "./AllScheduledClassesFilters";
import AllScheduledClassesItem from "./AllScheduledClassesItem";

import useStyles from "./styles";

export default function AllSchedClassesPage({ isBarOpen, navBarOpen }: HeaderProps) {
    const classes = useStyles();

    const { companyCertificationConfiguration } = useContext(ConfigurationContext);

    const {
        //Filters
        allSchedClassToShowCountFilter,
        setAllSchedClassToShowCountFilter,
        allSchedClassNameFilter,

        //sort
        allSchedClassesSorting,

        //Dto
        fetchAllSchedClasses,
        postSortFilterAllSchedClasses,
        isFetchingAllSchedClasses,
        resetAllSchedClassesState,
    } = React.useContext(ClassesContext);

    const [canShowMoreClasses, setCanShowMoreClasses] = useState(true);

    //Client side infinite scroll requires that we either simulate loading more items or we
    const currentAllSchedClassesCount = postSortFilterAllSchedClasses?.length ?? 0;

    //Take the shown count as either the full collection, or the current filtered number of items to show
    const currentShownCount = Math.min(currentAllSchedClassesCount, allSchedClassToShowCountFilter ?? 0);
    const itemsRef = useRef<HTMLDivElement>(null);

    //Memoize the section of classes that we're going to display right now
    const shownScheduledClasses = useMemo(
        () => (postSortFilterAllSchedClasses ? postSortFilterAllSchedClasses.slice(0, allSchedClassToShowCountFilter) : []),
        [postSortFilterAllSchedClasses, allSchedClassToShowCountFilter]
    );

    const onShowMore = useCallback(() => {
        if (!setAllSchedClassToShowCountFilter) {
            return;
        }
        // Simulate infinite scroll loading using constant so they can see the spinner
        setTimeout(() => {
            setAllSchedClassToShowCountFilter(currentShownCount + CLASSES_STEP_SIZE);
        }, THROTTLE_CLIENT_SIDE_SCROLL_LOADING_SIMULATION);
    }, [setAllSchedClassToShowCountFilter, currentShownCount]);

    //Set up the initial call to get all scheduled classes
    useEffect(() => {
        if (!postSortFilterAllSchedClasses && fetchAllSchedClasses) {
            fetchAllSchedClasses();
        }
    }, [postSortFilterAllSchedClasses, fetchAllSchedClasses]);

    //Set up effect that resets the page when the user leaves
    useEffect(() => {
        if (resetAllSchedClassesState) {
            return resetAllSchedClassesState;
        }
    }, [resetAllSchedClassesState]);

    //Set up indicator for infinite scroll loading on scroll end
    useEffect(() => {
        if (currentShownCount < currentAllSchedClassesCount) {
            setCanShowMoreClasses(true);
        } else {
            setCanShowMoreClasses(false);
        }
    }, [currentShownCount, currentAllSchedClassesCount]);

    const dynamicCountString = `${currentShownCount} ${canShowMoreClasses ? `from ${postSortFilterAllSchedClasses?.length} ` : ""}items`;
    const loading = isFetchingAllSchedClasses ?? true;
    const canShowTable = !loading && shownScheduledClasses?.length > 0;
    const canShowFilter = loading || shownScheduledClasses;
    const canSortData = (postSortFilterAllSchedClasses?.length ?? 0) > 0 ?? false;
    const canShowNoData = (postSortFilterAllSchedClasses?.length ?? -1) === 0 && !loading;

    const sectionlabel = useMemo(
        () => companyCertificationConfiguration?.section1Label,
        [companyCertificationConfiguration?.section1Label]
    );

    return (
        <>
            <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
            {/* TODO Extract to reusable pure component */}
            <div>
                {
                    <NavigationLink href={{ pathname: Routings.classes }} className={classes.back}>
                        <Box display="flex" alignItems="center" px={6} pt={4} pb={4}>
                            <BackArrow />
                            <Box ml={2.5}>Back To {sectionlabel}</Box>
                        </Box>
                    </NavigationLink>
                }
            </div>
            <Box display="flex" justifyContent="center">
                <PaperSection
                    px={6}
                    width={1}
                    title={
                        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" width={1}>
                            <Box fontWeight={600} whiteSpace="nowrap">
                                <Typography variant="h2">All Scheduled {sectionlabel}</Typography>
                            </Box>
                            {canShowFilter && (
                                <AllScheduledClassesFilters
                                    allSchedClassNameFilter={allSchedClassNameFilter}
                                    dateSortDirection={allSchedClassesSorting}
                                    isFetchingAllSchedClasses={loading}
                                    sortEnabled={canSortData}
                                />
                            )}
                        </Box>
                    }>
                    {canShowTable && (
                        <InfiniteScroll
                            scrollableTarget="app"
                            dataLength={shownScheduledClasses.length}
                            next={onShowMore}
                            hasMore={canShowMoreClasses}
                            loader={
                                canShowMoreClasses ? (
                                    <Box p={4} textAlign="center">
                                        <CircularProgress size={40} />
                                    </Box>
                                ) : null
                            }
                            hasChildren>
                            <Box px={5} py={2}>
                                <Typography variant="caption">{dynamicCountString}</Typography>
                            </Box>
                            <Divider />
                            <div {...{ ref: itemsRef }}>
                                {shownScheduledClasses.map((scheduledClass, i) => (
                                    <AllScheduledClassesItem key={i} scheduledClass={scheduledClass} />
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                    {canShowNoData && <NoDataMessage pb={5} pt={7} message="There's nothing to show here yet" />}
                    {/* Before context state is defined assume we're going to load data, and reduce amount of UI movement and change. */}
                    {loading && (
                        <Box p={4} textAlign="center">
                            <CircularProgress size={40} />
                        </Box>
                    )}
                </PaperSection>
            </Box>
        </>
    );
}
