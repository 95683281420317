import * as React from "react";

function SvgSessions(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg height="27" width="20" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M17.19 26.0498H2.15C0.96 26.0498 0 25.0898 0 23.9098V5.90977C0 4.71977 0.96 3.75977 2.15 3.75977H5.32V5.00977H2.15C1.65 5.00977 1.25 5.40977 1.25 5.90977V23.8998C1.25 24.3998 1.65 24.7998 2.15 24.7998H17.19C17.69 24.7998 18.09 24.3998 18.09 23.8998V5.90977C18.09 5.40977 17.69 5.00977 17.19 5.00977H13.9V3.76977H17.19C18.38 3.76977 19.34 4.72977 19.34 5.91977V23.9098C19.34 25.0898 18.37 26.0498 17.19 26.0498Z" />
            <path d="M13.8204 6.25H5.52039C5.17039 6.25 4.90039 5.97 4.90039 5.63V4.03C4.90039 2.84 5.86039 1.88 7.05039 1.88H7.28039C7.54039 0.8 8.51039 0 9.67039 0C10.8204 0 11.7904 0.79 12.0704 1.86L12.3304 1.87C13.4904 1.87 14.4504 2.83 14.4504 4.02V5.62C14.4504 5.97 14.1704 6.25 13.8204 6.25ZM6.14039 5H13.2004V4.02C13.2004 3.52 12.8004 3.12 12.3004 3.12L11.5404 3.09C11.4304 3.1 11.2604 3.05 11.1504 2.96C11.0004 2.84 10.9004 2.66 10.9004 2.47C10.9004 1.8 10.3504 1.25 9.68039 1.25C9.01039 1.25 8.46039 1.8 8.46039 2.47C8.46039 2.74 8.25039 2.98 8.00039 3.07C7.92039 3.1 7.85039 3.12 7.78039 3.12C7.77039 3.12 7.76039 3.12 7.74039 3.12H7.04039C6.54039 3.12 6.14039 3.52 6.14039 4.02V5Z" />
            <path d="M8.78988 18.1598C8.62988 18.1598 8.46988 18.0998 8.34988 17.9798L5.62988 15.2798L6.50988 14.3898L8.73988 16.5998L12.8599 11.5898L13.8299 12.3798L9.26988 17.9198C9.15988 18.0698 8.99988 18.1498 8.81988 18.1598C8.80988 18.1598 8.79988 18.1598 8.78988 18.1598Z" />
        </svg>
    );
}

export default SvgSessions;
