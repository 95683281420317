import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import RichText from "components/RichText";
import { MessageType } from "types";
import { SizeEnum } from "types/constants";
import { formatDateTime } from "helpers/dateHelpers";

import AvatarWithScore from "../AvatarWithScore";

interface MessageProps {
    message: MessageType;
}

const Message = ({ message }: MessageProps) => {
    const username = `${message.fromUser.firstName} ${message.fromUser.lastName}`;
    const formattedDateTime = formatDateTime(message.createdDate);

    const messageUserWithAvatar = useMemo(() => ({
        ...message.fromUser,
        avatar: message.avatar,
    }), [message]);

    return (
        <Grid container justifyContent="space-between">
            <Grid item xs={12} lg={3}>
                <Box display="flex" alignItems="center" mb={2}>
                    <AvatarWithScore user={messageUserWithAvatar} size={SizeEnum.small}/>
                    <Box display="flex" flexDirection="column" fontWeight={500} ml={2}>
                        <Typography variant="body1">
                            {username}
                        </Typography>
                        <Typography variant="caption">
                            {formattedDateTime}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Box display="flex" flexDirection="column" mr={2}>
                    <Box fontWeight={600} mb={2}>
                        <Typography variant="body1">
                            {message.subject}
                        </Typography>
                    </Box>
                    <Typography variant="caption" color="textPrimary">
                        <RichText text={message.messageContent}/>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default React.memo(Message);