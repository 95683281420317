import { makeStyles, Theme } from "@material-ui/core"
import { lighten } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        maxWidth: "100%",
        maxHeight: "100%",
    },
    defaultMenuItem: {
        display: "none",
    },
    logoPreviewBoxBase: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 6,
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    logoPreviewBoxPlaceholder: {
        backgroundColor: lighten(theme.palette.primary.main, 0.95),
        border: "1px dashed",
        borderColor: lighten(theme.palette.primary.main, 0.5),
    },
    logoPreviewBoxCompany: {
        border: "1px solid",
        borderColor: theme.palette.info.dark,
    },
    logoPreviewBoxError: {
        borderColor: theme.palette.error.main,
    },
}));

export default useStyles;