import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    memberList: {
        columnGap: theme.spacing(3),
        gridTemplateColumns: "repeat(1, 1fr)",
        rowGap: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
    },
    link: {
        textDecoration: "none",
    },
}));

export default useStyles;
