import * as React from "react";

function SvgGraph(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            width="24" 
            height="21" 
            viewBox="0 0 24 21" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M21.5441 8.71094V18.4967C21.5441 19.4519 20.7626 20.2333 19.8075 20.2333H4.57743C3.6223 20.2333 2.84082 19.4519 2.84082 18.4967V14.5894M9.04932 7.64258V19.6078M15.1797 13.061V19.6081M3.60498 8.84122L8.0507 3.6748M10.352 3.6748L14.0944 7.20881M16.4819 7.33877L20.5369 4.49072M2.84103 11.1943C3.56036 11.1943 4.14349 10.6111 4.14349 9.89181C4.14349 9.17249 3.56036 8.58936 2.84103 8.58936C2.1217 8.58936 1.53857 9.17249 1.53857 9.89181C1.53857 10.6111 2.1217 11.1943 2.84103 11.1943ZM9.04953 4.14349C9.76886 4.14349 10.352 3.56036 10.352 2.84103C10.352 2.1217 9.76886 1.53857 9.04953 1.53857C8.3302 1.53857 7.74707 2.1217 7.74707 2.84103C7.74707 3.56036 8.3302 4.14349 9.04953 4.14349ZM15.1794 9.59662C15.8987 9.59662 16.4819 9.01349 16.4819 8.29416C16.4819 7.57483 15.8987 6.9917 15.1794 6.9917C14.4601 6.9917 13.877 7.57483 13.877 8.29416C13.877 9.01349 14.4601 9.59662 15.1794 9.59662ZM21.5442 4.97699C22.2635 4.97699 22.8466 4.39386 22.8466 3.67453C22.8466 2.9552 22.2635 2.37207 21.5442 2.37207C20.8248 2.37207 20.2417 2.9552 20.2417 3.67453C20.2417 4.39386 20.8248 4.97699 21.5442 4.97699Z" stroke="#51A9BE" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgGraph;
