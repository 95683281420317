import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Grid, Typography, GridSize, CircularProgress } from "@material-ui/core";

import { ConfigurationContext } from "context";
import NoDataMessage from "components/NoDataMessage";
import PaperSection from "components/PaperSection";
import { DownArrow, UpArrow } from "svgComponents";
import { getUserCoachingDevelopment } from "services/wizardServices";
import { CoachingDevelopment, TeamProfileRouteParams, UserProfile } from "types";
import { CoachingPeriodEnum } from "types/constants";
import useCoachingDevelopmentHistory from "hooks/useCoachingDevelopmentHistory";

import BackToProfile from "../../BackToProfile";
import useStyles from "./styles";

interface CoachingDevelopmentHistoryProps {
    user: UserProfile,
}

function CoachingDevelopmentHistory({ user }: CoachingDevelopmentHistoryProps) {
    const classes = useStyles();
    const { userId } = useParams<TeamProfileRouteParams>();
    const [history, setHistory] = useState<CoachingDevelopment[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const historyMonthToDate = useCoachingDevelopmentHistory(history, CoachingPeriodEnum.monthToDate);
    const historyYearToDate = useCoachingDevelopmentHistory(history, CoachingPeriodEnum.yearToDate);
    const historyRolling12Month = useCoachingDevelopmentHistory(history, CoachingPeriodEnum.rolling12Months);
    const { fcrSectionConfiguration } = useContext(ConfigurationContext);

    const columns: Array<{ name: string, xs: GridSize }> = useMemo(() => [
        { name: "Time Period", xs: 3 },
        { name: "Coaching Sessions", xs: 2 },
        { name: `${fcrSectionConfiguration?.section1Label} Reports Initiated`, xs: 2 },
        { name: `${fcrSectionConfiguration?.section2Label} Skill Development`, xs: 2 },
        { name: `${fcrSectionConfiguration?.section3Label} Plans Completed`, xs: 3 },
    ], [fcrSectionConfiguration]);

    useEffect(() => {
        const fetchData = async () => {
            setHistory([]);
            setIsLoading(true);
            const { coachingDevelopment } = await getUserCoachingDevelopment(user.id);
            setHistory(coachingDevelopment);
            setIsLoading(false);
        }
        fetchData();
    }, [user.id]);

    const arrow = useCallback((isIncrease: boolean) => (isIncrease ? <UpArrow fill="#5FC363" /> : <DownArrow />), []);

    return (
        <>
            <BackToProfile userId={userId} />
            {history.length ?
                <PaperSection
                    title={
                        <Box fontWeight="600">
                            <Typography variant="h2">Coaching Development</Typography>
                        </Box>
                    }
                    mb={{ xs: 3, sm: 6 }}
                >
                    <Box px={{ xs: 2, lg: 5 }} pt={2} pb={2.5}>
                        <Grid container spacing={2} alignItems="center">
                            {columns.map(({ name, xs }) => (
                                <Grid item xs={xs} key={name}>
                                    <Box display="flex" justifyContent="center" fontWeight="600">
                                        <Typography variant="h3" align="center" className={classes.columnName}>
                                            {name}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Divider />
                    {[historyMonthToDate, historyYearToDate, historyRolling12Month].map(history => (
                        <React.Fragment key={history.periodLabel}>
                            <Box px={{ xs: 2, lg: 5 }} pt={2} pb={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Box display="flex" justifyContent="center" alignItems="center" fontWeight="500">
                                            <Typography>{history.periodLabel}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box display="flex" justifyContent="center" alignItems="center" fontWeight="500">
                                            <Typography>{history.sessions.total}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box display="flex" flexDirection={{ xs: "column", lg: "row" }} justifyContent="center" alignItems="center">
                                            <Typography variant="caption">({arrow(history.goals.isIncreased)} {history.goals.dynamic}%) </Typography>
                                            <Box fontWeight="500" pl={0.5}>
                                                <Typography>{history.goals.score}%</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box display="flex" flexDirection={{ xs: "column", lg: "row" }} justifyContent="center" alignItems="center">
                                            <Typography variant="caption">({arrow(history.focus.isIncreased)} {history.focus.dynamic}) </Typography>
                                            <Box fontWeight="500" pl={0.5}>
                                                <Typography>{history.focus.score}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box display="flex" flexDirection={{ xs: "column", lg: "row" }} justifyContent="center" alignItems="center">
                                            <Typography variant="caption">({arrow(history.plans.isIncreased)} {history.plans.dynamic}%) </Typography>
                                            <Box fontWeight="500" pl={0.5}>
                                                <Typography>{history.plans.score}%</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                        </React.Fragment>
                    ))}
                </PaperSection> :
                <Box display="flex" justifyContent="center" pt={12}>
                    {isLoading ?
                        <CircularProgress size={60} /> :
                        <NoDataMessage message="No Data" />
                    }
                </Box>

            }
        </>
    )
}

export default CoachingDevelopmentHistory;
