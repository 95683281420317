import React, { useState, useEffect } from "react";
import round from "lodash/round";
import sortBy from "lodash/sortBy";
import { Box, Paper, Typography, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { SizeEnum } from "types/constants";
import AvatarWithScore from "components/AvatarWithScore";
import { getUserFullName } from "helpers/userProfileHelper";
import NoDataMessage from "components/NoDataMessage";
import { SkillHistoryRouteParams, SkillOverviewHistory } from "types";
import { getCoachSkillOverviewHistory, getCoachSkillOverviewHistoryForHighLevelLead } from "services/wizardServices";
import { prepareForSort } from "helpers/stringHelpers";

import BackToDashboard from "../BackToDashboard";
import useStyles from "./styles";

interface TeamSkillHistoryProps {
    isHighLevel?: boolean;
}

function TeamSkillHistory({ isHighLevel }: TeamSkillHistoryProps) {
    const { userId, skillId } = useParams<SkillHistoryRouteParams>();
    const classes = useStyles();
    const [skillOverviewHistory, setSkillOverviewHistory] = useState<SkillOverviewHistory>();
    const sortedSkillOverviews = sortBy(skillOverviewHistory?.userSkillOverviews, (skillOverview) => prepareForSort(getUserFullName(skillOverview.user)));

    useEffect(() => {
        if (!userId) return;
        setSkillOverviewHistory(undefined);
        (isHighLevel) ?
            getCoachSkillOverviewHistoryForHighLevelLead(userId, skillId).then(setSkillOverviewHistory) :
            getCoachSkillOverviewHistory(userId, skillId).then(setSkillOverviewHistory)
    }, [isHighLevel, userId, skillId]);

    return (
        <Box px={7.5}>
            <BackToDashboard userId={userId} state={{ skillId }} />
            <Paper>
                {skillOverviewHistory && (
                    <Box px={4} pt={3} pb={2}>
                        <Typography variant="h1">{skillOverviewHistory.fcrCoachingSkillName}</Typography>
                    </Box>
                )}
                {skillOverviewHistory && sortedSkillOverviews.length > 0 && (
                    <Table classes={{ root: classes.table }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Team Members</TableCell>
                                <TableCell align="center">
                                    <Box>Times coached</Box>
                                    <Typography variant="caption">Rolling 12 Months</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Box>Avg. Rating</Box>
                                    <Typography variant="caption">Rolling 12 Months</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedSkillOverviews.map((skillOverview) => (
                                <TableRow key={skillOverview.user.id}>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            <Box mr={2}>
                                                <AvatarWithScore size={SizeEnum.mediumSmaller} user={skillOverview.user} />
                                            </Box>
                                            <Box mr={2} fontWeight={600}>
                                                <Typography variant="h3">
                                                    {getUserFullName(skillOverview.user)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        {skillOverview.rolling12MonthsTimesCoached || 0}
                                    </TableCell>
                                    <TableCell align="center">
                                        {typeof skillOverview.rolling12MonthsAverageScore === "number"
                                            ? round(skillOverview.rolling12MonthsAverageScore, 2)
                                            : "-"}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                {skillOverviewHistory && sortedSkillOverviews.length === 0 && (
                    <Box p={2}>
                        <NoDataMessage message="Nothing to show here" />
                    </Box>
                )}
                {!skillOverviewHistory && (
                    <Box p={4} textAlign="center">
                        <CircularProgress size={40} />
                    </Box>
                )}
            </Paper>
        </Box>
    )
}

export default TeamSkillHistory;
