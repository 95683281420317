import React, { ReactElement, useCallback, useState } from "react";
import ReactQuill from "react-quill";
import { Typography, Box } from "@material-ui/core";
import clsx from "clsx";

import { cleanupRichText, sanitizeRichText } from "helpers/stringHelpers";

import "react-quill/dist/quill.snow.css";
import useStyles from "./styles";

interface MultilineInputProps {
    name?: string;
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    children?: ReactElement;
    placeholder?: string;
    error?: boolean | string;
    disabled?: boolean;
}

const modules = {
    clipboard: {
        matchVisual: false,
    },
    toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
    ],
};

function MultilineInput({
    name,
    value,
    onChange,
    onBlur,
    children,
    placeholder = "Enter some text...",
    error = false,
    disabled = false,
}: MultilineInputProps) {
    const classes = useStyles();
    const [sanitizedValue] = useState(sanitizeRichText(value));

    const handleChange = useCallback(
        (value: string) => {
            // Used to check if the rich text string is effectively empty and only contains tags
            // since Quill doesn't clean such strings by itself
            onChange(cleanupRichText(value) ? value : "");
        },
        [onChange]
    );

    return (
        <>
            {name && (
                <Box my={2} fontWeight="600">
                    <Typography variant="h3">{name}</Typography>
                </Box>
            )}
            <Box color="text.primary">
                <ReactQuill
                    theme="snow"
                    defaultValue={sanitizedValue || ""}
                    value={value || ""}
                    onChange={handleChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    modules={modules}
                    className={clsx(classes.textarea, children && "extendedBorder", {
                        [classes.textareaError]: !!error,
                    })}
                    readOnly={disabled}
                />
            </Box>
            {error && typeof error === "string" && (
                <Box py={0.5} color="text.primary">
                    <Typography variant="caption" color="error">
                        {error}
                    </Typography>
                </Box>
            )}
            {children && (
                <Box px={3} py={1} className={classes.footer}>
                    {children}
                </Box>
            )}
        </>
    );
}

export default MultilineInput;
