import React, { useCallback, useState } from "react";
import { Box, Button, CircularProgress, Dialog, Typography } from "@material-ui/core";

import { deleteResourceCategory } from "services/resourceServices";
import { FcrResourceCategory } from "types";

interface DeleteCategoryProps {
    category: FcrResourceCategory;
    isOpen: boolean;
    onSubmit: (category: FcrResourceCategory) => void;
    onClose: () => void;
}

const DeleteCategory = ({ category, isOpen, onSubmit, onClose }: DeleteCategoryProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        await deleteResourceCategory(category.id);
        onSubmit(category);
        setIsLoading(false);
        onClose();
    }, [category, onSubmit, onClose]);

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <Box p={4}>
                <Typography variant="h1" component="span">
                    Delete Category
                </Typography>
            </Box>
            <Box py={3} px={4} bgcolor="#F6F8F9" fontWeight={500}>
                <Typography variant="body1">
                    Warning: All resources assigned to {category.value} will also be deleted.
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row-reverse" justifyContent="flex-start" p={4}>
                <Box ml={2.5}>
                    <Button variant="outlined" size="large" onClick={onClose}>
                        Cancel
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        disableElevation
                    >
                        {isLoading ? <CircularProgress size={24}/> : "Delete"}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default DeleteCategory;