import * as React from "react";

function SvgNoData(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={39}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.58 12.52c-.34 0-.62.28-.62.62v17.1c0 1.33-1.08 2.41-2.41 2.41h-7.7a.61.61 0 00.24-.48V20.74c0-.35-.28-.62-.62-.62-.34 0-.62.28-.62.62v11.43c0 .2.1.36.24.48h-9.93a.61.61 0 00.24-.48V11.28c0-.35-.28-.62-.62-.62-.34 0-.62.28-.62.62v20.89c0 .2.1.36.24.48H5.94c-1.33 0-2.41-1.08-2.41-2.41v-6.83c0-.35-.28-.62-.62-.62-.34 0-.62.28-.62.62v6.83c0 2.02 1.64 3.66 3.66 3.66h26.6c2.02 0 3.66-1.64 3.66-3.66v-17.1a.639.639 0 00-.63-.62z"
        fill="currentColor"
      />
      <path
        d="M35.58 1.45c-1.6 0-2.9 1.3-2.9 2.9 0 .45.11.87.29 1.25l-6.6 4.65s0 .01-.01.01a2.867 2.867 0 00-3.47-.27L16.46 3.9c.12-.31.19-.65.19-1 0-1.6-1.3-2.9-2.9-2.9-1.6 0-2.9 1.3-2.9 2.9 0 .51.14.98.37 1.4l-7.13 8.28c-.36-.16-.76-.26-1.19-.26-1.6 0-2.9 1.3-2.9 2.9 0 1.6 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9 0-.72-.27-1.37-.71-1.88l6.96-8.08c.48.35 1.06.56 1.69.56.79 0 1.5-.32 2.02-.83l6.24 5.9c-.29.45-.46.98-.46 1.55 0 1.6 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9 0-.41-.09-.81-.25-1.16l6.64-4.67c.5.41 1.13.67 1.83.67 1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.29-2.93-2.88-2.93zM2.91 16.86c-.91 0-1.65-.74-1.65-1.65a1.652 1.652 0 012.57-1.37s.01 0 .01.01c.43.3.72.8.72 1.36 0 .91-.74 1.65-1.65 1.65zM13.75 4.55c-.91 0-1.65-.74-1.65-1.65 0-.91.74-1.65 1.65-1.65.91 0 1.65.74 1.65 1.65 0 .91-.74 1.65-1.65 1.65zm10.7 9.52c-.91 0-1.65-.74-1.65-1.65 0-.91.74-1.65 1.65-1.65.91 0 1.65.74 1.65 1.65 0 .91-.74 1.65-1.65 1.65zM35.58 6c-.5 0-.95-.23-1.25-.59-.24-.29-.4-.65-.4-1.06 0-.91.74-1.65 1.65-1.65.91 0 1.65.74 1.65 1.65 0 .91-.75 1.65-1.65 1.65z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgNoData;
