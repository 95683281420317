import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@material-ui/core";
import { FcrItemConfiguration } from "types";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import AdminListItem from "components/AdminListItem";
import { Settings } from "svgComponents";
import { reorder } from "helpers/arrayHelpers";
import { orderBy } from "lodash";
import { SkillsIcon } from "svgComponents/MenuIcons";

export interface DragAndDropProps {
    fcrItems: FcrItemConfiguration[];
    isLoading?: boolean;
    onEditFcrItem: (fcrItems: FcrItemConfiguration) => void;
    onFcrItemReordered: (fcrItems: FcrItemConfiguration[]) => void;
}

function FcrDragAndDrop({ fcrItems, isLoading, onEditFcrItem, onFcrItemReordered }: DragAndDropProps) {
    const [currentFcrItems, setCurrentFcrItems] = useState(fcrItems);
    useEffect(() => {
        setCurrentFcrItems(orderBy(fcrItems.filter(({ isVisibleToRep }) => isVisibleToRep), ["order"]))
    }, [fcrItems]);

    const handleDragEnd = (result: DropResult) => {
        const { destination, source } = result;
        if (!destination || destination.index === source.index) {
            return;
        }
        const reorderFcrItem = reorder(currentFcrItems, source.index, destination.index);
        setCurrentFcrItems(reorderFcrItem);
        onFcrItemReordered(reorderFcrItem);
    };
    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="fcrItems">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {currentFcrItems.map((fcrItem, idx) => (
                                <Draggable
                                    key={fcrItem.id}
                                    draggableId={fcrItem.id}
                                    index={idx}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <AdminListItem
                                                key={fcrItem.id}
                                                icon={<Settings/>}
                                                loading={isLoading}
                                                title={fcrItem.elementHeader}
                                                description={fcrItem.elementBody}
                                                onEdit={() => onEditFcrItem(fcrItem)}
                                                onClick={() => onEditFcrItem(fcrItem)}
                                                dragHandleProps={provided.dragHandleProps}
                                                isDragging={snapshot.isDragging}
                                            />
                                        </div>
                                    )}

                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

export default FcrDragAndDrop;