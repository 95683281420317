import { Company, FcrResource, Team, UserProfile } from "types";
import { AuthProviderEnum } from "types/constants";

import API from "./apiService";

export const uploadUserLogo = async (memberId: string, avatar: FcrResource): Promise<FcrResource> => {
    const result = await API.post(`/user/uploadAvatar/${memberId}`, avatar);
    return result.data;
};

export async function getUserById(userId: string): Promise<UserProfile> {
    const response = await API.get(`/user/${userId}`);
    return response.data;
}

export async function getUserProfile(userId: string): Promise<UserProfile> {
    const response = await API.get(`/userProfile/${userId}`);
    return response.data;
}

export async function getTeamUserProfiles(teamId: Team["id"]): Promise<UserProfile[]> {
    const response = await API.get(`/userProfile/getForTeam/${teamId}`);
    return response.data;
}

// ToDo: Edit payload type to User and change member page form submitting
export async function postUser(
    authorizationProvider: AuthProviderEnum,
    payload: { [key in keyof UserProfile]?: unknown }
): Promise<UserProfile> {
    const headers = {
        AuthorizationProvider: authorizationProvider,
    };
    const response = await API.post(`/user/${payload.id}`, payload, { headers });
    return response.data;
}

export const getUsersForCompany = async (companyId: string): Promise<UserProfile[]> => {
    const result = await API.get(`/user/getForCompany/${companyId}`);
    return result.data;
};

export const getLeadersForCompany = async (companyId: string): Promise<UserProfile[]> => {
    const result = await API.get(`/user/getAvailableLeaders/${companyId}`);
    return result.data;
};

export const getAvailableLeadersForTeam = async (companyId: Company["id"], teamId: Team["id"]): Promise<UserProfile[]> => {
    const result = await API.get(`/user/getAvailableLeadersForTeam/${companyId}/${teamId}`);
    return result.data;
};

export const getUsersByManagerId = async (managerId: string): Promise<UserProfile[]> => {
    const result = await API.get(`/user/getUsersByManagerId/${managerId}`);
    return result.data;
};

export const getUsersByTeamId = async (teamId: Team["id"]): Promise<UserProfile[]> => {
    const result = await API.get(`/user/getUsersByTeamId/${teamId}`);
    return result.data;
};

export const getCoachesBelow = async (userId: UserProfile["id"]): Promise<UserProfile[]> => {
    const result = await API.get(`/user/getCoachesBelow/${userId}`);
    return result.data;
};

export const getUsersBelow = async (userId: UserProfile["id"]): Promise<UserProfile[]> => {
    const result = await API.get(`/user/getUsersBelow/${userId}`);
    return result.data;
};

export const refreshAllUsers = async (companyId: string): Promise<UserProfile[]> => {
    const result = await API.post(`/authenticationConfiguration/refreshAllUsers/${companyId}`);
    return result.data;
};

export const sendRegistrationEmail = async (userId: string): Promise<void> => {
    const result = await API.post(`/Email/SendInvitation/${userId}`);
    return result.data;
};

export const sendRegistrationEmailsByCompany = async (companyId: string): Promise<void> => {
    const result = await API.post(`/Email/SendInvitation/Company/${companyId}`);
    return result.data;
};
