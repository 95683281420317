import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => createStyles({
    notificationItem: {
        display: "flex",
        paddingTop: "24px",
        paddingBottom: "16px",
    
        "& .close-icon": {
            visibility: "hidden",
            cursor: "pointer",
            alignItem: "flex-start",
        },
        "&:hover .close-icon": {
            visibility: "visible",
        },
    },
    notificationContent: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        marginLeft: "16px",
    },
    notificationName: {
        fontWeight: 600,
        marginBottom: "4px",
    },
    avatar: {
        marginTop: "4px",
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export default useStyles;
