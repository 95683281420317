import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    selectInputBase: {
        width: "50%",
    },
    usersAutocomplete: {
        "& .MuiInputLabel-root": {
            transform: "translate(16px, 13px) scale(1)",
            fontSize: "14px",

            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, -5px) scale(0.75)",
            },
        },
        "& .MuiInputBase-root": {
            paddingTop: "6px",
            paddingBottom: "6px",
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            paddingTop: "3px",
            paddingBottom: "3px",
        },
        "& .MuiInputBase-input": {
            fontSize: "14px",
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "6px",
        },
        "& .MuiChip-root": {
            height: "24px",
        },
        "& .MuiChip-deleteIcon": {
            marginRight: "1px",
        },
    },
}));
