import { EmailConfiguration, EmailConfigurationForm } from "types";

export const getEmailConfigurationFormData = (emailConfiguration?: EmailConfiguration): EmailConfigurationForm => ({
    newMessages: emailConfiguration?.newMessages ?? false,
    resourceAssignments: emailConfiguration?.resourceAssignments ?? false,
    newCoachingSessionScheduled: emailConfiguration?.newCoachingSessionScheduled ?? false,
    coachingReportCreatedAndSubmittedByCoachee: emailConfiguration?.coachingReportCreatedAndSubmittedByCoachee ?? false,
    coachingReportCompleted: emailConfiguration?.coachingReportCompleted ?? false,
    coachingPlanAssignments: emailConfiguration?.coachingPlanAssignments ?? false,
    coachingReportAcknowledgementWithComment: emailConfiguration?.coachingReportAcknowledgementWithComment ?? false,
    coachingReportAcknowledgementWithoutComment: emailConfiguration?.coachingReportAcknowledgementWithoutComment ?? false,
    bccRegistrationEmails: emailConfiguration?.bccRegistrationEmails ?? [],
    evaluationCompletionEvaluatorLedToEvaluator: emailConfiguration?.evaluationCompletionEvaluatorLedToEvaluator ?? false,
    evaluationCompletionEvaluatorLedToTrainee: emailConfiguration?.evaluationCompletionEvaluatorLedToTrainee ?? false,
    evaluationCompletionEvaluatorLedToTraineesCoach: emailConfiguration?.evaluationCompletionEvaluatorLedToTraineesCoach ?? false,
    evaluationCompletionEvaluatorLedToOther: emailConfiguration?.evaluationCompletionEvaluatorLedToOther ?? "",
    evaluationCompletionEvaluatorLedToOtherChecked: !!emailConfiguration?.evaluationCompletionEvaluatorLedToOther?.length,
    evaluationCompletionSelfLedToEvaluator: emailConfiguration?.evaluationCompletionSelfLedToEvaluator ?? false,
    evaluationCompletionSelfLedToTrainee: emailConfiguration?.evaluationCompletionSelfLedToTrainee ?? false,
    evaluationCompletionSelfLedToTraineesCoach: emailConfiguration?.evaluationCompletionSelfLedToTraineesCoach ?? false,
    evaluationCompletionSelfLedToOther: emailConfiguration?.evaluationCompletionSelfLedToOther ?? "",
    evaluationCompletionSelfLedToOtherChecked: !!emailConfiguration?.evaluationCompletionSelfLedToOther?.length,
});

export const EMAIL_CONFIGURATION = [
    { key: "newMessages", label: "New messages/replies" },
    { key: "resourceAssignments", label: "Resource assignments" },
    { key: "newCoachingSessionScheduled", label: "New Coaching Session scheduled" },
    { key: "coachingReportCreatedAndSubmittedByCoachee", label: "Coaching Report created and submitted by Coachee" },
    { key: "coachingReportCompleted", label: "Coaching Report completed" },
    { key: "coachingPlanAssignments", label: "Coaching Plan assignments" },
    { key: "coachingReportAcknowledgementWithComment", label: "Completed Coaching Report acknowledgement with comment" },
    { key: "coachingReportAcknowledgementWithoutComment", label: "Completed Coaching Report acknowledgement without comment" },
];

export const EVALUATOR_LED_CONFIGURATION = [
    { key: "evaluationCompletionEvaluatorLedToEvaluator", label: "Evaluator" },
    { key: "evaluationCompletionEvaluatorLedToTrainee", label: "Trainee" },
    { key: "evaluationCompletionEvaluatorLedToTraineesCoach", label: "Trainee’s Coach" },
];

export const SELF_EVALUATOR_CONFIGURATION = [
    { key: "evaluationCompletionSelfLedToEvaluator", label: "Evaluator" },
    { key: "evaluationCompletionSelfLedToTrainee", label: "Trainee" },
    { key: "evaluationCompletionSelfLedToTraineesCoach", label: "Trainee’s Coach" },
];
