import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import orderBy from "lodash/orderBy";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import usePrevious from "hooks/usePrevious";

import { getResourceCategoriesForCompany, updateOrdersOfResourceCategories } from "services/resourceServices";
import { FcrResourceCategory } from "types";
import { CompaniesContext } from "context";

import ResourceList from "./ResourceList";
import CategoryList from "./CategoryList";

const ResourceManagement = () => {
    const { currentCompany } = useContext(CompaniesContext);
    const [categories, setCategories] = useState<FcrResourceCategory[]>();
    const [selectedCategory, setSelectedCategory] = useState<FcrResourceCategory>();
    const [isAddResourceOpen, toggleIsAddResourceOpen] = useReducer(state => !state, false);
    const [currentPage, setCurrentPage] = useState(1);
    const previousCategory = usePrevious(selectedCategory);

    const fetchCategories = useCallback(async (companyId: string) => {
        setCategories(undefined);
        const categories = await getResourceCategoriesForCompany(companyId);
        const orderedCategories = orderBy(categories, "order", "asc");
        setCategories(orderedCategories);
        setSelectedCategory(categories.length ? orderedCategories[0] : undefined);
    }, []);

    const onAddCategory = useCallback((category: FcrResourceCategory) => {
        setCategories((state) => state && [...state, category]);
        !selectedCategory && setSelectedCategory(category);
    }, [selectedCategory]);

    const onEditCategory = useCallback((category: FcrResourceCategory) => {
        setCategories((state) =>
            state!.map((existingCategory) =>
                existingCategory.id === category.id ? category : existingCategory));
    }, []);

    const onDeleteCategory = useCallback((category: FcrResourceCategory) => {
        setCategories((state) =>
            state!.filter((existingCategory) => existingCategory.id !== category.id));
        selectedCategory && selectedCategory.id === category.id && setSelectedCategory(undefined);
    }, [selectedCategory]);

    const onCategoriesReordered = useCallback((categories: FcrResourceCategory[]) => {
        setCategories(categories);
        const categoryOrders = categories.map((category, idx) => ({ id: category.id, order: idx + 1 }));
        updateOrdersOfResourceCategories(categoryOrders);
    }, []);

    useEffect(() => {
        currentCompany?.id && fetchCategories(currentCompany.id);
    }, [currentCompany?.id, fetchCategories]);

    useEffect(() => {
        selectedCategory !== previousCategory && setCurrentPage(1);
    }, [previousCategory, selectedCategory]);

    return (
        <>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start" height={60} mb={5}>
                    <Box>
                        <Typography variant="h1">
                            Resources Management
                        </Typography>
                        <Typography variant="h2">
                            {selectedCategory && selectedCategory.value}
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={toggleIsAddResourceOpen}
                        disabled={categories && !categories.length}
                        disableElevation
                    >
                        <Box my={0.5} mr={1}>+</Box>
                        New Resource
                    </Button>
                </Box>
                <Grid container direction="row-reverse" spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <CategoryList
                            categories={categories}
                            selectedCategory={selectedCategory}
                            onSelect={setSelectedCategory}
                            onAddCategory={onAddCategory}
                            onEditCategory={onEditCategory}
                            onDeleteCategory={onDeleteCategory}
                            onCategoriesReordered={onCategoriesReordered}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                        <ResourceList
                            categories={categories}
                            selectedCategory={selectedCategory}
                            isAddResourceOpen={isAddResourceOpen}
                            toggleIsAddResourceOpen={toggleIsAddResourceOpen}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ResourceManagement;