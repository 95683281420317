import React, { useEffect, useMemo, useState } from "react";
import { Box, MenuItem, Select } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { EvaluationsAutocomplete } from "components/EvaluationsAutocomplete";
import { EvaluationResultStatusEnum, EvaluationStatusText } from "types/constants";
import { TraineeClassInformation } from "types/Certification";

import useStyles from "./styles";

interface ClassTraineesFiltersProps {
    trainees: TraineeClassInformation[];
    onFilter: (filteredtrainees: TraineeClassInformation[]) => void;
}

enum EvaluationResultStatus {
    "All",
}

function ClassTraineesFilters({ trainees, onFilter }: ClassTraineesFiltersProps) {
    const classes = useStyles();
    const [traineeId, setTraineeId] = useState<TraineeClassInformation["id"] | null>(null);
    const [status, setStatus] = useState<EvaluationResultStatusEnum | EvaluationResultStatus>(EvaluationResultStatus.All);

    const autocompleteItems = useMemo(
        () => trainees.map(({ traineeId, traineeName }) => ({ id: traineeId, label: traineeName })),
        [trainees]
    );

    useEffect(() => {
        onFilter(trainees);
    }, [trainees, onFilter]);

    const filter = (traineeId: TraineeClassInformation["id"] | null, status: EvaluationResultStatus | EvaluationResultStatusEnum) => {
        const filtered =
            status === EvaluationResultStatus.All ? trainees : trainees.filter(({ evaluationStatusId }) => evaluationStatusId === status);

        if (traineeId) {
            return onFilter(filtered.filter((trainee) => trainee.traineeId === traineeId));
        }

        return onFilter(filtered);
    };

    const handleEvaluationFilter = (traineeId: TraineeClassInformation["id"] | null) => {
        setStatus(EvaluationResultStatus.All);
        setTraineeId(traineeId);
        filter(traineeId, EvaluationResultStatus.All);
    };

    const handleStatusFilter = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = e.target.value as EvaluationResultStatusEnum;
        setStatus(value);
        setTraineeId(null);
        filter(null, value);
    };

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box minWidth={250} mr={2}>
                <EvaluationsAutocomplete
                    label={"All Trainees"}
                    items={autocompleteItems}
                    value={traineeId}
                    popupIcon={<FilterListIcon />}
                    onChange={handleEvaluationFilter}
                />
            </Box>
            <Select
                variant="outlined"
                value={status}
                onChange={handleStatusFilter}
                IconComponent={FilterListIcon}
                className={classes.filter}>
                <MenuItem value={EvaluationResultStatus.All}>All Statuses</MenuItem>
                <MenuItem value={EvaluationResultStatusEnum.new}>{EvaluationStatusText[EvaluationResultStatusEnum.new]}</MenuItem>
                <MenuItem value={EvaluationResultStatusEnum.inProgress}>
                    {EvaluationStatusText[EvaluationResultStatusEnum.inProgress]}
                </MenuItem>
                <MenuItem value={EvaluationResultStatusEnum.completed}>
                    {EvaluationStatusText[EvaluationResultStatusEnum.completed]}
                </MenuItem>
            </Select>
        </Box>
    );
}

export default React.memo(ClassTraineesFilters) as typeof ClassTraineesFilters;
