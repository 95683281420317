import React from "react";
import SummaryCard, { SummaryCardProps } from "components/SummaryCard";

import GoalsIcon from "./GoalsIcon";

const renderDefaultIcon = () => <GoalsIcon /> ;

const SummaryCardGoals = ({
    renderIcon = renderDefaultIcon,
    theme = "sea",
    ...props
}: SummaryCardProps) => <SummaryCard theme={theme} renderIcon={renderIcon} { ...props } />;

export default SummaryCardGoals;