import sortBy from "lodash/sortBy";
import { v4 as uuidv4 } from "uuid";
import { MemberFormProps, UserProfile } from "types";
import { prepareForSort } from "helpers/stringHelpers";
import { AuthProviderEnum } from "types/constants";

export const initialUserProfileObj: UserProfile = {
    id: "",
    companyId: "",
    avatar: "",
    emailAddress: "",
    firstName: "",
    lastName: "",
    isActive: false,
    title: "",
    isTrainee: false,
    isTrainer: false,
    isALeader: false,
    isACoLeader: false,
    createdById: "",
    createdDate: "",
    modifiedById: "",
    modifiedDate: "",
    timesCoached: 0,
    minRating: 0,
    maxRating: 0,
    isInvitationSent: false,
    isSkillsAssigned: false,
    isEvaluator: true,
    hasClassesAsEvaluator: false,
    amountOfClassesAsEvaluator: 0,
    hasClassesAsTrainee: false,
    amountOfClassesAsTrainee: 0,
    hasTeamMembersAsTrainees: false,
    isCoach: false,
    isCoachee: false,
};

export const getUserFullName = (userProfile: UserProfile): string => {
    const { firstName = "", lastName = "" } = userProfile;
    return `${firstName}${lastName ? " " + lastName : ""}`;
};

export const getUserProfilesFullNameById = (userProfiles: UserProfile[]) =>
    new Map<UserProfile["id"], string>(userProfiles.map((userProfile) => [userProfile.id, getUserFullName(userProfile)]));

export const getSortedUserProfiles = (userProfiles: UserProfile[]) =>
    sortBy(userProfiles, (userProfile) => prepareForSort(getUserFullName(userProfile)));

export const getSortedUserProfilesIds = (userProfiles: UserProfile[]) => {
    const sortedUserProfiles = getSortedUserProfiles(userProfiles);
    return sortedUserProfiles.map((userProfile) => userProfile.id);
};

export const getMemberFormData = (member: UserProfile | undefined, companyId: string, userId: string): MemberFormProps => ({
    ...(member ?? {}),
    id: member?.id ?? uuidv4(),
    companyId: member?.companyId ?? companyId,
    companyLevelId: member?.companyLevelId ?? null,
    avatar: member?.avatar ?? "",
    emailAddress: member?.emailAddress ?? "",
    firstName: member?.firstName ?? "",
    lastName: member?.lastName ?? "",
    isActive: member?.isActive ?? true,
    title: member?.title ?? "",
    isTrainee: member?.isTrainee ?? false,
    isTrainer: member?.isTrainer ?? false,
    isALeader: member?.isALeader ?? false,
    isACoLeader: member?.isACoLeader ?? false,
    createdById: member?.createdById ?? userId,
    createdDate: member?.createdDate ?? new Date().toISOString(),
    modifiedById: userId,
    modifiedDate: new Date().toISOString(),
    timesCoached: member?.timesCoached ?? 0,
    minRating: member?.minRating ?? 0,
    maxRating: member?.maxRating ?? 0,
    officePhone: member?.officePhone ?? "",
    mobilePhone: member?.mobilePhone ?? "",
    location: member?.location ?? "",
    businessUnit: member?.businessUnit ?? "",
    isInvitationSent: member?.isInvitationSent ?? false,
    authorizationProvider: AuthProviderEnum.B2C,
    isSkillsAssigned: false,
    isEvaluator: member?.isEvaluator ?? true,
});
