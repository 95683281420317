import * as React from "react";

function SvgTrophy(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg 
            width="22" 
            height="21" 
            viewBox="0 0 22 21" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16.6506 1V8.9268C16.6506 12.0352 14.1046 14.5812 10.9962 14.5812C7.8878 14.5812 5.3418 12.0352 5.3418 8.9268V1H16.6506Z" stroke="#51A9BE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M10.9961 14.4976V19.5136" stroke="#51A9BE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M15.5474 20H6.4502" stroke="#51A9BE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.45563 10.8878C4.31563 10.6598 2.75763 10.6142 1.96723 9.37541C1.25283 8.25821 1.04003 6.50261 1.00203 4.76981C0.986834 4.31381 1.05523 3.70581 1.26043 3.47781C1.45803 3.24981 1.65563 3.12061 2.05843 3.12061H4.90083" stroke="#51A9BE" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M16.5376 10.888C17.6776 10.66 19.2356 10.6144 20.026 9.37562C20.7404 8.25842 20.9532 6.50282 20.9912 4.77002C20.9988 4.32162 20.9304 3.71362 20.7328 3.48562C20.5352 3.25762 20.3376 3.12842 19.9348 3.12842H17.0924" stroke="#51A9BE" strokeWidth="1.5" strokeMiterlimit="10"/>
        </svg>
    );
}

export default SvgTrophy;
