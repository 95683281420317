import React, { useState, useEffect } from "react";
import { Box, Button, MenuItem } from "@material-ui/core";
import { EvaluationConfiguration } from "types/Certification";
import { EvaluationsAutocomplete } from "components/EvaluationsAutocomplete";
import { Select } from "components/Form/FormSelect";
import useOnCompanyChange from "hooks/useOnCompanyChange";

interface EvaluationsFilterProps {
    evaluationsAutocompleteLabel?: string;
    evaluations: EvaluationConfiguration[];
    onFilter: (filteredEvaluations: EvaluationConfiguration[]) => void;
}

enum StatusFilterEmum {
    all,
    active,
    inactive,
}

export default function EvaluationsFilter({ evaluationsAutocompleteLabel, evaluations, onFilter }: EvaluationsFilterProps) {
    const [evaluationId, setEvaluationId] = useState<EvaluationConfiguration["id"] | null>(null);
    const [status, setStatus] = useState<StatusFilterEmum>(StatusFilterEmum.all);

    useEffect(() => {
        onFilter(evaluations);
    }, [evaluations, onFilter]);

    const filter = (evaluationId: EvaluationConfiguration["id"] | null, status: StatusFilterEmum) => {
        const filteredEvaluations =
            status === StatusFilterEmum.all
                ? evaluations
                : evaluations.filter(({ isActive }) => (status === StatusFilterEmum.active ? isActive : !isActive));

        if (evaluationId) {
            return onFilter(filteredEvaluations.filter((evaluation) => evaluation.id === evaluationId));
        }

        return onFilter(filteredEvaluations);
    };

    const handleEvaluationFilter = (evaluationId: EvaluationConfiguration["id"] | null) => {
        setStatus(StatusFilterEmum.all);
        setEvaluationId(evaluationId);
        filter(evaluationId, StatusFilterEmum.all);
    };

    const handleStatusFilter = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = e.target.value as StatusFilterEmum;
        setStatus(value);
        setEvaluationId(null);
        filter(null, value);
    };

    const handleResetFilter = () => {
        setStatus(StatusFilterEmum.all);
        setEvaluationId(null);
        filter(null, StatusFilterEmum.all);
    };

    useOnCompanyChange(handleResetFilter);

    if (!evaluations.length) return null;

    return (
        <Box py={3} px={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex">
                    <Box minWidth={300} mr={2}>
                        <EvaluationsAutocomplete
                            label={evaluationsAutocompleteLabel}
                            items={evaluations}
                            value={evaluationId}
                            onChange={handleEvaluationFilter}
                        />
                    </Box>
                    <Select variant="outlined" value={status} onChange={handleStatusFilter}>
                        <MenuItem value={StatusFilterEmum.all}>All Evaluations</MenuItem>
                        <MenuItem value={StatusFilterEmum.inactive}>Deactivated Evaluations</MenuItem>
                        <MenuItem value={StatusFilterEmum.active}>Only Active Evaluations</MenuItem>
                    </Select>
                </Box>
                <Button color="primary" size="large" onClick={handleResetFilter}>
                    Clear Filter
                </Button>
            </Box>
        </Box>
    );
}
