import { useContext, useEffect } from "react";
import { CompaniesContext } from "context";
import usePrevious from "hooks/usePrevious";

export default function useOnCompanyChange(callback: (...args: unknown[]) => unknown) {
    const { currentCompany } = useContext(CompaniesContext);
    const previousCompany = usePrevious(currentCompany);

    useEffect(() => {
        // Execute only if company was loaded at the moment (if not null)
        if (previousCompany && previousCompany?.id !== currentCompany?.id) callback();
    }, [currentCompany?.id, previousCompany, callback]);
}
