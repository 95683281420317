import React from "react";
import { Box, Typography, Card } from "@material-ui/core";
import { UserProfile } from "types";

import AvatarWithScore from "components/AvatarWithScore";

import useStyles from "../styles";

type TeamMemberCardProps = {
    member: UserProfile;
};

function TeamMemberCard({ member }: TeamMemberCardProps) {
    const classes = useStyles();
    return (
        <Card elevation={1} key={member.id}>
            <Box px={4} py={4} alignItems="center" display="flex" flexDirection="column">
                <Box mb={2}>
                    <AvatarWithScore user={member} />
                </Box>
                <Box mb={2}>
                    <Typography variant="h3">
                        {member.firstName} {member.lastName}
                    </Typography>
                </Box>
                <Box mt="auto">
                    <Typography className={classes.label} component="span" variant="body2">
                        Classes: {member.amountOfClassesAsTrainee || 0}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
}

export default React.memo(TeamMemberCard);
