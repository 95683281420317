import * as React from "react";

function SvgBackArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={15}
            height={14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.82 7.54l-.01-1.25-12.49.15L7.88.88 7 0 0 7l7 6.99.88-.88-5.42-5.42 12.36-.15z"
                fill="#7C8EA3"
            />
        </svg>
    );
}

export default SvgBackArrow;
