import React from "react";
import clsx from "clsx";
import { Box, Button, ButtonProps } from "@material-ui/core";

import useStyles from "./styles";

const SquareIconButton = ({ children, className, ...props }: ButtonProps) => {
    const classes = useStyles();

    return (
        <Button className={clsx(className, classes.iconButton)} {...props}>
            <Box display="flex" p={1}>
                {children}
            </Box>
        </Button>
    );
};

export default SquareIconButton;