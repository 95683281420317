import API from "./apiService";
import FcrResourceCategory from "../types/FcrResourceCategory";
import { FcrResource, UserProfile, UserList } from "../types";
import { ResourceTypeEnum } from "../types/constants";

export interface ResourceCategoryOrder {
    id: string,
    order: number,
}

export const getFcrResourceCategories = async (): Promise<FcrResourceCategory[]> => {
    const result = await API.get("/ResourceCategory/all");
    return result.data;
};

export const getResourceCategoriesForCompany = async (companyId: string): Promise<FcrResourceCategory[]> => {
    const { data } = await API.get(`/ResourceCategory/company/${companyId}`);
    return data;
};

export const updateResourceCategory = async (category: FcrResourceCategory): Promise<FcrResourceCategory> => {
    const { data } = await API.post(`/ResourceCategory/${category.id}`, category);
    return data;
};

export const deleteResourceCategory = async (categoryId: string): Promise<null> => {
    const { data } = await API.delete(`/ResourceCategory/${categoryId}`);
    return data;
};

export const updateOrdersOfResourceCategories = async (resourceCategoryOrders: ResourceCategoryOrder[]): Promise<ResourceCategoryOrder[]> => {
    const result = await API.post("/ResourceCategory/updateOrder", resourceCategoryOrders);
    return result.data;
};

export const getFcrResources = async (): Promise<FcrResource[]> => {
    const result = await API.get("/Resource/all");
    return result.data;
};

export const createOrUpdateFcrResource = async (resource: FcrResource) => {
    const result = await API.post(`/Resource/${resource.type}/${resource.id}`, resource);
    return result.data;
};

export const deleteFcrResource = async (resourceType: ResourceTypeEnum, resourceId: string) => {
    // TODO: should be deleted from fcrCoaching Plan by separate request?
    await API.delete(`/Resource/${resourceType}/${resourceId}`);
};

export const getFcrResource = async (resourceType: ResourceTypeEnum, resourceId: string): Promise<FcrResource> => {
    const result = await API.get(`/Resource/${resourceType}/${resourceId}`);
    return result.data;
}

export const getFcrResourcesForFcrCoachingPlan = async (fcrCoachingPlanId: string): Promise<FcrResource[]> => {
    const result = await API.get(`/Resource/fcrCoachingPlan/${fcrCoachingPlanId}`);
    return result.data;
};

export const getFcrResourcesForCategory = async (categoryId: string): Promise<FcrResource[]> => {
    const { data } = await API.get(`/Resource/resourceCategory/${categoryId}`);
    return data;
};

export const getFcrResourcesForUser = async (userId: string): Promise<FcrResource[]> => {
    const { data } = await API.get(`/Resource/user/${userId}`);
    return data;
};

export const updateFcrResourcesForFcrCoachingPlan = async (fcrCoachingPlanId: string, resources: Array<FcrResource>): Promise<Array<FcrResource>> => {
    const result = await API.post(`/Resource/fcrCoachingPlan/${fcrCoachingPlanId}`, resources);
    return result.data;
};

export const getResourcesForCompany = async (companyId: string): Promise<FcrResource[]> => {
    const { data } = await API.get(`/Resource/company/${companyId}`);
    return data;
};

export const getResourceAssignments = async (resourceType: ResourceTypeEnum, resourceId: string, pageNumber: number, getAssigned = true, recordsAmount = 100): Promise<UserList> => {
    const { data } = await API.get(`/Resource/${resourceType}/${resourceId}/getAssignments?pageNumber=${pageNumber}&getAssigned=${getAssigned}&recordsAmount=${recordsAmount}`);
    return data;
};

export const updateResourceAssignments = async (resourceType: ResourceTypeEnum, resourceId: string, assignments: string[], assign = true): Promise<UserProfile[]> => {
    const { data } = await API.post(`/Resource/${resourceType}/${resourceId}/updateAssignments?assign=${assign}`, assignments);
    return data;
};