import * as React from "react";

function SvgVirtual(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={13}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g fill="currentColor">
                <path d="M6.08 4.09c-.69.06-1.32.39-1.76.92-.45.53-.66 1.21-.59 1.9a2.606 2.606 0 002.59 2.37c.08 0 .16 0 .23-.01 1.43-.13 2.49-1.4 2.36-2.82a2.623 2.623 0 00-2.83-2.36zm.35 3.94c-.74.07-1.4-.49-1.46-1.23-.04-.36.07-.71.3-.98.23-.28.56-.45.92-.48.04 0 .08-.01.12-.01a1.354 1.354 0 01.12 2.7zM6.33 13.96a.65.65 0 00-.64.63c0 .34.29.64.64.64a.636.636 0 000-1.27z" />
                <path d="M12.66 2.46c0-.02-.01-.03-.01-.04v-.8c0-.9-.73-1.62-1.62-1.62h-9.4C.73 0 0 .73 0 1.62v13.52c0 .9.73 1.62 1.62 1.62h9.4c.9 0 1.62-.73 1.62-1.62V2.5c.01-.01.02-.03.02-.04zm-1.93 9.93a4.78 4.78 0 00-4.86-2.94 4.812 4.812 0 00-3.99 2.88l.13.06h-.76V3.08H11.4v9.31h-.67zm-1.4 0H3.25c.58-.96 1.59-1.6 2.72-1.7 1.38-.12 2.67.55 3.36 1.7zM1.62 1.25h9.4c.21 0 .38.17.38.38v.21H1.25v-.22c0-.2.17-.37.37-.37zm9.4 14.27h-9.4c-.2 0-.38-.17-.38-.38v-1.51H11.4v1.51c0 .21-.17.38-.38.38z" />
            </g>
        </svg>
    );
}

export default SvgVirtual;
