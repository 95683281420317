import { Box, Drawer, Link, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { ConfigurationContext } from "context";
import { CoachingPlans, DaysInTheField, Messages, Performance, Resources, TeamProfiles } from "svgComponents";
import { theme } from "theme";
import { LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW, Routings } from "types/constants";

import SvgCoachingPlans from "svgComponents/CoachingPlans";
import NoDataMessage from "components/NoDataMessage";

import Logo from "./Logo";
import MenuSubItem from "./MenuSubItem";
import useStyles from "./styles";

interface NavigationItem {
    key: string;
    enableColor?: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement;
    href?: string;
}

interface NavBarProps {
    open: boolean;
    navBarClose: () => void;
}

const dashboardNavigationItem: NavigationItem = {
    key: "Dashboard",
    enableColor: theme.colors.text.fontTeal,
    icon: (props: React.SVGProps<SVGSVGElement>) => <Performance {...props} />,
    href: Routings.dashboard,
};

const classesNavigationItem: NavigationItem = {
    key: "Classes",
    enableColor: theme.colors.identity.red,
    icon: (props: React.SVGProps<SVGSVGElement>) => <SvgCoachingPlans {...props} />,
    href: Routings.classes,
};

const resourcesNavigationItem: NavigationItem = {
    key: "Resources",
    enableColor: theme.colors.identity.red,
    icon: (props: React.SVGProps<SVGSVGElement>) => <Resources {...props} />,
    href: Routings.resources,
};

const coachNavigation: Array<NavigationItem> = [
    dashboardNavigationItem,
    {
        key: "Team Profiles",
        enableColor: "#FA81EE",
        icon: (props: React.SVGProps<SVGSVGElement>) => <TeamProfiles {...props} />,
    },
    {
        key: "Coaching Hub",
        enableColor: theme.colors.text.fontTeal,
        icon: (props: React.SVGProps<SVGSVGElement>) => <CoachingPlans {...props} />,
        href: Routings.coachingHub,
    },
    resourcesNavigationItem,
];

const coacheeNavigation: Array<NavigationItem> = [
    dashboardNavigationItem,
    resourcesNavigationItem,
    ...(false // isUnfinishedContentShown
        ? [
              {
                  key: "Sessions",
                  enableColor: theme.colors.text.fontTeal,
                  icon: (props: React.SVGProps<SVGSVGElement>) => <DaysInTheField {...props} />,
                  href: Routings.sessions,
              },
              {
                  key: "Reports",
                  enableColor: theme.colors.text.fontTeal,
                  icon: (props: React.SVGProps<SVGSVGElement>) => <CoachingPlans {...props} />,
                  href: Routings.allReports,
              },
              {
                  key: "Messages",
                  enableColor: theme.colors.text.fontTeal,
                  icon: (props: React.SVGProps<SVGSVGElement>) => <Messages {...props} />,
                  href: Routings.allMessages,
              },
          ]
        : []),
];

function NavBar({ open, navBarClose }: NavBarProps) {
    const classes = useStyles();
    const history = useHistory();
    const { currentUser, currentTeam, companyCertificationConfiguration, isConfigurationFetched } = useContext(ConfigurationContext);
    const [selected, setSelected] = useState<string>();
    const [selectedProfile, setSelectedProfile] = useState();

    const handleTeamProfileClick = useCallback(
        (id) => {
            setSelectedProfile(id);
            history.push(`${Routings.teamProfile}/${id}`);
        },
        [history]
    );

    const menu = useMemo(() => {
        const navigation: NavigationItem[] = [];
        if (currentUser) {
            if (localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW)) {
                if (currentUser.isCoachee) {
                    navigation.push(...coacheeNavigation);
                }
            } else if (currentUser.isCoach || currentUser.isACoLeader) {
                navigation.push(...coachNavigation);
            }

            if (
                (currentUser.isEvaluator && currentUser.hasClassesAsEvaluator) ||
                (currentUser.isTrainee && currentUser.hasClassesAsTrainee) ||
                currentUser.hasTeamMembersAsTrainees
            ) {
                const navItem: NavigationItem = {
                    ...classesNavigationItem,
                    key: companyCertificationConfiguration?.section1Label ?? classesNavigationItem.key,
                };
                navigation.push(navItem);
            }
        }
        return navigation;
    }, [companyCertificationConfiguration?.section1Label, currentUser]);

    const handleNavigationItemClick = useCallback(
        (key, href) => () => {
            setSelected(key);
            href && history.push(href);
        },
        [history]
    );

    return (
        <Drawer classes={{ paper: classes.drawer }} variant="persistent" open={open} role="navigation">
            <Box mx={2}>
                <Logo navBarClose={navBarClose} />
                {currentUser &&
                    isConfigurationFetched &&
                    (menu.length ? (
                        [
                            menu.map(({ key, enableColor, icon, href }: NavigationItem) => (
                                <Box
                                    key={key}
                                    className={clsx(classes.section, selected === key && classes.selectedSection)}
                                    onClick={handleNavigationItemClick(key, href)}>
                                    <Box display="flex" alignItems="center" px={2.5} py={2}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            color={selected === key ? enableColor : "text.secondary"}
                                            width={43}>
                                            {icon}
                                        </Box>
                                        <Typography>{key}</Typography>
                                    </Box>
                                    {key === "Team Profiles" && selected === "Team Profiles" && (
                                        <Box pb={2.5}>
                                            {currentTeam.length ? (
                                                currentTeam.map((user) => (
                                                    <MenuSubItem
                                                        key={user.id}
                                                        user={user}
                                                        selectedProfile={selectedProfile}
                                                        onClick={handleTeamProfileClick}
                                                    />
                                                ))
                                            ) : (
                                                <Box px={2.5} py={1}>
                                                    <Skeleton />
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            )),
                        ]
                    ) : (
                        <Box py={1}>
                            <NoDataMessage icon={null} title="No content" message="" m="auto" />
                        </Box>
                    ))}
                {(!currentUser || !isConfigurationFetched) &&
                    [1, 2, 3].map((index) => (
                        <Box key={index} py={2} px={2.5}>
                            <Skeleton />
                        </Box>
                    ))}
            </Box>
            <Box mt="auto" mx={2} pb={2}>
                <Link href="mailto:theteam@myomnicoach.com?subject=Omni coach Support Request" target="_blank" underline="none">
                    <Box alignItems="center" display="flex" color="text.secondary" px={2.5} py={2.5}>
                        <Box alignItems="center" display="flex" width={43}>
                            <Box
                                border="solid 1px currentColor"
                                borderRadius="50%"
                                className={classes.helpIcon}
                                fontSize={13}
                                fontWeight={500}
                                height={15}
                                lineHeight="15px"
                                textAlign="center"
                                width={15}>
                                ?
                            </Box>
                        </Box>
                        <Typography>Help</Typography>
                    </Box>
                </Link>
            </Box>
        </Drawer>
    );
}

export default React.memo(NavBar);
