import { User, UserProfile } from "types";
import { LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW } from "types/constants";

import API from "./apiService";

export const getCurrentTeam = async (): Promise<UserProfile[]> => {
    const { data } = await API.get("/UserProfile/getCurrentTeam");

    return data || [];
};

export const switchTeamView = () => {
    const isTeamMemberView = localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW);

    isTeamMemberView ?
        localStorage.removeItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW) :
        localStorage.setItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW, "true");

    window.location.assign("/");
};

export const getCurrentUserProfile = async (): Promise<UserProfile | undefined> => {
    const response = await API.get("/UserProfile/getCurrentUser");

    return response.data;
};

export const getUserProfile = async (userProfileId: string): Promise<UserProfile | undefined> => {
    const { data } = await API.get(`/UserProfile/${userProfileId}`);
    return data;
}

export const getUser = async (userId: User["id"]): Promise<User | undefined> => {
    const response = await API.get(`/user/${userId}`);

    return response.data;
};