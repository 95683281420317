import * as React from "react";

function SvgCoachingPlans(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={23}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                opacity={0.6}
                fill="currentColor"
            >
                <path d="M15.05 3.28h-2.34c-.15-.93-.95-1.65-1.9-1.65l-.16-.01a2.235 2.235 0 00-4.3.01h-.14c-.97 0-1.78.72-1.92 1.65H1.95C.88 3.28 0 4.16 0 5.23V20.9c0 1.08.88 1.95 1.95 1.95h13.1c1.08 0 1.95-.88 1.95-1.95V5.23c.01-1.07-.87-1.95-1.95-1.95zm-9.54.3c0-.39.32-.7.7-.7h.62c.09 0 .17-.02.25-.05.25-.09.44-.33.44-.6 0-.54.44-.98.98-.98s.98.44.98.98c0 .19.1.37.25.49.11.08.28.17.39.13l.66.03c.39 0 .7.32.7.7v.77H5.51v-.77zM15.76 20.9c0 .39-.32.7-.7.7H1.95c-.39 0-.7-.32-.7-.7V5.23c0-.39.32-.7.7-.7h2.31v.45c0 .35.28.62.62.62h7.23c.35 0 .62-.28.62-.62v-.45h2.31c.39 0 .7.32.7.7V20.9h.02z" />
                <path d="M11.57 8.9H5.09c-.35 0-.62.28-.62.62 0 .34.28.62.62.62h6.48c.35 0 .62-.28.62-.62 0-.34-.28-.62-.62-.62zM11.57 12.73H5.09c-.35 0-.62.28-.62.62 0 .34.28.62.62.62h6.48c.35 0 .62-.28.62-.62 0-.34-.28-.62-.62-.62zM8.93 16.57H5.09c-.35 0-.62.28-.62.62 0 .34.28.62.62.62h3.84c.35 0 .62-.28.62-.62 0-.34-.27-.62-.62-.62z" />
            </g>
        </svg>
    );
}

export default SvgCoachingPlans;
