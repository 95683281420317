import * as React from "react";

function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={16}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g opacity={0.7} clipPath="url(#settings_svg__clip0)" fill="currentColor">
                <path
                    d="M9.33 16.09H6.36a.99.99 0 01-.98-.83l-.23-1.64a5.95 5.95 0 01-.77-.45l-1.53.62c-.45.18-.98 0-1.23-.42L.13 10.8a1 1 0 01.24-1.28l1.3-1.02c-.01-.16-.02-.3-.02-.45 0-.15.01-.3.02-.45L.38 6.59a.994.994 0 01-.24-1.28l1.48-2.57c.24-.43.75-.61 1.21-.44l1.54.62c.25-.17.5-.32.77-.45L5.37.84c.09-.49.5-.84.99-.84h2.97c.48 0 .89.34.98.82l.24 1.66c.26.13.52.28.77.45l1.53-.62c.46-.18.98 0 1.23.43l1.48 2.57c.25.44.15.97-.24 1.28l-1.3 1.02c.01.15.02.3.02.45 0 .15-.01.3-.02.45l1.29 1.01c.39.32.49.86.24 1.28l-1.48 2.57c-.25.42-.76.61-1.21.43l-1.54-.62c-.25.17-.5.32-.77.45l-.23 1.63c-.09.49-.5.83-.99.83zm-2.75-1.25h2.53l.3-2.09.33-.14c.4-.17.77-.39 1.11-.65l.28-.22 1.97.79 1.26-2.19-1.67-1.31.04-.35c.03-.21.05-.43.05-.64 0-.21-.02-.43-.05-.64l-.04-.35 1.67-1.31-1.26-2.19-1.97.79-.28-.22a4.81 4.81 0 00-1.11-.65l-.33-.13-.3-2.09H6.58l-.3 2.09-.32.14c-.4.17-.77.38-1.11.65l-.29.22-1.97-.8-1.26 2.19L3 7.05l-.05.35c-.03.21-.05.43-.05.65 0 .22.02.43.05.65l.05.34-1.67 1.31 1.26 2.19 1.97-.79.28.22c.35.27.72.49 1.11.65l.33.14.3 2.08zm1.32-4.11c-1.45 0-2.62-1.18-2.62-2.62 0-1.44 1.17-2.63 2.62-2.63 1.45 0 2.62 1.18 2.62 2.62 0 1.44-1.17 2.63-2.62 2.63zm0-4A1.374 1.374 0 109.27 8.1c0-.76-.61-1.37-1.37-1.37z"
                />
            </g>
            <defs>
                <clipPath id="settings_svg__clip0">
                    <path fill="currentColor" d="M0 0h15.69v16.09H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgSettings;
