import React, { useCallback, useState, useContext } from "react";
import {
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    Paper,
    Popper,
    Typography,
    Button,
} from "@material-ui/core";

import SvgNotifications from "svgComponents/Notifications";
import { ConfigurationContext, NotificationsContext } from "context";
import NoDataMessage from "components/NoDataMessage";
import { removeAllNotifications, removeNotification } from "services/notificationService";

import NotificationItem from "./NotificationItem";
import useStyles from "./styles";

const Notifications = () => {
    const classes = useStyles();
    const { currentUser } = useContext(ConfigurationContext);
    const { notifications, isUnread, setIsUnread } = useContext(NotificationsContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
        setIsUnread(false);
    }, [setIsUnread]);

    const handleClose = useCallback(() => {
        if (!anchorEl) return;

        setAnchorEl(null);
        setIsUnread(false);
    }, [anchorEl, setIsUnread]);

    const clearNotifications = useCallback(() => {
        removeAllNotifications(currentUser!.id)
    }, [currentUser]);

    const handleDelete = useCallback((id: string) => {
        removeNotification(currentUser!.id, id)
    }, [currentUser]);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box>
                <IconButton onClick={handleClick}>
                    <Badge color="error" variant="dot" overlap="circular" invisible={!isUnread || !!anchorEl}>
                        <SvgNotifications />
                    </Badge>
                </IconButton>
                <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                    className={classes.popover}
                    disablePortal
                >
                    <Paper elevation={2} className={classes.paper}>
                        <Box display="flex" flexDirection="column" maxHeight={540} minHeight={200} width={{ xs: 1, sm: 570 }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" py={3} px={4}>
                                <Box display="flex" alignItems="center">
                                    <Box fontWeight={600} mr={1.5}>
                                        <Typography variant="h3">
                                            Notifications
                                        </Typography>
                                    </Box>
                                    <Typography variant="caption">
                                        Last 30 Days
                                    </Typography>
                                </Box>
                                {!!notifications.length && 
                                    <Box m={-1}>
                                        <Button variant="text" className={classes.link} onClick={clearNotifications}>
                                            <Typography variant="body2" component="span" >
                                                Clear all
                                            </Typography>
                                        </Button>
                                    </Box>
                                }
                            </Box>
                            <Divider/>
                            {!!notifications.length ? 
                                <div className={classes.scrollContainer}>
                                    {notifications.map((notification, index) => (
                                        <React.Fragment key={notification.id}>
                                            <NotificationItem
                                                handleClose={handleClose}
                                                notification={notification}
                                                onDelete={handleDelete}
                                                isDividerShown={index !== notifications.length - 1}
                                            />
                                        </React.Fragment>
                                    ))}
                                </div>
                                : <NoDataMessage
                                    flex={1}
                                    icon={null}
                                    justifyContent="center"
                                    message="No notifications at this time."
                                    title=""
                                />}
                        </Box>
                    </Paper>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

export default React.memo(Notifications);