import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import { ConfigurationContext } from "context";

import Header from "components/Header";
import NavigationLink from "components/NavigationLink";
import NoDataMessage from "components/NoDataMessage";
import PaperSection from "components/PaperSection";

import BackArrow from "svgComponents/BackArrow";

import { HeaderProps, UserProfile } from "types";
import { Routings } from "types/constants";
import { TraineeClassesRouteParams } from "types/interfaces";
import { TraineeClassInfoDto } from "types/Certification";
import { getClassesForCompanyByTrainee } from "services/certificationService";

import { getUserProfile } from "services/userServices";

import TraineeClassesItem from "./TraineeClassesItem";
import TraineeClassesFilters from "./TraineeClassesFilters";

import useStyles from "./styles";

export default function TraineeClassesPage({ isBarOpen, navBarOpen }: HeaderProps) {
    const classes = useStyles();
    const { companyCertificationConfiguration } = useContext(ConfigurationContext);
    const { traineeId } = useParams<TraineeClassesRouteParams>();
    const [isFetched, setIsFetched] = useState(false);
    const [traineeProfile, setTraineeProfile] = useState<UserProfile>();
    const [traineeClasses, setTraineeClasses] = useState<TraineeClassInfoDto[]>([]);
    const [filteredClasses, setFilteredClasses] = useState<TraineeClassInfoDto[]>([]);

    const totalLabel = useMemo(() => (traineeClasses?.length === 1 ? "item" : "items"), [traineeClasses?.length]);

    const fetchData = useCallback(() => {
        Promise.all([getClassesForCompanyByTrainee(traineeId), getUserProfile(traineeId)])
            .then(([classes, profile]) => {
                setTraineeClasses(classes);
                setTraineeProfile(profile);
            })
            .finally(() => setIsFetched(true));
    }, [traineeId]);

    useEffect(() => {
        if (!isFetched) fetchData();
    }, [fetchData, isFetched]);

    const sectionlabel = useMemo(
        () => companyCertificationConfiguration?.section1Label,
        [companyCertificationConfiguration?.section1Label]
    );

    return (
        <>
            <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
            {isFetched && traineeClasses && (
                <>
                    <NavigationLink href={{ pathname: Routings.classes }} className={classes.back}>
                        <Box display="flex" alignItems="center" px={6} pt={4} pb={4}>
                            <BackArrow />
                            <Box ml={2.5}>Back To {sectionlabel}</Box>
                        </Box>
                    </NavigationLink>

                    <Box display="flex" justifyContent="center" mb={5}>
                        <PaperSection
                            px={6}
                            width={1}
                            title={
                                <Box display="flex" alignItems="center" flexWrap="wrap" width={1}>
                                    <Box fontWeight={600} whiteSpace="nowrap" mr={"auto"}>
                                        <Typography variant="h2">
                                            {traineeProfile?.firstName} {traineeProfile?.lastName}’s Classes
                                        </Typography>
                                    </Box>
                                    <TraineeClassesFilters classes={traineeClasses} onFilter={setFilteredClasses} />
                                </Box>
                            }>
                            <Box px={5} py={2}>
                                <Typography variant="caption">
                                    {traineeClasses?.length} {totalLabel}
                                </Typography>
                            </Box>
                            <Divider />

                            {filteredClasses.map((item) => (
                                <TraineeClassesItem key={item.id} item={item} />
                            ))}

                            {!filteredClasses.length && (
                                <Box py={4}>
                                    <NoDataMessage />
                                </Box>
                            )}
                        </PaperSection>
                    </Box>
                </>
            )}

            {isFetched && !traineeClasses && (
                <Box py={4}>
                    <NoDataMessage />
                </Box>
            )}
            {!isFetched && (
                <Box py={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            )}
        </>
    );
}
