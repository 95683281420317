import React, { FC } from "react";
import clsx from "clsx";
import { Box, MenuItem, Typography } from "@material-ui/core";
import { RightArrow } from "svgComponents";
import NavigationLink from "components/NavigationLink";
import SummarySelect from "components/SummarySelect";
import { CoachingPeriodEnum } from "types/constants";

import useStyles from "./styles";

export interface SummaryLayoutProps {
    title: string,
    period?: string,
    linkLabel?: string,
    linkUrl?: string,
    className?: string,
    options?: Record<string, string>,
    onPeriodChange?: (event: React.ChangeEvent<{ name?: string; value: CoachingPeriodEnum }>, child: React.ReactNode) => void
}

// eslint-disable-next-line react/prop-types
const SummaryLayout: FC<SummaryLayoutProps> = ({ children, title, linkLabel = "", linkUrl = "#", className = "", period = "", options = {},  onPeriodChange = () => { /* */ } }) => {
    const isLink = linkLabel.length > 0;
    const classes = useStyles();

    return (
        <Box
            className={clsx(classes.root, className)}
            component="section"
            pb={7}
            pt={5}
            px={{ xs: 3, lg: 12 }}
            borderBottom={1}
        >
            <Box
                display="flex"
                minHeight={5}
                alignItems="center"
            >
                <Box mr={2}>
                    <Typography variant="h1">{title}</Typography>
                </Box>
                <Box mr={2}>
                    <SummarySelect value={period} onChange={onPeriodChange}>
                        {Object.entries(options).map(([key, value]) => (
                            <MenuItem key={key} value={key}>{value}</MenuItem>
                        ))}
                    </SummarySelect>
                </Box>
                <Box ml="auto">
                    {isLink && (
                        <NavigationLink href={linkUrl}>
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>{linkLabel}</Box>
                                <RightArrow />
                            </Box>
                        </NavigationLink>
                    )}
                </Box>
            </Box>
            <Box className={classes.cards} display="grid" mt={4}>
                {children}
            </Box>
        </Box>
    )
}

export default SummaryLayout
