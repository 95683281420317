import React, { useEffect, useMemo } from "react";
import orderBy from "lodash/orderBy";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Paper } from "@material-ui/core";

import NoDataMessage from "components/NoDataMessage";
import { CoachingPlanType, FcrCoachingSkillHistory, TeamProfileRouteParams } from "types";
import { logEvent } from "helpers/analyticsHelper";

import SkillHistoryItem from "./SkillHistoryItem";
import BackToProfile from "../../BackToProfile";

interface TeamProfileSkillHistoryProps {
    fcrCoachingSkillHistory?: FcrCoachingSkillHistory[];
    isFcrCoachingSkillHistoryFetching?: boolean;
    onCoachingPlanChange: (newCoachingPlan: CoachingPlanType) => void,
}

const TeamProfileSkillHistory = ({ fcrCoachingSkillHistory, isFcrCoachingSkillHistoryFetching = false, onCoachingPlanChange }: TeamProfileSkillHistoryProps) => {
    const { userId } = useParams<TeamProfileRouteParams>();

    useEffect(() => {
        logEvent("skill_history_opened", { userId: userId });
    }, [userId]);

    const orderedSkills = useMemo(() =>
        fcrCoachingSkillHistory && fcrCoachingSkillHistory.length ?
            orderBy(fcrCoachingSkillHistory, "averageScore", "desc") : [],
    [fcrCoachingSkillHistory]
    );

    return (
        <>
            <BackToProfile userId={userId} />
            {(!isFcrCoachingSkillHistoryFetching && fcrCoachingSkillHistory) && (
                fcrCoachingSkillHistory.length ? (
                    orderedSkills.map((skill, index) => (
                        <SkillHistoryItem
                            key={index}
                            skill={skill}
                            onCoachingPlanChange={onCoachingPlanChange}
                        />
                    ))
                ) : (
                    <Paper>
                        <NoDataMessage py={7} />
                    </Paper>
                )
            )}
            {isFcrCoachingSkillHistoryFetching && (
                <Box display="flex" justifyContent="center" py={10}>
                    <CircularProgress size={24} />
                </Box>
            )}
        </>
    );
}

export default TeamProfileSkillHistory;