import { ThemeProvider } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import LoggedOut from "routes/Auth/LoggedOut";
import ProtectedRoute from "routes/Auth/ProtectedRoute";
import SignIn from "routes/Auth/SignIn";
import UserNotFound from "routes/Auth/UserNotFound";
import MainComponent from "routes/MainComponent";

import authService from "services/authService";
import SvgDefinitions from "svgComponents/SvgDefinitions";
import { theme } from "theme";
import { Routings, UI_FLICKER_DELAY } from "types/constants";

function App() {
    const [isAuthed, setIsAuthed] = useState(authService.isAuthenticated());
    const authInitialized = useRef(false);
    //Here to work around bug in react where app renders twice in strict mode
    useEffect(() => {
        if (authInitialized.current) {
            return;
        }
        //Set a time out so the sign in screen doesn't flicker faster than 1 second
        setTimeout(() => {
            // Auth layer should be set up at global level.
            authService.initialize(setIsAuthed);
        }, UI_FLICKER_DELAY);
        // But only once, reason: see
        authInitialized.current = true;
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    {!isAuthed && (
                        <>
                            {/* 
                            Unauthed default is sign in page, 
                            so user doesn't see empty white screen while app redirects, 
                            redirect is there for saving specific url requests before authenticating 
                            */}
                            <Route exact path="/">
                                <SignIn />
                            </Route>
                            <Route path={Routings.signin}>
                                <SignIn />
                            </Route>
                            <Route path={Routings.signedout}>
                                <LoggedOut />
                            </Route>
                            <Route path={Routings.userNotFound}>
                                <UserNotFound />
                            </Route>
                        </>
                    )}
                    <ProtectedRoute path="/" isAuthed={isAuthed}>
                        <MainComponent />
                    </ProtectedRoute>
                </Switch>
            </Router>
            <SvgDefinitions />
        </ThemeProvider>
    );
}
export default App;
