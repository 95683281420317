import React, { useState, useContext, useRef } from "react";
import { Box, Divider } from "@material-ui/core";
import { CompanyLevel, Team, UserProfile } from "types";
import { UsersAutocomplete } from "components/UsersAutocomplete";
import LevelFilter, { LevelFilterRef } from "components/LevelFilter";
import useOnCompanyChange from "hooks/useOnCompanyChange";
import { extractTeamLeaders } from "helpers/teamHelpers";
import { CompaniesContext } from "context";

interface TeamsFilterProps {
    usersAutocompleteLabel?: string,
    teams: Team[],
    onFilter: (filteredTeams: Team[] | null) => void,
}

export default function UsersFilter({
    usersAutocompleteLabel,
    teams = [],
    onFilter,
}: TeamsFilterProps) {
    const { companyLevels } = useContext(CompaniesContext);
    const teamLeaders = extractTeamLeaders(teams);
    const levelFilterRef = useRef<LevelFilterRef>(null);
    const [userProfileId, setUserProfileId] = useState<UserProfile["id"] | null>(null);

    const filter = (userProfileId: UserProfile["id"] | null, levelIds: Array<CompanyLevel["id"]> | null) => {
        if (userProfileId) {
            return onFilter(teams.filter((team) => (
                team.teamLeaderId === userProfileId
            )));
        }

        if (!!levelIds?.length) {
            return onFilter(teams.filter((team) => (
                levelIds.includes(team.companyLevelId!)
            )));
        }

        return onFilter(null);
    };

    const handleUserFilter = (userProfileId: UserProfile["id"] | null) => {
        setUserProfileId(userProfileId);
        levelFilterRef?.current?.reset();
        filter(userProfileId, null);
    };

    const handleLevelFilter = (levelIds: Array<CompanyLevel["id"]>) => {
        setUserProfileId(null);
        filter(null, levelIds);
    };

    useOnCompanyChange(() => {
        setUserProfileId(null);
        levelFilterRef?.current?.reset();
        filter(null, null);
    });

    if (!teams.length) return null;

    return (
        <Box py={3} px={4}>
            {(teamLeaders.length > 0) && (
                <>
                    <Box maxWidth={410}>
                        <UsersAutocomplete
                            label={usersAutocompleteLabel}
                            userProfiles={teamLeaders}
                            value={userProfileId}
                            onChange={handleUserFilter}
                        />
                    </Box>
                    <Box py={3}><Divider /></Box>
                </>
            )}
            <>
                <LevelFilter<true> 
                    title=""
                    caption=""
                    direction="row"
                    companyLevels={companyLevels}
                    onFilter={handleLevelFilter}
                    ref={levelFilterRef}
                />
            </>
        </Box>
    );
}
