import { createTheme } from "@material-ui/core/styles";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import { AlertClassKey } from "@material-ui/lab/Alert";
import type {} from "@material-ui/lab/themeAugmentation";

type overridesNameToClassKey = {
    [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

interface Gradient {
    fallback: string;
    gradient: string;
}

declare module "@material-ui/core/styles/overrides" {
    // eslint-disable-next-line
    export interface ComponentNameToClassKey extends overridesNameToClassKey {
        MuiAlert: AlertClassKey;
    }
}

declare module "@material-ui/core/styles" {
    interface Theme {
        text: {
            fontFamily: string
            fontStyle: string
            fontWeight: string
            fontSize: number,
            textTransform: string
        },
        colors: {
            identity: {
                teal: string,
                red: string,
                darkBlue: string,
            },
            text: {
                mediumGray: string,
                lightGray: string,
                fontPurple: string,
                fontTeal: string,
                darkGray: string,
                fontGreen: string,
                fontRed: string,
                darkBlue: string,
                light: string,
            },
            secondary: {
                purple: string,
                orange: string
            },
            structure: {
                fieldsOutline: string,
                rulesAndBorders: string,
                background: string,
                pageBackground: string,
                iconBackground: string,
            },
            graph: {
                high: string,
                medium: string,
                low: string,
                lowest: string,
            },
            graphVertical: {
                high: string,
                medium: string,
                low: string,
                lowest: string,
            },
            statusLabel: {
                background: {
                    green: string,
                },
                text: {
                    gray: string,
                },
            },
            organizationalStructure: string[],
            avatar: string[],
            error: string,
        },
        gradients: {
            darkSea: Gradient;
            sea: Gradient;
            danger: Gradient;
            epic: Gradient;
        },
        defaultButtonStyles: {
            border: string,
            background: string,
            cursor: string,
            backgroundColor: string,
            elevation: number,
            "&:hover": {
                backgroundColor: string,
            }
        },
        MuiTextField: {
            root: {
                backgroundColor: "#FFFFFF",
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "6px",
            },
        },
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        text: {
            fontFamily: string
            fontStyle: string
            fontWeight: string
            fontSize: number,
            textTransform: string
        },
        colors: {
            identity: {
                teal: string,
                red: string,
                darkBlue: string,
            },
            text: {
                mediumGray: string,
                lightGray: string,
                fontPurple: string,
                fontTeal: string,
                darkGray: string,
                fontGreen: string,
                fontRed: string,
                darkBlue: string,
                light: string,
            },
            secondary: {
                purple: string,
                orange: string
            },
            structure: {
                fieldsOutline: string,
                rulesAndBorders: string,
                background: string,
                pageBackground: string,
                iconBackground: string,
            },
            graph: {
                high: string,
                medium: string,
                low: string,
                lowest: string,
            },
            graphVertical: {
                high: string,
                medium: string,
                low: string,
                lowest: string,
            },
            statusLabel: {
                background: {
                    green: string,
                },
                text: {
                    gray: string,
                },
            },
            organizationalStructure: string[],
            avatar: string[],
            error: string,
        },
        gradients: {
            darkSea: Gradient;
            sea: Gradient;
            danger: Gradient;
            epic: Gradient;
        },
        defaultButtonStyles: {
            border: string,
            background: string,
            cursor: string,
            backgroundColor: string,
            elevation: number,
            "&:hover": {
                backgroundColor: string,
            },
        }
    }
}

const defaultTheme = createTheme();

export const theme = createTheme({
    text: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        textTransform: "unset",
    },
    palette: {
        primary: {
            main: "#1FACC1",
            light: "#51A9BE",
            contrastText: "#FFFFFF",
        },
        info: {
            main: "#F8F9FA",
            dark: "#EEF0F3",
        },
        text: {
            primary: "#253A54",
            secondary: "#7C8EA3",
        },
    },
    colors: {
        identity: {
            teal: "#52AABE",
            red: "#CC4734",
            darkBlue: "#2E476B",
        },
        text: {
            mediumGray: "#5A6F8A",
            lightGray: "#7C8EA3",
            fontPurple: "#B02DA3",
            fontTeal: "#37C1E2",
            darkGray: "#253A54",
            fontGreen: "#3EB943",
            fontRed: "#CC344F",
            darkBlue: "#27476E",
            light: "#fff",
        },
        secondary: {
            purple: "#9D4794",
            orange: "#F28D44",
        },
        structure: {
            fieldsOutline: "#BBC4CD",
            rulesAndBorders: "#EEF0F3",
            background: "#EEF0F3",
            pageBackground: "#F6F8F9",
            iconBackground: "rgba(81, 169, 190, 0.2)",
        },
        graph: {
            high: "linear-gradient(270deg, #21DEFA 0%, #0CC2CF 50%, #1BBAB0 100%)",
            medium: "linear-gradient(270deg, #36C0D3 0%, #4B93D5 52.6%, #6185D5 100%)",
            low: "linear-gradient(90deg, #EE8718 0%, #EA7C1E 52.6%, #E04F22 100%)",
            lowest: "linear-gradient(270deg, #C63360 0%, #9D4794 100%)",
        },
        graphVertical: {
            high: "linear-gradient(180deg, #21DEFA 0%, #0CC2CF 50%, #1BBAB0 100%)",
            medium: "linear-gradient(180deg, #36C0D3 0%, #4B93D5 52.6%, #6185D5 100%)",
            low: "linear-gradient(180deg, #EE8718 0%, #EA7C1E 52.6%, #E04F22 100%)",
            lowest: "linear-gradient(180deg, #C63360 0%, #9D4794 100%)",
        },
        statusLabel: {
            background: {
                green: "rgba(114, 255, 65, 0.19)",
            },
            text: {
                gray: "#526B8B",
            },
        },
        organizationalStructure: [
            "#9c4793",
            "#5e5cb1",
            "#3cb942",
            "#f28d43",
            "#c2ac38",
        ],
        avatar: [
            "#8DB43B",
            "#52AABE",
            "#CC4734",
            "#2E476B",
            "#9D4794",
            "#F28D44",
            "#3EB943",
            "#DA4A7D",
            "#5E5CB2",
            "#5769AB",
            "#99406B",
            "#52AD92",
            "#2E6B34",
            "#C83F58",
            "#C77437",
            "#C2AC39",
        ],
        error: "#DD3928",
    },
    gradients: {
        darkSea: {
            fallback: "#427eaa",
            gradient: "linear-gradient(30deg, rgba(62,154,179,1) 0%, rgba(70,133,162,1) 100%)",
        },
        sea: {
            fallback: "#1facc1",
            gradient: "linear-gradient(30deg, rgba(108,213,226,1) 0%, rgba(42,177,198,1) 100%)",
        },
        danger: {
            fallback: "#cc4734",
            gradient: "linear-gradient(30deg, rgba(241,117,121,1) 0%, rgba(226,78,68,1) 100%)",
        },
        epic: {
            fallback: "#9d4794",
            gradient: "linear-gradient(30deg, rgba(203,127,204,1) 0%, rgba(163,78,155,1) 100%)",
        },
    },
    defaultButtonStyles: {
        border: "none",
        background: "none",
        cursor: "pointer",
        backgroundColor: "transparent",
        elevation: 0,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    typography: {
        fontFamily: "Poppins",
        allVariants: {
            color: "#253A54",
        },
        h1: {
            fontSize: 22,
            lineHeight: "33px",
            fontWeight: 600,
        },
        h2: {
            fontSize: 17,
            lineHeight: "24px",
            fontWeight: "inherit",
        },
        h3: {
            fontSize: 15,
            lineHeight: "24px",
            fontWeight: "inherit",
        },
        h5: {
            fontWeight: 600,
            fontSize: 48,
            lineHeight: "70px",
        },
        h6: {
            fontWeight: 500,
            fontSize: 25,
            lineHeight: "37px",
        },
        caption: {
            color: "#7C8EA3",
        },
        body1: {
            fontSize: 14,
            lineHeight: "21px",
            color: "inherit",
            fontWeight: "inherit",
        },
        body2: {
            fontSize: 13,
            lineHeight: "20px",
            color: "inherit",
            fontWeight: "inherit",
        },
        subtitle1: {
            fontWeight: 600,
            fontSize: 40,
            lineHeight: "60px",
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: 32,
            lineHeight: "48px",
        },
        button: {
            textTransform: "none",
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                boxShadow: "none",
                borderBottom: "1px solid #EEF0F3",
            },
        },
        MuiButton: {
            root: {
                borderRadius: "6px",
            },
            label: {
                fontSize: 14,
                fontWeight: "normal",
            },
        },
        MuiIconButton: {
            root: {
                borderRadius: "6px",
            },
        },
        MuiTouchRipple: {
            child: {
                borderRadius: "6px",
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                display: "none",
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                "& .MuiTypography-h2": {
                    fontSize: "40px",
                },
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                marginBottom: 12,
                marginTop: 12,
            },
            dayLabel: {
                color: "#253A54",
                fontWeight: 600,
                fontStyle: "normal",
                fontSize: "12px",
                lineHeight: "24px",
                width: 32,
                height: 32,
            },
            transitionContainer: {
                "& *": {
                    fontWeight: 500,
                    paddingLeft: 8,
                },
            },
        },
        MuiPopover: {
            paper: {
                "& * > .MuiPickersBasePicker-pickerView": {
                    minWidth: "unset",
                    minHeight: "unset",
                    margin: "0px 16px",
                },
            },
        },
        MuiPickersCalendar: {
            transitionContainer: {
                marginTop: 0,
            },
        },
        MuiPickersDay: {
            day: {
                width: 32,
                height: 32,
                fontWeight: 600,
            },
            hidden: {
                opacity: "unset",
                color: "#7C8EA3",
                fontWeight: 400,
            },
            daySelected: {
                backgroundColor: "#51A9BE",
            },
        },
        MuiStepper: {
            root: {
                padding: 0,
            },
        },
        MuiInputBase: {
            multiline: {
                borderRadius: "6px",
                border: "1px solid #BBC4CD",
                marginBottom: 16,
                padding: "24px 32px 24px 24px",
                fontSize: "15px",
                lineHeight: "27px",
                opacity: 0.8,
            },
            input: {
                padding: "3px 0px",
            },
        },
        MuiStepConnector: {
            active: {
                borderBottom: "1px solid #51A9BE",
                "&.MuiStepConnector-vertical": {
                    borderBottom: 0,
                    borderLeft: "1px solid #51A9BE",
                },
            },
            completed: {
                borderBottom: "1px solid #51A9BE",
                "&.MuiStepConnector-vertical": {
                    borderBottom: 0,
                    borderLeft: "1px solid #51A9BE",
                },
            },
            root: {
                borderBottom: "0.5px dashed rgba(39, 71, 110, 0.9)",
                "&.MuiStepConnector-vertical": {
                    borderBottom: 0,
                    borderLeft: "0.5px dashed rgba(39, 71, 110, 0.9)",
                },
            },
            line: {
                display: "none",
            },
        },
        MuiStepLabel: {
            active: {
                fontWeight: "inherit",
                color: "#253A54",
            },
            completed: {
                fontWeight: "inherit",
                color: "#253A54",
            },
            label: {
                fontWeight: "inherit",
                color: "#253A54",
            },
        },
        MuiSlider: {
            thumb: {
                width: 19,
                height: 19,
                color: "#FFFFFF",
                boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.26)",
                "&:focus, &:hover, &$active, &$open": {
                    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.26)",
                },
            },
            track: {
                height: 9,
                borderRadius: 40,
            },
            rail: {
                height: 9,
                background: "#EEF0F3",
                color: "#EEF0F3",
                opacity: 1,
                borderRadius: 40,
            },
        },
        MuiLinearProgress: {
            root: {
                height: 9,
                borderRadius: 5,
            },
            colorPrimary: {
                backgroundColor: "#EEF0F3",
            },
            bar: {
                borderRadius: 5,
                backgroundPosition: "right",
            },
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: "#52AABE",
            },
        },
        MuiTypography: {
            paragraph: {
                marginBottom: 8,
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "14px",
            },
            elevation1: {
                boxShadow: "0px 0px 5px rgba(39, 71, 110, 0.06)",
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#EEF0F3",
            },
            middle: {
                marginLeft: "30px",
                marginRight: "30px",
            },
            vertical: {
                marginLeft: -1,
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: "#27476E",
            },
            arrow: {
                color: "#27476E",
            },
        },
        MuiLink: {
            button: {
                fontFamily: "Poppins",
                fontSize: "15px",
                lineHeight: "24px",
            },
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: "#253A54",
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "21px",
            },
        },
        MuiAlert: {
            filledInfo: {
                backgroundColor: "#253A54",
                color: "#FFFFFF",
                "& .MuiTypography-root": {
                    color: "#FFFFFF",
                },
            },
            filledSuccess: {
                "& .MuiTypography-root": {
                    color: "#FFFFFF",
                },
            },
            filledWarning: {
                "& .MuiTypography-root": {
                    color: "#FFFFFF",
                },
            },
            filledError: {
                "& .MuiTypography-root": {
                    color: "#FFFFFF",
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "6px",
            },
        },
        MuiSelect: {
            outlined: {
                borderRadius: "6px",
            },
        },
        MuiTab: {
            root: {
                padding: "0 0 8px",
                marginRight: "16px",
                minHeight: 0,
                borderBottom: "2px solid transparent",
                [defaultTheme.breakpoints.up("xs")]: {
                    minWidth: 0,
                },
                "&:not($selected):hover, &$selected": {
                    textDecoration: "none",
                    borderBottomColor: "currentColor",
                },
            },
            textColorInherit: {
                opacity: 1,
                fontWeight: 500,
            },
            textColorPrimary: {
                color: "#5A6F8A",
            },
        },
        MuiTabs: {
            root: {
                padding: "0",
                minHeight: 0,
            },
            indicator: {
                display: "none",
            },
        },
        MuiTabPanel: {
            root: {
                padding: "0",
            },
        },
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
});
