import React, { useContext, useMemo } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { ChevronLeft, TickIcon } from "svgComponents";
import { Box, Grid, Typography, Paper, Button } from "@material-ui/core";
import { Company, CompanyFormProps, CompanyLevelLabel, FcrResource } from "types";
import { AdminRoutings } from "types/constants";
import { getCompanyFormData } from "helpers/companyHelper";
import {
    saveCompanyLevelLabels,
    saveCompany,
    uploadCompanyLogo,
    saveCompanyFcrSectionConfiguration,
    saveCertificationSectionConfiguration,
} from "services/companyServices";
import LoadingButton from "components/LoadingButton";
import { CompaniesContext } from "context";
import NavigationLink from "components/NavigationLink";
import { getResourceFromFile } from "helpers/resourceHelper";

import CompanyDetailsForm from "./CompanyDetailsForm";
import LevelLabelsForm from "./LevelLabelsForm";
import FcrSectionConfigurationForm from "./FcrSectionConfigurationForm";
import ClassesNavigation from "./ClassesNavigation";

const CompanySchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    contactEmail: Yup.string().required("Required"),
    authenticationTypeId: Yup.string().required("Required"),
    logoFile: Yup.mixed().when("logo", (logo, schema) => (logo ? schema : schema.required("Required"))),
    fcrSectionConfiguration: Yup.object().shape({
        section1Label: Yup.string().required("Required"),
        section2Label: Yup.string().required("Required"),
        section3Label: Yup.string().required("Required"),
        section4Label: Yup.string().required("Required"),
    }),
    levelLabels: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Required"),
        })
    ),
    learningLibraryUrl: Yup.string().url("Enter a valid URL"),
    certificationsUrl: Yup.string().url("Enter a valid URL"),
    certificationSectionConfiguration: Yup.object().shape({
        section1Label: Yup.string().required("Required"),
    }),
});

function CompanyForm() {
    const {
        currentUser,
        currentCompany,
        companyLevelLabels,
        setCompanyLevelLabels,
        companyCertificationConfiguration,
        getAllCompaniesList,
        handleCompanyUpdated,
        isFetchingCompanyData,
        fcrSectionConfiguration,
        setFcrSectionConfiguration,
    } = useContext(CompaniesContext);
    const companyId = currentCompany?.id;
    const history = useHistory();

    const initialValues: CompanyFormProps = useMemo(
        () => getCompanyFormData(currentCompany, companyLevelLabels, fcrSectionConfiguration, companyCertificationConfiguration),
        [currentCompany, companyLevelLabels, fcrSectionConfiguration, companyCertificationConfiguration]
    );

    const saveCompanyLogo = async (companyId: string, file: File | null): Promise<FcrResource | null> => {
        if (!file) return Promise.resolve(null);

        const resource = await getResourceFromFile(file, companyId, currentUser!.id);

        return uploadCompanyLogo(companyId, resource);
    };

    const handleSubmit = async (values: CompanyFormProps, { setSubmitting }: FormikHelpers<CompanyFormProps>) => {
        const { levelLabels, fcrSectionConfiguration, logoFile, certificationSectionConfiguration, ...companyData } = values;

        return saveCompany(companyData)
            .then((company: Company) => {
                const levelLabelsWithCompanyId: CompanyLevelLabel[] = levelLabels.map((levelLabel) => ({
                    ...levelLabel,
                    companyId: company.id,
                }));

                return Promise.all([
                    saveCompanyLevelLabels(company.id, levelLabelsWithCompanyId),
                    saveCompanyFcrSectionConfiguration(company.id, fcrSectionConfiguration),
                    saveCompanyLogo(company.id, logoFile),
                    saveCertificationSectionConfiguration(company.id, certificationSectionConfiguration),
                ]);
            })
            .then(([companyLevelLabels, fcrSectionConfiguration]) => {
                if (!companyId) {
                    getAllCompaniesList(companyLevelLabels[0].companyId);
                } else {
                    handleCompanyUpdated(companyId);
                }
                setCompanyLevelLabels(companyLevelLabels);
                setFcrSectionConfiguration(fcrSectionConfiguration);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleCancel = () => {
        history.push(AdminRoutings.companies);
    };

    return (
        <Formik enableReinitialize initialValues={initialValues} validationSchema={CompanySchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
                <Form>
                    <Box mb={6} display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                        <NavigationLink hoverUnderline={false} href={AdminRoutings.companies}>
                            <Typography variant="h1">
                                <Box display="flex" alignItems="center" gridGap={12}>
                                    <ChevronLeft display="block" height={24} width={24} />
                                    {companyId ? "Edit Company Information" : "New Company"}
                                </Box>
                            </Typography>
                        </NavigationLink>
                        <Box display="flex">
                            <Box mr={2}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    disableElevation
                                    loading={isSubmitting}
                                    loadingLabel={companyId ? "Updating..." : "Creating..."}
                                    startIcon={!companyId ? null : <TickIcon />}>
                                    {companyId ? "Save updates" : "Create Company"}
                                </LoadingButton>
                            </Box>
                            <Button variant="outlined" size="large" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Paper>
                                <CompanyDetailsForm />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <LevelLabelsForm isLoading={isFetchingCompanyData} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <FcrSectionConfigurationForm isLoading={isFetchingCompanyData} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <ClassesNavigation isLoading={isFetchingCompanyData} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default CompanyForm;
