import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    root: {
        height: "100%",
        width: "auto",
        "& path": {
            stroke: "currentColor",
        },
    },
}, {
    name: "FocusIcon",
});
