import React, { useMemo } from "react";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { Box, Button, CircularProgress } from "@material-ui/core";

import FormMultilineInput from "components/Form/FormMultilineInput";
import AvatarWithScore from "components/AvatarWithScore";
import { addMessage } from "services/messageServices";
import { MessageType, UserProfile } from "types";
import { SizeEnum } from "types/constants";

interface MessageHistoryThreadReplyProps {
    currentUser: UserProfile;
    originalMessage: MessageType;
    onResponseSent: (message: MessageType) => void;
}

const MessageHistoryThreadReply = ({ currentUser, originalMessage, onResponseSent }: MessageHistoryThreadReplyProps) => {
    const toUserId = useMemo(() => (
        originalMessage.fromUserId === currentUser.id
            ? originalMessage.toUserId
            : originalMessage.fromUserId
    ), [currentUser.id, originalMessage]);

    const formik = useFormik({
        initialValues: { response: "" },
        validationSchema: Yup.object().shape({
            response: Yup.string().required("Required"),
        }),
        onSubmit({ response }, { setSubmitting, resetForm }) {
            addMessage(originalMessage.subject, response, toUserId, originalMessage.threadId)
                .then((message) => {
                    resetForm();
                    onResponseSent(message);
                })
                .finally(() => setSubmitting(false));
        },
    });

    return (
        <Box display="flex">
            <FormikProvider value={formik}>
                <AvatarWithScore user={currentUser} size={SizeEnum.small} />
                <Box ml={2} width={1}>
                    <FormMultilineInput
                        name="response"
                        placeholder="Add response..."
                    />
                    <Box display="flex" justifyContent="end" mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            disabled={formik.isSubmitting}
                        >
                            {formik.isSubmitting ? <CircularProgress size={24} /> : "Send response"}
                        </Button>
                    </Box>
                </Box>
            </FormikProvider>
        </Box>
    );
};

export default React.memo(MessageHistoryThreadReply);
