import React from "react";
import { Paper, Box } from "@material-ui/core";

interface PaperCard {
    children: React.ReactNode;
}

function PaperCard({ children }: PaperCard) {
    return (
        <Paper>
            <Box py={{ xs: 3, md: 4 }} px={{ xs: 3, md: 5 }}>
                {children}
            </Box>
        </Paper>
    );
}

export default PaperCard;
