import React from "react";
import FormAutocompleteInput, { AutocompleteInput } from "components/Form/FormAutocomplete";
import useUsersAutocomplete, { UseUsersAutocompleteProps } from "hooks/useUsersAutocomplete";
import { UserProfile } from "types";

interface FormUsersAutocompleteProps<Multiple extends boolean | undefined> extends UseUsersAutocompleteProps<Multiple> {
    name: string,
    required?: boolean,
}

export function UsersAutocomplete<
    Multiple extends boolean | undefined = undefined,
>({
    ...props
}: UseUsersAutocompleteProps<Multiple>) {
    const baseProps = useUsersAutocomplete<Multiple>(props);

    return (
        <AutocompleteInput<UserProfile["id"], Multiple, false, false>
            {...baseProps}
        />
    );
}

export function FormUsersAutocomplete<
    Multiple extends boolean | undefined = undefined,
>({ 
    name, 
    ...props
}: FormUsersAutocompleteProps<Multiple>) {
    const baseProps = useUsersAutocomplete<Multiple>(props);

    return (
        <FormAutocompleteInput<UserProfile["id"], Multiple, false, false>
            {...baseProps}
            name={name}
        />
    );
}
