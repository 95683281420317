import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    checkbox: {
        display: "none",
    },
    listItem: {
        padding: "0 20px",

        "&:last-child > *": {
            borderBottom: "none",
            marginBottom: 0,
        },
    },
    listItemDisabled: {
        opacity: 0.6,
    },
    listItemActive: {
        color: theme.colors.text.light,
        background: theme.colors.identity.teal,

        "&:hover": {
            background: theme.colors.identity.teal,
        },
    },
    listItemWrap: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        padding: "14px 0",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.info.dark}`,
        marginBottom: "-1px",
    },
    listItemWrapActive: {
        color: theme.colors.text.light,
        borderBottom: "1px solid rgb(238, 240, 243, 0.2)",
        zIndex: 1,
    },
    listItemContent: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
    },
    listItemName: {
        paddingRight: "5px",
    },
    check: {
        opacity: 0,
        transition: theme.transitions.create("opacity", { duration: 300 }),
        color: theme.colors.text.light,
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "32px",
    },
    checkActive: {
        opacity: 1,
    },
    lvl: {
        flexWrap: "nowrap",
        justifyContent: "flex-start",

        "$listItemDisabled &": {
            textDecoration: "line-through",
            textDecorationColor: theme.colors.text.mediumGray,
        },
    },
    lvlItem: {
        position: "relative",
        paddingLeft: "11px",
    },
    lvlArrow: {
        position: "absolute",
        top: "50%", 
        left: "-3px",
        color: theme.colors.structure.fieldsOutline,
        transform: "translateY(-50%);",
        "& svg": {
            display: "block",
        },
    },
    lvlLabel: {
        color: theme.colors.text.mediumGray,

        "$listItemActive &": {
            color: theme.colors.text.light,
        },
    },
    listItemTick: {
        display: "flex", 
        justifyContent: "flex-end", 
        alignItems: "center", 
        position: "absolute", 
        right: 0,
        top: "15px",
    },
}));
