import * as React from "react";

function SvgRidealong(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={15}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.91 5.705h-.12c-.46-2.82-3.58-5.15-6.99-5.15-3.28 0-6.04 2.17-6.5 5.01-2.42.25-4.3 1.99-4.3 4.11v1.63h1.89v.03c0 1.58 1.29 2.87 2.87 2.87s2.87-1.29 2.87-2.87v-.03h3.4c.02 1.56 1.3 2.83 2.87 2.83s2.84-1.26 2.87-2.83h1.16v-5.6h-.02zm-1.45-.27h-5.48l-.01-3.61c2.55.06 4.87 1.63 5.49 3.61zm-6.74-3.52l.01 3.52H5.59c.45-1.78 2.09-3.16 4.13-3.52zm-4.97 11.04a1.626 1.626 0 01-1.56-2.06c.2-.69.84-1.18 1.56-1.18.74 0 1.38.5 1.57 1.21.03.13.05.27.05.41.01.9-.72 1.62-1.62 1.62zm9.13-.07c-.89 0-1.62-.73-1.62-1.62 0-.89.73-1.62 1.62-1.62a1.62 1.62 0 110 3.24zm0-4.49c-1.15 0-2.14.69-2.6 1.67H7.32c-.48-.96-1.46-1.6-2.57-1.6-1.1 0-2.09.64-2.57 1.59h-.93v-.38c0-1.6 1.63-2.91 3.63-2.91h11.78v3.29h-.18a2.862 2.862 0 00-2.6-1.66z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgRidealong;
