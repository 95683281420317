import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Box, Button, Dialog, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography, Tooltip } from "@material-ui/core";
import { FcrItemConfiguration, IdValue } from "types";
import { Form, useFormik, FormikProvider } from "formik";
import LoadingButton from "components/LoadingButton";
import { DeleteIcon, TickIcon } from "svgComponents";
import { FormInput, FormMultilineInput } from "components/Form";
import { ComponentType, FcrSimpleCheckboxItemValueEnum } from "types/constants";
import { deleteFcrItemConfiguration, postFcrItemConfiguration } from "services/wizardServices";
import Checkbox from "components/Checkbox";
import { getFcrItemConfigurationFormData, buildIdValue, TYPES_DATA, ERROR_MESSAGES } from "helpers/fcrConfigurationHelper";

import FcrItemIdValues from "./FcrItemIdValues";
import useStyles from "./styles";

interface FcrSettingsModalProps {
    item: FcrItemConfiguration | null,
    fcrConfigurationId: string,
    open: boolean,
    onClose: () => void,
    onSuccess: () => Promise<void>,
    currentListLength: number,
}

const validationSchema = Yup.object().shape({
    elementHeader: Yup.string().required("Required"),
    itemValues: Yup.array()
        .when("fcrItemTypeId", (fcrItemTypeId: ComponentType, schema) => {
            if (fcrItemTypeId === ComponentType.multiselectCheckbox
                || fcrItemTypeId === ComponentType.radioButtons
                || fcrItemTypeId === ComponentType.dropdownList
            ) {
                return schema
                    .compact((itemValue: IdValue) => !itemValue.value)
                    .min(2, ERROR_MESSAGES[fcrItemTypeId]);
            }
            if (fcrItemTypeId === ComponentType.freeInput) {
                return schema.of(Yup.object().shape({
                    value: Yup.string().required("Required"),
                }));
            }
            return schema;
        }),
});

export default function FcrSettingsModal({ fcrConfigurationId, item, open, onClose, onSuccess, currentListLength }: FcrSettingsModalProps) {
    const classes = useStyles();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const disabled = useMemo(() => loadingSubmit || loadingDelete, [loadingDelete, loadingSubmit]);

    const initialValues = useMemo(() => (
        getFcrItemConfigurationFormData(item, fcrConfigurationId)
    ), [item, fcrConfigurationId]);

    const handleSubmit = useCallback(async (values: FcrItemConfiguration) => {
        if (disabled) return;

        setLoadingSubmit(true);
        const payload: FcrItemConfiguration = {
            ...values,
            order: currentListLength + 1,
            itemValues: values.itemValues
                .filter(({ value }) => value)
                .map((itemValue, idx) => ({ ...itemValue, order: idx + 1 })),
        };

        postFcrItemConfiguration(payload)
            .then(onSuccess)
            .then(onClose)
            .finally(() => setLoadingSubmit(false));
    }, [disabled, onClose, onSuccess, currentListLength]);

    const handleDelete = useCallback(async () => {
        if (loadingDelete) return;

        setLoadingDelete(true);

        deleteFcrItemConfiguration(item!.id)
            .then(onSuccess)
            .then(onClose)
            .finally(() => setLoadingDelete(false));
    }, [item, loadingDelete, onSuccess, onClose]);

    const formik = useFormik<FcrItemConfiguration>({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
    });

    const { values, handleChange, setValues, resetForm } = formik;

    useEffect(() => {
        if (!open) resetForm();
    }, [open, resetForm]);

    const handleItemTypeChange = useCallback((e) => {
        const updatedValues = new Map();
        updatedValues.set("fcrItemTypeId", e.target.value);

        if (e.target.value === item?.fcrItemTypeId) {
            updatedValues.set("itemValues", item?.itemValues);
        } else if (e.target.value === ComponentType.compliance) {
            updatedValues.set("itemValues", [
                buildIdValue({ ...values.itemValues[0], value: "True", order: 1 }),
                buildIdValue({ ...values.itemValues[1], value: "False", order: 2 }),
            ]);
            updatedValues.set("isRequired", true);
        } else if (e.target.value === ComponentType.singleCheckbox) {
            updatedValues.set("itemValues", [
                buildIdValue({ ...values.itemValues[0], value: FcrSimpleCheckboxItemValueEnum.checked, order: 1 }),
                buildIdValue({ ...values.itemValues[1], value: FcrSimpleCheckboxItemValueEnum.unchecked, order: 2 }),
            ]);
        } else if (e.target.value === ComponentType.freeInput) {
            updatedValues.set("itemValues", [buildIdValue({ order: 1 })]);
        } else {
            updatedValues.set("itemValues", [buildIdValue({ order: 1 }), buildIdValue({ order: 2 })]);
        }

        setValues({ ...values, ...Object.fromEntries(updatedValues.entries()) });
    }, [item, values, setValues]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <FormikProvider value={formik}>
                <Form>
                    <Box className={classes.window} width="100%">
                        <Box px={4} py={3}>
                            <Typography variant="h1">
                                {item ? "Edit Summary Element" : "Create New Summary Element"}
                            </Typography>
                        </Box>
                        <Box className={classes.form} px={3} py={3}>
                            <Typography variant="h2">
                                <Box component="span" fontWeight={500}>
                                    Element Details
                                </Box>
                            </Typography>
                            <Box className={classes.field} mt={3}>
                                <FormInput fullWidth name="elementHeader" placeholder="Title" />
                            </Box>
                            <Box className={classes.bodyText} mt={2}>
                                <FormMultilineInput name="elementBody" placeholder="Body text..." />
                            </Box>
                            <Box mt={4}>
                                <Divider />
                            </Box>
                            <Box mt={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="h2">
                                            <Box component="span" fontWeight={500}>
                                                Type
                                            </Box>
                                        </Typography>
                                        <Box mt={1}>
                                            <RadioGroup name="fcrItemTypeId">
                                                {TYPES_DATA.map(([key, label, tooltip], idx) => (
                                                    <Tooltip
                                                        key={idx}
                                                        arrow
                                                        placement="top"
                                                        title={tooltip}
                                                    >
                                                        <FormControlLabel
                                                            control={<Radio color="primary" />}
                                                            value={key}
                                                            label={label}
                                                            onChange={handleItemTypeChange}
                                                            checked={key === values.fcrItemTypeId}
                                                        />
                                                    </Tooltip>
                                                ))}
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="h2">
                                            <Box component="span" fontWeight={500}>
                                                Value(s)
                                            </Box>
                                        </Typography>
                                        <Box mt={2}>
                                            <FcrItemIdValues />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box px={3} py={3}>
                            <Grid alignItems="center" container spacing={2}>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox name="isRequired" color="primary" />}
                                        label="Required"
                                        onChange={handleChange}
                                        checked={values.isRequired}
                                        disabled={values.fcrItemTypeId === ComponentType.compliance}
                                    />
                                </Grid>
                                <Grid className={classes.alignRight} item>
                                    <LoadingButton
                                        color="primary"
                                        disabled={disabled}
                                        disableElevation
                                        loading={loadingSubmit}
                                        loadingLabel={item ? "Saving..." : "Creating..."}
                                        size="large"
                                        startIcon={item ? <TickIcon /> : null}
                                        variant="contained"
                                        type="submit"
                                    >
                                        <Box component="span" lineHeight="30px">
                                            {item ? "Save" : "Create"}
                                        </Box>
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={disabled}
                                        size="large"
                                        variant="outlined"
                                        onClick={onClose}
                                    >
                                        <Box component="span" lineHeight="30px">Cancel</Box>
                                    </Button>
                                </Grid>
                                {item && (
                                    <Grid item>
                                        <LoadingButton
                                            color="default"
                                            disabled={disabled}
                                            disableElevation
                                            loading={loadingDelete}
                                            loadingLabel={""}
                                            size="large"
                                            startIcon={null}
                                            variant="outlined"
                                            type="submit"
                                            onClick={handleDelete}
                                        >
                                            <Box alignItems="center" display="flex" component="span" height="30px">
                                                <DeleteIcon height={20} width={20} />
                                            </Box>
                                        </LoadingButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Box>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}