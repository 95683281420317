import * as React from "react";

function SvgAchievement(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={20}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M19.74 15.9999L15.4 8.97988C15.29 8.79988 15.09 8.67988 14.87 8.67988C14.65 8.67988 14.45 8.78988 14.34 8.97988L12.6 11.8299L8.63 5.40988V3.61988H10.84C11.07 3.61988 11.29 3.48988 11.39 3.27988C11.5 3.06988 11.48 2.81988 11.35 2.62988L10.98 2.10988L11.34 1.61988C11.48 1.42988 11.5 1.17988 11.39 0.969883C11.28 0.759883 11.07 0.629883 10.83 0.629883H7.99C7.65 0.629883 7.37 0.909882 7.37 1.24988V2.99988C7.37 3.00988 7.37 3.00988 7.37 3.01988V5.42988L0.929999 15.9999C0.809999 16.1899 0.809997 16.4299 0.919997 16.6299C1.03 16.8299 1.24 16.9499 1.46 16.9499H10.59H10.72H19.21C19.44 16.9499 19.65 16.8299 19.76 16.6299C19.86 16.4399 19.86 16.1899 19.74 15.9999ZM14.87 10.5099L15.33 11.2599H14.41L14.87 10.5099ZM8 6.78988L9.44 9.11988H6.58L8 6.78988ZM8.61 1.87988H9.62C9.56 2.03988 9.56 2.20988 9.63 2.36988H8.61V1.87988V1.87988ZM5.82 10.3699H10.22L11.87 13.0299L10.25 15.6999H2.58L5.82 10.3699ZM11.7 15.6999L13.11 13.3899L13.65 12.4999H16.11L18.09 15.6999H11.7Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgAchievement;