import React from "react";
import { Box, Typography, FormHelperText, useTheme } from "@material-ui/core";

export function AssignmentCaptionMessage() {
    const theme = useTheme();

    return (
        <Typography variant="caption">
            Select from the list of available members to assign them to the selected team. Only those that have not yet been assigned to a
            team will be displayed in the
            <Box component="span" fontWeight="500" color={theme.palette.text.primary}>
                {" "}
                Available Members{" "}
            </Box>
            list.
        </Typography>
    );
}

export function AssignmentWarningMessage() {
    return (
        <FormHelperText error>
            Note: When working with large groups, to reduce the risk of timeouts, please incrementally save your changes after updating
            around 200 records at a time.
        </FormHelperText>
    );
}
