import React from "react";
import SvgGoals from "svgComponents/Goals";
import clsx from "clsx";

import useStyles from "./styles";

const GoalsIcon = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
    const classes = useStyles()

    return <SvgGoals className={clsx(classes.root, className)} {...props} />
} 

export default GoalsIcon;
