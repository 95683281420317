import * as React from "react";

function SvgBubbleWithCheckmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.211 14.26v-2.52h-1.26c-.69 0-1.26-.57-1.26-1.26V2.92c0-.69.57-1.26 1.26-1.26h10.08c.691 0 1.26.57 1.26 1.26v7.56c0 .69-.569 1.26-1.26 1.26h-5.46l-3.36 2.52z"
        stroke="#51A9BE"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <g clipPath="url(#BubbleWithCheckmark_svg__clip0_0_1)">
        <path
          d="M5.563 6.758L7.188 8.45l3.285-3.387"
          stroke="#51A9BE"
          strokeWidth={1.5}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="BubbleWithCheckmark_svg__clip0_0_1">
          <path
            fill="#fff"
            transform="translate(5 4.5)"
            d="M0 0h6.04v4.516H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBubbleWithCheckmark;
