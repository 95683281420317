import { v4 as uuidv4 } from "uuid";

import { CoachingSession, CoachingSessionType, UpcomingCoachingSession, CoachingSessionResults, CoachingSessionFilter } from "types";

import API from "./apiService";
import { constructCoachingPlans } from "./wizardServices";

export const getLastCoachingSession = async (userId: string): Promise<CoachingSession | undefined> => {
    const response = await API.get(`/CoachingSession/getLastForAssignee/${userId}`);

    return response.data;
};

export const getCoachingSession = async (coachingSessionId: string): Promise<CoachingSession> => {
    const results = await API.get(`/coachingSession/${coachingSessionId}`);
    return results.data;
}

export const deleteCoachingSession = async (coachingSessionId: string): Promise<boolean> => {
    const results = await API.delete(`/coachingSession/${coachingSessionId}`);
    return results.data;
}

export const saveCoachingSession = async (coachingSession: Partial<CoachingSession>): Promise<CoachingSession> => {
    if (!coachingSession.id) {
        const newCoachingSession = {
            ...coachingSession,
            id: uuidv4(),
        };
        const result = await API.post(`/coachingSession/${newCoachingSession.id}`, newCoachingSession);
        return result.data;
    } else {
        const result = await API.post(`/coachingSession/${coachingSession.id}`, coachingSession);
        return result.data;
    }
}

export const getCoachingSessionsForCoachAndAssignee = async (coachId: string, assigneeId: string, pageNumber: number, filters?: CoachingSessionFilter, recordsAmount = 50): Promise<CoachingSessionResults> => {
    const url = `/coachingSession/getForCoachAndAssignee/${coachId}/${assigneeId}?pageNumber=${pageNumber}&recordsAmount=${recordsAmount}`;

    const result = await API.post(url, filters);
    return result.data;
}

export const getCoachingSessionsForAssignee = async (assigneeId: string, pageNumber: number, filters?: CoachingSessionFilter, recordsAmount = 50): Promise<CoachingSessionResults> => {
    const url = `/coachingSession/getForAssignee/${assigneeId}?pageNumber=${pageNumber}&recordsAmount=${recordsAmount}`;

    const result = await API.post(url, filters);
    return result.data;
}

export const getCoachingSessionsForCoach = async (coachId: string, startDate?: string): Promise<Array<CoachingSession>> => {
    const url = `/coachingSession/getForCoach/${coachId}${startDate ? `?startDate=${startDate}` : ""}`;
    const result = await API.get(url);
    return result.data;
}

const formatUpcomingCoachingSessionsResponse = (data: UpcomingCoachingSession[]): UpcomingCoachingSession[] => data.map(session => {
    if (!session.coachingSession.fcrResult) return session;

    const plans = session.coachingSession.fcrResult.fcrCoachingPlans ?? [];
    const skills = session.coachingSession.fcrResult.fcrCoachingSkillResults ?? [];
    const fcrCoachingPlans = constructCoachingPlans(plans, skills);

    return {
        ...session,
        coachingSession: {
            ...session.coachingSession,
            fcrResult: {
                ...session.coachingSession.fcrResult,
                fcrCoachingPlans,
            },
        },
    }
});

export const getUpcomingCoachingSessionsForCoach = async (coachId: string, amount = 3, startDate?: string): Promise<Array<UpcomingCoachingSession>> => {
    const response = await API.get(`/coachingSession/getUpcomingForCoach/${coachId}/${amount}${startDate ? `?startDate=${startDate}` : ""}`);

    return formatUpcomingCoachingSessionsResponse(response.data ?? []);
};

export const getUpcomingCoachingSessionsForHighLevelLead = async (leadId: string, amount = 3): Promise<Array<UpcomingCoachingSession>> => {
    const response = await API.get(`/coachingSession/getUpcomingForHighLevelLead/${leadId}/${amount}`);

    return formatUpcomingCoachingSessionsResponse(response.data ?? []);
};

export const getScheduledCoachingSessionsForCoach = async (coachId: string): Promise<Array<CoachingSession>> => {
    const response = await API.get(`/coachingSession/getForCoach/${coachId}`);

    return response.data;
};

export const getCoachingSessionsForUsersBelow = async (userId: string, pageNumber: number, filters?: CoachingSessionFilter, recordsAmount = 50): Promise<CoachingSessionResults> => {
    const response = await API.post(`/coachingSession/getForUsersBelow/${userId}?pageNumber=${pageNumber}&recordsAmount=${recordsAmount}`, filters);

    return response.data;
};

export const getAmountOfIncompleteReportsForCoach = async (userId: string): Promise<number> => {
    const { data } = await API.get(`/coachingSession/getAmountOfIncompleteForCoach/${userId}`);
    return data;
};

export const getAmountOfIncompleteReportsForCoachee = async (userId: string): Promise<number> => {
    const { data } = await API.get(`/coachingSession/getAmountOfIncompleteForCoachee/${userId}`);
    return data;
};

export const getAmountOfNotStartedForSessionType = async (sessionTypeId: string, companyId: string): Promise<number> => {
    const response = await API.get(`/coachingSession/getAmountOfNotStartedForSessionType/${sessionTypeId}?companyId=${companyId}`);
    return response.data;
};

export const getCoachingSessionTypesForFcrConf = async (fcrConfigurationId: string): Promise<CoachingSessionType[]> => {
    const response = await API.get(`/coachingSessionType/getForFcrConfiguration/${fcrConfigurationId}`);
    return response.data;
};

export const saveCoachingSessionType = async (fcrConfigurationId: string, data: CoachingSessionType): Promise<CoachingSessionType> => {
    const response = await API.post(`/coachingSessionType/saveForFcrConfiguration/${fcrConfigurationId}/${data.id}`, data);
    return response.data;
};

export const deleteCoachingSessionType = async (fcrConfigurationId: string, sessionTypeId: string): Promise<CoachingSessionType> => {
    const response = await API.delete(`/coachingSessionType/deleteForFcrConfiguration/${fcrConfigurationId}/${sessionTypeId}`);
    return response.data;
};