import { Box, MenuItem, Select, SortDirection, TextField } from "@material-ui/core";
import React from "react";

import { SortDirectionEnum } from "types/constants";

import { ClassesContext } from "context";

import { TuneIcon } from "svgComponents";

import {
    AllScheduledClassesSorting,
    defaultAllClassesSortDirection,
    DEFAULT_CLASS_NAME_FILTER_VALUE,
} from "routes/MainClassListingsPage/shared-classes.data";

import useStyles from "./styles";

const TuneFilterIcon = (props: unknown) => <TuneIcon height={24} {...props} />;

export interface AllScheduledClassesFiltersProps {
    allSchedClassNameFilter: string | undefined;
    dateSortDirection: SortDirection | undefined;
    isFetchingAllSchedClasses: boolean | undefined;
    sortEnabled: boolean | undefined;
}

function AllScheduledClassesFilters({
    allSchedClassNameFilter,
    dateSortDirection,
    isFetchingAllSchedClasses,
    sortEnabled,
}: AllScheduledClassesFiltersProps) {
    const classes = useStyles();

    //Extract context state funcs to update state, rely on all displayed values to be passed via props to memoize pure component
    const { setAllSchedClassNameFilter, setAllSchedClassesSorting } = React.useContext(ClassesContext);

    /** Update sort by date direction */
    const handleSortDirectionChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (!e?.target?.value || !setAllSchedClassesSorting) {
            return;
        }
        //Actually sort the data stored in the client
        setAllSchedClassesSorting((e.target.value as SortDirectionEnum) ?? defaultAllClassesSortDirection.dateSortDirection);
    };

    /** Update the class name to filter by or revert to all class names */
    const handleClassNameFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined): void => {
        if (!setAllSchedClassNameFilter) {
            return;
        }
        setAllSchedClassNameFilter(event?.target?.value ?? DEFAULT_CLASS_NAME_FILTER_VALUE);
    };

    return (
        <Box className={classes.filtersContainer}>
            <Box minWidth={220}>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Filter By Class Name"
                    value={allSchedClassNameFilter ?? ""}
                    onChange={handleClassNameFilterChange}
                    disabled={isFetchingAllSchedClasses}
                />
            </Box>
            {/* Note that sorting should be disabled when there is no data or the filter doesn't match any class titles. */}
            {dateSortDirection ? (
                <Select
                    variant="outlined"
                    value={dateSortDirection}
                    onChange={handleSortDirectionChange}
                    IconComponent={TuneFilterIcon}
                    disabled={isFetchingAllSchedClasses || !sortEnabled}
                    className={classes.filter}>
                    <MenuItem value={SortDirectionEnum.asc}>Oldest</MenuItem>
                    <MenuItem value={SortDirectionEnum.desc}>Most Recent</MenuItem>
                </Select>
            ) : null}
        </Box>
    );
}

export default React.memo(AllScheduledClassesFilters);
