import * as React from "react";

function SvgTrophy(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M15.33 2.44977C15.14 2.23977 14.85 1.98977 14.3 1.98977H12.62V1.12977C12.62 0.789768 12.34 0.509766 12 0.509766H4.06C3.72 0.509766 3.44001 0.789768 3.44001 1.12977V1.98977H1.76001C1.21001 1.98977 0.920007 2.23977 0.730007 2.44977C0.420007 2.79977 0.380003 3.42977 0.390003 3.77977C0.430003 5.42977 0.670002 6.55977 1.17 7.33977C1.79 8.30977 2.86 8.47977 3.64 8.60977C3.73 8.61977 3.81001 8.63977 3.90001 8.65977C4.55001 10.0298 5.86001 11.0198 7.41001 11.2298V13.8398H4.84C4.5 13.8398 4.22 14.1198 4.22 14.4598C4.22 14.7998 4.5 15.0798 4.84 15.0798H11.23C11.57 15.0798 11.85 14.7998 11.85 14.4598C11.85 14.1198 11.57 13.8398 11.23 13.8398H8.66001V11.2298C10.22 11.0198 11.52 10.0298 12.17 8.65977C12.25 8.63977 12.34 8.62977 12.43 8.60977C13.21 8.47977 14.29 8.30977 14.9 7.33977C15.39 6.56977 15.64 5.43977 15.68 3.77977C15.67 3.42977 15.64 2.79977 15.33 2.44977ZM2.2 6.66977C1.84 6.09977 1.65001 5.14977 1.62001 3.75977C1.61001 3.48977 1.66 3.28977 1.64 3.28977L1.17 2.86977L1.73001 3.24977H3.41001V6.70977C3.41001 6.91977 3.44 7.12977 3.47 7.32977C2.95 7.21977 2.46 7.07977 2.2 6.66977ZM11.36 6.69977C11.36 8.53977 9.86001 10.0498 8.01001 10.0498C6.16001 10.0498 4.67 8.54977 4.67 6.69977V1.75977H11.36V6.69977ZM13.83 6.66977C13.57 7.07977 13.09 7.21977 12.55 7.31977C12.58 7.10977 12.61 6.90977 12.61 6.69977V3.23977L14.35 3.22977C14.37 3.27977 14.42 3.48977 14.41 3.74977C14.38 5.14977 14.19 6.09977 13.83 6.66977Z" fill="black"/>
        </svg>
    );
}

export default SvgTrophy;