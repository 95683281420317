import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        width: "100%",
        height: theme.spacing(8),
    },
}));

export default useStyles;
