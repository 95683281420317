import { makeStyles, alpha } from "@material-ui/core/styles";

const BORDER_WIDTH_GHOST = 1;

export default makeStyles(theme => ({
    root: {
        "&.flat": {
            paddingBottom: theme.spacing(2.5),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(2.5),
            paddingTop: theme.spacing(1.5),
            "&.darkSea": {
                backgroundColor: theme.gradients.darkSea.fallback,
                backgroundImage: theme.gradients.darkSea.gradient,
            },
            "&.sea": {
                backgroundColor: theme.gradients.sea.fallback,
                backgroundImage: theme.gradients.sea.gradient,
            },
            "&.danger": {
                backgroundColor: theme.gradients.danger.fallback,
                backgroundImage: theme.gradients.danger.gradient,
            },
            "&.epic": {
                backgroundColor: theme.gradients.epic.fallback,
                backgroundImage: theme.gradients.epic.gradient,
            },
        },
        "&.ghost": {
            borderStyle: "solid",
            borderWidth: BORDER_WIDTH_GHOST,
            paddingBottom: theme.spacing(2.5) - BORDER_WIDTH_GHOST,
            paddingLeft: theme.spacing(3) - BORDER_WIDTH_GHOST,
            paddingRight: theme.spacing(2.5) - BORDER_WIDTH_GHOST,
            paddingTop: theme.spacing(1.5) - BORDER_WIDTH_GHOST,
            "&.darkSea": {
                color: theme.gradients.darkSea.fallback,
                borderColor: theme.gradients.darkSea.fallback,
            },
            "&.sea": {
                color: theme.gradients.sea.fallback,
                borderColor: theme.gradients.sea.fallback,
            },
            "&.danger": {
                color: theme.gradients.danger.fallback,
                borderColor: theme.gradients.danger.fallback,
            },
            "&.epic": {
                color: theme.gradients.epic.fallback,
                borderColor: theme.gradients.epic.fallback,
            },
        },
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    cellLabel: {
        "$root.flat &": {
            color: theme.colors.text.light,
        },
        "$root.ghost &": {
            color: theme.colors.text.darkGray,
        },
    },
    cellIcon: {
        "$root.flat &": {
            color: alpha(theme.colors.text.light, .5),
            opacity: .5,
        },
    },
    cellScore: {
        "$root.flat &": {
            color: theme.colors.text.light,
        },
    },
    label: {
        color: "currentColor",
    },
    score: {
        color: "currentColor",
        fontSize: theme.spacing(4),
        lineHeight: 1.25,
        "&:not(:first-child)": {
            marginLeft: theme.spacing(1),
        },
    },
    scoreArrow: {
        transition: "transform .1s linear",
    },
    scoreArrowDown: {
        transform: "rotate(180deg)",
    },
    signature: {
        fontSize: theme.spacing(1.5),
        "$root.flat &": {
            color: alpha(theme.colors.text.light, .6),
        },
        "$root.ghost &": {
            color: alpha(theme.colors.text.darkGray, .6),
        },
    },
    progress: {
        "$root.flat &": {
            color: "#fff",
        },
        "$root.ghost.darkSea &": {
            color: theme.gradients.darkSea.fallback,
        },
        "$root.ghost.sea &": {
            color: theme.gradients.sea.fallback,
        },
        "$root.ghost.danger &": {
            color: theme.gradients.danger.fallback,
        },
        "$root.ghost.epic &": {
            color: theme.gradients.epic.fallback,
        },
    },
}), {
    name: "SummaryCard",
});
