import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    picker: {
        padding: "4px",
        borderRadius: "6px",

        "&:hover": {
            background: theme.colors.structure.pageBackground,
        },

        "& .MuiInput-input": {
            width: "75px",
            fontSize: "15px",
            textAlign: "center",
            cursor: "pointer",
        },

        "& .MuiInput-underline:before": {
            content: "none",
        },
    },
}));
