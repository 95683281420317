import React, { useMemo, useState } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import { Box, Button, CircularProgress, FormControlLabel, FormGroup, FormHelperText, Grid, Typography } from "@material-ui/core";

import PaperCard from "components/PaperCard";
import FormDatePicker from "components/Form/FormDatePicker";
import { SelectItemProps } from "components/Form/FormSelect";
import { FormInput, FormSwitcher, FormMultilineInput, FormSelect } from "components/Form";
import { IClassBaseForm, EvaluationConfiguration } from "types/Certification";
import { getEvaluationById } from "services/certificationService";

import PreviewEvaluationFormModal from "./PreviewEvaluationFormModal";

interface ClassBaseFormProps {
    evaluationForms: EvaluationConfiguration[];
}

export default function ClassBaseForm({ evaluationForms }: ClassBaseFormProps) {
    const { errors, values } = useFormikContext<IClassBaseForm>();
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [currentEvaluationConfiguration, setCurrentEvaluationConfiguration] = useState<EvaluationConfiguration>();
    const [isFetchingEvaluation, setIsFetchingEvaluation] = useState<boolean>(false);

    const formSelectEvaluationOptions: SelectItemProps[] = useMemo(
        () => evaluationForms.filter(({ isActive }) => isActive).map(({ id, name }) => ({ id, title: name })),
        [evaluationForms]
    );

    const handlePreviewEvaluationFormModal = () => {
        setIsFetchingEvaluation(true);
        getEvaluationById(values.evaluationForm)
            .then(setCurrentEvaluationConfiguration)
            .finally(() => {
                setShowPreviewModal(true);
                setIsFetchingEvaluation(false);
            });
    };

    const handleCloseModal = () => {
        setShowPreviewModal(false);
    };

    return (
        <PaperCard>
            <FormGroup style={{ flexWrap: "nowrap" }}>
                <Box mb={3} ml="11px">
                    <Grid alignItems="center" component="span" container>
                        <Grid component="span" item xs={3} md={3}>
                            <FormControlLabel control={<FormSwitcher name="isActive" />} label={<Typography>Active</Typography>} />
                        </Grid>
                        <Grid component="span" item>
                            <FormControlLabel
                                control={<FormSwitcher name="allowSelfEvaluation" />}
                                label={<Typography>Self-Evaluation</Typography>}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <FormInput name="title" label="Class Name" />
                </Box>
                <Box mb={2}>
                    <FormMultilineInput placeholder="Form Description" name="description" />
                </Box>
                <Box mb={2}>
                    <Box display="flex">
                        <Box mb={1} mr={2} display="flex" alignItems="center">
                            <Typography noWrap>Release Date</Typography>
                        </Box>
                        <Box mb={1} display="flex" alignItems="center">
                            <Typography variant="caption">
                                Date the class will be available for completion on the website, but will not be displayed.
                            </Typography>
                        </Box>
                    </Box>
                    <FormDatePicker name="releaseDate" />
                </Box>
                <Box mb={2}>
                    <Box display="flex">
                        <Box mb={1} mr={2} display="flex" alignItems="center">
                            <Typography noWrap>Class Date</Typography>
                        </Box>
                        <Box mb={1} display="flex" alignItems="center">
                            <Typography variant="caption">Date that will be displayed on the site as the Class Date.</Typography>
                        </Box>
                    </Box>
                    <FormDatePicker name="classDate" />
                </Box>
                <Box mb={3}>
                    <Box mb={1} mr={2} alignItems="center">
                        <Typography noWrap>Select Evaluation Form</Typography>
                    </Box>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={10}>
                            <FormSelect name="evaluationForm" options={formSelectEvaluationOptions} error={!!errors.evaluationForm} />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="text"
                                color="primary"
                                size="large"
                                disabled={!values.evaluationForm}
                                style={{ whiteSpace: "nowrap" }}
                                onClick={handlePreviewEvaluationFormModal}>
                                {isFetchingEvaluation ? <CircularProgress size={24} /> : "View Form"}
                            </Button>
                        </Grid>
                    </Grid>
                    <ErrorMessage name="evaluationForm">{(message) => <FormHelperText error>{message}</FormHelperText>}</ErrorMessage>
                </Box>
                <Box mb={2}>
                    <FormInput name="reportingUrl" label="Reporting link" />
                </Box>
            </FormGroup>
            {showPreviewModal && currentEvaluationConfiguration && (
                <PreviewEvaluationFormModal
                    isOpen={showPreviewModal}
                    onClose={handleCloseModal}
                    evaluationConfiguration={currentEvaluationConfiguration}
                />
            )}
        </PaperCard>
    );
}
