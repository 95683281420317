import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    expired: {
        color: theme.palette.error.main,
    },
    hover: {
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
            cursor: "pointer",
            transition: "background-color .2s linear",
        },
    },
}));

export default useStyles;