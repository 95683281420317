import React, { useMemo } from "react";
import groupBy from "lodash/groupBy";
import meanBy from "lodash/meanBy";
import orderBy from "lodash/orderBy";
import takeRight from "lodash/takeRight";
import { Box, BoxProps } from "@material-ui/core";

import { monthList } from "helpers/dateHelpers";
import { YearMonthAverageScore } from "types";

import BarWrapper from "./BarWrapper";

interface BarGraphProps extends BoxProps {
    scores: YearMonthAverageScore[];
    alternatingLabels?: boolean
}

const BarGraph = ({ scores, alternatingLabels = true, ...props }: BarGraphProps) => {
    const processedScores = useMemo(() => {
        const groupedScores = groupBy(scores, (e) => `${e.month} ${e.year}`);
        const fullYearScores = {
            ...monthList,
            ...groupedScores,
        };
        const averageScores = Object.values(fullYearScores).map((scores) => ({
            month: scores[0].month,
            year: scores[0].year,
            averageScore: meanBy(scores as YearMonthAverageScore[], "averageScore"),
        }));
        const orderedScores = orderBy(averageScores, ["year", "month"]);
        return takeRight(orderedScores, 12);
    }, [scores]);

    return (
        <Box display="flex" justifyContent="space-between" {...props}>
            {processedScores.map((score, index) => (
                <BarWrapper
                    key={index}
                    monthScore={score}
                    isLabelShown={alternatingLabels ? !!(index % 2) : true}
                />
            ))}
        </Box>
    );
}

export default BarGraph;