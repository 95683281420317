import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    cards: {
        columnGap: theme.spacing(3),
        gridTemplateColumns: "repeat(1, 1fr)",
        rowGap: theme.spacing(3),
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
    },
    title: {
        fontWeight: 600,
    },
}))

export default useStyles;
