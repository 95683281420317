import * as React from "react";

function SvgReadLetter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#readLetter_svg__clip0_2325_16072)"
        stroke="#51A9BE"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.36 12.46H.96C.7 12.46.5 12.25.5 12V4.9L6.63.5l6.19 4.39V12c0 .26-.21.46-.46.46z" />
        <path d="M.64 4.89l5.99 2.89 6.03-2.89" />
      </g>
      <defs>
        <clipPath id="readLetter_svg__clip0_2325_16072">
          <path fill="#fff" d="M0 0h13.32v12.96H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgReadLetter;
