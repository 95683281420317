import { Box, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";

import useStyles from "./styles";

export interface ConditionalLinkProps {
    condition?: boolean;
    children: React.ReactElement;
    onClick?: () => void;
}

export default function ConditionalLink(props: ConditionalLinkProps) {
    const classes = useStyles();
    const { children, onClick, condition = true } = props;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    return condition && onClick ? (
        <Box
            onClick={() => {
                setIsFetching(true);
                onClick();
            }}
            className={classes.link}>
            {children}
            {isFetching && (
                <Box className={classes.spinner}>
                    <CircularProgress size={40} />
                </Box>
            )}
        </Box>
    ) : (
        <>{children}</>
    );
}
