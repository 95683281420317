import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AdminRoutings } from "types/constants";

import EvaluationPage from "./Evaluations/EvaluationPage";
import EvaluationManagement from "./Evaluations";

import CertificationClasses from "./Classes";
import ClassPage from "./Classes/ClassPage";

export default function AdminCertification() {
    return (
        <Switch>
            <Route path={[AdminRoutings.addCertificationEvaluation, `${AdminRoutings.certificationEvaluations}/:evaluationId`]}>
                <EvaluationPage />
            </Route>
            <Route path={[AdminRoutings.addCertificationClass, `${AdminRoutings.certificationClasses}/:classId`]}>
                <ClassPage />
            </Route>
            <Route path={AdminRoutings.certificationEvaluations}>
                <EvaluationManagement />
            </Route>
            <Route path={AdminRoutings.certificationClasses}>
                <CertificationClasses />
            </Route>
            <Route>
                <Redirect to={AdminRoutings.certificationEvaluations} />
            </Route>
        </Switch>
    );
}
