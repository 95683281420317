import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { CoachingSessionType } from "types";
import { getSessionTypeIcon } from "helpers/coachingHistoryHelper";

import useStyles from "./styles";

interface CoachingSessionNameProps {
    coachingSessionType: CoachingSessionType;
    encircledIcon?: boolean;
}

const CoachingSessionName = ({ coachingSessionType, encircledIcon = false }: CoachingSessionNameProps) => {
    const classes = useStyles();

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="text.secondary"
                className={clsx(encircledIcon && classes.encircledIcon)}
                mr={1.5}
            >
                {getSessionTypeIcon(coachingSessionType.icon)}
            </Box>
            <Typography variant="h3">
                {coachingSessionType.value}
            </Typography>
        </>
    );
};

export default React.memo(CoachingSessionName);