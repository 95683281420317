import { EmailConfiguration } from "types";

import API from "./apiService";

export const getEmailConfigurationForCompany = async (companyId: string): Promise<EmailConfiguration> => {
    const result = await API.get(`/CompanyEmailConfiguration/getForCompany/${companyId}`);
    return result.data;
};

export const saveEmailConfiguration = async (companyId: string, emailConfiguration: EmailConfiguration): Promise<EmailConfiguration> => {
    const result = await API.post(`/CompanyEmailConfiguration/saveForCompany/${companyId}`, emailConfiguration);
    return result.data;
};
