import * as React from "react";

function SvgGraph(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={19}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16.52 6.14934C16.18 6.14934 15.9 6.42934 15.9 6.76934V14.4093C15.9 14.8093 15.57 15.1393 15.17 15.1393H12.18V10.1693C12.18 9.82934 11.9 9.54934 11.56 9.54934C11.22 9.54934 10.94 9.82934 10.94 10.1693V15.1393H7.41V5.93934C7.41 5.59934 7.13 5.31934 6.79 5.31934C6.45 5.31934 6.17 5.59934 6.17 5.93934V15.1393H3.31C2.91 15.1393 2.58 14.8093 2.58 14.4093V11.3593C2.58 11.0193 2.3 10.7393 1.96 10.7393C1.62 10.7393 1.34 11.0193 1.34 11.3593V14.4093C1.34 15.4993 2.23 16.3893 3.32 16.3893H15.2C16.29 16.3893 17.18 15.4993 17.18 14.4093V6.77934C17.14 6.42934 16.86 6.14934 16.52 6.14934Z" fill="currentColor" />
            <path d="M16.52 1.20957C15.62 1.20957 14.88 1.94957 14.88 2.84957C14.88 2.99957 14.91 3.13957 14.94 3.27957L12.4 5.05957C12.15 4.90957 11.86 4.80957 11.55 4.80957C11.32 4.80957 11.11 4.85957 10.91 4.93957L8.37 2.53957C8.39 2.42957 8.41 2.31957 8.41 2.19957C8.41 1.28957 7.67 0.55957 6.77 0.55957C5.87 0.55957 5.13 1.29957 5.13 2.19957C5.13 2.39957 5.17 2.58957 5.24 2.75957L2.35 6.10957C2.22 6.06957 2.08 6.04957 1.93 6.04957C1.02 6.04957 0.290001 6.78957 0.290001 7.68957C0.290001 8.58957 1.03 9.32957 1.93 9.32957C2.83 9.32957 3.57 8.58957 3.57 7.68957C3.57 7.38957 3.48 7.10957 3.34 6.86957L6.09 3.67957C6.3 3.77957 6.53 3.82957 6.77 3.82957C7.1 3.82957 7.4 3.72957 7.65 3.56957L10.03 5.81957C9.95 6.00957 9.91 6.21957 9.91 6.43957C9.91 7.34957 10.65 8.07957 11.55 8.07957C12.45 8.07957 13.19 7.33957 13.19 6.43957C13.19 6.30957 13.17 6.17957 13.14 6.05957L15.7 4.25957C15.94 4.39957 16.22 4.47957 16.51 4.47957C17.42 4.47957 18.15 3.73957 18.15 2.83957C18.16 1.93957 17.42 1.20957 16.52 1.20957ZM1.93 8.08957C1.71 8.08957 1.54 7.91957 1.54 7.69957C1.54 7.47957 1.72 7.30957 1.93 7.30957C2.14 7.30957 2.32 7.48957 2.32 7.69957C2.32 7.90957 2.14 8.08957 1.93 8.08957ZM6.77 2.58957C6.55 2.58957 6.38 2.41957 6.38 2.19957C6.38 1.97957 6.55 1.80957 6.77 1.80957C6.99 1.80957 7.16 1.98957 7.16 2.19957C7.16 2.40957 6.98 2.58957 6.77 2.58957ZM11.55 6.84957C11.33 6.84957 11.16 6.66957 11.16 6.45957C11.16 6.24957 11.34 6.06957 11.55 6.06957C11.76 6.06957 11.94 6.23957 11.94 6.45957C11.94 6.67957 11.76 6.84957 11.55 6.84957ZM16.52 3.23957C16.41 3.23957 16.32 3.19957 16.24 3.12957C16.17 3.05957 16.13 2.95957 16.13 2.85957C16.13 2.63957 16.3 2.46957 16.52 2.46957C16.74 2.46957 16.91 2.63957 16.91 2.85957C16.91 3.07957 16.73 3.23957 16.52 3.23957Z" fill="currentColor" />
        </svg>
    );
}

export default SvgGraph;