import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import range from "lodash/range";
import groupBy from "lodash/groupBy";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const DATE_FORMAT = "MMM DD, YYYY";
const FULL_DATE_TIME_FORMAT = "MMMM DD, YYYY @ h:mm A";
const DATE_FORMAT_SHORT = "MMM DD";
const MONTH_FORMAT = "MMM";
const MONTH_FORMAT_FULL = "MMMM";
const DAY_FORMAT = "D";
const TIME_FORMAT = "h:mm A";
const DATETIME_FORMAT = "MMM DD, YYYY, h:mm A";
const PAST_YEAR_DATE_FORMAT = "M/D/YY";
const YEAR_FORMAT = "YYYY";

export const formatDate = (date: string | Date) => dayjs(date).format(DATE_FORMAT);

export const formatFullDate = (date: string | Date) => dayjs(date).format(FULL_DATE_TIME_FORMAT);

export const formatFullMonthDate = (date: string | Date) => dayjs(date).format(DATE_FORMAT_SHORT);

export const formatShortDate = (date: string | Date, withTime?: boolean, keepLocalTime?: boolean) =>
    dayjs(date)
        .utc(keepLocalTime)
        .format(
            `${DATE_FORMAT_SHORT}${new Date(date).getFullYear() === new Date().getFullYear() ? "" : ", YYYY"}${withTime ? ", h:mm A" : ""}`
        );

export const formatMonthNumber = (month: number) => dayjs(month.toString(), "M").format(MONTH_FORMAT).toUpperCase();

export const formatMonth = (month: string | number | Date) => dayjs(month.toString()).format(MONTH_FORMAT).toUpperCase();

export const formatMonthFull = (month: string | number | Date) => dayjs(month.toString()).format(MONTH_FORMAT_FULL).toUpperCase();

export const formatDay = (day: string | number | Date) => dayjs(day.toString()).format(DAY_FORMAT);

export const formatTime = (date: string | Date) => dayjs(date).format(TIME_FORMAT);

export const formatDateTime = (date: string | Date) => dayjs(date).format(DATETIME_FORMAT);

export const formatPastYearDate = (date: string | Date) => dayjs(date).format(PAST_YEAR_DATE_FORMAT);

export const formatYear = (date: string | Date) => dayjs(date).format(YEAR_FORMAT);

export const setTime = (date: string | Date, time: string | Date) => {
    const dayjsTime = dayjs(time);
    return dayjs(date).hour(dayjsTime.hour()).minute(dayjsTime.minute()).second(dayjsTime.second()).toISOString();
};

export const addHour = (date: string | Date) => dayjs(date).add(1, "hour").toDate();

/** Return `true` when the passed date is in the current year, instead `false`. */
export const checkPastYear = (date: string | Date) => dayjs(date).year() >= dayjs().year();

/** Return `true` when the passed date is in the current year and the date is NOT past due, instead `false`  */
export const checkDueDate = (date: string | Date, dueDate: string | Date = new Date()) =>
    !checkPastYear(date) || dayjs(date).unix() < dayjs(dueDate).unix();

const getMonthList = () => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const months = range(currentMonth - 11, currentMonth + 1).map((monthNumber) => ({
        month: monthNumber <= 0 ? (monthNumber + 12) % 13 : monthNumber,
        year: monthNumber <= 0 ? currentYear - 1 : currentYear,
    }));

    return groupBy(months, (e) => `${e.month} ${e.year}`);
};

export const monthList = getMonthList();

export const monthFullList: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const monthShortList: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const getRelativeTime = (date: string | Date) => dayjs(date).fromNow();

export const getTimeAgo = (end: string | Date, start: string | Date = new Date()): string => dayjs(end).from(start);

export const yearNow: string = dayjs().year().toString();
