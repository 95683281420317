import * as React from "react";

function SvgTargetBlank(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={14}
            height={14}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.5079 7.50588V12.022C12.5079 12.85 11.8304 13.5274 11.0025 13.5274H1.97022C1.14226 13.5274 0.464844 12.85 0.464844 12.022V2.98975C0.464844 2.16179 1.14226 1.48438 1.97022 1.48438H6.48635" stroke="currentColor" strokeOpacity="0.7" strokeWidth="1.25" strokeMiterlimit="10"/>
            <path d="M5.97656 8.03011L13.2099 0.691406" stroke="currentColor" strokeOpacity="0.7" strokeWidth="1.25" strokeMiterlimit="10"/>
            <path d="M8.84375 0.464844H13.4803V5.10893" stroke="currentColor" strokeOpacity="0.7" strokeWidth="1.25" strokeMiterlimit="10"/>
        </svg>
    );
}

export default SvgTargetBlank;
