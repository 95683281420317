import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import { Box, CircularProgress, Dialog, useMediaQuery, useTheme } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import {
    constructCoachingPlans,
    deleteCoachingSkillObservation,
    deleteFcrCoachingPlan,
    getFcrCoachingPlansForFcrResult,
    getFcrConfigurationForUser,
    saveCoachingPlans,
    saveCoachingSkillObservations,
    saveDevelopmentGoal,
    saveFcrItemResults,
    saveFcrStep,
    submitFcrResult,
} from "services/wizardServices";
import { getCoachingSession, saveCoachingSession } from "services/coachingSessionServices";
import {
    CoachingPlanType,
    CoachingSession,
    DevelopmentGoals,
    FcrCoachingSkill,
    FcrCoachingSkillHistory,
    FcrCoachingSkillObservation,
    FcrConfiguration,
    FcrItemConfiguration,
    FcrItemResult,
    FcrResult,
    HeaderProps,
} from "types";
import { AlertSeverity, ComponentType, LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW, Routings } from "types/constants";
import Header from "components/Header";
import ScheduleCoachingSession from "components/CoachingReportSelector/ScheduleCoachingSession";
import { ScrollContext, SnackbarContext } from "context";
import { cleanupRichText } from "helpers/stringHelpers";
import { sortFcrSkillsObservations } from "helpers/fcrHelper";
import { getDevGoalsFields } from "routes/CoachingReport/Wizard/config";

import WizardHeader from "./WizardHeader";
import WizardContent from "./WizardContent";
import WizardFooter from "./WizardFooter";

interface WizardComponentProps extends HeaderProps {
    fcrResult: FcrResult;
    fcrConfiguration: FcrConfiguration;
    fcrCoachingSkillsHistory?: FcrCoachingSkillHistory[];
    coachingSession: CoachingSession;
    fetchCoachingSession: () => void;
}

const AUTOSAVE_DELAY = 3000;

function Wizard({
    fcrResult,
    coachingSession,
    fetchCoachingSession,
    isBarOpen,
    navBarOpen,
    fcrConfiguration,
    fcrCoachingSkillsHistory,
}: WizardComponentProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const user = coachingSession.assignee;
    const history = useHistory();
    const { scrollToTop } = useContext(ScrollContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const [step, setStep] = useState(0);
    const [devGoals, setDevGoals] = useState<DevelopmentGoals>(fcrResult.fcrDevelopmentGoal || { id: uuidv4(), fcrResultId: fcrResult.id });
    const [fcrCoachingSkills, setFcrCoachingSkills] = useState<FcrCoachingSkill[]>();
    const [fcrCoachingSkillObservations, setFcrCoachingSkillObservations] = useState<FcrCoachingSkillObservation[]>(
        sortFcrSkillsObservations(fcrCoachingSkills ?? [], fcrResult.fcrCoachingSkillResults || [])
    );
    const [isCoachingSkillObservationsSaving, setIsCoachingSkillObservationsSaving] = useState(false);
    const [isCoachingSkillObservationsDeleting, setIsCoachingSkillObservationsDeleting] = useState(false);
    const [coachingPlans, setCoachingPlans] = useState<CoachingPlanType[]>(fcrResult.fcrCoachingPlans || []);
    const [fcrItemResults, setFcrItemResults] = useState<FcrItemResult[]>(fcrResult.fcrItemResults || []);
    const [showError, setShowError] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [nextStepDisabled, setNextStepDisabled] = useState(false);
    const [fcrItemConfigurations, setFcrItemConfigurations] = useState<FcrItemConfiguration[]>([]);
    const [completedSteps, setCompletedSteps] = useState<{ [k: number]: boolean }>({});
    const isCoachingSkillObservationsFetching = isCoachingSkillObservationsSaving || isCoachingSkillObservationsDeleting;
    const productSalesData = coachingSession.productSalesData;

    const [isCoachingReportSelectorOpen, toggleCoachingReportSelector] = useReducer((state) => !state, false);

    const isTeamMemberView = localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW);
    const finishLaterUrl = useMemo(
        () => (isTeamMemberView ? Routings.reports : `${Routings.teamProfile}/${user.id}${Routings.reports}`),
        [user.id, isTeamMemberView]
    );

    useEffect(() => {
        if (!isCoachingReportSelectorOpen && fcrResult) fetchCoachingSession();
    }, [isCoachingReportSelectorOpen, fcrResult, fetchCoachingSession]);

    useEffect(() => {
        const fetchUserConfiguration = async () => {
            const userConfiguration = await getFcrConfigurationForUser(fcrConfiguration.id, user.id);
            setFcrCoachingSkills(userConfiguration.fcrCoachingSkills);
            setFcrCoachingSkillObservations((state) => {
                const coachingSkillObservations =
                    state?.map((observation) => {
                        const fcrCoachingSkill = userConfiguration.fcrCoachingSkills.find((e) => e.id === observation.fcrCoachingSkillId);

                        return fcrCoachingSkill
                            ? {
                                ...observation,
                                fcrCoachingSkill,
                            }
                            : observation;
                    }) || [];
                return sortFcrSkillsObservations(userConfiguration.fcrCoachingSkills ?? [], coachingSkillObservations);
            });
            setFcrItemConfigurations(userConfiguration.fcrItemConfigurations.sort((a, b) => a.order - b.order));
        };

        fetchUserConfiguration();
    }, [fcrConfiguration.id, user.id]);

    const handleComplete = useCallback(
        (isComplete: boolean, completedStep: number) => {
            const newCompleted = completedSteps || {};
            // eslint-disable-next-line functional/immutable-data
            newCompleted[completedStep || step] = isComplete;
            setCompletedSteps(newCompleted);
        },
        [completedSteps, step]
    );

    const handleCompleteDevelopmentGoals = useCallback(
        (value: DevelopmentGoals) => {
            const isCompletedDevelopmentGoals = !getDevGoalsFields(fcrConfiguration).find(
                ({ fieldName }) => cleanupRichText(value[fieldName as keyof DevelopmentGoals])?.length < 10
            );
            handleComplete(isCompletedDevelopmentGoals, 0);
        },
        [handleComplete, fcrConfiguration]
    );

    const saveDevelopmentGoalsDebounced = useMemo(
        () =>
            debounce((value: DevelopmentGoals) => {
                saveDevelopmentGoal(value);
            }, AUTOSAVE_DELAY),
        []
    );

    const handleObservations = useCallback(
        (value: Array<FcrCoachingSkillObservation>) => {
            const isCompletedObservations = !!fcrCoachingSkills?.find((fcrCoachingSkill) =>
                value.find(
                    (i) =>
                        i.fcrCoachingSkillId === fcrCoachingSkill.id &&
                        i.rating >
                            (fcrConfiguration?.wholeNumberRating ? Math.ceil(fcrCoachingSkill.minRating) : fcrCoachingSkill.minRating) &&
                        cleanupRichText(i.observation).length > 9
                )
            );

            handleComplete(isCompletedObservations, 1);
        },
        [fcrCoachingSkills, fcrConfiguration?.wholeNumberRating, handleComplete]
    );

    const saveObservationsDebounced = useMemo(
        () =>
            debounce((value: Array<FcrCoachingSkillObservation>) => {
                fcrResult && fcrResult.id ? saveCoachingSkillObservations(fcrResult.id, value) : () => null;
            }, AUTOSAVE_DELAY),
        [fcrResult]
    );

    const handleCoachingPlans = useCallback(
        (value: Array<CoachingPlanType>) => {
            const isCompletedCoachingPlans = !!value.find(({ coachingPlanData }) => cleanupRichText(coachingPlanData).length > 9);
            handleComplete(isCompletedCoachingPlans, 2);
        },
        [handleComplete]
    );

    const saveCoachingPlansDebounced = useMemo(
        () =>
            debounce((value: Array<CoachingPlanType>) => {
                saveCoachingPlans(value);
            }, AUTOSAVE_DELAY),
        []
    );

    const updateCoachingSession = useCallback(async (coachingSessionId: string) => {
        const session = await getCoachingSession(coachingSessionId);
        const updatedCoachingSession = {
            ...session,
            fcrResult: {
                ...session.fcrResult!,
                submittedByCoacheeDate: new Date().toISOString(),
            },
        };

        await saveCoachingSession(updatedCoachingSession);
    }, []);

    const saveFcrItemResultsDebounced = useMemo(
        () =>
            debounce(
                (value: Array<FcrItemResult>) => (fcrResult && fcrResult.id ? saveFcrItemResults(fcrResult.id, value) : () => null),
                AUTOSAVE_DELAY
            ),
        [fcrResult]
    );

    useEffect(() => {
        !completedSteps[0] && handleCompleteDevelopmentGoals(devGoals);
        !completedSteps[1] && handleObservations(fcrCoachingSkillObservations);
        !completedSteps[2] && handleCoachingPlans(coachingPlans);
    }, [
        coachingPlans,
        completedSteps,
        devGoals,
        fcrCoachingSkillObservations,
        handleCoachingPlans,
        handleCompleteDevelopmentGoals,
        handleObservations,
    ]);

    useEffect(
        () => () => {
            saveDevelopmentGoalsDebounced.flush();
            saveObservationsDebounced.flush();
            saveCoachingPlansDebounced.flush();
            saveFcrItemResultsDebounced.flush();
        },
        [saveCoachingPlansDebounced, saveDevelopmentGoalsDebounced, saveObservationsDebounced, saveFcrItemResultsDebounced]
    );

    const changeData = useCallback(
        (value: DevelopmentGoals | FcrCoachingSkillObservation | Array<CoachingPlanType> | Array<FcrItemResult>) => {
            switch (step) {
                case 0:
                    handleCompleteDevelopmentGoals(value as DevelopmentGoals);
                    setDevGoals(value as DevelopmentGoals);
                    saveDevelopmentGoalsDebounced(value as DevelopmentGoals);
                    break;
                case 1:
                    setFcrCoachingSkillObservations((fcrCoachingSkills) => {
                        const newObservations = fcrCoachingSkills
                            ? fcrCoachingSkills.map((fcrCoachingSkill) => {
                                if (fcrCoachingSkill.id === (value as FcrCoachingSkillObservation).id) {
                                    return value as FcrCoachingSkillObservation;
                                }

                                return fcrCoachingSkill;
                            })
                            : [];

                        handleObservations(newObservations);
                        saveObservationsDebounced(newObservations);

                        return newObservations;
                    });
                    break;
                case 2:
                    handleCoachingPlans(value as Array<CoachingPlanType>);
                    setCoachingPlans(value as Array<CoachingPlanType>);
                    saveCoachingPlansDebounced(value as Array<CoachingPlanType>);
                    break;
                case 3:
                    if (showError) setShowError(false);
                    setFcrItemResults(value as Array<FcrItemResult>);
                    break;
            }
        },
        [
            step,
            saveDevelopmentGoalsDebounced,
            saveCoachingPlansDebounced,
            showError,
            saveObservationsDebounced,
            handleObservations,
            handleCompleteDevelopmentGoals,
            handleCoachingPlans,
        ]
    );

    const validateData = useCallback(
        () =>
            fcrItemConfigurations.every((configuration) =>
                configuration.isRequired && configuration.fcrItemTypeId !== ComponentType.staticText
                    ? fcrItemResults.find((fcrItemResult) => fcrItemResult.fcrItemConfigurationId === configuration.id)?.fcrItemValueId
                    : true
            ),
        [fcrItemConfigurations, fcrItemResults]
    );

    const addFcrCoachingSkillObservation = useCallback(
        (value: FcrCoachingSkillObservation) => {
            const newObservations = [...fcrCoachingSkillObservations, value];
            const sortedNewObservations = sortFcrSkillsObservations(fcrCoachingSkills ?? [], newObservations);

            setIsCoachingSkillObservationsSaving(true);
            setFcrCoachingSkillObservations(sortedNewObservations);
            saveObservationsDebounced(sortedNewObservations);
            setIsCoachingSkillObservationsSaving(false);
        },
        [fcrCoachingSkillObservations, fcrCoachingSkills, saveObservationsDebounced]
    );

    const removeFcrCoachingSkillObservation = useCallback(
        async (id: string) => {
            const newFcrCoachingSkillObservations = fcrCoachingSkillObservations.filter((observation) => observation.id !== id);

            // Todo: Cascade deleting should be on the backend side
            const cpId = coachingPlans.find((cp) => cp.fcrCoachingSkillResultId === id)?.id;
            if (cpId && fcrResult && fcrResult.id) {
                await deleteFcrCoachingPlan(cpId);
                const cp = await getFcrCoachingPlansForFcrResult(fcrResult.id, newFcrCoachingSkillObservations);
                setCoachingPlans(cp);
            }

            setIsCoachingSkillObservationsDeleting(true);
            await deleteCoachingSkillObservation(id);
            setIsCoachingSkillObservationsDeleting(false);

            if (!newFcrCoachingSkillObservations.length) handleComplete(false, 2);

            handleObservations(newFcrCoachingSkillObservations);
            setFcrCoachingSkillObservations(newFcrCoachingSkillObservations);
        },
        [fcrCoachingSkillObservations, coachingPlans, fcrResult, handleComplete, handleObservations]
    );

    const moveToStep = useCallback(
        (newStep: number) => {
            // Todo: refactor this - remove showError dependency
            if (showError) setShowError(false);

            setStep(newStep);
            saveFcrStep(newStep);
        },
        [showError]
    );

    const moveToNextStep = useCallback(async () => {
        switch (step) {
            case 0:
                saveDevelopmentGoalsDebounced.flush();

                if (!isTeamMemberView) {
                    moveToStep(step + 1);
                    return;
                }

                if (!fcrResult?.submittedByCoacheeDate && fcrResult?.createdById === fcrResult?.assigneeId) {
                    setNextStepDisabled(true);
                    await updateCoachingSession(coachingSession.id).finally(() => setNextStepDisabled(false));
                    history.push(Routings.reports);
                    openSnackbar("Coaching Report has been submitted.");
                } else {
                    history.goBack();
                }

                break;
            case 1:
                saveObservationsDebounced.flush();
                setCoachingPlans(constructCoachingPlans(coachingPlans, fcrCoachingSkillObservations));
                moveToStep(step + 1);
                break;
            case 2:
                saveCoachingPlansDebounced.flush();
                moveToStep(step + 1);
                break;
            case 3:
                const fieldsAreFilled = validateData();
                setShowError(!fieldsAreFilled);

                if (!fieldsAreFilled) return;

                setIsSubmitLoading(true);
                await saveFcrItemResults(fcrResult.id, fcrItemResults);

                if (fcrResult && fcrResult.id) {
                    await submitFcrResult(fcrResult.id).catch(() => {
                        setIsSubmitLoading(false);
                        openSnackbar("Unexpected error occurred.", AlertSeverity.error);
                    });
                } else {
                    scrollToTop(false);
                }

                setIsSubmitLoading(false);
                moveToStep(step + 1);
                break;
        }
    }, [
        step,
        saveDevelopmentGoalsDebounced,
        isTeamMemberView,
        fcrResult,
        saveObservationsDebounced,
        coachingPlans,
        fcrCoachingSkillObservations,
        moveToStep,
        saveCoachingPlansDebounced,
        validateData,
        fcrItemResults,
        updateCoachingSession,
        coachingSession.id,
        history,
        openSnackbar,
        scrollToTop,
    ]);

    const moveToPrevStep = useCallback(() => {
        moveToStep(step > 0 ? step - 1 : step);
    }, [moveToStep, step]);

    const handleSessionSaved = useCallback(
        (coachingSession: CoachingSession) => {
            history.push(`${Routings.fcr}/${coachingSession.id}`);
        },
        [history]
    );

    const handleSessionDelete = useCallback(() => {
        history.push(finishLaterUrl);
    }, [history, finishLaterUrl]);

    const isDataLoaded = useMemo(
        () => devGoals && fcrCoachingSkills && coachingPlans && fcrItemConfigurations && fcrCoachingSkillsHistory,
        [coachingPlans, devGoals, fcrCoachingSkills, fcrItemConfigurations, fcrCoachingSkillsHistory]
    );

    return (
        <>
            {step < 4 ? (
                <WizardHeader
                    step={step}
                    moveToStep={moveToStep}
                    user={user}
                    finishLaterUrl={finishLaterUrl}
                    isBarOpen={isBarOpen}
                    navBarOpen={navBarOpen}
                    toggleCoachingReportSelector={toggleCoachingReportSelector}
                    coachingSession={coachingSession}
                    completedSteps={completedSteps}
                />
            ) : (
                <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            )}
            {isDataLoaded ? (
                <>
                    <WizardContent
                        step={step}
                        user={user}
                        developmentGoals={devGoals!}
                        fcrCoachingSkills={fcrCoachingSkills!}
                        fcrCoachingSkillObservations={fcrCoachingSkillObservations}
                        isCoachingSkillObservationsFetching={isCoachingSkillObservationsFetching}
                        coachingPlans={coachingPlans}
                        fcrItemConfigurations={fcrItemConfigurations}
                        fcrItemResults={fcrItemResults}
                        fcrCoachingSkillsHistory={fcrCoachingSkillsHistory!}
                        onChange={changeData}
                        moveToStep={moveToStep}
                        addFcrCoachingSkillObservation={addFcrCoachingSkillObservation}
                        removeFcrCoachingSkillObservation={removeFcrCoachingSkillObservation}
                        fcrResult={fcrResult}
                        isSubmitLoading={isSubmitLoading}
                        showError={showError}
                        coachingSession={coachingSession}
                        fcrConfiguration={fcrConfiguration}
                        productSalesData={productSalesData}
                    />
                    {step < 4 && (
                        <WizardFooter
                            step={step}
                            prevStep={moveToPrevStep}
                            prevStepDisabled={isCoachingSkillObservationsFetching}
                            nextStep={moveToNextStep}
                            nextStepDisabled={nextStepDisabled || isCoachingSkillObservationsFetching}
                            coachingSession={coachingSession}
                            isCoachingSkillObservationsSaving={isCoachingSkillObservationsSaving}
                            isCoachingSkillObservationsDeleting={isCoachingSkillObservationsDeleting}
                        />
                    )}
                </>
            ) : (
                <Box display="flex" justifyContent="space-around" pt={10}>
                    <CircularProgress />
                </Box>
            )}
            {isCoachingReportSelectorOpen && (
                <Dialog open={isCoachingReportSelectorOpen} onClose={toggleCoachingReportSelector} fullScreen={fullScreen} fullWidth>
                    <ScheduleCoachingSession
                        user={user}
                        coachingSession={coachingSession}
                        onClose={toggleCoachingReportSelector}
                        onSuccess={handleSessionSaved}
                        onDelete={handleSessionDelete}
                        fcrConfiguration={fcrConfiguration}
                    />
                </Dialog>
            )}
        </>
    );
}

export default Wizard;
