import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    select: {
        backgroundColor: theme.palette.common.white,
        "& .MuiSelect-root": {
            display: "flex",
            alignItems: "center",
            padding: "15px 14px",
            backgroundColor: theme.palette.common.white,
            minHeight: theme.spacing(3),

            "&:focus": {
                backgroundColor: theme.palette.common.white,
            },
        },
    },
}));
