import React from "react";
import clsx from "clsx";
import { Box, Grid, IconButton, Typography, BoxProps } from "@material-ui/core";
import { EvaluationConfiguration } from "types/Certification";
import CircleIcon from "components/CircleIcon";
import RichText from "components/RichText";
import { DeleteIcon, EditIcon } from "svgComponents";
import { formatShortDate } from "helpers/dateHelpers";

import useStyles from "./styles";

interface EvaluationListItemProps {
    evaluation: EvaluationConfiguration;
    icon?: React.ReactNode;
    loading?: boolean;
    bodyProps?: BoxProps;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onEdit?: (e: React.MouseEvent<HTMLElement>) => void;
    onDelete?: (e: React.MouseEvent<HTMLElement>) => void;
}

const EvaluationListItem = ({ evaluation, icon, loading, bodyProps, onClick, onEdit, onDelete }: EvaluationListItemProps) => {
    const classes = useStyles();

    return (
        <Box
            onClick={!loading ? onClick : undefined}
            className={clsx(classes.body, { [classes.bodyInactive]: !evaluation.isActive })}
            px={4}
            py={2}
            {...(bodyProps ?? {})}>
            <Grid alignItems="center" container spacing={3} wrap="nowrap">
                {icon && (
                    <Grid item>
                        <CircleIcon className={classes.icon}>{icon}</CircleIcon>
                    </Grid>
                )}
                <Grid className={classes.content} item>
                    <Typography variant="body1">
                        <Box component="span" fontWeight={500}>
                            {evaluation.name}
                        </Box>
                    </Typography>
                    {evaluation.description && (
                        <Typography component="div" variant="body2">
                            <Box color="#7c8ea3" component="div" mt={0.5}>
                                <RichText text={evaluation.description} />
                            </Box>
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    <Typography>{formatShortDate(evaluation.createdDate)}</Typography>
                    <Typography variant="caption">Created</Typography>
                </Grid>
                <Grid className={classes.actions} item>
                    <Grid container spacing={1} wrap="nowrap">
                        {onEdit && (
                            <Grid item style={{ marginRight: evaluation.isInUse ? "48px" : "0" }}>
                                <IconButton aria-label="edit" disabled={loading} onClick={onEdit}>
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        )}
                        {onDelete && (
                            <Grid item>
                                <IconButton aria-label="delete" disabled={loading} onClick={onDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EvaluationListItem;
