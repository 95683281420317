import React from "react";
import clsx from "clsx";
import { Box, Tooltip, Typography, Theme, CircularProgress } from "@material-ui/core";

import useStyles from "./styles";
import ArrowIcon from "./ArrowIcon";

export interface SummaryCardProps {
    label?: string,
    score: string | number,
    signature: string,
    subLabel?: string,
    isIncreased?: boolean,
    className?: string,
    tooltip?: string,
    type?: "flat" | "ghost",
    theme?: keyof Theme["gradients"],
    isFetching?: boolean;
    renderIcon?: () => React.ReactNode
}

const SummaryCard = ({
    label,
    signature,
    score,
    subLabel,
    className = "",
    tooltip = "",
    isIncreased = false,
    type = "flat",
    theme = "darkSea",
    isFetching = false,
    renderIcon,
}: SummaryCardProps) => {
    const classes = useStyles();

    return (
        <Tooltip title={tooltip} arrow>
            <Box
                className={clsx(classes.root, className, type, theme)}
                minHeight={120}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                boxSizing="border-box"
                borderRadius={8}
            >
                <Box className={classes.row}>
                    <Box className={classes.cellLabel} pt={1}>
                        <Typography className={classes.label} variant="body1">{label}</Typography>
                        {subLabel && <Typography className={classes.label} variant="body2">{subLabel}</Typography>}
                    </Box>
                    <Box
                        className={classes.cellScore}
                        ml={2}
                        display="flex"
                        alignItems="center"
                    >
                        {isFetching ? (
                            <Box mt={1}>
                                <CircularProgress className={classes.progress} size={20} />
                            </Box>
                        ) : (
                            <>
                                <ArrowIcon className={clsx(classes.scoreArrow, !isIncreased && classes.scoreArrowDown)} />
                                <Typography className={classes.score}>{score}</Typography>
                            </>
                        )}
                    </Box>
                </Box>
                <Box className={classes.row} alignItems="flex-end" mt={2}>
                    <Box className={classes.cellIcon} height={28}>
                        {renderIcon?.()}
                    </Box>
                    <Box ml={2}>
                        <Typography className={classes.signature}>{signature}</Typography>
                    </Box>
                </Box>
            </Box>
        </Tooltip>
    )
}

export default SummaryCard;