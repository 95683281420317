import React, { useState, useMemo, useCallback, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { CoachingHubContext, ConfigurationContext } from "context";
import { CoachingSession, UserProfile } from "types";
import { FcrStateEnum, Routings } from "types/constants";
import PaperSection from "components/PaperSection";
import NoDataMessage from "components/NoDataMessage";
import CoachingReportItem from "components/CoachingReportItem";
import useScrollToItem from "hooks/useScrollToItem";

import CoachingSessionFilters from "../CoachingSessionFilters/CoachingSessionFiltersWithSubmitButton";
import useStyles from "./styles";

const STEP_SIZE = 50;

const STATUS_FILTER_OPTIONS = [
    { value: FcrStateEnum.new, label: "New" },
    { value: FcrStateEnum.draft, label: "Draft" },
    { value: FcrStateEnum.completed, label: "Completed" },
    { value: FcrStateEnum.acknowledged, label: "Acknowledged" },
    { value: FcrStateEnum.acknowledgedWithComment, label: "Acknowledge with Comment" },
];

interface CoachingReportsProps {
    coachesBelow: UserProfile[];
    membersBelow: UserProfile[];
}

const defaultFilters = {
    userId: null,
    status: null,
    orderDescending: true,
    returnStarted: true,
    getOnlyUpcoming: false,
    getOnlyIncomplete: false,
    state: null,
    excludeAssigneesMarkedFinishLater: true,
}

export default function CoachingReports({ coachesBelow, membersBelow }: CoachingReportsProps) {
    const classes = useStyles();
    const history = useHistory();
    const { currentUser, currentTeam, isConfigurationFetching, companyLevels, hasCoachingReportAccess } = useContext(ConfigurationContext);
    const { coachingSessions, coachingHubFilters, isFetchingByFilters, fetchCoachingSessions, totalFilteredAmount } = useContext(CoachingHubContext);
    const [page, setPage] = useState<number>(1);
    const [shownCount, setShownCount] = useState(STEP_SIZE);
    const [isShowMore, setIsShowMore] = useState(true);
    const itemsRef = useRef<HTMLDivElement>(null);
    const isLoading = isFetchingByFilters || !coachingSessions || !currentUser;

    useScrollToItem(coachingSessions || [], "sessionId", itemsRef, shownCount, setShownCount);

    const shownSessions = useMemo(() => coachingSessions ? coachingSessions.slice(0, shownCount) : [], [coachingSessions, shownCount]);

    const onShowMore = useCallback(() => {
        setPage(page + 1)
        setShownCount(shownCount + STEP_SIZE)
        fetchCoachingSessions(page + 1, coachingHubFilters || defaultFilters)
    }, [coachingHubFilters, fetchCoachingSessions, page, shownCount]);

    useEffect(() => {
        if (!coachingHubFilters) {
            fetchCoachingSessions(page, coachingHubFilters || defaultFilters)
        }
    }, [coachingHubFilters, fetchCoachingSessions, page]);

    useEffect(() => {
        if (shownSessions.length !== totalFilteredAmount) {
            setIsShowMore(true)
        } else {
            setIsShowMore(false)
        }
    }, [coachingSessions, fetchCoachingSessions, page, shownSessions.length, totalFilteredAmount]);

    useEffect(() => {
        if (isFetchingByFilters) setShownCount(STEP_SIZE)
    }, [isFetchingByFilters]);

    const handleClick = useCallback((coachingSession: CoachingSession) => {
        history.push(`${Routings.fcr}/${coachingSession.id}`, { backPath: history.location.pathname, backText: "Back to Hub" });
    }, [history]);

    return (
        <PaperSection
            px={6}
            width={1}
            title={(
                <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" width={1}>
                    <Box fontWeight={600} whiteSpace="nowrap">
                        <Typography variant="h2">All Coaching Reports</Typography>
                    </Box>
                    {currentUser && !isConfigurationFetching && (
                        <CoachingSessionFilters<FcrStateEnum>
                            currentUser={currentUser}
                            currentTeam={currentTeam}
                            companyLevels={companyLevels}
                            membersBelow={membersBelow}
                            coachesBelow={coachesBelow}
                            loading={isLoading}
                            statusOptions={STATUS_FILTER_OPTIONS}
                            setPage={setPage}
                        />
                    )}
                </Box>
            )}
        >
            {!isLoading && (<InfiniteScroll
                scrollableTarget="app"
                dataLength={shownSessions.length}
                next={onShowMore}
                hasMore={isShowMore && totalFilteredAmount > shownCount}
                loader={isShowMore ? <Box p={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box> : null}
                hasChildren
            >
                <Box px={5} py={2}>
                    <Typography variant="caption">{`${shownSessions.length} ${isShowMore ? `from ${totalFilteredAmount} ` : ""}items`}</Typography>
                </Box>
                <Divider />
                <div {...{ ref: itemsRef }}>
                    {shownSessions.map(session => (
                        <div key={session.id} className={classes.itemWrap}>
                            <CoachingReportItem
                                session={session}
                                className={classes.item}
                                showAssigneeCell
                                hasAccess={hasCoachingReportAccess(session)}
                                onClick={handleClick}
                            />
                        </div>
                    ))}
                </div>
            </InfiniteScroll>
            )}    
            {!coachingSessions?.length && !isLoading && (
                <NoDataMessage pb={5} pt={7} message="There's nothing to show here yet" />
            )}
            {isLoading && (
                <Box p={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            )}
        </PaperSection>
    );
}
