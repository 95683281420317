import { makeStyles } from "@material-ui/core"; 

const useStyles = makeStyles(theme => ({
    link: {
        alignItems: "center",
        color: theme.palette.primary.main,
        cursor: "pointer",
        display: "inline-flex",
        textDecoration: "none",
    },
    disabled: {
        color: theme.palette.text.primary,
        cursor: "not-allowed",
        pointerEvents: "none",
    },
    name: {
        textDecoration: "underline",
    },
}));

export default useStyles;
