import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    skillRatingItem: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        marginTop: "12px",
        minHeight: "100px",

        "& li p": {
            marginTop: 0,
        },

        transition: theme.transitions.create("border-color", {
            duration: 300,
            delay: 100,
            easing: "linear",
        }),

        "&:hover": {
            borderColor: theme.colors.identity.teal,
        },
    },
    btn: {
        minWidth: "32px",
        padding: "6px",

        "& svg": {
            margin: "auto",

            "& path": {
                fill: theme.palette.text.secondary,
            },
        },

        "&:hover": {
            "& svg path": {
                fill: theme.colors.identity.teal,
            },
        },

        [theme.breakpoints.up("md")]: {
            opacity: 0,

            transition: theme.transitions.create("opacity", {
                duration: 300,
                delay: 100,
                easing: "linear",
            }),

            "$skillRatingItem:hover &": {
                opacity: 1,
            },
        },
    },
}));

export default useStyles;
