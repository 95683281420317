import * as React from "react";

function SvgPdf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={20}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.87 5.71c0-.27-.17-.49-.41-.58L11.6.19a.636.636 0 00-.45-.19H1.62C.73 0 0 .73 0 1.63v16.53c0 .9.73 1.62 1.62 1.62h13.62c.9 0 1.62-.73 1.62-1.62V5.8c0-.02-.01-.03-.01-.05.01-.01.02-.02.02-.04zm-5.09-3.55l2.88 2.93h-2.51c-.2 0-.38-.17-.38-.38V2.16h.01zm3.46 16.37H1.62c-.2 0-.38-.17-.38-.38V1.63c0-.2.17-.38.38-.38h8.91v3.46c0 .9.73 1.62 1.62 1.62h3.46v11.82c.01.21-.16.38-.37.38z"
        fill="#7C8EA2"
      />
      <path
        d="M11.24 10.96c-.32 0-.67.02-1.04.06a6.11 6.11 0 01-.75-.5c-.52-.43-.99-1.18-1.3-2.11.11-.61.12-1.15.12-1.7v-.27c0-.03 0-.04-.01-.07.03-.02.04-.05.05-.07.05-.28.03-.48-.08-.6a.38.38 0 00-.31-.14h-.2c-.18 0-.31.13-.37.36-.16.58-.07 1.58.21 2.54-.21.75-.52 1.53-.92 2.33-.36.72-.67 1.3-.95 1.75 0-.01-.01-.03-.02-.04-.04-.07-.13-.1-.2-.05-1.1.58-1.58 1.26-1.67 1.67-.03.18.01.28.05.32.02.02.03.04.05.04l.21.11c.08.04.16.06.25.06.53 0 1.23-.76 2.15-2.33.95-.33 2.25-.6 3.48-.74.75.39 1.61.65 2.18.65.47 0 .6-.18.63-.34v-.01c.01-.01.02-.03.03-.04.1-.21.04-.37-.02-.46-.22-.35-.98-.42-1.57-.42zm-6.9 3.21c.13-.3.55-.89 1.22-1.4-.57.9-.95 1.28-1.22 1.4zM7.88 6c0-.01.01-.02.01-.04.05.07.1.17.11.33.01.02.01.04.02.04-.02.03-.03.05-.04.07-.02.11-.04.24-.06.39-.02.11-.04.24-.06.39-.07-.5-.07-.93.02-1.18zm-1.11 5.87c.13-.24.27-.49.4-.74.32-.63.57-1.21.74-1.75.3.65.69 1.18 1.13 1.56.08.07.17.13.26.2-.87.17-1.74.42-2.53.73zm5.6-.1c-.27 0-.67-.09-1.1-.25.97 0 1.2.17 1.24.21.01.01.02.02.02.03-.04.01-.08.01-.16.01z"
        fill="#7C8EA2"
      />
    </svg>
  );
}

export default SvgPdf;
