import React from "react";
import clsx from "clsx";
import { Box, Grid, IconButton, Typography, Button, BoxProps } from "@material-ui/core";
import CircleIcon from "components/CircleIcon";
import RichText from "components/RichText";
import { DeleteIcon, EditIcon, HideIcon, RearrageIcon } from "svgComponents";

import useStyles from "./styles";

interface AdminListItemProps {
    icon?: React.ReactNode,
    loading?: boolean,
    title: string,
    description?: string,
    isInactive?: boolean;
    isDragging?: boolean,
    dragHandleProps?: unknown;
    bodyProps?: BoxProps;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void,
    onDelete?: (e: React.MouseEvent<HTMLElement>) => void,
    onEdit?: (e: React.MouseEvent<HTMLElement>) => void,
}

const AdminListItem = ({
    description,
    icon,
    loading,
    title,
    isInactive,
    isDragging,
    dragHandleProps,
    bodyProps,
    onClick,
    onDelete,
    onEdit,
}: AdminListItemProps) => {
    const classes = useStyles();

    return (
        <Box
            onClick={!loading ? onClick : undefined}
            className={clsx(classes.body, { [classes.bodyInactive]: isInactive })}
            px={4} py={2} {...(bodyProps ?? {})}
        >
            {dragHandleProps && (
                <Button
                    className={clsx(classes.dragHandleBtn, { [classes.actions]: !isDragging })}
                    {...dragHandleProps}
                >
                    <RearrageIcon />
                </Button>
            )}
            <Grid alignItems="center" container spacing={3} wrap="nowrap">
                {icon && <Grid item>
                    <CircleIcon className={classes.icon}>
                        {icon}
                    </CircleIcon>
                </Grid>}
                <Grid className={classes.content} item>
                    <Typography variant="body1">
                        <Box component="span" fontWeight={500}>{title}</Box>
                    </Typography>
                    {description && (
                        <Typography component="div" variant="body2">
                            <Box color="#7c8ea3" component="div" mt={.5}>
                                <RichText text={description} />
                            </Box>
                        </Typography>
                    )}
                </Grid>
                {isInactive && (
                    <Grid item className={classes.inactiveIconContainer}>
                        <HideIcon />
                    </Grid>
                )}
                <Grid className={classes.actions} item>
                    <Grid container spacing={1} wrap="nowrap">
                        {onEdit && (
                            <Grid item>
                                <IconButton aria-label="edit" disabled={loading} onClick={onEdit}>
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        )}
                        {onDelete && (
                            <Grid item>
                                <IconButton aria-label="delete" disabled={loading} onClick={onDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};

export default AdminListItem;