import React, { useReducer } from "react";
import { Box, Button, CircularProgress, Paper, Typography, useTheme } from "@material-ui/core";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import AddIcon from "svgComponents/AddIcon";
import { FcrResourceCategory } from "types";
import { reorder } from "helpers/arrayHelpers";

import CategoryListItem from "./CategoryListItem";
import EditCategory from "./EditCategory";

interface CategoryListProps {
    categories?: FcrResourceCategory[];
    selectedCategory?: FcrResourceCategory;
    onSelect: (category: FcrResourceCategory) => void;
    onAddCategory: (category: FcrResourceCategory) => void;
    onEditCategory: (category: FcrResourceCategory) => void;
    onDeleteCategory: (category: FcrResourceCategory) => void;
    onCategoriesReordered: (categories: FcrResourceCategory[]) => void;
}

const CategoryList = ({
    categories,
    selectedCategory,
    onSelect,
    onAddCategory,
    onEditCategory,
    onDeleteCategory,
    onCategoriesReordered,
}: CategoryListProps) => {
    const theme = useTheme();
    const [isAddOpen, toggleIsAddOpen] = useReducer(state => !state, false);

    const handleDragEnd = (result: DropResult) => {
        if (!categories) return;

        const { destination, source } = result;

        if (!destination || destination.index === source.index) {
            return;
        }

        onCategoriesReordered(reorder(categories, source.index, destination.index));
    };

    return (
        <>
            <Paper>
                <Box display="flex" flexDirection="column" pt={3}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" fontWeight={600} px={5} mb={2}>
                        <Typography variant="h2">
                            Categories
                        </Typography>
                        <Button>
                            <Box display="flex" alignItems="center" color="primary.main" onClick={toggleIsAddOpen}>
                                <Box display="flex" mr={1}>
                                    <AddIcon />
                                </Box>
                                New Category
                            </Box>
                        </Button>
                    </Box>
                    <Box mb={2} px={5} color={theme.colors.text.mediumGray}>
                        <Typography variant="body2">
                            {categories?.length ?
                                "Organize your resources under categories. Select a category to view the resources saved within it." :
                                "You need to create a category in order to create new resources."
                            }
                        </Typography>
                    </Box>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="categories">
                            {(provided, droppableSnapshot) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {categories && categories.map((category, index) => (
                                        <CategoryListItem
                                            key={category.id}
                                            index={index}
                                            category={category}
                                            isSelected={selectedCategory?.id === category.id}
                                            isDraggingOver={droppableSnapshot.isDraggingOver}
                                            onSelect={onSelect}
                                            onEditCategory={onEditCategory}
                                            onDeleteCategory={onDeleteCategory}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {!categories && (
                        <Box display="flex" justifyContent="center" mt={2} pb={3}>
                            <CircularProgress size={40} />
                        </Box>
                    )}
                </Box>
            </Paper>
            {isAddOpen && (
                <EditCategory
                    nextOrder={categories ? categories.length + 1 : 0}
                    isOpen={isAddOpen}
                    onSubmit={onAddCategory}
                    onClose={toggleIsAddOpen}
                />
            )}
        </>
    );
}
export default CategoryList;