import React, { useCallback, useContext } from "react";
import { Box, Grid, CircularProgress } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import ConfirmationModal from "components/ConfirmationModal";
import { postUser, uploadUserLogo } from "services/userServices";
import { UserProfile } from "types";
import { FormAvatarInput, FormInput } from "components/Form";
import { AlertSeverity, AuthProviderEnum } from "types/constants";
import { getResourceFromFile } from "helpers/resourceHelper";
import { ConfigurationContext, SnackbarContext } from "context";

interface ManageProfileModalProps {
    isOpen: boolean;
    onSave?: () => void;
    onError?: () => void;
    onClose?: () => void;
}

export type ManageProfileModalForm = Pick<UserProfile, "firstName" | "lastName"> & {
    avatarFile?: File;
};

const ManageProfileModal = ({ isOpen, onSave, onError, onClose }: ManageProfileModalProps) => {
    const { currentUserDto, patchCurrentUser, patchCurrentUserDto, isConfigurationFetching } = useContext(ConfigurationContext);
    const { openSnackbar } = useContext(SnackbarContext);

    const handleSubmit = useCallback(
        ({ firstName, lastName, avatarFile }, { setSubmitting }) => {
            if (!currentUserDto) return;

            setSubmitting(true);

            avatarFile &&
                getResourceFromFile(avatarFile, currentUserDto.companyId, currentUserDto.id)
                    .then((resource) => uploadUserLogo(currentUserDto.id, resource))
                    .then((logoData) => {
                        postUser(AuthProviderEnum.B2C, {
                            ...currentUserDto,
                            firstName,
                            lastName,
                            avatar: logoData.url,
                        }).then((user) => {
                            const userPatch = {
                                firstName: user.firstName,
                                lastName: user.lastName,
                                avatar: user.avatar,
                            };

                            if (typeof onSave === "function") onSave();
                            patchCurrentUser(userPatch);
                            patchCurrentUserDto(userPatch);
                            openSnackbar("Profile edits saved successfully", AlertSeverity.success);
                            setSubmitting(false);
                        });
                    })
                    .catch(() => {
                        openSnackbar("Failed to save profile edits", AlertSeverity.error);
                        if (typeof onError === "function") onError();
                        setSubmitting(false);
                    });
        },
        [currentUserDto, onError, onSave, openSnackbar, patchCurrentUser, patchCurrentUserDto]
    );

    const formik = useFormik<ManageProfileModalForm>({
        enableReinitialize: true,
        initialValues: {
            firstName: currentUserDto?.firstName ?? "",
            lastName: currentUserDto?.lastName ?? "",
        },
        onSubmit: handleSubmit,
    });

    return (
        <ConfirmationModal
            isOpen={isOpen}
            isLoading={isConfigurationFetching || formik.isSubmitting}
            loadingLabel={isConfigurationFetching ? "Loading data" : "Saving"}
            header="Edit your profile"
            body={
                isConfigurationFetching ? (
                    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                        <CircularProgress size={40} />
                    </Box>
                ) : (
                    currentUserDto && (
                        <FormikProvider value={formik}>
                            {
                                <Box mb={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormInput name="firstName" label="First name" required={true} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormInput name="lastName" label="Last name" required={true} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            <Box display="flex" justifyContent="center">
                                <FormAvatarInput name="avatarFile" initialAvatarUrl={currentUserDto.avatar} />
                            </Box>
                        </FormikProvider>
                    )
                )
            }
            confirmBtn="Save updates"
            onConfirm={formik.submitForm}
            onClose={onClose}
        />
    );
};

export default ManageProfileModal;
