import React, { useCallback, useState, useContext } from "react";
import { Box, Button, CircularProgress, Dialog, Typography } from "@material-ui/core";

import { CompaniesContext } from "context";

export interface AppDialogProps {
    title?: string;
    message: string;
    action?: string;
    isOpen: boolean;
    asyncConfirmation: () => Promise<unknown>;
    confirmationCallback?: () => void;
    closeCallback?: () => void;
}

export const initialAppDialogProps: AppDialogProps = {
    title: "",
    message: "",
    action: "Ok",
    isOpen: false,
    asyncConfirmation: () => Promise.resolve(),
    confirmationCallback: () => null,
    closeCallback: () => null,
};

const AppDialog = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { appDialogProps, setAppDialogProps } = useContext(CompaniesContext);
    const { title, message, action, isOpen, asyncConfirmation, confirmationCallback, closeCallback } = appDialogProps;

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        await asyncConfirmation();
        setIsLoading(false);
        confirmationCallback && confirmationCallback();
        setAppDialogProps({ ...appDialogProps, isOpen: false });
    }, [appDialogProps, asyncConfirmation, confirmationCallback, setAppDialogProps]);

    const handleOnClose = useCallback(async () => {
        setAppDialogProps({ ...appDialogProps, isOpen: false });
        closeCallback && closeCallback();
    }, [appDialogProps, closeCallback, setAppDialogProps]);

    return (
        <Dialog open={isOpen} onClose={handleOnClose} fullWidth>
            {title && (
                <Box p={4}>
                    <Typography variant="h1" component="span">
                        {title}
                    </Typography>
                </Box>
            )}
            <Box py={3} px={4} bgcolor="#F6F8F9" fontWeight={500}>
                <Typography variant="body1">{message}</Typography>
            </Box>
            <Box display="flex" flexDirection="row-reverse" justifyContent="flex-start" p={4}>
                <Box ml={2.5}>
                    <Button variant="outlined" size="large" onClick={handleOnClose}>
                        Cancel
                    </Button>
                </Box>
                <Box>
                    <Button variant="contained" color="secondary" size="large" onClick={handleSubmit} disabled={isLoading} disableElevation>
                        {isLoading ? <CircularProgress size={24} /> : action}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default AppDialog;
