import React, { useEffect, useState } from "react";
import orderBy from "lodash/orderBy"

import { NotificationsContext } from "context";
import { NotificationType, UserProfile } from "types";
import { notificationsByUser  } from "services/notificationService";

interface NotificationsProviderProps {
    currentUser?: UserProfile;
    children: React.ReactNode;
}

const NotificationsProvider = ({ currentUser, children }: NotificationsProviderProps) => {
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [isUnread, setIsUnread] = useState(false);

    useEffect(() => {
        if (currentUser) {
            notificationsByUser(currentUser.id).on("value", (snapshot) => {
                setNotifications(Object.values(orderBy(snapshot.val(), "createdAt", "desc") || []))
            })
            notificationsByUser(currentUser.id).on("child_added", () => {
                setIsUnread(true)
            })
        }
    }, [currentUser]);

    return (
        <NotificationsContext.Provider value={{
            notifications,
            isUnread,
            setIsUnread,
        }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsProvider;