import * as React from "react";

function SvgActionable(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Rearrange" fill="currentColor">
                    <path
                        d="M6,13 L6,15 L4,15 L4,13 L6,13 Z M12,13 L12,15 L10,15 L10,13 L12,13 Z M6,7 L6,9 L4,9 L4,7 L6,7 Z M12,7 L12,9 L10,9 L10,7 L12,7 Z M6,1 L6,3 L4,3 L4,1 L6,1 Z M12,1 L12,3 L10,3 L10,1 L12,1 Z"
                        id="Combined-Shape"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default SvgActionable;
