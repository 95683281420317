import { Settings, Resources } from "svgComponents";
import { UsersIcon, SkillsIcon, CompanyIcon, ProductImportIcon, EmailIcon } from "svgComponents/MenuIcons";
import { AdminRoutings } from "types/constants";

import { Menu } from "./index";

export const adminMenu: Array<Menu> = [
    {
        title: "Companies",
        enableColor: "#20ACC1",
        href: AdminRoutings.companies,
        icon: CompanyIcon,
    },
    {
        title: "Users",
        enableColor: "#53BF56",
        icon: UsersIcon,
        subMenu: [
            {
                title: "Members",
                href: AdminRoutings.members,
            },
            {
                title: "Teams",
                href: AdminRoutings.teams,
            },
        ],
    },
    {
        title: "Resources",
        enableColor: "#CC344F",
        icon: Resources,
        subMenu: [
            {
                title: "Management",
                href: AdminRoutings.resourceManagement,
            },
            {
                title: "Distribution",
                href: AdminRoutings.resourceDistribution,
            },
        ],
    },
    {
        title: "Skills",
        enableColor: "#53BF56",
        icon: SkillsIcon,
        subMenu: [
            {
                title: "Management",
                href: AdminRoutings.skillsManagement,
            },
            {
                title: "Distribution",
                href: AdminRoutings.skillsDistribution,
            },
        ],
    },
    {
        title: "Evaluations",
        enableColor: "#53BF56",
        icon: SkillsIcon,
        subMenu: [
            {
                title: "Management",
                href: AdminRoutings.certificationEvaluations,
            },
            {
                title: "Classes",
                href: AdminRoutings.certificationClasses,
            },
        ],
    },
    {
        title: "FCR Settings",
        enableColor: "#53BF56",
        icon: Settings,
        href: AdminRoutings.fcrSettings,
    },
    {
        title: "Product Import",
        enableColor: "#53BF56",
        icon: ProductImportIcon,
        href: AdminRoutings.productImport,
    },
    {
        title: "Email Configuration",
        enableColor: "#53BF56",
        icon: EmailIcon,
        href: AdminRoutings.emailConfiguration,
    },
];
