import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import PaperSection from "components/PaperSection";
import React from "react";
import { EvaluatorClassesViewAllRouting } from "types/constants";
import { EvaluatorClassDto } from "types/Certification";

import AllScheduledClassesItem from "./AllScheduledClassesPage/AllScheduledClassesItem";
import useStyles from "./ClassesPreview.styles";

export interface AllScheduledClassesPreviewProps {
    isLoading: boolean;
    classDtos: EvaluatorClassDto[] | null | undefined;
    sectionlabel: string;
}

function AllScheduledClassesPreview({ isLoading, classDtos, sectionlabel }: AllScheduledClassesPreviewProps) {
    const classes = useStyles();
    return (
        <PaperSection
            px={6}
            title={
                <Box mr={1.5} display="flex" alignItems="center">
                    <Box>
                        <Typography className={classes.title} variant="h2">
                            All Scheduled {sectionlabel}
                        </Typography>
                    </Box>
                </Box>
            }
            mb={{ xs: 3, sm: 6 }}
            href={EvaluatorClassesViewAllRouting.allScheduledClasses}>
            <Divider />
            <Box>
                {isLoading ? (
                    <Box p={4} textAlign="center">
                        <CircularProgress size={40} />
                    </Box>
                ) : (
                    classDtos?.map((classDto, i) => <AllScheduledClassesItem key={i} scheduledClass={classDto} />)
                )}
            </Box>
        </PaperSection>
    );
}

export default React.memo(AllScheduledClassesPreview);
