import { FcrCoachingSkillObservation, FcrCoachingSkill, CoachingPlanType, FcrSectionConfiguration } from "types";

export const isValidFcrCoachingSkillObservation = (skill: FcrCoachingSkillObservation) => (
    !(skill?.fcrCoachingSkill?.minRating === skill?.rating && !skill?.observation)
);

export const DEFAULT_FCR_SECTION_CONFIGURATION: Pick<
    FcrSectionConfiguration,
    "section1Label" | "section2Label" | "section3Label" | "section4Label"
> = {
    section1Label: "Reflect",
    section2Label: "Focus",
    section3Label: "Act",
    section4Label: "Summary",
};

export const buildSectionLabelsArray = (fcrSectionConfiguration?: Partial<FcrSectionConfiguration>) => [
    fcrSectionConfiguration?.section1Label,
    fcrSectionConfiguration?.section2Label,
    fcrSectionConfiguration?.section3Label,
    fcrSectionConfiguration?.section4Label,
];

export const getFcrRatingDescription = (skill: FcrCoachingSkill, rating: number) => {
    const formattedScore = rating && Number(rating.toFixed(1));
    const ratingObject = skill.fcrCoachingSkillRatings &&
        skill.fcrCoachingSkillRatings.find(e => formattedScore >= e.min && formattedScore <= e.max);
    const ratingDescription = ratingObject &&
        ratingObject.fcrCoachingSkillRatingLeadingInfos &&
        ratingObject.fcrCoachingSkillRatingLeadingInfos[0];

    return ratingDescription;
}

export const sortFcrSkillsObservations = (fcrCoachingSkills: FcrCoachingSkill[], coachingSkillObservations: FcrCoachingSkillObservation[]) => {
    const observationsSortIds = fcrCoachingSkills.map((skill) => skill.id) ?? [];
    return coachingSkillObservations.slice().sort((a, b) => (
        observationsSortIds.indexOf(a.fcrCoachingSkillId) - observationsSortIds.indexOf(b.fcrCoachingSkillId)
    ));
}

export const sortFcrCoachingPlans = (fcrCoachingSkills: FcrCoachingSkill[], coachingSkillObservations: FcrCoachingSkillObservation[], coachingPlans: CoachingPlanType[]) => {
    const sortedFcrSkillsObservations = sortFcrSkillsObservations(fcrCoachingSkills, coachingSkillObservations);
    const sortedFcrSkillsObservationsIds = sortedFcrSkillsObservations.map((observation) => observation.id) ?? [];

    return coachingPlans.slice().sort((a, b) => (
        sortedFcrSkillsObservationsIds.indexOf(a.fcrCoachingSkillResultId) - sortedFcrSkillsObservationsIds.indexOf(b.fcrCoachingSkillResultId)
    ));
}
