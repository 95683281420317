import React, { useMemo } from "react";
import { LinearProgress } from "@material-ui/core";

import { calculatePercentFromRange } from "helpers/numberHelper";

import useStyles from "./styles";

interface LinearProgressGradientProps {
    score?: number;
    minRating: number;
    maxRating: number;
}

const LinearProgressGradient = ({ score = 0, minRating, maxRating }: LinearProgressGradientProps) => {
    const classes = useStyles();
    const progressValue = useMemo(
        () => calculatePercentFromRange(score, minRating, maxRating),
        [maxRating, minRating, score]
    );

    const progressBarClass = useMemo(() => {
        switch (true) {
            case progressValue < 40:
                return classes.barLowest;
            case progressValue < 60:
                return classes.barLow;
            case progressValue < 80:
                return classes.barMedium;
            default:
                return classes.barHigh;
        }
    }, [progressValue, classes]);

    return (
        <LinearProgress
            variant="determinate"
            value={progressValue}
            classes={{ bar: progressBarClass }}
        />
    );
};

export default LinearProgressGradient;