import React, { useState, useContext, useEffect } from "react";
import { Box, Drawer } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CompaniesContext } from "context";
import { AdminRoutings } from "types/constants";

import { adminMenu } from "./adminMenu";
import MenuItem from "./MenuItem";
import useStyles from "../styles";
import Logo from "../Logo";

interface MenuItemProps {
    title: string;
    enableColor: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement;
    href?: string;
}

export interface MenuSubItemProps {
    title: string;
    href?: string;
}

export interface Menu extends MenuItemProps {
    subMenu?: Array<MenuSubItemProps>;
}

interface NavBarProps {
    open: boolean;
    navBarClose: () => void;
}

function AdminNavBar({ open, navBarClose }: NavBarProps) {
    const classes = useStyles();
    const { currentUser, currentCompany } = useContext(CompaniesContext);
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>("");

    useEffect(() => {
        currentCompany && !currentCompany.isActive && setSelectedMenuItem("");
    }, [currentCompany]);

    return (
        <Drawer
            classes={{ paper: classes.drawer }}
            variant="persistent"
            open={open}
            role="navigation"
        >
            <Box mx={2} pb={2.5}>
                <Logo navBarClose={navBarClose} />
                {currentUser ? (adminMenu.map((adminMenuItem: Menu) => (
                    <MenuItem
                        key={adminMenuItem.title}
                        disabled={adminMenuItem.href !== AdminRoutings.companies && !currentCompany?.isActive}
                        selectedMenuItem={selectedMenuItem}
                        setSelectedMenuItem={setSelectedMenuItem}
                        {...adminMenuItem}
                    />
                ))) : (
                    [1, 2, 3].map((index) => (
                        <Box key={index} py={2} px={2.5}>
                            <Skeleton />
                        </Box>
                    ))
                )}
            </Box>
        </Drawer>
    );
}

export default React.memo(AdminNavBar);
