import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkbox: {
            background: theme.colors.structure.background,
            borderRadius: "6px",
        },
        darkBlueText: {
            color: theme.colors.text.darkBlue,
        },
        redText: {
            color: "#DD3928",
        },
        radioButtonAnswer: {
            height: "24px",
            alignItems: "center",
            display: "flex"
        },
        dropDownAnswers: {
            position: "relative",
            width: "15px",
            height: "15px",
            top: "-28px",
            marginBottom: "-15px",
            left: "36px",
        },
        spaceForAnswer: {
            marginLeft: "2rem",
        },
        checkmarkIcon: {
            color: "green",
        },
        crossIcon: {
            color: "red",
        },
    })
);

export default useStyles;
