import React, { useState, useCallback, useMemo } from "react";
import { orderBy } from "lodash";
import { Box, Button, Divider, Grid, Typography, CircularProgress } from "@material-ui/core";
import { PlusIcon } from "svgComponents";
import { CoachingSessionType, FcrConfiguration } from "types";
import AdminListItem from "components/AdminListItem";
import ConfirmationModal from "components/ConfirmationModal";
import { getSessionTypeIcon } from "helpers/coachingHistoryHelper";
import { deleteCoachingSessionType, getAmountOfNotStartedForSessionType } from "services/coachingSessionServices";

import CoachingSessionTypeFormModal from "./CoachingSessionTypeFormModal";

interface CoachingSessionTypesProps {
    fcrConf: FcrConfiguration | null;
    onSuccess: () => Promise<void>,
    isFetching: boolean;
}

export default function CoachingSessionTypes({ fcrConf, onSuccess, isFetching }: CoachingSessionTypesProps) {
    const [isModalOpened, setModalOpened] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<CoachingSessionType | null>(null);
    const [isDeleting, setDeleting] = useState<boolean>(false);
    const [deleteConfirmationModalData, setDeleteConfirmationModalData] = useState<{ id: string; amount: number; } | null>(null);

    const orderedSessionTypes = useMemo(() => (
        orderBy(fcrConf?.coachingSessionTypes ?? [], ["isActive", ({ value }) => value.toLowerCase()], ["desc", "asc"])
    ), [fcrConf?.coachingSessionTypes]);

    const sendDeleteRequest = useCallback((itemId: string) => {
        if(!fcrConf) return;

        setDeleting(true);
        deleteCoachingSessionType(fcrConf.id, itemId)
            .then(onSuccess)
            .then(() => {
                deleteConfirmationModalData && setDeleteConfirmationModalData(null);
                isModalOpened && setModalOpened(false);
                isModalOpened && setEditItem(null);
            })
            .finally(() => setDeleting(false));
    }, [fcrConf, onSuccess, deleteConfirmationModalData, isModalOpened]);

    const handleDelete = useCallback((item: CoachingSessionType) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setDeleting(true);
        getAmountOfNotStartedForSessionType(item.id, fcrConf?.companyId ?? "")
            .then((amount: number) => {
                if (amount > 0) {
                    setDeleting(false);
                    setDeleteConfirmationModalData({ id: item.id, amount });
                } else {
                    sendDeleteRequest(item.id);
                }
            })
            .catch(() => setDeleting(false));
    }, [sendDeleteRequest, fcrConf]);

    const handleEdit = useCallback((item: CoachingSessionType) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setModalOpened(true);
        setEditItem(item);
    }, []);

    const handleClose = useCallback(() => {
        setModalOpened(false);
        setEditItem(null);
    }, []);

    const handleCreate = useCallback(() => {
        setModalOpened(true);
        setEditItem(null);
    }, []);

    const nextOrder = Math.max(...orderedSessionTypes.map(({ order }) => order)) + 1;

    return (
        <>
            <Box pt={3}>
                <Box px={4}>
                    <Grid alignItems="center" justifyContent="space-between" container spacing={1}>
                        <Grid item>
                            <Typography variant="h2">
                                <Box component="span" fontWeight={500}>Coaching Session Types</Box>
                            </Typography>
                        </Grid>
                        {!isFetching && fcrConf && 
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    startIcon={<PlusIcon />}
                                    onClick={handleCreate}
                                >
                                    <Box lineHeight="30px">New Session Type</Box>
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Box>
                {!isFetching && fcrConf ? 
                    <>
                        <Box mt={3}>
                            {orderedSessionTypes.map((sessionType) => (
                                <React.Fragment key={sessionType.id}>
                                    <Divider />
                                    <AdminListItem
                                        icon={getSessionTypeIcon(sessionType.icon)}
                                        isInactive={!sessionType.isActive}
                                        bodyProps={{ py: 1 }}
                                        loading={isDeleting}
                                        title={sessionType.value}
                                        onDelete={orderedSessionTypes.length > 1 ? handleDelete(sessionType) : undefined}
                                        onEdit={handleEdit(sessionType)}
                                        onClick={handleEdit(sessionType)}
                                    />
                                </React.Fragment>
                            ))}
                        </Box>
                        {isModalOpened && (
                            <CoachingSessionTypeFormModal
                                item={editItem}
                                open={isModalOpened}
                                fcrConfigurationId={fcrConf.id}
                                isDeleting={isDeleting}
                                nextOrder={nextOrder}
                                onClose={handleClose}
                                onSuccess={onSuccess}
                                onDelete={handleDelete}
                            />
                        )}
                        {deleteConfirmationModalData && (
                            <ConfirmationModal
                                header="Delete Coaching Session Type"
                                body={`
                                    Warning: There are ${deleteConfirmationModalData.amount} Scheduled Session${deleteConfirmationModalData.amount > 1 ? "s" : ""}
                                    without Coaching Reports that will also be deleted if you delete this Coaching Session Type. Proceed?
                                `}
                                confirmBtn="Proceed"
                                isLoading={isDeleting}
                                loadingLabel="Deleting..."
                                isOpen={!!deleteConfirmationModalData}
                                onClose={() => setDeleteConfirmationModalData(null)}
                                onConfirm={() => sendDeleteRequest(deleteConfirmationModalData.id)}
                            />
                        )}
                    </> :
                    <Box px={6} pt={4} pb={3} textAlign="center">
                        <CircularProgress size={40} />
                    </Box>
                }
            </Box>
        </>
    )
}
