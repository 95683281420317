import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    skeleton: {
        border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        borderRadius: "6px",
    },
}));

export default useStyles;

