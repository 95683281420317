import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Button, Divider, Grid, MenuItem, Typography } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { Form, FormikProvider, useFormik } from "formik";

import { EvaluationsAutocomplete } from "components/EvaluationsAutocomplete";
import { FormSelect, Select, SelectItemProps } from "components/Form/FormSelect";
import { CertificationClassInformation } from "types/Certification";
import useOnCompanyChange from "hooks/useOnCompanyChange";
import { yearNow, formatMonthFull, formatYear, monthFullList } from "helpers/dateHelpers";

import { TickIcon } from "svgComponents";
import sortBy from "lodash/sortBy";

interface CertificationClassesFilterProps {
    evaluationsAutocompleteLabel?: string;
    certificationClasses: CertificationClassInformation[];
    onFilter: (filteredClasses: CertificationClassInformation[]) => void;
}

enum StatusFilterEmum {
    all,
    active,
    inactive,
}

interface CertificationClassesForm {
    month: string;
    year: string;
}

const ALL_ITEMS_VALUE = "All";

const monthFieldOptions: SelectItemProps[] = [ALL_ITEMS_VALUE, ...monthFullList].map((month) => ({
    id: month,
    title: month,
}));

const DEFAULT_FORM_VALUES = { year: ALL_ITEMS_VALUE, month: ALL_ITEMS_VALUE };
const INITIAL_FORM_VALUES = { ...DEFAULT_FORM_VALUES, year: yearNow };

export default function CertificationClassesFilter({
    evaluationsAutocompleteLabel,
    certificationClasses,
    onFilter,
}: CertificationClassesFilterProps) {
    const [evaluationClassId, setEvaluationClassId] = useState<CertificationClassInformation["id"] | null>(null);
    const [yearFieldOptions, setYearFieldOptions] = useState<SelectItemProps[]>([]);
    const [status, setStatus] = useState<StatusFilterEmum>(StatusFilterEmum.all);

    const filter = (evaluationClassId: CertificationClassInformation["id"] | null, status: StatusFilterEmum) => {
        const filtered =
            status === StatusFilterEmum.all
                ? certificationClasses
                : certificationClasses.filter(({ isActive }) => (status === StatusFilterEmum.active ? isActive : !isActive));

        if (evaluationClassId) {
            return onFilter(filtered.filter((evaluationClass) => evaluationClass.id === evaluationClassId));
        }

        return handleOnFilter(filtered);
    };

    const handleOnFilter = useCallback(
        (filtered: CertificationClassInformation[]) => {
            onFilter(filtered);
        },
        [onFilter]
    );

    const handleNameFilter = (evaluationClassId: CertificationClassInformation["id"] | null) => {
        setStatus(StatusFilterEmum.all);
        setEvaluationClassId(evaluationClassId);
        resetCertificationClassesForm();
        filter(evaluationClassId, StatusFilterEmum.all);
    };

    const handleStatusFilter = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = e.target.value as StatusFilterEmum;
        setStatus(value);
        setEvaluationClassId(null);
        resetCertificationClassesForm();
        filter(null, value);
    };

    const handleResetFilter = () => {
        setStatus(StatusFilterEmum.all);
        setEvaluationClassId(null);
        resetCertificationClassesForm();
        filter(null, StatusFilterEmum.all);
    };

    const resetCertificationClassesForm = () => {
        formik.setValues(DEFAULT_FORM_VALUES);
    };

    useOnCompanyChange(handleResetFilter);

    const initialValues = useMemo<CertificationClassesForm>(() => INITIAL_FORM_VALUES, []);

    const filterCertificationClasses = useCallback(
        async (values: CertificationClassesForm) => {
            // eslint-disable-next-line functional/no-let
            let filtered = certificationClasses;
            if (values.year !== ALL_ITEMS_VALUE) {
                filtered = filtered.filter((c) => formatYear(c.classDate) === values.year);
            }

            if (values.month !== ALL_ITEMS_VALUE) {
                filtered = filtered.filter((c) => formatMonthFull(c.classDate) === values.month?.toUpperCase());
            }
            handleOnFilter(filtered);
        },
        [certificationClasses, handleOnFilter]
    );

    const handleYearFieldChange: SelectInputProps["onChange"] = (event) => {
        const { value } = event.target;
        formik.setFieldValue("year", value);
        if (value === ALL_ITEMS_VALUE) {
            formik.setFieldValue("month", ALL_ITEMS_VALUE);
        }
        return event.target.value;
    };

    const handleSubmit = useCallback(
        async (values: CertificationClassesForm) => {
            setStatus(StatusFilterEmum.all);
            setEvaluationClassId(null);
            filterCertificationClasses(values);
        },
        [filterCertificationClasses]
    );

    const formik = useFormik<CertificationClassesForm>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: handleSubmit,
    });

    const disabledMonth = useCallback(() => formik.values.year === ALL_ITEMS_VALUE, [formik.values.year]);

    useEffect(() => {
        const yearValues = certificationClasses.map(({ classDate }) => formatYear(classDate));
        yearValues.push(yearNow, ALL_ITEMS_VALUE);
        setYearFieldOptions(
            sortBy([...new Set(yearValues)], (year) => year)
                .reverse()
                .map((year) => ({
                    id: year,
                    title: year,
                }))
        );
        filterCertificationClasses(INITIAL_FORM_VALUES);
    }, [certificationClasses, filterCertificationClasses, initialValues]);

    if (!certificationClasses.length) return null;

    return (
        <Box py={3} px={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex">
                    <Box minWidth={300} mr={2}>
                        <EvaluationsAutocomplete
                            label={evaluationsAutocompleteLabel}
                            items={certificationClasses}
                            value={evaluationClassId}
                            onChange={handleNameFilter}
                        />
                    </Box>
                    <Select variant="outlined" value={status} onChange={handleStatusFilter}>
                        <MenuItem value={StatusFilterEmum.all}>All Classes</MenuItem>
                        <MenuItem value={StatusFilterEmum.inactive}>Deactivated Classes</MenuItem>
                        <MenuItem value={StatusFilterEmum.active}>Only Active Classes</MenuItem>
                    </Select>
                </Box>
                <Button color="primary" size="large" onClick={handleResetFilter}>
                    Clear Filter
                </Button>
            </Box>
            <Box py={3}>
                <Divider />
            </Box>
            <Box>
                <FormikProvider value={formik}>
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <FormSelect disabled={disabledMonth()} name="month" label="Class Month" options={monthFieldOptions} />
                            </Grid>
                            <Grid item xs={2}>
                                <FormSelect name="year" label="Class Year" options={yearFieldOptions} onChange={handleYearFieldChange} />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                style={{
                                    marginLeft: "auto",
                                }}>
                                <Button fullWidth variant="contained" color="primary" size="large" type="submit" startIcon={<TickIcon />}>
                                    <Typography noWrap>
                                        <Box lineHeight="30px">Apply Filter</Box>
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Box>
        </Box>
    );
}
