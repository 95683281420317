import React, { useMemo } from "react";
import { Box, Card, Typography } from "@material-ui/core";
import AvatarWithScore from "components/AvatarWithScore";
import { ScoreBarMode } from "components/AvatarWithScore/ScoreBar";
import SvgCalendar from "svgComponents/Calendar";
import { UserProfile, UserProfileOverview } from "types";
import { formatPastYearDate } from "helpers/dateHelpers";
import { getUserFullName } from "helpers/userProfileHelper";

import useStyles from "./styles";

interface TeamOverviewMemberProps {
    leader?: UserProfile,
    user: UserProfile,
    overview?: UserProfileOverview | null,
    isHighLevelTeamMode?: boolean,
}

export default function TeamOverviewMember({ isHighLevelTeamMode, user, overview }: TeamOverviewMemberProps) {
    const classes = useStyles();
    const { timesCoached, lastCoached, skillInFocus, averageScore } = overview ?? user;
    const fullName = useMemo(() => getUserFullName(user), [user]);
    const lastCoachSession = useMemo(() => lastCoached ? formatPastYearDate(lastCoached) : "", [lastCoached]);
    const scoreMode = useMemo(() => isHighLevelTeamMode ? ScoreBarMode.DARK : ScoreBarMode.LIGHT, [isHighLevelTeamMode]);

    return (
        <Card elevation={1} className={classes.card}>
            <Box
                px={4}
                py={4}
                alignItems="center"
                display="flex"
                flexDirection="column"
                className={classes.layout}
            >
                <Box>
                    <AvatarWithScore user={user} overview={overview} score={averageScore} scoreCaption={isHighLevelTeamMode ? "Team" : ""} scoreMode={scoreMode} />
                </Box>
                <Box mt={4}>
                    <Typography className={classes.fullName} variant="h3">{fullName}</Typography>
                </Box>
                {skillInFocus && (
                    <Box mt={.5} textAlign="center">
                        <Typography className={classes.skill} component="span" variant="body2">{skillInFocus}</Typography>
                    </Box>
                )}

                <Box mt="auto" pt={3}>
                    <Typography className={classes.label} component="span" variant="body2">Coached: </Typography>
                    <Typography className={classes.coachedNumber} component="span" variant="body2">{timesCoached} <SvgCalendar className={classes.icon} height="12" width="11" viewBox="0 0 15 17" /></Typography>
                </Box>
                {lastCoachSession && (
                    <Box mt={.5}>
                        <Typography className={classes.label} component="span" variant="body2">Last coached: </Typography>
                        <Typography className={classes.date} component="span" variant="body2">{lastCoachSession}</Typography>
                    </Box>
                )}
            </Box>
        </Card>
    )
}
