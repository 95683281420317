import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, Divider, Grid, Typography } from "@material-ui/core";
import CoachingPlanType from "types/CoachingPlanType";
import { CoachingPlanStatusEnum, Routings, SizeEnum } from "types/constants";
import { Link } from "react-router-dom";
import AvatarWithScore from "components/AvatarWithScore";
import SvgDiamond from "svgComponents/Diamond";
import SvgCalendar from "svgComponents/Calendar";
import { checkDueDate, formatDate, getTimeAgo } from "helpers/dateHelpers";
import useStyles from "components/CoachingPlanModal/style";
import SvgCloseCross from "svgComponents/CloseCross";
import RichText from "components/RichText";
import StatusLabel from "components/StatusLabel";
import clsx from "clsx";
import { CoachingSession } from "types";
import { ConfigurationContext } from "context";
import { changeFcrCoachingPlanStatus } from "services/wizardServices";
import { getCoachingSession } from "services/coachingSessionServices";

import CoachingPlanResourceLink from "./CoachingPlanResourceLink";

interface CoachingPlanModalProps {
    data: CoachingPlanType,
    isShown: boolean,
    onClose: () => void,
    onCoachingPlanChange: (newCoachingPlan: CoachingPlanType) => void,
}

export default function CoachingPlanModal({ data, isShown, onCoachingPlanChange, onClose }: CoachingPlanModalProps) {
    const classes = useStyles();
    const { currentUser, hasCoachingReportAccess } = useContext(ConfigurationContext);
    const [coachingSession, setCoachingSession] = useState<CoachingSession | null>(null);
    const { 
        fcrCoachingPlanStatusId, 
        createdBy, 
        modifiedBy, 
        coachingSessionId, 
        resources, 
        coachingPlanData,
        fcrCoachingSkillName,
        dueDate,
        createdDate,
        inProgressDate,
        completedDate,
    } = data;

    const isCompleted = useMemo(() => fcrCoachingPlanStatusId === CoachingPlanStatusEnum.completed, [fcrCoachingPlanStatusId]);

    const creatorName = useMemo(
        () => `${createdBy?.firstName || ""} ${createdBy?.lastName || ""}`.trim(),
        [createdBy]
    );
    const modifierName = useMemo(
        () => `${modifiedBy?.firstName || ""} ${modifiedBy?.lastName || ""}`.trim(),
        [modifiedBy]
    );
    const isCoach = useMemo(() => {
        const isALeader = currentUser?.isALeader ?? false;
        const isACoLeader = currentUser?.isACoLeader ?? false;
        return (isALeader || isACoLeader);
    }, [currentUser]);

    useEffect(() => {
        if (coachingSessionId) {
            getCoachingSession(coachingSessionId).then(setCoachingSession);
        } else {
            setCoachingSession(null);
        }
    }, [coachingSessionId]);

    const changeStatus = useCallback(async (status: CoachingPlanStatusEnum) => {
        const response = await changeFcrCoachingPlanStatus(data.id, status);
        if (response) onCoachingPlanChange(response);
        onClose();
    }, [data.id, onCoachingPlanChange, onClose])

    const start = useCallback(() => changeStatus(CoachingPlanStatusEnum.inProgress), [changeStatus]);
    const complete = useCallback(() => changeStatus(CoachingPlanStatusEnum.completed), [changeStatus]);

    return (
        <Dialog open={isShown} onClose={onClose} maxWidth="lg">
            <Box className={classes.window} width="100%">
                <Box px={4.5} py={3.5}>
                    <Grid alignItems="center" container spacing={2}>
                        <Grid item>
                            <Typography variant="h1">Coaching Plan</Typography>
                        </Grid>
                        <Grid className={classes.alignRight} item>
                            <StatusLabel status={fcrCoachingPlanStatusId} darkBackground={true} />
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.close}
                                color="inherit"
                                size="large"
                                variant="text"
                                onClick={onClose}
                            >
                                <SvgCloseCross />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box px={4.5}>
                    <Typography className={classes.headline} variant="body1">Description</Typography>
                    <Box mt={.5}>
                        <Typography component="div" variant="body1">
                            <RichText text={coachingPlanData} />
                        </Typography>
                    </Box>
                </Box>
                {resources.length > 0 && (
                    <Box mt={4} px={4.5}>
                        <Typography className={classes.headline} variant="body1">Resource(s)</Typography>
                        <Box className={classes.resources} component="ul" mt={1.5} mb={0} pl={0}>
                            {resources.map((resource, idx) => (
                                <Box key={resource.id} component="li" mt={idx > 0 ? 1 : 0}>
                                    <CoachingPlanResourceLink resource={resource} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
                <Box mt={4} px={4.5}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography className={classes.headline} variant="body1">Coached By</Typography>
                            <Grid alignItems="center" className={classes.info} container spacing={2}>
                                <Grid item>
                                    {createdBy && (
                                        <AvatarWithScore user={createdBy} size={SizeEnum.small} />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">{creatorName}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.headline} variant="body1">Skill</Typography>
                            <Grid alignItems="center" className={classes.info} container spacing={2}>
                                <Grid item>
                                    <Grid
                                        alignItems="center"
                                        className={classes.infoIcon}
                                        container
                                        justifyContent="center"
                                    >
                                        <SvgDiamond />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">{fcrCoachingSkillName}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.headline} variant="body1">Due Date</Typography>
                            <Grid alignItems="center" className={classes.info} container wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Grid
                                        alignItems="center"
                                        className={classes.infoIcon}
                                        container
                                        justifyContent="center"
                                    >
                                        <SvgCalendar viewBox="0 0 15 17" width={12} height={13} />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography className={clsx({ [classes.expired]: checkDueDate(dueDate) && !isCompleted })} variant="body1">
                                        <Box component="span" fontWeight={500}>
                                            {formatDate(dueDate)}
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.history} mt={4} px={4.5} py={3.5}>
                    <Box className={classes.historyRecords} component="ul" my={0} pl={0}>
                        {createdDate && (
                            <Typography component="li" variant="body2">
                                {creatorName} created coaching plan.
                                <Box component="span" display="inline-block" ml={1}>
                                    {getTimeAgo(createdDate)}
                                </Box>
                            </Typography>
                        )}
                        {inProgressDate && (
                            <Typography component="li" variant="body2">
                                {modifierName} changed status to In Progress.
                                <Box component="span" display="inline-block" ml={1}>
                                    {getTimeAgo(inProgressDate)}
                                </Box>
                            </Typography>
                        )}
                        {completedDate && (
                            <Typography component="li" variant="body2">
                                {modifierName} changed status to Completed.
                                <Box component="span" display="inline-block" ml={1}>
                                    {getTimeAgo(completedDate)}
                                </Box>
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Divider />
                <Box px={4.5} py={3.5}>
                    <Grid alignItems="center" container spacing={2}>
                        {coachingSession && hasCoachingReportAccess(coachingSession) && (
                            <Grid className={classes.alignLeft} item>
                                <Link className={classes.viewLink} to={`${Routings.fcr}/${coachingSessionId}`}>
                                    <Typography component="span" variant="body1" color="inherit">
                                        View Coaching Report
                                    </Typography>
                                </Link>
                            </Grid>
                        )}
                        {!isCoach && !inProgressDate && !completedDate && (
                            <Grid item>
                                <Button
                                    color="inherit"
                                    size="large"
                                    variant="outlined"
                                    onClick={start}
                                >
                                    Mark as In Progress
                                </Button>
                            </Grid>
                        )}
                        {!isCoach && !completedDate && (
                            <Grid item>
                                <Button
                                    color="primary"
                                    disableElevation
                                    size="large"
                                    variant="contained"
                                    onClick={complete}
                                >
                                    Mark as Complete
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
        </Dialog>
    )
}
