import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    popover: {
        marginTop: theme.spacing(1),
    },
    paper: {
        borderRadius: "8px",
    },
    link: {
        color: theme.colors.text.mediumGray,
    },
    scrollContainer: {
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        marginTop: "8px",
        marginBottom: "8px",
        marginRight: "4px",
        paddingLeft: "32px",
        paddingRight: "28px",

        "&::-webkit-scrollbar": {
            width: "10px",
        },

        "&::-webkit-scrollbar-thumb": {
            background: "#EFF2F4",
            borderRadius: "10px",
        },

        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
    },
}));
