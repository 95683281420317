import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    defaultBar: {
        background: theme.colors.structure.rulesAndBorders,
        transition: theme.transitions.create("background-color", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
        }),
    },
    barHigh: {
        "&:hover": {
            background: theme.colors.graphVertical.high,
        },
    },
    barMedium: {
        "&:hover": {
            background: theme.colors.graphVertical.medium,
        },
    },
    barLow: {
        "&:hover": {
            background: theme.colors.graphVertical.low,
        },
    },
    barLowest: {
        "&:hover": {
            background: theme.colors.graphVertical.lowest,
        },
    },
}));
