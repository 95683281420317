import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        "& fieldset": {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.colors.structure.fieldsOutline,
            borderRadius: "6px",
        },
        "& + .Mui-error": {
            marginLeft: 0,
        },
    },
    input: {
        padding: "12px 14px",
        minHeight: "22px",
    },
    outlined: {
        transform: "translate(16px, 15px) scale(1)",
    },
}));

export default useStyles;
