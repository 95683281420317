import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ConfigurationContext } from "context";
import SummaryCardSessions from "components/SummaryCard/SummaryCardSessions";
import SummaryCardGoals from "components/SummaryCard/SummaryCardGoals";
import SummaryCardFocus from "components/SummaryCard/SummaryCardFocus";
import SummaryCardPlans from "components/SummaryCard/SummaryCardPlans";
import { CoachingHistory } from "types/CoachHistory";
import { COACHING_PERIOD_OPTS } from "hooks/useCoachingPeriod";
import { calcScore } from "helpers/coachingHistoryHelper";
import { CoachingPeriodEnum } from "types/constants";

import SummaryLayout from "../SummaryLayout";
import useSummary from "../useSummary";

interface CoachSummaryProps {
    data?: CoachingHistory[];
    currentDate: Date;
    teamMode: boolean;
    isFetching?: boolean;
}

const CoachSummary = ({ data = [], isFetching = false, currentDate, teamMode }: CoachSummaryProps) => {
    const { fcrSectionConfiguration } = useContext(ConfigurationContext);

    const { period, periodLabel, curHistory, prevHistory, setPeriod, setHistory } = useSummary(data, currentDate);

    useEffect(() => setHistory(data), [data, setHistory]);

    const handlePeriodChange = useCallback((event: React.ChangeEvent<{ name?: string; value: CoachingPeriodEnum }>) =>
        setPeriod(event.target.value), [setPeriod]);

    const sessions = useMemo(() => calcScore(curHistory, prevHistory, "coachingSessions"), [curHistory, prevHistory]);
    const goals = useMemo(() => calcScore(curHistory, prevHistory, "developmentGoals"), [curHistory, prevHistory]);
    const focus = useMemo(() => calcScore(curHistory, prevHistory, "skillFocus"), [curHistory, prevHistory]);
    const plans = useMemo(() => calcScore(curHistory, prevHistory, "coachingPlans"), [curHistory, prevHistory]);

    return (
        <SummaryLayout
            period={period}
            options={COACHING_PERIOD_OPTS}
            onPeriodChange={handlePeriodChange}
            title={teamMode ? "Coaching" : "My Coaching"}
        >
            <SummaryCardSessions
                signature={periodLabel}
                label="Coaching Sessions"
                score={sessions.total}
                isIncreased={sessions.isIncreased}
                isFetching={isFetching}
                tooltip={teamMode
                    ? "This is the number of coaching sessions your team has completed"
                    : "This is the number of coaching sessions you have completed with your team"
                }
            />
            <SummaryCardGoals
                signature={periodLabel}
                label={fcrSectionConfiguration?.section1Label}
                score={`${goals.score}%`}
                isIncreased={goals.isIncreased}
                isFetching={isFetching}
                tooltip={teamMode
                    ? "This is the % of coaching reports your team has completed that include development goals"
                    : "This is the % of coaching reports you've completed that include development goals"
                }
            />
            <SummaryCardFocus
                signature={periodLabel}
                label={fcrSectionConfiguration?.section2Label}
                score={`${focus.score}%`}
                isIncreased={focus.isIncreased}
                isFetching={isFetching}
                tooltip={teamMode
                    ? "This is the % of coaching reports that consistently focus on one skill with the same team member for your team"
                    : "This is the % of coaching reports that consistently focus on one skill with the same team member"
                }
            />
            <SummaryCardPlans
                signature={periodLabel}
                label={fcrSectionConfiguration?.section3Label}
                score={`${plans.score}%`}
                isIncreased={plans.isIncreased}
                isFetching={isFetching}
                tooltip={teamMode
                    ? "This is the % of coaching reports have been completed by your team that include a coaching plan"
                    : "This is the % of coaching reports you've completed that include a coaching plan"
                }
            />
        </SummaryLayout>
    )
}

export default CoachSummary;