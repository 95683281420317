import React from "react";
import SvgSessions from "svgComponents/Sessions";
import clsx from "clsx";

import useStyles from "./styles";

const SessionsIcon = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
    const classes = useStyles()

    return <SvgSessions className={clsx(classes.root, className)} {...props} />
} 

export default SessionsIcon;
