import React from "react";
import { AutocompleteInput } from "components/Form/FormAutocomplete";
import useEvaluationsAutocomplete, { UseEvaluationsAutocompleteProps } from "hooks/useEvaluationsAutocomplete";
import { CertificationClassInformation, EvaluationConfiguration } from "types/Certification";

export function EvaluationsAutocomplete<Multiple extends boolean | undefined = undefined>({
    ...props
}: UseEvaluationsAutocompleteProps<Multiple>) {
    const baseProps = useEvaluationsAutocomplete<Multiple>(props);

    return (
        <AutocompleteInput<EvaluationConfiguration["id"] | CertificationClassInformation["id"], Multiple, false, false> {...baseProps} />
    );
}
