import React, { useCallback, useContext, useMemo } from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import CircleIcon from "components/CircleIcon";
import ConditionalLink from "components/shared/ConditionalLink";
import ConditionalTooltip from "components/shared/ConditionalTooltip";

import { formatShortDate } from "helpers/dateHelpers";
import { getClassStatusDateCaption, getEvaluationUrl } from "helpers/certificationHelper";

import { EvaluationResultStatusEnum, EvaluationStatusText } from "types/constants";
import { TraineeClassInfoDto } from "types/Certification";

import { Calendar } from "svgComponents";
import * as CoachingSessionTypeIcons from "svgComponents/CoachingSessionTypeIcons";
import IconPersonCheck from "svgComponents/IconPersonCheck";

import { ConfigurationContext } from "context";

import useStyles from "./styles";

export interface TraineeClassesItemProps {
    item: TraineeClassInfoDto;
}

function TraineeClassesItem({ item }: TraineeClassesItemProps) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const { currentUser } = useContext(ConfigurationContext);

    const statusColor: string = useMemo(() => {
        switch (item.evaluationStatusId as unknown as EvaluationResultStatusEnum) {
            case EvaluationResultStatusEnum.new:
                return theme.colors.statusLabel.background.green;
            case EvaluationResultStatusEnum.inProgress:
                return theme.colors.structure.pageBackground;
            case EvaluationResultStatusEnum.completed:
                return theme.colors.structure.pageBackground;
            default:
                return theme.colors.statusLabel.background.green;
        }
    }, [item, theme]);

    const isEvaluator: boolean = useMemo(() => {
        if (currentUser) {
            return item.classEvaluatorIds.includes(currentUser.id);
        }
        return false;
    }, [currentUser, item.classEvaluatorIds]);

    const isCompleted: boolean = useMemo(() => item.evaluationStatusId === EvaluationResultStatusEnum.completed, [item.evaluationStatusId]);

    const canShowLink = useMemo(() => {
        if (!isCompleted) {
            return !item.isSelfEvaluated && isEvaluator;
        }
        return true;
    }, [isCompleted, isEvaluator, item.isSelfEvaluated]);

    const handleClickClass = useCallback(async () => {
        if (currentUser) {
            const url = await getEvaluationUrl(item, currentUser.id);
            history.push(url);
        }
    }, [currentUser, history, item]);

    return (
        <ConditionalLink onClick={handleClickClass} condition={canShowLink}>
            <ConditionalTooltip textContent="Unable to view until Completed" condition={!canShowLink}>
                <div className={classes.classItemWrap}>
                    <Grid container spacing={2} className={clsx(classes.classItem)}>
                        <Grid item md className={classes.classItemCell}>
                            <CircleIcon>
                                <Calendar />
                            </CircleIcon>
                            <div className={classes.classItemCellText}>
                                {item.classDate ? (
                                    <div className={classes.bold}>
                                        <Typography variant="h2">{formatShortDate(new Date(item.classDate))}</Typography>
                                    </div>
                                ) : (
                                    <Typography variant="h2">-</Typography>
                                )}
                            </div>
                        </Grid>
                        <Grid item md className={classes.classItemCell}>
                            <CircleIcon>
                                <CoachingSessionTypeIcons.BusinessPlanningIcon />
                            </CircleIcon>
                            <div className={classes.classItemCellTextBold}>
                                <Typography variant="h2">{item.className}</Typography>
                                <Typography variant="caption">Class</Typography>
                            </div>
                        </Grid>
                        <Grid item xs className={clsx([classes.classItemCell, classes.classItemJustIcon])}>
                            {item.isSelfEvaluated ? (
                                <CircleIcon>
                                    <IconPersonCheck />
                                </CircleIcon>
                            ) : null}
                        </Grid>
                        <Grid item md={2} className={classes.classItemCell}>
                            <div className={classes.classItemStatus} style={{ backgroundColor: statusColor }}>
                                {EvaluationStatusText[item.evaluationStatusId as unknown as EvaluationResultStatusEnum]}
                            </div>
                        </Grid>

                        <Grid item md className={classes.classItemCell}>
                            <div className={classes.classItemCellTextBold}>
                                {item.evaluationStatusDate ? (
                                    <Typography variant="h2">{formatShortDate(new Date(item.evaluationStatusDate))}</Typography>
                                ) : null}
                                <Typography variant="caption">
                                    {getClassStatusDateCaption(item.evaluationStatusId as unknown as EvaluationResultStatusEnum)}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </ConditionalTooltip>
        </ConditionalLink>
    );
}

export default React.memo(TraineeClassesItem);
