import { makeStyles } from "@material-ui/core/styles";

import { SizeEnum } from "types/constants";

export default makeStyles((theme) => ({
    avatar: {
        backgroundColor: (props: { id: string }) =>
            theme.colors.avatar[parseInt(props.id[0], 16) % theme.colors.avatar.length],
    },
    avatarWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    [SizeEnum.large]: {
        height: 100,
        width: 100,
        fontSize: 45,
    },
    [SizeEnum.medium]: {
        width: 80,
        height: 80,
        fontSize: 40,
    },
    [SizeEnum.mediumSmaller]: {
        width: 40,
        height: 40,
        fontSize: 20,
    },
    [SizeEnum.mediumSmall]: {
        width: 56,
        height: 56,
        fontSize: 28,
    },
    [SizeEnum.small]: {
        width: 32,
        height: 32,
        fontSize: 16,
    },
    [SizeEnum.xs]: {
        width: 24,
        height: 24,
        fontSize: 14,
    },
}));
