import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
    CoachingReportRouteParams,
    CoachingSession,
    FcrCoachingSkillHistory,
    FcrConfiguration,
    FcrResult,
    HeaderProps,
    UserProfile,
    EmailConfiguration,
} from "types";
import { FcrStatusEnum, Routings } from "types/constants";
import {
    getFcrConfigurationForUserFcrResult,
    getFcrResultById,
    getUserSkillHistory,
} from "services/wizardServices";
import { getCoachingSession } from "services/coachingSessionServices";
import { getEmailConfigurationForCompany } from "services/emailConfiguration";
import { ConfigurationContext } from "context";

import CoachingReportViewer from "./CoachingReportViewer";
import Wizard from "./Wizard";

function CoachingReport({ isBarOpen, navBarOpen }: HeaderProps) {
    const history = useHistory();
    const { coachingSessionId } = useParams<CoachingReportRouteParams>();
    const [coachingSession, setCoachingSession] = useState<CoachingSession>();
    const [fcrResult, setFcrResult] = useState<FcrResult>();
    const [fcrConfiguration, setFcrConfiguration] = useState<FcrConfiguration>();
    const [fcrCoachingSkillsHistory, setFcrCoachingSkillsHistory] = useState<FcrCoachingSkillHistory[]>();
    const [emailConfiguration, setEmailConfiguration] = useState<EmailConfiguration>();
    const { hasCoachingReportAccess, currentUser, currentCompany } = useContext(ConfigurationContext);

    const fetchCoachingSession = useCallback(async () => {
        const session = await getCoachingSession(coachingSessionId);
        setCoachingSession(session);
        return session;
    }, [coachingSessionId]);

    useEffect(() => {
        const fetchEmailConfiguration = (companyId: string) => {
            getEmailConfigurationForCompany(companyId)
                .then(setEmailConfiguration);
        };
        currentCompany?.id && fetchEmailConfiguration(currentCompany.id);

        const fetchFcrResult = async (fcrResultId?: string) => {
            fcrResultId && setFcrResult(await getFcrResultById(fcrResultId));            
        };

        const fetchFcrConfiguration = async (user: UserProfile, fcrResultId?: string) => {
            setFcrConfiguration(undefined);
            fcrResultId && setFcrConfiguration(await getFcrConfigurationForUserFcrResult(fcrResultId, user.id));
        };

        const fetchFcrSkillHistory = async (user: UserProfile) => {
            setFcrCoachingSkillsHistory(undefined);
            setFcrCoachingSkillsHistory(await getUserSkillHistory(user!.id, true));
        };

        currentUser && fetchCoachingSession()
            .then((session) => {
                if (hasCoachingReportAccess(session)) {
                    fetchFcrResult(session.fcrResultId);
                    fetchFcrConfiguration(session.assignee, session.fcrResultId);
                    fetchFcrSkillHistory(session.assignee);
                } else {
                    history.push(Routings.dashboard);
                }
            });
    }, [currentCompany?.id, currentUser, fetchCoachingSession, hasCoachingReportAccess, history]);

    const isFcrCompleted = useMemo(
        () => coachingSession && fcrResult && (
            fcrResult.fcrResultStatusId === FcrStatusEnum.completed     // TODO: add other completed statuses
        ),
        [fcrResult, coachingSession]
    );

    return coachingSession && fcrResult && fcrConfiguration ? (
        <>
            {isFcrCompleted ? (
                <CoachingReportViewer
                    coachingSession={coachingSession}
                    fcrResult={fcrResult}
                    isBarOpen={isBarOpen}
                    navBarOpen={navBarOpen}
                    emailConfiguration={emailConfiguration}
                />
            ) : (
                <Wizard
                    fcrResult={fcrResult}
                    fcrConfiguration={fcrConfiguration}
                    fcrCoachingSkillsHistory={fcrCoachingSkillsHistory}
                    coachingSession={coachingSession}
                    fetchCoachingSession={fetchCoachingSession}
                    isBarOpen={isBarOpen}
                    navBarOpen={navBarOpen}
                />
            )}
        </>
    ) : null;
}

export default CoachingReport;

