
import * as React from "react";

export default function SvgStructure({ width = 17, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <defs>
                <rect id="path-1" x="0" y="0" width="580" height="436" rx="14"></rect>
                <filter x="-1.3%" y="-1.7%" width="102.6%" height="103.4%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.145098039   0 0 0 0 0.22745098   0 0 0 0 0.329411765  0 0 0 0.06 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g id="Company" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Company---Edit" transform="translate(-1080.000000, -691.000000)">
                    <rect fill="currentColor" x="0" y="0" width="1564" height="1080"></rect>
                    <g id="body" transform="translate(256.000000, 0.000000)">
                        <g id="levels" transform="translate(664.000000, 326.000000)">
                            <g id="Rectangle">
                                <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                                <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"></use>
                            </g>
                            <g id="Manage-Structure-cta" transform="translate(40.000000, 350.000000)">
                                <rect id="Background" stroke="currentColor" opacity="0.5" x="0.5" y="0.5" width="499" height="45" rx="6"></rect>
                                <g id="container" transform="translate(120.500000, 13.000000)" fill="currentColor">
                                    <g id="Icon" transform="translate(0.000000, 2.000000)">
                                        <path d="M9,1 C10.1045695,1 11,1.8954305 11,3 L11,5 C11,6.1045695 10.1045695,7 9,7 L8.5,7 L8.5,8.5 L12,8.5 C13.3254834,8.5 14.4100387,9.53153594 14.4946823,10.8356243 L14.5,11 L14.5010222,11.0632721 C15.3631171,11.2856488 16,12.0684484 16,13 C16,14.1045695 15.1045695,15 14,15 C12.8954305,15 12,14.1045695 12,13 C12,12.0680872 12.6373769,11.2850418 13.4999808,11.0630137 L13.5,11 C13.5,10.2203039 12.9051119,9.57955132 12.14446,9.50686658 L12,9.5 L8.5,9.5 L8.50102216,11.0632721 C9.36311708,11.2856488 10,12.0684484 10,13 C10,14.1045695 9.1045695,15 8,15 C6.8954305,15 6,14.1045695 6,13 C6,12.0680872 6.63737692,11.2850418 7.49998077,11.0630137 L7.5,9.5 L4,9.5 C3.22030388,9.5 2.57955132,10.0948881 2.50686658,10.85554 L2.5,11 L2.50102216,11.0632721 C3.36311708,11.2856488 4,12.0684484 4,13 C4,14.1045695 3.1045695,15 2,15 C0.8954305,15 0,14.1045695 0,13 C0,12.0680872 0.63737692,11.2850418 1.49998077,11.0630137 L1.5,11 C1.5,9.6745166 2.53153594,8.58996133 3.83562431,8.50531768 L4,8.5 L7.5,8.5 L7.5,7 L7,7 C5.8954305,7 5,6.1045695 5,5 L5,3 C5,1.8954305 5.8954305,1 7,1 L9,1 Z M2,12 C1.44771525,12 1,12.4477153 1,13 C1,13.5522847 1.44771525,14 2,14 C2.55228475,14 3,13.5522847 3,13 C3,12.4477153 2.55228475,12 2,12 Z M8,12 C7.44771525,12 7,12.4477153 7,13 C7,13.5522847 7.44771525,14 8,14 C8.55228475,14 9,13.5522847 9,13 C9,12.4477153 8.55228475,12 8,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 C13,13.5522847 13.4477153,14 14,14 C14.5522847,14 15,13.5522847 15,13 C15,12.4477153 14.5522847,12 14,12 Z M9,2 L7,2 C6.48716416,2 6.06449284,2.38604019 6.00672773,2.88337887 L6,3 L6,5 C6,5.51283584 6.38604019,5.93550716 6.88337887,5.99327227 L7,6 L9,6 C9.51283584,6 9.93550716,5.61395981 9.99327227,5.11662113 L10,5 L10,3 C10,2.48716416 9.61395981,2.06449284 9.11662113,2.00672773 L9,2 Z" id="Combined-Shape"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
