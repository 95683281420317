import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { UserProfile } from "types";
import { SizeEnum, USERS_FILTER_LABEL } from "types/constants";
import { getUserProfilesFullNameById, getSortedUserProfilesIds, getUserFullName } from "helpers/userProfileHelper";
import { AutocompleteInputProps, AutocompleteInputValue } from "components/Form/FormAutocomplete";
import useStyles from "components/UsersAutocomplete/styles";
import { Input } from "components/Form";
import AvatarWithScore from "components/AvatarWithScore";
import { UserIcon } from "svgComponents";

type ExcludedAutocompleteInputProps = "options" | "isPopupIconFixed" | "getOptionLabel";

export interface UseUsersAutocompleteProps<
    Multiple extends boolean | undefined,
    > extends Omit<AutocompleteInputProps<UserProfile["id"], Multiple, false, false>, ExcludedAutocompleteInputProps> {
    userProfiles: UserProfile[],
    hasAvatar?: boolean,
    allUsersOption?: string,
}

export default function useUsersAutocomplete<
    Multiple extends boolean | undefined = undefined,
    >({
    label,
    openOnFocus,
    userProfiles,
    disableCloseOnSelect,
    multiple,
    popupIcon,
    hasAvatar = false,
    allUsersOption,
    ...props
}: UseUsersAutocompleteProps<Multiple>) {
    const classes = useStyles();
    const users = useMemo(() => ({
        fullNameById: getUserProfilesFullNameById(userProfiles),
        sortedProfilesIds: [...(allUsersOption ? [""] : []), ...getSortedUserProfilesIds(userProfiles)],
    }), [userProfiles, allUsersOption]);

    const getOptionLabel = (userProfileId: UserProfile["id"]) => userProfileId
        ? (users.fullNameById.get(userProfileId) ?? "")
        : (allUsersOption ?? "");

    const avatarProps = hasAvatar ? {
        renderInput: (inputProps: AutocompleteRenderInputParams, userId?: AutocompleteInputValue<UserProfile["id"], Multiple, false, false>) => {
            const user = userProfiles.find((user) => user.id === userId);
            const input = <Input {...inputProps} />;

            if (!user) return input;

            return (
                <Box className={classes.avatarRoot}>
                    <AvatarWithScore className={classes.avatar} user={user} size={SizeEnum.xs} />
                    {input}
                </Box>
            );
        },
        renderOption: (userId: UserProfile["id"]) => {
            const user = userProfiles.find((user) => user.id === userId);

            if (!user) return null;

            return (
                <Box display="flex" alignItems="center">
                    <AvatarWithScore user={user} size={SizeEnum.xs} />
                    <Box ml={1.5}>
                        <Typography variant="h3">
                            {getUserFullName(user)}
                        </Typography>
                    </Box>
                </Box>
            );
        },
    } : {};

    return {
        ...props,
        multiple,
        getOptionLabel: getOptionLabel,
        options: users.sortedProfilesIds,
        isPopupIconFixed: true,
        popupIcon: popupIcon ?? <UserIcon height={22} width={22} />,
        label: label ?? USERS_FILTER_LABEL,
        openOnFocus: openOnFocus ?? true,
        disableCloseOnSelect: disableCloseOnSelect ?? (multiple ?? false),
        ...avatarProps,
    };
}