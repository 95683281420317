import React from "react";
import { useTheme, Box } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Settings } from "svgComponents";
import { reorder } from "helpers/arrayHelpers";
import AdminListItem from "components/AdminListItem";
import { EvaluationItemConfiguration } from "types/Certification";

export interface DragAndDropListProps {
    evaluationInUse: boolean;
    evaluationItems: EvaluationItemConfiguration[];
    isLoading?: boolean;
    onEdit: (evaluationItem: EvaluationItemConfiguration) => void;
    onDelete: (evaluationItemId: EvaluationItemConfiguration["id"]) => Promise<unknown>;
    onReordered: (evaluationItems: EvaluationItemConfiguration[]) => void;
}

function EvaluationItemDragAndDropList({
    evaluationInUse,
    evaluationItems,
    isLoading,
    onEdit,
    onDelete,
    onReordered,
}: DragAndDropListProps) {
    const theme = useTheme();
    const handleDragEnd = (result: DropResult) => {
        const { destination, source } = result;
        if (!destination || destination.index === source.index) {
            return;
        }
        const reorderedEvaluationItems = reorder(evaluationItems, source.index, destination.index).map((item, i) => ({
            ...item,
            order: i,
        }));
        onReordered(reorderedEvaluationItems);
    };

    const handleDelete = (e: React.SyntheticEvent, evaluationItem: EvaluationItemConfiguration) => {
        e.stopPropagation();
        onDelete(evaluationItem.id);
    };

    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="evaluationItems">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {evaluationItems.map((item, idx) => (
                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                            <Box
                                                borderTop={idx > 0 ? `1px solid ${theme.colors.structure.rulesAndBorders}` : null}
                                                bgcolor={snapshot.isDragging ? theme.colors.structure.pageBackground : null}>
                                                <AdminListItem
                                                    description={item.elementBody}
                                                    icon={<Settings />}
                                                    loading={isLoading}
                                                    title={item.elementHeader}
                                                    onEdit={() => onEdit(item)}
                                                    onClick={() => onEdit(item)}
                                                    dragHandleProps={provided.dragHandleProps}
                                                    isDragging={snapshot.isDragging}
                                                />
                                            </Box>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

export default EvaluationItemDragAndDropList;
