
import * as React from "react";

export default function SvgSpinner({ height = 16, width = 16, ...props }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={height}
            fill="none"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="22 22 44 44"
            {...props}
        >
            <circle
                cx="44"
                cy="44"
                r="20.2"
                fill="none"
                strokeWidth="3.6"
                stroke="currentColor"
            ></circle>
        </svg>
    )
}
