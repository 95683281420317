import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    filter: {
        alignSelf: "stretch",
        "& .MuiSelect-root": {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            paddingRight: theme.spacing(6),
        },
        "& .MuiSelect-icon": {
            height: 24,
            marginRight: theme.spacing(1),
        },
    },
    filtersContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexWrap: "wrap",
        gap: theme.spacing(2),
    },
}));

export default useStyles;
