import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    background: {
        backgroundColor: theme.colors.structure.pageBackground,
    },
    inputWrapper: {
        borderRadius: "6px",
    },
    input: {
        paddingTop: "16px",
        paddingBottom: "16px",
    },
    button: {
        paddingTop: "12px",
        paddingBottom: "12px",
    },
}));

export default useStyles;
