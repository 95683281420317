import React, { useContext, useEffect, useMemo } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import NoDataMessage from "components/NoDataMessage";
import { Link } from "react-router-dom";
import sortBy from "lodash/sortBy";
import find from "lodash/find";
import { getTeamOverviewForHighLevelLead } from "services/historyService";
import { getTeamByMemberId, getTeamByLeaderId } from "services/teamServices";
import { getTeamUserProfiles } from "services/userServices";
import { isHighLevel } from "helpers/companyHelper";
import { Team, UserProfile, UserProfileOverview } from "types";
import useAsync, { PromiseStatus } from "hooks/useAsync";
import { ConfigurationContext } from "context";

import useStyles from "./styles";
import TeamOverviewMember from "./TeamOverviewMember";

interface TeamOverviewProps {
    user: UserProfile;
    teamMode: boolean;
    onDataIsFetched: () => void;
}

function TeamOverview({ user, teamMode, onDataIsFetched }: TeamOverviewProps) {
    const classes = useStyles();
    const {  companyLevels } = useContext(ConfigurationContext);
    const { status: teamFetchStatus, value: team } = useAsync<Team | null>(() => {
        if (!user.id) return Promise.resolve(null);
        
        return user.isALeader || user.isACoLeader ? (
            getTeamByLeaderId(user.companyId, user.id).then((teams) => teams[0] ?? null)
        ) : (
            getTeamByMemberId(user.id)
        );
    }, [user.id]);
    const { status: teamMembersFetchStatus, value: teamMembers } = useAsync<UserProfile[] | null>(() => (
        (team?.id) ? getTeamUserProfiles(team.id) : Promise.resolve(null)
    ), [team?.id]);
    const isHighLevelTeamMode = teamMode && isHighLevel(user.level, companyLevels);
    const { status: teamOverviewFetchStatus, value: teamOverview } = useAsync<UserProfileOverview[] | null>(() => (
        (user.id && isHighLevelTeamMode) ? getTeamOverviewForHighLevelLead(user.id) : Promise.resolve(null)
    ), [user.id, isHighLevelTeamMode]);
    const sortedTeamMembers = useMemo(() => sortBy(teamMembers, ["lastName"]), [teamMembers]);
    const isFetching = (
        !companyLevels.length ||
        (teamFetchStatus === PromiseStatus.PENDING) ||
        (teamMembersFetchStatus === PromiseStatus.PENDING) ||
        (teamOverviewFetchStatus === PromiseStatus.PENDING)
    );

    useEffect(() => {
        !isFetching && team && onDataIsFetched();
    }, [isFetching, team, onDataIsFetched]);

    const renderMembers = () => {
        if (isFetching) return (
            <Box display="flex" justifyContent="center" px={4} pt={3.5} pb={5}>
                <CircularProgress size={24} />
            </Box>
        );

        if (!sortedTeamMembers.length) return (
            <Box mt={5}>
                <NoDataMessage />
            </Box>
        );

        return (
            <Box className={classes.memberList} mt={5} display="grid">
                {sortedTeamMembers.map(member => (
                    <Box key={member.id}>
                        <Link className={classes.link} to={`/team-profile/${member.id}`}>
                            <TeamOverviewMember isHighLevelTeamMode={isHighLevelTeamMode} leader={user} user={member} overview={isHighLevelTeamMode ? find(teamOverview, ["userId", member.id]) : null} />
                        </Link>
                    </Box>
                ))}
            </Box>
        );
    };

    return (
        <Box pb={8} pt={6} component="section">
            <Typography variant="h1">Team Overview</Typography>
            {renderMembers()}
        </Box>
    )
}

export default React.memo(TeamOverview);
