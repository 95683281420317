import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
    alertAction: {
        display: "block",
    },
}), {
    name: "ImportResult",
});

export default useStyles;