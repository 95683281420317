import { BoxProps, Tooltip, useTheme } from "@material-ui/core";
import { ConfigurationContext } from "context";
import React, { useContext, useMemo } from "react";
import { BubbleWithCheckmark, BubbleWithCheckmarkRedDot, Checkmark, ReadLetter } from "svgComponents";
import { FcrResult } from "types";
import { CoachingPlanStatusEnum, EvaluationResultStatusEnum, FcrStateEnum, StatusEnum } from "types/constants";

import useStyles from "./styles";

interface StatusLabelProps extends BoxProps {
    status: StatusEnum | CoachingPlanStatusEnum | FcrStateEnum | EvaluationResultStatusEnum;
    fcrResult?: FcrResult;
    darkBackground?: boolean;
}

interface StatusData {
    color: string;
    text: string | React.ReactNode;
    bgcolor?: string;
    tooltip?: string;
}

const StatusLabel = ({ status, fcrResult, darkBackground = false, ...props }: StatusLabelProps) => {
    const theme = useTheme();
    const classes = useStyles();
    const { currentUser } = useContext(ConfigurationContext);

    const fcrStatusData = useMemo((): StatusData => {
        if (fcrResult?.assigneeId === currentUser?.id) {
            if (status !== FcrStateEnum.draft && fcrResult?.submittedByCoacheeDate) {
                return {
                    color: theme.palette.primary.light,
                    text: "Submitted",
                };
            }
            if (fcrResult?.assigneeId === fcrResult?.createdById && !fcrResult?.submittedByCoacheeDate) {
                return {
                    color: theme.colors.statusLabel.text.gray,
                    text: "Draft",
                };
            }
            return {
                bgcolor: theme.colors.statusLabel.background.green,
                color: theme.colors.statusLabel.text.gray,
                text: "New",
            };
        }

        if (fcrResult?.coachId === currentUser?.id) {
            if (status !== FcrStateEnum.draft && fcrResult?.submittedByCoacheeDate) {
                return {
                    bgcolor: theme.colors.statusLabel.background.green,
                    color: theme.colors.statusLabel.text.gray,
                    text: "New",
                };
            }

            return {
                color: theme.colors.statusLabel.text.gray,
                text: "Draft",
            };
        }

        return {
            bgcolor: theme.colors.statusLabel.background.green,
            color: theme.colors.statusLabel.text.gray,
            text: "New",
        };
    }, [fcrResult, currentUser, status, theme]);

    const { color, text, bgcolor, tooltip } = useMemo((): StatusData => {
        switch (status) {
            case FcrStateEnum.new:
            case FcrStateEnum.draft:
                return fcrStatusData;
            case StatusEnum.completed:
            case StatusEnum.inProgress:
            case FcrStateEnum.completed:
            case CoachingPlanStatusEnum.completed:
                return {
                    color: theme.palette.primary.light,
                    text: "Completed",
                    tooltip: "Unopened by Coachee",
                };
            case CoachingPlanStatusEnum.inProgress:
                return {
                    color: theme.palette.primary.light,
                    text: "In Progress",
                };
            case FcrStateEnum.readByCoachee:
                return {
                    color: theme.palette.primary.light,
                    text: (
                        <div className={classes.label}>
                            <ReadLetter />
                            <div className={classes.labelStatus}>Completed</div>
                        </div>
                    ),
                    tooltip: "Opened by Coachee",
                };
            case FcrStateEnum.readByCoach:
                return {
                    color: theme.palette.primary.light,
                    text: (
                        <div className={classes.label}>
                            <BubbleWithCheckmark />
                            <div className={classes.labelStatus}>Completed</div>
                        </div>
                    ),
                    tooltip: "Commented and acknowledged by Coachee and read by the Coach",
                };
            case FcrStateEnum.acknowledged:
                return {
                    color: theme.palette.primary.light,
                    text: (
                        <div className={classes.label}>
                            <Checkmark />
                            <div className={classes.labelStatus}>Completed</div>
                        </div>
                    ),
                    tooltip: "Opened and acknowledged by Coachee",
                };
            case FcrStateEnum.acknowledgedWithComment:
                return {
                    color: theme.palette.primary.light,
                    text: (
                        <div className={classes.label}>
                            <BubbleWithCheckmarkRedDot />
                            <div className={classes.labelStatus}>Completed</div>
                        </div>
                    ),
                    tooltip: "Commented and acknowledged by Coachee, but the comment has not been read by the Coach",
                };
            case StatusEnum.assigned:
            case CoachingPlanStatusEnum.assigned:
                return {
                    color: theme.palette.text.secondary,
                    text: "Assigned",
                };
            case EvaluationResultStatusEnum.new:
                return {
                    bgcolor: theme.colors.statusLabel.background.green,
                    color: theme.colors.statusLabel.text.gray,
                    text: "New",
                };
            case EvaluationResultStatusEnum.inProgress:
                return {
                    color: theme.palette.primary.light,
                    text: "In Progress",
                };
            case EvaluationResultStatusEnum.completed:
                return {
                    color: theme.palette.primary.light,
                    text: "Completed",
                };
            default:
                return {
                    color: theme.palette.text.secondary,
                    text: "?",
                };
        }
    }, [status, fcrStatusData, theme.palette, theme.colors.statusLabel, classes]);

    return (
        <Tooltip arrow title={tooltip ?? ""}>
            <div
                className={classes.labelWrap}
                style={{
                    backgroundColor: bgcolor || (darkBackground ? theme.palette.info.dark : theme.palette.info.main),
                    color: color,
                    ...props,
                }}>
                {text}
            </div>
        </Tooltip>
    );
};

export default StatusLabel;
