import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    barHigh: {
        background: theme.colors.graph.high,
    },
    barMedium: {
        background: theme.colors.graph.medium,
    },
    barLow: {
        background: theme.colors.graph.low,
    },
    barLowest: {
        background: theme.colors.graph.lowest,
    },
    tooltip: {
        fontSize: "40px",
        fontWeight: 600,
        color: theme.colors.text.darkBlue,
        backgroundColor: "unset",
        lineHeight: "57px",
        left: `calc(-50% + ${theme.spacing(0.5)}px)`,
        "& *": {
            background: "transparent",
            color: theme.colors.text.darkBlue,
        },
    },
    thumb: {
        marginLeft: "-10px",
    },
    disabledThumb: {
        height: 0,
    },
    mark: {
        display: "none",
    },
    markLabel: {
        fontSize: "12px",
        marginTop: "15px",
    },
}));
