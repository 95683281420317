import React, { useCallback, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, Button, CircularProgress, Dialog, TextField, Typography } from "@material-ui/core";

import SquareIconButton from "components/SquareIconButton";
import Checkmark from "svgComponents/Checkmark";
import DeleteIcon from "svgComponents/DeleteIcon";
import { updateResourceCategory } from "services/resourceServices";
import { FcrResourceCategory } from "types";
import { CompaniesContext } from "context";

interface EditCategoryProps {
    category?: FcrResourceCategory;
    nextOrder?: number;
    isOpen: boolean;
    onSubmit: (category: FcrResourceCategory) => void;
    onClose: () => void;
    onDelete?: () => void;
}

const EditCategory = ({ category, nextOrder, isOpen, onSubmit, onClose, onDelete }: EditCategoryProps) => {
    const { currentCompany } = useContext(CompaniesContext);
    const [categoryName, setCategoryName] = useState(category ? category.value : "");
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = useCallback((event) => {
        setCategoryName(event.target.value);
    }, []);

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        onSubmit(await updateResourceCategory({
            id: category ? category.id : uuidv4(),
            value: categoryName,
            companyId: currentCompany!.id,
            order: category ? category.order : (nextOrder ?? 0),
        }));
        setIsLoading(false);
        onClose();
    }, [onSubmit, category, nextOrder, categoryName, currentCompany, onClose]);
    
    const handleDelete = useCallback(() => {
        onDelete && onDelete();
        onClose();
    }, [onClose, onDelete]);

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <Box p={4}>
                <Typography variant="h1" component="span">
                    {category ? "Edit Category" : "Create New Category"}
                </Typography>
            </Box>
            <Box py={3} px={4} bgcolor="#F6F8F9">
                <Box bgcolor="common.white" borderRadius={6}>
                    <TextField
                        variant="outlined"
                        label="Category name"
                        fullWidth
                        value={categoryName}
                        onChange={handleChange}
                    />
                </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" p={4}>
                {category && (
                    <Box display="flex" mr={5}>
                        <SquareIconButton variant="outlined" size="large" onClick={handleDelete}>
                            <DeleteIcon width={20} height={20} viewBox="0 0 16 16"/>
                        </SquareIconButton>
                    </Box>
                )}
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={category && !isLoading && <Checkmark/>}
                        onClick={handleSubmit}
                        disabled={isLoading || !categoryName}
                        disableElevation
                    >
                        {isLoading && <CircularProgress size={24}/>}
                        {!isLoading && (
                            category ? "Save" : "Create"
                        )}
                    </Button>
                </Box>
                <Box ml={2.5}>
                    <Button variant="outlined" size="large" onClick={onClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default EditCategory;