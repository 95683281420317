import React, { useCallback } from "react";
import { Box, CircularProgress, MenuItem, Select } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { CoachingSessionType } from "types";
import CoachingSessionName from "components/CoachingSessionName";

import useStyles from "./styles";

interface CoachingSessionTypeSelectProps {
    value: string;
    onChange: (id: string) => void;
    coachingSessionTypes: CoachingSessionType[] | null;
}

const Loader = () => <Box mr={1.5}><CircularProgress size={24} /></Box>;

const CoachingSessionTypeSelect = ({ value, onChange, coachingSessionTypes }: CoachingSessionTypeSelectProps) => {
    const classes = useStyles();

    const handleChange = useCallback((event) => onChange(event.target.value), [onChange]);

    return (
        <Select
            variant="outlined"
            value={value}
            onChange={handleChange}
            fullWidth
            IconComponent={coachingSessionTypes ? ExpandMore : Loader}
            className={classes.select}
        >
            {coachingSessionTypes && coachingSessionTypes.map((coachingSessionType) => (
                <MenuItem key={coachingSessionType.id} value={coachingSessionType.id}>
                    <CoachingSessionName
                        coachingSessionType={coachingSessionType}
                        encircledIcon
                    />
                </MenuItem>
            ))}
        </Select>
    );
}

export default CoachingSessionTypeSelect;