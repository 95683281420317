import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { orderBy } from "lodash";
import { CompaniesContext } from "context";
import { Company, FcrCoachingSkill, CompanyLevel, UserProfile, Team, TreeSet, CompanyLevelLabel, FcrSectionConfiguration } from "types";

import { getFcrCoachingSkillsForCompany } from "services/fcrCoachingSkillServices";
import {
    getAllCompanies,
    getCompanyLevelLabels,
    getCertificationSectionConfiguration,
    getCompanyLevelsTree,
    getCompanyFcrSectionConfiguration,
} from "services/companyServices";
import { getUsersForCompany, getLeadersForCompany } from "services/userServices";
import { getAllCompanyTeams } from "services/teamServices";
import { getCompany } from "services/wizardServices";
import { getCurrentUserProfile } from "services/profileServices";
import { AdminRoutings, LOCAL_STORAGE_LAST_SELECTED_COMPANY } from "types/constants";
import { getCompanyByIdWithFirstByDefault } from "helpers/companyHelper";
import { AppDialogProps, initialAppDialogProps } from "routes/MainComponent/AppDialog";
import { CertificationSectionConfiguration } from "types/Certification";

interface ProviderProps {
    children: React.ReactNode;
}

const AdminProvider = ({ children }: ProviderProps) => {
    const history = useHistory();
    const [currentUser, setCurrentUser] = useState<UserProfile>();
    const [isFetchingCompanyData, setIsFetchingCompanyData] = useState<boolean>(false);
    const [allCompanies, setAllCompanies] = useState<Company[]>([]);
    const [currentCompany, setCurrentCompanyState] = useState<Company | null>(null);
    const [fcrCoachingSkillsForCompany, setFcrCoachingSkillsForCompany] = useState<FcrCoachingSkill[]>([]);
    const [companyUsers, setCompanyUsers] = useState<UserProfile[]>([]);
    const [isFetchingCompanyUsers, setCompanyUsersFetching] = useState<boolean>(false);
    const [companyLeaders, setCompanyLeaders] = useState<UserProfile[]>([]);
    const [companyTeams, setCompanyTeams] = useState<Team[]>([]);
    const [companyLevelsTree, setCompanyLevelsTree] = useState<TreeSet<CompanyLevel> | null>(null);
    const [companyLevelLabels, setCompanyLevelLabels] = useState<CompanyLevelLabel[]>([]);
    const [companyCertificationConfiguration, setcompanyCertificationConfiguration] = useState<CertificationSectionConfiguration | null>(
        null
    );
    const [fcrSectionConfiguration, setFcrSectionConfiguration] = useState<FcrSectionConfiguration | null>(null);
    const onBeforeCompanyChange = useRef<(() => boolean) | undefined>();
    const isCompanyPage = useRouteMatch(AdminRoutings.company);
    const isCompaniesPage = useRouteMatch(AdminRoutings.companies);
    const [appDialogProps, _setAppDialogProps] = useState<AppDialogProps>(initialAppDialogProps);

    const setOnBeforeCompanyChange = (callback: (() => boolean) | undefined) => {
        onBeforeCompanyChange.current = callback;
    };

    const setCurrentCompanyStateWithLocalStorage = useCallback((company: Company | null) => {
        setCurrentCompanyState(company);
        if (company) {
            localStorage.setItem(LOCAL_STORAGE_LAST_SELECTED_COMPANY, company.id);
        } else {
            localStorage.removeItem(LOCAL_STORAGE_LAST_SELECTED_COMPANY);
        }
    }, []);

    const setCurrentCompany = useCallback(
        (company: Company | null) => {
            if (typeof onBeforeCompanyChange.current === "function") {
                if (onBeforeCompanyChange.current()) {
                    setCurrentCompanyStateWithLocalStorage(company);
                }
            } else {
                setCurrentCompanyStateWithLocalStorage(company);
            }
        },
        [setCurrentCompanyStateWithLocalStorage]
    );

    const sortCompanies = useCallback(
        (companies: Company[]) => orderBy(companies, ["isActive", (company: Company) => company.name.toLowerCase()], ["desc", "asc"]),
        []
    );

    const handleCompanyUpdated = useCallback(
        async (companyId: string) => {
            const updatedCompany = await getCompany(companyId);

            if (updatedCompany) {
                const updatedAllCompanies = allCompanies.map((company) => (company.id === updatedCompany.id ? updatedCompany : company));

                setAllCompanies(sortCompanies(updatedAllCompanies));
                setCurrentCompanyStateWithLocalStorage(updatedCompany);
            }
        },
        [allCompanies, sortCompanies, setCurrentCompanyStateWithLocalStorage]
    );

    const getAllCompaniesList = useCallback(
        async (currentCompanyId?: string) => {
            const companies = sortCompanies(await getAllCompanies());
            setAllCompanies(companies);

            if (companies.length) {
                const companyId = currentCompanyId || localStorage.getItem(LOCAL_STORAGE_LAST_SELECTED_COMPANY);
                const currentCompany = getCompanyByIdWithFirstByDefault(companies, companyId);

                setCurrentCompanyStateWithLocalStorage(currentCompany || null);
            }
        },
        [sortCompanies, setCurrentCompanyStateWithLocalStorage]
    );

    const companyLevels = useMemo(() => {
        const levels: CompanyLevel[] = [];

        if (!companyLevelsTree) return levels;

        (function populateLevels(levelTreeItem: TreeSet) {
            levels.push(levelTreeItem.value);
            if (levelTreeItem.nodes.length > 0) {
                levelTreeItem.nodes.forEach(populateLevels);
            }
        })(companyLevelsTree);

        return levels;
    }, [companyLevelsTree]);

    const fetchCompanyUsers = useCallback(() => {
        if (currentCompany?.id) {
            setCompanyUsersFetching(true);
            return getUsersForCompany(currentCompany.id)
                .then(setCompanyUsers)
                .finally(() => setCompanyUsersFetching(false));
        }
        return Promise.resolve();
    }, [currentCompany?.id]);

    const setAppDialogProps = useCallback(
        (props) => {
            _setAppDialogProps({ ...appDialogProps, ...props });
        },
        [appDialogProps]
    );

    useEffect(() => {
        if (currentCompany && !currentCompany.isActive && !isCompaniesPage && !isCompanyPage) {
            history.push(AdminRoutings.company);
        }
    }, [currentCompany, isCompaniesPage, isCompanyPage, history]);

    useEffect(() => {
        getCurrentUserProfile().then(setCurrentUser);
    }, []);

    useEffect(() => {
        !allCompanies.length && getAllCompaniesList();
    }, [allCompanies, getAllCompaniesList]);

    useEffect(() => {
        if (!currentCompany?.id) {
            setFcrCoachingSkillsForCompany([]);
            setCompanyUsers([]);
            setCompanyLeaders([]);
            setCompanyTeams([]);
            setCompanyLevelsTree(null);
            setCompanyLevelLabels([]);
            setcompanyCertificationConfiguration(null);
            setFcrSectionConfiguration(null);
            return;
        }

        const fetchCompanyData = () => {
            setIsFetchingCompanyData(true);

            Promise.all([
                getFcrCoachingSkillsForCompany(currentCompany.id).then(setFcrCoachingSkillsForCompany),
                fetchCompanyUsers(),
                getLeadersForCompany(currentCompany.id).then(setCompanyLeaders),
                getAllCompanyTeams(currentCompany.id).then(setCompanyTeams),
                getCompanyLevelsTree(currentCompany.id).then(setCompanyLevelsTree),
                getCompanyLevelLabels(currentCompany.id).then(setCompanyLevelLabels),
                getCertificationSectionConfiguration(currentCompany.id).then(setcompanyCertificationConfiguration),
                getCompanyFcrSectionConfiguration(currentCompany.id).then(setFcrSectionConfiguration),
            ]).finally(() => {
                setIsFetchingCompanyData(false);
            });
        };

        fetchCompanyData();
    }, [currentCompany?.id, fetchCompanyUsers]);

    useEffect(() => {
        if (!currentCompany && !isCompanyPage && allCompanies.length) {
            const companyId = localStorage.getItem(LOCAL_STORAGE_LAST_SELECTED_COMPANY);
            const currentCompany = getCompanyByIdWithFirstByDefault(allCompanies, companyId);

            setCurrentCompanyStateWithLocalStorage(currentCompany);
        }
    }, [currentCompany, setCurrentCompanyStateWithLocalStorage, isCompanyPage, allCompanies]);

    return (
        <CompaniesContext.Provider
            value={{
                currentUser,
                isFetchingCompanyData,
                currentCompany,
                setCurrentCompany,
                allCompanies,
                getAllCompaniesList,
                handleCompanyUpdated,
                fcrCoachingSkillsForCompany,
                setFcrCoachingSkillsForCompany,
                companyLevels,
                companyUsers,
                fetchCompanyUsers,
                isFetchingCompanyUsers,
                companyLeaders,
                companyTeams,
                setCompanyTeams,
                companyLevelsTree,
                setCompanyLevelsTree,
                companyLevelLabels,
                setCompanyLevelLabels,
                companyCertificationConfiguration,
                fcrSectionConfiguration,
                setFcrSectionConfiguration,
                setOnBeforeCompanyChange,
                appDialogProps,
                setAppDialogProps,
            }}>
            {children}
        </CompaniesContext.Provider>
    );
};

export default AdminProvider;
