import React from "react";
import { Box, Typography } from "@material-ui/core";

import LabelScoreBar from "components/LabelScoreBar";
import SvgCalendar from "svgComponents/Calendar";
import { FcrCoachingSkillHistory } from "types";
import { SizeEnum } from "types/constants";
import { getFcrRatingDescription } from "helpers/fcrHelper";

import useStyles from "./styles";

interface TeamProfileSkillProps {
    skill: FcrCoachingSkillHistory;
}

const TeamProfileSkill = ({ skill }: TeamProfileSkillProps) => {
    const classes = useStyles();
    const ratingDescription = getFcrRatingDescription(skill?.fcrCoachingSkill, skill.averageScore)

    return (
        <Box display="flex" height={1}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                px={{ xs: 3, md: 5 }}
                py={{ xs: 2, md: 4 }}
                mb={1}
                width={1}
            >
                <Box mb={3} className={classes.skillName}>
                    <Typography variant="h1" component="span">
                        {skill.fcrCoachingSkill.name}
                    </Typography>
                </Box>
                <Box>
                    <Box mb={4}>
                        <LabelScoreBar
                            label="Average Score"
                            score={skill.timesCoached ? skill.averageScore : undefined}
                            size={SizeEnum.medium}
                            minRating={skill.fcrCoachingSkill.minRating}
                            maxRating={skill.fcrCoachingSkill.maxRating}
                            scoreDescription={ratingDescription?.title}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center" color="primary.main">
                            <Box mr={1.5}>
                                <Typography variant="h6" component="span" color="primary">
                                    {skill.timesCoached || 0}
                                </Typography>
                            </Box>
                            <SvgCalendar height={20} width={20} viewBox="0 0 16 16"/>
                        </Box>
                        <Typography variant="caption">
                            Times Coached
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default TeamProfileSkill;