import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    content: {
        transition: theme.transitions.create("margin", {
            duration: 500,
        }),
    },
    sidebarCollapsed: {
        [theme.breakpoints.up("xs")]: {
            marginLeft: "-326px",
        },
    },
}));

export default useStyles;
