import React, { useCallback } from "react";
import { Box, InputAdornment } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";

import SvgCalendar from "svgComponents/Calendar";

import useStyles from "./styles";

interface CoachingSessionDatePickerProps {
    value: Date,
    onChange: (date: Date) => void;
}

const CoachingSessionDatePicker = ({ value, onChange }: CoachingSessionDatePickerProps) => {
    const classes = useStyles();

    const handleChange = useCallback((date) => onChange(date.toDate()), [onChange]);

    return (
        <MuiPickersUtilsProvider utils={DayJsUtils}>
            <DatePicker
                value={value}
                onChange={handleChange}
                variant="inline"
                inputVariant="outlined"
                format="MMMM D, YYYY"
                fullWidth
                autoOk
                className={classes.picker}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height={32}
                                width={32}
                                borderRadius="50%"
                                bgcolor="#F6F8F9"
                                mr={0.5}
                            >
                                <SvgCalendar/>
                            </Box>
                        </InputAdornment>
                    ),
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default CoachingSessionDatePicker;