import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Paper } from "@material-ui/core";

import Header from "components/Header";
import { HeaderProps, TeamProfileRouteParams, UserProfile } from "types";
import { getUserProfile } from "services/profileServices";
import { ConfigurationContext } from "context";

import TeamProfile from "./TeamProfile";

const TeamProfileWrapper = ({ isBarOpen, navBarOpen }: HeaderProps) => {
    const { currentUser } = useContext(ConfigurationContext);
    const { userId } = useParams<TeamProfileRouteParams>();
    const [user, setUser] = useState<UserProfile | null | undefined>();

    useEffect(() => {
        const fetchUser = async () => {
            const id = userId ? userId : currentUser!.id;
            setUser(undefined);
            setUser(await getUserProfile(id).catch(() => null));
        }

        currentUser && fetchUser();
    }, [currentUser, userId]);

    return (
        <>
            <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            {user ? (
                <TeamProfile user={user} />
            ) : (
                <Box display="flex" flexDirection="column" p={4}>
                    {user === null ? (
                        <Paper>
                            <Box p={4}>
                                This user does not exist
                            </Box>
                        </Paper>
                    ) : (
                        <Box m="auto">
                            <CircularProgress size={40}/>
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
}

export default TeamProfileWrapper;