import React, { useEffect, useState, useContext, useCallback, useMemo, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, Paper, Divider, Typography, CircularProgress } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import orderBy from "lodash/orderBy";

import NoDataMessage from "components/NoDataMessage";
import CoachingReportItem from "components/CoachingReportItem";
import { getCoachingSessionsForCoachAndAssignee, getCoachingSessionsForAssignee } from "services/coachingSessionServices";
import { CoachingSession, TeamProfileRouteParams } from "types";
import { Routings } from "types/constants";
import { ConfigurationContext } from "context";
import useScrollToItem from "hooks/useScrollToItem";

import BackToProfile from "../../BackToProfile";

const STEP_SIZE = 50;

const coachingReportsFilters = {
    userId: null,
    status: null,
    orderDescending: true,
    returnStarted: true,
    getOnlyUpcoming: false,
    getOnlyIncomplete: false,
    state: null,
    excludeAssigneesMarkedFinishLater: false,
}

function CoachingReportHistory() {
    const history = useHistory();
    const itemsRef = useRef<HTMLDivElement>(null);
    const { userId } = useParams<TeamProfileRouteParams>();
    const { currentUser, hasCoachingReportAccess } = useContext(ConfigurationContext);
    const [reports, setReports] = useState<CoachingSession[]>([]);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [shownCount, setShownCount] = useState(STEP_SIZE);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowMore, setIsShowMore] = useState(true);
    const shownReports = useMemo(() => reports.slice(0, shownCount), [reports, shownCount]);

    useScrollToItem(reports || [], "sessionId", itemsRef, shownCount, setShownCount);

    const fetchSessions = useCallback(async (pageNumber: number) => {
        if (!currentUser) return;

        const coachId = currentUser.id;
        const assigneeId = userId!;
        setIsLoading(true);

        const coachingSessions = assigneeId && coachId !== assigneeId ?
            await (await getCoachingSessionsForCoachAndAssignee(coachId, assigneeId, pageNumber, coachingReportsFilters)) :
            await (await getCoachingSessionsForAssignee(coachId, pageNumber, coachingReportsFilters));

        setReports(orderBy((reports?.length ? [...reports, ...coachingSessions.results] : coachingSessions.results), "scheduleStartDate", "desc"));
        !reports?.length && setTotalAmount(coachingSessions?.totalFilteredAmount || 0);
        setIsLoading(false);
    }, [currentUser, reports, userId]);

    useEffect(() => {
        if (!reports?.length) fetchSessions(page);
    }, [currentUser, fetchSessions, page, reports?.length, userId]);

    const handleClick = useCallback((coachingSession: CoachingSession) => {
        history.push(
            `${Routings.fcr}/${coachingSession.id}`,
            { backPath: history.location.pathname, backText: "Back to Reports" }
        );
    }, [history]);

    const onShowMore = useCallback(() => {
        setPage(page + 1)
        setShownCount(shownCount + STEP_SIZE)
        fetchSessions(page + 1)
    }, [fetchSessions, page, shownCount]);

    useEffect(() => {
        if (shownReports.length !== totalAmount) {
            setIsShowMore(true)
        } else {
            setIsShowMore(false)
        }
    }, [shownReports.length, totalAmount]);

    return (
        <>
            <BackToProfile userId={userId} />
            {reports.length ?
                <Paper>
                    <InfiniteScroll
                        scrollableTarget="app"
                        dataLength={shownReports.length}
                        next={onShowMore}
                        hasMore={isShowMore && totalAmount > shownCount}
                        loader={isShowMore ? <Box p={4} textAlign="center">
                            <CircularProgress size={40} />
                        </Box> : null}
                        hasChildren
                    >
                        <Box px={5} pt={2.5} pb={2} fontWeight={600}>
                            <Typography variant="h2">All Coaching Reports</Typography>
                        </Box>
                        <Divider />
                        <Box px={5} py={2}>
                            <Typography variant="caption">{`${shownReports.length} ${isShowMore ? `from ${totalAmount} ` : ""}items`}</Typography>
                        </Box>
                        <Divider />
                        <Box display="flex" flexDirection="column" {...{ ref: itemsRef }}>
                            {shownReports.map(report => (
                                <Box key={report.id}>
                                    <Box p={1}>
                                        <CoachingReportItem
                                            session={report}
                                            onClick={handleClick}
                                            hasAccess={hasCoachingReportAccess(report)}
                                        />
                                    </Box>
                                    <Divider />
                                </Box>
                            ))}
                        </Box>
                    </InfiniteScroll>
                </Paper> :
                <Box display="flex" justifyContent="center" pt={12}>
                    {isLoading ?
                        <CircularProgress size={60} /> :
                        <NoDataMessage message="No Data" />
                    }
                </Box>
            }
        </>
    )
}

export default CoachingReportHistory;
