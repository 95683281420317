import React from "react";
import SvgUpArrow from "svgComponents/UpArrow";
import clsx from "clsx";

import useStyles from "./styles";

const ArrowIcon = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
    const classes = useStyles()

    return <SvgUpArrow className={clsx(classes.root, className)} {...props} />
} 

export default ArrowIcon;
