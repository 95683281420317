import { v4 as uuidv4 } from "uuid";
import { FcrResource, FcrResourceCategory } from "types";
import { ResourceTypeEnum } from "types/constants";

import { toBase64 } from "./fileHelper";

export const getResourceFormData = (
    resource: FcrResource | null,
    userId: string,
    companyId: string,
    resourceCategoryId: string
): FcrResource => ({
    id: resource?.id ?? uuidv4(),
    type: resource?.type ?? ResourceTypeEnum.document,
    name: resource?.name ?? "",
    description: resource?.description ?? "",
    contentType: resource?.contentType ?? undefined,
    url: resource?.url ?? "",
    documentBytes: resource?.documentBytes ?? undefined,
    documentFilename: resource?.documentFilename ?? "",
    companyId: resource?.companyId ?? companyId,
    createdById: resource?.createdById ?? userId,
    createdDate: resource?.createdDate ?? (new Date).toISOString(),
    modifiedById: userId,
    modifiedDate: (new Date).toISOString(),
    resourceCategoryId: resource?.resourceCategoryId ?? resourceCategoryId,
    resourceCategory: resource?.resourceCategory ?? undefined,
    isActive: resource?.isActive ?? true,
});

export const getResourceFromFile = async (file: File, companyId: string, userId: string): Promise<FcrResource> => {
    const { base64 } = await toBase64(file);

    return {
        id: uuidv4(),
        name: file.name.split(".").slice(0, -1).join("."),
        contentType: file.type,
        documentBytes: base64,
        documentFilename: file.name,
        companyId: companyId,
        type: ResourceTypeEnum.document,
        createdById: userId,
        createdDate: (new Date).toISOString(),
        modifiedById: userId,
        modifiedDate: (new Date).toISOString(),
    };
};

export const filterValidResources = (resources: FcrResource[]) => (
    resources.filter((resource) => (resource.name && resource.isActive && resource.resourceCategoryId))
);

export const filterValidCategories = (categories: FcrResourceCategory[], resources: FcrResource[]) => {
    const resourcesCategoriesIds = filterValidResources(resources).map((resource) => resource.resourceCategoryId);
    return categories.filter((category) => resourcesCategoriesIds.includes(category.id));
};