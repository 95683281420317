import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, useMediaQuery, useTheme } from "@material-ui/core";

import { CoachingSession, FcrConfiguration, UserProfile } from "types";
import { Routings } from "types/constants";

import SelectCoachingSession from "./SelectCoachingSession";
import ScheduleCoachingSession, { ScheduleCoachingSessionMessages } from "./ScheduleCoachingSession";

interface CoachingReportSelectorProps {
    user?: UserProfile;
    usersBelow?: UserProfile[] | null;
    isOpen: boolean;
    createFcr?: boolean;
    fcrConfiguration?: FcrConfiguration;
    initialIsNewSession?: boolean;
    disableToggleNewSession?: boolean;
    scheduleCoachingSessionMessages?: ScheduleCoachingSessionMessages;
    onClose: () => void;
    onSuccess?: (coachingSession: CoachingSession) => void;
}

const CoachingReportSelector = ({
    user,
    usersBelow,
    isOpen,
    createFcr = true,
    fcrConfiguration,
    initialIsNewSession = false,
    disableToggleNewSession = false,
    scheduleCoachingSessionMessages,
    onClose,
    onSuccess,
}: CoachingReportSelectorProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory();

    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [isNewSession, setIsNewSession] = useState(initialIsNewSession);

    const toggleNewSession = useCallback((e) => {
        e.preventDefault();
        setIsNewSession(!isNewSession);
    }, [isNewSession]);

    const handleSessionSaved = useCallback((coachingSession: CoachingSession) => {
        history.push(`${Routings.fcr}/${coachingSession.id}`, { backPath: history.location.pathname });
    }, [history]);

    const onSuccessHandler = (coachingSession: CoachingSession) => {
        if (onSuccess) {
            onSuccess(coachingSession)
            !disableToggleNewSession && handleSessionSaved(coachingSession)
        } else {
            handleSessionSaved(coachingSession)
        }
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} fullWidth>
            {isNewSession ? (
                <ScheduleCoachingSession
                    user={user}
                    usersBelow={usersBelow}
                    createFcr={createFcr}
                    toggleNewSession={!disableToggleNewSession ? toggleNewSession : undefined}
                    fcrConfiguration={fcrConfiguration}
                    messages={scheduleCoachingSessionMessages}
                    onSuccess={onSuccessHandler}
                    onClose={onClose}
                    selectedUserIdExternal={selectedUserId}
                    setSelectedUserIdExternal={setSelectedUserId}
                />
            ) : (
                <SelectCoachingSession
                    user={user}
                    usersBelow={usersBelow}
                    toggleNewSession={!disableToggleNewSession ? toggleNewSession : undefined}
                    fcrConfiguration={fcrConfiguration}
                    onSuccess={onSuccessHandler}
                    onClose={onClose}
                    selectedUserIdExternal={selectedUserId}
                    setSelectedUserIdExternal={setSelectedUserId}
                />
            )}
        </Dialog>
    );
}

export default CoachingReportSelector;