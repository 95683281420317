import React from "react";

import GradientHigh from "./GradientHigh";
import GradientMedium from "./GradientMedium";
import GradientLow from "./GradientLow";
import GradientLowest from "./GradientLowest";

const GradientImports = () => (
    <svg
        width={0}
        height={0}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ display: "block" }}
    >
        <defs>
            <GradientHigh />
            <GradientMedium />
            <GradientLow />
            <GradientLowest />
        </defs>
    </svg>
);

export default GradientImports;