import * as React from "react";

function SvgPerformance(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={13}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g opacity={0.7} clipPath="url(#performance_svg__clip0)" fill="currentColor">
                <path d="M1.25 2.42H0v9.08h1.25V2.42zM7 4.44H5.75v7.06H7V4.44zM12.75 0H11.5v11.5h1.25V0z" />
            </g>
            <defs>
                <clipPath id="performance_svg__clip0">
                    <path fill="#fff" d="M0 0h12.75v11.5H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgPerformance;
