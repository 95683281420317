import React from "react";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import { styled } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const Switcher = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 28,
    height: 19,
    padding: 0,
    marginRight: 16,
    "& .MuiSwitch-input": {
        height: 18,
    },
    "& .MuiSwitch-switchBase": {
        padding: 3,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(10px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "transparent",
                boxSizing: "border-box",
                border: `1px solid ${theme.palette.primary.main}`,
                opacity: 1,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                border: `1px solid ${theme.colors.structure.fieldsOutline}`,
                backgroundColor: grey[900],
                opacity: 0.12,  
            },
            "& .MuiSwitch-thumb": {
                backgroundColor: theme.palette.primary.main,
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                backgroundColor: theme.colors.structure.fieldsOutline,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: theme.palette.primary.main,
            border: `6px solid ${theme.palette.primary.main}`,
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.colors.structure.fieldsOutline,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            border: `1px solid ${theme.colors.structure.fieldsOutline}`,
            backgroundColor: grey[900],
            opacity: 0.12,  
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 12,
        height: 12,
        backgroundColor: theme.colors.structure.fieldsOutline,
    },
    "& .MuiSwitch-track": {
        borderRadius: "18px",
        boxSizing: "border-box",
        border: `1px solid ${theme.colors.structure.fieldsOutline}`,
        backgroundColor: "transparent",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

export default Switcher;