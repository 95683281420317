import React, { useState, useCallback } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { Value } from "@material-ui/lab/useAutocomplete";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useField } from "formik";
import { Input } from "components/Form";
import Checkbox from "components/Checkbox";

import useStyles from "./styles";

export type { Value as AutocompleteInputValue } from "@material-ui/lab/useAutocomplete";

export interface AutocompleteInputProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    > extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "renderInput" | "onChange"> {
    label?: string,
    isPopupIconFixed?: boolean,
    inputProps?: { [key: string]: unknown },
    popupIcon?: React.ReactNode,
    selectionLimit?: number,
    onChange?: (value: Value<T, Multiple, DisableClearable, FreeSolo>) => void,
    renderInput?: (params: AutocompleteRenderInputParams, value?: Value<T, Multiple, DisableClearable, FreeSolo>) => React.ReactNode
}

const AutocompleteLoader = () => (<Box display="flex" alignItems="center" mt={"2px"} mr={"2px"}><CircularProgress size={20} /></Box>);

export function AutocompleteInput<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({
    label,
    options = [],
    popupIcon = <ExpandMore />,
    isPopupIconFixed = false,
    inputProps,
    selectionLimit = -1,
    value,
    multiple,
    loading,
    onChange,
    getOptionSelected,
    getOptionDisabled,
    renderInput,
    renderOption,
    ...props
}: AutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
    const [selectedValue, setSelectedValue] = useState<Value<T, Multiple, DisableClearable, FreeSolo> | undefined>(value); // eslint-disable-line
    const classes = useStyles({ isPopupIconFixed });

    const handleRenderOption = multiple ? ((option: T, { selected }: { selected: boolean }) => (
        <>
            <Checkbox
                color="primary"
                checked={selected}
                value={option}
            />
            {props.getOptionLabel ? props.getOptionLabel(option) : option}
        </>
    )) : renderOption;

    const handleChange: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>["onChange"] = useCallback((_, value) => {
        const formattedValue = value || null;
        setSelectedValue(formattedValue);
        if (typeof onChange === "function") onChange(formattedValue);
    }, [onChange]);

    const handleGetOptionDisabled = useCallback((option: T) => {
        const disabledStateByDefault = (typeof getOptionDisabled === "function") ? getOptionDisabled(option) : false;

        if (disabledStateByDefault) return disabledStateByDefault;

        if (multiple && (selectionLimit > -1) && Array.isArray(selectedValue)) {
            const isOptionSelected = (typeof getOptionSelected === "function") ? getOptionSelected(option, selectedValue as T) : selectedValue.includes(option);
            return isOptionSelected ? false : (selectedValue.length >= selectionLimit);
        } else {
            return disabledStateByDefault;
        }
    }, [multiple, selectedValue, selectionLimit, getOptionSelected, getOptionDisabled]);

    return (
        <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
            {...props}
            multiple={multiple}
            value={value}
            classes={classes}
            options={options}
            loading={loading}
            disabled={loading || !options.length}
            closeIcon={null}
            popupIcon={loading ? <AutocompleteLoader /> : popupIcon}
            renderOption={handleRenderOption}
            getOptionDisabled={handleGetOptionDisabled}
            getOptionSelected={getOptionSelected}
            onChange={handleChange}
            renderInput={(params) => {
                const fullInputProps = {
                    ...params,
                    ...inputProps,
                    label,
                };

                if (typeof renderInput === "function") return renderInput(fullInputProps, value);

                return (
                    <Input {...fullInputProps} />
                );
            }}
        />
    );
}

interface FormAutocompleteInputProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    > extends AutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo> {
    name: string,
    required?: boolean,
}

function FormAutocompleteInput<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({
    name = "",
    options = [],
    multiple,
    required = false,
    onChange,
    ...props
}: FormAutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
    const [{ value, onBlur }, { touched, error }, { setValue, setTouched }] = useField({
        name,
        multiple,
    });

    const handleChange: FormAutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo>["onChange"] = useCallback((value) => {
        setValue(value);
        if (typeof onChange === "function") onChange(value);
    }, [onChange, setValue]);

    return (
        <AutocompleteInput<T, Multiple, DisableClearable, FreeSolo>
            {...props}
            multiple={multiple}
            onChange={handleChange}
            onBlur={() => setTouched(true)}
            inputProps={{
                error: !!(touched && error),
                helperText: touched && error ? error : null,
                onBlur,
                required,
            }}
            options={options}
            value={value}
        />
    );
}

export default FormAutocompleteInput;
