import * as React from "react";

function SvgCompany(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-16-/-Company" fill="currentColor">
                    <path
                        d="M0,16 L0,15 L1,15 L1,3 L11,0 L11,6 L15,6 L15,15 L16,15 L16,16 L0,16 Z M10,1.343 L2,3.743 L2,15 L10,15 L10,1.343 Z M14,7 L11,7 L11,15 L14,15 L14,7 Z M8,12 L8,13 L4,13 L4,12 L8,12 Z M8,9 L8,10 L4,10 L4,9 L8,9 Z M8,6 L8,7 L4,7 L4,6 L8,6 Z"
                        id="Combined-Shape"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgCompany;
