import React, { useMemo } from "react";
import { orderBy } from "lodash";
import { EvaluationItemConfiguration } from "types/Certification";
import { EvaluationItemResultSaveDto } from "types/Evaluation.dto";

import { NULL_GUID } from "types/constants";

import TraineeEvaluationFormItem from "../TraineeEvaluationFromItem";

interface TraineeEvaluationFormProps {
    evaluationItemConfigurations: EvaluationItemConfiguration[];
    completed?: boolean;
    disabled?: boolean;
    isQuestionBased?: boolean;
    evaluationResultId?: string;
    showError?: boolean;
    evalItemResultSaveDtos?: EvaluationItemResultSaveDto[];
    setEvalItemResultSaveDtos?: React.Dispatch<React.SetStateAction<EvaluationItemResultSaveDto[]>>;
}

const TraineeEvaluationForm = ({
    evaluationItemConfigurations,
    completed = false,
    disabled = false,
    isQuestionBased = false,
    evaluationResultId = NULL_GUID,
    showError = false,
    evalItemResultSaveDtos = [],
    setEvalItemResultSaveDtos = () => null,
}: TraineeEvaluationFormProps) => {
    const sortedConfigurations: EvaluationItemConfiguration[] = useMemo(
        () => orderBy(evaluationItemConfigurations, "order"),
        [evaluationItemConfigurations]
    );

    return (
        <>
            {sortedConfigurations.map((item) => (
                <TraineeEvaluationFormItem
                    key={item.id}
                    evaluationResultId={evaluationResultId}
                    showAnswers={completed && isQuestionBased}
                    disabled={disabled}
                    showError={showError}
                    item={item}
                    evalItemResultSaveDtos={evalItemResultSaveDtos}
                    setEvalItemResultSaveDtos={setEvalItemResultSaveDtos}
                />
            ))}
        </>
    );
};

export default React.memo(TraineeEvaluationForm);
