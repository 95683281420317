import React, { ReactElement } from "react";
import MultilineInput from "components/MultilineInput";
import { useField } from "formik";

interface FormMultilineInputProps {
    name: string;
    children?: ReactElement;
    placeholder?: string;
    onBlur?: () => void;
}

function FormMultilineInput({
    name = "",
    placeholder = "",
    children,
    onBlur,
}: FormMultilineInputProps) {
    const [field, meta, helpers] = useField<string>(name);

    const handleBlur = () => {
        helpers.setTouched(true);
        if (typeof onBlur === "function") onBlur();
    };

    return (
        <MultilineInput
            placeholder={placeholder}
            value={field.value}
            error={(meta.touched && meta.error) && meta.error}
            onChange={helpers.setValue}
            onBlur={handleBlur}
        >
            {children}
        </MultilineInput>
    );
}

export default FormMultilineInput;
