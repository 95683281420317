import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    stepLabel: {
        cursor: "pointer",
    },
    icon: {
        width: 32,
        height: 32,
        borderRadius: "4px",
    },
    activeText: {
        fontSize: 12,
        color: theme.palette.common.white,
    },
    disabled: {
        background: "#F0F1F3",
    },
    disabledText: {
        fontSize: 12,
        color: theme.palette.common.black,
        opacity: 0.8,
    },
    completed: {
        background: "#F1FBFD",
    },
    active: {
        background: theme.palette.primary.main,

        "& svg path": {
            fill: theme.palette.common.white,
        },
    },
}));

export default useStyles;
