import React, { useContext, useMemo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";

import AvatarWithScore from "components/AvatarWithScore";
import LevelHierarchy from "components/LevelHierarchy";
import { UserProfile } from "types";
import { SizeEnum } from "types/constants";
import { CompaniesContext } from "context";
import { getUserFullName } from "helpers/userProfileHelper";

import useStyles from "../styles";

interface MemberItemProps {
    baseUrl: string,
    user: UserProfile,
    userId?: string,
}

export default function MemberItem({
    baseUrl,
    user,
    userId,
}: MemberItemProps) {
    const classes = useStyles();

    const { companyLevels } = useContext(CompaniesContext);

    const editUrl = useMemo(() => `${baseUrl}/${userId || user.id}`, [baseUrl, userId, user.id]);

    const description = useMemo(() => {
        const levelTitle = user.level ? `Level ${user.level}` : "";
        const noTeamAssignmentTitle = user.level && !user.userTeamMember ? " - No team assignment" : "";
        const noLevelTeamAssignmentTitle = !user.level && !user.userTeamMember ? "No Level/Team assignment" : "";
        const deactivatedTitle = !user.isActive ? " - Deactivated" : "";

        return (
            <Typography component="span" color="textSecondary" variant="body2">
                {levelTitle}
                {(noTeamAssignmentTitle || noLevelTeamAssignmentTitle) && (
                    <Box component="span" color="error.main">{`${noTeamAssignmentTitle}${noLevelTeamAssignmentTitle}`}</Box>
                )}
                {deactivatedTitle}
            </Typography>
        )
    }, [user]);

    return (
        <Link className={clsx(classes.body, { [classes.bodyInactive]: !user?.isActive })} to={{ pathname: editUrl, state: user }}>
            <Box py={3} px={4}>
                <Grid alignItems="center" container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Grid alignItems="center" container spacing={2}>
                            <Grid item>
                                <AvatarWithScore size={SizeEnum.mediumSmaller} user={user} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h2">
                                    <Box component="span" fontWeight={500}>
                                        {getUserFullName(user)}
                                    </Box>
                                </Typography>
                                {description}
                            </Grid>
                        </Grid>
                    </Grid>
                    <LevelHierarchy
                        companyLevelId={user.companyLevelId}
                        companyLevels={companyLevels}
                        customClasses={classes}
                    />
                </Grid>
            </Box>
        </Link>
    )
}
