import * as React from "react";

function SvgCheckmark(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={11} height={9} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#checkmark_svg__clip0)">
                <mask id="checkmark_svg__a" fill="currentColor">
                    <path d="M3.967 8.19a.442.442 0 01-.322-.132L0 4.435l.644-.651 3.294 3.272L9.734 0l.703.578-6.111 7.444a.455.455 0 01-.33.168h-.03z" />
                </mask>
                <path
                    d="M3.967 8.19a.442.442 0 01-.322-.132L0 4.435l.644-.651 3.294 3.272L9.734 0l.703.578-6.111 7.444a.455.455 0 01-.33.168h-.03z"
                    fill="currentColor"
                />
                <path
                    d="M3.645 8.058l.707-.707-.002-.002-.705.71zM0 4.435l-.711-.703-.701.71.707.703.705-.71zm.644-.651l.705-.71-.711-.706-.705.713.711.703zm3.294 3.272l-.705.709.78.775.697-.85-.772-.634zM9.734 0l.636-.772-.773-.636-.635.773.772.635zm.703.578l.773.635.634-.772-.772-.635-.635.772zM4.326 8.022l-.773-.635-.007.008-.007.009.787.618zm-.33.168v1h.03l.029-.002-.059-.998zm-.03-1a.556.556 0 01.385.161L2.939 8.765c.282.282.649.425 1.029.425v-2zm.384.159L.705 3.726l-1.41 1.419L2.94 8.768l1.41-1.419zM.71 5.139l.644-.652-1.422-1.406-.644.651L.71 5.138zm-.772-.646l3.294 3.272 1.41-1.419-3.294-3.272-1.41 1.42zM4.71 7.69L10.507.635 8.962-.635 3.165 6.421 4.71 7.69zM9.1.772l.703.578 1.27-1.544-.702-.578L9.099.772zm.565-.828L3.553 7.387l1.545 1.27 6.112-7.444-1.546-1.27zM3.54 7.404a.545.545 0 01.398-.212l.118 1.996c.383-.022.783-.2 1.057-.549L3.539 7.404zm.457-.214h-.001-.001-.001-.001-.002-.001-.001-.002-.001-.001-.002-.002-.002-.001-.001-.001-.001-.002-.003-.001-.001v2H3.997v-2z"
                    fill="currentColor"
                    mask="url(#checkmark_svg__a)"
                />
            </g>
            <defs>
                <clipPath id="checkmark_svg__clip0">
                    <path fill="currentColor" d="M0 0h10.444v8.19H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgCheckmark;
