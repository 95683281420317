import React, { useState, useEffect, useContext } from "react";
import orderBy from "lodash/orderBy";
import { Box } from "@material-ui/core";

import { FcrResource, FcrResourceCategory, HeaderProps } from "types";
import Header from "components/Header";
import { getResourceCategoriesForCompany, getFcrResourcesForUser } from "services/resourceServices";
import { ConfigurationContext } from "context";
import { filterValidResources, filterValidCategories } from "helpers/resourceHelper";

import CategoryTree from "./CategoryTree";
import ResourcePaper from "./ResourcePaper";

function Resources({ isBarOpen, navBarOpen }: HeaderProps) {
    const [categories, setCategories] = useState<FcrResourceCategory[]>([]);
    const [resources, setResources] = useState<FcrResource[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<FcrResourceCategory["id"] | undefined>();
    const [isDataFetching, setIsDataFetching] = useState(false);
    const { currentUser } = useContext(ConfigurationContext);
    const isLoading = !currentUser || isDataFetching;
    const selectedCategoryResources = resources.filter(resource => resource.resourceCategoryId === selectedCategoryId);

    useEffect(() => {
        if (!currentUser) return;

        setIsDataFetching(true);

        Promise.all([
            getResourceCategoriesForCompany(currentUser.companyId),
            getFcrResourcesForUser(currentUser.id),
        ])
            .then(([categories, resources]) => {
                const validResources = filterValidResources(resources);
                const validCategories = filterValidCategories(categories, validResources);
                const sortedValidCategories = orderBy(validCategories, "order", "asc");

                setCategories(sortedValidCategories);
                setResources(validResources);
                setSelectedCategoryId(sortedValidCategories[0]?.id);
            })
            .finally(() => setIsDataFetching(false));
    }, [currentUser]);

    return (
        <>
            <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            <Box display="flex">
                {categories.length > 0 && (
                    <CategoryTree
                        categories={categories}
                        isLoading={isLoading}
                        selectedCategory={selectedCategoryId}
                        setCategory={setSelectedCategoryId}
                    />
                )}
                <ResourcePaper
                    resources={selectedCategoryResources}
                    isLoading={isLoading} />
            </Box>
        </>
    )
}

export default Resources;
