import { v4 as uuidv4 } from "uuid";

import { MessageType, MessageList, MessageThread, MessageThreadList } from "types";

import API from "./apiService";

export const addMessage = async (subject: string, messageContent: string, toUserId: string, threadId?: string): Promise<MessageType> => {
    const response = await API.post("/Message/add", {
        threadId: threadId || uuidv4(),
        subject,
        messageContent,
        toUserId,
    });
    return response.data;
};

export const getRecentMessages = async (userId: string): Promise<MessageType[]> => {
    const { data } = await API.get(`/Message/getLastMessagesForUser/${userId}`);

    return data || [];
};

export const getAllMessages = async (userId: string, pageNumber: number, recordsAmount = 50): Promise<MessageList> => {
    const { data } = await API.get(`/Message/getAllMessagesForUser/${userId}?pageNumber=${pageNumber}&recordsAmount=${recordsAmount}`);
    return data || [];
};

export const markThreadAsReadForUser = async (threadId: string, userId: string): Promise<void> => {
    const { data } = await API.post(`/Message/markThreadAsReadForUser/${threadId}/${userId}`);

    return data;
};

export const getAllThreadsForUser = async (userId: string, pageNumber: number, recordsAmount = 50, messagesPerThread = 1): Promise<MessageThreadList> => {
    const { data } = await API.get(`/Message/getAllThreadsForUser/${userId}?pageNumber=${pageNumber}&recordsAmount=${recordsAmount}&messagesPerThread=${messagesPerThread}`);
    return data || [];
};

export const getMessageThread = async (threadId: string, pageNumber = 0, recordsAmount = 50): Promise<MessageThread> => {
    const { data } = await API.get(`/Message/getThread/${threadId}?pageNumber=${pageNumber}&recordsAmount=${recordsAmount}`);

    return data || [];
};