import * as React from "react";

function GradientLowest() {
    return (
        <linearGradient id="gradientLowest">
            <stop stopColor="#9D4794"/>
            <stop offset="1" stopColor="#C63360"/>
        </linearGradient>
    );
}

export default GradientLowest;
