import { EvaluatorClassDto, EvaluatorClassDtoProps, TraineeClassInfoDto, TraineeClassInfoDtoProps } from "types/Certification";

/** Shared filter function for classes which is currently set to filter on the title or className dto property depending on which type is passed in, alternatively you could extend it to receive the dto prop to filter by if needed but function will need to be generisized even more, notice that = never is added as usage without generics isn't allowed on this function */
export function getFilterForClasses<Dto = never, Prop extends keyof Dto = never, InferredDto extends Dto = Dto>(
    input: string, prop: Prop
) {
    return (
        c: InferredDto
    ): boolean => {
        if (input === "") return true;
        return ((c[prop] ?? "") as string).toLowerCase().includes(input.toLowerCase()) ?? false
    }
}

// TODO Decide if better to copy paste functions like this or enable reuse as the function above affords - Discuss with Alonzo

/** Shared sorting function that sorts by class date dto prop but could receive the dto prop to use as an extension when needed */
export const getClassesSorter = (sortingDir: "asc" | "desc") => (a: EvaluatorClassDto, b: EvaluatorClassDto) => {
    const timeA = new Date(a[EvaluatorClassDtoProps.classDate]).getTime();
    const timeB = new Date(b[EvaluatorClassDtoProps.classDate]).getTime();
    if (sortingDir === "asc") {
        return timeA - timeB;
    } else if (sortingDir === "desc") {
        return timeB - timeA;
    } else {
        return 0;
    }
}
/** Shared sorting function that sorts by class date dto prop but could receive the dto prop to use as an extension when needed */
export const getTraineeClassesSorter = (sortingDir: "asc" | "desc") => (a: TraineeClassInfoDto, b: TraineeClassInfoDto) => {
    const timeA = new Date(a[TraineeClassInfoDtoProps.classDate]).getTime();
    const timeB = new Date(b[TraineeClassInfoDtoProps.classDate]).getTime();
    if (sortingDir === "asc") {
        return timeA - timeB;
    } else if (sortingDir === "desc") {
        return timeB - timeA;
    } else {
        return 0;
    }
}