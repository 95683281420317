import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            height: theme.spacing(5.75),
        },
    })
);

export default useStyles;
