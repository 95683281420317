import React from "react";
import * as Yup from "yup";
import { Box, Button, Dialog, Grid, Typography, useTheme } from "@material-ui/core";
import { Form, useFormik, FormikProvider } from "formik";

import { DeleteIcon, TickIcon } from "svgComponents";
import LoadingButton from "components/LoadingButton";
import { FormInput } from "components/Form";

import useStyles from "./styles";

interface BccEmailsModalProps {
    item: string | null;
    open: boolean;
    onDelete: (e: React.MouseEvent<HTMLElement>) => void,
    onClose: () => void;
    onSuccess: (bccEmail: string) => void;
    onEdit: (email: string, editedEmail: string) => void;
}

interface BccEmailsModalValues {
    email: string;
}

export default function BccEmailsModal({
    item,
    open,
    onClose,
    onSuccess,
    onEdit,
    onDelete,
}: BccEmailsModalProps) {
    const theme = useTheme();
    const classes = useStyles();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Must be a valid email").required("Required"),
    });

    const formik = useFormik<BccEmailsModalValues>({
        enableReinitialize: true,
        initialValues: {
            email: item ?? "",
        },
        validationSchema,
        onSubmit(values: BccEmailsModalValues, { setSubmitting }) {
            item ? onEdit(item, values.email) : onSuccess(values.email)
            onClose()
            setSubmitting(false)
        },
    });

    const disabled = formik.isSubmitting;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <FormikProvider value={formik}>
                <Form>
                    <Box px={4} py={3}>
                        <Typography variant="h1">
                            {item ? "Edit Registration Email BCC" : "Create Registration Email BCC"}
                        </Typography>
                    </Box>
                    <Box bgcolor={theme.colors.structure.pageBackground} px={4} py={3}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} className={classes.field}>
                                    <FormInput name="email" label="Registration Email BCC" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box px={4} py={3} display="flex" alignItems="center">
                        <Grid alignItems="center" justifyContent="flex-end" container spacing={2}>
                            <Grid item>
                                <LoadingButton
                                    color="primary"
                                    disabled={disabled}
                                    disableElevation
                                    loading={formik.isSubmitting}
                                    loadingLabel={
                                        <Box component="span" lineHeight="30px">
                                            {item ? "Saving..." : "Creating..."}
                                        </Box>
                                    }
                                    size="large"
                                    startIcon={item ? <TickIcon /> : null}
                                    variant="contained"
                                    type="submit"
                                >
                                    <Box component="span" lineHeight="30px">
                                        {item ? "Edit" : "Add"}
                                    </Box>
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={disabled}
                                    size="large"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    <Box component="span" lineHeight="30px">Cancel</Box>
                                </Button>
                            </Grid>
                            {item && (
                                <Grid item>
                                    <LoadingButton
                                        color="default"
                                        disabled={disabled}
                                        disableElevation
                                        loadingLabel={<Box component="span" lineHeight="30px">Deleting...</Box>}
                                        size="large"
                                        startIcon={null}
                                        variant="outlined"
                                        type="submit"
                                        onClick={onDelete}
                                    >
                                        <Box alignItems="center" display="flex" component="span" height="30px">
                                            <DeleteIcon height={20} width={20} />
                                        </Box>
                                    </LoadingButton>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}