import * as React from "react";

function GradientMedium() {
    return (
        <linearGradient id="gradientMedium">
            <stop stopColor="#6185D5"/>
            <stop offset="0.473958" stopColor="#4B93D5"/>
            <stop offset="1" stopColor="#36C0D3"/>
        </linearGradient>
    );
}

export default GradientMedium;
