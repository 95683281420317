import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    card: {
        height: "100%",
    },
    layout: {
        boxSizing: "border-box",
        minHeight: "100%",
    },
    fullName: {
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    skill: {
        color: theme.colors.text.darkBlue,
    },
    label: {
        color: theme.palette.text.secondary,
    },
    coachedNumber: {
        color: theme.palette.primary.main,
    },
    date: {
        color: theme.colors.text.mediumGray,
    },
    icon: {
        verticalAlign: "text-top",
        marginTop: 4,
    },
}));

export default useStyles;
