import { Company, CompanyLevel, CompanyLevelLabel, FcrResource, FcrSectionConfiguration } from "types";
import { CertificationSectionConfiguration } from "types/Certification";
import TreeSet from "types/TreeSet";

import API from "./apiService";

export const getAllCompanies = async (): Promise<Company[]> => {
    const result = await API.get("/company/getAll");
    return result.data;
};

export const saveCompany = async (company: Company): Promise<Company> => {
    const result = await API.post(`/company/${company.id}`, company);
    return result.data;
};

export const uploadCompanyLogo = async (companyId: string, logo: unknown): Promise<FcrResource> => {
    const result = await API.post(`/company/uploadLogo/${companyId}`, logo);
    return result.data;
};

export const getCompanyLevels = async (companyId: string): Promise<CompanyLevel[]> => {
    const result = await API.get(`companyLevel/getAll/${companyId}`);
    return result.data;
};

export const getCompanyLevelLabels = async (companyId: string): Promise<CompanyLevelLabel[]> => {
    const result = await API.get(`companyLevelLabel/getForCompany/${companyId}`);
    return result.data;
};

export const saveCompanyLevel = async (companyLevel: CompanyLevel): Promise<CompanyLevel> => {
    const result = await API.post(`companyLevel/${companyLevel.id}`, companyLevel);
    return result.data;
};

export const deleteCompanyLevel = async (companyLevelId: string): Promise<void> => {
    const result = await API.delete(`companyLevel/${companyLevelId}`);
    return result.data;
};

export const getCompanyLevelsTree = async (companyId: string): Promise<TreeSet<CompanyLevel>> => {
    const result = await API.get(`companyLevel/getCompanyLevelsTreeWithUsers/${companyId}`);
    return result.data;
};

export const saveCompanyLevelLabels = async (companyId: string, companyLevelLabels: CompanyLevelLabel[]): Promise<CompanyLevelLabel[]> => {
    const result = await API.post(`companyLevelLabel/saveForCompany/${companyId}`, companyLevelLabels);
    return result.data;
};

export const getCompanyFcrSectionConfiguration = async (companyId: string): Promise<FcrSectionConfiguration> => {
    const result = await API.get(`FcrSectionConfiguration/getForCompany/${companyId}`);
    return result.data;
};

export const saveCompanyFcrSectionConfiguration = async (
    companyId: string,
    fcrSectionConfiguration: FcrSectionConfiguration
): Promise<FcrSectionConfiguration> => {
    const result = await API.post(`FcrSectionConfiguration/saveForCompany/${companyId}`, fcrSectionConfiguration);
    return result.data;
};

export const getCertificationSectionConfiguration = async (companyId: string): Promise<CertificationSectionConfiguration> => {
    const result = await API.get(`Certification/SectionConfiguration/getForCompany/${companyId}`);
    return result.data;
};

export const saveCertificationSectionConfiguration = async (
    companyId: string,
    payload: CertificationSectionConfiguration
): Promise<CertificationSectionConfiguration> => {
    const result = await API.post(`Certification/SectionConfiguration/saveForCompany/${companyId}`, payload);
    return result.data;
};
