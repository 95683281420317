import { Box, CircularProgress } from "@material-ui/core";
import Header from "components/Header";
import { ClassesContext, ConfigurationContext } from "context";
import React, { useContext, useEffect } from "react";
import { HeaderProps } from "types";
import { NUMBER_OF_ITEMS_IN_PREVIEW } from "types/constants";

import AllMyClassesPreview from "./AllMyClassesPreview";
import AllScheduledClassesPreview from "./AllScheduledClassesPreview";
import MyTeam from "./MyTeam";

import useStyles from "./styles";

function MainClassesPage({ isBarOpen, navBarOpen }: HeaderProps) {
    const classes = useStyles();

    const { currentUser, companyCertificationConfiguration, isConfigurationFetched } = useContext(ConfigurationContext);
    const { isEvaluator, isTrainee, hasTeamMembersAsTrainees } = currentUser ?? {};
    const { fetchCertificationClassesPreview, allMainClassesPreview, setAllMainClassesPreview, isFetchingAllMainClassesPreview } =
        React.useContext(ClassesContext);

    useEffect(() => {
        if (!isFetchingAllMainClassesPreview && !allMainClassesPreview && fetchCertificationClassesPreview) {
            fetchCertificationClassesPreview();
        }
    }, [isFetchingAllMainClassesPreview, allMainClassesPreview, fetchCertificationClassesPreview]);

    useEffect(() => setAllMainClassesPreview(undefined), [setAllMainClassesPreview]);

    return (
        <>
            <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
            {isConfigurationFetched && !isFetchingAllMainClassesPreview ? (
                <>
                    {isEvaluator && (allMainClassesPreview?.evaluatorClasses?.length ?? []) > 0 && (
                        <AllScheduledClassesPreview
                            sectionlabel={companyCertificationConfiguration?.section1Label ?? ""}
                            isLoading={isFetchingAllMainClassesPreview ?? false}
                            classDtos={allMainClassesPreview?.evaluatorClasses.slice(0, NUMBER_OF_ITEMS_IN_PREVIEW - 1)}
                        />
                    )}
                    {isTrainee && (allMainClassesPreview?.traineeClasses?.length ?? []) > 0 && (
                        <AllMyClassesPreview
                            sectionlabel={companyCertificationConfiguration?.section1Label ?? ""}
                            isLoading={isFetchingAllMainClassesPreview ?? false}
                            classDtos={allMainClassesPreview?.traineeClasses.slice(0, NUMBER_OF_ITEMS_IN_PREVIEW - 1)}
                        />
                    )}
                    {hasTeamMembersAsTrainees && <MyTeam />}
                </>
            ) : (
                <Box p={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            )}
        </>
    );
}

export default MainClassesPage;
