import React, { useCallback, useContext, useMemo, useReducer } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Button, Grid, Typography, useTheme, Dialog } from "@material-ui/core";
import { CoachingSession, FcrConfiguration } from "types";
import CircleIcon from "components/CircleIcon";
import { formatShortDate, formatTime } from "helpers/dateHelpers";
import { Calendar } from "svgComponents";
import { getSessionTypeIcon } from "helpers/coachingHistoryHelper";
import AvatarWithScore from "components/AvatarWithScore";
import { SizeEnum, CoachingSessionStatusEnum, Routings, FcrStatusEnum } from "types/constants";
import { getUserFullName } from "helpers/userProfileHelper";
import ScheduleCoachingSession from "components/CoachingReportSelector/ScheduleCoachingSession";
import { ConfigurationContext } from "context";

import useStyles from "./styles";

interface ScheduledSessionItemProps {
    session: CoachingSession;
    fcrConfiguration?: FcrConfiguration;
    onUpdated: (coachingSession: CoachingSession) => void;
    onDeleted: (coachingSession: CoachingSession) => void;
}

const CoachingSessionStatusMessages = {
    [CoachingSessionStatusEnum.upcoming]: "Upcoming",
    [CoachingSessionStatusEnum.completed]: "Completed",
    [CoachingSessionStatusEnum.incomplete]: "Incomplete",
};

function ScheduledSessionItem({ session, fcrConfiguration, onUpdated, onDeleted }: ScheduledSessionItemProps) {
    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();

    const { hasCoachingReportAccess } = useContext(ConfigurationContext);

    const [showEditSessionDialog, toggleShowEditSessionDialog] = useReducer(state => !state, false);

    const { status, bgColor } = useMemo(() => {
        if (session.fcrResult?.fcrResultStatusId === FcrStatusEnum.completed) {
            return {
                status: CoachingSessionStatusEnum.completed,
                bgColor: theme.colors.structure.pageBackground,
            };
        }
        if (new Date(session.scheduleStartDate).getTime() - new Date().getTime() > 0) {
            return {
                status: CoachingSessionStatusEnum.upcoming,
                bgColor: theme.colors.statusLabel.background.green,
            };
        }
        return {
            status: CoachingSessionStatusEnum.incomplete,
            bgColor: theme.colors.structure.pageBackground,
        };
    }, [session, theme]);

    const isEditAvailable = 
        (status === CoachingSessionStatusEnum.upcoming && session.fcrResult?.fcrResultStatusId !== FcrStatusEnum.completed) || 
        status === CoachingSessionStatusEnum.incomplete;

    const handleEdit = useCallback((e: React.SyntheticEvent) => {
        if (!isEditAvailable) return;
        e.stopPropagation();
        toggleShowEditSessionDialog();
    }, [isEditAvailable, toggleShowEditSessionDialog]);

    const handleViewReport = useCallback((e: React.SyntheticEvent) => {
        e.stopPropagation();
        history.push(`${Routings.fcr}/${session.id}`, { backPath: history.location.pathname, backText: "Back to Hub" });
    }, [history, session.id]);

    return (
        <>
            <div className={classes.sessionItemWrap}>
                <Grid
                    container
                    spacing={2}
                    onClick={handleEdit}
                    className={clsx(classes.sessionItem, { [classes.sessionItemClickable]: isEditAvailable })}
                >
                    <Grid item md className={classes.sessionItemCell}>
                        <CircleIcon>
                            <Calendar />
                        </CircleIcon>
                        <div className={classes.sessionItemCellText}>
                            {session.scheduleStartDate ? (
                                <>
                                    <div className={classes.bold}>
                                        <Typography variant="h2">
                                            {formatShortDate(new Date(session.scheduleStartDate))}
                                        </Typography>
                                    </div>
                                    <Typography>
                                        {!session.isAllDayEvent && formatTime(new Date(session.scheduleStartDate))}
                                        {session.isAllDayEvent && "All Day"}
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="h2">-</Typography>
                            )}
                        </div>
                    </Grid>
                    <Grid item md className={classes.sessionItemCell}>
                        <AvatarWithScore user={session.coach} size={SizeEnum.small} />
                        <div className={classes.sessionItemCellTextBold}>
                            <Typography variant="h2">
                                {getUserFullName(session.coach)}
                            </Typography>
                            <Typography variant="caption">
                                Coached By
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md className={classes.sessionItemCell}>
                        <CircleIcon>
                            {getSessionTypeIcon(session.coachingSessionType.icon)}
                        </CircleIcon>
                        <div className={classes.sessionItemCellTextBold}>
                            <Typography variant="h2">
                                {session.coachingSessionType.value}
                            </Typography>
                            <Typography variant="caption">
                                Session Type
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md className={classes.sessionItemCell}>
                        <AvatarWithScore user={session.assignee} size={SizeEnum.small} />
                        <div className={classes.sessionItemCellTextBold}>
                            <div className={classes.bold}>
                                <Typography variant="h2">
                                    {getUserFullName(session.assignee)}
                                </Typography>
                            </div>
                            <Typography variant="caption">
                                Team Member
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md className={classes.sessionItemCell} style={{ justifyContent: "flex-end" }}>
                        <div className={classes.sessionItemStatus} style={{ backgroundColor: bgColor }}>
                            {CoachingSessionStatusMessages[status]}
                        </div>
                        <Grid container spacing={2} className={classes.sessionItemActions}>
                            {hasCoachingReportAccess(session) && (
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="text"
                                        className={classes.link}
                                        onClick={handleViewReport}
                                    >
                                        View Report
                                    </Button>
                                </Grid>
                            )}
                            {isEditAvailable && (
                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        className={classes.link}
                                        onClick={handleEdit}
                                    >
                                        Edit
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={showEditSessionDialog}
                onClose={toggleShowEditSessionDialog}
                fullWidth
            >
                <ScheduleCoachingSession
                    coachingSession={session}
                    user={session.assignee}
                    onClose={toggleShowEditSessionDialog}
                    onSuccess={onUpdated}
                    onDelete={onDeleted}
                    fcrConfiguration={fcrConfiguration}
                />
            </Dialog>
        </>
    );
}

export default React.memo(ScheduledSessionItem);
