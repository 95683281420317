import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import some from "lodash/some";
import { Box, Typography } from "@material-ui/core";

import { CompaniesContext, SnackbarContext } from "context";
import { sortTeamsByOrder } from "helpers/teamHelpers";
import { getAllCompanyTeams } from "services/teamServices";
import { AdminRoutings, AlertSeverity, TEAM_USERS_FILTER_LABEL } from "types/constants";
import { Team } from "types";
import TeamsFilter from "components/TeamsFilter";
import LoadingButton from "components/LoadingButton";
import { sendRegistrationEmailsByCompany } from "services/userServices";

import ItemsList from "../../ItemsList";
import TeamItem from "../../ItemsList/TeamItem";

const SHOW_PER_SCROLL = 10;

export default function TeamsListPage() {
    const { currentCompany } = useContext(CompaniesContext);
    const [shownQty, setShownQty] = useState(SHOW_PER_SCROLL);
    const [allTeams, setAllTeams] = useState<Team[]>([]);
    const [filteredTeams, setFilteredTeams] = useState<Team[] | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const { openSnackbar } = useContext(SnackbarContext);
    const teamsForShowing = (filteredTeams ? filteredTeams : allTeams);
    const paginatedTeams = useMemo(() => teamsForShowing.slice(0, shownQty), [teamsForShowing, shownQty]);
    const onNextScroll = useCallback(() => setShownQty(state => state + SHOW_PER_SCROLL), []);

    const fetchUsers = (companyId: string) => {
        setLoading(true);

        getAllCompanyTeams(companyId)
            .then((teams) => {
                setAllTeams(sortTeamsByOrder(teams) || teams);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (currentCompany?.id) fetchUsers(currentCompany?.id);
    }, [currentCompany?.id]);

    const handleSendEmails = useCallback(() => {
        if (!currentCompany?.id) return;
        setSending(true);
        sendRegistrationEmailsByCompany(currentCompany.id)
            .then(() => openSnackbar("Emails were successfully sent.", AlertSeverity.success))
            .catch((e) => openSnackbar(e.response.data || "Failed to send emails.", AlertSeverity.error))
            .finally(() => setSending(false));
    }, [currentCompany?.id, openSnackbar]);

    const hasUnassignedLeader = useMemo(() => some(allTeams, (team) => !team.teamLeader), [allTeams]);
    
    return (
        <>
            <Box
                mb={6}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                minHeight="46px"
            >
                <Typography variant="h1">Teams</Typography>
                <Box display="flex">
                    <Box>
                        <LoadingButton
                            color="primary"
                            disableElevation
                            loading={sending}
                            loadingLabel="Sending..."
                            size="large"
                            variant="outlined"
                            onClick={handleSendEmails}
                        >
                            <Box component="span" lineHeight="30px">
                                Send Bulk Registration Emails
                            </Box>
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
            <ItemsList
                filter={(
                    <TeamsFilter
                        usersAutocompleteLabel={TEAM_USERS_FILTER_LABEL}
                        teams={allTeams}
                        onFilter={setFilteredTeams}
                    />
                )}
                filteredUsers={teamsForShowing ?? []}
                warning={hasUnassignedLeader ? "Warning: Teams without a Team Leader assigned can break navigation and calculations for higher level views." : null}
                quantity={paginatedTeams.length}
                isHasMore={teamsForShowing.length > paginatedTeams.length}
                totalLabel={(teamsForShowing.length > 1) ? "teams" : "team"}
                onNext={onNextScroll}
                isLoading={isLoading}
            >
                {paginatedTeams.map(team => (
                    <TeamItem
                        key={team.id}
                        baseUrl={AdminRoutings.teams}
                        description={`${team.userMembers?.length ?? 0} Members`}
                        user={team.teamLeader}
                        userId={team.id}
                        team={team}
                        setAllTeams={setAllTeams}
                    />
                ))}
            </ItemsList>
        </>
    )
}
