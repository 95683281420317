import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paperEdit: {
        background: theme.palette.primary.contrastText,
        boxShadow: "0 4px 20px 0 rgba(82, 170, 190, 0.2)",
        border: `2px solid ${theme.colors.identity.teal}`,
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        color: theme.palette.text.secondary,
        borderRadius: theme.spacing(1.25),
    },
    numberField: {
        "& input": {
            paddingRight: "5px",
        },
    },
}));

export default useStyles;
