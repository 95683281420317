import React from "react";
import { Box, Grid, Typography, CircularProgress } from "@material-ui/core";
import { FormInput } from "components/Form";

interface FcrSectionConfigurationFormProps {
    isLoading: boolean;
}

function FcrSectionConfigurationForm({ isLoading }: FcrSectionConfigurationFormProps) {
    return (
        <Box p={4}>
            <Box fontWeight={600} mb={3} display="flex" alignItems="center">
                <Typography variant="h2">
                    Report Section Names
                </Typography>
                {isLoading && (
                    <Box ml={2}>
                        <CircularProgress size={17} />
                    </Box>
                )}
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormInput name="fcrSectionConfiguration.section1Label" label="Step 1 Label" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInput name="fcrSectionConfiguration.section2Label" label="Step 2 Label" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInput name="fcrSectionConfiguration.section3Label" label="Step 3 Label" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInput name="fcrSectionConfiguration.section4Label" label="Step 4 Label" required />
                </Grid>
            </Grid>
        </Box>
    )
}

export default FcrSectionConfigurationForm;
