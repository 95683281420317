import { ClassesContext, ConfigurationContext, IClassesContext, SnackbarContext } from "context";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { defaultAllClassesFilters, defaultAllClassesSortDirection } from "routes/MainClassListingsPage/shared-classes.data";
import {
    getCertificationClassesPreviewForUser,
    getCertificationEvaluationResultFull,
    getClassesForCompanyByEvaluator,
    getClassesForCompanyByTrainee,
    postEvaluationResultSaveDto,
} from "services/certificationService";
import {
    CertClassesPreviewForUserDto,
    EvaluatorClassDto,
    EvaluatorClassDtoProps,
    TraineeClassInfoDto,
    TraineeClassInfoDtoProps,
} from "types/Certification";

import { EvalResultsSaveOptionsEnum } from "helpers/certificationHelper";
import {
    AlertSeverity,
    ClassesRouting,
    EvaluatorClassesViewAllRouting,
    NUMBER_OF_ITEMS_IN_PREVIEW,
    Routings,
    SortDirectionEnum,
} from "types/constants";
import { EvaluationResultSaveDto, FullEvaluationDto } from "types/Evaluation.dto";

import { getClassesSorter, getFilterForClasses, getTraineeClassesSorter } from "./shared-filter-sort";

interface ClassesProviderProps {
    children: React.ReactNode;
}

const ClassesProvider = ({ children }: ClassesProviderProps) => {
    //User context
    const history = useHistory();
    const { currentUser } = React.useContext(ConfigurationContext);
    const { openSnackbar } = useContext(SnackbarContext);
    // const { traineeId } = currentUser ?? {}

    //CONTEXT STATE INITIALIZATION
    //ALL SCHEDULED CLASSES
    //DTO
    const [allSchedClasses, setAllSchedClasses] = useState<EvaluatorClassDto[] | undefined>(undefined);
    // loading
    const [isFetchingAllSchedClasses, setIsFetchingAllSchedClasses] = useState(false);

    //Sort
    const [allSchedClassesSorting, setAllSchedClassesSorting] = useState<SortDirectionEnum>(
        defaultAllClassesSortDirection.dateSortDirection
    );
    //Filter
    const [allSchedClassNameFilter, setAllSchedClassNameFilter] = useState<string>(defaultAllClassesFilters.className);
    const [allSchedClassToShowCountFilter, setAllSchedClassToShowCountFilter] = useState<number>(defaultAllClassesFilters.numberOfItems);

    //ALL MY CLASSES
    //DTO
    const [allMyClasses, setAllMyClasses] = useState<TraineeClassInfoDto[] | undefined>(undefined);
    // loading
    const [isFetchingAllMyClasses, setIsFetchingAllMyClasses] = useState(false);

    //Sort
    const [allMyClassesSorting, setAllMyClassesSorting] = useState<SortDirectionEnum>(defaultAllClassesSortDirection.dateSortDirection);
    //Filter
    const [allMyClassNameFilter, setAllMyClassNameFilter] = useState<string>(defaultAllClassesFilters.className);
    const [allMyClassToShowCountFilter, setAllMyClassToShowCountFilter] = useState<number>(defaultAllClassesFilters.numberOfItems);

    //Evaluations
    // get data
    const [isFetchingFullEvalDto, setIsFetchingFullEvalDto] = useState<boolean | undefined>();
    const [fullEvalDtoError, setFullEvalDtoError] = useState<string | undefined>();
    // validate data
    const [showError, setShowError] = useState(false);
    // const [requiredDataValidationError, setRequiredDataValidaitonError] = useState<string | null>(null)
    // save data
    const [postEvaluationResult, setPostEvaluationResult] = useState<boolean | undefined>();
    const [isAwaitingPostEvaluationResultSave, setIsAwaitingPostEvaluationResultSave] = useState<boolean | undefined>();
    const [isAwaitingPostEvaluationResultSubmit, setIsAwaitingPostEvaluationResultSubmit] = useState<boolean>(false);

    //FILTERING AND SORTING SECTION
    /** Memoize the sorted and filter result since we only need this when the sort direction or the filter input changes */
    const postSortFilterAllSchedClasses: EvaluatorClassDto[] | undefined = useMemo(() => {
        //Apply the filter
        const filteredClasses = allSchedClasses?.filter(
            getFilterForClasses<EvaluatorClassDto, EvaluatorClassDtoProps>(allSchedClassNameFilter, EvaluatorClassDtoProps.title)
        );
        //Then sort what's left
        filteredClasses?.sort(getClassesSorter(allSchedClassesSorting));
        return filteredClasses;
    }, [allSchedClasses, allSchedClassNameFilter, allSchedClassesSorting]);

    const postSortFilterAllMyClasses: TraineeClassInfoDto[] | undefined = useMemo(() => {
        //Apply the filter
        const filteredClasses = allMyClasses?.filter(
            getFilterForClasses<TraineeClassInfoDto, TraineeClassInfoDtoProps>(allMyClassNameFilter, TraineeClassInfoDtoProps.className)
        );
        //Then sort what's left
        filteredClasses?.sort(getTraineeClassesSorter(allMyClassesSorting));
        return filteredClasses;
    }, [allMyClasses, allMyClassNameFilter, allMyClassesSorting]);

    //NETWORK CALL SECTION
    const fetchAllSchedClasses = useCallback(() => {
        if (!currentUser?.id) {
            return;
        }
        setIsFetchingAllSchedClasses(true);
        getClassesForCompanyByEvaluator(currentUser.id)
            .then((res: Array<EvaluatorClassDto>) => setAllSchedClasses(res ?? []))
            // .then((res: Array<EvaluatorClassDto>) => setAllSchedClasses(getMockClassesList(555) ?? []))
            .finally(() => setIsFetchingAllSchedClasses(false));
    }, [currentUser?.id]);
    const fetchAllMyClasses = useCallback(() => {
        if (!currentUser?.id) {
            return;
        }
        setIsFetchingAllMyClasses(true);
        getClassesForCompanyByTrainee(currentUser.id)
            .then((res: Array<TraineeClassInfoDto>) => setAllMyClasses(res ?? []))
            // .then((res: Array<EvaluatorClassDto>) => setAllMyClasses(getMockClassesList(555) ?? []))
            .finally(() => setIsFetchingAllMyClasses(false));
    }, [currentUser?.id]);

    const fetchFullEvaluation = useCallback(
        (inputs, setFullEvalDto) => {
            if (!currentUser?.id) {
                return;
            }
            setIsFetchingFullEvalDto(true);
            getCertificationEvaluationResultFull(inputs)
                .then((evaluation: FullEvaluationDto) => setFullEvalDto(evaluation))
                .catch((err) => setFullEvalDtoError("TODO Set up error handling on get"))
                .finally(() => setIsFetchingFullEvalDto(false));
        },
        [currentUser]
    );
    const postEvaluationResults = useCallback(
        (evaluationResultSaveDto: EvaluationResultSaveDto, action: EvalResultsSaveOptionsEnum): Promise<EvaluationResultSaveDto> => {
            if (action === EvalResultsSaveOptionsEnum.save) {
                setIsAwaitingPostEvaluationResultSave(true);
            } else if (action === EvalResultsSaveOptionsEnum.submit) {
                setIsAwaitingPostEvaluationResultSubmit(true);
            }
            return postEvaluationResultSaveDto(evaluationResultSaveDto).then((evaluationResult: EvaluationResultSaveDto) => {
                setPostEvaluationResult(true);
                if (action === EvalResultsSaveOptionsEnum.save) {
                    history.replace(Routings.classes);
                    setIsAwaitingPostEvaluationResultSave(false);
                } else if (action === EvalResultsSaveOptionsEnum.submit) {
                    setIsAwaitingPostEvaluationResultSubmit(false);
                }
                return evaluationResult;
            });
        },
        [history]
    );

    //All Classes Preview Section
    const [allMainClassesPreview, setAllMainClassesPreview] = useState<CertClassesPreviewForUserDto | undefined>(undefined);
    // loading
    const [isFetchingAllMainClassesPreview, setIsFetchingAllMainClassesPreview] = useState<boolean | undefined>(false);

    //Network call
    const fetchCertificationClassesPreview = useCallback(() => {
        if (!currentUser?.id) {
            return;
        }
        setIsFetchingAllMainClassesPreview(true);
        getCertificationClassesPreviewForUser(currentUser.id, NUMBER_OF_ITEMS_IN_PREVIEW)
            .then((res: CertClassesPreviewForUserDto) => setAllMainClassesPreview(res))
            .finally(() => setIsFetchingAllMainClassesPreview(false));
    }, [currentUser?.id]);

    //Reset all schedules classes related state to defaults
    const resetAllSchedClassesState = useCallback(() => {
        //Dto
        setAllSchedClasses(undefined);
        //Filters
        setAllSchedClassNameFilter(defaultAllClassesFilters.className);
        setAllSchedClassToShowCountFilter(defaultAllClassesFilters.numberOfItems);
        //Sort
        setAllSchedClassesSorting(defaultAllClassesSortDirection.dateSortDirection);
    }, []);
    const resetAllMyClassesState = useCallback(() => {
        //Dto
        setAllMyClasses(undefined);
        //Filters
        setAllMyClassNameFilter(defaultAllClassesFilters.className);
        setAllMyClassToShowCountFilter(defaultAllClassesFilters.numberOfItems);
        //Sort
        setAllMyClassesSorting(defaultAllClassesSortDirection.dateSortDirection);
    }, []);

    //Reset evluation page state
    const resetEvaluationState = useCallback(() => {
        //Dto
        setIsFetchingFullEvalDto(undefined);
        setFullEvalDtoError(undefined);
        // validate data
        setShowError(false);
        // save data
        setPostEvaluationResult(undefined);
        setIsAwaitingPostEvaluationResultSave(undefined);
        setIsAwaitingPostEvaluationResultSubmit(false);
    }, []);

    const currentClassesContext: IClassesContext = {
        //All Classes Preview
        fetchCertificationClassesPreview,
        allMainClassesPreview,
        setAllMainClassesPreview,
        isFetchingAllMainClassesPreview,

        //All Scheduled Classes
        postSortFilterAllSchedClasses,
        fetchAllSchedClasses,
        isFetchingAllSchedClasses,
        setAllSchedClasses,
        resetAllSchedClassesState,
        // sort
        allSchedClassesSorting,
        setAllSchedClassesSorting,
        // filter
        allSchedClassNameFilter,
        setAllSchedClassNameFilter,
        allSchedClassToShowCountFilter,
        setAllSchedClassToShowCountFilter,

        //All My Classes
        postSortFilterAllMyClasses,
        fetchAllMyClasses,
        isFetchingAllMyClasses,
        setAllMyClasses,
        resetAllMyClassesState,
        // sort
        allMyClassesSorting,
        setAllMyClassesSorting,
        // filter
        allMyClassNameFilter,
        setAllMyClassNameFilter,
        allMyClassToShowCountFilter,
        setAllMyClassToShowCountFilter,

        //Trainee Evaluation
        // get
        fetchFullEvaluation,
        isFetchingFullEvalDto,
        fullEvalDtoError,
        // post
        postEvaluationResults,
        postEvaluationResult,
        isAwaitingPostEvaluationResultSave,
        isAwaitingPostEvaluationResultSubmit,
        showError,
        setShowError,
        //seset evaluation state
        resetEvaluationState,
    };

    return <ClassesContext.Provider value={currentClassesContext}>{children}</ClassesContext.Provider>;
};

export default ClassesProvider;
