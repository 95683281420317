import React from "react";
import { Box, Typography } from "@material-ui/core";
import NavigationLink from "components/NavigationLink";
import { Routings } from "types/constants";
import { BackArrow } from "svgComponents";

interface BackToProfileProps {
    userId: string | undefined;
}

function BackToProfile({ userId }: BackToProfileProps) {
    return (
        <Box mb={4}>
            <NavigationLink href={userId ? `${Routings.teamProfile}/${userId}` : Routings.dashboard}>
                <Box display="flex" alignItems="center">
                    <BackArrow/>
                    <Box ml={2.5}>
                        <Typography>
                            Back to Profile
                        </Typography>
                    </Box>
                </Box>
            </NavigationLink>
        </Box>
    )
}

export default BackToProfile;
