import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    checkbox: {
        background: theme.colors.structure.background,
        borderRadius: "6px",
    },
    darkBlueText: {
        color: theme.colors.text.darkBlue,
    },
    redText: {
        color: "#DD3928",
    },
}));

export default useStyles;
