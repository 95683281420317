import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import RichText from "components/RichText";

import useStyles from "./styles";

interface RatingMeaningProps {
    title: string;
    body: string;
}

export default function RatingMeaning({ title, body }: RatingMeaningProps) {
    const classes = useStyles();
    
    return (
        <Paper className={classes.ratingMeaning}>
            <Box px={4} py={4}>
                <Box fontWeight="600">
                    <Typography variant="h2">{title}</Typography>
                </Box>
                <Box mt={1.5}>
                    <RichText text={body} />
                </Box>
            </Box>
        </Paper>
    )
}
