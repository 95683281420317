import React from "react";

import { Box, Typography } from "@material-ui/core";
import { Routings } from "types/constants";
import LinkButton from "components/LinkButton";
import { Success, Warning } from "svgComponents";

function ValidationError() {
    return (
        <Box pb={4}>
            <Box
                display="flex"
                alignItems="center"
                px={4} py={3}
                bgcolor="rgba(204, 71, 52, 0.1)"
                border="1px solid #CC4734"
                borderRadius="8px"
            >
                <Warning />
                <Box pl={3.5} fontWeight="600">
                    <Typography variant="h2">One or more required fields is missing.</Typography>
                </Box>
            </Box>
        </Box>
    )
}

function Submit() {
    return (
        <Box pb={6} pt={4}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={4} py={3}
                bgcolor="#ECF8EC"
                border="1px solid #3EB943"
                borderRadius="8px"
            >
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                        <Success stroke="#3EB943" />
                        <Box pl={3.5} fontWeight="600">
                            <Typography variant="h2">This coaching report has been submitted.</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="baseline" pl={6}>
                        <Typography variant="h3">User will be notified.</Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box mr={2} color="text.secondary">
                        <LinkButton
                            href={Routings.dashboard}
                            variant="outlined"
                            color="inherit"
                            size="large"
                        >
                            Go to Dashboard
                        </LinkButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default { ValidationError, Submit }