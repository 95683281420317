import { useState, useLayoutEffect, RefObject } from "react";

export default function useResizeObserver(
    ref: RefObject<HTMLElement>, 
    callback?: (width?: number, height?: number) => void
) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        if (!ref.current) return;

        setWidth(ref.current.getBoundingClientRect().width);
        setHeight(ref.current.getBoundingClientRect().height);

        const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            const [entry] = entries;
            const { width, height } = entry.contentRect;
            setWidth(width);
            setHeight(height);

            if (typeof callback === "function") callback(width, height);
        });
        resizeObserver.observe(ref.current);

        return () => resizeObserver.disconnect();
    }, [ref, callback]);

    return [width, height];
}