import React, { useCallback, useState } from "react";
import { Box, Button, CircularProgress, Dialog, Typography } from "@material-ui/core";

import { deleteFcrResource } from "services/resourceServices";
import { FcrResource } from "types";

interface DeleteResourceProps {
    resource: FcrResource;
    isOpen: boolean;
    onSubmit: (resource: FcrResource) => void;
    onClose: () => void;
}

const DeleteResource = ({ resource, isOpen, onSubmit, onClose }: DeleteResourceProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        await deleteFcrResource(resource.type, resource.id);
        onSubmit(resource);
        setIsLoading(false);
        onClose();
    }, [resource, onSubmit, onClose]);

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <Box p={4}>
                <Typography variant="h1" component="span">
                    Delete Resource
                </Typography>
            </Box>
            <Box py={3} px={4} bgcolor="#F6F8F9" fontWeight={500}>
                <Typography variant="body1">
                    Warning: Are you sure you want to delete {resource.name}?
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row-reverse" justifyContent="flex-start" p={4}>
                <Box ml={2.5}>
                    <Button variant="outlined" size="large" onClick={onClose}>
                        Cancel
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        disableElevation
                    >
                        {isLoading ? <CircularProgress size={24}/> : "Delete"}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default DeleteResource;