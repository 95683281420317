import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    graphPaper: {
        backgroundColor: theme.palette.info.main,
    },
    label: {
        opacity: 0.6,
    },
    timesCoached: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
}));

export default useStyles;
