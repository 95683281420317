import * as React from "react";

function SvgActionable(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Arrow-/-Right" fill="currentColor">
                    <path
                        d="M8.66569059,1.5 L15,8.00509673 L8.66529832,14.5 L8.00039227,13.8072643 L13.183,8.499 L1,8.5 L1,7.5 L13.176,7.499 L8,2.19192119 L8.66569059,1.5 Z"
                        id="Combined-Shape"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default SvgActionable;
