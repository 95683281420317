import React from "react";
import { Select } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const SummarySelect = styled(({ ...props }) => <Select { ...props } variant="outlined" />)(({ theme }) => ({
    "& [class*=input]": {
        backgroundColor: theme.palette.info.main,
        color: "#526b8b",
        lineHeight: 1.15,
        paddingBottom: theme.spacing(1.625),
        paddingTop: theme.spacing(1.625),
    },
    "& fieldset": {
        borderWidth: 0,
    },
}), {
    name: "SummarySelect",
})

export default SummarySelect;
