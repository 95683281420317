import React, { useCallback, useState, useMemo } from "react";
import { differenceWith } from "lodash";
import { Box, Popover, Typography, ButtonBase, Button, List, ListSubheader, CircularProgress } from "@material-ui/core";
import { CloseCross } from "svgComponents";
import { FcrResource, FcrResourceCategory } from "types";

import ResourceItem from "./ResourceItem";
import useStyles from "./styles";

interface AddResourceModalProps {
    closeModal: () => void;
    anchorEl: HTMLElement | null;
    userAvailableResources: Array<FcrResource>;
    resources: Array<FcrResource>;
    resourceCategories: Array<FcrResourceCategory>;
    saveResources: (resources: Array<FcrResource>) => Promise<void>;
}

function AddResourceModal({ saveResources, closeModal, anchorEl, userAvailableResources, resources, resourceCategories }: AddResourceModalProps) {
    const classes = useStyles();
    const [checkedResources, setCheckedResources] = useState<Array<FcrResource>>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleToggle = useCallback((value: FcrResource) => {
        const currentIndex = checkedResources.findIndex(checkedResource => value.id === checkedResource.id);
        const newChecked = [...checkedResources];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedResources(newChecked);
    }, [checkedResources]);

    const resourcesForDisplay = useMemo(() => differenceWith(
        userAvailableResources,
        resources,
        (a, b) => a.id === b.id),
    [resources, userAvailableResources]);

    const hasAvailableResources = useMemo(() => resourceCategories.reduce((currentState, category) => {
        const filteredResources = resourcesForDisplay.filter(resource => resource.resourceCategoryId === category.id);
        return (!!filteredResources.length) || currentState;
    }, false), [resourceCategories, resourcesForDisplay]);
    
    const saveResource = useCallback(async () => {
        setIsLoading(true);
        await saveResources(checkedResources);
        closeModal();
        setIsLoading(false);
    }, [checkedResources, saveResources, closeModal]);

    const renderSaveButton = () => {
        if (!hasAvailableResources) return (
            <Typography variant="caption">No available resources</Typography>
        );

        return (
            <Box pt={1.5}>
                <Button
                    disabled={!checkedResources.length || isLoading}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={saveResource}
                >
                    {isLoading ? <CircularProgress size={24} /> : `Add ${checkedResources.length || ""} Resource(s)`}
                </Button>
            </Box>
        )
    };

    return (
        <Popover
            open={true}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        >
            <Box px={3} pt={2.5} pb={3}>
                <Box
                    fontWeight="600" mb={2}
                    display="flex" justifyContent="space-between"
                >
                    <Typography variant="h2">Add Resources</Typography>
                    <Box pl={{ xs: 8, md: 15, lg: 30 }}>
                        <ButtonBase
                            aria-label="close"
                            onClick={closeModal}
                            disableRipple
                        >
                            <CloseCross />
                        </ButtonBase>
                    </Box>
                </Box>
                <List disablePadding style={{ display: "flex", flexDirection: "column", maxHeight: "75vh", overflow: "auto" }}>
                    {hasAvailableResources && resourceCategories.map(category => {
                        const filteredResources = resourcesForDisplay.filter(resource => resource.resourceCategoryId === category.id);

                        if (!filteredResources.length) return;

                        return (
                            <React.Fragment key={category.id}>
                                <ListSubheader
                                    disableSticky={true}
                                    className={classes.listSubHeader}
                                >
                                    {category.value}
                                </ListSubheader>
                                {filteredResources.map((resource) => (
                                    <ResourceItem
                                        key={resource.id}
                                        resource={resource}
                                        checkedResources={checkedResources}
                                        handleToggle={handleToggle}
                                    />
                                ))}
                            </React.Fragment>
                        );
                    })}
                </List>
                {renderSaveButton()}
            </Box>
        </Popover>
    )
}

export default AddResourceModal;
