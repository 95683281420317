import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    sessionItemWrap: {
        padding: theme.spacing(2),
    },
    sessionItem: {
        borderRadius: 4,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
        },
    },
    sessionItemClickable: {
        cursor: "pointer",
    },
    sessionItemCell: {
        display: "flex",
        alignItems: "center",
    },
    sessionItemCellText: {
        paddingLeft: theme.spacing(3),
    },
    sessionItemCellTextBold: {
        paddingLeft: theme.spacing(3),
        fontWeight: 500,
    },
    sessionItemStatus: {
        display: "inline-block",
        fontWeight: 500,
        borderRadius: 4,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        color: theme.palette.text.secondary,
        [theme.breakpoints.up("sm")]: {
            "$sessionItem:hover &": {
                display: "none",
            },
        },
    },
    sessionItemActions: {
        justifyContent: "flex-end",
        [theme.breakpoints.up("sm")]: {
            display: "none",
            "$sessionItem:hover &": {
                display: "flex",
            },
        },
        "& .MuiGrid-item": {
            display: "flex",
            alignItems: "center",
        },
    },
    link: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        "&, &:hover": {
            textDecoration: "underline",
        },
    },
    bold: {
        fontWeight: 500,
    },
}));

export default useStyles;
