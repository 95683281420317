import React, { useMemo, useState, useContext } from "react";
import { Box, MenuItem, Select, Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { SortDirectionEnum, FcrStatusEnum, CoachingSessionStatusEnum } from "types/constants";
import { CompanyLevel, UserProfile } from "types";
import { CoachingHubContext } from "context";
import { UsersAutocomplete } from "components/UsersAutocomplete";
import { TuneIcon } from "svgComponents";
import { isHighLevel } from "helpers/companyHelper";
import { getUsersByManagerId } from "services/userServices";
import useLocationStateParam from "hooks/useLocationStateParam";

import useStyles from "./styles";

export interface FiltersData<S> {
    sortDirection: SortDirectionEnum;
    status: S | "";
    memberId: string | null;
    leaderId: string | null;
}

interface FiltersProps<S> {
    currentUser?: UserProfile;
    currentTeam?: UserProfile[];
    companyLevels?: CompanyLevel[];
    membersBelow: UserProfile[];
    coachesBelow?: UserProfile[];
    loading: boolean;
    statusOptions?: { value: S, label: string }[];
    setPage: (page: number) => void;
}

const TuneFilterIcon = (props: unknown) => <TuneIcon height={24} {...props} />;

function CoachingSessionFilters<S extends string>({
    currentUser,
    currentTeam,
    companyLevels,
    membersBelow,
    coachesBelow,
    loading,
    statusOptions,
    setPage,
}: FiltersProps<S>) {
    const classes = useStyles();
    const sessionIdStateParam = useLocationStateParam("sessionId");
    const { fetchCoachingSessions, setIsFetchingByFilters, setCoachingHubFilters, isFetchingByFilters, coachingHubFilters } = useContext(CoachingHubContext);

    const isHighLevelUser = currentUser && companyLevels && isHighLevel(currentUser.level, companyLevels);

    const [sortDirection, setSortDirection] = useState<SortDirectionEnum>(SortDirectionEnum.desc);
    const [status, setStatus] = useState<S | "">("");
    const [memberId, setMemberId] = useState<string>("");
    const [leaderId, setLeaderId] = useState<string>(isHighLevelUser && currentUser && !sessionIdStateParam ? currentUser.id : "");

    const [membersByLeaders, setMembersByLeaders] = useState<{ [key: string]: UserProfile[] }>(
        isHighLevelUser && currentUser && currentTeam ? { [currentUser.id]: currentTeam } : {}
    );

    const coaches = useMemo(() => [...(coachesBelow ?? []), ...(currentUser ? [currentUser] : [])], [coachesBelow, currentUser]);
    const members = useMemo(() => leaderId ? (membersByLeaders[leaderId] ?? []) : membersBelow, [leaderId, membersByLeaders, membersBelow]);

    const handleSortDirectionChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const sortDirection = e.target.value as SortDirectionEnum;
        setSortDirection(sortDirection);
    };

    const handleStatusChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const status = e.target.value as S;
        setStatus(status);
    };

    const handleLeaderChange = (leaderId: string | null) => {
        setLeaderId(leaderId ?? "");
        if (leaderId) setMemberId("");

        if (leaderId && !membersByLeaders[leaderId]) {
            getUsersByManagerId(leaderId).then((users) => {
                setMembersByLeaders({ ...membersByLeaders, [leaderId]: users });
            });
        }
    };

    const handleMemberChange = (memberId: string | null) => {
        setMemberId(memberId ?? "");
    };

    const handleSubmit = () => {
        const filters = {
            coachId: isHighLevelUser && leaderId ? leaderId : null,
            assigneeId: isHighLevelUser && memberId ? memberId : null,
            userId: !isHighLevelUser && memberId ? memberId : null,
            status: status === CoachingSessionStatusEnum.completed ? FcrStatusEnum.completed : null,
            orderDescending: sortDirection === SortDirectionEnum.desc,
            returnStarted: coachingHubFilters?.returnStarted || null,
            getOnlyUpcoming: status === CoachingSessionStatusEnum.upcoming,
            getOnlyIncomplete: status === CoachingSessionStatusEnum.incomplete,
            state: status && status !== CoachingSessionStatusEnum.completed ? status : null,
            excludeAssigneesMarkedFinishLater: coachingHubFilters?.excludeAssigneesMarkedFinishLater || false,
        }
        
        setCoachingHubFilters(filters);
        setIsFetchingByFilters(true);
        setPage(1);
        fetchCoachingSessions(1, filters);
    };
    
    return (
        <Box className={classes.filtersContainer}>
            {isHighLevelUser && (
                <Box minWidth={220}>
                    <UsersAutocomplete
                        label="Team Leader"
                        allUsersOption="All Team Leaders"
                        userProfiles={coaches}
                        popupIcon={<FilterListIcon />}
                        value={leaderId}
                        onChange={handleLeaderChange}
                        disabled={loading}
                    />
                </Box>
            )}
            <Box minWidth={220}>
                <UsersAutocomplete
                    label="Team Member"
                    allUsersOption="All Team Members"
                    userProfiles={members}
                    popupIcon={<FilterListIcon />}
                    value={memberId}
                    onChange={handleMemberChange}
                    disabled={loading}
                    loading={!!leaderId && !membersByLeaders[leaderId]}
                />
            </Box>
            {statusOptions && (
                <Select
                    variant="outlined"
                    value={status}
                    onChange={handleStatusChange}
                    IconComponent={FilterListIcon}
                    disabled={loading}
                    displayEmpty
                    className={classes.filter}
                >
                    <MenuItem value="">
                        All Statuses
                    </MenuItem>
                    {statusOptions.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            )}
            <Select
                variant="outlined"
                value={sortDirection}
                onChange={handleSortDirectionChange}
                IconComponent={TuneFilterIcon}
                disabled={loading}
                className={classes.filter}
            >
                <MenuItem value={SortDirectionEnum.desc}>
                    Most Recent
                </MenuItem>
                <MenuItem value={SortDirectionEnum.asc}>
                    Oldest
                </MenuItem>
            </Select>
            <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                disabled={isFetchingByFilters}
            >
                <Box lineHeight="30px">
                    Apply
                </Box>
            </Button>
        </Box>
    );
}

export default React.memo(CoachingSessionFilters) as typeof CoachingSessionFilters;
