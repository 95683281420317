import * as React from "react";

function SvgDiamond (props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={16}
            height={14}
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M15.0814 4.23047H0.53125" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.53125 4.23047L7.80211 13.4735L15.0814 4.23047" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.523438 4.23081L3.44363 0.523438H12.1704L15.0821 4.23081" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.38281 4.24609L7.81207 13.4722L5.38281 4.24609Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.23 4.24609L7.80078 13.4722L10.23 4.24609Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.2319 4.24774L9.25 0.523438" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.38281 4.24774L6.35621 0.523438" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgDiamond;
