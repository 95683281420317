import intersectionWith from "lodash/intersectionWith";
import omit from "lodash/omit";
import {
    DevelopmentGoals, CoachingPlanType, FcrResult, Company, FcrConfiguration,
    FcrCoachingSkill, FcrCoachingSkillObservation, FcrStatus, FcrResultPdf,
    FcrItemConfiguration, FcrItemResult, FcrCoachingSkillHistory, SkillOverviewHistory,
} from "types";
import { UserCoachingDevelopment, UserCoachingHistory } from "types/CoachHistory";
import { AuthProviderEnum, CoachingPlanStatusEnum, ComponentType } from "types/constants";

import { v4 as uuidv4 } from "uuid";

import API from "./apiService";

export const getCompany = async (companyId: string): Promise<Company> => {
    const result = await API.get(`/company/${companyId}`);
    return result.data;
}

//todo: change type to be IdValue type
export const getFcrStatuses = async (): Promise<FcrStatus[]> => {
    const result = await API.get("/fcrConfiguration/fcrStatuses", {
        cache: {
            maxAge: 24 * 60 * 60 * 1000,
        },
    });
    return result.data;
}

export const getCompanyByUser = async (userId: string): Promise<Company> => {
    const result = await API.get(`/company/getCompanyByUser/${userId}`);
    return result.data;
}

export const getCurrentFcrConfiguration = async (companyId: string): Promise<FcrConfiguration> => {
    const result = await API.get(`/fcrConfiguration/getCurrentForCompany/${companyId}`);
    return result.data;
}

export const postFcrConfiguration = async (
    payload: FcrConfiguration,
    authorizationProvider = AuthProviderEnum.B2C
): Promise<FcrConfiguration> => {
    const headers = {
        AuthorizationProvider: authorizationProvider,
    };
    const response = await API.post(`/fcrConfiguration/${payload.id}`, payload, { headers });
    return response.data;
}

export const getFcrConfigurationForUser = async (fcrConfigurationId: string, userId: string): Promise<FcrConfiguration> => {
    const { data } = await API.get(`/fcrConfiguration/getForUser/${fcrConfigurationId}/${userId}`);
    return data;
}

export const getFcrConfigurationForUserFcrResult = async (fcrResultId: string, userId: string): Promise<FcrConfiguration> => {
    const { data } = await API.get(`/fcrConfiguration/getForUserFcrResult/${fcrResultId}/${userId}`);
    return data;
}

export const getFcrResultsByUser = async (userId: string): Promise<FcrResult[]> => {
    const results = await API.get(`/fcrResult/getFcrResultsByUser/${userId}`);
    return results.data;
}

export const getFcrResultById = async (fcrResultId: string): Promise<FcrResult> => {
    const { data } = await API.get(`/fcrResult/${fcrResultId}`);
    return data;
}

export const saveFcrResult = async (fcrResult: Partial<FcrResult>): Promise<FcrResult> => {
    if (!fcrResult.id) {
        const newFcrResult = {
            ...fcrResult,
            id: uuidv4(),
        };
        const result = await API.post(`/fcrResult/${newFcrResult.id}`, newFcrResult);
        return result.data;
    } else {
        const result = await API.post(`/fcrResult/${fcrResult.id}`, fcrResult);
        return result.data;
    }
}

export const submitFcrResult = async (fcrResultId: string): Promise<boolean> => {
    const result = await API.post(`/fcrResult/${fcrResultId}/submit`);
    return result.data;
}

export const resendEmailForFcrResult = async (fcrResultId: string): Promise<boolean> => {
    const result = await API.post(`/fcrResult/${fcrResultId}/email`);
    return result.data;
}

export const getFcrResultPdfUrl = async (fcrResultId: string): Promise<FcrResultPdf> => {
    const result = await API.get(`/fcrResult/${fcrResultId}/pdf`);
    return result.data;
}

export const readByCoacheeFcrResult = async (fcrResultId: string, date?: string): Promise<boolean> => {
    const result = await API.post(`/fcrResult/${fcrResultId}/readByCoachee${date ? `?date= ${date}` : ""}`);
    return result.data;
}

export const readByCoachFcrResult = async (fcrResultId: string, date?: string): Promise<boolean> => {
    const result = await API.post(`/fcrResult/${fcrResultId}/readByCoach${date ? `?date= ${date}` : ""}`);
    return result.data;
}

export const acknowledgeFcrResult = async (fcrResultId: string, comment: string): Promise<boolean> => {
    const result = await API.post(`/fcrResult/${fcrResultId}/acknowledge`, {
        acknowledgementDate: new Date().toISOString(),
        ...(comment && { acknowledgementComment: comment }),
    });
    return result.data;
}

export const getDevelopmentGoal = async (developmentGoalId: string): Promise<DevelopmentGoals> => {
    const result = await API.get(`/fcrDevelopmentGoal/getFcrDevelopmentGoal/${developmentGoalId}`);
    return result.data;
}

export const getDevelopmentGoals = async (fcrResultId: string): Promise<DevelopmentGoals[]> => {
    const result = await API.get(`/fcrDevelopmentGoal/getForFcrResult/${fcrResultId}`);
    return result.data;
}

export const saveDevelopmentGoal = async (developmentGoal: DevelopmentGoals): Promise<DevelopmentGoals> => {
    const result = await API.post(`/fcrDevelopmentGoal/${developmentGoal.id}`, developmentGoal);
    return result.data;
};

//Coaching Skills and Results: start

export const getCoachingSkillsForFcrConfiguration = async (configurationId: string): Promise<Array<FcrCoachingSkill>> => {
    const result = await API.get(`/fcrCoachingSkill/getForFcrConfiguration/${configurationId}`);
    return result.data;
}

export const getCoachingSkillsForFcrConfigurationAndUser = async (configurationId: string, userId: string): Promise<Array<FcrCoachingSkill>> => {
    const result = await API.get(`/fcrCoachingSkill/getForFcrConfigurationAndUser/${configurationId}/${userId}`);
    return result.data;
}

// No need for now. The actual data comes from the backend with coaching skill
// Todo: remove after applying leading info for component

// export const getCoachingSkillRatings = async (fcrCoachingSkillId: string): Promise<Array<FcrCoachingSkillRating>> => {
//     const result = await API.get(`/fcrCoachingSkillRating/getForFcrCoachingSkill/${fcrCoachingSkillId}`);
//     return result.data;
// }

// async function fillCoachingSkillsWithRatings(fcrCoachingSkills: Array<FcrCoachingSkill>) {
//     const newArray = fcrCoachingSkills.map(async (element) => {
//         const csRatings = await getCoachingSkillRatings(element.id);
//         return {
//             ...element,
//             fcrCoachingSkillRatings: csRatings,
//         };
//     });

//     return Promise.all(newArray);
// }

export const getCoachingSkillObservationsForFcrResult = async (fcrResultId: string, fcrCoachingSkills: Array<FcrCoachingSkill>): Promise<Array<FcrCoachingSkillObservation>> => {
    const result = await API.get(`/fcrCoachingSkillResult/getForFcrResult/${fcrResultId}`);

    if (result.data) {
        return (result.data as Array<FcrCoachingSkillObservation>).map((element) => {
            const fcrCoachingSkill = fcrCoachingSkills.find((skill) => skill.id === element.fcrCoachingSkillId);
            if (fcrCoachingSkill != undefined)
                return {
                    ...element,
                    fcrCoachingSkill: fcrCoachingSkill,
                    fcrCoachingSkillId: fcrCoachingSkill.id,
                    fcrCoachingSkillName: fcrCoachingSkill.name,
                };
            return element;
        });
    }

    return result.data;
}

export const getCoachingSkillObservation = async (fcrCoachingSkillObservationId: string): Promise<FcrCoachingSkillObservation> => {
    const result = await API.get(`/fcrCoachingSkillResult/${fcrCoachingSkillObservationId}`);
    return result.data;
}

export const saveCoachingSkillObservation = async (fcrCoachingSkillObservation: FcrCoachingSkillObservation): Promise<FcrCoachingSkillObservation> => {
    if (!fcrCoachingSkillObservation.id) {
        const newRecord = Object.assign({ id: uuidv4() }, fcrCoachingSkillObservation);
        const result = await API.post(`/fcrCoachingSkillResult/${newRecord.id}`, newRecord);
        return result.data;
    } else {
        const result = await API.post(`/fcrCoachingSkillResult/${fcrCoachingSkillObservation.id}`, fcrCoachingSkillObservation);
        return result.data;
    }
};

export const saveCoachingSkillObservations = async (fcrResultId: string, fcrCoachingSkillObservations: Array<FcrCoachingSkillObservation>): Promise<Array<FcrCoachingSkillObservation>> => {
    const newArray = fcrCoachingSkillObservations.map((element) => ({
        ...omit(element, ["isIgnored"]),
        id: element.id || uuidv4(),
    }));

    const result = await API.post(`/fcrCoachingSkillResult/saveFcrCoachingSkillResults/${fcrResultId}`, newArray);
    return result.data;
};

export const deleteCoachingSkillObservation = async (fcrCoachingSkillObservationId: string): Promise<boolean> => {
    const result = await API.delete(`/fcrCoachingSkillResult/${fcrCoachingSkillObservationId}`);
    return result.data;
};

//Coaching Skills and Results: end

//Coaching Plans: start

export const getFcrCoachingPlan = async (fcrCoachingPlanId: string): Promise<CoachingPlanType> => {
    const result = await API.get(`/fcrCoachingPlan/${fcrCoachingPlanId}`);
    return result.data;
}

export const saveFcrCoachingPlan = async (coachingPlan: CoachingPlanType): Promise<CoachingPlanType> => {
    if (!coachingPlan.id) {
        const newRecord = {
            ...coachingPlan,
            id: uuidv4(),
        };
        const result = await API.post(`/fcrCoachingPlan/${newRecord.id}`, newRecord);
        return result.data;
    } else {
        const result = await API.post(`/fcrCoachingPlan/${coachingPlan.id}`, coachingPlan);
        return result.data;
    }
};

export const changeFcrCoachingPlanStatus = async (
    fcrCoachingPlanId: string,
    status: CoachingPlanStatusEnum
): Promise<CoachingPlanType> => {
    const response = await API.post(`/fcrCoachingPlan/${fcrCoachingPlanId}/setStatus/${status}`);
    return response.data;
}

export const saveCoachingPlans = async (coachingPlans: Array<CoachingPlanType>): Promise<Array<CoachingPlanType>> =>
    Promise.all(coachingPlans.map(async (coachingPlan) => saveFcrCoachingPlan(coachingPlan)));

export const deleteFcrCoachingPlan = async (fcrCoachingPlanId: string): Promise<boolean> => {
    const result = await API.delete(`/fcrCoachingPlan/${fcrCoachingPlanId}`);
    return result.data;

};

export const getFcrCoachingPlansForFcrResult = async (fcrResultId: string, fcrCoachingSkillObservations: Array<FcrCoachingSkillObservation>): Promise<Array<CoachingPlanType>> => {
    const result = await API.get(`/fcrCoachingPlan/getForFcrResult/${fcrResultId}`);

    if (result.data) {
        return (result.data as Array<CoachingPlanType>).map((element) => {
            const fcrCoachingSkillObservation = fcrCoachingSkillObservations.find((res) => res.id == element.fcrCoachingSkillResultId);
            if (fcrCoachingSkillObservation != undefined) {
                return {
                    ...element,
                    fcrCoachingSkillObservation: fcrCoachingSkillObservation,
                    fcrCoachingSkillName: fcrCoachingSkillObservation.fcrCoachingSkillName,
                };
            } else {
                return element;
            }
        });
    }

    return result.data;
};

export const constructCoachingPlans = (coachingPlans: CoachingPlanType[], fcrCoachingSkillObservations: FcrCoachingSkillObservation[]) =>
    coachingPlans.map((element) => {
        const fcrCoachingSkillObservation = fcrCoachingSkillObservations.find((res) => res.id == element.fcrCoachingSkillResultId);
        if (fcrCoachingSkillObservation != undefined) {
            return {
                ...element,
                fcrCoachingSkillObservation: fcrCoachingSkillObservation,
                fcrCoachingSkillName: fcrCoachingSkillObservation.fcrCoachingSkillName,
            };
        } else {
            return element;
        }
    });

export const getFcrCoachingPlansForFcrCoachingSkillObservation = async (fcrCoachingSkillObservationId: string): Promise<Array<CoachingPlanType>> => {
    const result = await API.get(`/fcrCoachingPlan/getForFcrCoachingSkillResult/${fcrCoachingSkillObservationId}`);
    return result.data;
}

export const getAllFcrCoachingPlanByUserId = async (userId: string): Promise<CoachingPlanType[]> => {
    const result = await API.get(`/fcrCoachingPlan/getAllForUser/${userId}`);
    return result.data;
}

//Coaching Plans: end

export const saveFcrStep = async (step: number) => {
    // eslint-disable-next-line
    console.log(step);
    // TODO: Save data
    return step;
}

//Fcr Items: start

export const postFcrItemConfiguration = async (payload: FcrItemConfiguration): Promise<FcrItemConfiguration> => {
    const response = await API.post(`/fcrItemConfiguration/${payload.id}`, payload);
    return response.data;
}

export const deleteFcrItemConfiguration = async (id: string): Promise<string> => {
    const response = await API.delete(`/fcrItemConfiguration/${id}`);
    return response.data;
}

export const getFcrItemsForFcrConfiguration = async (fcrConfigurationId: string): Promise<Array<FcrItemConfiguration>> => {
    const response = await API.get(`/fcrItemConfiguration/getForFcrConfiguration/${fcrConfigurationId}`);

    // Filter by hardcoded types of configuration
    const result = intersectionWith(
        response.data,
        Object.values(ComponentType),
        (itemConfiguration: FcrItemConfiguration, typeId: string) => itemConfiguration.fcrItemTypeId === typeId
    );

    return result;
}

export const getFcrItemResultsForFcrResult = async (fcrResultId: string): Promise<Array<FcrItemResult>> => {
    const result = await API.get(`/fcrItemResult/getForFcrResult/${fcrResultId}`);
    return result.data;
}

export const saveFcrItemResults = async (fcrResultId: string, fcrItemResults: Array<FcrItemResult>): Promise<Array<FcrItemResult>> => {
    const newArray = fcrItemResults.map((element) => ({
        ...element,
        id: element.id || uuidv4(),
    }));

    const result = await API.post(`/fcrItemResult/saveFcrItemResults/${fcrResultId}`, newArray);
    return result.data;
};

//Fcr Items: end

//Skill History: start

export const getUserSkillHistory = async (userId: string, onlyActive: boolean, timePeriod = 12): Promise<Array<FcrCoachingSkillHistory>> => {
    const result = await API.get<FcrCoachingSkillHistory[]>(`/history/userSkills/${userId}/${timePeriod}?onlyActive=${onlyActive}`);
    return result.data;
}

export const getTeamSkillHistoryForCoach = async (coachId: string, timePeriod = 12): Promise<Array<FcrCoachingSkillHistory>> => {
    const result = await API.get(`/history/teamSkills/${coachId}/${timePeriod}`);
    return result.data;
}

export const getTeamSkillHistoryForHighLevelLead = async (coachId: string, timePeriod = 12): Promise<Array<FcrCoachingSkillHistory>> => {
    const result = await API.get(`/history/teamSkillsForHighLevelLead/${coachId}/${timePeriod}`);
    return result.data;
}

export const getCoachSkillOverviewHistory = async (coachId: string, skillId: string): Promise<SkillOverviewHistory> => {
    const result = await API.get(`/history/coachSkillOverviewHistory/${coachId}/${skillId}`);
    return result.data;
};

export const getCoachSkillOverviewHistoryForHighLevelLead = async (leadId: string, skillId: string): Promise<SkillOverviewHistory> => {
    const result = await API.get(`/history/coachSkillOverviewHistoryForHighLevelLead/${leadId}/${skillId}`);
    return result.data;
};

//Skill History: end

// Coaching History: start
export const getUserCoachingDevelopment = async (userId: string): Promise<UserCoachingDevelopment> => {
    const response = await API.get(`/history/userCoachingDevelopment/${userId}`);
    return response.data;
}

export const getCoachingHistoryForCoach = async (coachId: string): Promise<UserCoachingHistory> => {
    const response = await API.get(`/history/coachCoachingHistory/${coachId}`);
    return response.data;
}

export const getCoachingHistoryForHighLevelLead = async (leadId: string): Promise<UserCoachingHistory> => {
    const response = await API.get(`/history/coachingHistoryForHighLevelLead/${leadId}`);
    return response.data;
}
// Coaching History: end