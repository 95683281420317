import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, Link, Tooltip, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { orderBy } from "lodash";

import { DeleteIcon, BackArrow } from "svgComponents";
import { FcrStatusEnum } from "types/constants";
import { ConfigurationContext } from "context";
import { setTime } from "helpers/dateHelpers";
import { saveCoachingSession, deleteCoachingSession, getCoachingSessionTypesForFcrConf } from "services/coachingSessionServices";
import { FcrConfiguration, UserProfile, CoachingSession, CoachingSessionType } from "types";
import LoadingButton from "components/LoadingButton";
import ConfirmationModal from "components/ConfirmationModal";
import usePrevious from "hooks/usePrevious";

import CoachingSessionTypeSelect from "./CoachingSessionTypeSelect";
import CoachingSessionDatePicker from "./CoachingSessionDatePicker";
import CoachingSessionTimePicker from "./CoachingSessionTimePicker";
import CoachingSessionUserSelect from "./CoachingSessionUserSelect";
import UserLabel from "../UserLabel";

import useStyles from "./styles";

export interface ScheduleCoachingSessionMessages {
    newSessionHeader?: string | React.ReactNode;
    editSessionHeader?: string | React.ReactNode;
    newSessionSubmitButton?: string | React.ReactNode;
    editSessionSubmitButton?: string | React.ReactNode;
    bodyTitle?: string | React.ReactNode;
}

interface ScheduleCoachingSessionProps {
    user?: UserProfile;
    usersBelow?: UserProfile[] | null;
    toggleNewSession?: (e: React.SyntheticEvent) => void;
    onClose: () => void;
    onDelete?: (coachingSession: CoachingSession) => void;
    onSuccess?: (coachingSession: CoachingSession) => void;
    fcrConfiguration?: FcrConfiguration;
    coachingSession?: CoachingSession;
    messages?: ScheduleCoachingSessionMessages;
    createFcr?: boolean;
    selectedUserIdExternal?: string | null;
    setSelectedUserIdExternal?: (selectedUserId: string | null) => void;
}

const defaultMessages: ScheduleCoachingSessionMessages = {
    newSessionHeader: "Schedule New Session",
    editSessionHeader: "Update session",
    newSessionSubmitButton: "Schedule Session & Start Report",
    editSessionSubmitButton: "Update Session",
    bodyTitle: "Schedule Details",
};

const ScheduleCoachingSession = ({
    user,
    toggleNewSession,
    onClose,
    onDelete,
    onSuccess,
    fcrConfiguration,
    coachingSession,
    messages: messagesProp,
    createFcr = true,
    usersBelow,
    selectedUserIdExternal,
    setSelectedUserIdExternal,
}: ScheduleCoachingSessionProps) => {
    const classes = useStyles();
    const { currentUser, coacheeTeam } = useContext(ConfigurationContext);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(selectedUserIdExternal || user?.id || null);
    const previousSelectedUserId = usePrevious(selectedUserId);
    const [coachingSessionTypes, setCoachingSessionTypes] = useState<CoachingSessionType[] | null>(null);
    const [sessionType, setSessionType] = useState<string>("");
    const [sessionDate, setSessionDate] = useState(coachingSession?.scheduleStartDate ? new Date(coachingSession?.scheduleStartDate) : new Date());
    const [sessionStartTime, setSessionStartTime] = useState<Date | null>(!coachingSession?.isAllDayEvent && coachingSession?.scheduleStartDate && new Date(coachingSession?.scheduleStartDate) || null);
    const [sessionEndTime, setSessionEndTime] = useState<Date | null>(!coachingSession?.isAllDayEvent && coachingSession?.scheduleEndDate && new Date(coachingSession?.scheduleEndDate) || null);
    const [isAllDay, setIsAllDay] = useState(!!coachingSession?.isAllDayEvent);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteConfirmationShown, setIsDeleteConfirmationShown] = useState(false);
    const coachId = (user?.id === currentUser?.id) ? coacheeTeam?.teamLeader?.id : currentUser!.id;
    const isUserSessionLeader = coachId === currentUser?.id;
    const isSessionCreatedByUser = coachingSession?.createdById === currentUser?.id;

    useEffect(() => {
        if (selectedUserIdExternal && setSelectedUserIdExternal && selectedUserIdExternal !== selectedUserId) {
            setSelectedUserId(selectedUserIdExternal)
            setSelectedUserIdExternal(selectedUserIdExternal)
        }
    }, [previousSelectedUserId, selectedUserId, selectedUserIdExternal, setSelectedUserIdExternal]);

    useEffect(() => {
        if (selectedUserId && setSelectedUserIdExternal && selectedUserId !== previousSelectedUserId && previousSelectedUserId !== null) {
            setSelectedUserId(selectedUserId)
            setSelectedUserIdExternal(selectedUserId)
        }
    }, [previousSelectedUserId, selectedUserId, setSelectedUserIdExternal]);

    useEffect(() => {
        const formatCoachingSessionTypes = (sessionTypes: CoachingSessionType[]) => (
            orderBy(sessionTypes.filter(({ isActive }) => isActive), ({ value }) => value.toLowerCase())
        );

        if (coachingSession?.fcrResult?.fcrConfigurationId) {
            getCoachingSessionTypesForFcrConf(coachingSession.fcrResult.fcrConfigurationId)
                .then((coachingSessionTypes) => {
                    setCoachingSessionTypes(formatCoachingSessionTypes(coachingSessionTypes));
                    setSessionType(coachingSession.coachingSessionTypeId);
                });
        } else if (fcrConfiguration?.coachingSessionTypes) {
            const coachingSessionTypes = formatCoachingSessionTypes(fcrConfiguration.coachingSessionTypes);
            setCoachingSessionTypes(coachingSessionTypes);
            setSessionType(coachingSessionTypes[0]?.id || "");
        }
    }, [coachingSession?.fcrResult?.fcrConfigurationId, coachingSession?.coachingSessionTypeId, fcrConfiguration?.coachingSessionTypes]);

    const isSubmitEnabled = useMemo(
        () => selectedUserId && sessionType && sessionDate && (
            (sessionStartTime && sessionEndTime && sessionStartTime < sessionEndTime) || isAllDay
        ),
        [selectedUserId, sessionType, sessionDate, sessionStartTime, sessionEndTime, isAllDay]
    );

    const handleSubmit = useCallback(() => {
        if (!isSubmitEnabled || isLoading || !fcrConfiguration || !coachId) return;

        const newCoachingSessionWithoutFcr = {
            assigneeId: selectedUserId!,
            coachId: coachId,
            createdById: coachingSession?.createdById ?? currentUser!.id,    // TODO: redundant, remove later
            ...(coachingSession ? coachingSession : {}),
            modifiedById: currentUser!.id,    // TODO: redundant, remove later
            ...(isAllDay ? {
                scheduleStartDate: sessionDate.toISOString(),
            } : {
                scheduleStartDate: sessionStartTime ? setTime(sessionDate, sessionStartTime) : undefined,
                scheduleEndDate: sessionEndTime ? setTime(sessionDate, sessionEndTime) : undefined,
            }),
            isAllDayEvent: isAllDay,
            coachingSessionTypeId: sessionType,
        };

        const newCoachingSession = !coachingSession && createFcr
            ? {
                ...newCoachingSessionWithoutFcr,
                fcrResult: {
                    id: uuidv4(),
                    fcrConfigurationId: fcrConfiguration!.id,
                    assigneeId: selectedUserId!,
                    coachId: coachId,
                    companyId: currentUser!.companyId,
                    createdById: currentUser!.id,    // TODO: redundant, remove later
                    modifiedById: currentUser!.id,   // TODO: redundant, remove later
                    fcrResultStatusId: FcrStatusEnum.new,
                },
            }
            : newCoachingSessionWithoutFcr;

        setIsLoading(true);
        saveCoachingSession(newCoachingSession)
            .then((savedCoachingSession) => {
                onClose();
                if (onSuccess) onSuccess(savedCoachingSession);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [
        currentUser, fcrConfiguration, isAllDay, isLoading, isSubmitEnabled, onClose, onSuccess,
        sessionDate, sessionEndTime, sessionStartTime, sessionType, selectedUserId, createFcr,
        coachingSession, coachId,
    ]);

    const handleDelete = useCallback(() => {
        if (isLoading || isDeleting || !coachingSession) return;

        setIsDeleting(true);
        deleteCoachingSession(coachingSession.id)
            .then(() => {
                onClose();
                if (typeof onDelete === "function") onDelete(coachingSession);
            })
            .finally(() => {
                setIsDeleteConfirmationShown(false);
                setIsDeleting(false);
            });
    }, [coachingSession, isLoading, isDeleting, onClose, onDelete]);

    const messages = useMemo(() => ({ ...defaultMessages, ...(messagesProp ?? {}) }), [messagesProp]);

    const labelText = useMemo(() => (
        coachingSession ? messages.editSessionHeader : messages.newSessionHeader
    ), [coachingSession, messages]);

    const submitText = useMemo(() => {
        if (isLoading) return <CircularProgress size={24} />;
        else if (coachingSession) return messages.editSessionSubmitButton;
        else return messages.newSessionSubmitButton;
    }, [isLoading, coachingSession, messages]);

    const handleDeleteClick = useCallback(() => setIsDeleteConfirmationShown(true), []);
    
    const handleDeleteCancelClick = useCallback(() => setIsDeleteConfirmationShown(false), []);

    const renderDeleteButton = () => {
        if (!coachingSession) return null;

        const isDisabled = !coachingSession?.canBeDeleted || !(isSessionCreatedByUser || isUserSessionLeader);

        const button = (
            <LoadingButton
                loading={isDeleting}
                className={classes.deleteButton}
                aria-label="Delete"
                variant="outlined"
                color="inherit"
                size="large"
                loadingLabel={null}
                disabled={isDisabled}
                onClick={handleDeleteClick}
            >
                <DeleteIcon height={24} />
            </LoadingButton>
        );

        if (isDisabled) {
            return (
                <Tooltip title="Coaching Reports cannot be deleted if they contain text in Step 1 or have selected Skills in Step 2" placement="bottom" arrow>
                    <Box>{button}</Box>
                </Tooltip>
            );
        }

        return button;
    };

    if (isDeleteConfirmationShown) {
        return coachingSession ? (
            <ConfirmationModal
                isOpen={true}
                header="Delete session"
                body={`Are you sure you want to delete this Session${coachingSession?.fcrResult ? " and associated Coaching Report" : ""}?`}
                confirmBtn="Proceed"
                isLoading={isDeleting}
                loadingLabel="Deleting"
                onConfirm={handleDelete}
                onClose={handleDeleteCancelClick}
            />
        ) : null;
    }

    return (
        <>
            <Box py={3} px={4}>
                {!coachingSession && toggleNewSession && <Link href="#" color="inherit" onClick={toggleNewSession}>
                    <Box display="flex" alignItems="center" mb={1}>
                        <BackArrow />
                        <Box ml={1.5}>
                            Back to Sessions
                        </Box>
                    </Box>
                </Link>}
                <Box display="flex" alignItems="center">
                    <Typography variant="h1" component="span">
                        {labelText}{user ? ":" : ""}
                    </Typography>
                    {user && <UserLabel user={user} />}
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                pt={3} pb={5} px={4}
                bgcolor="#F6F8F9"
            >
                <Typography variant="h2" component="span">
                    {messages.bodyTitle}
                </Typography>
                {!user && (
                    <Box mt={3.5}>
                        <CoachingSessionUserSelect
                            value={selectedUserId}
                            userProfiles={usersBelow ?? []}
                            loading={!usersBelow}
                            onChange={setSelectedUserId}
                        />
                    </Box>
                )}
                <Box mt={3.5}>
                    <CoachingSessionTypeSelect
                        value={sessionType}
                        onChange={setSessionType}
                        coachingSessionTypes={coachingSessionTypes}
                    />
                </Box>
                <Box mt={3.5}>
                    <CoachingSessionDatePicker value={sessionDate} onChange={setSessionDate} />
                </Box>
                <Box mt={3.5}>
                    <CoachingSessionTimePicker
                        startTime={sessionStartTime}
                        endTime={sessionEndTime}
                        onStartTimeChange={setSessionStartTime}
                        onEndTimeChange={setSessionEndTime}
                        isAllDay={isAllDay}
                        onAllDayChange={setIsAllDay}
                    />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" p={3}>
                <Box color="text.secondary">
                    {renderDeleteButton()}
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        disabled={!isSubmitEnabled || isLoading || isDeleting || !fcrConfiguration}
                        onClick={handleSubmit}
                    >
                        {submitText}
                    </Button>
                    <Box
                        ml={2}
                        color="text.secondary"
                    >
                        <Button
                            variant="outlined"
                            color="inherit"
                            size="large"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default ScheduleCoachingSession;