import * as React from "react";

function SvgLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      {...props}
    >
      <g fill="currentColor">
        <path d="M12.399 10.66l-.88-.88 2.58-2.58a3.489 3.489 0 000-4.93 3.449 3.449 0 00-2.46-1.02c-1.34 0-2.22.78-2.46 1.02l-2.59 2.58-.88-.88 2.58-2.58c.32-.33 1.53-1.39 3.35-1.39 1.26 0 2.45.49 3.35 1.39a4.726 4.726 0 010 6.69l-2.59 2.58zM4.73 16.37c-1.26 0-2.45-.49-3.35-1.39a4.726 4.726 0 010-6.69l2.58-2.58.89.89-2.58 2.58a3.489 3.489 0 000 4.93c1.31 1.31 3.61 1.32 4.93 0l2.58-2.58.88.88-2.58 2.58c-.9.89-2.09 1.38-3.35 1.38z" />
        <path d="M11.352 4.133l-7.227 7.226.884.884 7.226-7.226-.883-.884z" />
      </g>
      <defs>
        <clipPath id="link_svg__clip0">
          <path fill="#fff" d="M0 0h16.37v16.37H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLink;
