import React, { useReducer } from "react";
import { Box, Collapse, Divider, Paper } from "@material-ui/core";

import { CoachingPlanType, UpcomingCoachingSession } from "types";

import UpcomingSessionContent from "./UpcomingSessionContent";
import UpcomingSessionHeader from "./UpcomingSessionHeader";
import useStyles from "./styles";

interface UpcomingSessionProps {
    teamMode: boolean;
    upcomingSession: UpcomingCoachingSession;
    onCoachingPlanChange: (newCoachingPlan: CoachingPlanType) => void;
}

const UpcomingSession = ({ teamMode, upcomingSession, onCoachingPlanChange }: UpcomingSessionProps) => {
    const classes = useStyles();
    const [isExpanded, toggleIsExpanded] = useReducer(state => !state, false);

    return (
        <Box mb={2.5}>
            <Paper>
                <Box display="flex" flexDirection="column">
                    <Box py={4} onClick={toggleIsExpanded} className={classes.collapse}>
                        <UpcomingSessionHeader
                            teamMode={teamMode}
                            upcomingSession={upcomingSession}
                            isExpanded={isExpanded}
                        />
                    </Box>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Divider />
                        <UpcomingSessionContent
                            upcomingSession={upcomingSession}
                            onCoachingPlanChange={onCoachingPlanChange}
                        />
                    </Collapse>
                </Box>
            </Paper>
        </Box>
    );
};

export default UpcomingSession;