import React, { useMemo } from "react";
import { Box, Divider, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import orderBy from "lodash/orderBy";

import CoachingPlanModal from "components/CoachingPlanModal";
import LabelScoreBar from "components/LabelScoreBar";
import NoDataMessage from "components/NoDataMessage";
import BarGraph from "components/BarGraph";
import CoachingPlan from "components/CoachingPlan";
import useCoachingPlanModal from "hooks/useCoachingPlanModal";
import { Calendar, CoachingPlans, Preformance } from "svgComponents";
import { CoachingPlanType, FcrCoachingSkillHistory } from "types";
import { SizeEnum } from "types/constants";
import { getFcrRatingDescription } from "helpers/fcrHelper";

import IconBlock from "./IconBlock";

interface SkillHistoryItemProps {
    skill: FcrCoachingSkillHistory;
    onCoachingPlanChange: (newCoachingPlan: CoachingPlanType) => void,
}

const SkillHistoryItem = ({ skill, onCoachingPlanChange }: SkillHistoryItemProps) => {
    const theme = useTheme();
    const alternatingLabels = useMediaQuery(theme.breakpoints.down("sm"));
    const { data, isShown, open, close } = useCoachingPlanModal();
  
    const {
        fcrCoachingSkill: { name },
        averageScore,
        timesCoached,
        coachingPlansAmount,
        trend,
        fcrCoachingPlans,
        resultsScores,
    } = skill;

    const sortedPlans = useMemo(() => orderBy(fcrCoachingPlans ?? [], "dueDate", "desc"), [ fcrCoachingPlans ]);
    const ratingDescription = getFcrRatingDescription(skill?.fcrCoachingSkill, averageScore)

    const performanceString = useMemo(
        () => trend > 0 ? `+${trend}` : trend.toString(),
        [trend]
    );

    return (
        <Box mb={4}>
            <Paper>
                <Box display="flex" flexDirection="column" py={{ xs: 3, md: 4 }} px={{ xs: 3, md: 5 }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" fontWeight={600} mb={6}>
                        <Typography variant="h2" component="span">
                            {name}
                        </Typography>
                        <Box ml={2} textAlign="right">
                            <Typography variant="caption">
                                Last 12 months
                            </Typography>
                        </Box>
                    </Box>
                    {averageScore ? (
                        <>
                            <Box
                                display="flex"
                                flexDirection={{ xs: "column", lg: "row" }}
                                justifyContent="space-between"
                                alignItems="center"
                                mb={6}
                            >
                                <Box width={1}>
                                    <LabelScoreBar
                                        label="Average Score"
                                        score={averageScore}
                                        size={SizeEnum.large}
                                        minRating={skill.fcrCoachingSkill.minRating}
                                        maxRating={skill.fcrCoachingSkill.maxRating}
                                        scoreDescription={ratingDescription?.title}
                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent={{ xs: "flex-start", lg: "flex-end" }}
                                    width={1}
                                    ml={{ xs: 0, lg: 5 }}
                                    mt={{ xs: 6, lg: 0 }}
                                >
                                    <BarGraph scores={resultsScores} width={1} alternatingLabels={alternatingLabels} />
                                </Box>
                            </Box>
                            <Divider />
                            <Box my={5.5} display="flex" justifyContent="space-between" flexWrap="wrap">
                                <IconBlock label="Times Coached" number={timesCoached} icon={
                                    <Calendar width={18} height={20} viewBox="0 0 15 15" opacity={0.6}/>
                                }/>
                                <IconBlock label="Coaching Plans" number={coachingPlansAmount} icon={
                                    <CoachingPlans width={18} height={24}/>
                                }/>
                                <IconBlock label="Performance" number={performanceString} icon={
                                    <Preformance width={18} height={19} opacity={0.6}/>
                                }/>
                            </Box>
                            <Divider />
                            <Box display="flex" flexDirection="column">
                                <Box fontWeight={600} mt={4} mb={2}>
                                    <Typography variant="h3">
                                        Coaching Plans
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    {sortedPlans.length ? (
                                        sortedPlans.map((coachingPlan, index) => (
                                            <React.Fragment key={index}>
                                                <CoachingPlan
                                                    coachingPlan={coachingPlan}
                                                    onDetailOpen={open}
                                                    py={2.5}
                                                    px={2}
                                                    mx={-2}
                                                />
                                                {index !== sortedPlans.length - 1 && (
                                                    <Divider />
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <NoDataMessage message="There's nothing to show here yet" />
                                    )}
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <NoDataMessage pb={5} />
                    )}
                </Box>
                {isShown && (
                    <CoachingPlanModal
                        data={data}
                        isShown={isShown}
                        onClose={close}
                        onCoachingPlanChange={onCoachingPlanChange}
                    />
                )}
            </Paper>
        </Box>
    );
};

export default SkillHistoryItem;