import React from "react";
import { Box, Typography } from "@material-ui/core";
import orderBy from "lodash/orderBy";
import { ProductSalesData } from "types";
import PaperSection from "components/PaperSection";

import useStyles from "./styles";
import ProductSalesLabel from "./ProductSalesLabel";

export enum PerformanceMetricsVariant {
    Default = "default",
    Paper = "paper",
    FCR = "fcr",
    FcrResult = "fcrResult",
}

interface PerformanceMetricsProps {
    productSalesData: ProductSalesData;
    title?: string;
    variant?: PerformanceMetricsVariant;
}

function PerformanceMetrics({ productSalesData, title = "Performance Metrics", variant = PerformanceMetricsVariant.Default }: PerformanceMetricsProps) {
    const classes = useStyles({ variant });
    const productSalesLabelsValues = orderBy(productSalesData?.productSalesLabelsValues, "order") ?? [];

    if (!productSalesLabelsValues.length) return null;

    const renderHeading = () => (
        <Typography className={classes.heading}>
            {title}
        </Typography>
    );

    const renderList = () => (
        <Box className={classes.list} display="grid">
            {productSalesLabelsValues.map((productSalesLabel) => {
                if (!productSalesLabel.label && !productSalesLabel.parsedValue) return null;
                return <ProductSalesLabel key={productSalesLabel.order} value={productSalesLabel} variant={variant} />;
            })}
        </Box>
    );

    if (variant === PerformanceMetricsVariant.Paper) return (
        <PaperSection title={renderHeading()}>
            <Box py={{ xs: 4, md: 10 }} px={3}>{renderList()}</Box>
        </PaperSection>
    );

    return (
        <Box component="section">
            <Box>{renderHeading()}</Box>
            {renderList()}
        </Box>
    );
}

export default PerformanceMetrics;
