import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    arrow: {
        position: "relative",
        overflow: "hidden",
        width: "1em",
        height: "1em",
        top: "8px",
        boxSizing: "border-box",
        color: theme.palette.background.paper,
        borderTop: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        borderLeft: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        background: theme.palette.info.main,
        transform: "rotate(45deg)",
    },
    historySection: {
        background: "rgba(248, 249, 250, 0.5)",
    },
    graphInfo: {
        opacity: 0.6,
    },
    skillLogo: {
        width: "56px",
        height: "56px",
        background: "#EEF6F8",
        borderRadius: "4px",
    },
    skillInfoName: {
        opacity: 0.6,
    },
}));

export default useStyles;
