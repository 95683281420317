import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    picker: {
        backgroundColor: theme.palette.common.white,

        "& .MuiInputBase-input": {
            fontSize: "15px",
            padding: "18px 0",
        },
    },
}));
