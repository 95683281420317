import React from "react";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";

interface ComplianceCheckboxProps {
    isChecked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
}

const ComplianceCheckbox = ({ isChecked, onChange, label }: ComplianceCheckboxProps) => (
    <Box p={3} my={4} bgcolor="#F8F9FA" borderRadius={6}>
        <FormControlLabel
            control={
                <Checkbox checked={isChecked} onChange={onChange} color="primary"/>
            }
            label={label}
        />
    </Box>
);

export default ComplianceCheckbox;