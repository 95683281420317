import * as React from "react";

function SvgUpArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={8}
            width={11}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.103.49A.456.456 0 005.74.3c-.121 0-.243.082-.344.217L.641 6.865a.836.836 0 00-.101.73c.08.243.263.405.465.405h9.488c.203 0 .385-.135.466-.405.08-.27.04-.568-.101-.757L6.103.49z"
                opacity={0.9}
            />
        </svg>
    );
}

export default SvgUpArrow;
