import React, { useMemo } from "react";
import { Slider as MaterialSlider, Mark } from "@material-ui/core";
import clsx from "clsx";

import { calculatePercentFromRange } from "helpers/numberHelper";

import useStyles from "./styles";

export interface SliderProps {
    minRating: number;
    maxRating: number;
    value?: number;
    defaultValue?: number;
    onChange?: (value: number) => void;
    step?: number;
    marks?: Mark[];
    valueLabelDisplay?: "on" | "auto" | "off";
    name?: string;
    disabled?: boolean;
}

function Slider({
    minRating,
    maxRating,
    value = 0,
    defaultValue = 0,
    onChange,
    step = 1,
    marks,
    valueLabelDisplay = "off",
    name,
    disabled = false,
}: SliderProps) {
    const classes = useStyles();

    const progressValue = useMemo(
        () => calculatePercentFromRange(value, minRating, maxRating),
        [maxRating, minRating, value]
    );

    const progressBarClass = useMemo(() => {
        switch (true) {
            case progressValue < 40:
                return classes.barLowest;
            case progressValue < 60:
                return classes.barLow;
            case progressValue < 80:
                return classes.barMedium;
            default:
                return classes.barHigh;
        }
    }, [
        classes.barHigh,
        classes.barLow,
        classes.barLowest,
        classes.barMedium,
        progressValue,
    ]);

    return (
        <MaterialSlider
            min={minRating}
            max={maxRating}
            step={step}
            marks={marks}
            defaultValue={defaultValue}
            value={value}
            valueLabelDisplay={valueLabelDisplay}
            classes={{
                track: progressBarClass,
                valueLabel: classes.tooltip,
                thumb: clsx({ [classes.disabledThumb]: !onChange }, classes.thumb),
                mark: classes.mark,
                markLabel: classes.markLabel,
            }}
            name={name}
            disabled={disabled}
            onChange={(event, value) => onChange && onChange(value as number)}
        />
    );
}

export default Slider;
