import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import orderBy from "lodash/orderBy";
import { CompaniesContext } from "context";

import { PlusIcon } from "svgComponents";

import { UserProfile, ImportResult } from "types";
import { AdminRoutings, AuthenticationTypeEnum } from "types/constants";

import NavigationLink from "components/NavigationLink";
import LoadingButton from "components/LoadingButton";
import UsersFilter from "components/UsersFilter";
import ImportResultAlert from "components/ImportResult";

import useOnCompanyChange from "hooks/useOnCompanyChange";
import { importMembers, importUserProfilesImages } from "services/importService";

import { refreshAllUsers } from "services/userServices";

import ItemsList from "../../ItemsList";
import MemberItem from "../../ItemsList/MemberItem";

const SHOW_PER_SCROLL = 10;

export default function MemberListPage() {
    const history = useHistory<{ importResult: ImportResult }>();
    const { currentCompany, isFetchingCompanyData, companyLevels, companyUsers, isFetchingCompanyUsers, fetchCompanyUsers } =
        useContext(CompaniesContext);
    const [shownQty, setShownQty] = useState<number>(SHOW_PER_SCROLL);
    const [allUsers, setAllUsers] = useState<UserProfile[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<UserProfile[] | null>(allUsers);
    const [importResult, setImportResult] = useState<ImportResult | undefined>(history.location.state?.importResult);
    const [importing, setImporting] = useState<{ members?: boolean; avatars?: boolean }>({ members: false, avatars: false });
    const [isMembersRefreshing, setIsMembersRefreshing] = useState<boolean>(false);
    const isMounted = useRef<boolean>(true);

    useEffect(
        () => () => {
            isMounted.current = false;
        },
        []
    );

    useOnCompanyChange(() => {
        if (importResult) setImportResult(undefined);
    });

    const onNextScroll = useCallback(() => setShownQty((state) => state + SHOW_PER_SCROLL), []);

    const shownUsers = useMemo(() => {
        const users = filteredUsers ?? allUsers;
        return users?.slice(0, shownQty);
    }, [allUsers, filteredUsers, shownQty]);

    useEffect(() => {
        const filteredUsersByOrder = orderBy(
            companyUsers,
            ["isActive", "userTeamMember", "level", "firstName"],
            ["desc", "desc", "asc", "asc"]
        );

        setAllUsers(filteredUsersByOrder);
        setFilteredUsers(filteredUsersByOrder);
    }, [companyUsers]);

    const showImportResult = (importResult: ImportResult) => {
        if (isMounted.current) {
            setImportResult(importResult);
        } else {
            history.push(AdminRoutings.members, { importResult });
        }
    };

    const handleImportResolved = (importPromise: Promise<ImportResult>) =>
        importPromise
            .then((response) => {
                showImportResult(response);
                if (isMounted.current) fetchCompanyUsers();
            })
            .catch((error) => {
                if (typeof error.response.data === "string") {
                    showImportResult({
                        isSuccess: false,
                        hasCriticalError: true,
                        logs: [],
                        errors: [{ message: error.response.data }],
                    });
                } else {
                    showImportResult(error.response.data);
                }
            });

    const handleImportMembers = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (!file) return;

        setImportResult(undefined);
        setImporting({ ...importing, members: true });

        handleImportResolved(importMembers(currentCompany!.id, file)).finally(() => setImporting({ ...importing, members: false }));
    };

    const handleImportAvatars = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (!file) return;

        setImportResult(undefined);
        setImporting({ ...importing, avatars: true });

        handleImportResolved(importUserProfilesImages(currentCompany!.id, file)).finally(() =>
            setImporting({ ...importing, avatars: false })
        );
    };

    const handleRefreshMembers = () => {
        if (currentCompany) {
            setIsMembersRefreshing(true);
            refreshAllUsers(currentCompany?.id).finally(() => {
                setIsMembersRefreshing(false);
                fetchCompanyUsers();
            });
        }
    };

    const isOpenIdMember: boolean = useMemo(
        () => (currentCompany?.authenticationTypeId ? currentCompany?.authenticationTypeId === AuthenticationTypeEnum.openId : false),
        [currentCompany?.authenticationTypeId]
    );

    return (
        <>
            {importResult && (
                <Box mb={4}>
                    <ImportResultAlert
                        result={importResult}
                        logsShownCount={1}
                        onClose={() => setImportResult(undefined)}
                        errorTitle={`Import was unsuccessful and process was rolled back.
                            Please fix the following errors and import the file again.`}
                    />
                </Box>
            )}
            <Grid alignItems="center" container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h1">Members</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        {currentCompany && !isFetchingCompanyData && (
                            <>
                                <Grid item>
                                    <Box display="flex" height={1}>
                                        <LoadingButton
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            disableElevation
                                            component="label"
                                            loading={importing.avatars}
                                            loadingLabel="Avatars Importing...">
                                            <Box component="span" lineHeight="30px">
                                                Import Avatars (.zip)
                                            </Box>
                                            <input type="file" accept=".zip" onChange={handleImportAvatars} hidden />
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box display="flex" height={1}>
                                        <LoadingButton
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            disableElevation
                                            component="label"
                                            loading={importing.members}
                                            loadingLabel="Members Importing...">
                                            <Box component="span" lineHeight="30px">
                                                Import Members (.xlsx)
                                            </Box>
                                            <input type="file" accept=".xlsx" onChange={handleImportMembers} hidden />
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </>
                        )}
                        {isOpenIdMember && (
                            <Grid item>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disableElevation
                                    component="label"
                                    onClick={handleRefreshMembers}
                                    loading={isMembersRefreshing}
                                    loadingLabel="Members Refreshing...">
                                    <Box component="span" lineHeight="30px">
                                        Refresh External Members
                                    </Box>
                                </LoadingButton>
                            </Grid>
                        )}
                        <Grid item>
                            <NavigationLink href={AdminRoutings.addMember} hoverUnderline={false}>
                                <Button variant="contained" color="primary" size="large" disableElevation startIcon={<PlusIcon />}>
                                    <Box component="span" lineHeight="30px">
                                        New Member
                                    </Box>
                                </Button>
                            </NavigationLink>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={6}>
                <ItemsList
                    filter={<UsersFilter companyLevels={companyLevels} userProfiles={allUsers} onFilter={setFilteredUsers} />}
                    quantity={shownUsers.length}
                    filteredUsers={filteredUsers ?? []}
                    isHasMore={!!filteredUsers && filteredUsers.length > shownUsers.length}
                    totalLabel={filteredUsers?.length === 1 ? "member" : "members"}
                    onNext={onNextScroll}
                    isLoading={isFetchingCompanyUsers}>
                    {shownUsers.map((user) => (
                        <MemberItem baseUrl={AdminRoutings.members} key={user.id} user={user} />
                    ))}
                </ItemsList>
            </Box>
        </>
    );
}
