import * as React from "react";

function SvgNotifications(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={15}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g opacity={0.7} clipPath="url(#notifications_svg__clip0)" fill="currentColor">
                <path
                    d="M14.68 13.08c-.61-.73-1.58-2.18-1.62-2.95-.1-1.87-.18-2.84-.18-2.84 0-2.69-1.88-4.96-4.42-5.56l.03-.65C8.49.48 8 0 7.41 0c-.59 0-1.07.48-1.07 1.07v.51l.02.14c-2.54.61-4.41 2.87-4.41 5.51 0 0-.08.97-.18 2.9-.04.77-1.02 2.22-1.62 2.95-.15.19-.19.45-.08.66.11.21.32.36.57.36h4.03a2.81 2.81 0 002.75 2.27c1.36 0 2.5-.98 2.75-2.27h4.03c.24 0 .46-.14.57-.36.11-.22.07-.47-.09-.66zm-7.27 2.04c-.66 0-1.22-.41-1.45-.98h2.9c-.23.58-.79.98-1.45.98zm-5.54-2.27c.52-.77 1.1-1.81 1.15-2.66.09-1.89.17-2.84.18-2.9 0-2.36 1.84-4.31 4.21-4.47 2.37.15 4.23 2.11 4.23 4.53 0 .01.08.96.18 2.85.04.85.63 1.9 1.15 2.66H1.87v-.01z"
                />
            </g>
            <defs>
                <clipPath id="notifications_svg__clip0">
                    <path fill="#fff" d="M0 0h14.83v16.37H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgNotifications;
