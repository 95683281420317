import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import PaperSection from "components/PaperSection";
import React from "react";
import { TraineeClassInfoDto } from "types/Certification";
import { EvaluatorClassesViewAllRouting } from "types/constants";

import AllMyClassesPageItem from "./AllMyClassesPage/AllMyClassesPageItem";
import useStyles from "./ClassesPreview.styles";

export interface AllMyClassesPreviewProps {
    isLoading: boolean;
    classDtos: TraineeClassInfoDto[] | null | undefined;
    sectionlabel: string;
}

function AllMyClassesPreview({ isLoading, classDtos, sectionlabel }: AllMyClassesPreviewProps) {
    const classes = useStyles();
    return (
        <PaperSection
            px={6}
            title={
                <Box mr={1.5} display="flex" alignItems="center">
                    <Typography className={classes.title} variant="h2">
                        All My {sectionlabel}
                    </Typography>
                </Box>
            }
            mb={{ xs: 3, sm: 6 }}
            href={EvaluatorClassesViewAllRouting.allMyClasses}>
            <Divider />
            <Box>
                {isLoading ? (
                    <Box p={4} textAlign="center">
                        <CircularProgress size={40} />
                    </Box>
                ) : (
                    classDtos?.map((item, i) => <AllMyClassesPageItem key={i} myClass={item} />)
                )}
            </Box>
        </PaperSection>
    );
}

export default React.memo(AllMyClassesPreview);
