import React from "react";
import { Route, Switch } from "react-router-dom";
import { AdminRoutings } from "types/constants";

import TeamsListPage from "./TeamsListPage";
import TeamPage from "./TeamPage";

export default function AdminTeams() {
    
    return (
        <Switch>
            <Route path={[
                `${AdminRoutings.teams}/:teamId`,
            ]}>
                <TeamPage />
            </Route>
            <Route>
                <TeamsListPage />
            </Route>
        </Switch>
    )
}
