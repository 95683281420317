import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    radio: {
        padding: 0,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    errorRadio: {
        color: "#DD3928",
    },
    button: {
        padding: theme.spacing(2.5),
        color: theme.colors.text.fontTeal,
        border: `1px solid ${theme.colors.structure.fieldsOutline}`,
        borderRadius: "6px",

        margin: 0,
        display: "flex",
        justifyContent: "flex-start",
        "& > .MuiTypography-body1": {
            width: "80%",
            textAlign: "center",
        },
    },
    checkedButton: {
        background: "rgba(55, 193, 226, 0.1)",
        border: `1px solid ${theme.colors.text.fontTeal}`,
    },
    errorButton: {
        background: "transparent",
        border: "1px solid #DD3928",
    },
}));

export default useStyles;
