import React from "react";
import SummaryCard, { SummaryCardProps } from "components/SummaryCard";

import PlansIcon from "./PlansIcon";

const renderDefaultIcon = () => <PlansIcon /> ;

const SummaryCardPlans = ({
    renderIcon = renderDefaultIcon,
    theme = "epic",
    ...props
}: SummaryCardProps) => <SummaryCard theme={theme} renderIcon={renderIcon} { ...props } />;

export default SummaryCardPlans;