import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { HeaderProps } from "types";
import { ClassesRouting, EvaluatorClassesViewAllRouting, Routings } from "types/constants";
import Header from "components/Header";

import AllMyClassesPage from "./AllMyClassesPage";
import AllScheduledClassesPage from "./AllScheduledClassesPage";
import TraineeEvaluationFormPage from "./AllMyClassesPage/TraineeEvaluationFormPage/index.page";

import MainClassesPage from "./index";
import ClassTraineesPage from "./ClassTraineesPage";

import useStyles from "./styles";
import TraineeClassesPage from "./TraineeClassesPage";

function MainClassesRoutes({ isBarOpen, navBarOpen }: HeaderProps) {
    const classes = useStyles();

    return (
        <Switch>
            <Route path={EvaluatorClassesViewAllRouting.allScheduledClasses}>
                <AllScheduledClassesPage isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
            <Route path={EvaluatorClassesViewAllRouting.allMyClasses}>
                <AllMyClassesPage isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
            <Route key={"new"} path={`${Routings.classes}/:classId/${ClassesRouting.evaluations}/new`}>
                <TraineeEvaluationFormPage isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
            <Route key={"inProgress"} path={`${Routings.classes}/:classId/${ClassesRouting.evaluations}/:evaluationResultId/edit`}>
                <TraineeEvaluationFormPage isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
            <Route key={"created"} path={`${Routings.classes}/:classId/${ClassesRouting.evaluations}/:evaluationResultId`}>
                <TraineeEvaluationFormPage<true> viewMode isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
            <Route path={`${EvaluatorClassesViewAllRouting.traineeClasses}/:traineeId`}>
                <TraineeClassesPage isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
            <Route path={`${Routings.classes}/:classId`} exact>
                <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header2} />
                <ClassTraineesPage />
            </Route>
            <Route path={Routings.classes} exact>
                <MainClassesPage isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
        </Switch>
    );
}

export default MainClassesRoutes;
