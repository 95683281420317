import sanitizeHtml from "sanitize-html";
import toString from "lodash/toString";

export const getAcronym = (string: string) =>
    string.split(/\s/).reduce((acronym, word) => acronym + word.slice(0, 1), "");

const sanitizeConfig = {
    allowedTags: ["b", "i", "em", "u", "s", "p", "strong", "ul", "ol", "li", "br"],
    allowedAttributes: { li: ["class"] },
};

export const sanitizeRichText = (string?: string | null) => sanitizeHtml(toString(string), sanitizeConfig);

const cleanupConfig = {
    allowedTags: [],
    allowedAttributes: {},
};

export const cleanupRichText = (string?: string | null, charactersToShow?: number) => {
    const strippedString = sanitizeHtml(toString(string), cleanupConfig);
    return charactersToShow ? `${strippedString.slice(0, charactersToShow)}...` : strippedString;
}

export const prepareForSort = (string: string) => string.toLowerCase().replace(/\s/g, "");
