import { useMemo, useState } from "react";
import { CoachingPeriodEnum } from "types/constants";

export const COACHING_PERIOD_OPTS: Record<CoachingPeriodEnum, string> = {
    [CoachingPeriodEnum.monthToDate]: "Month to Date",
    [CoachingPeriodEnum.yearToDate]: "Year to Date",
    [CoachingPeriodEnum.rolling12Months]: "Rolling 12 Months",
}

export default function useCoachingPeriod(initialPeriod: CoachingPeriodEnum = CoachingPeriodEnum.yearToDate) {
    const [ period, savePeriod ] = useState(initialPeriod);
    const periodLabel = useMemo(() => COACHING_PERIOD_OPTS[period], [ period ]);

    return { period, periodLabel, setPeriod: savePeriod }
}
