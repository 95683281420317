import React, { useMemo } from "react";

import { Box, Theme, Typography, useMediaQuery, Divider, Grid } from "@material-ui/core";
import { SizeEnum } from "types/constants";
import { UserProfile, CoachingSession } from "types";
import { Calendar } from "svgComponents";
import { formatFullMonthDate } from "helpers/dateHelpers";
import AvatarWithScore from "components/AvatarWithScore";
import CircleIcon from "components/CircleIcon";
import { getSessionTypeIcon } from "helpers/coachingHistoryHelper";

interface CoachingReportHeaderProps {
    user: UserProfile;
    coachingSession: CoachingSession;
}

function CoachingReportHeader({ user, coachingSession }: CoachingReportHeaderProps) {
    const isNotPortable = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const userName = useMemo(() => user ? `${user.firstName} ${user.lastName}` : "", [user]);
    const { scheduleStartDate } = coachingSession;

    return (
        <>
            <Box px={7} pb={6} pt={5} >
                <Grid container spacing={7}>
                    <Grid item xs={12} md={4}>
                        <Box display="flex" flexDirection={isNotPortable ? "column" : "row"} justifyContent="space-between" width="100%">
                            <Box display="flex" alignItems="flex-start" flexDirection="column">
                                <Typography variant="h1">Team Member</Typography>
                                <Box display="flex" alignItems="center" pt={3}>
                                    <AvatarWithScore user={user} size={SizeEnum.mediumSmall} />
                                    <Box pl={3} fontWeight="500">
                                        <Typography variant="h2">{userName}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {isNotPortable ?
                                <Box pt={5}>
                                    <Divider />
                                </Box> :
                                <Divider orientation="vertical" flexItem />
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                            <Box display="flex" alignItems="flex-start" flexDirection="column">
                                <Typography variant="h1">Session Type</Typography>
                                <Box display="flex" alignItems="center" pt={3}>
                                    <CircleIcon size={56}>
                                        {getSessionTypeIcon(coachingSession.coachingSessionType.icon)}
                                    </CircleIcon>
                                    <Box pl={3} fontWeight="500">
                                        <Typography variant="h2">
                                            {coachingSession.coachingSessionType.value}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box display="flex" alignItems="flex-start" flexDirection="column">
                            <Typography variant="h1">Session Date</Typography>
                            <Box display="flex" alignItems="center" pt={3}>
                                <CircleIcon size={56}>
                                    <Calendar />
                                </CircleIcon>
                                <Box pl={3} fontWeight="500">
                                    <Typography variant="h2">
                                        {formatFullMonthDate(scheduleStartDate || new Date())}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
        </>
    )
}

export default CoachingReportHeader;
