import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
    (theme) => ({
        alignRight: {
            marginLeft: "auto",
        },
        link: {
            textDecoration: "none",
        },
        titleGap: {
            marginLeft: theme.spacing(1.5),
        },
    }),
    {
        name: "ClassPage",
    }
);

export default useStyles;
