import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Card, Grid, Typography, CircularProgress } from "@material-ui/core";
import { PlusIcon } from "svgComponents";
import PaperSection from "components/PaperSection";
import { CompaniesContext } from "context";
import { getCurrentFcrConfiguration, postFcrConfiguration } from "services/wizardServices";
import NoDataMessage from "components/NoDataMessage";
import { FcrConfiguration, FcrItemConfiguration } from "types";

import FcrDragAndDrop from "routes/AdminComponent/FcrSettings/FcrDragAndDrop";

import fcrConfiguration from "types/FcrConfiguration";

import fcrItemConfiguration from "types/FcrItemConfiguration";

import useStyles from "./styles";
import FcrSettingsModal from "./FcrSettingsModal";
import SettingsForm from "./SettingsForm";
import CoachingSessionTypes from "./CoachingSessionTypes";

export default function FcrSettings() {
    const classes = useStyles();
    const { currentCompany } = useContext(CompaniesContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [fcrConf, setFcrConf] = useState<FcrConfiguration | null>(null);
    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<FcrItemConfiguration | null>(null);
    // TODO: set up so adding a new fcrItem will also increment the order.
    const fcrItems = useMemo(() => fcrConf?.fcrItemConfigurations ?? [], [fcrConf?.fcrItemConfigurations]);

    const elementsCount = useMemo(() => `${loading ? 0 : fcrItems.length} elements`, [fcrItems.length, loading]);

    const fetchFcrConf = useCallback(async () => {
        setLoading(true);

        if (!currentCompany?.id) {
            setLoading(false);
            return setFcrConf(null);
        }

        const response = await getCurrentFcrConfiguration(currentCompany?.id)
            .catch(() => null)
            .finally(() => setLoading(false));
        setFcrConf(response);
    }, [currentCompany?.id]);

    useEffect(() => {
        fetchFcrConf();
    }, [currentCompany?.id, fetchFcrConf]);

    const handleCreate = useCallback(() => {
        setModalOpened(true);
        setEditItem(null);
    }, [])

    const handleClose = useCallback(() => {
        setModalOpened(false);
        setEditItem(null);
    }, []);

    const handleEditFcrItem = (fcrItem: FcrItemConfiguration) => {
        setModalOpened(true);
        setEditItem(fcrItem)
    };

    const handleFcrItemReorder = (items: FcrItemConfiguration[]) => {
        // update fcrConf with itemOrders
        const itemOrders: FcrItemConfiguration[] = items.map((item, idx) => ({ ...item, order: idx + 1 })), payload = {
            ...fcrConf,
            fcrItemConfigurations: itemOrders,
        };
        postFcrConfiguration(payload as FcrConfiguration);
    };

    return (
        <>
            <Grid alignItems="center" container spacing={2}>
                <Grid item>
                    <Typography variant="h1">Field Coaching Report</Typography>
                </Grid>
                <Grid className={classes.alignRight} item>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!fcrConf}
                        disableElevation
                        startIcon={<PlusIcon/>}
                        onClick={handleCreate}
                    >
                        <Box lineHeight="30px">New Summary Element</Box>
                    </Button>
                </Grid>
            </Grid>
            <Grid className={classes.body} container spacing={2}>
                <Grid item xs={12} md={8}>
                    <PaperSection title={elementsCount}>
                        <FcrDragAndDrop
                            fcrItems={fcrItems}
                            isLoading={loading}
                            onEditFcrItem={handleEditFcrItem}
                            onFcrItemReordered={handleFcrItemReorder}
                        />
                        {!loading && !fcrItems.length && (
                            <Box px={6} py={4}>
                                <NoDataMessage/>
                            </Box>
                        )}
                        {loading && (
                            <Box px={6} py={4} textAlign="center">
                                <CircularProgress size={40}/>
                            </Box>
                        )}
                    </PaperSection>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <SettingsForm fcrConf={fcrConf} onSuccess={setFcrConf} isFetching={loading}/>
                    </Card>
                    <Box mt={2}>
                        <Card>
                            <CoachingSessionTypes fcrConf={fcrConf} onSuccess={fetchFcrConf} isFetching={loading}/>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
            {fcrConf && modalOpened && (
                <FcrSettingsModal
                    open={modalOpened}
                    item={editItem}
                    fcrConfigurationId={fcrConf.id}
                    onClose={handleClose}
                    onSuccess={fetchFcrConf}
                    currentListLength={fcrItems.length}
                />
            )}
        </>
    )
}
