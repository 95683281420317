import React, { useContext, useMemo } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import ClassesProvider from "components/ClassesProvider";
import CoachingHubProvider from "components/CoachingHubProvider";
import Header from "components/Header";
import CoachingHub from "routes/CoachingHub";
import Dashboard from "routes/Dashboard";
import Resources from "routes/Resources";
import { LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW, Routings } from "types/constants";
import TeamProfileDashboard from "routes/TeamProfileWrapper/TeamProfileDashboard";
import { HeaderProps } from "types";
import CoachingReport from "routes/CoachingReport";
import MainClassesRoutes from "routes/MainClassListingsPage/classes.routes";
import { ConfigurationContext } from "context";
import NoDataMessage from "components/NoDataMessage";
import { Box, CircularProgress } from "@material-ui/core";

const ClientComponent = ({ isBarOpen, navBarOpen }: HeaderProps) => {
    const { currentUser, isConfigurationFetched } = useContext(ConfigurationContext);

    const hasRole: boolean = useMemo(() => {
        if (isConfigurationFetched && currentUser) {
            const {
                isCoach,
                isCoachee,
                isACoLeader,
                isEvaluator,
                hasClassesAsEvaluator,
                isTrainee,
                hasClassesAsTrainee,
                hasTeamMembersAsTrainees,
            } = currentUser;

            if (!localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW)) {
                return (
                    isACoLeader ||
                    isCoach ||
                    hasTeamMembersAsTrainees ||
                    isCoachee ||
                    (isEvaluator && hasClassesAsEvaluator) ||
                    (isTrainee && hasClassesAsTrainee)
                );
            }
            return true;
        }
        return true;
    }, [currentUser, isConfigurationFetched]);

    /**
     * Not a coach and not a coachee but you do have either team members as trainees or classes.
     * */
    const classesAsDashboard = useMemo(() => {
        const { isCoach, isCoachee, isEvaluator, hasClassesAsEvaluator, isTrainee, hasClassesAsTrainee, hasTeamMembersAsTrainees } =
            currentUser ?? {};
        const hasClasses = (isEvaluator && hasClassesAsEvaluator) || (isTrainee && hasClassesAsTrainee);
        return !isCoachee && !isCoach && (hasTeamMembersAsTrainees || hasClasses);
    }, [currentUser]);

    if (!isConfigurationFetched) {
        return (
            <Box p={12} textAlign="center">
                <CircularProgress size={40} />
            </Box>
        );
    }
    const classesRoutes = (
        <Route path={Routings.classes}>
            <ClassesProvider>
                <MainClassesRoutes isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </ClassesProvider>
        </Route>
    );
    const routes = classesAsDashboard ? (
        <Switch>
            {classesRoutes}
            <Route>
                <Redirect to={Routings.classes} />
            </Route>
        </Switch>
    ) : (
        <Switch>
            <Route path={`${Routings.teamProfile}/:userId`}>
                <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
                <TeamProfileDashboard />
            </Route>
            <Route path={`${Routings.fcr}/:coachingSessionId`}>
                <CoachingReport isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
            <Route path={Routings.resources}>
                <Resources isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
            <Route path={Routings.coachingHub}>
                <CoachingHubProvider>
                    <CoachingHub isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
                </CoachingHubProvider>
            </Route>
            {classesRoutes}
            <Route path={Routings.dashboard}>
                <Dashboard isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
            </Route>
        </Switch>
    );
    return hasRole ? (
        routes
    ) : (
        <Route>
            <Box py={10}>
                <NoDataMessage icon={null} title="No content" message="" m="auto" />
            </Box>
        </Route>
    );
};

export default ClientComponent;
