import * as React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import useStyles from "../styles";

export const CompaniesListSkeleton = (): React.ReactElement => {
    const classes = useStyles();

    return (
        <>
            {[1, 2, 3, 4, 5, 6].map((index) => (
                <Grid item xs={2} key={index}>
                    <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={80}
                        className={classes.skeleton}
                    />
                </Grid>
            ))}
        </>
    );
};
