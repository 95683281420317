import * as React from "react";

function SvgFocus(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg height="29" width="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M15.0901 14.6706C14.8401 16.6106 13.8101 18.1406 12.2701 18.5406C10.0001 19.1206 7.50008 17.0206 6.67008 13.8306C5.84008 10.6406 7.02008 7.59056 9.29008 7.00056C10.7801 6.62056 12.3701 7.39056 13.5201 8.87056" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.6496 13.3406C19.1496 18.8606 16.7896 23.6806 12.7596 24.7206C8.06956 25.9306 2.87956 21.5706 1.17956 14.9706C-0.520441 8.37057 1.88956 2.04057 6.57956 0.830567C10.4996 -0.179433 14.7596 2.69057 17.0596 7.51057" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.4802 8.2793L11.4502 13.3793" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.77 6.12008L26.64 4.33008L26.35 6.46008L28.18 7.67008L24.32 9.45008L22.5 8.27008L22.77 6.12008Z" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.16965 23.1895L2.38965 27.6695" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.0596 24.25L16.1896 27.67" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgFocus;
