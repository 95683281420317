import { useMemo } from "react";
import { CoachingDevelopment } from "types/CoachHistory";
import { CoachingPeriodEnum } from "types/constants";
import useCoachingPeriod from "hooks/useCoachingPeriod";
import { byToDate, calcScore } from "helpers/coachingHistoryHelper";

export default function useCoachingDevelopmentHistory(
    initialHistory: CoachingDevelopment[] = [],
    initialPeriod: CoachingPeriodEnum = CoachingPeriodEnum.yearToDate
) {
    const { period, periodLabel } = useCoachingPeriod(initialPeriod);

    // History
    const allHistory = useMemo(() => initialHistory.filter(({ reportType }) => reportType === period), [initialHistory, period]);
    const curHistory = useMemo(() => allHistory.filter(byToDate(new Date())), [allHistory]);
    const prevHistory = useMemo(() => allHistory.filter(byToDate(new Date(), false)), [allHistory]);

    const sessions = useMemo(() => calcScore(curHistory, prevHistory, "coachingSessions"), [curHistory, prevHistory]);
    const goals = useMemo(() => calcScore(curHistory, prevHistory, "developmentGoalsInitiated"), [curHistory, prevHistory]);
    const focus = useMemo(() => calcScore(curHistory, prevHistory, "skillsAverageRating", 2), [curHistory, prevHistory]);
    const plans = useMemo(() => calcScore(curHistory, prevHistory, "coachingPlansCompleted"), [curHistory, prevHistory]);

    return {
        period,
        periodLabel,
        curHistory,
        prevHistory,
        sessions,
        goals,
        focus,
        plans,
    }
}
