import React, { useCallback, useMemo, useReducer } from "react";
import { FcrResource, FcrResourceCategory } from "types";

import AdminListItem from "components/AdminListItem";
import Pdf from "svgComponents/Pdf";
import Link from "svgComponents/Link";
import { ResourceTypeEnum } from "types/constants";

import EditResource from "../EditResource";
import DeleteResource from "../DeleteResource";

interface ResourceListItemProps {
    categories: FcrResourceCategory[];
    resource: FcrResource;
    onEditResource: (resource: FcrResource) => void;
    onDeleteResource: (resource: FcrResource) => void;
}

const ResourceListItem = ({ categories, resource, onEditResource, onDeleteResource }: ResourceListItemProps) => {
    const [isEditOpen, toggleIsEditOpen] = useReducer(state => !state, false);
    const [isDeleteOpen, toggleIsDeleteOpen] = useReducer(state => !state, false);

    const resourceIcon = useMemo(() => {
        switch (resource.type) {
            case ResourceTypeEnum.link:
                return <Link/>;
            case ResourceTypeEnum.document:
            default:
                return <Pdf/>;
        }
    }, [resource]);

    const handleEdit = useCallback((e) => {
        e.stopPropagation();
        toggleIsEditOpen();
    }, []);

    const handleDelete = useCallback((e) => {
        e.stopPropagation();
        toggleIsDeleteOpen();
    }, []);

    return (
        <>
            <AdminListItem
                icon={resourceIcon}
                loading={false}
                title={resource.name}
                description={resource.description}
                onClick={toggleIsEditOpen}
                onEdit={handleEdit}
                onDelete={handleDelete}
            />
            {isEditOpen && (
                <EditResource
                    resource={resource}
                    categories={categories}
                    isOpen={isEditOpen}
                    onClose={toggleIsEditOpen}
                    onSubmit={onEditResource}
                    onDelete={toggleIsDeleteOpen}
                />
            )}
            {isDeleteOpen && (
                <DeleteResource
                    resource={resource}
                    isOpen={isDeleteOpen}
                    onClose={toggleIsDeleteOpen}
                    onSubmit={onDeleteResource}
                />
            )}
        </>
    );
};

export default ResourceListItem;