import React, { useCallback, useContext, useState } from "react";
import { CoachingSession, MessageType, CoachingSessionFilter, MessageList, MessageThread } from "types";
import { CoachingHubContext, ConfigurationContext } from "context";
import { getCoachingSessionsForUsersBelow } from "services/coachingSessionServices";
import { getAllMessages, getAllThreadsForUser } from "services/messageServices";

interface CoachingHubProviderProps {
    children: React.ReactNode;
}

const CoachingHubProvider = ({ children }: CoachingHubProviderProps) => {
    const { currentUser } = useContext(ConfigurationContext);
    const [coachingSessions, setCoachingSessions] = useState<CoachingSession[] | null>(null);
    const [isFetchingByFilters, setIsFetchingByFilters] = useState<boolean>(false);
    const [totalFilteredAmount, setTotalFilteredAmount] = useState<number>(0);
    const [coachingHubFilters, setCoachingHubFilters] = useState<CoachingSessionFilter | null>(null);
    const [messages, setMessages] = useState<MessageType[]>();
    const [messageThreadList, setMessageThreadList] = useState<MessageThread[] | null>();
    const [totalMessageThreads, setTotalMessageThreads] = useState<number>(0);
    const [isFetchingMessages, setIsFetchingMessages] = useState(false); 

    const fetchMessages = useCallback(() => {
        if (currentUser?.id) {
            setIsFetchingMessages(true);
            getAllMessages(currentUser.id, 0)
                .then((messageList: MessageList) => setMessages(messageList.results))
                .finally(() => setIsFetchingMessages(false));
        }
    }, [currentUser?.id]);

    const fetchMessagesThreads = useCallback((pageNumber: number) => {
        if (!currentUser?.id) return;
        
        getAllThreadsForUser(currentUser.id, pageNumber).then(({ messageThreads, totalFilteredAmount }) => {
            setMessageThreadList(!!messageThreadList?.length && pageNumber !== 1 ? [...messageThreadList, ...messageThreads] : messageThreads)
            setTotalMessageThreads(totalFilteredAmount)
        })
    }, [currentUser?.id, messageThreadList]);

    const clearMessagesThreads = useCallback(() => {
        setMessageThreadList(null)
        setTotalMessageThreads(0)
    }, []);

    const fetchCoachingSessions = useCallback((pageNumber: number, filters?: CoachingSessionFilter) => {
        if (!currentUser?.id) return;
        if (!coachingHubFilters && filters) setCoachingHubFilters(filters)

        getCoachingSessionsForUsersBelow(currentUser.id, pageNumber, filters).then(({ results, totalFilteredAmount }) => {
            setCoachingSessions(!!coachingSessions?.length && pageNumber !== 1 ? [...coachingSessions, ...results] : results);
            setTotalFilteredAmount(totalFilteredAmount)
        }).finally(() => setIsFetchingByFilters(false))
    }, [coachingHubFilters, coachingSessions, currentUser?.id]);

    const clearCoachingSessions = useCallback(() => {
        setCoachingHubFilters(null)
        setCoachingSessions(null)
        setTotalFilteredAmount(0)
    }, []);

    const handleCoachingSessionSaved = useCallback(() => {
        coachingHubFilters && fetchCoachingSessions(1, coachingHubFilters)
    }, [coachingHubFilters, fetchCoachingSessions]);

    const handleCoachingSessionDeleted = useCallback((deletedSession: CoachingSession) => {
        if (!coachingSessions) return;

        setCoachingSessions(
            coachingSessions.filter((session) => session.id !== deletedSession.id)
        );
    }, [coachingSessions]);

    const handleMessagesSaved = useCallback((newMessages: MessageType[]) => {
        if (!messages) return;

        setMessages([...newMessages, ...messages]);
    }, [messages]);

    const handleMarkThreadAsRead = useCallback((threadId: string) => {
        if (!currentUser?.id || !messages) return;

        const updatedMessages = messages.map((message) => (
            message.threadId === threadId && message.isNew && message.toUserId === currentUser.id
                ? { ...message, isNew: false }
                : message
        ));

        setMessages(updatedMessages);
    }, [currentUser?.id, messages]);

    return (
        <CoachingHubContext.Provider value={{
            coachingSessions,
            clearCoachingSessions,
            fetchCoachingSessions,
            isFetchingByFilters,
            setIsFetchingByFilters,
            handleCoachingSessionSaved,
            handleCoachingSessionDeleted,
            messages,
            fetchMessages,
            handleMessagesSaved,
            handleMarkThreadAsRead,
            isFetchingMessages,
            totalFilteredAmount,
            coachingHubFilters,
            setCoachingHubFilters,
            messageThreadList,
            fetchMessagesThreads,
            totalMessageThreads,
            clearMessagesThreads,
        }}>
            {children}
        </CoachingHubContext.Provider>
    );
};

export default CoachingHubProvider;