import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography, Button, Grid, CircularProgress, Card, Divider } from "@material-ui/core";
import { CompaniesContext } from "context";
import { PlusIcon } from "svgComponents";
import { SkillsIcon } from "svgComponents/MenuIcons";
import { EvaluationConfiguration } from "types/Certification";
import NoDataMessage from "components/NoDataMessage";
import EvaluationsFilter from "components/EvaluationsFilter";

import EvaluationListItem from "components/EvaluationListItem";
import NavigationLink from "components/NavigationLink";
import { AdminRoutings } from "types/constants";
import { useHistory } from "react-router-dom";
import { deleteEvaluationConfiguration, getEvaluationConfigurationForCompany } from "services/certificationService";

const AUTOCOMPLETE_LABEL = "Search all Evaluations";

function EvaluationManagement() {
    const { currentCompany, setAppDialogProps } = useContext(CompaniesContext);
    const history = useHistory();
    const [evaluations, setEvaluations] = useState<EvaluationConfiguration[]>([]);
    const [filteredEvaluations, setFilteredEvaluations] = useState<EvaluationConfiguration[]>([]);
    const [isEvaluationsFetched, setIsEvaluationsFetched] = useState<boolean>(false);
    const totalLabel = filteredEvaluations?.length === 1 ? "evaluation" : "evaluations";

    const fetchEvaluationConfigurations = useCallback(() => {
        setIsEvaluationsFetched(false);
        if (currentCompany) {
            getEvaluationConfigurationForCompany(currentCompany.id)
                .then(setEvaluations, setFilteredEvaluations)
                .finally(() => setIsEvaluationsFetched(true));
        }
    }, [currentCompany]);

    useEffect(() => {
        fetchEvaluationConfigurations();
    }, [currentCompany, fetchEvaluationConfigurations, setFilteredEvaluations]);

    const handleEdit = useCallback(
        (evaluationId) => {
            history.push(`${AdminRoutings.certificationEvaluations}/${evaluationId}`);
        },
        [history]
    );

    const handleDelete = useCallback(
        (evaluationId: EvaluationConfiguration["id"]) => (e: React.SyntheticEvent) => {
            e.stopPropagation();
            const evaluation = evaluations.find(({ id }) => id === evaluationId);
            setAppDialogProps({
                title: "Delete Evaluation",
                message: `Warning: Are you sure you want to delete ${evaluation?.name}?`,
                action: "Delete",
                isOpen: true,
                asyncConfirmation: () => deleteEvaluationConfiguration(evaluationId),
                confirmationCallback: () => fetchEvaluationConfigurations(),
            });
        },
        [evaluations, fetchEvaluationConfigurations, setAppDialogProps]
    );

    return (
        <>
            <Grid alignItems="center" container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h1">Evaluation Management</Typography>
                </Grid>
                <Grid item>
                    <NavigationLink href={AdminRoutings.addCertificationEvaluation} hoverUnderline={false}>
                        <Button variant="contained" color="primary" size="large" disableElevation startIcon={<PlusIcon />}>
                            <Box component="span" lineHeight="30px">
                                New Evaluation
                            </Box>
                        </Button>
                    </NavigationLink>
                </Grid>
            </Grid>
            <Box mt={5}>
                <Card>
                    {isEvaluationsFetched && (
                        <>
                            <EvaluationsFilter
                                evaluations={evaluations}
                                onFilter={setFilteredEvaluations}
                                evaluationsAutocompleteLabel={AUTOCOMPLETE_LABEL}
                            />
                            <Divider />
                            <Box display="flex" py={2} px={4}>
                                <Box flexShrink={0}>
                                    <Typography
                                        color="textSecondary"
                                        variant="body1">{`${filteredEvaluations.length} ${totalLabel}`}</Typography>
                                </Box>
                            </Box>

                            <Divider />
                            {filteredEvaluations.map((evaluation, idx) => (
                                <React.Fragment key={evaluation.id}>
                                    {idx > 0 && <Divider />}
                                    <EvaluationListItem
                                        evaluation={evaluation}
                                        key={evaluation.id}
                                        icon={<SkillsIcon />}
                                        onEdit={() => {
                                            handleEdit(evaluation.id);
                                        }}
                                        onClick={() => {
                                            handleEdit(evaluation.id);
                                        }}
                                        onDelete={!evaluation.isInUse ? handleDelete(evaluation.id) : undefined}
                                    />
                                </React.Fragment>
                            ))}
                        </>
                    )}
                    {isEvaluationsFetched && filteredEvaluations.length === 0 && (
                        <Box px={6} py={4}>
                            <NoDataMessage />
                        </Box>
                    )}
                    {!isEvaluationsFetched && (
                        <Box px={6} py={4} textAlign="center">
                            <CircularProgress size={40} />
                        </Box>
                    )}
                </Card>
            </Box>
        </>
    );
}

export default EvaluationManagement;
