import React from "react";
import clsx from "clsx";

import useStyles from "./styles";
interface CircleIconProps {
    children: React.ReactNode;
    size?: number;
    className?: string;
}

export default function CircleIcon({ children, size = 48, className }: CircleIconProps) {
    const classes = useStyles();

    return (
        <div 
            className={clsx(classes.circleIcon, className)}
            style={{
                minWidth: size,
                minHeight: size,
            }}
        >
            {children}
        </div>
    );
}