import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { Box, Divider, Grid, Hidden, Typography } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";

import AvatarWithScore from "components/AvatarWithScore";
import { Routings, SizeEnum } from "types/constants";
import CoachingSessionName from "components/CoachingSessionName";
import NavigationLink from "components/NavigationLink";
import { formatDate, formatTime } from "helpers/dateHelpers";
import { UpcomingCoachingSession } from "types";
import { getUserFullName } from "helpers/userProfileHelper";
import { ConfigurationContext } from "context";

import useStyles from "./styles";

interface UpcomingSessionHeaderProps {
    teamMode: boolean;
    upcomingSession: UpcomingCoachingSession;
    isExpanded: boolean;
}

const UpcomingSessionHeader = ({ teamMode, upcomingSession, isExpanded }: UpcomingSessionHeaderProps) => {
    const classes = useStyles();
    const location = useLocation();
    const { hasCoachingReportAccess } = useContext(ConfigurationContext);
    const { assigneeProfile, coachingSession } = upcomingSession;
    const { scheduleStartDate, coachingSessionType, id: coachingSessionId, coach } = coachingSession;

    const assigneeFullName = useMemo(() => getUserFullName(assigneeProfile), [assigneeProfile]);

    const coachFullName = useMemo(() => getUserFullName(coach), [coach]);

    const formattedDate = useMemo(
        () => scheduleStartDate && formatDate(scheduleStartDate),
        [scheduleStartDate]
    );
    const formattedTime = useMemo(
        () => scheduleStartDate && formatTime(scheduleStartDate),
        [scheduleStartDate]
    );

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6} md={3}>
                <Box display="flex" flexDirection="column" alignItems={{ xs: "center", md: "start" }} pl={{ xs: 0, md: 3, lg: 5 }}>
                    <Box display="flex" alignItems="center">
                        <AvatarWithScore
                            user={assigneeProfile}
                            score={assigneeProfile.averageScore}
                            size={SizeEnum.small}
                        />
                        <Box fontWeight={600} ml={2.5}>
                            <Typography variant="h3" component="span">
                                {assigneeFullName}
                            </Typography>
                            {teamMode && (
                                <Typography variant="caption" component="div" color="textPrimary">
                                    Coached by{" "}
                                    <Typography
                                        variant="caption"
                                        component="span"
                                        color="textPrimary"
                                        className={classes.coachFullName}
                                    >
                                        {coachFullName}
                                    </Typography>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Divider orientation="vertical" flexItem/>
            <Grid item xs={6} md={3}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box
                        display="flex"
                        fontWeight={500}
                    >
                        <CoachingSessionName coachingSessionType={coachingSessionType} />
                    </Box>
                    {hasCoachingReportAccess(coachingSession) && (
                        <NavigationLink
                            href={{
                                pathname: `${Routings.fcr}/${coachingSessionId}`,
                                state: { backPath: location.pathname, backText: "Back to Dashboard" },
                            }}
                            className={classes.link}
                        >
                            View Report
                        </NavigationLink>
                    )}
                </Box>
            </Grid>
            <Hidden smDown>
                <Divider orientation="vertical" flexItem/>
            </Hidden>
            <Grid item xs={6} md={3}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mx="auto"
                    height={1}
                >
                    <Typography variant="caption" color="inherit">
                        {formattedDate}
                    </Typography>
                    <Box fontWeight={500}>
                        <Typography variant="h3">
                            {formattedTime}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Divider orientation="vertical" flexItem/>
            <Grid item xs={6} md={3}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Typography variant="body2">
                            {isExpanded ? "Hide" : "Show"} Details
                        </Typography>
                        <Box display="flex" alignItems="center" ml={1.5} className={clsx({
                            [classes.isExpanded]: isExpanded,
                        })}>
                            <ExpandMore/>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default UpcomingSessionHeader;