import React from "react";
import SummaryCard, { SummaryCardProps } from "components/SummaryCard";

import SessionsIcon from "./SessionsIcon";

const renderDefaultIcon = () => <SessionsIcon /> ;

const SummaryCardSessions = ({
    renderIcon = renderDefaultIcon,
    theme = "darkSea",
    ...props
}: SummaryCardProps) => <SummaryCard theme={theme} renderIcon={renderIcon} { ...props } />;

export default SummaryCardSessions;