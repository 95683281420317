import * as React from "react";

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={14}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#menu_svg__clip0)" fill="#B5C0CB">
                <path d="M14 10.38H0v1.25h14v-1.25zM14 5.19H0v1.25h14V5.19zM14 0H0v1.25h14V0z" />
            </g>
            <defs>
                <clipPath id="menu_svg__clip0">
                    <path fill="#fff" d="M0 0h14v11.63H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgMenu;
