import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppBar, Toolbar, Box, Button, IconButton, Typography, Tooltip, Link } from "@material-ui/core";

import AvatarWithScore from "components/AvatarWithScore";
import { SizeEnum, LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW, AlertSeverity } from "types/constants";
import { BackArrow, Calendar, Menu } from "svgComponents";
import { formatShortDate } from "helpers/dateHelpers";
import { ConfigurationContext, SnackbarContext } from "context";
import { HeaderProps, UserProfile, CoachingSession } from "types";
import useLocationStateParam from "hooks/useLocationStateParam";
import { resendEmailForFcrResult, getFcrResultPdfUrl } from "services/wizardServices";
import CoachingSessionName from "components/CoachingSessionName";
import LoadingButton from "components/LoadingButton";
import NavigationLink from "components/NavigationLink";

import Stepper from "./Stepper";

import useStyles from "./styles";

interface WizardHeaderProps extends HeaderProps {
    user: UserProfile;
    finishLaterUrl?: string;
    step?: number;
    moveToStep?: (step: number) => void;
    toggleCoachingReportSelector?: () => void;
    isViewing?: boolean;
    coachingSession: CoachingSession;
    completedSteps?: { [k: number]: boolean };
}

const tooltip = "Resends confirmation email to the Coach and Coachee";

function WizardHeader({
    user,
    finishLaterUrl,
    step,
    moveToStep,
    isBarOpen,
    navBarOpen,
    toggleCoachingReportSelector,
    coachingSession,
    isViewing = false,
    completedSteps,
}: WizardHeaderProps) {
    const classes = useStyles();
    const history = useHistory();
    const backPath = useLocationStateParam<string>("backPath");
    const backText = useLocationStateParam<string>("backText");
    const { currentUser } = useContext(ConfigurationContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const [isSending, setSending] = useState<boolean>(false);
    const [pdfUrl, setPdfUrl] = useState<string>();
    const { scheduleStartDate, isAllDayEvent, fcrResult, fcrResultId } = coachingSession;

    const isTeamMemberView = localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW);

    const { id, firstName, lastName } = user;
    const userName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);

    const rescheduleClick = useCallback(
        () => !isViewing && toggleCoachingReportSelector && toggleCoachingReportSelector(),
        [isViewing, toggleCoachingReportSelector]
    );

    const handleResendEmail = useCallback(() => {
        setSending(true);
        resendEmailForFcrResult(fcrResultId!)
            .then(() => openSnackbar("Email was successfully sent.", AlertSeverity.success))
            .catch((e) => openSnackbar(e.response.data || "Failed to send email.", AlertSeverity.error))
            .finally(() => setSending(false));
    }, [fcrResultId, openSnackbar]);

    const handleFinishLater = useCallback(() => {
        const url = backPath || finishLaterUrl!;
        history.push(url);
        openSnackbar("Draft saved to profile.");
    }, [history, backPath, finishLaterUrl, openSnackbar]);

    const formattedDate = useMemo(
        () => formatShortDate(new Date(scheduleStartDate), !isAllDayEvent, true),
        [isAllDayEvent, scheduleStartDate]
    );

    const isVisibleFinishLater = useMemo(
        () =>
            finishLaterUrl &&
            !(isViewing && currentUser!.id !== id) &&
            (!isTeamMemberView ||
                (isTeamMemberView && !fcrResult?.submittedByCoacheeDate && fcrResult?.createdById !== fcrResult?.coachId)),
        [
            finishLaterUrl,
            currentUser,
            fcrResult?.submittedByCoacheeDate,
            id,
            isTeamMemberView,
            isViewing,
            fcrResult?.createdById,
            fcrResult?.coachId,
        ]
    );

    useEffect(() => {
        if (!fcrResult?.pdfUrl && fcrResultId) getFcrResultPdfUrl(fcrResultId).then(({ url }) => setPdfUrl(url));
    }, [fcrResult?.pdfUrl, fcrResultId]);

    return (
        <AppBar position="sticky" color="inherit">
            <Toolbar disableGutters={true}>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    px={{ xs: 2, md: 8 }}
                    pt={{ xs: 1, md: 3 }}
                    pb={{ xs: 1, md: 2 }}
                    className={classes.header}>
                    <div>
                        {backPath && backText && (
                            <NavigationLink
                                // eslint-disable-next-line object-curly-spacing
                                href={{ pathname: backPath, state: { sessionId: coachingSession.id } }}
                                className={classes.back}>
                                <Box display="flex" alignItems="center" py={0.5}>
                                    <BackArrow />
                                    <Box ml={2.5}>{backText}</Box>
                                </Box>
                            </NavigationLink>
                        )}
                    </div>
                    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                        <Box display="flex" alignItems="center" mb={2}>
                            {!isBarOpen && (
                                <Box pr={1} ml={-1.5}>
                                    <IconButton className={classes.navBarOpen} onClick={navBarOpen}>
                                        <Menu />
                                    </IconButton>
                                </Box>
                            )}
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems={{ xs: "right", md: "center" }}>
                                    <Box pr={2} fontWeight={600}>
                                        <Typography variant="h2">{isViewing ? "Coaching Report:" : "New Coaching Report:"}</Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="row" alignItems="center" pt={{ xs: 1, md: 0 }} flexWrap="wrap">
                                        <AvatarWithScore user={user} size={SizeEnum.xs} />
                                        <Box pl={1.5} pr={2} fontWeight={500}>
                                            <Typography variant="h3">{userName}</Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="center"
                                            py={1}
                                            px={2}
                                            className={classes.date}
                                            fontWeight="500"
                                            mr={2}
                                            onClick={rescheduleClick}>
                                            <Calendar />
                                            <Box pl={1}>{formattedDate}</Box>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="center"
                                            mr={2}
                                            py={1}
                                            px={1}
                                            className={classes.date}
                                            fontWeight="500">
                                            <CoachingSessionName coachingSessionType={coachingSession.coachingSessionType} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {isVisibleFinishLater && (
                            <Box display="flex" color="text.secondary" mb={2} justifyContent="flex-end" alignItems="flex-end">
                                <Button variant="outlined" color="inherit" size="large" disableRipple onClick={handleFinishLater}>
                                    Finish Later
                                </Button>
                            </Box>
                        )}
                        {isViewing && (
                            <Box display="flex" color="text.secondary" mb={2} flex={1} justifyContent="flex-end" alignItems="flex-end">
                                {(fcrResult?.pdfUrl || fcrResultId) && (
                                    <Box color="text.secondary" whiteSpace="nowrap">
                                        <Link target="_blank" rel="noopener noreferrer" underline="none" href={fcrResult?.pdfUrl || pdfUrl}>
                                            <Button variant="outlined" color="primary" size="medium">
                                                <Box lineHeight="30px">Download PDF</Box>
                                            </Button>
                                        </Link>
                                    </Box>
                                )}
                                <Tooltip title={tooltip} placement="bottom" arrow>
                                    <Box color="text.secondary" whiteSpace="nowrap" pl={2}>
                                        <LoadingButton
                                            variant="outlined"
                                            color="inherit"
                                            size="medium"
                                            disableElevation
                                            loading={isSending}
                                            loadingLabel="Sending..."
                                            onClick={handleResendEmail}>
                                            Resend Email
                                        </LoadingButton>
                                    </Box>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                    {!isViewing && step !== undefined && moveToStep && (
                        <Stepper step={step} moveToStep={moveToStep} completedSteps={completedSteps} />
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default WizardHeader;
