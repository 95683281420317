import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => createStyles({
    richText: {
        "@global": {
            "p, ol, ul": {
                margin: 0,
            },
            ".ql-indent-1": {
                marginLeft: 18,
            },
            ".ql-indent-2": {
                marginLeft: 38,
            },
            ".ql-indent-3": {
                marginLeft: 58,
            },
            ".ql-indent-4": {
                marginLeft: 78,
            },
            ".ql-indent-5": {
                marginLeft: 98,
            },
            ".ql-indent-6": {
                marginLeft: 118,
            },
            ".ql-indent-7": {
                marginLeft: 138,
            },
            ".ql-indent-8": {
                marginLeft: 158,
            },
        },
    },
}));

export default useStyles;
