import React from "react";
import { TextFieldProps } from "@material-ui/core";
import { FormInput } from "components/Form";
import InputMask from "react-input-mask";
import { useField } from "formik";

export default function FormPhoneInput(props: TextFieldProps) {
    const [, meta, helpers] = useField(props.name ?? "");
    
    return (
        <InputMask
            alwaysShowMask={false}
            mask="999-999-9999"
            value={meta.value}
            disabled={props.disabled || false}
            onChange={(event) => helpers.setValue(event.target.value)}
        >
            {() => <FormInput {...props} type="tel" />}
        </InputMask>
    );
}