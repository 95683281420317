import * as React from "react";

function SvgFolderOpened(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.381 4.61V3.09a.85.85 0 00-.85-.85h-6.92L6.511.93c-.16-.19-.4-.3-.65-.3h-4.39a.835.835 0 00-.85.84v10.4c0 .47.38.85.85.85h13.06c.47 0 .85-.38.85-.85"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeMiterlimit={10}
      />
      <path
        d="M14.531 12.72H1.471c-.47 0-.85-.35-.85-.77l2.55-6.13c0-.43.38-.77.85-.77h13.06c.47 0 .85.35.85.77l-2.55 6.13c0 .43-.38.77-.85.77z"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgFolderOpened;
