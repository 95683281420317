import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Box } from "@material-ui/core";

import { MenuLink } from "svgComponents";

import useStyles from "./styles";

import logo from "./logo.png";

interface LogoProps {
    navBarClose: () => void
}

function Logo({ navBarClose }: LogoProps) {
    const classes = useStyles();
    return (
        <Box
            display="flex" alignItems="center" justifyContent="space-between"
            pt={2} pb={3}
        >
            <Box pl={2}>
                <Link to="/">
                    <Box display="flex" alignItems="center">
                        <img src={logo} alt="logo" />
                    </Box>
                </Link>
            </Box>
            <IconButton className={classes.closeButton} aria-label='close' onClick={() => navBarClose()}>
                <MenuLink/>
            </IconButton>
        </Box>
    );
}

export default Logo;
