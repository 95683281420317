import React, { forwardRef, useState, useContext, useEffect, useCallback, ForwardedRef, useImperativeHandle, useMemo, useRef } from "react";
import { useFormikContext } from "formik";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { CompaniesContext } from "context";

import PaperCard from "components/PaperCard";
import ClassUsersAssignment from "components/ClassUsersAssignment";
import LevelFilter, { LevelFilterRef } from "components/LevelFilter";

import { AssignUsersRef, CertificationClassUser, CertificationUser, IClassBaseForm } from "types/Certification";
import { CompanyLevel } from "types";
import { getClassEvaluatorsByСompanyId, getClassTraineesByСompanyId } from "services/certificationService";
import { CertificationClassEntity } from "types/constants";

interface ClassUsersAssignmentManagerProps {
    assignedClassUsers: CertificationClassUser[];
    isEvaluator?: boolean;
}

const ClassUsersAssignmentManager = forwardRef(
    ({ assignedClassUsers, isEvaluator }: ClassUsersAssignmentManagerProps, ref: ForwardedRef<AssignUsersRef>) => {
        const [levelIds, setLevelIds] = useState<Array<CompanyLevel["id"]>>([]);
        const levelFilterRef = useRef<LevelFilterRef>(null);
        const { isSubmitting } = useFormikContext<IClassBaseForm>();
        const { currentCompany, companyLevels } = useContext(CompaniesContext);

        const [isDateFetched, setIsDateFetched] = useState<boolean>(false);
        const [assignedUsers, setAssignedUsers] = useState<CertificationUser[]>([]);
        const [availableUsers, setAvailableUsers] = useState<CertificationUser[]>([]);

        const fetchRequest = useCallback(
            async (companyId: string) => (isEvaluator ? getClassEvaluatorsByСompanyId(companyId) : getClassTraineesByСompanyId(companyId)),
            [isEvaluator]
        );

        const fetchData = useCallback(
            (companyId: string) =>
                fetchRequest(companyId)
                    .then((availableUsers) => {
                        const assignedUsers = assignedClassUsers
                            .filter((user) => user.isEvaluator === !!isEvaluator)
                            .map((user) => ({
                                ...user,
                                id: user.userId,
                            }));
                        setAssignedUsers(assignedUsers);
                        setAvailableUsers(availableUsers.filter(({ id }) => !assignedUsers.find((user) => user.id === id)));
                    })
                    .finally(() => {
                        setIsDateFetched(true);
                    }),
            [assignedClassUsers, fetchRequest, isEvaluator]
        );

        const filterChangedHandler = useCallback(() => {
            levelFilterRef?.current?.reset();
            setLevelIds([]);
        }, []);

        const getAssignments = useCallback(
            () => ({
                availableUsers,
                assignedUsers,
            }),
            [assignedUsers, availableUsers]
        );

        useImperativeHandle(ref, () => ({
            getAssignments,
        }));

        useEffect(() => {
            if (currentCompany && !isDateFetched) {
                fetchData(currentCompany.id);
            }
        }, [fetchData, currentCompany, isDateFetched]);

        const entity = useMemo(() => (isEvaluator ? CertificationClassEntity.Evaluator : CertificationClassEntity.Trainee), [isEvaluator]);

        return (
            <Grid container spacing={3}>
                {isDateFetched ? (
                    <>
                        <Grid item xs={12} md={8}>
                            <PaperCard>
                                <ClassUsersAssignment
                                    entity={entity}
                                    levelIds={levelIds}
                                    onFilterChanged={filterChangedHandler}
                                    availableUsers={availableUsers}
                                    assignedUsers={assignedUsers}
                                    setAvailableUsers={setAvailableUsers}
                                    setAssignedUsers={setAssignedUsers}
                                />
                            </PaperCard>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PaperCard>
                                <LevelFilter<true>
                                    multiple
                                    title={`${entity} Filter`}
                                    companyLevels={companyLevels}
                                    onFilter={setLevelIds}
                                    isSubmitting={isSubmitting}
                                    ref={levelFilterRef}
                                />
                            </PaperCard>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} md={8}>
                        <PaperCard>
                            <Box px={6} py={4} textAlign="center">
                                <CircularProgress size={40} />
                            </Box>
                        </PaperCard>
                    </Grid>
                )}
            </Grid>
        );
    }
);

export default ClassUsersAssignmentManager;
