import React, { useCallback, useState } from "react";
import { Box, Typography, Paper, Grid, Button } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "svgComponents";

import { AssignmentCaptionMessage, AssignmentWarningMessage } from "components/AssignmentMessages";

import { CertificationUser } from "types/Certification";
import { CompanyLevel } from "types";
import { CertificationClassEntity } from "types/constants";

import useStyles from "./styles";
import ClassUsersColumn from "./ClassUsersColumn";

interface ClassUsersAssignmentProps {
    entity: CertificationClassEntity;
    levelIds: Array<CompanyLevel["id"]>;
    onFilterChanged: () => void;
    availableUsers: CertificationUser[];
    assignedUsers: CertificationUser[];
    setAvailableUsers: (users: CertificationUser[]) => void;
    setAssignedUsers: (users: CertificationUser[]) => void;
}

export default function ClassUsersAssignment({
    entity,
    levelIds,
    onFilterChanged: filterChangedHandler,
    availableUsers,
    assignedUsers,
    setAvailableUsers,
    setAssignedUsers,
}: ClassUsersAssignmentProps) {
    const classes = useStyles();
    const [checkedAvailableUsers, setCheckedAvailableUsers] = useState<string[]>([]);
    const [checkedAssignedUsers, setCheckedAssignedUsers] = useState<string[]>([]);

    const filteredByLevelAvailableUsers = levelIds.length
        ? availableUsers.filter((user) => levelIds.includes(user.companyLevelId!))
        : availableUsers;

    const filteredByLevelAssignedUsers = levelIds.length
        ? assignedUsers.filter((user) => levelIds.includes(user.companyLevelId!))
        : assignedUsers;

    const setChecked = (availableIds: string[] = [], assignedIds: string[] = []) => {
        setCheckedAvailableUsers(availableIds);
        setCheckedAssignedUsers(assignedIds);
    };

    const handleAssigneUsers = useCallback(() => {
        const assigned: CertificationUser[] = [];
        const available: CertificationUser[] = availableUsers.filter((user) => {
            if (checkedAvailableUsers.includes(user.id)) {
                assigned.push(user);
                return false;
            }
            return true;
        });
        setAvailableUsers(available);
        setAssignedUsers([...assignedUsers, ...assigned]);
        setCheckedAvailableUsers([]);
    }, [assignedUsers, availableUsers, checkedAvailableUsers, setAssignedUsers, setAvailableUsers]);

    const handleUnassignUsers = useCallback(() => {
        const available: CertificationUser[] = [];
        const assigned: CertificationUser[] = assignedUsers.filter((user) => {
            if (checkedAssignedUsers.includes(user.id)) {
                available.push(user);
                return false;
            }
            return true;
        });
        setAvailableUsers([...availableUsers, ...available]);
        setAssignedUsers(assigned);
        setCheckedAssignedUsers([]);
    }, [assignedUsers, availableUsers, checkedAssignedUsers, setAssignedUsers, setAvailableUsers]);

    return (
        <Paper className={classes.paper}>
            <Box display="flex" flexDirection="column" py={2} px={3}>
                <Box mb={2} fontWeight={600}>
                    <Typography variant="h3">{`Assign ${entity}s`}</Typography>
                </Box>
                <Box mb={2}>
                    <AssignmentCaptionMessage />
                </Box>
                <Box mb={3}>
                    <AssignmentWarningMessage />
                </Box>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item className={classes.column}>
                        <ClassUsersColumn
                            title={`Available ${entity}s`}
                            entity={entity}
                            users={availableUsers}
                            filteredByLevelUsers={filteredByLevelAvailableUsers}
                            checked={checkedAvailableUsers}
                            setChecked={setChecked}
                            onFilterChanged={filterChangedHandler}
                        />
                    </Grid>
                    <Grid item className={classes.columnCenter}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                className={classes.arrowBtn}
                                onClick={handleAssigneUsers}
                                disabled={!filteredByLevelAvailableUsers.length || !checkedAvailableUsers.length}
                                aria-label="move selected right">
                                <ArrowRight />
                            </Button>
                            <Button
                                className={classes.arrowBtn}
                                onClick={handleUnassignUsers}
                                disabled={!assignedUsers.length || !checkedAssignedUsers.length}
                                aria-label="move selected left">
                                <ArrowLeft />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.column}>
                        <ClassUsersColumn
                            title={`Distribution ${entity}s`}
                            entity={entity}
                            users={assignedUsers}
                            filteredByLevelUsers={filteredByLevelAssignedUsers}
                            checked={checkedAssignedUsers}
                            setChecked={(ids) => {
                                setChecked([], ids);
                            }}
                            onFilterChanged={filterChangedHandler}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
