import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    paper: {
        width: "100%",
        height: 280,
        padding: 0,
        overflow: "auto",
        border: `1px solid ${theme.colors.structure.background}`,
        borderRadius: "6px",
        background: "linear - gradient(180deg, #EEEEEE 0 %, #DADADA 85.38 %, rgba(216, 216, 216, 0) 100 %)",
    },
    arrowBtn: {
        minWidth: 40,
        width: 40,
        height: 40,
        boxShadow: "none",
        border: "none",
        display: "flex",

        "& span": {
            margin: "auto",
        },

        "& path": {
            fill: theme.palette.primary.main,
        },

        "&.Mui-disabled path": {
            fill: theme.colors.structure.fieldsOutline,
        },
    },
    list: {
        padding: 0,
    },
    column: {
        [theme.breakpoints.up("xs")]: {
            flexGrow: 0,
            maxWidth: "100%",
            flexBasis: "100%",
            marginBottom: "15px",
        },
        [theme.breakpoints.up("md")]: {
            flexGrow: 0,
            maxWidth: "100%",
            flexBasis: "100%",
            marginBottom: "15px",
        },
        [theme.breakpoints.up("lg")]: {
            flexGrow: 0,
            maxWidth: "45%",
            flexBasis: "45%",
        },
    },
    columnCenter: {
        flexGrow: 0,
        maxWidth: "10%",
        flexBasis: "10%",
    },
}));
