import React, { useContext, useMemo } from "react";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import { Box, CircularProgress, Divider, Grid, Hidden, Typography } from "@material-ui/core";

import AvatarWithScore from "components/AvatarWithScore";
import CoachingSessionName from "components/CoachingSessionName";
import LabelScoreBar from "components/LabelScoreBar";
import NavigationLink from "components/NavigationLink";
import NoDataMessage from "components/NoDataMessage";
import PaperSection from "components/PaperSection";
import RichText from "components/RichText";
import { Routings, SizeEnum } from "types/constants";
import { CoachingSession, FcrCoachingSkill, TeamProfileRouteParams } from "types";
import { formatDate, formatTime } from "helpers/dateHelpers";
import { getUserFullName } from "helpers/userProfileHelper";
import { ConfigurationContext } from "context";

import useStyles from "./styles";

interface LastCoachingSessionProps {
    coachingSession?: CoachingSession | null;
    coachingSkills: FcrCoachingSkill[];
}

const LastCoachingSession = ({ coachingSession, coachingSkills }: LastCoachingSessionProps) => {
    const classes = useStyles();
    const { url } = useRouteMatch();
    const history = useHistory();
    const { userId } = useParams<TeamProfileRouteParams>();
    const { hasCoachingReportAccess } = useContext(ConfigurationContext);

    const isFetching = coachingSession === null;
    const fcrResult = coachingSession?.fcrResult;

    const formattedDate = useMemo(
        () => coachingSession?.scheduleStartDate && formatDate(coachingSession?.scheduleStartDate),
        [coachingSession?.scheduleStartDate]
    );
    const formattedTime = useMemo(
        () => coachingSession?.scheduleStartDate && formatTime(coachingSession?.scheduleStartDate),
        [coachingSession?.scheduleStartDate]
    );

    const pathForRouting = useMemo(() => url === "/" ? "" : url, [url]);

    const filteredSkills = useMemo(() =>
        fcrResult && fcrResult.fcrCoachingSkillResults && fcrResult.fcrCoachingSkillResults.length ?
            fcrResult.fcrCoachingSkillResults
                .filter(e => !e.isIgnored)
                .map(skill => ({
                    ...skill,
                    fcrCoachingSkill: coachingSkills.find(e => e.id === skill.fcrCoachingSkillId),
                }))
            : [],
    [coachingSkills, fcrResult]
    );

    return (
        <PaperSection title={
            <Box fontWeight={600} mr={1.5}>
                <Typography variant="h2">
                    {userId ? "Last Coaching Session" : "My Last Coaching Session"}
                </Typography>
            </Box>
        } mb={{ xs: 3, sm: 6 }} href={`${pathForRouting}${Routings.reports}`} label="View All">
            {coachingSession && fcrResult && !!coachingSkills.length && (
                <Box display="flex" flexDirection="column">
                    <Box my={2}>
                        <Grid container>
                            <Grid item xs={12} lg={4}>
                                <Box display="flex" alignItems="center" px={5} mb={{ xs: 2, lg: 0 }} justifyContent={{ xs: "center", lg: "start" }}>
                                    <AvatarWithScore user={coachingSession.coach} size={SizeEnum.small}/>
                                    <Box display="flex" flexDirection="column" ml={3}>
                                        <Typography variant="caption">
                                            Coached by
                                        </Typography>
                                        <Box fontWeight={500}>
                                            <Typography variant="h3">
                                                {coachingSession.coach && getUserFullName(coachingSession.coach)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Hidden mdDown>
                                <Divider orientation="vertical" flexItem/>
                            </Hidden>
                            <Grid item xs={6} lg={4}>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box
                                        display="flex"
                                        fontWeight={500}
                                    >
                                        <CoachingSessionName coachingSessionType={coachingSession.coachingSessionType} />
                                    </Box>
                                    {hasCoachingReportAccess(coachingSession) && (
                                        <NavigationLink
                                            href={{
                                                pathname: `${Routings.fcr}/${coachingSession.id}`,
                                                state: { backPath: history.location.pathname, backText: "Back to Profile" },
                                            }}
                                            className={classes.link}
                                        >
                                            View Report
                                        </NavigationLink>
                                    )}
                                </Box>
                            </Grid>
                            <Divider orientation="vertical" flexItem/>
                            <Grid item xs={6} lg={4}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    mx="auto"
                                    height={1}
                                >
                                    <Typography variant="caption" color="inherit">
                                        {formattedDate}
                                    </Typography>
                                    <Box fontWeight={500}>
                                        <Typography variant="h3">
                                            {formattedTime}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box display="flex" flexDirection="column" p={4}>
                        <Box mb={2.5} fontWeight={600}>
                            <Typography variant="h3">
                                Skills Coached
                            </Typography>
                        </Box>
                        <Grid container spacing={6}>
                            {filteredSkills.length ? filteredSkills.map((skill) => (
                                skill.fcrCoachingSkill && <Grid item xs={6} key={skill.id}>
                                    <LabelScoreBar
                                        label={skill.fcrCoachingSkillName}
                                        score={skill.rating}
                                        minRating={skill.fcrCoachingSkill.minRating}
                                        maxRating={skill.fcrCoachingSkill.maxRating}
                                        justifyContent="flex-end"
                                        height={1}
                                    />
                                </Grid>
                            )) : (
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        No data to share
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Box mt={6} mb={2} fontWeight={600}>
                            <Typography variant="h3">
                                Development Goals
                            </Typography>
                        </Box>
                        {fcrResult.fcrDevelopmentGoal && fcrResult.fcrDevelopmentGoal.developmentGoals ? (
                            <Typography variant="body2" component="div">
                                <RichText text={fcrResult.fcrDevelopmentGoal?.developmentGoals || ""}/>
                            </Typography>
                        ) : (
                            <Typography variant="caption">
                                No data to share
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
            {(!coachingSession || !fcrResult) && (
                isFetching ? (
                    <Box display="flex" justifyContent="center" py={10}>
                        <CircularProgress size={24}/>
                    </Box>
                ) : (
                    <NoDataMessage message="There's nothing to report here yet." py={5} />
                )
            )}
        </PaperSection>
    );
};

export default LastCoachingSession;