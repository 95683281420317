import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useField } from "formik";

import useStyles from "./styles";

export function Input({ variant = "outlined", ...props }: TextFieldProps) {
    const classes = useStyles();

    const defaultConfig = {
        ...props,
        variant,
        fullWidth: true,
    };

    return <TextField
        {...defaultConfig}
        InputProps={{
            ...props.InputProps,
            classes: {
                root: classes.root,
                input: classes.input,
            },
        }}
        InputLabelProps={{
            ...props.InputLabelProps,
            classes: {
                outlined: classes.outlined,
            },
        }}
        SelectProps={{
            IconComponent: ExpandMore,
        }}
    />;
}

function FormInput({ name = "", error, helperText, ...props }: TextFieldProps) {
    const [field, meta] = useField(name);

    const defaultConfig = {
        ...field,
        ...props,
    };

    return <Input
        {...defaultConfig}
        error={!!(error || meta.touched && meta.error)}
        helperText={helperText || meta.touched && meta.error ? meta.error : <></>}
    />;
}

export default FormInput;
