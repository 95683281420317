import React, { ReactElement, useCallback, useState } from "react";
import { Snackbar as MaterialSnackbar, Box, ButtonBase, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { SnackbarContext } from "context";
import { CloseCross } from "svgComponents";
import { AlertSeverity } from "types/constants";

interface SnackbarProps {
    children: ReactElement;
}

type SnackbarMessage = {
    text: string;
    severity: AlertSeverity;
};

function Snackbar({ children }: SnackbarProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState<SnackbarMessage[]>([]);
    const snackMessage = messages[0];

    const openSnackbar = useCallback((text: string, severity = AlertSeverity.info) => {
        setIsOpen(true);
        setMessages((value) => [...value, { text, severity }]);
    }, []);

    const removeSnackbar = useCallback(() => {
        setIsOpen(false);

        const newMessages = [...messages];
        newMessages.shift();
        setMessages(newMessages);
        setIsOpen(newMessages.length > 0);
    }, [messages]);

    return (
        <SnackbarContext.Provider value={{ openSnackbar }}>
            {children}
            {isOpen && snackMessage &&
                <MaterialSnackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={isOpen}
                    autoHideDuration={10000}
                    onClose={removeSnackbar}
                >
                    <Alert variant="filled" severity={snackMessage.severity}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1">
                                {snackMessage.text}
                            </Typography>
                            <Box pl={2} display="flex" alignItems="center">
                                <ButtonBase aria-label="close" onClick={removeSnackbar}>
                                    <CloseCross />
                                </ButtonBase>
                            </Box>
                        </Box>
                    </Alert>
                </MaterialSnackbar>
            }
        </SnackbarContext.Provider>
    )
}

export default Snackbar;
