import React from "react";
import { get } from "lodash";
import { Button, FormHelperText, Grid, IconButton } from "@material-ui/core";
import { FieldArray, useFormikContext, ErrorMessage } from "formik";
import { AddIcon, DeleteIcon } from "svgComponents";

import { FormInput, FormMultilineInput } from "components/Form";
import { ComponentTypeEvaluation } from "types/constants";
import { EvaluationItemConfiguration } from "types/Certification";
import { buildEvaluationItemValue } from "helpers/certificationHelper";

import useStyles from "../styles";

interface EvaluationItemValuesProps {
    evaluationInUse: boolean;
}

export default function EvaluationItemValues({ evaluationInUse }: EvaluationItemValuesProps) {
    const classes = useStyles();
    const { values, errors, touched, setFieldTouched } = useFormikContext<EvaluationItemConfiguration>();

    return (
        <FieldArray name="evaluationItemValues">
            {({ push, remove }) => (
                <>
                    {values.evaluationItemTypeId !== ComponentTypeEvaluation.staticText && (
                        <Grid alignItems="center" container spacing={2}>
                            {values.evaluationItemTypeId === ComponentTypeEvaluation.textEntry ? (
                                <Grid className={classes.bodyText} item xs={12}>
                                    <FormMultilineInput name="evaluationItemValues[0].name" placeholder="Value text" />
                                    <ErrorMessage name="evaluationItemValues[0].name">
                                        {(message) => <FormHelperText error>{message}</FormHelperText>}
                                    </ErrorMessage>
                                </Grid>
                            ) : (
                                <>
                                    {values.evaluationItemValues.map((evaluationItemValue, idx) => (
                                        <React.Fragment key={evaluationItemValue.id}>
                                            <Grid item xs={1}>
                                                {idx + 1}.
                                            </Grid>
                                            <Grid item xs={7}>
                                                <FormInput
                                                    className={classes.field}
                                                    fullWidth
                                                    name={`evaluationItemValues[${idx}].name`}
                                                    placeholder="Name text"
                                                    type="text"
                                                    onBlur={() => setFieldTouched("evaluationItemValues")}
                                                    error={
                                                        !!(
                                                            errors.evaluationItemValues &&
                                                            touched.evaluationItemValues &&
                                                            !get(values, `evaluationItemValues[${idx}].name`)
                                                        )
                                                    }
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormInput
                                                    className={classes.field}
                                                    fullWidth
                                                    name={`evaluationItemValues[${idx}].value`}
                                                    placeholder="Value text"
                                                    type="number"
                                                    onBlur={() => setFieldTouched("evaluationItemValues")}
                                                    error={
                                                        !!(
                                                            errors.evaluationItemValues &&
                                                            touched.evaluationItemValues &&
                                                            !get(values, `evaluationItemValues[${idx}].value`)
                                                        )
                                                    }
                                                    InputProps={{
                                                        endAdornment: values.evaluationItemValues.length > 2 && !evaluationInUse && (
                                                            <IconButton onClick={() => remove(idx)}>
                                                                <DeleteIcon height={20} width={20} />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                    <ErrorMessage name="evaluationItemValues">
                                        {(message) =>
                                            typeof message === "string" ? (
                                                <>
                                                    <Grid item xs={1}>
                                                        &nbsp;
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <FormHelperText error>{message}</FormHelperText>
                                                    </Grid>
                                                </>
                                            ) : null
                                        }
                                    </ErrorMessage>
                                    <Grid item xs={1}>
                                        &nbsp;
                                    </Grid>
                                    {!evaluationInUse && (
                                        <Grid item xs={11}>
                                            <Button
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                onClick={() =>
                                                    push(
                                                        buildEvaluationItemValue({
                                                            order: values.evaluationItemValues.length + 1,
                                                        })
                                                    )
                                                }>
                                                Add Value
                                            </Button>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    )}
                </>
            )}
        </FieldArray>
    );
}
