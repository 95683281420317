import * as React from "react";

function SvgActionable(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Tick" fill="currentColor">
                    <polygon
                        id="Path-3"
                        points="12.7928932 4 13.5 4.70710678 6.51001701 12 2.5 7.98998299 3.20710678 7.28287621 6.50955339 10.5844296"
                    ></polygon>
                </g>
            </g>
        </svg>
    );
}

export default SvgActionable;
