import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";

import NoDataMessage from "components/NoDataMessage";
import { getUpcomingCoachingSessionsForCoach, getUpcomingCoachingSessionsForHighLevelLead } from "services/coachingSessionServices";
import { CoachingPlanType, UpcomingCoachingSession, UserProfile } from "types";

import UpcomingSession from "./UpcomingSession";

interface UpcomingSessionsProps {
    user: UserProfile;
    teamMode: boolean;
    onDataIsFetched: () => void;
}

const UpcomingSessions = ({ user, teamMode, onDataIsFetched }: UpcomingSessionsProps) => {
    const [upcomingSessions, setUpcomingSessions] = useState<UpcomingCoachingSession[]>();

    const updateCoachingPlan = useCallback((newCoachingPlan: CoachingPlanType) => {
        const sessions: UpcomingCoachingSession[] = (upcomingSessions ?? [])
            .map(session => session.lastFcrResult
                ? ({
                    ...session,
                    lastFcrResult: {
                        ...session.lastFcrResult,
                        fcrCoachingPlans: (session.lastFcrResult.fcrCoachingPlans ?? [])
                            .map(oldCoachingPlan => oldCoachingPlan.id === newCoachingPlan.id
                                ? newCoachingPlan
                                : oldCoachingPlan
                            ),
                    },
                })
                : session
            )
        setUpcomingSessions(sessions);
    }, [upcomingSessions]);

    useEffect(() => {
        const fetchUpcomingSessions = async (userId: string) => {
            setUpcomingSessions(undefined);

            const upcomingSessions = teamMode
                ? await getUpcomingCoachingSessionsForHighLevelLead(userId)
                : await getUpcomingCoachingSessionsForCoach(userId);

            setUpcomingSessions(upcomingSessions);
            onDataIsFetched();
        };

        user.id && fetchUpcomingSessions(user.id);
    }, [user.id, teamMode, onDataIsFetched]);

    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="h1" component="span">
                Upcoming Sessions
            </Typography>
            <Box display="flex" flexDirection="column" mt={4}>
                {upcomingSessions && upcomingSessions.length > 0 ? (
                    upcomingSessions.map((upcomingSession) => (
                        <UpcomingSession
                            teamMode={teamMode}
                            upcomingSession={upcomingSession}
                            key={upcomingSession.coachingSession.id}
                            onCoachingPlanChange={updateCoachingPlan}
                        />
                    ))
                ) : (
                    <Box
                        display="flex" justifyContent="center"
                        px={4} pt={3.5} pb={5}
                    >
                        {!upcomingSessions ? (
                            <CircularProgress size={24} />
                        ): (
                            <NoDataMessage message="There's nothing to report here yet" />  
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default React.memo(UpcomingSessions);
