import { setTime } from "helpers/dateHelpers";
import React from "react";
import { useHistory } from "react-router-dom";
import authService from "services/authService";
import { Routings, UI_FLICKER_DELAY } from "types/constants";

import AuthPage from "../AuthPage";

/** This is the page B2C redirect the user to once there is a session and state in the url. */
const UserNotFound = () => {
    const history = useHistory();
    const handleLoginClick = () => {
        if (authService.isAuthenticated()) {
            console.warn("Something went wrong in code as user was redirected to user not found page but they're still authenticated.");
        } else {
            history.push("/");
            setTimeout(() => {
                authService.loginRedirect();
            }, UI_FLICKER_DELAY);
        }
    };
    const message = "User not found, please contact your admin.";
    return <AuthPage isLoading={false} handleButtonClickCb={handleLoginClick} message={message} heading="Access Denied" />;
};
export default UserNotFound;
