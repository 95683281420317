import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";

import ComplianceCheckbox from "components/ComplianceCheckbox";
import MultilineInput from "components/MultilineInput";
import RichText from "components/RichText";
import { acknowledgeFcrResult, readByCoacheeFcrResult, readByCoachFcrResult } from "services/wizardServices";
import { FcrResult } from "types";
import { cleanupRichText } from "helpers/stringHelpers";
import { ConfigurationContext } from "context";

interface CoachingReportAcknowledgementProps {
    fcrResult: FcrResult;
}

const CoachingReportAcknowledgement = ({ fcrResult }: CoachingReportAcknowledgementProps) => {
    const { currentUser } = useContext(ConfigurationContext);
    const [isChecked, toggleIsChecked] = useReducer((state) => !state, false);
    const { acknowledged, acknowledgementComment, readByCoachee, coachId, readByCoach, assigneeId } = fcrResult

    const [isAcknowledged, setIsAcknowledged] = useState(acknowledged);
    const [comment, setComment] = useState(acknowledgementComment || "");
    
    const isCommentNotEmpty = useMemo(() => !!cleanupRichText(comment), [comment]);

    const handleSubmit = useCallback(async () => {
        await acknowledgeFcrResult(fcrResult.id, isCommentNotEmpty ? comment : "");
        setIsAcknowledged(true);
    }, [comment, fcrResult.id, isCommentNotEmpty]);
    
    useEffect(() => {
        if (!readByCoachee && currentUser?.id === assigneeId) {
            readByCoacheeFcrResult(fcrResult.id)
        }
    }, [assigneeId, coachId, currentUser?.id, fcrResult.id, readByCoachee]);

    useEffect(() => {
        if (acknowledgementComment && isAcknowledged && !readByCoach && readByCoachee && currentUser?.id === coachId) {
            readByCoachFcrResult(fcrResult.id)
        }
    }, [acknowledgementComment, coachId, currentUser?.id, fcrResult.id, isAcknowledged, readByCoach, readByCoachee]);

    return (
        <>
            {isAcknowledged ? (
                isCommentNotEmpty && (
                    <>
                        <Box py={7} px={{ xs: 2, sm: 8, lg: 14 }} bgcolor="common.white">
                            <Typography variant="h1" component="span">
                                Comment
                            </Typography>
                            <Box mt={2}>
                                <RichText text={comment} />
                            </Box>
                        </Box>
                    </>
                )
            ) : (
                fcrResult.assigneeId === currentUser?.id && (
                    <>
                        <Box py={7} px={{ xs: 2, sm: 8, lg: 14 }} bgcolor="common.white">
                            <Typography variant="h1" component="span">
                                Acknowledgement
                            </Typography>
                            <ComplianceCheckbox
                                isChecked={isChecked}
                                onChange={toggleIsChecked}
                                label="I read and reviewed this Coaching Report."
                            />
                            <MultilineInput value={comment} onChange={setComment} placeholder="Write a comment (optional)..."/>
                        </Box>
                        <Box
                            position="sticky"
                            bottom={0}
                            display="flex"
                            justifyContent="flex-end"
                            borderTop="1px solid #EEF0F3"
                            bgcolor="common.white"
                            py={4} px={{ xs: 2, sm: 8, lg: 14 }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                onClick={handleSubmit}
                                disabled={!isChecked}
                            >
                                Acknowledge
                            </Button>
                        </Box>
                    </>
                )
            )}
        </>
    );
};

export default CoachingReportAcknowledgement;