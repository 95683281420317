import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Divider, Typography } from "@material-ui/core";

import { ConfigurationContext } from "context";
import RichText from "components/RichText";
import FcrConfigurationItem from "components/FcrConfigurationItem";
import RatingMeaning from "components/RatingMeaning";
import { getFcrConfigurationForUser } from "services/wizardServices";
import { DevelopmentGoals, FcrCoachingSkillObservation, FcrConfiguration, FcrItemConfiguration, FcrResult, ProductSalesData } from "types";
import { ComponentType } from "types/constants";
import { formatDate } from "helpers/dateHelpers";
import { getFcrRatingDescription, sortFcrSkillsObservations } from "helpers/fcrHelper";
import CoachingPlanResourceLink from "components/CoachingPlanModal/CoachingPlanResourceLink";
import PerformanceMetrics, { PerformanceMetricsVariant } from "routes/Dashboard/DashboardContent/PerformanceMetrics";

import { getDevGoalsFields } from "../../Wizard/config";
import CoachingReportAcknowledgement from "./CoachingReportAcknowledgement";

interface CoachingReportViewerContentProps {
    fcrResult: FcrResult;
    createdBy: string;
    coachedBy: string;
    productSalesData?: ProductSalesData;
}

const CoachingReportViewerContent = ({ fcrResult, createdBy, coachedBy, productSalesData }: CoachingReportViewerContentProps) => {
    const { fcrSectionConfiguration } = useContext(ConfigurationContext);

    const [fcrConfiguration, setFcrConfiguration] = useState<FcrConfiguration | null>(null);
    const [fcrItemConfigurations, setFcrItemConfigurations] = useState<FcrItemConfiguration[]>();

    const filteredSkillResults = useMemo(() => (
        sortFcrSkillsObservations(fcrConfiguration?.fcrCoachingSkills ?? [], (fcrResult.fcrCoachingSkillResults || []))
            .filter(e => !e.isIgnored)
    ), [fcrConfiguration?.fcrCoachingSkills, fcrResult.fcrCoachingSkillResults]);
    const filteredActSkillResults = useMemo(() => (
        filteredSkillResults.filter((e) => (e?.fcrCoachingPlans) && (e?.fcrCoachingPlans?.length > 0))
    ), [filteredSkillResults]);

    useEffect(() => {
        const fetchFcrConfiguration = async () => {
            const fcrConfiguration = await getFcrConfigurationForUser(fcrResult.fcrConfigurationId, fcrResult.assigneeId);

            if (fcrConfiguration) {
                setFcrConfiguration(fcrConfiguration);
                setFcrItemConfigurations(fcrConfiguration.fcrItemConfigurations);
            }
        };

        fetchFcrConfiguration();
    }, [fcrResult]);

    const renderOverallRating = (fcrCoachingSkillResult: FcrCoachingSkillObservation) => {
        if (!fcrConfiguration) return null;

        const fcrCoachingSkill = fcrConfiguration.fcrCoachingSkills.find((skill) =>
            skill.id === fcrCoachingSkillResult.fcrCoachingSkillId
        );

        const ratingDescription = fcrCoachingSkill
            && getFcrRatingDescription(fcrCoachingSkill, fcrCoachingSkillResult.rating);

        return <>
            {!fcrConfiguration.hideSkillRatingNumbers && (
                <Typography variant="subtitle2">
                    {fcrCoachingSkillResult.rating}
                </Typography>
            )}
            {!!ratingDescription && (
                <Box fontWeight="initial" mt={1}>
                    <RatingMeaning title={ratingDescription.title} body={ratingDescription.body} />
                </Box>
            )}
        </>
    };

    return (
        <>
            <Box py={4} px={{ xs: 2, sm: 8 }}>
                <Typography variant="h1">
                    Coaching Report Summary
                </Typography>
                <Typography variant="body2">
                    Coached by {coachedBy}
                </Typography>
                <Typography variant="body2">
                    Created by {createdBy}
                </Typography>
            </Box>
            <Box bgcolor="common.white" py={7} px={{ xs: 2, sm: 8, lg: 14 }} mb={3} pb={3}>
                <Box mb={7}>
                    <Typography variant="h1" component="span">
                        {fcrSectionConfiguration?.section1Label}
                    </Typography>
                    {fcrConfiguration && getDevGoalsFields(fcrConfiguration).map(({ fieldName, label }) => (
                        <Typography key={fieldName} variant="body1" component="div">
                            <Box mt={4} mb={2} fontWeight={600}>
                                {label}
                            </Box>
                            <RichText
                                text={
                                    fcrResult.fcrDevelopmentGoal &&
                                    fcrResult.fcrDevelopmentGoal[fieldName as keyof DevelopmentGoals] || ""
                                } />
                        </Typography>
                    ))}
                    {productSalesData && (
                        <Box mb={{ xs: 3, sm: 6 }}>
                            <PerformanceMetrics
                                productSalesData={productSalesData}
                                variant={PerformanceMetricsVariant.FcrResult}
                            />
                        </Box>
                    )}
                </Box>
                {!!filteredSkillResults.length && (
                    <>
                        <Divider />
                        <Box mt={6} mb={7}>
                            <Typography variant="h1" component="span">
                                {fcrSectionConfiguration?.section2Label}
                            </Typography>
                            {filteredSkillResults && filteredSkillResults.map((fcrCoachingSkillResult) => (
                                <Box key={fcrCoachingSkillResult.id} mt={6}>
                                    <Box border="1px solid #E4E7EB" borderRadius={8} p={6}>
                                        <Typography variant="h1" component="span">
                                            {fcrCoachingSkillResult.fcrCoachingSkillName}
                                        </Typography>
                                        <Box fontWeight={600} mt={2.5} mb={2}>
                                            <Typography variant="body1">
                                                Overall Rating
                                            </Typography>
                                            <Box mb={2}>
                                                {renderOverallRating(fcrCoachingSkillResult)}
                                            </Box>
                                            <Typography variant="body1">
                                                Observations
                                            </Typography>
                                        </Box>
                                        <Typography variant="body1" component="div">
                                            <RichText text={fcrCoachingSkillResult.observation} />
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
                {!!filteredActSkillResults.length && (
                    <>
                        <Divider />
                        <Box mt={6} mb={7}>
                            <Typography variant="h1" component="span">
                                {fcrSectionConfiguration?.section3Label}
                            </Typography>
                            {filteredActSkillResults.map((fcrCoachingSkillResult) => (!!fcrCoachingSkillResult?.fcrCoachingPlans?.length) && (
                                <Box key={fcrCoachingSkillResult.id} mt={6}>
                                    <Box border="1px solid #E4E7EB" borderRadius={8} pt={6} pb={3} px={6}>
                                        <Typography variant="h1" component="span">
                                            {fcrCoachingSkillResult.fcrCoachingSkillName}
                                        </Typography>
                                        {fcrCoachingSkillResult.fcrCoachingPlans && fcrCoachingSkillResult.fcrCoachingPlans.map((coachingPlan) => (
                                            <Box key={coachingPlan.id} pb={3}>
                                                <Box fontWeight={600} mt={2.5} mb={2}>
                                                    Coaching Plan
                                                </Box>
                                                <Typography variant="body1" component="div">
                                                    <RichText text={coachingPlan.coachingPlanData} />
                                                </Typography>
                                                {coachingPlan.resources.length > 0 && (
                                                    <Box mt={3}>
                                                        <Typography variant="body2">
                                                            <Box component="span" fontWeight={500}>Resource(s):</Box>
                                                        </Typography>
                                                        <Box component="ul" mt={1.5} mb={0} pl={0} style={{ listStyle: "none" }}>
                                                            {coachingPlan.resources.map((resource, idx) => (
                                                                <Box key={resource.id} component="li" mt={idx > 0 ? 1 : 0}>
                                                                    <CoachingPlanResourceLink resource={resource} />
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    </Box>
                                                )}
                                                <Box mt={3}>
                                                    Due Date:
                                                    <Box display="inline" fontWeight={500} ml={1}>
                                                        {formatDate(coachingPlan.dueDate)}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
            </Box>
            {fcrItemConfigurations && !!fcrItemConfigurations.length && !!fcrResult.fcrItemResults?.length &&
                <Box bgcolor="common.white" py={7} px={{ xs: 2, sm: 8, lg: 14 }} mb={3}>
                    {fcrItemConfigurations.map(fcrItemConfiguration => {
                        const fcrItemResults = fcrResult.fcrItemResults?.filter(e => e.fcrItemConfigurationId === fcrItemConfiguration.id) ?? [];
                        return fcrItemResults.length > 0 ?
                            <FcrConfigurationItem
                                key={fcrItemConfiguration.id}
                                fcrItemConfiguration={fcrItemConfiguration}
                                fcrItemResults={fcrItemResults}
                            /> :
                            <Box mb={4} key={fcrItemConfiguration.id}>
                                <Box fontWeight={600} color="text.primary" mb={1.5}>
                                    <Typography variant="h3">
                                        {fcrItemConfiguration.elementHeader}
                                    </Typography>
                                    <Typography variant="body1" component="div">
                                        <RichText text={fcrItemConfiguration.elementBody} />
                                    </Typography>
                                </Box>
                                {fcrItemConfiguration.fcrItemTypeId !== ComponentType.staticText && (
                                    <Typography variant="body1" color="textSecondary">
                                        Unanswered
                                    </Typography>
                                )}
                            </Box>;
                    })}
                </Box>
            }
            <CoachingReportAcknowledgement fcrResult={fcrResult} />
        </>
    );
}

export default CoachingReportViewerContent;