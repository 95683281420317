import React, { useContext, useState } from "react";
import { Box, Typography, Button, Grid, CircularProgress } from "@material-ui/core";
import { CompaniesContext } from "context";
import { PlusIcon } from "svgComponents";
import { FcrCoachingSkill } from "types";
import NoDataMessage from "components/NoDataMessage";
import PaperSection from "components/PaperSection";
import { getFcrCoachingSkillsForCompany, updateOrdersOfFcrCoachingSkills } from "services/fcrCoachingSkillServices";

import SkillFormModal from "./SkillFormModal";
import SkillsDragAndDropList from "./SkillsDragAndDropList";

function SkillManagement() {
    const {
        currentCompany,
        isFetchingCompanyData,
        fcrCoachingSkillsForCompany: skills,
        setFcrCoachingSkillsForCompany,
    } = useContext(CompaniesContext);
    const [isSkillFormModalOpen, setIsFormSkillModalOpen] = useState<boolean>(false);
    const [editingSkill, setEditingSkill] = useState<FcrCoachingSkill | null>(null);

    const fetchFcrCoachingSkillsForCompany = async (newSkillId?: string) => {
        if (currentCompany) {
            const fcrCoachingSkillsForCompany = await getFcrCoachingSkillsForCompany(currentCompany.id);
            setFcrCoachingSkillsForCompany(fcrCoachingSkillsForCompany);

            if (editingSkill || newSkillId) {
                const editingSkillId = editingSkill?.id || newSkillId;
                setEditingSkill(
                    fcrCoachingSkillsForCompany.find((skill) => skill.id === editingSkillId) ?? null
                )
            }
        }
    };

    const handleCreateSkill = () => {
        setIsFormSkillModalOpen(true);
    };

    const handleEditSkill = (skill: FcrCoachingSkill) => {
        setIsFormSkillModalOpen(true);
        setEditingSkill(skill);
    };

    const handleCloseModal = () => {
        setIsFormSkillModalOpen(false);
        setEditingSkill(null);
    };

    const handleSkillsReordered = (skills: FcrCoachingSkill[]) => {
        const skillOrders = skills.map((skill, idx) => ({ id: skill.id, order: idx + 1 }));

        updateOrdersOfFcrCoachingSkills(skillOrders).then(fetchFcrCoachingSkillsForCompany);
    };

    const nextOrder = skills.reduce((max, skill) => skill.order > max ? skill.order : max, 1) + 1;

    return (
        <>
            <Grid alignItems="center" container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h1">Skill Management</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box textAlign={{ xs: "left", md: "right" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!currentCompany}
                            disableElevation
                            startIcon={<PlusIcon />}
                            onClick={handleCreateSkill}

                        >
                            New Skill
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={5}>
                <PaperSection title={isFetchingCompanyData ? "" : `${skills.length} skills`}>
                    {!isFetchingCompanyData && (
                        <SkillsDragAndDropList
                            skills={skills}
                            onEditSkill={handleEditSkill}
                            onSkillsReordered={handleSkillsReordered}
                        />
                    )}
                    {!isFetchingCompanyData && !skills.length && (
                        <Box px={6} py={4}>
                            <NoDataMessage />
                        </Box>
                    )}
                    {isFetchingCompanyData && (
                        <Box px={6} py={4} textAlign="center">
                            <CircularProgress size={40} />
                        </Box>
                    )}
                </PaperSection>
            </Box>
            <SkillFormModal
                isOpen={isSkillFormModalOpen}
                skill={editingSkill}
                nextOrder={nextOrder}
                onClose={handleCloseModal}
                onSuccess={fetchFcrCoachingSkillsForCompany}
            />
        </>
    );
}

export default SkillManagement;
