import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
    backgroundColor: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => createStyles({
    iconContainer: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.spacing(6),
        backgroundColor: (props) => props.backgroundColor,
        "& svg": {
            color: theme.colors.text.light,
        },
    },
    label: {
        paddingLeft: theme.spacing(1),
        "&:hover": {
            backgroundColor: "initial",
        },
        ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content &": {
            backgroundColor: "initial",
        },
        ".MuiTreeItem-root:focus > .MuiTreeItem-content &": {
            backgroundColor: "initial",
        },
        ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content &:hover": {
            backgroundColor: "initial",
        },
        ".MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content &": {
            backgroundColor: "initial",
        },
    },
    content: {
        padding: theme.spacing(2),
        width: "auto",
        "&:hover": {
            backgroundColor: theme.palette.info.main,
        },
    },
    group: {
        marginLeft: theme.spacing(5),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2.5),
        borderLeft: `1px solid ${theme.palette.info.dark}`,
    },
}));

export default useStyles;
