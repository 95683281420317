import React from "react";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { Box, Button, Dialog, FormControlLabel, Grid, MenuItem, Typography, useTheme } from "@material-ui/core";
import { Form, useFormik, FormikProvider } from "formik";
import { DeleteIcon, TickIcon } from "svgComponents";
import { CoachingSessionType } from "types";
import { CoachingSessionTypeIconsEnum } from "types/constants";
import { getSessionTypeIcon } from "helpers/coachingHistoryHelper";
import LoadingButton from "components/LoadingButton";
import { FormInput, FormSwitcher } from "components/Form";
import { saveCoachingSessionType } from "services/coachingSessionServices";

import useStyles from "./styles";

interface CoachingSessionTypeFormModalProps {
    item: CoachingSessionType | null;
    open: boolean;
    nextOrder: number;
    fcrConfigurationId: string;
    isDeleting: boolean;
    onDelete: (item: CoachingSessionType) => (e: React.SyntheticEvent) => void;
    onClose: () => void;
    onSuccess: () => Promise<void>;
}

interface CoachingSessionTypeFormValues {
    value: string;
    icon: CoachingSessionTypeIconsEnum | "";
    isActive: boolean;
}

const validationSchema = Yup.object().shape({
    value: Yup.string().required("Required"),
    icon: Yup.mixed<CoachingSessionTypeIconsEnum>().oneOf(Object.values(CoachingSessionTypeIconsEnum), "Required").required("Required"),
});

export default function CoachingSessionTypeFormModal({
    item,
    open,
    nextOrder,
    fcrConfigurationId,
    isDeleting,
    onSuccess,
    onClose,
    onDelete,
}: CoachingSessionTypeFormModalProps) {
    const theme = useTheme();
    const classes = useStyles();

    const buildSaveData = ({ value, isActive, icon: selectedIcon }: CoachingSessionTypeFormValues): CoachingSessionType => {
        const icon = selectedIcon as CoachingSessionTypeIconsEnum;

        return {
            id: item?.id ?? uuidv4(),
            order: item?.order ?? nextOrder,
            isDefault: item?.isDefault ?? false,
            fcrConfigurationId,
            isActive,
            value,
            icon,
        };
    };

    const formik = useFormik<CoachingSessionTypeFormValues>({
        enableReinitialize: true,
        initialValues: {
            value: item?.value ?? "",
            icon: item?.icon ?? "",
            isActive: item?.isActive ?? true,
        },
        validationSchema,
        onSubmit(values: CoachingSessionTypeFormValues, { setSubmitting }) {
            saveCoachingSessionType(fcrConfigurationId, buildSaveData(values))
                .then(onSuccess)
                .then(onClose)
                .catch(() => setSubmitting(false));
        },
    });

    const disabled = formik.isSubmitting || isDeleting;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <FormikProvider value={formik}>
                <Form>
                    <Box px={4} py={3}>
                        <Typography variant="h1">
                            {item ? "Edit Session Type" : "Create New Session Type"}
                        </Typography>
                    </Box>
                    <Box bgcolor={theme.colors.structure.pageBackground} px={4} py={3}>
                        {item && (
                            <Typography variant="body2">
                                Editing this session type will not affect any existing Coaching Reports.
                            </Typography>
                        )}

                        <Box mt={item ? 3 : 0}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={2} className={classes.field}>
                                    <FormInput name="icon" label="Icon" variant="outlined" select>
                                        {Object.values(CoachingSessionTypeIconsEnum).map((value) => (
                                            <MenuItem key={value} value={value}>
                                                <Box height={22} display="flex" alignItems="center">
                                                    {getSessionTypeIcon(value)}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </FormInput>
                                </Grid>
                                <Grid item xs={12} sm={10} className={classes.field}>
                                    <FormInput name="value" label="Session Type Name" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box px={4} py={3} display="flex" alignItems="center">
                        <Box ml="11px">
                            <FormControlLabel
                                control={<FormSwitcher name="isActive" />}
                                label={<Typography>{formik.values.isActive ? "Active" : "Inactive"}</Typography>}
                            />
                        </Box>
                        <Grid alignItems="center" justifyContent="flex-end" container spacing={2}>
                            <Grid item>
                                <LoadingButton
                                    color="primary"
                                    disabled={disabled}
                                    disableElevation
                                    loading={formik.isSubmitting}
                                    loadingLabel={
                                        <Box component="span" lineHeight="30px">
                                            {item ? "Saving..." : "Creating..."}
                                        </Box>
                                    }
                                    size="large"
                                    startIcon={item ? <TickIcon /> : null}
                                    variant="contained"
                                    type="submit"
                                >
                                    <Box component="span" lineHeight="30px">
                                        {item ? "Save" : "Create"}
                                    </Box>
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={disabled}
                                    size="large"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    <Box component="span" lineHeight="30px">Cancel</Box>
                                </Button>
                            </Grid>
                            {item && (
                                <Grid item>
                                    <LoadingButton
                                        color="default"
                                        disabled={disabled}
                                        disableElevation
                                        loading={isDeleting}
                                        loadingLabel={<Box component="span" lineHeight="30px">Deleting...</Box>}
                                        size="large"
                                        startIcon={null}
                                        variant="outlined"
                                        type="submit"
                                        onClick={onDelete(item)}
                                    >
                                        <Box alignItems="center" display="flex" component="span" height="30px">
                                            <DeleteIcon height={20} width={20} />
                                        </Box>
                                    </LoadingButton>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}