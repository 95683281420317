/* eslint-disable functional/no-let */
import axios, { AxiosTransformer } from "axios";
import { setupCache } from "axios-cache-adapter";
import localforage from "localforage";
import {
    AuthProviderEnum,
    LOCAL_STORAGE_EMAIL,
    Routings,
} from "types/constants";

import authService from "./authService";
const forageStore = localforage.createInstance({ name: "omnicoach" });

const cache = setupCache({
    store: forageStore,
    clearOnStale: true,
    maxAge: 0,
});

const validateAxiosStatus = (status: number) => {
    // If user is forbidden or there's a server 500 error, redirect to access denied/user not found
    // TODO set up custom responses for 403, 403 with error message, and 500, access denied, user not found, and server maintenance page
    if (status === 403 || status === 500) {
        authService.logout(Routings.userNotFound)
        // return false
    }
    //Ideally the downstream provider unique id and email is mapped into the access token.
    // Mapping: 
    //TODO Handle custom pages for these edge cases on user management
    // User not in database
    // User doesn't have a IdentityProviderId
    // User updated information from a downstream system 
        // email?
        // oid likely shouldn't change - could use the "SYNC" button that exists already in the app - Could be a chron job that occurs every day or so...
    // Import User from downstream system
        // create a user - doesn't exist anywhere - now they exist in 
        // upload an excel file
    // User removed from downstream system
        // Ideally we test this to see how B2C reacts, perhaps it keeps generating access token until it needs a new id token from the open id provider, and then it fails
    // Email registration updates
    // Condition: Where user is removed from source open id provider

    // For 401, reload the page to get a new token
    // In the future, we can improve this by catching any request with a 401, 
    // freezing it using observables or some other comporable axios toolset, 
    // get a new token, and then unfreeze them to create a seemless user experience, 
    // until budget is available for that research and implementation, this will do, to keep the user from having to log in again.
    if (status === 401) {
        window.location.reload()
        // return false
    }
    return true
}

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    adapter: cache.adapter,
    validateStatus: validateAxiosStatus

});

// Add configuration or interceptors as needed
// See https://github.com/axios/axios for documentation

API.interceptors.request.use(async (config) => {
    // TODO redirect to 403 page if forbidden
    //TODO get token if 401, else if 401 and fail redirect to login
    if (authService.isAuthenticated()) {
        const { Authorization } = authService.getAuthHeaders() ?? {}

        if (Authorization) {
            // eslint-disable-next-line functional/immutable-data -- TODO Create new headers instead of mutating immutable data
            config.headers = {
                ...config.headers,
                AuthorizationProvider: AuthProviderEnum.B2C,
                Authorization,
            };
        }

    }

    return config;
});

export default API;