import React, { useState, useCallback, useMemo, useContext, useEffect, useRef } from "react";
import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { CoachingHubContext, ConfigurationContext } from "context";
import { FcrConfiguration, UserProfile } from "types";
import { CoachingSessionStatusEnum } from "types/constants";
import useScrollToItem from "hooks/useScrollToItem";
import PaperSection from "components/PaperSection";
import NoDataMessage from "components/NoDataMessage";

import ScheduledSessionItem from "./ScheduledSessionItem";
import CoachingSessionFilters from "../CoachingSessionFilters/CoachingSessionFiltersWithSubmitButton";

interface ScheduledSessionsProps {
    fcrConfiguration?: FcrConfiguration;
    coachesBelow: UserProfile[];
    membersBelow: UserProfile[];
}

const STEP_SIZE = 50;

const STATUS_FILTER_OPTIONS = [
    { value: CoachingSessionStatusEnum.upcoming, label: "Upcoming" },
    { value: CoachingSessionStatusEnum.completed, label: "Completed" },
    { value: CoachingSessionStatusEnum.incomplete, label: "Incomplete" },
];

const defaultFilters = {
    userId: null,
    status: null,
    orderDescending: true,
    returnStarted: null,
    getOnlyUpcoming: false,
    getOnlyIncomplete: false,
    state: null,
    excludeAssigneesMarkedFinishLater: false,
}

export default function ScheduledSessions({ fcrConfiguration, coachesBelow, membersBelow }: ScheduledSessionsProps) {
    const { currentUser, currentTeam, isConfigurationFetching, companyLevels } = useContext(ConfigurationContext);
    const { coachingSessions, coachingHubFilters, isFetchingByFilters, fetchCoachingSessions, totalFilteredAmount, handleCoachingSessionSaved, handleCoachingSessionDeleted } = useContext(CoachingHubContext);
    const [shownCount, setShownCount] = useState(STEP_SIZE);
    const [isShowMore, setIsShowMore] = useState(true);
    const [page, setPage] = useState<number>(1);
    const itemsRef = useRef<HTMLDivElement>(null);
    const isLoading = isFetchingByFilters || !coachingSessions || !currentUser;

    useScrollToItem(coachingSessions || [], "sessionId", itemsRef, shownCount, setShownCount);

    const shownSessions = useMemo(() => coachingSessions ? coachingSessions.slice(0, shownCount) : [], [coachingSessions, shownCount]);

    const onShowMore = useCallback(() => {
        setPage(page + 1)
        setShownCount(shownCount + STEP_SIZE)
        fetchCoachingSessions(page + 1, coachingHubFilters || defaultFilters)
    }, [coachingHubFilters, fetchCoachingSessions, page, shownCount]);

    useEffect(() => {
        if (!coachingHubFilters) {
            fetchCoachingSessions(page, coachingHubFilters || defaultFilters)
        }
    }, [coachingHubFilters, fetchCoachingSessions, page]);

    useEffect(() => {
        if (shownSessions.length !== totalFilteredAmount) {
            setIsShowMore(true)
        } else {
            setIsShowMore(false)
        }
    }, [coachingSessions, fetchCoachingSessions, page, shownSessions.length, totalFilteredAmount]);

    useEffect(() => {
        if (isFetchingByFilters) setShownCount(STEP_SIZE)
    }, [isFetchingByFilters]);

    return (
        <PaperSection
            px={6}
            width={1}
            title={(
                <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" width={1}>
                    <Box fontWeight={600} whiteSpace="nowrap">
                        <Typography variant="h2">All Scheduled Sessions</Typography>
                    </Box>
                    {currentUser && !isConfigurationFetching && (
                        <CoachingSessionFilters<CoachingSessionStatusEnum>
                            currentUser={currentUser}
                            currentTeam={currentTeam}
                            companyLevels={companyLevels}
                            membersBelow={membersBelow}
                            coachesBelow={coachesBelow}
                            loading={isLoading}
                            statusOptions={STATUS_FILTER_OPTIONS}
                            setPage={setPage}
                        />
                    )}
                </Box>
            )}
        >
            {!isLoading && (<InfiniteScroll
                scrollableTarget="app"
                dataLength={shownSessions.length}
                next={onShowMore}
                hasMore={isShowMore && totalFilteredAmount > shownCount}
                loader={isShowMore ? <Box p={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box> : null}
                hasChildren
            >
                <Box px={5} py={2}>
                    <Typography variant="caption">{`${shownSessions.length} ${isShowMore ? `from ${totalFilteredAmount} ` : ""}items`}</Typography>
                </Box>
                <Divider />
                <div {...{ ref: itemsRef }}>
                    {shownSessions.map(session => (
                        <ScheduledSessionItem
                            key={session.id}
                            session={session}
                            fcrConfiguration={fcrConfiguration}
                            onUpdated={handleCoachingSessionSaved}
                            onDeleted={handleCoachingSessionDeleted}
                        />
                    ))}
                </div>
            </InfiniteScroll>
            )}       
            {!coachingSessions?.length && !isLoading && (
                <NoDataMessage pb={5} pt={7} message="There's nothing to show here yet" />
            )}
            {isLoading && (
                <Box p={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            )}
        </PaperSection>
    );
}
