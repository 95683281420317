import React, { useState, useEffect } from "react";
import { orderBy } from "lodash";
import { useTheme, Box } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { FcrCoachingSkill } from "types";
import { SkillsIcon } from "svgComponents/MenuIcons";
import { reorder } from "helpers/arrayHelpers";
import AdminListItem from "components/AdminListItem";

export interface DragAndDropListProps {
    skills: FcrCoachingSkill[];
    isLoading?: boolean;
    onEditSkill: (skill: FcrCoachingSkill) => void;
    onDeleteSkill?: (skill: FcrCoachingSkill) => void;
    onSkillsReordered: (skills: FcrCoachingSkill[]) => void;
}

function SkillsDragAndDropList({ skills, isLoading, onEditSkill, onDeleteSkill, onSkillsReordered }: DragAndDropListProps) {
    const theme = useTheme();

    const [activeSkills, setActiveSkills] = useState(orderBy(skills.filter(({ isActive }) => isActive), ["order"]));
    const [inactiveSkills, setInactiveSkills] = useState(skills.filter(({ isActive }) => !isActive));

    useEffect(() => {
        setActiveSkills(orderBy(skills.filter(({ isActive }) => isActive), ["order"]));
        setInactiveSkills(skills.filter(({ isActive }) => !isActive));
    }, [skills]);

    const handleDragEnd = (result: DropResult) => {
        const { destination, source } = result;

        if (!destination || destination.index === source.index) {
            return;
        }

        const reorderedSkills = reorder(activeSkills, source.index, destination.index);

        setActiveSkills(reorderedSkills);
        onSkillsReordered(reorderedSkills);
    };

    const handleDeleteSkill = onDeleteSkill ? (e: React.SyntheticEvent, skill: FcrCoachingSkill) => {
        e.stopPropagation();
        onDeleteSkill(skill);
    } : undefined;

    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="skills">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {activeSkills.map((skill, idx) => (
                                <Draggable
                                    key={skill.id}
                                    draggableId={skill.id}
                                    index={idx}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <Box
                                                borderTop={
                                                    idx > 0
                                                        ? `1px solid ${theme.colors.structure.rulesAndBorders}`
                                                        : null
                                                }
                                                bgcolor={
                                                    snapshot.isDragging
                                                        ? theme.colors.structure.pageBackground
                                                        : null
                                                }
                                            >
                                                <AdminListItem
                                                    description={skill.description}
                                                    icon={<SkillsIcon />}
                                                    loading={isLoading}
                                                    title={skill.title}
                                                    onEdit={() => onEditSkill(skill)}
                                                    onClick={() => onEditSkill(skill)}
                                                    isInactive={!skill.isActive}
                                                    dragHandleProps={provided.dragHandleProps}
                                                    isDragging={snapshot.isDragging}
                                                />
                                            </Box>
                                        </div>
                                    )}

                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {inactiveSkills.map((skill, idx) => (
                <Box
                    key={skill.id}
                    borderTop={
                        activeSkills.length > 0 || idx > 0
                            ? `1px solid ${theme.colors.structure.rulesAndBorders}`
                            : null
                    }
                >
                    <AdminListItem
                        description={skill.description}
                        icon={<SkillsIcon />}
                        loading={isLoading}
                        title={skill.title}
                        onDelete={handleDeleteSkill && ((e) => handleDeleteSkill(e, skill))}
                        onEdit={() => onEditSkill(skill)}
                        onClick={() => onEditSkill(skill)}
                        isInactive
                    />
                </Box>
            ))}
        </>
    );
}

export default SkillsDragAndDropList;
