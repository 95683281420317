import React from "react";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import FcrResourceCategory from "types/FcrResourceCategory";
import { Skeleton } from "@material-ui/lab";
import { Folder, FolderOpened } from "svgComponents";

import useStyles from "./styles";

interface CategoryTreeProps {
    categories: FcrResourceCategory[];
    isLoading: boolean;
    selectedCategory?: string;
    setCategory: (id: string) => void;
}

function CategoryTree({ categories, isLoading, selectedCategory, setCategory }: CategoryTreeProps) {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            px={{ xs: 2, sm: 6 }}
            py={{ xs: 2, sm: 6 }}
            maxWidth={{ md: "234px" }}
            minWidth={{ md: "234px" }}
            bgcolor="common.white"
        >
            <Box pb={4}>
                <Typography variant="h1">All Resources</Typography>
            </Box>
            {isLoading ?
                [1, 2, 3].map((index) => (
                    <Box key={index} py={2} px={2.5}>
                        <Skeleton />
                    </Box>
                )) :
                categories.map(({ id, value }) => (
                    <Box pb={2.5} key={id}>
                        <ButtonBase
                            disableRipple
                            className={classes.categoryButton}
                            onClick={() => setCategory(id)}
                        >
                            <Box display="flex" alignItems="center" fontWeight={id === selectedCategory ? 500 : 400}>
                                <Box display="flex" alignItems="center" pr={2.5}>
                                    {id === selectedCategory ?
                                        <FolderOpened stroke="#1FACC1" /> :
                                        <Folder stroke="#7489A2" />}
                                </Box>
                                <Typography>
                                    {value}
                                </Typography>
                            </Box>
                        </ButtonBase>
                    </Box>
                ))
            }
        </Box>
    )
}

export default CategoryTree;
