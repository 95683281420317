import * as React from "react";

function SvgCloseCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.473 14.53L14.532.47M.473.47l14.059 14.06"
        stroke="currentColor"
        strokeOpacity={0.51}
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCloseCross;
