import React, { useState, useRef } from "react";
import { Box, Divider, Button, MenuItem } from "@material-ui/core";
import { CompanyLevel, UserProfile } from "types";
import { UsersAutocomplete } from "components/UsersAutocomplete";
import LevelFilter, { LevelFilterRef } from "components/LevelFilter";
import { Select } from "components/Form/FormSelect";
import useOnCompanyChange from "hooks/useOnCompanyChange";

interface UsersFilterProps {
    usersAutocompleteLabel?: string;
    companyLevels: CompanyLevel[];
    userProfiles: UserProfile[];
    onFilter: (filteredUserProfiles: UserProfile[] | null) => void;
}

enum StatusFilterEmum {
    all,
    active,
    inactive,
}

export default function UsersFilter({
    usersAutocompleteLabel,
    companyLevels,
    userProfiles,
    onFilter,
}: UsersFilterProps) {
    const levelFilterRef = useRef<LevelFilterRef>(null);
    const [userProfileId, setUserProfileId] = useState<UserProfile["id"] | null>(null);
    const [levelIds, setLevelIds] = useState<CompanyLevel["id"][]>([]);
    const [status, setStatus] = useState<StatusFilterEmum>(StatusFilterEmum.all);

    const filter = (
        userProfileId: UserProfile["id"] | null,
        levelIds: CompanyLevel["id"][],
        status: StatusFilterEmum
    ) => {
        const users = status === StatusFilterEmum.all
            ? userProfiles
            : userProfiles.filter(({ isActive }) => status === StatusFilterEmum.active ? isActive : !isActive)

        if (userProfileId) {
            return onFilter(users.filter((userProfile) => (
                userProfile.id === userProfileId
            )));
        }

        if (levelIds.length > 0) {
            return onFilter(users.filter((userProfile) => (
                levelIds.includes(userProfile.companyLevelId!)
            )));
        }

        return onFilter(users);
    };

    const handleUserFilter = (userProfileId: UserProfile["id"] | null) => {
        setStatus(StatusFilterEmum.all);
        levelFilterRef.current?.reset();
        setLevelIds([]);

        setUserProfileId(userProfileId);
        filter(userProfileId, [], StatusFilterEmum.all);
    };

    const handleLevelFilter = (levelIds: Array<CompanyLevel["id"]>) => {
        setUserProfileId(null);

        setLevelIds(levelIds);
        filter(null, levelIds, status);
    };

    const handleStatusFilter = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setUserProfileId(null);

        const value = e.target.value as StatusFilterEmum;
        setStatus(value);
        filter(null, levelIds, value);
    };

    const handleResetFilter = () => {
        setStatus(StatusFilterEmum.all);
        setUserProfileId(null);
        levelFilterRef.current?.reset();
        setLevelIds([]);
        filter(null, [], StatusFilterEmum.all);
    };

    useOnCompanyChange(handleResetFilter);

    if (!userProfiles.length) return null;

    return (
        <Box py={3} px={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex">
                    <Box minWidth={300} mr={2}>
                        <UsersAutocomplete
                            label={usersAutocompleteLabel}
                            userProfiles={userProfiles}
                            value={userProfileId}
                            onChange={handleUserFilter}
                        />
                    </Box>
                    <Select variant="outlined" value={status} onChange={handleStatusFilter}>
                        <MenuItem value={StatusFilterEmum.all}>All Members</MenuItem>
                        <MenuItem value={StatusFilterEmum.inactive}>Deactivated Members</MenuItem>
                        <MenuItem value={StatusFilterEmum.active}>Only Active Members</MenuItem>
                    </Select>
                </Box>
                <Button color="primary" size="large" onClick={handleResetFilter}>
                    Clear Filter
                </Button>
            </Box>
            {companyLevels.length ? (
                <>
                    <Box py={3}><Divider /></Box>
                    <LevelFilter<true>
                        multiple
                        title=""
                        caption=""
                        direction="row"
                        companyLevels={companyLevels}
                        onFilter={handleLevelFilter}
                        ref={levelFilterRef}
                    />
                </>
            ) : null}
        </Box>
    );
}
