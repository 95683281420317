import React from "react";
import { Box, List, ListItem, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ImportError, ImportResult } from "types";

import useStyles from "./styles";

interface ImportResultAlertProps {
    result: ImportResult;
    successTitle?: string | React.ReactNode;
    errorTitle?: string | React.ReactNode;
    warningTitle?: string | React.ReactNode;
    logsShownCount?: number;
    onClose?: () => void
}

export default function ImportResultAlert({
    result,
    successTitle = "Import was successful.",
    errorTitle = "Import was unsuccessful. Please fix the following errors and import the file again.",
    warningTitle = "Import was successful with warnings.",
    logsShownCount,
    onClose,
}: ImportResultAlertProps) {
    const classes = useStyles();

    const getErrorMessage = (error: ImportError, idx: number) => {
        const mainMessage = `${idx + 1}. ${error.message}`;
        const posMessage = error.rowIndex || error.columnIndex
            ? ` (row: ${error.rowIndex}, column: ${error.columnIndex})`
            : "";

        return mainMessage + posMessage;
    };

    const getSeverity = () => {
        if (result.isSuccess) {
            return result.warnings && result.warnings.length > 0 ? "warning" : "success";
        }
        return "error";
    };

    const getTitle = () => {
        if (result.isSuccess) {
            return result.warnings && result.warnings.length > 0 ? warningTitle : successTitle;
        }
        return errorTitle;
    };

    const displayedLogs = typeof logsShownCount === "number"
        ? result.logs.slice(0, logsShownCount)
        : result.logs;

    return (
        <Alert
            severity={getSeverity()}
            classes={{ action: classes.alertAction }}
            onClose={onClose}
        >
            <AlertTitle>{getTitle()}</AlertTitle>
            <List>
                {result.isSuccess && displayedLogs.length > 0 && displayedLogs.map((log, idx) => (
                    <Typography key={idx}>{log}</Typography>
                ))}
                {result.isSuccess && result.warnings && result.warnings.length > 0 && (
                    <Box mt={2}>
                        <Typography>Warnings:</Typography>
                        {result.warnings.map((warning, idx) => (
                            <ListItem key={idx}>{getErrorMessage(warning, idx)}</ListItem>
                        ))}
                    </Box>
                )}
                {!result.isSuccess && result.errors && result.errors.map((error, idx) => (
                    <ListItem key={idx}>{getErrorMessage(error, idx)}</ListItem>
                ))}
            </List>
        </Alert>
    );
}