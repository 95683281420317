import React, { useCallback, useEffect, useMemo, useState, useContext, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import { ChevronLeft, TickIcon } from "svgComponents";
import { CompaniesContext, SnackbarContext } from "context";

import NavigationLink from "components/NavigationLink";
import LoadingButton from "components/LoadingButton";
import ClassUsersAssignmentManager from "components/ClassUsersAssignmentManager";

import useOnCompanyChange from "hooks/useOnCompanyChange";
import {
    EvaluationConfiguration,
    AssignUsersRef,
    CertificationClass,
    CertificationClassEvaluationAssignment,
    CertificationClassUser,
    IClassBaseForm,
} from "types/Certification";
import { AdminRouteCertificationClassParams } from "types/interfaces";
import { AdminRoutings, AlertSeverity, REGEX } from "types/constants";
import {
    getClassById,
    getClassRosterByClassId,
    saveClass,
    updateClassRoster,
    getEvaluationConfigurationForCompany,
    updateEvaluationAssignments,
    getEvaluationAssignmentsByClassId,
    copyClass,
    sendCertificationClassEmail,
} from "services/certificationService";
import {
    buildClassEvaluationAssignments,
    buildClassEvaluators,
    buildClassTrainees,
    getClassBaseFormData,
} from "helpers/certificationHelper";

import ClassBaseForm from "./ClassBaseForm";
import useStyles from "./styles";

const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    releaseDate: Yup.string().required("Required"),
    classDate: Yup.string().required("Required"),
    evaluationForm: Yup.string().required("Required"),
    reportingUrl: Yup.string().matches(REGEX.URL, "Incorrect URL format. Example: http://example.com or https://www.example.com"),
});

const userAssignments = { availableUsers: [], assignedUsers: [] };

export default function ClassPage() {
    const classes = useStyles();
    const history = useHistory<CertificationClass | undefined>();
    const { classId } = useParams<AdminRouteCertificationClassParams>();
    const { currentCompany } = useContext(CompaniesContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const assignEvaluatorsRef = useRef<AssignUsersRef>(null);
    const assignTraineesRef = useRef<AssignUsersRef>(null);

    const [certificationClass, setCertificationClass] = useState<CertificationClass>();
    const [evaluationForms, setEvaluationForms] = useState<EvaluationConfiguration[]>([]);
    const [evaluationAssignments, setEvaluationAssignments] = useState<CertificationClassEvaluationAssignment[]>([]);
    const [isClassFetched, setIsDataFetched] = useState<boolean>(false);
    const [isClassCopying, setIsClassCopying] = useState<boolean>(false);
    const [isEmailSending, setIsEmailSending] = useState<boolean>(false);
    const [assignedClassUsers, setAssignedClassUsers] = useState<CertificationClassUser[]>([]);

    useOnCompanyChange(() => history.push(AdminRoutings.certificationClasses));

    const fetchClassData = useCallback(
        async (companyId: string) =>
            getEvaluationConfigurationForCompany(companyId)
                .then(setEvaluationForms)
                .finally(() => {
                    setIsDataFetched(true);
                }),
        []
    );

    const fetchCurentClassData = useCallback(
        async (classId: string, companyId: string) =>
            Promise.all([
                getEvaluationConfigurationForCompany(companyId),
                getEvaluationAssignmentsByClassId(classId),
                getClassById(classId),
                getClassRosterByClassId(classId),
            ])
                .then(([evaluationConfigurations, evaluationAssignments, certificationClass, assignedUsers]) => {
                    setEvaluationAssignments(evaluationAssignments);
                    setEvaluationForms(evaluationConfigurations);
                    setCertificationClass(certificationClass);
                    setAssignedClassUsers(assignedUsers);
                })
                .finally(() => {
                    setIsDataFetched(true);
                }),
        []
    );

    useEffect(() => {
        if (currentCompany) {
            if (classId) {
                fetchCurentClassData(classId, currentCompany.id);
            } else {
                fetchClassData(currentCompany.id);
            }
        }
    }, [classId, fetchCurentClassData, currentCompany, fetchClassData]);

    const handleSubmit = useCallback(
        async (formData: IClassBaseForm) => {
            const evaluationAssignmentIds: string[] = formData.evaluationForm ? [formData.evaluationForm] : [];
            const evaluationUnassignmentIds: string[] = evaluationForms
                .filter(({ id }) => !evaluationAssignmentIds.includes(id))
                .map(({ id }) => id);
            const currentClassId = formData.id;

            const { availableUsers: availableEvaluators, assignedUsers: assignedEvaluators } = assignEvaluatorsRef.current
                ? assignEvaluatorsRef.current.getAssignments()
                : userAssignments;

            const { availableUsers: availableTrainees, assignedUsers: assignedTrainees } = assignTraineesRef.current
                ? assignTraineesRef.current.getAssignments()
                : userAssignments;

            await saveClass(formData);
            await Promise.all([
                updateEvaluationAssignments(buildClassEvaluationAssignments(currentClassId, evaluationAssignmentIds, true)),
                updateEvaluationAssignments(buildClassEvaluationAssignments(currentClassId, evaluationUnassignmentIds, false)),
                updateClassRoster(buildClassEvaluators(currentClassId, assignedEvaluators, true)),
                updateClassRoster(buildClassEvaluators(currentClassId, availableEvaluators, false)),
                updateClassRoster(buildClassTrainees(currentClassId, assignedTrainees, true)),
                updateClassRoster(buildClassTrainees(currentClassId, availableTrainees, false)),
            ])
                .then(() => {
                    if (classId) {
                        openSnackbar("Class was successfully saved.", AlertSeverity.success);
                    } else {
                        history.push(`${AdminRoutings.certificationClasses}/${formData.id}`);
                        openSnackbar("Class was successfully created.", AlertSeverity.success);
                    }
                })
                .catch(() => openSnackbar("Unexpected error occurred.", AlertSeverity.error));
        },
        [classId, evaluationForms, history, openSnackbar]
    );

    const handleCreateCopy = useCallback(() => {
        if (classId) {
            setIsClassCopying(true);
            copyClass(classId)
                .then(() => {
                    openSnackbar("Class was successfully copied to listings.", AlertSeverity.success);
                })
                .catch(() => openSnackbar("Unexpected error occurred.", AlertSeverity.error))
                .finally(() => {
                    setIsClassCopying(false);
                });
        }
    }, [classId, openSnackbar]);

    const handleSendEmail = useCallback(() => {
        if (!classId) return;

        setIsEmailSending(true);
        sendCertificationClassEmail(classId)
            .then(() => {
                openSnackbar("The email was successfully sent.", AlertSeverity.success);
            })
            .catch((data) => {
                openSnackbar("Unexpected error occurred.", AlertSeverity.error);
            })
            .finally(() => {
                setIsEmailSending(false);
            })
    }, [classId, openSnackbar]);

    const initialValues = useMemo(
        () => getClassBaseFormData(certificationClass, currentCompany ? currentCompany.id : "", evaluationAssignments),
        [certificationClass, currentCompany, evaluationAssignments]
    );

    const formik = useFormik<IClassBaseForm>({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const newEvaluationType = useMemo(() => (classId ? certificationClass?.title : "New Class"), [certificationClass?.title, classId]);

    return (
        <>
            <FormikProvider value={formik}>
                <Grid alignItems="center" container spacing={2}>
                    <Grid item>
                        <NavigationLink href={AdminRoutings.certificationClasses} hoverUnderline={false}>
                            <Typography variant="h1">
                                <Grid alignItems="center" component="span" container>
                                    <Grid component="span" item>
                                        <ChevronLeft display="block" height={24} width={24} />
                                    </Grid>
                                    <Grid className={classes.titleGap} component="span" item>
                                        {newEvaluationType}
                                    </Grid>
                                </Grid>
                            </Typography>
                        </NavigationLink>
                    </Grid>
                    <Grid className={classes.alignRight} item>
                        <LoadingButton
                            variant="outlined"
                            color="primary"
                            size="large"
                            disabled={!classId}
                            disableElevation
                            component="label"
                            loadingLabel="Sending..."
                            loading={isEmailSending}
                            onClick={handleSendEmail}
                        >
                            <Box component="span" lineHeight="30px">
                                Send Email
                            </Box>
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <Box display="flex" height={1}>
                            <LoadingButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                disabled={!classId}
                                disableElevation
                                component="label"
                                loadingLabel="Copying..."
                                loading={isClassCopying}
                                onClick={handleCreateCopy}>
                                <Box component="span" lineHeight="30px">
                                    Create a copy
                                </Box>
                            </LoadingButton>
                        </Box>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            color="primary"
                            loading={formik.isSubmitting}
                            loadingLabel={!classId ? "Creating..." : "Saving..."}
                            size="large"
                            startIcon={!classId ? null : <TickIcon />}
                            variant="contained"
                            onClick={formik.submitForm}>
                            <Box component="span" lineHeight="30px">
                                {!classId ? "Create Class" : "Save updates"}
                            </Box>
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <Link className={classes.link} to={AdminRoutings.certificationClasses}>
                            <Button variant="outlined" size="large">
                                <Box component="span" lineHeight="30px">
                                    Cancel
                                </Box>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                {isClassFetched && (
                    <Box mt={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <Box mb={3}>
                                    <ClassBaseForm evaluationForms={evaluationForms} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mb={3}>
                            <ClassUsersAssignmentManager isEvaluator assignedClassUsers={assignedClassUsers} ref={assignEvaluatorsRef} />
                        </Box>
                        <Box>
                            <ClassUsersAssignmentManager assignedClassUsers={assignedClassUsers} ref={assignTraineesRef} />
                        </Box>
                    </Box>
                )}
                {!isClassFetched && (
                    <Box px={6} py={4} textAlign="center">
                        <CircularProgress size={40} />
                    </Box>
                )}
            </FormikProvider>
        </>
    );
}
