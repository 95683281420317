import React, { useContext, useMemo, useCallback, useState } from "react";
import { Box, Grid, Typography, Avatar, IconButton, CircularProgress } from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { sortTeamsByOrder } from "helpers/teamHelpers";
import AvatarWithScore from "components/AvatarWithScore";
import LevelHierarchy from "components/LevelHierarchy";
import { UserProfile, Team } from "types";
import { SizeEnum } from "types/constants";
import { ShowIcon, HideIcon } from "svgComponents";
import { CompaniesContext } from "context";
import { updateTeam, getAllCompanyTeams } from "services/teamServices";

import useStyles from "../styles";

interface TeamItemProps {
    baseUrl: string,
    description?: string,
    user: UserProfile | null,
    userId?: string,
    team?: Team;
    setAllTeams: (teams: Team[]) => void;
}

export default function TeamItem({
    description,
    baseUrl,
    user,
    userId,
    team,
    setAllTeams,
}: TeamItemProps) {
    const classes = useStyles();
    const { companyLevels, currentCompany } = useContext(CompaniesContext);
    const history = useHistory();
    const [isLoading, setLoading] = useState<boolean>(false);

    const fullName = useMemo(() => `${user?.firstName} ${user?.lastName}`.trim(), [user?.firstName, user?.lastName]);
    const editUrl = useMemo(() => `${baseUrl}/${userId || user?.id}`, [baseUrl, userId, user]);

    const handleOnClick = useCallback(() => {
        history.push(editUrl, [user])
    }, [editUrl, history, user]);

    const onTeamUpdated = useCallback((isTeamActive) => {
        setLoading(true)
        currentCompany?.id && team && updateTeam({ ...team, isActive: isTeamActive })
            .then(() => getAllCompanyTeams(currentCompany?.id))
            .then(teams => setAllTeams(sortTeamsByOrder(teams) || teams))
            .finally(() => setLoading(false))
    }, [currentCompany?.id, setAllTeams, team]);

    const onInactive = useCallback((e) => {
        e.stopPropagation();
        onTeamUpdated(false)
    }, [onTeamUpdated]);

    const onActive = useCallback((e) => {
        e.stopPropagation();
        onTeamUpdated(true)
    }, [onTeamUpdated]);

    return (
        <Box className={clsx(classes.body, { [classes.bodyInactive]: !team?.isActive })} onClick={handleOnClick}>
            <Box py={3} px={4} >
                <Grid alignItems="center" container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Grid alignItems="center" container spacing={2}>
                            <Grid item>
                                {user ?
                                    <AvatarWithScore size={SizeEnum.mediumSmaller} user={user} /> :
                                    <Avatar className={classes.missing}><PriorityHighIcon /></Avatar>
                                }
                            </Grid>
                            <Grid item>
                                <Typography variant="h2">
                                    {user ?
                                        <Box component="span" fontWeight={500}>{fullName}</Box> :
                                        <Box component="span" fontWeight={600} color="error.main">MISSING LEADER</Box>
                                    }
                                </Typography>
                                {description && (
                                    <Typography color="textSecondary" variant="body2">
                                        {description}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <LevelHierarchy
                        companyLevelId={team?.companyLevelId}
                        companyLevels={companyLevels}
                        customClasses={classes}
                    />
                    {!isLoading && !team?.userMembers?.length && team?.isActive &&
                        <Grid className={classes.actions} item>
                            <Grid container spacing={1} wrap="nowrap">
                                <Grid item>
                                    <IconButton aria-label="inactive" onClick={onInactive}>
                                        <HideIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {!isLoading && !team?.isActive &&
                        <Grid className={classes.inactiveActions} item>
                            <Grid item>
                                <IconButton aria-label="inactive" className={classes.hide} onClick={onInactive}>
                                    <HideIcon />
                                </IconButton>
                                <IconButton aria-label="delete" onClick={onActive}>
                                    <ShowIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                    {isLoading &&
                        <Grid className={classes.inactiveActions} item>
                            <Grid item>
                                <Box display="flex" alignItems="center" mr={1.5}><CircularProgress size={20} /></Box>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}
