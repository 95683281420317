import { Box, Button, Dialog, Typography, useMediaQuery, useTheme, Tooltip } from "@material-ui/core";
import { FormInput } from "components/Form";
import LoadingButton from "components/LoadingButton";
import { useFormik, FormikProvider, Form } from "formik";
import React, { useState } from "react";
import { DeleteIcon } from "svgComponents";
import { CompanyLevelLabel, TreeSet } from "types";

import useStyles from "./styles";

interface EditCompanyModalProps {
    isOpen: boolean;
    onSubmit: (name: string) => Promise<void>;
    onDelete: () => Promise<void>;
    onClose: () => void;
    companyLevelTreeSet: TreeSet;
    companyLevelLabel: CompanyLevelLabel;
}

const DISABLE_DELETION_TOOLTIP = "Please remove all users assigned to this level and child levels prior to deletion";

function EditCompanyLevelModal({ isOpen, onSubmit, onClose, onDelete, companyLevelTreeSet, companyLevelLabel }: EditCompanyModalProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();
    const [isDeleting, setDeleting] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: { name: companyLevelTreeSet.value.name },
        onSubmit(values, { setErrors, setSubmitting }) {
            onSubmit(values.name)
                .catch((error) => setErrors({ name: error.response.data }))
                .finally(() => setSubmitting(false));
        },
    });

    const handleDelete = () => {
        setDeleting(true);
        onDelete()
            .catch((error) => formik.setErrors({ name: error.response.data }))
            .finally(() => setDeleting(false));
    };

    const checkUsersInLevelAndChildren = (treeSet: TreeSet): boolean => (
        treeSet.value.users.length > 0
        || treeSet.nodes.length > 0 && treeSet.nodes.some(checkUsersInLevelAndChildren)
    );

    const hasUsers = checkUsersInLevelAndChildren(companyLevelTreeSet);

    return (
        <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
            <FormikProvider value={formik}>
                <Form>
                    <Box px={4} py={4}>
                        <Typography variant="h1">Edit {companyLevelLabel?.name}</Typography>
                    </Box>
                    <Box px={4} py={4} bgcolor={theme.colors.structure.pageBackground}>
                        <FormInput
                            name="name"
                            label={`${companyLevelLabel.name} label`}
                            classes={classes}
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between" px={4} py={3}>
                        <Tooltip
                            arrow
                            placement="top"
                            title={hasUsers ? DISABLE_DELETION_TOOLTIP : ""}
                        >
                            <Box>
                                <LoadingButton
                                    variant="outlined"
                                    color="default"
                                    size="large"
                                    onClick={handleDelete}
                                    disabled={hasUsers}
                                    disableElevation
                                    loading={isDeleting}
                                    loadingLabel="Deleting..."
                                >
                                    <DeleteIcon width="20" height="20" />
                                </LoadingButton>
                            </Box>
                        </Tooltip>
                        <Box display="flex">
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                disabled={!formik.values.name}
                                disableElevation
                                loading={formik.isSubmitting}
                                loadingLabel="Saving..."
                            >
                                Save
                            </LoadingButton>
                            <Box pl={2}>
                                <Button variant="outlined" size="large" onClick={onClose}>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}

export default EditCompanyLevelModal;