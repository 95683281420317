import React from "react";
import SummaryCard, { SummaryCardProps } from "components/SummaryCard";

import FocusIcon from "./SessionsIcon";

const renderDefaultIcon = () => <FocusIcon />;

const SummaryCardFocus = ({
    renderIcon = renderDefaultIcon,
    theme = "danger",
    ...props
}: SummaryCardProps) => <SummaryCard theme={theme} renderIcon={renderIcon} {...props} />;

export default SummaryCardFocus;