import React, { useContext, useMemo, useReducer, useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useLocation, Redirect } from "react-router-dom";
import { Box, Button, Typography, Divider, Grid } from "@material-ui/core";
import Header from "components/Header";
import NavigationLink from "components/NavigationLink";
import { HeaderProps, UserProfile } from "types";
import { CoachingHubContext, ConfigurationContext } from "context";
import { getCoachesBelow, getUsersBelow } from "services/userServices";
import CoachingReportSelector from "components/CoachingReportSelector";
import MessageModal from "components/MessageModal";
import useAsync from "hooks/useAsync";

import ScheduledSessions from "./ScheduledSessions";
import CoachingReports from "./CoachingReports";
import Messages from "./Messages";
import MessageDetail from "./MessageDetail";

// TODO: Remove comments after completion of /coaching-plans
// import plans from "./plans.svg";

import useStyles from "./styles";

const menu = [
    { name: "Schedule", href: "/scheduled-sessions" },
    { name: "Coaching Reports", href: "/coaching-reports" },
    // TODO: Remove comments after completion of /coaching-plans
    // { name: "Coaching Plans", href: "/coaching-plans" },
    { name: "Messages", href: "/messages" },
];

function CoachingHub({ isBarOpen, navBarOpen }: HeaderProps) {
    const { path } = useRouteMatch();
    const location = useLocation();
    const classes = useStyles();
    const { currentUser, fcrConfiguration } = useContext(ConfigurationContext);
    const { handleCoachingSessionSaved, handleMessagesSaved, clearCoachingSessions, clearMessagesThreads } = useContext(CoachingHubContext);
    const [selected, setSelected] = useState(0);
    const [showNewSessionDialog, toggleShowNewSessionDialog] = useReducer(state => !state, false);
    const [showNewReportDialog, toggleShowNewReportDialog] = useReducer(state => !state, false);
    const [showNewMessageDialog, toggleShowNewMessageDialog] = useReducer(state => !state, false);

    const { value: membersBelow } = useAsync<UserProfile[] | null>(async () => (
        currentUser?.id ? await getUsersBelow(currentUser.id) : null
    ), [currentUser?.id]);

    const { value: coachesBelow } = useAsync<UserProfile[] | null>(async () => (
        currentUser?.id ? await getCoachesBelow(currentUser.id) : null
    ), [currentUser?.id]);

    const actionProps = useMemo(() => {
        switch (selected) {
            case 0:
                return { text: "New Coaching Session", action: toggleShowNewSessionDialog };
            case 1:
                return { text: "New Coaching Report", action: toggleShowNewReportDialog };
            case 2:
                return { text: "New Message", action: toggleShowNewMessageDialog };
            default:
                null;
        }
    }, [selected]);

    useEffect(() => {
        const selectedTabIndex = Math.max(menu.findIndex(({ href }) => location.pathname.includes(href)));
        
        if (selectedTabIndex > 1) {
            clearMessagesThreads();
        } else {
            clearCoachingSessions();
        }
        
        setSelected(selectedTabIndex);
    }, [clearCoachingSessions, clearMessagesThreads, location.pathname]);

    return (
        <>
            <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
            <Box bgcolor="common.white" px={8} pt={4.5} pb={2}>
                <Grid container justifyContent="space-between" className={classes.title}>
                    <Typography variant="h1">Coaching Hub</Typography>
                    {actionProps && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={actionProps.action}
                        >
                            <Box mr={1} fontSize="18px" lineHeight="30px">
                                +
                            </Box>
                            {actionProps.text}
                        </Button>
                    )}
                </Grid>
                <Grid container spacing={3}>
                    {menu.map((item, index) => (
                        <Grid key={item.name} item onClick={() => setSelected(index)}>
                            <NavigationLink href={path + item.href} className={selected === index ? classes.selectedTab : ""}>
                                {item.name}
                            </NavigationLink>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="center" pt={6}>
                <Switch>
                    <Redirect exact from={`${path}`} to={`${path}/scheduled-sessions`} />
                    <Route path={`${path}/coaching-reports`}>
                        <CoachingReports
                            coachesBelow={coachesBelow ?? []}
                            membersBelow={membersBelow ?? []}
                        />
                    </Route>
                    {/* TODO: Remove comments after completion of /coaching-plans */}
                    {/* <Route path={`${path}/coaching-plans`}>
                        <Paper>
                            <img src={plans} alt="" />
                        </Paper>
                    </Route> */}
                    <Route exact path={`${path}/messages`}>
                        <Messages membersBelow={membersBelow ?? []} />
                    </Route>
                    <Route exact path={`${path}/messages/:threadId`}>
                        <MessageDetail />
                    </Route>
                    <Route path={[`${path}/scheduled-sessions`, path]}>
                        <ScheduledSessions
                            coachesBelow={coachesBelow ?? []}
                            membersBelow={membersBelow ?? []}
                            fcrConfiguration={fcrConfiguration}
                        />
                    </Route>
                </Switch>
            </Box>
            {(showNewSessionDialog || showNewReportDialog) && (
                <CoachingReportSelector
                    isOpen={showNewSessionDialog || showNewReportDialog}
                    usersBelow={membersBelow}
                    createFcr={showNewReportDialog}
                    fcrConfiguration={fcrConfiguration}
                    initialIsNewSession={showNewSessionDialog}
                    disableToggleNewSession={showNewSessionDialog}
                    scheduleCoachingSessionMessages={showNewSessionDialog ? {
                        newSessionHeader: "Schedule Coaching Session",
                        newSessionSubmitButton: "Schedule Coaching Session",
                        bodyTitle: <Box fontWeight={600}>Select Details</Box>,
                    } : undefined}
                    onClose={showNewSessionDialog ? toggleShowNewSessionDialog : toggleShowNewReportDialog}
                    onSuccess={handleCoachingSessionSaved}
                />
            )}
            {showNewMessageDialog && (
                <MessageModal
                    isOpen={showNewMessageDialog}
                    usersBelow={membersBelow}
                    onSuccess={handleMessagesSaved}
                    onClose={toggleShowNewMessageDialog}
                />
            )}
        </>
    )
}

export default CoachingHub;
