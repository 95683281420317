import React, { useCallback, useState } from "react";
import { Button } from "@material-ui/core";

interface ObservationsSkillButtonProps {
    text: string;
    isActiveByDefault?: boolean;
    disabled?: boolean;
    onClick: () => void;
    className?: string;
}

const ObservationsSkillButton = ({ text, disabled, isActiveByDefault = false, onClick, className }: ObservationsSkillButtonProps) => {
    const [isActive, setIsActive] = useState(isActiveByDefault);

    const handleClick = useCallback(() => {
        setIsActive(isActive => !isActive);
        onClick();
    }, [onClick]);

    return (
        <Button
            variant={isActive ? "contained" : "outlined"}
            color={isActive ? "primary" : "inherit"}
            size="large"
            className={className}
            onClick={handleClick}
            disabled={disabled}
            disableElevation
        >
            {text}
        </Button>
    )
};

export default ObservationsSkillButton;