import React from "react";
import { Checkbox as MaterialCheckbox, CheckboxProps } from "@material-ui/core";

function Checkbox({ ...props }: CheckboxProps) {
    return (
        <MaterialCheckbox
            color="primary"
            disableRipple={true}
            icon={
                <div style={{
                    width: "18px", height: "18px",
                    border: "1px solid #BBC4CD", boxSizing: "border-box", borderRadius: "4px",
                }} />
            }
            checkedIcon={
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" stroke="currentColor"/>
                    <g clipPath="url(#clip0)">
                        <path d="M12.7303 6.21436L8.03796 11.6621L5.24609 9.00792" stroke="currentColor" strokeWidth="1.25" strokeMiterlimit="10" strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="8" height="6" fill="white" transform="translate(5 6)"/>
                        </clipPath>
                    </defs>
                </svg>
            }
            {...props}
        />
    );
}

export default Checkbox;
