import { makeStyles } from "@material-ui/core"; 

const useStyles = makeStyles(() => ({
    heading: {
        fontSize: "19px",
        lineHeight: 1.5,
    },
}));

export default useStyles;
