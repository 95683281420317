import React, { useCallback, useMemo } from "react";
import { Box, BoxProps, Typography } from "@material-ui/core";
import StatusLabel from "components/StatusLabel";
import { CoachingPlanType } from "types";
import { CoachingPlanStatusEnum } from "types/constants";
import ResourceIcon from "components/ResourceIcon";
import clsx from "clsx";
import { checkDueDate, formatPastYearDate, formatShortDate } from "helpers/dateHelpers";

import RichText from "../RichText";
import useStyles from "./style";

interface CoachingPlanProps extends BoxProps {
    coachingPlan: CoachingPlanType;
    className?: string;
    darkStatusBackground?: boolean;
    onDetailOpen?: (plan: CoachingPlanType) => void;
}

const CoachingPlan = ({
    coachingPlan,
    className = "",
    darkStatusBackground = false,
    onDetailOpen,
    ...props
}: CoachingPlanProps) => {
    const {
        dueDate,
        coachingPlanData,
        resources,
        fcrCoachingPlanStatusId = CoachingPlanStatusEnum.assigned,
    } = coachingPlan;
    const classes = useStyles();

    const formattedDate = useMemo(
        () => new Date(dueDate).getFullYear() < new Date().getFullYear() ?
            formatPastYearDate(dueDate) : formatShortDate(dueDate),
        [dueDate]
    );

    const isDueDayExpired = useMemo(() => checkDueDate(dueDate), [dueDate]);
    const isResource = useMemo(() => resources?.length > 0, [ resources ]);
    const resourceType = useMemo(() => resources?.[0]?.type ,[ resources ]);
    const isCompleted = useMemo(() => fcrCoachingPlanStatusId === CoachingPlanStatusEnum.completed, [fcrCoachingPlanStatusId]);

    const handleClick = useCallback(() => onDetailOpen?.(coachingPlan), [ onDetailOpen, coachingPlan]);

    return (
        <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
            className={clsx(className, {
                [classes.hover]: !!onDetailOpen,
            })}
            onClick={handleClick}
            {...props}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                flexShrink={0}
            >
                <Typography variant="h2" component="p" className={clsx({ [classes.expired]: isDueDayExpired && !isCompleted })}>
                    {formattedDate}
                </Typography>
                <Typography variant="caption" className={clsx({ [classes.expired]: isDueDayExpired && !isCompleted })}>
                    DUE DATE
                </Typography>
            </Box>
            <Box ml={{ xs: 0, md: 5 }} mt={{ xs: 2, md: 0 }} flex="1 1 auto">
                <Typography variant="body2" component="div">
                    <RichText text={coachingPlanData}/>
                </Typography>
            </Box>
            {isResource && (
                <Box ml={{ xs: 0, md: 5 }} mt={{ xs: 2, md: 0 }} flex="0 0 14px">
                    <ResourceIcon type={resourceType} display="block" />
                </Box>
            )}
            <Box ml={{ xs: 0, md: 5 }} mt={{ xs: 2, md: 0 }}>
                <StatusLabel status={fcrCoachingPlanStatusId} darkBackground={darkStatusBackground} />
            </Box>
        </Box>
    );
}

export default React.memo(CoachingPlan);