import React, { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { Box, Button, Divider, Grid, Typography, CircularProgress, Card, FormHelperText } from "@material-ui/core";
import { ChevronLeft, PlusIcon, TickIcon } from "svgComponents";
import { CompaniesContext, SnackbarContext } from "context";

import NoDataMessage from "components/NoDataMessage";
import NavigationLink from "components/NavigationLink";
import LoadingButton from "components/LoadingButton";
import useOnCompanyChange from "hooks/useOnCompanyChange";
import {
    EvaluationConfiguration,
    EvaluationFormProps,
    EvaluationItemConfiguration,
    ItemOrder,
    QuestionBasedForm,
} from "types/Certification";
import { AdminRouteCertificationEvaluationParams } from "types/interfaces";
import { AdminRoutings, AlertSeverity } from "types/constants";
import {
    getEvaluationById,
    saveEvaluationConfiguration,
    saveEvaluationItemConfiguration,
    deleteEvaluationItemConfiguration,
    copyEvaluationConfiguration,
} from "services/certificationService";
import { getEvaluationFormData } from "helpers/certificationHelper";

import EvaluationForm from "./EvaluationForm";
import EvaluationItemModal from "./EvaluationItemModal";
import EvaluationItemDragAndDropList from "./EvaluationItemDragAndDropList";

import useStyles from "./styles";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
});

export default function EvaluationPage() {
    const classes = useStyles();
    const history = useHistory<EvaluationConfiguration | undefined>();
    const { evaluationId } = useParams<AdminRouteCertificationEvaluationParams>();
    const { currentCompany } = useContext(CompaniesContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const [evaluation, _setEvaluation] = useState<EvaluationConfiguration | undefined>();
    const [evaluationItems, setEvaluationItems] = useState<EvaluationItemConfiguration[]>([]);
    const [inEditItem, setInEditItem] = useState<EvaluationItemConfiguration | null>(null);
    const [isEvaluationFetched, setIsEvaluationFetched] = useState<boolean>(false);
    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [isEvaluatioCopying, setIsEvaluationCopying] = useState<boolean>(false);

    useOnCompanyChange(() => history.push(AdminRoutings.certificationEvaluations));

    const setEvaluation = useCallback((evaluation: EvaluationConfiguration | undefined) => {
        evaluation?.evaluationItemConfigurations.sort((a, b) => (a.order || 0) - (b.order || 0));
        setEvaluationItems(evaluation?.evaluationItemConfigurations || []);
        _setEvaluation(evaluation);
    }, []);

    const fetchEvaluationData = useCallback(
        (evaluationId: string) => {
            getEvaluationById(evaluationId)
                .then((evaluation: EvaluationConfiguration) => {
                    setEvaluation(evaluation);
                    setIsEvaluationFetched(true);
                })
                .catch(() => history.push(AdminRoutings.addCertificationEvaluation));
        },
        [history, setEvaluation]
    );

    useEffect(() => {
        if (evaluationId) {
            return fetchEvaluationData(evaluationId);
        }
        setIsEvaluationFetched(true);
    }, [evaluationId, fetchEvaluationData]);

    const handleCreate = useCallback(() => {
        setModalOpened(true);
        setInEditItem(null);
    }, []);

    const handleClose = useCallback(() => {
        setModalOpened(false);
        setInEditItem(null);
    }, []);

    const initialValues = useMemo(() => getEvaluationFormData(evaluation, currentCompany?.id), [evaluation, currentCompany]);

    const handleSubmit = useCallback(
        async (formData: EvaluationFormProps) => {
            const itemsOrder: ItemOrder[] = evaluationItems.map(({ id }, i) => ({ id, order: i + 1 }));
            const evaluationData = {
                ...formData,
                isQuestionBasedForm: formData.questionBasedForm === QuestionBasedForm.Flagged,
                itemsOrder,
            };

            await saveEvaluationConfiguration(evaluationData);

            if (!evaluationId) {
                history.push(`${AdminRoutings.certificationEvaluations}/${evaluationData.id}`);
            }
        },
        [evaluationId, evaluationItems, history]
    );

    const handleEvaluationItemCreate = useCallback(
        async (payload: EvaluationItemConfiguration) => {
            if (evaluation) {
                return saveEvaluationItemConfiguration(payload).finally(() => {
                    setIsEvaluationFetched(false);
                    fetchEvaluationData(evaluation?.id);
                });
            }
        },
        [evaluation, fetchEvaluationData]
    );

    const handleEvaluationItemDelete = useCallback(
        async (evaluationItemId: EvaluationItemConfiguration["id"]) => {
            if (evaluation) {
                setIsEvaluationFetched(false);
                return deleteEvaluationItemConfiguration(evaluationItemId).finally(() => {
                    setIsEvaluationFetched(false);
                    fetchEvaluationData(evaluation?.id);
                });
            }
        },
        [evaluation, fetchEvaluationData]
    );

    const handleEvaluationItemEdit = useCallback((evaluationItem: EvaluationItemConfiguration) => {
        setModalOpened(true);
        setInEditItem(evaluationItem);
    }, []);

    const handleEvaluationItemReordered = useCallback((evaluationItems: EvaluationItemConfiguration[]) => {
        setEvaluationItems(evaluationItems);
    }, []);

    const handleCreateCopy = useCallback(() => {
        if (evaluationId) {
            setIsEvaluationCopying(true);
            copyEvaluationConfiguration(evaluationId)
                .then(() => {
                    openSnackbar("Evaluation Form was successfully copied to listings.", AlertSeverity.success);
                })
                .catch(() => openSnackbar("Unexpected error occurred.", AlertSeverity.error))
                .finally(() => {
                    setIsEvaluationCopying(false);
                });
        }
    }, [evaluationId, openSnackbar]);

    const formik = useFormik<EvaluationFormProps>({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const newEvaluationType = useMemo<string | undefined>(
        () => (evaluationId ? evaluation?.name : "New Evaluation"),
        [evaluationId, evaluation]
    );
    const showQuestionBasedFormNotice = useMemo<boolean | undefined>(
        () => !!(evaluationId && !evaluation?.isInUse && evaluationItems.length),
        [evaluationId, evaluation?.isInUse, evaluationItems.length]
    );

    return (
        <>
            <FormikProvider value={formik}>
                <Grid alignItems="center" container spacing={2}>
                    <Grid item>
                        <NavigationLink href={AdminRoutings.certificationEvaluations} hoverUnderline={false}>
                            <Typography variant="h1">
                                <Grid alignItems="center" component="span" container>
                                    <Grid component="span" item>
                                        <ChevronLeft display="block" height={24} width={24} />
                                    </Grid>
                                    <Grid className={classes.titleGap} component="span" item>
                                        {newEvaluationType}
                                    </Grid>
                                </Grid>
                            </Typography>
                        </NavigationLink>
                    </Grid>
                    <Grid className={classes.alignRight} item>
                        <Box display="flex" height={1}>
                            <LoadingButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                disabled={!evaluationId}
                                disableElevation
                                component="label"
                                loadingLabel="Copying..."
                                loading={isEvaluatioCopying}
                                onClick={handleCreateCopy}>
                                <Box component="span" lineHeight="30px">
                                    Create a copy
                                </Box>
                            </LoadingButton>
                        </Box>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            color="primary"
                            loading={formik.isSubmitting}
                            loadingLabel={!evaluationId ? "Creating..." : "Saving..."}
                            size="large"
                            startIcon={!evaluationId ? null : <TickIcon />}
                            variant="contained"
                            onClick={formik.submitForm}>
                            <Box component="span" lineHeight="30px">
                                {!evaluationId ? "Create Evaluation" : "Save updates"}
                            </Box>
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <Link className={classes.link} to={AdminRoutings.certificationEvaluations}>
                            <Button variant="outlined" size="large">
                                <Box component="span" lineHeight="30px">
                                    Cancel
                                </Box>
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

                {isEvaluationFetched && (
                    <Box mt={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                {evaluation?.isInUse && (
                                    <Box mb={1}>
                                        <FormHelperText error>
                                            Notice: This form is being actively used. Edits are limited to text updates and will affect all
                                            forms in progress. Previously created PDFs for completed Evaluations will not be updated.
                                        </FormHelperText>
                                    </Box>
                                )}
                                <Box mb={3}>
                                    <EvaluationForm
                                        questionBasedFormNotice={
                                            showQuestionBasedFormNotice && (
                                                <Box mb={1}>
                                                    <FormHelperText error>
                                                        Notice: To ensure data integrity, make sure to verify that the existing elements for
                                                        this form match the selected Answer Format.
                                                    </FormHelperText>
                                                </Box>
                                            )
                                        }
                                    />
                                </Box>
                                <Box>
                                    <Card>
                                        <Box py={1} px={4}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Box display="flex">{evaluationItems.length} elements</Box>
                                                <Box display="flex">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        disabled={!evaluationId || evaluation?.isInUse}
                                                        disableElevation
                                                        startIcon={<PlusIcon />}
                                                        onClick={handleCreate}>
                                                        <Box component="span" lineHeight="30px">
                                                            New Elements
                                                        </Box>
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        {evaluationItems.length ? (
                                            <Box>
                                                <EvaluationItemDragAndDropList
                                                    evaluationInUse={evaluation?.isInUse || false}
                                                    evaluationItems={evaluationItems}
                                                    onEdit={handleEvaluationItemEdit}
                                                    onDelete={handleEvaluationItemDelete}
                                                    onReordered={handleEvaluationItemReordered}
                                                />
                                            </Box>
                                        ) : (
                                            <Box px={6} py={4}>
                                                <NoDataMessage />
                                            </Box>
                                        )}
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {!isEvaluationFetched && (
                    <Box px={6} py={4} textAlign="center">
                        <CircularProgress size={40} />
                    </Box>
                )}
            </FormikProvider>
            {evaluation && modalOpened && (
                <EvaluationItemModal
                    open={true}
                    item={inEditItem}
                    evaluationConfigurationId={evaluation.id}
                    evaluationInUse={evaluation?.isInUse || false}
                    questionBasedForm={formik.values.questionBasedForm}
                    onClose={handleClose}
                    onCreate={handleEvaluationItemCreate}
                    onDelete={handleEvaluationItemDelete}
                />
            )}
        </>
    );
}
