import React, { useContext } from "react";
import { Box, Hidden, IconButton, Tooltip, Typography } from "@material-ui/core";

import SvgCalendar from "svgComponents/Calendar";
import AvatarWithScore from "components/AvatarWithScore";
import LabelScoreBar from "components/LabelScoreBar";
import NavigationLink from "components/NavigationLink";
import RichText from "components/RichText";
import CoachedBy from "components/CoachedBy";
import LoadingButton from "components/LoadingButton";
import { MenuLink } from "svgComponents";
import { formatDate } from "helpers/dateHelpers";
import { Team, UserProfile } from "types";
import { ConfigurationContext } from "context";
import { getUserFullName } from "helpers/userProfileHelper";

interface TeamProfileSidebarProps {
    user: UserProfile;
    teamAsForMember?: Team | null;
    isTeamAsForMemberFetching?: boolean;
    isCoachingSkillsFetching: boolean,
    openCoachingReportSelector: () => void;
    openNewMessageModal: () => void;
    onSidebarCollapse: () => void;
}

const TeamProfileSidebar = ({
    user,
    teamAsForMember,
    isTeamAsForMemberFetching,
    isCoachingSkillsFetching,
    openCoachingReportSelector,
    openNewMessageModal,
    onSidebarCollapse,
}: TeamProfileSidebarProps) => {
    const {
        lastCoached,
        skillInFocus,
        timesCoached,
        highestSkillName,
        highestSkillRating,
        lowestSkillName,
        lowestSkillRating,
        developmentGoal,
        averageScore,
        minRating,
        maxRating,
        isSkillsAssigned,
    } = user;

    const { currentUser, isConfigurationFetching } = useContext(ConfigurationContext);
    const coachedBy = teamAsForMember?.teamLeader;
    const isLoading = isConfigurationFetching || isTeamAsForMemberFetching || isCoachingSkillsFetching;
    const isCurrentUser = currentUser?.id === user?.id;

    const renderNewCoachingReportButton = () => {
        const disabled = (isCurrentUser && !coachedBy) || !isSkillsAssigned || isLoading;

        const tooltip = isSkillsAssigned
            ? "A team leader needs to be assigned."
            : "Team Member missing Skill assignments. Please contact administrator.";

        const button = (
            <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                disabled={disabled}
                disableElevation
                fullWidth
                loading={isLoading}
                loadingLabel={(
                    <Box lineHeight="30px">New Coaching Report</Box>
                )}
                onClick={openCoachingReportSelector}
            >
                <Box mr={1} fontSize="18px" lineHeight="30px">
                    +
                </Box>
                New Coaching Report
            </LoadingButton>
        );

        if (!disabled || isLoading) return button;

        return (
            <Tooltip title={tooltip} placement="bottom" arrow>
                <Box>{button}</Box>
            </Tooltip>
        );
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            px={{ xs: 2, sm: 6 }}
            py={6}
            flexShrink={0}
            maxWidth={{ xs: "100%", sm: "230px" }}
            minWidth={{ xs: "100%", sm: "230px" }}
            bgcolor="common.white"
        >
            <Box display="flex" flexDirection="column" alignItems="center" position="relative">
                <Hidden xsDown>
                    <Box position="absolute" display="flex" height={42} top={-32} right={-32}>
                        <IconButton onClick={onSidebarCollapse}>
                            <MenuLink />
                        </IconButton>
                    </Box>
                </Hidden>
                <AvatarWithScore user={user} score={averageScore} />
                <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                    <Typography variant="h1" component="span" align="center">
                        {getUserFullName(user)}
                    </Typography>
                    {(coachedBy && !isCurrentUser) && <CoachedBy user={coachedBy} />}
                    {lastCoached && (
                        <Typography variant="caption">
                            Last Coached {formatDate(lastCoached)}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignSelf="center"
                pb={4}
                maxWidth={{ xs: "400px", md: "none" }}
                width={1}
            >
                {renderNewCoachingReportButton()}
                <Box
                    mt={2}
                    display="flex"
                    flexDirection="column"
                    color="text.secondary"
                >
                    <LoadingButton
                        variant="outlined" 
                        color="inherit" 
                        size="large"
                        loading={isLoading}
                        disabled={isLoading}
                        loadingLabel={(
                            <Box lineHeight="30px">New Message</Box>
                        )}
                        onClick={openNewMessageModal}
                    >
                        <Box mr={1} fontSize="18px" lineHeight="30px">
                            +
                        </Box>
                        New Message
                    </LoadingButton>
                </Box>
            </Box>
            <Box pb={4}>
                <Typography variant="caption" paragraph>
                    Skill in Focus
                </Typography>
                <Typography variant="h3">
                    {skillInFocus || "--"}
                </Typography>
            </Box>
            <Box pb={4}>
                <Typography variant="caption" paragraph>
                    Times Coached
                </Typography>
                <Box display="flex" alignItems="center">
                    <Box mr={1} display="flex">
                        <SvgCalendar />
                    </Box>
                    <Typography variant="h3">
                        {timesCoached || 0} Times
                    </Typography>
                </Box>
            </Box>
            <Box pb={4}>
                <Typography variant="caption" paragraph>
                    Highest Score
                </Typography>
                {highestSkillName ? (
                    <LabelScoreBar
                        score={highestSkillRating}
                        label={highestSkillName}
                        minRating={minRating}
                        maxRating={maxRating}
                    />
                ) : (
                    <Typography variant="h3">
                        --
                    </Typography>
                )}
            </Box>
            <Box pb={4}>
                <Typography variant="caption" paragraph>
                    Lowest Score
                </Typography>
                {lowestSkillName ? (
                    <LabelScoreBar
                        score={lowestSkillRating}
                        label={lowestSkillName}
                        minRating={minRating}
                        maxRating={maxRating}
                    />
                ) : (
                    <Typography variant="h3">
                        --
                    </Typography>
                )}
            </Box>

            <Box pb={4}>
                <Typography variant="caption" paragraph>
                    Development Goal
                </Typography>
                {developmentGoal ? (
                    <Box
                        bgcolor="info.main"
                        border="1px solid #EEF0F3"
                        borderRadius={8}
                        p={2}
                    >
                        <Typography variant="body2" component="div">
                            {developmentGoal.length < 100 ? (
                                <RichText text={developmentGoal} />
                            ) : (
                                <>
                                    <RichText text={developmentGoal} charactersToShow={100} />
                                    <NavigationLink href="" underline>
                                        read more
                                    </NavigationLink>
                                </>
                            )}
                        </Typography>
                    </Box>
                ) : (
                    <Typography variant="h3">
                        --
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default TeamProfileSidebar;