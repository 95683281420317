import React from "react";
import { Box, Paper } from "@material-ui/core";

import coachingHistory from "./coachingHistory.svg";
import BackToDashboard from "../BackToDashboard";

function CoachingHistory() {
    return (
        <Box px={7.5}>
            <BackToDashboard />
            <Paper>
                <Box display="flex" justifyContent="center" pt={3} pb={4}>
                    <img src={coachingHistory} alt="" />
                </Box>
            </Paper>
        </Box>
    )
}

export default CoachingHistory;
