import React from "react";
import clsx from "clsx";
import { Link, LinkProps } from "react-router-dom";

import useStyles from "./styles";

interface NavigationLinkProps {
    href: LinkProps["to"];
    underline?: boolean;
    hoverUnderline?: boolean;
    className?: string;
    children?: React.ReactNode;
    fakeLink?: boolean;
}

const NavigationLink = ({ href, underline = false, hoverUnderline = true, className, children, fakeLink = false }: NavigationLinkProps) => {
    const classes = useStyles();
    const classNames = clsx(
        classes.link,
        {
            [classes.noUnderline]: !underline,
            [classes.noHoverUnderline]: !hoverUnderline,
            [classes.fakeLink]: fakeLink,
        },
        className
    );

    return fakeLink ? (
        <div className={classNames}>{children}</div>
    ) : (
        <Link to={href} className={classNames}>
            {children}
        </Link>
    );
};

export default NavigationLink;
