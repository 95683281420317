import * as React from "react";

function SvgFolder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={17}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.531 2.24h-6.92L6.511.93c-.16-.19-.4-.3-.65-.3h-4.39a.835.835 0 00-.85.84v10.4c0 .47.38.85.85.85h13.06c.47 0 .85-.38.85-.85V3.09a.85.85 0 00-.85-.85z"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgFolder;
