import React from "react";
import { Box, Typography } from "@material-ui/core";
import { UserProfile } from "types";
import AvatarWithScore from "components/AvatarWithScore";
import { SizeEnum } from "types/constants";

interface UserLabelProps {
    user: UserProfile;
}

function UserLabel({ user }: UserLabelProps) {
    return (
        <>
            <Box display="inline-block" ml={2} mr={1.5}>
                <AvatarWithScore
                    user={user}
                    size={SizeEnum.xs}
                />
            </Box>
            <Typography variant="h2" component="span">
                {`${user.firstName} ${user.lastName}`}
            </Typography>
        </>
    )
}

export default UserLabel;
