import React, { useState, MouseEvent, useContext, useCallback, useMemo } from "react";
import {
    AppBar,
    Box,
    ButtonBase,
    IconButton,
    Toolbar,
    Popper,
    Typography,
    Button,
    ClickAwayListener,
    Paper,
    Divider,
    useTheme,
} from "@material-ui/core";
import { HeaderProps } from "types";
import { Menu, Settings, Switch } from "svgComponents";
import AvatarWithScore from "components/AvatarWithScore";
import Notifications from "components/Notifications";
import ManageProfileModal from "components/ManageProfileModal";
import { LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW, SizeEnum } from "types/constants";
import authService from "services/authService";
import { switchTeamView } from "services/profileServices";
import { ConfigurationContext } from "context";

import useStyles from "./styles";

const Header = ({ isBarOpen, navBarOpen, className }: HeaderProps) => {
    const { currentUser, currentCompany } = useContext(ConfigurationContext);
    const theme = useTheme();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isManageProfileModalOpen, setIsManagerProfileModalOpen] = useState(false);

    const toggleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
    }, [anchorEl]);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const userName = useMemo(() =>
        currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "",
    [currentUser]
    );

    const isTeamMemberView = localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW);

    const switchView = useCallback(() => switchTeamView(), []);

    const handleManageProfileClick = useCallback(() => {
        handleClose();
        setIsManagerProfileModalOpen(true);
    }, []);

    const closeManageProfileModal = useCallback(() => {
        setIsManagerProfileModalOpen(false);
    }, []);

    return (
        <AppBar color="inherit" position="sticky" className={className}>
            <Toolbar>
                {!isBarOpen && (
                    <IconButton edge="start" disableRipple onClick={() => navBarOpen()}>
                        <Menu />
                    </IconButton>
                )}
                <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                    <Box>
                        {currentCompany?.logo && currentCompany?.name && (
                            <img
                                alt={currentCompany.name}
                                className={classes.logo}
                                src={currentCompany.logo}
                            />    
                        )}
                    </Box>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <Box pr={2}>
                                <Notifications/>
                            </Box>
                            {currentUser && (
                                <>
                                    <ButtonBase disableRipple onClick={toggleOpen}>
                                        <AvatarWithScore user={currentUser} size={SizeEnum.small}/>
                                    </ButtonBase>
                                    <Popper
                                        id={currentUser.id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        placement="bottom-end"
                                        className={classes.popover}
                                        disablePortal
                                    >
                                        <Paper className={classes.paper}>
                                            <Box display="flex" flexDirection="column" alignItems="center" p={4}>
                                                <AvatarWithScore user={currentUser} size={SizeEnum.medium}/>
                                                <Box pt={2.5} pb={4} display="flex" flexDirection="column" alignItems="center">
                                                    <Box fontWeight="600">
                                                        <Typography variant="h2">{userName}</Typography>
                                                    </Box>
                                                    <Typography variant="caption" className={classes.position}>
                                                        {currentUser.title}
                                                    </Typography>
                                                </Box>
                                                {(currentUser.isALeader || currentUser.isACoLeader) && (
                                                    <Box color="text.secondary" width={1} mb={2}>
                                                        <Button
                                                            variant="outlined"
                                                            color="inherit"
                                                            size="large"
                                                            onClick={switchView}
                                                            fullWidth
                                                        >
                                                            <Box display="flex" color={theme.palette.text.secondary} mr={1.5}>
                                                                <Switch/>
                                                            </Box>
                                                            Switch to {isTeamMemberView ? "Coaching" : "My Profile"}
                                                        </Button>
                                                    </Box>
                                                )}
                                                <Box color="text.secondary" width={1}>
                                                    <Button
                                                        variant="outlined"
                                                        color="inherit"
                                                        size="large"
                                                        onClick={handleManageProfileClick}
                                                        fullWidth
                                                    >
                                                        <Box display="flex" color={theme.palette.text.secondary} mr={1.5}>
                                                            <Settings/>
                                                        </Box>
                                                        Manage profile
                                                    </Button>
                                                    <Box alignSelf="stretch" my={3} mx={-4}>
                                                        <Divider/>
                                                    </Box>
                                                </Box>
                                                <Box color="text.secondary">
                                                    <Button
                                                        variant="outlined"
                                                        color="inherit"
                                                        size="large"
                                                        onClick={() => authService.logout()}
                                                    >
                                                        Sign out of Omni &middot; coach
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Popper>
                                </>
                            )}
                        </Box>
                    </ClickAwayListener>
                </Box>
            </Toolbar>
            {isManageProfileModalOpen && (
                <ManageProfileModal
                    isOpen={isManageProfileModalOpen}
                    onSave={closeManageProfileModal}
                    onClose={closeManageProfileModal}
                />
            )}
        </AppBar>
    );
};

export default React.memo(Header);
