import React from "react";
import dayjs from "dayjs";
import mean from "lodash/mean"
import { CoachingHistoryBase } from "types/CoachHistory";
import { CoachingSessionTypeIconsEnum } from "types/constants";
import * as CoachingSessionTypeIcons from "svgComponents/CoachingSessionTypeIcons";

export function getSessionTypeIcon(icon: CoachingSessionTypeIconsEnum): React.ReactNode {
    switch (icon) {
        case CoachingSessionTypeIconsEnum.virtual:
            return <CoachingSessionTypeIcons.VirtualIcon />;
        case CoachingSessionTypeIconsEnum.businessPlanning:
            return <CoachingSessionTypeIcons.BusinessPlanningIcon />;
        case CoachingSessionTypeIconsEnum.rideAlong:
            return <CoachingSessionTypeIcons.RidealongIcon />;
        case CoachingSessionTypeIconsEnum.achievement:
            return <CoachingSessionTypeIcons.AchievementIcon />;
        case CoachingSessionTypeIconsEnum.calendarList:
            return <CoachingSessionTypeIcons.CalendarListIcon />;
        case CoachingSessionTypeIconsEnum.completedTask:
            return <CoachingSessionTypeIcons.CompletedTaskIcon />;
        case CoachingSessionTypeIconsEnum.graph:
            return <CoachingSessionTypeIcons.GraphIcon />;
        case CoachingSessionTypeIconsEnum.presenter:
            return <CoachingSessionTypeIcons.PresenterIcon />;
        case CoachingSessionTypeIconsEnum.star:
            return <CoachingSessionTypeIcons.StarIcon />;
        case CoachingSessionTypeIconsEnum.target:
            return <CoachingSessionTypeIcons.TargetIcon />;
        case CoachingSessionTypeIconsEnum.trophy:
            return <CoachingSessionTypeIcons.TrophyIcon />;
        default:
            return null;
    }
}

export function byToDate(currentDate: Date, isCurrentPeriod = true) {
    const today = dayjs(currentDate).startOf("day").unix();

    return ({ toDate }: CoachingHistoryBase) => {
        const coachToDate = dayjs(toDate).startOf("day").unix();
        return isCurrentPeriod ? coachToDate === today : coachToDate !== today;
    }
}

export function calcScore<T extends CoachingHistoryBase>(
    curHistory: T[],
    prevHistory: T[],
    scoreName: Exclude<keyof T, keyof CoachingHistoryBase>,
    toFixed = 0
) {
    const curScores = curHistory.map(item => item[scoreName]) as unknown[];
    const prevScores = prevHistory.map(item => item[scoreName]) as unknown[];
    const curScoreNumbs = curScores.filter(Number.isFinite) as number[];
    const prevScoreNumbs = prevScores.filter(Number.isFinite) as number[];
    const total = curScoreNumbs.reduce((acc, cur) => acc + cur, 0);
    const score = calc(curScoreNumbs, toFixed);
    const prevScore = calc(prevScoreNumbs, toFixed);
    const dynamic = parseFloat((score - prevScore).toFixed(toFixed));
    const isIncreased = dynamic >= 0;
    return { total, score, dynamic: Math.abs(dynamic), isIncreased }
}

function calc(value: number[], toFixed = 0) {
    const average = value.length > 0 ? mean(value) : 0;
    return parseFloat(average.toFixed(toFixed));
}
