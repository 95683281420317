import React, { useCallback, useMemo } from "react";
import {
    Box,
    Typography,
    FormControl,
    RadioGroup,
    Grid,
    Select,
    MenuItem,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { v4 as uuidv4 } from "uuid";
import orderBy from "lodash/orderBy";

import Checkbox from "components/Checkbox";
import RadioButton from "components/FormControlRadioButton";
import MultilineInput from "components/MultilineInput";
import RichText from "components/RichText";
import { FcrItemConfiguration, FcrItemResult, FcrResult } from "types";
import { ComponentType, FcrComplianceItemValueEnum } from "types/constants";

import useStyles from "./styles";

interface FcrConfigurationItemProps {
    fcrItemConfiguration: FcrItemConfiguration;
    fcrResult?: FcrResult;
    fcrItemResults: FcrItemResult[];
    fcrItemResultsChange?: (fcrItemConfiguration: FcrItemConfiguration, result: FcrItemResult | FcrItemResult[]) => void;
    showError?: boolean
}

function FcrConfigurationItem({
    fcrItemConfiguration,
    fcrResult,
    fcrItemResults,
    fcrItemResultsChange,
    showError,
}: FcrConfigurationItemProps) {
    const classes = useStyles();

    const getValue = useCallback(() => {
        if (fcrItemConfiguration.fcrItemTypeId === ComponentType.freeInput) {
            return fcrItemResults[0]?.itemValue?.value ?? "";
        }
        if (fcrItemConfiguration.fcrItemTypeId === ComponentType.multiselectCheckbox) {
            return fcrItemResults.map((result) => result.fcrItemValueId);
        }

        return fcrItemResults[0]?.fcrItemValueId ?? "";
    }, [fcrItemConfiguration, fcrItemResults]);

    const value = useMemo(getValue, [getValue]);

    const sortedItemValues = useMemo(() => orderBy(fcrItemConfiguration.itemValues, "order"), [fcrItemConfiguration.itemValues]);

    const handleChange = useCallback((fcrItemResultRest: Pick<FcrItemResult, "fcrItemValueId" | "itemValue">) => {
        if (fcrItemResultsChange) {
            const fcrItemResultId = fcrItemResults[0]?.id || uuidv4();

            const updatedFcrItemResult = {
                id: fcrItemResultId,
                fcrItemConfigurationId: fcrItemConfiguration.id,
                fcrResultId: fcrResult?.id || "",
                ...fcrItemResultRest,
            };

            fcrItemResultsChange(fcrItemConfiguration, updatedFcrItemResult);
        }
    }, [fcrItemConfiguration, fcrItemResultsChange, fcrItemResults, fcrResult]);

    const handleChangeValue = useCallback(({ target }) => {
        handleChange({ fcrItemValueId: target.value });
    }, [handleChange]);

    const handleChangeFreeInput = useCallback((value) => {
        const fcrItemValueId = fcrItemResults[0]?.fcrItemValueId || uuidv4();

        handleChange({
            fcrItemValueId,
            itemValue: { id: fcrItemValueId, value, order: 1, isActive: null },
        });
    }, [fcrItemResults, handleChange]);

    const handleChangeCompliance = useCallback(({ target }) => {
        const checkedValue = target.checked ? FcrComplianceItemValueEnum.checked : FcrComplianceItemValueEnum.unchecked;
        const itemValue = sortedItemValues?.find((itemValue) =>
            itemValue.value.toLowerCase() === checkedValue.toLowerCase()
        );

        if (!itemValue || !fcrItemResultsChange) return;

        if (!target.checked) {
            const updatedResults = fcrItemResults.filter((result) => result.fcrItemConfigurationId !== itemValue.fcrItemConfigurationId)
            fcrItemResultsChange(fcrItemConfiguration, updatedResults);
        } else {
            handleChange({ fcrItemValueId: itemValue.id });
        }
    }, [sortedItemValues, fcrItemResultsChange, fcrItemResults, fcrItemConfiguration, handleChange]);

    const handleChangeMultiselectCheckbox = useCallback(({ target }) => {
        if (fcrItemResultsChange) {
            const updatedResults = value.includes(target.value)
                ? fcrItemResults.filter((result) => result.fcrItemValueId !== target.value)
                : [...fcrItemResults, {
                    id: uuidv4(),
                    fcrItemConfigurationId: fcrItemConfiguration.id,
                    fcrResultId: fcrResult?.id || "",
                    fcrItemValueId: target.value,
                }];

            fcrItemResultsChange(fcrItemConfiguration, updatedResults);
        }
    }, [value, fcrItemConfiguration, fcrItemResults, fcrResult, fcrItemResultsChange]);

    const error = useMemo(() => {
        if (!showError || !fcrItemConfiguration.isRequired) return false;
        return Array.isArray(value) ? !value.length : !value;
    }, [showError, value, fcrItemConfiguration.isRequired]);

    const ErrorMessage = useMemo(() => error && (
        <Box pt={1.5}>
            <Typography className={classes.redText}>This field is required.</Typography>
        </Box>
    ), [error, classes.redText]);

    const ElementHeaderWithBody = useMemo(() => (
        <Box pb={2} className={classes.darkBlueText}>
            <Box fontWeight="600" pb={1.5}>
                <Typography variant="h3" color="inherit" component="div">
                    <RichText text={fcrItemConfiguration.elementHeader} />
                </Typography>
            </Box>
            <Typography variant="body1" color="inherit" component="div">
                <RichText text={fcrItemConfiguration.elementBody} />
            </Typography>
        </Box>
    ), [classes.darkBlueText, fcrItemConfiguration.elementHeader, fcrItemConfiguration.elementBody]);

    switch (fcrItemConfiguration.fcrItemTypeId) {
        case ComponentType.radioButtons:
            return (
                <Box>
                    {ElementHeaderWithBody}
                    <Box pb={4}>
                        <FormControl fullWidth disabled={!fcrItemResultsChange}>
                            <RadioGroup aria-label={fcrItemConfiguration.id} row value={value} onChange={handleChangeValue}>
                                <Grid container spacing={3}>
                                    {sortedItemValues?.map(itemValue =>
                                        <Grid key={itemValue.id} item md={3} xs={6}>
                                            <RadioButton
                                                key={itemValue.id}
                                                label={itemValue.value}
                                                value={itemValue.id}
                                                checked={itemValue.id === value}
                                                error={error}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                        {ErrorMessage}
                    </Box>
                </Box>
            )
        case ComponentType.singleCheckbox:
        case ComponentType.multiselectCheckbox:
            return (
                <Box pb={4}>
                    {ElementHeaderWithBody}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.checkbox}
                        px={3}
                        pt={2}
                        pb={2.5}
                        border={error ? 1 : 0}
                        borderColor={error ? "error.main" : ""}
                    >
                        <FormGroup>
                            {sortedItemValues?.map(itemValue =>
                                <FormControlLabel
                                    key={itemValue.id}
                                    control={<Checkbox color="primary" />}
                                    checked={typeof value === "string"
                                        ? itemValue.id === value
                                        : value.includes(itemValue.id)
                                    }
                                    value={itemValue.id}
                                    onChange={typeof value === "string"
                                        ? handleChangeValue
                                        : handleChangeMultiselectCheckbox
                                    }
                                    disabled={!fcrItemResultsChange}
                                    label={
                                        <Typography
                                            variant="h3"
                                            color="inherit"
                                            component="div"
                                        >
                                            <RichText text={itemValue.value} />
                                        </Typography>
                                    }
                                />
                            )}
                        </FormGroup>
                    </Box>
                    {ErrorMessage}
                </Box>
            );
        case ComponentType.compliance:
            const checkedItemValue = sortedItemValues?.find((itemValue) => (
                itemValue.value.toLowerCase() === FcrComplianceItemValueEnum.checked.toLowerCase()
            ));

            return (
                <Box pb={4}>
                    <Box
                        className={classes.checkbox}
                        px={3}
                        pt={2}
                        pb={2.5}
                        border={error ? 1 : 0}
                        borderColor={error ? "error.main" : ""}
                    >
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label={<RichText text={fcrItemConfiguration.elementBody} />}
                            checked={checkedItemValue?.id === value}
                            onChange={handleChangeCompliance}
                            disabled={!fcrItemResultsChange}
                        />
                    </Box>
                    {ErrorMessage}
                </Box>
            );
        case ComponentType.staticText:
            return (
                <Box pb={2}>
                    {ElementHeaderWithBody}
                </Box>
            );
        case ComponentType.freeInput:
            return (
                <Box pb={4}>
                    {ElementHeaderWithBody}
                    {fcrItemResultsChange ? (
                        <MultilineInput value={value as string} onChange={handleChangeFreeInput} error={error} />
                    ) : (
                        <RichText text={value as string} />
                    )}
                    {ErrorMessage}
                </Box>
            )
        case ComponentType.dropdownList:
            return (
                <Box pb={4}>
                    {ElementHeaderWithBody}
                    <Select
                        variant="outlined"
                        value={value}
                        onChange={handleChangeValue}
                        IconComponent={ExpandMore}
                        disabled={!fcrItemResultsChange}
                        error={error}
                        fullWidth
                    >
                        {sortedItemValues?.map(item =>
                            <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
                        )}
                    </Select>
                    {ErrorMessage}
                </Box>
            );
        default:
            return (<div />)
    }
}

export default FcrConfigurationItem;
