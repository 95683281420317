import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { Box, Typography, Link, Divider, Button } from "@material-ui/core";
import FcrConfigurationItem from "components/FcrConfigurationItem";
import PerformanceMetrics, { PerformanceMetricsVariant } from "routes/Dashboard/DashboardContent/PerformanceMetrics";
import {
    DevelopmentGoals,
    FcrCoachingSkillObservation,
    CoachingPlanType,
    FcrItemConfiguration,
    FcrItemResult,
    FcrResult,
    UserProfile,
    FcrConfiguration,
    ProductSalesData,
    CoachingSession,
} from "types";
import { sortFcrSkillsObservations, sortFcrCoachingPlans, buildSectionLabelsArray } from "helpers/fcrHelper";
import { ConfigurationContext } from "context";

import { getDevGoalsFields } from "../../config";

import useStyles from "./styles";
import DevelopmentGoalItem from "./DevelopmentGoalItem";
import SkillCoachedItem from "./SkillCoachedItem";
import CoachingPlanItem from "./CoachingPlanItem";
import SubmitLoadingModal from "./SubmitLoadingModal";
import CoachingReportHeader from "./CoachingReportHeader";

interface ReviewProps {
    submitted?: boolean;
    user: UserProfile;
    developmentGoals: DevelopmentGoals;
    fcrCoachingSkillObservations: Array<FcrCoachingSkillObservation>;
    coachingPlans: Array<CoachingPlanType>;
    fcrItemConfigurations: Array<FcrItemConfiguration>;
    fcrItemResults: Array<FcrItemResult>;
    moveToStep: (stepNumber: number) => void;
    onChange: (value: Array<FcrItemResult>) => void;
    fcrResult?: FcrResult;
    showError: boolean;
    isSubmitLoading: boolean;
    fcrConfiguration: FcrConfiguration;
    productSalesData?: ProductSalesData | null;
    coachingSession: CoachingSession;
}

function Review({
    submitted,
    user,
    developmentGoals,
    fcrCoachingSkillObservations,
    coachingPlans,
    fcrItemConfigurations,
    fcrItemResults,
    moveToStep,
    onChange,
    fcrResult,
    showError,
    isSubmitLoading,
    fcrConfiguration,
    productSalesData,
    coachingSession,
}: ReviewProps) {
    const classes = useStyles();

    const { fcrSectionConfiguration } = useContext(ConfigurationContext);

    const sectionsToRender = useMemo(() => {
        const resultSectionsArray = buildSectionLabelsArray(fcrSectionConfiguration);
        fcrItemConfigurations.length === 0 && resultSectionsArray.pop();
        return resultSectionsArray;
    }, [fcrSectionConfiguration, fcrItemConfigurations]);

    const fcrItemResultsChange = useCallback(
        (fcrItemConfiguration: FcrItemConfiguration, result: FcrItemResult | FcrItemResult[]) => {
            if (Array.isArray(result)) {
                const fcrItemsFilteredResults = fcrItemResults.filter((e) => e.fcrItemConfigurationId !== fcrItemConfiguration.id);

                onChange([...fcrItemsFilteredResults, ...result]);
            } else {
                const fcrItemResult = fcrItemResults.find((e) => e.fcrItemConfigurationId === fcrItemConfiguration.id);

                if (fcrItemResult) {
                    onChange(fcrItemResults.map((itemResult) => (itemResult.id === result.id ? result : itemResult)));
                } else {
                    onChange([...fcrItemResults, result]);
                }
            }
        },
        [fcrItemResults, onChange]
    );

    const sectionContent = useCallback(
        (index: number) => {
            switch (true) {
                case index === 0:
                    return (
                        <>
                            {Object.entries(developmentGoals)
                                .filter((value) => getDevGoalsFields(fcrConfiguration).find((dg) => dg.fieldName === value[0]))
                                .map((value, key) => (
                                    <DevelopmentGoalItem key={key} value={value} fcrConfiguration={fcrConfiguration} />
                                ))}
                            {productSalesData && (
                                <Box mb={3}>
                                    <PerformanceMetrics productSalesData={productSalesData} variant={PerformanceMetricsVariant.FCR} />
                                </Box>
                            )}
                        </>
                    );

                case index === 1:
                    return sortFcrSkillsObservations(fcrConfiguration.fcrCoachingSkills, fcrCoachingSkillObservations).map((sc) => (
                        <SkillCoachedItem key={sc.id} skillCoached={sc} fcrConfiguration={fcrConfiguration} />
                    ));
                case index === 2:
                    return sortFcrCoachingPlans(fcrConfiguration.fcrCoachingSkills, fcrCoachingSkillObservations, coachingPlans).map(
                        (cp) => (
                            <CoachingPlanItem
                                key={cp.id}
                                coachingPlan={cp}
                                fcrCoachingSkillName={
                                    fcrCoachingSkillObservations.find((so) => so.id === cp.fcrCoachingSkillResultId)
                                        ?.fcrCoachingSkillName || ""
                                }
                            />
                        )
                    );
                case index === 3:
                    return fcrItemConfigurations.map((fcrItemConfiguration) => {
                        const filteredFcrItemResults = fcrItemResults.filter((e) => e.fcrItemConfigurationId === fcrItemConfiguration.id);
                        return (
                            <FcrConfigurationItem
                                key={fcrItemConfiguration.id}
                                fcrItemConfiguration={fcrItemConfiguration}
                                fcrResult={fcrResult}
                                fcrItemResults={filteredFcrItemResults}
                                fcrItemResultsChange={!submitted ? fcrItemResultsChange : undefined}
                                showError={showError}
                            />
                        );
                    });
            }
        },
        [
            coachingPlans,
            developmentGoals,
            fcrCoachingSkillObservations,
            fcrItemConfigurations,
            fcrItemResultsChange,
            fcrItemResults,
            fcrResult,
            showError,
            submitted,
            fcrConfiguration,
            productSalesData,
        ]
    );

    return (
        <>
            <Box className={clsx(classes.content, submitted && classes.contentSubmitted)}>
                {submitted && <CoachingReportHeader user={user} coachingSession={coachingSession} />}
                {sectionsToRender.map((sectionName, index) => (
                    <Box key={index}>
                        <Box px={{ xs: 2, md: 8, lg: 13.5 }} pt={{ xs: 3, lg: 6.5 }} pb={{ xs: 1, lg: 3 }}>
                            <Box display="flex" flexDirection="row" alignItems="center" pb={4.5}>
                                <Typography variant="h1" className={classes.sectionHeader}>
                                    {sectionName}
                                </Typography>
                                {index < 3 && !submitted && (
                                    <Box pl={2}>
                                        <Button
                                            disableRipple
                                            disableFocusRipple
                                            disableElevation
                                            disableTouchRipple
                                            onClick={() => moveToStep(index)}>
                                            <Link variant="body2" underline="always">
                                                Edit Section
                                            </Link>
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            {sectionContent(index)}
                        </Box>
                        <Divider />
                    </Box>
                ))}
            </Box>
            <SubmitLoadingModal open={isSubmitLoading} message={"We’re submitting your report. "} />
        </>
    );
}

export default Review;
