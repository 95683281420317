import React, { useState, useMemo, useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { orderBy } from "lodash";
import { Box, CircularProgress, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { CoachingHubContext, ConfigurationContext } from "context";
import { MessageThread, UserProfile } from "types";
import { Routings } from "types/constants";
import PaperSection from "components/PaperSection";
import NoDataMessage from "components/NoDataMessage";
import { formatShortDate } from "helpers/dateHelpers";
import { cleanupRichText } from "helpers/stringHelpers";
import { getUserFullName } from "helpers/userProfileHelper";

import useStyles from "./styles";
import CoachingSessionFilters, { FiltersData } from "../CoachingSessionFilters";

const STEP_SIZE = 50;

interface MessagesProps {
    membersBelow: UserProfile[];
}

export default function Messages({ membersBelow }: MessagesProps) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { currentUser } = useContext(ConfigurationContext);
    const { messageThreadList, fetchMessagesThreads, isFetchingMessages, totalMessageThreads } = useContext(CoachingHubContext);
    const [shownCount, setShownCount] = useState<number>(STEP_SIZE);
    const [isShowMore, setIsShowMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [formattedThreads, setFormattedThreads] = useState<MessageThread[]>([]);
    const [isFiltered, setIsFiltered] = useState<boolean>(true);

    useEffect(() => {
        if (!messageThreadList) fetchMessagesThreads(page)
    }, [fetchMessagesThreads, messageThreadList, page]);

    const shownSessions = useMemo(() => messageThreadList ? messageThreadList.slice(0, shownCount) : [], [messageThreadList, shownCount]);

    const onShowMore = useCallback(() => {
        setPage(page + 1)
        setShownCount(shownCount + STEP_SIZE)
        fetchMessagesThreads(page + 1)
    }, [fetchMessagesThreads, page, shownCount]);
    
    useEffect(() => {
        if (shownSessions.length !== totalMessageThreads) {
            setIsShowMore(true)
        } else {
            setIsShowMore(false)
        }
    }, [shownSessions.length, totalMessageThreads]);

    const isLoading = isFetchingMessages || !messageThreadList || !currentUser;

    const shownThreads = useMemo(() => formattedThreads.slice(0, shownCount), [formattedThreads, shownCount]);

    const membersBelowWithCurrentUser = useMemo(() => (
        currentUser ? [currentUser, ...membersBelow] : membersBelow
    ), [membersBelow, currentUser]);

    const handleFiltersChange = useCallback(({ sortDirection, memberId }: FiltersData<unknown>) => {        
        // eslint-disable-next-line functional/no-let
        let formattedThreads = messageThreadList;
        setIsFiltered(false)

        if (memberId) {
            formattedThreads = formattedThreads?.filter((message) => message.fromUserId === memberId);
            setIsFiltered(true)
        }

        formattedThreads = orderBy(
            formattedThreads,
            (message) => new Date(message.messages[0].createdDate),
            [sortDirection]
        );

        formattedThreads && setFormattedThreads(formattedThreads);
    }, [messageThreadList]);

    const handleClick = useCallback((threadId: string) => {        
        history.push(`${Routings.coachingHub}/messages/${threadId}`);
    }, [history]);

    return (
        <PaperSection
            width={`calc(100% - ${theme.spacing(6) * 2}px)`}
            title={(
                <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" width={1}>
                    <Box fontWeight={600} whiteSpace="nowrap">
                        <Typography variant="h2">Messages</Typography>
                    </Box>
                    <CoachingSessionFilters
                        membersBelow={membersBelowWithCurrentUser}
                        loading={isLoading}
                        onFiltersChange={handleFiltersChange}
                    />
                </Box>
            )}
        >
            {!isLoading && 
                <InfiniteScroll
                    scrollableTarget="app"
                    dataLength={shownThreads.length}
                    next={onShowMore}
                    hasMore={isShowMore && totalMessageThreads > shownCount}
                    loader={isShowMore ? <Box p={4} textAlign="center">
                        <CircularProgress size={40} />
                    </Box> : null}
                    hasChildren
                >
                    <Box px={5} py={2}>
                        <Typography variant="caption">{`${shownThreads.length} ${isShowMore && !isFiltered ? `from ${totalMessageThreads} ` : ""}items`}</Typography>
                    </Box>
                    <Divider />
                    {shownThreads.map((thread) => (
                        <Box key={thread.id} p={1}>
                            <Grid container className={classes.item} onClick={() => handleClick(thread.id)}>
                                <Grid item container wrap="nowrap" xs={12} md={8} className={classes.messageCell}>
                                    <Box fontWeight={500} pr={0.5}>
                                        {thread.messages[0].subject}
                                    </Box>
                                    {"-"}
                                    <Box color={theme.colors.text.mediumGray} pl={0.5} overflow="hidden" textOverflow="ellipsis">
                                        {cleanupRichText(thread.messages[0].messageContent)}
                                    </Box>
                                    {thread.totalMessages - 1 > 0 && (
                                        <>
                                            <Box fontWeight={500} color={theme.palette.primary.main} ml={1}>
                                                {thread.totalMessages - 1} {thread.totalMessages - 1 > 1 ? "responses" : "response"}
                                            </Box>
                                            {thread.totalNewMessages > 0 && (
                                                <Box
                                                    fontWeight={500}
                                                    bgcolor={theme.palette.primary.main}
                                                    color={theme.palette.common.white}
                                                    ml={1} px={1}
                                                    borderRadius={4}
                                                >
                                                    {thread.totalNewMessages}
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Box pl={{ md: 5 }} fontWeight={500} whiteSpace="nowrap">
                                        {getUserFullName(thread.fromUser)}
                                    </Box>
                                </Grid>
                                <Grid item container justifyContent="flex-end" md={1} xs={6}>
                                    <Box fontWeight={500} whiteSpace="nowrap">
                                        {formatShortDate(new Date(thread.messages[0].createdDate))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </InfiniteScroll>
            }
            {formattedThreads.length === 0 && !isLoading && (
                <NoDataMessage pb={5} pt={7} message="There's nothing to show here yet" />
            )}
            {isLoading && (
                <Box p={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            )}
        </PaperSection>
    );
}
