import * as React from "react";

function SvgDownArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={9}
            height={7}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                opacity={0.96}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.197 6.839A.39.39 0 004.509 7c.104 0 .208-.07.294-.186l4.075-5.44A.716.716 0 008.965.75c-.07-.209-.226-.348-.4-.348H.436c-.174 0-.33.116-.4.348-.069.231-.034.486.087.648l4.075 5.44z"
                fill="#DD3928"
            />
        </svg>
    );
}

export default SvgDownArrow;
