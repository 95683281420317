import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ConfigurationContext } from "context";
import CircleIcon from "components/CircleIcon";
import StatusLabel from "components/StatusLabel";
import ConditionalLink from "components/shared/ConditionalLink";
import ConditionalTooltip from "components/shared/ConditionalTooltip";

import { formatShortDate } from "helpers/dateHelpers";
import { getEvaluationUrl } from "helpers/certificationHelper";

import { EvaluationResultStatusEnum } from "types/constants";
import { computedSecondaryStatusBasedOnStatus, EvaluationStatusIdToNameLookup, TraineeClassInfoDto } from "types/Certification";

import * as CoachingSessionTypeIcons from "svgComponents/CoachingSessionTypeIcons";
import IconPersonCheck from "svgComponents/IconPersonCheck";
import { Calendar } from "svgComponents";

import useStyles from "./styles";

export interface AllMyClassesPageItemProps {
    myClass: TraineeClassInfoDto;
}

const AllMyClassesPageItem: React.FC<AllMyClassesPageItemProps> = ({ myClass }: AllMyClassesPageItemProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { currentUser } = useContext(ConfigurationContext);
    const secondaryComputedState = computedSecondaryStatusBasedOnStatus(EvaluationStatusIdToNameLookup[myClass.evaluationStatusId]);
    const statusIsntComplete = myClass.evaluationStatusId !== EvaluationResultStatusEnum.completed;
    const notCompletedAndNotSelfEvaluated = statusIsntComplete && !myClass.isSelfEvaluated;
    const notCompleteAndIsSelfEvaluated = statusIsntComplete && myClass.isSelfEvaluated;

    const showLink =
        (myClass.evaluationStatusId === EvaluationResultStatusEnum.completed || notCompleteAndIsSelfEvaluated) &&
        !!myClass.evaluationConfigurationId;

    const getToolTipText = useCallback(() => {
        if (notCompletedAndNotSelfEvaluated) {
            return "Evaluator led Class";
        } else if (myClass.isSelfEvaluated) {
            return "Self-evaluation";
        } else {
            return "";
        }
    }, [notCompletedAndNotSelfEvaluated, myClass.isSelfEvaluated]);

    const handleClickClass = useCallback(async () => {
        if (currentUser) {
            const url = await getEvaluationUrl(myClass, currentUser.id);
            history.push(url);
        }
    }, [currentUser, history, myClass]);

    return (
        <ConditionalLink onClick={handleClickClass} condition={showLink}>
            <ConditionalTooltip textContent={getToolTipText()} condition={!!getToolTipText()}>
                <div className={classes.classItemWrap}>
                    <Grid container spacing={2} className={clsx(classes.classItem)}>
                        <Grid item xs={12} sm={6} md={4} lg={2} className={classes.classItemCell}>
                            <CircleIcon>
                                <Calendar />
                            </CircleIcon>
                            <div className={classes.classItemCellText}>
                                {myClass.classDate ? (
                                    <div className={classes.bold}>
                                        <Typography variant="h2">{formatShortDate(new Date(myClass.classDate))}</Typography>
                                    </div>
                                ) : (
                                    <Typography variant="h2">-</Typography>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2} className={classes.classItemCell}>
                            <CircleIcon>
                                <CoachingSessionTypeIcons.BusinessPlanningIcon />
                            </CircleIcon>
                            <div className={classes.classItemCellTextBold}>
                                <Typography variant="h2">{myClass.className}</Typography>
                                <Typography variant="caption">Class</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2} className={clsx([classes.classItemCell, classes.classItemJustIcon])}>
                            {myClass.isSelfEvaluated ? (
                                <CircleIcon>
                                    <IconPersonCheck />
                                </CircleIcon>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2} className={classes.classItemCell}>
                            {myClass.evaluationStatusId ? <StatusLabel status={myClass.evaluationStatusId} /> : null}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2} className={classes.classItemCell}>
                            <div className={classes.classItemCellTextBold}>
                                {myClass.evaluationStatusDate ? (
                                    <Typography variant="h2">{formatShortDate(new Date(myClass.evaluationStatusDate))}</Typography>
                                ) : null}
                                <Typography variant="caption">{secondaryComputedState}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </ConditionalTooltip>
        </ConditionalLink>
    );
};

export default React.memo(AllMyClassesPageItem);
