import React, { useCallback, useState, useMemo } from "react";
import { List, Button, Paper, Typography, Box, Tooltip } from "@material-ui/core";
import union from "lodash/union";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import InfiniteScroll from "react-infinite-scroll-component";

import { UserProfile, CompanyLevel } from "types";

import CustomListItem from "./CustomListItem";
import useStyles from "../styles";

interface CustomListProps {
    type?: string;
    fetchMembers?: (pageNumber: number) => void;
    title?: string;
    usersIds?: string[];
    users?: UserProfile[];
    totalAmountUsers: number;
    checked: string[];
    companyLevels: CompanyLevel[];
    setChecked: (checked: string[]) => void;
    resetChecked: (checked: string[]) => void;
    setPaging: (page: number) => void;
}

const STEP_SIZE = 100;

const CustomList = ({
    title,
    usersIds,
    users,
    checked,
    companyLevels,
    setChecked,
    resetChecked,
    fetchMembers,
    type,
    totalAmountUsers,
    setPaging,
}: CustomListProps) => {
    const classes = useStyles();
    const [page, setPage] = useState<number>(1);
    const [shownCount, setShownCount] = useState(STEP_SIZE);

    const shownUsers = useMemo(() => (users ? users.slice(0, shownCount) : []), [shownCount, users]);

    const handleToggle = useCallback(
        (value: string) => {
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            resetChecked([]);
            setChecked(newChecked);
        },
        [checked, resetChecked, setChecked]
    );

    const numberOfChecked = useCallback((usersIds) => intersection(checked, usersIds).length, [checked]);

    const handleToggleAll = useCallback(
        (usersIds?: string[]) => () => {
            resetChecked([]);
            if (!!usersIds?.length && numberOfChecked(usersIds) === usersIds.length) {
                setChecked(difference(checked, usersIds));
            } else {
                setChecked(union(checked, usersIds));
            }
        },
        [checked, numberOfChecked, resetChecked, setChecked]
    );

    const onShowMore = useCallback(() => {
        setPage(page + 1);
        setShownCount(shownCount + STEP_SIZE);
        setPaging(page + 1);
        fetchMembers && fetchMembers(page + 1);
    }, [fetchMembers, page, setPaging, shownCount]);

    const isHasMore = totalAmountUsers >= shownCount;

    return (
        <>
            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
                <Typography noWrap>{title}</Typography>
            </Box>
            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
                <Typography color="textSecondary" variant="body1">{`${users?.length} ${
                    isHasMore && totalAmountUsers ? `from ${totalAmountUsers} ` : ""
                } users`}</Typography>
                <Tooltip arrow placement="top" title={"When using Select All, this will only select those that are visible in the list."}>
                    <Button color="primary" onClick={handleToggleAll(usersIds)}>
                        <Box whiteSpace="nowrap">Select all</Box>
                    </Button>
                </Tooltip>
            </Box>
            <Paper className={classes.paper} id={type ?? ""}>
                <List dense component="div" role="list" className={classes.list}>
                    <InfiniteScroll
                        scrollableTarget={type ?? ""}
                        dataLength={shownUsers.length}
                        next={onShowMore}
                        hasMore={totalAmountUsers > shownCount}
                        loader={null}
                        hasChildren>
                        {users?.map((user, index) => (
                            <CustomListItem
                                key={`${index}${user.id}`}
                                user={user}
                                checked={checked}
                                handleToggle={handleToggle}
                                companyLevels={companyLevels}
                            />
                        ))}
                    </InfiniteScroll>
                </List>
            </Paper>
        </>
    );
};

export default CustomList;
