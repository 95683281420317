import * as React from "react";

function SvgReminderIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={14}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g fill="currentColor">
                <path d="M6.65 16.29C2.98 16.29 0 13.3 0 9.63s2.98-6.65 6.65-6.65c3.67 0 6.65 2.98 6.65 6.65 0 3.67-2.98 6.66-6.65 6.66zm0-12.06c-2.98 0-5.4 2.42-5.4 5.4 0 2.98 2.42 5.4 5.4 5.4 2.98 0 5.4-2.42 5.4-5.4 0-2.98-2.42-5.4-5.4-5.4z" />
                <path d="M6.65 10.26a.62.62 0 01-.44-.18.628.628 0 010-.88l2.21-2.21c.24-.24.64-.24.88 0s.24.64 0 .88l-2.21 2.21a.62.62 0 01-.44.18zM8.3 1.25H5.01a.625.625 0 010-1.25H8.3c.34 0 .62.28.62.62 0 .34-.28.63-.62.63z" />
            </g>
            <defs>
                <clipPath id="reminder-icon_svg__clip0">
                    <path fill="#fff" d="M0 0h13.3v16.29H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgReminderIcon;
