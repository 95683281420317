import React, { useEffect, useMemo, useState, ReactNode, useContext, useRef } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import clsx from "clsx";

import AvatarWithScore from "components/AvatarWithScore";
import {
    CoachingPlanType,
    CoachingSession,
    FcrCoachingSkill,
    FcrCoachingSkillHistory,
    MessageType,
    UserProfile,
} from "types";
import { ConfigurationContext, ScrollContext } from "context";
import { HEADER_HEIGHT, Routings, SizeEnum } from "types/constants";
import { getLastCoachingSession } from "services/coachingSessionServices";
import PerformanceMetrics, { PerformanceMetricsVariant } from "routes/Dashboard/DashboardContent/PerformanceMetrics";
import useLocationStateParam from "hooks/useLocationStateParam";

import TeamProfileSkills from "./TeamProfileSkills";
import TeamProfileSkillHistory from "./TeamProfileSkillHistory";
import LastCoachingSession from "./LastCoachingSession";
import TeamProfileMessages from "./TeamProfileMessages";
import TeamProfileCoaching from "./TeamProfileCoaching";
import CoachingReportHistory from "./CoachingReportHistory";
import MessageHistory from "./MessageHistory";
import CoachingPlanHistory from "./CoachingPlanHistory";
import TeamProfileCoachingPlans from "./TeamProfileCoachingPlans";
import CoachingDevelopmentHistory from "./CoachingDevelopmentHistory";
import useStyles from "./styles";

interface TeamProfileContentProps {
    user: UserProfile;
    isFcrCoachingSkillHistoryFetching?: boolean;
    isRecentMessagesFetching?: boolean;
    recentMessages: MessageType[];
    coachingSkills: FcrCoachingSkill[];
    fcrCoachingSkillHistory?: FcrCoachingSkillHistory[];
    onCoachingPlanChange: (newCoachingPlan: CoachingPlanType) => void;
    isSidebarVisible: boolean;
    onSidebarCollapse: () => void;
    navigation?: ReactNode;
}

const TeamProfileContent = ({
    user,
    isFcrCoachingSkillHistoryFetching = false,
    isRecentMessagesFetching = false,
    coachingSkills,
    fcrCoachingSkillHistory,
    recentMessages,
    onCoachingPlanChange,
    isSidebarVisible,
    onSidebarCollapse,
    navigation,
}: TeamProfileContentProps) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const lastSessionRef = useRef<HTMLDivElement>(null);
    const { scrollContainerRef } = useContext(ScrollContext);
    const isScrollIntoLastSession = !!useLocationStateParam("sessionId");
    const { currentUser } = useContext(ConfigurationContext);
    const [lastCoachingSession, setLastCoachingSession] = useState<CoachingSession | null | undefined>(null);
    const isLastCoachingSessionFetching = lastCoachingSession === null;

    const pathForRouting = useMemo(() => path === "/" ? "" : path, [path]);

    useEffect(() => {
        setLastCoachingSession(null);
        getLastCoachingSession(user.id).then(setLastCoachingSession);
    }, [user.id]);

    useEffect(() => {
        if (!isScrollIntoLastSession || !scrollContainerRef.current || !lastSessionRef.current) return;
        scrollContainerRef.current.scroll({
            top: lastSessionRef.current.offsetTop - HEADER_HEIGHT,
            behavior: "smooth",
        });
    }, [
        isScrollIntoLastSession,
        scrollContainerRef,
        isFcrCoachingSkillHistoryFetching,
        isRecentMessagesFetching,
        isLastCoachingSessionFetching,
    ]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            minWidth={0}
            minHeight="100vh"
            px={{ xs: 2, md: 3, lg: 6 }}
            pt={{ xs: 2, md: 3, lg: 4 }}
            className={clsx(classes.content, {
                [classes.sidebarCollapsed]: !isSidebarVisible,
            }
            )}
        >
            {!isSidebarVisible && (
                <Box mb={2}>
                    <Button disableRipple>
                        <Box display="flex" alignItems="center" onClick={onSidebarCollapse} m={1}>
                            <AvatarWithScore user={user} score={user.averageScore} size={SizeEnum.mediumSmaller} />
                            <Box fontWeight={500} ml={3}>
                                <Typography variant="h3">
                                    {user.firstName} {user.lastName}
                                </Typography>
                            </Box>
                        </Box>
                    </Button>
                </Box>
            )}
            {navigation && (
                <Box mb={{ xs: 2, md: 3, lg: 3.5 }}>{navigation}</Box>
            )}
            <Switch>
                <Route exact path={path}>
                    <>
                        <TeamProfileCoaching userData={user} />
                        <TeamProfileSkills fcrCoachingSkillHistory={fcrCoachingSkillHistory?.filter(x => x.fcrCoachingSkill.isActive)} />
                        {user.productSalesData && (
                            <Box mb={{ xs: 3, sm: 6 }}>
                                <PerformanceMetrics
                                    productSalesData={user.productSalesData}
                                    variant={PerformanceMetricsVariant.Paper}
                                    title={user.id === currentUser?.id ? "My Performance Metrics" : `${user.firstName}'s Performance Metrics`}
                                />
                            </Box>
                        )}
                        <Box {...{ ref: lastSessionRef }}>
                            <LastCoachingSession coachingSession={lastCoachingSession} coachingSkills={coachingSkills} />
                        </Box>
                        <TeamProfileCoachingPlans
                            isFcrCoachingSkillHistoryFetching={isFcrCoachingSkillHistoryFetching}
                            fcrCoachingSkillHistory={fcrCoachingSkillHistory}
                            onCoachingPlanChange={onCoachingPlanChange}
                        />
                        <TeamProfileMessages messages={recentMessages} isRecentMessagesFetching={isRecentMessagesFetching} />
                    </>
                </Route>
                <Route path={`${pathForRouting}${Routings.skills}`}>
                    <TeamProfileSkillHistory
                        isFcrCoachingSkillHistoryFetching={isFcrCoachingSkillHistoryFetching}
                        fcrCoachingSkillHistory={fcrCoachingSkillHistory}
                        onCoachingPlanChange={onCoachingPlanChange}
                    />
                </Route>
                <Route path={`${pathForRouting}${Routings.reports}`}>
                    <CoachingReportHistory />
                </Route>
                <Route path={`${pathForRouting}${Routings.messages}`}>
                    <MessageHistory user={user} />
                </Route>
                <Route path={`${pathForRouting}${Routings.coachingPlans}`}>
                    <CoachingPlanHistory user={user} />
                </Route>
                <Route path={`${pathForRouting}${Routings.coachingDevelopment}`}>
                    <CoachingDevelopmentHistory user={user} />
                </Route>
            </Switch>
        </Box>
    );
}

export default TeamProfileContent;