import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        back: {
            display: "inline-block",
        },
        navBarOpen: {
            cursor: "pointer",
        },
        header: {
            background: theme.palette.common.white,
            borderBottom: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        },
        date: {
            color: theme.palette.text.primary,
            borderRadius: "6px",
            background: theme.palette.info.main,
            cursor: "pointer",
        },
        avatar: {
            backgroundColor: (props: { id: string }) => theme.colors.avatar[parseInt(props.id[0], 16) % theme.colors.avatar.length],
        },
        xs: {
            width: 24,
            height: 24,
            fontSize: 14,
        },
    })
);

export default useStyles;
