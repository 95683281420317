import { initialUserProfileObj } from "helpers/userProfileHelper";
import { useCallback, useState } from "react";
import { CoachingPlanType } from "types";
import { CoachingPlanStatusEnum } from "types/constants";

const initialData: CoachingPlanType = {
    assignedDate: null,
    coachingPlanData: "",
    coachingSessionId: "",
    completedDate: null,
    createdById: "",
    createdDate: "",
    dueDate: "",
    fcrCoachingPlanStatusId: CoachingPlanStatusEnum.assigned,
    fcrCoachingSkillName: "",
    fcrCoachingSkillResultId: "",
    fcrResultId: "",
    id: "",
    inProgressDate: null,
    modifiedById: "",
    modifiedDate: "",
    reminderDate: null,
    resources: [],
    sendReminder: false,
    createdBy: { ...initialUserProfileObj },
    modifiedBy: { ...initialUserProfileObj },
}

export default function useCoachingPlanModal() {
    const [ data, setData ] = useState(initialData);
    const [ isShown, setIsShown ] = useState(false);

    const open = useCallback((newData: CoachingPlanType) => {
        setIsShown(true);
        setData(newData);
    }, []);

    const close = useCallback(() => {
        setIsShown(false);
        setData(initialData);
    }, [])
    
    return {
        data,
        isShown,
        open,
        close,
    }
}
