import React, { useMemo, useContext } from "react";
import round from "lodash/round";
import clsx from "clsx";
import { Box, Tooltip } from "@material-ui/core";
import { ConfigurationContext } from "context";

import useStyles from "./styles";

interface BarProps {
    score: number;
}

const Bar = ({ score }: BarProps) => {
    const classes = useStyles();
    const { fcrConfiguration } = useContext(ConfigurationContext);
    const barHeight = useMemo(() => score && score / 5, [score]);

    const barClass = useMemo(() => {
        switch (true) {
            case score < 2:
                return classes.barLowest;
            case score < 3:
                return classes.barLow;
            case score < 4:
                return classes.barMedium;
            default:
                return classes.barHigh;
        }
    }, [score, classes]);

    return (
        <Box
            height="85px"
            width="10px"
            position="relative"
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
        >
            <Tooltip 
                title={round(score, 1)} 
                arrow 
                placement="top" 
                disableHoverListener={fcrConfiguration?.hideSkillRatingNumbers} 
                enterTouchDelay={0}
            >
                <Box
                    position="absolute"
                    bottom={0}
                    width={1}
                    height={barHeight}
                    borderRadius={14}
                    className={clsx(classes.defaultBar, barClass)}
                />
            </Tooltip>
        </Box>
    );
};

export default Bar;