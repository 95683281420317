import React, { useCallback } from "react";
import authService from "services/authService";

import AuthPage from "../AuthPage";

/** This is the page B2C redirect the user to once there is a session and state in the url. */
const SignIn = () => {
    if (authService.isAuthenticated()) {
        // Use shouldn't land here when authentiated something went wrong show warning
        console.warn("Something went wrong in code as user was redirected to sign in when they're authenticated.");
    }
    return <AuthPage isLoading={true} heading="Logging In" message="Signing you into OmniCoach" />;
};
export default SignIn;
