import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        borderRadius: 4,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
        },
    },
    messageCell: {
        whiteSpace: "nowrap",
    },
}));

export default useStyles;
