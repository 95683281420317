import React, { useContext, useCallback, useState, useMemo } from "react";
import { CompaniesContext } from "context";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Button, Typography, Grid, IconButton, FormHelperText } from "@material-ui/core";

import { PlusIcon, Menu } from "svgComponents";
import { Company, HeaderProps } from "types";
import { AdminRoutings } from "types/constants";

import { CompaniesSkeleton } from "./CompaniesSkeleton";
import useStyles from "./styles";

const STEP_SIZE = 20;

export default function Companies({ isBarOpen, navBarOpen }: HeaderProps) {
    const classes = useStyles();
    const history = useHistory();
    const { allCompanies, setCurrentCompany } = useContext(CompaniesContext);
    const [shownNumb, setShownNumb] = useState(STEP_SIZE);

    const onShowMore = useCallback(
        () => setShownNumb(shownNumb + STEP_SIZE),
        [shownNumb]
    );

    const shownCompanies = useMemo(
        () => allCompanies.slice(0, shownNumb), 
        [allCompanies, shownNumb]
    );

    const handleEditCompany = useCallback(
        (company: Company) => {
            setCurrentCompany(company);
            history.push(AdminRoutings.company);   
        },
        [history, setCurrentCompany]
    );

    const handleCreateNewCompany = useCallback(
        () => {
            setCurrentCompany(null);
            history.push(AdminRoutings.company);   
        },
        [history, setCurrentCompany]
    );

    return (
        <>
            <Box
                mb={5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                minHeight="46px"
            >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    {!isBarOpen && (
                        <IconButton edge="start" disableRipple onClick={() => navBarOpen()}>
                            <Menu />
                        </IconButton>
                    )}
                    <Typography variant="h1">Companies</Typography>
                </Box>
                <Box display="flex">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        startIcon={<PlusIcon />}
                        onClick={handleCreateNewCompany}
                    >
                        <Box lineHeight="30px">New Company</Box>
                    </Button>
                </Box>
            </Box>
            {allCompanies.length ? (
                <Box m={-1}>
                    <InfiniteScroll
                        scrollableTarget="app"
                        dataLength={shownCompanies.length}
                        next={onShowMore}
                        hasMore={allCompanies.length > shownNumb}
                        loader={<></>}
                    >
                        <Box p={1}>
                            <Grid container spacing={2}>
                                {shownCompanies.map((company) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={company.id} className={classes.card}>
                                        <Box
                                            className={classes.content}
                                            justifyContent={company.logo ? "space-between" : "center"}
                                            onClick={() => handleEditCompany(company)}
                                        >
                                            {company.logo && (
                                                <Box flex={1} alignItems="center" display="flex">
                                                    <img src={company.logo} alt={company.name} />
                                                </Box>
                                            ) }
                                            {company.name && (
                                                <Box mt={company.logo ? 2 : 0}>
                                                    <Typography variant="h2">{company.name}</Typography>
                                                </Box>
                                            )}
                                            {!company.isActive && (
                                                <FormHelperText error>Deactivated</FormHelperText>
                                            )}
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </InfiniteScroll>
                </Box >
            ) : (
                <Grid container spacing={2}>
                    <CompaniesSkeleton />
                </Grid>
            )}
        </>
    );
}
