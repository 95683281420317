import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ConfigurationContext } from "context";
import SummaryCardSessions from "components/SummaryCard/SummaryCardSessions";
import SummaryCardGoals from "components/SummaryCard/SummaryCardGoals";
import SummaryCardFocus from "components/SummaryCard/SummaryCardFocus";
import SummaryCardPlans from "components/SummaryCard/SummaryCardPlans";
import { CoachingHistory } from "types/CoachHistory";
import { COACHING_PERIOD_OPTS } from "hooks/useCoachingPeriod";
import { calcScore } from "helpers/coachingHistoryHelper";
import { CoachingPeriodEnum } from "types/constants";

import SummaryLayout from "../SummaryLayout";
import useSummary from "../useSummary";

interface TeamSummaryProps {
    data?: CoachingHistory[];
    currentDate: Date;
    teamMode: boolean;
    isFetching?: boolean;
}

const TeamSummary = ({ data = [], currentDate, teamMode, isFetching = false }: TeamSummaryProps) => {
    const { fcrSectionConfiguration } = useContext(ConfigurationContext);

    const { period, periodLabel, curHistory, prevHistory, setPeriod, setHistory } = useSummary(data, currentDate);

    useEffect(() => setHistory(data), [data, setHistory]);

    const handlePeriodChange = useCallback((event: React.ChangeEvent<{ name?: string; value: CoachingPeriodEnum }>) =>
        setPeriod(event.target.value), [setPeriod]);

    const sessions = useMemo(() => calcScore(curHistory, prevHistory, "teamCoachingSessions"), [curHistory, prevHistory]);
    const goals = useMemo(() => calcScore(curHistory, prevHistory, "teamDevelopmentGoalsInitiated"), [curHistory, prevHistory]);
    const focus = useMemo(() => calcScore(curHistory, prevHistory, "teamSkillsAverageRating", 2), [curHistory, prevHistory]);
    const plans = useMemo(() => calcScore(curHistory, prevHistory, "teamCoachingPlansCompleted"), [curHistory, prevHistory]);

    return (
        <SummaryLayout
            period={period}
            options={COACHING_PERIOD_OPTS}
            onPeriodChange={handlePeriodChange}
            title={teamMode ? "Team Development" : "My Team Development"}
        >
            <SummaryCardSessions
                signature={periodLabel}
                label="Coaching Sessions"
                score={sessions.total}
                isIncreased={sessions.isIncreased}
                isFetching={isFetching}
                tooltip="This is the total number of coaching sessions completed for your team"
                type="ghost"
            />
            <SummaryCardGoals
                signature={periodLabel}
                label={fcrSectionConfiguration?.section1Label}
                subLabel="Reports Initiated"
                score={`${goals.score}%`}
                isIncreased={goals.isIncreased}
                isFetching={isFetching}
                tooltip={
                    teamMode
                        ? "This is the % of completed coaching reports initiated by those being coached within your teams"
                        : "This is the % of completed coaching reports initiated by your team"
                }
                type="ghost"
            />
            <SummaryCardFocus
                signature={periodLabel}
                label={fcrSectionConfiguration?.section2Label}
                subLabel="Skill Development"
                score={focus.dynamic}
                isIncreased={focus.isIncreased}
                isFetching={isFetching}
                tooltip="This is the increase or decrease in your team's overall average skill rating"
                type="ghost"
            />
            <SummaryCardPlans
                signature={periodLabel}
                label={fcrSectionConfiguration?.section3Label}
                subLabel="Plans Completed"
                score={`${plans.score}%`}
                isIncreased={plans.isIncreased}
                isFetching={isFetching}
                tooltip="This is the % of assigned coaching plans completed by your team"
                type="ghost"
            />
        </SummaryLayout>
    )
}

export default TeamSummary;