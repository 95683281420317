import { lighten, Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formInput: {
        "& .MuiInputBase-root": {
            backgroundColor: theme.palette.common.white,
        },
    },
    createRatingButton: {
        border: `1px dashed ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(0.75),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.main, 0.95),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(1),
        cursor: "pointer",
    },
    btnDelete: {
        padding: "5px",

        "& svg": {
            "& path": {
                fill: theme.palette.text.secondary,
            },
        },
    },
}));

export default useStyles;
