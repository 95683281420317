import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        },
        contentSubmitted: {
            borderRadius: "8px",
        },
    })
);

export default useStyles;
