import { CoLeaderProfile, Team, TeamMember, UserProfile } from "types";

import API from "./apiService";

// Team
export const getTeam = async (teamId: Team["id"]): Promise<Team> => {
    const result = await API.get(`/team/${teamId}`);
    return result.data;
};

export const updateTeam = async (team: Team): Promise<boolean> => {
    const result = await API.post(`/team/${team.id}`, team);
    return result.data;
};

export const updateTeamMembers = async (teamId: Team["id"], userIds: Array<UserProfile["id"]>): Promise<boolean> => {
    const result = await API.post(`/team/${teamId}/updateTeamMembers`, userIds);
    return result.data;
};

export const updateCoLeaders = async (
    teamId: Team["id"],
    coLeaders: Pick<CoLeaderProfile, "id" | "hasCoachingReportReadAccess">[]
): Promise<boolean> => {
    const result = await API.post(`/team/${teamId}/updateCoLeaders`, coLeaders);
    return result.data;
};

export const getAllCompanyTeams = async (companyId: string): Promise<Team[]> => {
    const result = await API.get(`/team/getForCompany/${companyId}`);
    return result.data;
}

export const getTeamByMemberId = async (teamMemberId: UserProfile["id"]): Promise<Team> => {
    const result = await API.get(`/team/getByTeamMemberId/${teamMemberId}`);
    return result.data;
}

export const getTeamByLeaderId = async (companyId: UserProfile["id"], leaderId: UserProfile["id"]): Promise<Team[]> => {
    const result = await API.get(`/team/getByLeaderId/${companyId}?leaderId=${leaderId}`);
    return result.data;
}

// TeamMember
export const getTeamMembersById = async (teamId: string): Promise<TeamMember[]> => {
    const result = await API.get(`/TeamMember/${teamId}/getTeamMembers`);
    return result.data;
}
