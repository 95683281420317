import React, { useMemo } from "react";
import { Box, BoxProps } from "@material-ui/core";
import { SizeEnum } from "types/constants";

interface IconBoxProps extends BoxProps {
    icon: React.ReactNode;
    size?: SizeEnum;
}

const IconBox = ({ icon, size = SizeEnum.large, ...props }: IconBoxProps) => {
    const dimensions = useMemo(() => {
        switch (size) {
            case SizeEnum.large:
                return "56px";
            default:
                return "32px";
        }
    }, [size]);

    return (
        <Box
            {...props}
            height={dimensions}
            width={dimensions}
            display="flex"
            flexShrink={0}
            borderRadius={4}
        >
            <Box display="flex" m="auto">
                {icon}
            </Box>
        </Box>
    );
}

export default IconBox;