import * as React from "react";

function SvgActionable(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Delete" fill="currentColor">
                    <path
                        d="M8,0 C9.30625206,0 10.4175144,0.834850101 10.8293257,2.00008893 L15,2 L15,3 L13.914,3 L12.8783942,15.0854011 C12.8340555,15.6026853 12.4012285,16 11.8820475,16 L4.11795246,16 C3.59877153,16 3.16594446,15.6026853 3.12160581,15.0854011 L2.086,3 L1,3 L1,2 L5.17067428,2.00008893 C5.58248558,0.834850101 6.69374794,0 8,0 Z M12.91,3 L3.089,3 L4.11795246,15 L11.8820475,15 L12.91,3 Z M6.5,5 L7,13 L6,13 L5.5,5 L6.5,5 Z M10.5,5 L10,13 L9,13 L9.5,5 L10.5,5 Z M8,1 C7.29709214,1 6.67887693,1.36261235 6.32221236,1.91097906 L6.268,2 L9.731,2 L9.68753208,1.9257146 C9.35707385,1.40803354 8.79495551,1.05356747 8.15019106,1.00557069 L8,1 Z"
                        id="Combined-Shape"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgActionable;
