import * as React from "react";

export default function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="Email" stroke="none" strokeWidth="1" fill="currentColor">
                <path d="M16,2 L16,12 C16,13.1045695 15.1045695,14 14,14 L2,14 C0.8954305,14 0,13.1045695 0,12 L0,2 L16,2 Z M15,3 L1,3 L1,12 C1,12.5128358 1.38604019,12.9355072 1.88337887,12.9932723 L2,13 L14,13 C14.5128358,13 14.9355072,12.6139598 14.9932723,12.1166211 L15,12 L15,3 Z M12.6876525,4.6095656 L13.3123475,5.3904344 L8,9.64031242 L2.68765248,5.3904344 L3.31234752,4.6095656 L8,8.359 L12.6876525,4.6095656 Z" />
            </g>
        </svg>
    );
}
