import React, { useCallback } from "react";
import { useField } from "formik";
import { Box, InputAdornment } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";

import SvgCalendar from "svgComponents/Calendar";

import useStyles from "./styles";

interface FormDatePickerProps {
    name: string;
}

const FormDatePicker = ({ name }: FormDatePickerProps) => {
    const classes = useStyles();
    const [field, meta, helpers] = useField<string>(name);
    const handleChange = useCallback((date) => helpers.setValue(date.toDate()), [helpers]);

    return (
        <MuiPickersUtilsProvider utils={DayJsUtils}>
            <DatePicker
                value={field.value}
                onChange={handleChange}
                variant="inline"
                name={name}
                inputVariant="outlined"
                format="MMMM D, YYYY"
                fullWidth
                autoOk
                className={classes.picker}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height={32}
                                width={32}
                                borderRadius="50%"
                                bgcolor="#F6F8F9"
                                mr={0.5}>
                                <SvgCalendar />
                            </Box>
                        </InputAdornment>
                    ),
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default FormDatePicker;
