import React, { useMemo } from "react";
import { Box, BoxProps, Typography } from "@material-ui/core";

import { formatMonthNumber } from "helpers/dateHelpers";

import Bar from "./Bar";

interface BarWrapperProps extends BoxProps {
    monthScore: {
        month: number;
        year?: number;
        averageScore?: number;
    };
    isLabelShown?: boolean;
}

const BarWrapper = ({ monthScore, isLabelShown = false, ...props }: BarWrapperProps) => {
    const { averageScore, month } = monthScore;
    const monthName = useMemo(() => formatMonthNumber(month), [month]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            minWidth="25px"
            {...props}
        >
            <Bar score={averageScore || 0} />
            <Box mt={2} visibility={isLabelShown ? "visible" : "hidden"}>
                <Typography variant="caption">
                    {monthName}
                </Typography>
            </Box>
        </Box>
    );
};

export default BarWrapper;