import { makeStyles } from "@material-ui/core"; 

const useStyles = makeStyles(theme => ({
    window: {
        maxWidth: theme.spacing(90),
        [theme.breakpoints.up("md")]: {
            width: theme.spacing(90),
        },
    },
    headline: {
        color: theme.palette.text.secondary,
        fontWeight: 500,
    },
    resources: {
        listStyle: "none",
    },
    info: {
        flexWrap: "nowrap",
        minHeight: theme.spacing(7),
    },
    infoIcon: {
        backgroundColor: theme.palette.info.dark,
        borderRadius: "50%",
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    history: {
        backgroundColor: theme.colors.structure.pageBackground,
    },
    historyRecords: {
        listStyle: "none",
    },
    viewLink: {
        color: theme.palette.primary.main,
    },
    close: {
        minWidth: 0,
    },
    expired: {
        color: theme.palette.error.main,
    },
    alignLeft: {
        marginRight: "auto",
    },
    alignRight: {
        marginLeft: "auto",
    },
}));

export default useStyles;
