import React, { useState } from "react";
import { Box, FormControlLabel, FormGroup, Grid, Typography } from "@material-ui/core";
import { FormInput, FormSwitcher, FormMultilineInput, FormSelect } from "components/Form";

import PaperCard from "components/PaperCard";
import { SelectItemProps } from "components/Form/FormSelect";
import { EvaluationFormProps, QuestionBasedForm } from "types/Certification";
import { useFormikContext } from "formik";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";

const answerFormatOptions: SelectItemProps[] = [
    {
        id: QuestionBasedForm.Flagged,
        title: "Flagged Answers",
    },
    {
        id: QuestionBasedForm.Aggregated,
        title: "Aggregated Answers",
    },
];

interface EvaluationComponentFormProps {
    questionBasedFormNotice: React.ReactFragment | undefined;
}

export default function EvaluationForm({ questionBasedFormNotice }: EvaluationComponentFormProps) {
    const formik = useFormikContext<EvaluationFormProps>();
    const [questionBasedFormChanged, setQuestionBasedFormChanged] = useState<boolean>(false);

    const questionBasedFormChangedHandler: SelectInputProps["onChange"] = (e) => {
        setQuestionBasedFormChanged(true);
        formik.setFieldValue("questionBasedForm", e.target.value);
    };

    return (
        <PaperCard>
            <FormGroup>
                <Box mb={3} ml="11px">
                    <FormControlLabel control={<FormSwitcher name="isActive" />} label={<Typography>Active</Typography>} />
                </Box>
                <Box mb={2}>
                    <FormInput name="name" label="Form Name" />
                </Box>
                <Box mb={3}>
                    <FormMultilineInput placeholder="Form Description" name="description" />
                </Box>
                <Box mb={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormSelect
                                name="questionBasedForm"
                                disabled={formik.values.isInUse}
                                label="Answer Format"
                                options={answerFormatOptions}
                                onChange={questionBasedFormChangedHandler}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" alignItems="center" minHeight={46}>
                                {formik.values.questionBasedForm === QuestionBasedForm.Flagged ? (
                                    <Typography variant="caption">
                                        Element value fields indicate correct answers (1) and incorrect answers (0).
                                    </Typography>
                                ) : (
                                    <Typography variant="caption">Element value fields will be aggregated for scoring.</Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {questionBasedFormNotice && questionBasedFormChanged && <Box>{questionBasedFormNotice}</Box>}
            </FormGroup>
        </PaperCard>
    );
}
