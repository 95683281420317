import * as React from "react";

function SvgTeamMember(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={6}
            height={6}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.26 5.88H.62C.28 5.88 0 5.6 0 5.26V.62C0 .28.28 0 .62 0h4.63c.35 0 .63.28.63.62v4.63c0 .35-.28.63-.62.63zM1.25 4.63h3.38V1.25H1.25v3.38z"
                fill="#7C8EA3"
            />
        </svg>
    );
}

export default SvgTeamMember;
