import React, { forwardRef, useState, useCallback, useMemo, useImperativeHandle, ForwardedRef } from "react";
import { Grid, Box, Typography, Button, GridDirection, CircularProgress } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import { CompanyLevel } from "types";
import { TickIcon } from "svgComponents";
import { FormAutocompleteInput } from "components/Form";
import { LEVEL_FILTER_TITLE, LEVEL_FILTER_CAPTION } from "types/constants";
import {
    COMPANY_LEVELS,
    DEFAULT_LEVEL_HIERARCHY,
    getLabelsByLevelNum,
    getLevelsById,
    getLevelsIdsByLevelNum,
    getLevelsChildrenIds,
    buildLevelHierarchyToLevel,
    isEmptyLevelHierarchy,
    LevelHierarchy,
} from "helpers/companyHelper";

type FilterResult<Multiple> = Multiple extends false | undefined ? CompanyLevel["id"] | null : Array<CompanyLevel["id"]>;

interface LevelFilterProps<Multiple extends boolean | undefined> {
    isSubmitting?: boolean;
    multiple?: Multiple;
    title?: string;
    caption?: string | React.ReactNode;
    direction?: GridDirection;
    submitOnChange?: boolean;
    companyLevels: CompanyLevel[];
    initialLevelId?: CompanyLevel["id"] | null;
    onFilter: (levelId: FilterResult<Multiple>) => void;
    onLevelChangeCallback?: () => void;
}

export interface LevelFilterRef {
    reset: () => void;
    resetPreviousFilteredId: () => void;
}

const ALL_LEVELS_ID = "all";

const LevelFilter = forwardRef(
    <Multiple extends boolean | undefined = undefined>(
        {
            isSubmitting,
            multiple,
            title = LEVEL_FILTER_TITLE,
            caption = LEVEL_FILTER_CAPTION,
            direction = "column",
            submitOnChange = false,
            companyLevels,
            initialLevelId,
            onFilter,
            onLevelChangeCallback = () => {
                false;
            },
        }: LevelFilterProps<Multiple>,
        ref: ForwardedRef<LevelFilterRef>
    ) => {
        const [previousFilteredIds, setPreviousFilteredIds] = useState<Array<CompanyLevel["id"]>>([]);

        const levels = useMemo(
            () => ({
                itemsById: getLevelsById(companyLevels),
                itemsIdsByLevelNum: getLevelsIdsByLevelNum(companyLevels),
                itemsChildrenIdsById: getLevelsChildrenIds(companyLevels),
                labelsByLevelNum: getLabelsByLevelNum(companyLevels),
            }),
            [companyLevels]
        );

        const getFilteredIds = useCallback(
            (levelHierarchy: LevelHierarchy) =>
                COMPANY_LEVELS.reduce(
                    (previousFilteredIds: Array<CompanyLevel["id"]>, levelNum: CompanyLevel["level"]): Array<CompanyLevel["id"]> => {
                        const levelId = levelHierarchy[levelNum];

                        if (levelId === ALL_LEVELS_ID) {
                            return [ALL_LEVELS_ID, ...Array.from(levels.itemsIdsByLevelNum.get(levelNum) ?? [])];
                        } else {
                            return levelId ? [levelId] : previousFilteredIds;
                        }
                    },
                    []
                ),
            [levels.itemsIdsByLevelNum]
        );

        const handleSubmit = useCallback(
            (values) => {
                const filteredIds = getFilteredIds(values);
                setPreviousFilteredIds(filteredIds);
                onFilter((multiple ? filteredIds : filteredIds[0] ?? null) as FilterResult<Multiple>);
            },
            [multiple, getFilteredIds, setPreviousFilteredIds, onFilter]
        );

        const getDefaultInitialValues = useCallback((): LevelHierarchy => {
            const firstLevelNum = COMPANY_LEVELS[0];
            const [firstLevelId] = levels.itemsIdsByLevelNum.get(firstLevelNum) ?? [DEFAULT_LEVEL_HIERARCHY[firstLevelNum]];

            return {
                ...DEFAULT_LEVEL_HIERARCHY,
                [firstLevelNum]: firstLevelId,
            };
        }, [levels]);

        const getInitialValuesByInitialLevelId = useCallback(
            (initialLevelId: CompanyLevel["id"]) => {
                const initialLevelHierarchy = buildLevelHierarchyToLevel(initialLevelId, companyLevels);
                return isEmptyLevelHierarchy(initialLevelHierarchy) ? getDefaultInitialValues() : initialLevelHierarchy;
            },
            [companyLevels, getDefaultInitialValues]
        );

        const initialValues = useMemo<LevelHierarchy>(() => {
            if (initialLevelId === null) return DEFAULT_LEVEL_HIERARCHY;
            return initialLevelId ? getInitialValuesByInitialLevelId(initialLevelId) : getDefaultInitialValues();
        }, [initialLevelId, getDefaultInitialValues, getInitialValuesByInitialLevelId]);

        const formik = useFormik<LevelHierarchy>({
            enableReinitialize: true,
            initialValues: initialValues,
            onSubmit: handleSubmit,
        });

        const resetLevels = (from: CompanyLevel["level"] = COMPANY_LEVELS[0]) =>
            COMPANY_LEVELS.slice(COMPANY_LEVELS.indexOf(from) + 1).forEach((level) => formik.setFieldValue(level.toString(), null));

        const resetPreviousFilteredId = () => setPreviousFilteredIds([]);

        const handleChange = (levelId: CompanyLevel["id"] | null, levelNum: CompanyLevel["level"]) => {
            resetLevels(levelNum);
            if (!levels.itemsIdsByLevelNum.get(levelNum + 1)?.size) {
                onLevelChangeCallback();
            }
            if (submitOnChange) formik.submitForm();
        };

        const reset = () => {
            resetLevels();
            resetPreviousFilteredId();
        };

        useImperativeHandle(ref, () => ({
            reset,
            resetPreviousFilteredId,
        }));

        const sortOptions = (options: CompanyLevel["id"][]) => sortBy(options, (levelId) => levels.itemsById.get(levelId)!.name);

        const getSortedLevelIds = (levelNum: number) => {
            const levelIndex = COMPANY_LEVELS.indexOf(levelNum);

            if (levelIndex === 0) {
                return sortOptions(Array.from(levels.itemsIdsByLevelNum.get(levelNum) ?? []));
            } else {
                const previousSelectedLevelId = formik.values[COMPANY_LEVELS[levelIndex - 1]];
                return previousSelectedLevelId
                    ? sortOptions(Array.from(levels.itemsChildrenIdsById.get(previousSelectedLevelId) ?? []))
                    : [];
            }
        };

        const getOptions = (levelNum: number) => {
            const levelIndex = COMPANY_LEVELS.indexOf(levelNum);
            const sortedLevelIds = getSortedLevelIds(levelNum);
            const previousSelectedLevelId = formik.values[COMPANY_LEVELS[levelIndex - 1]];
            const levelOptions = multiple ? [ALL_LEVELS_ID, ...sortedLevelIds] : sortedLevelIds;

            if (previousSelectedLevelId === ALL_LEVELS_ID) {
                return [ALL_LEVELS_ID];
            }

            if (!previousSelectedLevelId && levelIndex > 0) {
                return [];
            }

            return levelOptions;
        };

        const renderLevelsSelects = () =>
            COMPANY_LEVELS.map((levelNum) => {
                const label = levels.labelsByLevelNum.get(levelNum);

                if (!label) return;

                const getOptionLabel = (levelId: CompanyLevel["id"]) => {
                    if (levelId === ALL_LEVELS_ID) {
                        return `All ${levels.labelsByLevelNum.get(levelNum)?.name ?? ""} Level Members`;
                    } else {
                        return levels.itemsById.get(levelId)?.name ?? "";
                    }
                };

                return (
                    <Grid key={label.id} item xs>
                        <FormAutocompleteInput<CompanyLevel["id"]>
                            name={levelNum.toString()}
                            label={label.name}
                            options={getOptions(levelNum)}
                            getOptionLabel={getOptionLabel}
                            onChange={(levelId) => handleChange(levelId, levelNum)}
                        />
                    </Grid>
                );
            });

        return (
            <>
                {title && (
                    <Box mb={2} fontWeight={600}>
                        <Typography variant="h2">{title}</Typography>
                    </Box>
                )}
                {caption && (
                    <Box mb={3}>
                        <Typography variant="caption">{caption}</Typography>
                    </Box>
                )}
                <Box
                    position="relative"
                    style={{
                        opacity: isSubmitting ? 0.6 : 1,
                    }}>
                    <FormikProvider value={formik}>
                        <Form>
                            <Grid direction={direction} spacing={2} container>
                                {renderLevelsSelects()}
                                {!submitOnChange && (
                                    <Grid item xs>
                                        <Button
                                            fullWidth
                                            disabled={isEqual(previousFilteredIds, getFilteredIds(formik.values))}
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            startIcon={<TickIcon />}>
                                            <Typography noWrap>
                                                <Box lineHeight="30px">Apply Filter</Box>
                                            </Typography>
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    </FormikProvider>
                    {isSubmitting && (
                        <Box
                            top="0"
                            left="0"
                            bottom="0"
                            width="100%"
                            position="absolute"
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <CircularProgress size={24} />
                        </Box>
                    )}
                </Box>
            </>
        );
    }
);

export default LevelFilter as <Multiple extends boolean | undefined = undefined>(
    props: LevelFilterProps<Multiple> & { ref?: ForwardedRef<LevelFilterRef> }
) => JSX.Element;
