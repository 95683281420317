import * as React from "react";

function SvgBusinessPlanning(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={20}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                fill="currentColor"
            >
                <path d="M11.14 10.08h3.3c.34 0 .62-.28.62-.62 0-.34-.28-.62-.62-.62h-3.3c-.34 0-.62.28-.62.62 0 .34.27.62.62.62zM11.14 7.54h3.3c.34 0 .62-.28.62-.62 0-.34-.28-.62-.62-.62h-3.3c-.34 0-.62.28-.62.62 0 .34.28.62.62.62z" />
                <path d="M18.55 0H.62C.28 0 0 .28 0 .62v2.67c0 .35.28.62.62.62h.56v8.31c0 .87.73 1.58 1.62 1.58h6.15v.86a1.53 1.53 0 00.62 2.93c.84 0 1.53-.69 1.53-1.53 0-.62-.37-1.16-.91-1.4v-.86h6.15c.9 0 1.62-.71 1.62-1.58v-8.3h.56c.34 0 .62-.28.62-.62V.62c.04-.34-.24-.62-.59-.62zM9.59 16.34a.28.28 0 01-.28-.28c0-.15.13-.28.28-.28.15 0 .28.13.28.28 0 .15-.12.28-.28.28zm7.15-4.12c0 .18-.17.33-.38.33H2.81c-.2 0-.38-.15-.38-.33v-8.3h14.3v8.3h.01zm1.19-9.55H1.25V1.25h16.68v1.42z" />
                <path d="M6.61 10.53a2.5 2.5 0 000-5 2.5 2.5 0 000 5zm0-3.74c.69 0 1.25.56 1.25 1.25s-.57 1.24-1.25 1.24-1.25-.56-1.25-1.24c0-.68.56-1.25 1.25-1.25z" />
            </g>
        </svg>
    );
}

export default SvgBusinessPlanning;
