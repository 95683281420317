import { SortDirectionEnum } from "types/constants";

export const CLASSES_STEP_SIZE = 50;
export const THROTTLE_CLIENT_SIDE_SCROLL_LOADING_SIMULATION = 1000;
export const DEFAULT_CLASS_NAME_FILTER_VALUE = "";

export type AllScheduledClassesFilter = {
    className: string;
    numberOfItems: number;
};
export interface AllScheduledClassesSorting {
    dateSortDirection: SortDirectionEnum;
}

export const defaultAllSchedClassesSortDirection: AllScheduledClassesSorting = {
    dateSortDirection: "desc" as SortDirectionEnum,
};

// These are shared between all my classes and all scheduled classes
export const defaultAllClassesSortDirection: AllScheduledClassesSorting = {
    dateSortDirection: "desc" as SortDirectionEnum,
};

export const defaultAllClassesFilters: AllScheduledClassesFilter = {
    className: DEFAULT_CLASS_NAME_FILTER_VALUE,
    numberOfItems: CLASSES_STEP_SIZE,
};
