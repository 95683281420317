import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(5),
    },
    slide: {
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
        boxSizing: "border-box",
    },
    slideActive: {
        "& button": {
            borderColor: theme.colors.identity.teal,
        },
    },
    content: {
        minHeight: "80px",
        width: "100%",
        border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        borderRadius: "6px",
        transition: ".3s",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "none",
        padding: "8px",

        "&:hover": {
            cursor: "pointer",
            transition: ".3s",
            borderColor: theme.colors.identity.teal,
        },

        "& img": {
            maxWidth: "100%",
            maxHeight: "62px",
        },
    },
    sliderBtn: {
        minWidth: "40px",
        border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        width: "40px",
        height: "40px",
        background: "none",
        transition: ".3s",
        position: "relative",
        zIndex: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& + button": {
            marginLeft: "-1px",
        },
        
        "&:hover": {
            zIndex: 1,
            cursor: "pointer",
            transition: ".3s",
            borderColor: theme.colors.identity.teal,
        },
    },
    sliderLeftBtn: {
        borderRadius: "6px 0 0 6px",
    },

    sliderRightBtn: {
        borderRadius: "0 6px 6px 0",
    },
    skeleton: {
        border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        borderRadius: "6px",
    },
}));

export default useStyles;
