import React, { useCallback } from "react";
import { Box, Button, Dialog, Typography, useTheme } from "@material-ui/core";
import LoadingButton from "components/LoadingButton";

interface ConfirmationModalProps {
    isOpen: boolean;
    header?: string;
    body?: string | React.ReactNode;
    isLoading?: boolean;
    loadingLabel?: string;
    confirmBtn?: string;
    cancelBtn?: string;
    onConfirm?: () => void;
    onClose?: () => void;
}

function ConfirmationModal({
    isOpen,
    header,
    body,
    isLoading,
    loadingLabel,
    confirmBtn = "Confirm",
    cancelBtn = "Cancel",
    onConfirm,
    onClose,
}: ConfirmationModalProps) {
    const theme = useTheme();

    const handleClose = useCallback((_, reason) => {
        const isCloseWhileLoading = (isLoading && reason === "backdropClick");
        if (typeof onClose !== "function" || isCloseWhileLoading) return;
        onClose();
    }, [isLoading, onClose]);

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth>
            {header && (
                <Box px={4} py={4}>
                    <Typography variant="h1">{header}</Typography>
                </Box>
            )}
            {body && (
                <Box px={4} py={4} bgcolor={theme.colors.structure.pageBackground}>
                    <Typography variant="body1">
                        {body}
                    </Typography>
                </Box>
            )}
            <Box display="flex" justifyContent="flex-end" px={4} py={3}>
                <LoadingButton variant="contained" color="primary" size="large" loading={isLoading} loadingLabel={loadingLabel} disabled={isLoading} onClick={onConfirm}>
                    {confirmBtn}
                </LoadingButton>
                <Box pl={2} color="text.secondary">
                    <Button variant="outlined" color="inherit" size="large" onClick={onClose} disabled={isLoading}>
                        <Box component="span" lineHeight="30px">
                            {cancelBtn}
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default ConfirmationModal;