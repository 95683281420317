import React, { useCallback, useMemo, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { useField } from "formik";

import useStyles from "./styles";
import fallbackAvatar from "./fallback.jpg"

interface FormAvatarInputProps {
    initialAvatarUrl?: string, // url
    name?: string,
}

export default function FormAvatarInput({ initialAvatarUrl, name = "" }: FormAvatarInputProps) {
    const baseAvatarUrl = initialAvatarUrl || fallbackAvatar;
    const classes = useStyles();
    const [, , helpers] = useField<File>(name);
    const [avatarBase64, setAvatarBase64] = useState<string>("");
    const avatarUrl = useMemo(() => avatarBase64 || baseAvatarUrl, [avatarBase64, baseAvatarUrl]);
    const urlFileReader = useMemo(() => {
        const reader = new FileReader();
        // eslint-disable-next-line functional/immutable-data
        reader.onloadend = () => {
            const value = typeof reader.result === "string" ? reader.result : baseAvatarUrl;
            setAvatarBase64(value);
        }
        return reader;
    }, [baseAvatarUrl]);
    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        const file = event.target.files?.[0] ?? null;
        if (!file) return;
        helpers.setValue(file);
        urlFileReader.readAsDataURL(file);
    }, [helpers, urlFileReader])

    return (
        <Box position="relative" pt={30} width={220}>
            <label>
                <input
                    accept=".png,.jpg,.jpeg"
                    className={classes.field}
                    name={name}
                    type="file"
                    onChange={handleChange}
                />
                <Box
                    borderRadius="50%"
                    className={classes.previewBox}
                    component="span"
                    display="block"
                    left="50%"
                    position="absolute"
                    top={0}
                >
                    <img className={classes.previewImg} src={avatarUrl} />
                </Box>
                <Box component="span">
                    <Button
                        color="primary"
                        component="span"
                        size="large"
                        variant="outlined"
                        fullWidth
                    >
                        <Box component="span" lineHeight="30px">
                            Upload Profile Image
                        </Box>
                    </Button>
                </Box>
            </label>
        </Box>
    )
}