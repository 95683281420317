import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Box } from "@material-ui/core";
import { UserProfile } from "types";
import { HEADER_HEIGHT } from "types/constants";
import useLocationStateParam from "hooks/useLocationStateParam";
import { ScrollContext } from "context";

import TeamOverview from "./TeamOverview";
import SkillsOverview from "./SkillsOverview";
import UpcomingSessions from "./UpcomingSessions";
import SummaryOverview from "./SummaryOverview";
import PerformanceMetrics from "./PerformanceMetrics";

interface DashboardContentProps {
    user: UserProfile;
    teamMode: boolean;
}

const CHILDREN_COUNT = 4;

const DashboardContent = ({ user, teamMode }: DashboardContentProps) => {
    const { scrollContainerRef } = useContext(ScrollContext);
    const isScrollIntoSessions = !!useLocationStateParam("sessionId");
    const upcomingSessionsRef = useRef<HTMLDivElement>(null);
    const isScrollIntoSkillsOverview = !!useLocationStateParam("skillId");
    const skillsOverviewRef = useRef<HTMLDivElement>(null);
    const [fetchedChildrenCount, setFetchedChildrenCount] = useState<number>(0);

    const handleScroll = useCallback((ref: React.RefObject<HTMLDivElement>) => {
        if (!scrollContainerRef.current || !ref.current || fetchedChildrenCount > CHILDREN_COUNT) return;
        scrollContainerRef.current.scroll({
            top: ref.current.offsetTop - HEADER_HEIGHT,
            behavior: "smooth",
        });
    }, [scrollContainerRef, fetchedChildrenCount]);

    useEffect(() => {
        isScrollIntoSessions && handleScroll(upcomingSessionsRef);
    }, [isScrollIntoSessions, handleScroll]);

    useEffect(() => {
        isScrollIntoSkillsOverview && handleScroll(skillsOverviewRef);
    }, [isScrollIntoSkillsOverview, handleScroll]);

    const handleDataIsFetched = useCallback(() => setFetchedChildrenCount((count) => count + 1), []);

    return (
        <>
            <SummaryOverview user={user} teamMode={teamMode} onDataIsFetched={handleDataIsFetched} />
            <Box px={{ xs: 3, lg: 12 }} >
                <TeamOverview user={user} teamMode={teamMode} onDataIsFetched={handleDataIsFetched} />
                <Box {...{ ref: skillsOverviewRef }}>
                    <SkillsOverview user={user} teamMode={teamMode} onDataIsFetched={handleDataIsFetched} />
                </Box>
                {(!teamMode && user.productSalesData) && (
                    <Box mb={10}>
                        <PerformanceMetrics productSalesData={user.productSalesData} />
                    </Box>
                )}
                <Box {...{ ref: upcomingSessionsRef }}>
                    <UpcomingSessions user={user} teamMode={teamMode} onDataIsFetched={handleDataIsFetched} />
                </Box>
            </Box>
        </>
    );
};

export default DashboardContent;
