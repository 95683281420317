import { useState, useEffect, useCallback } from "react";

export enum PromiseStatus {
    IDLE,
    PENDING,
    FULFILLED,
    REJECTED,
}

const useAsync = <T, E = string>(
    asyncFunction: (...args: unknown[]) => Promise<T>,
    dependencies: unknown[] = [],
    immediate = true
) => {
    const [status, setStatus] = useState<PromiseStatus>(PromiseStatus.IDLE);
    const [value, setValue] = useState<T | null>(null);
    const [error, setError] = useState<E | null>(null);

    const execute = useCallback(() => {
        setStatus(PromiseStatus.PENDING);
        setValue(null);
        setError(null);

        return asyncFunction(...dependencies)
            .then((response: T) => {
                setValue(response);
                setStatus(PromiseStatus.FULFILLED);
            })
            .catch((error: E) => {
                setError(error);
                setStatus(PromiseStatus.REJECTED);
            });
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (immediate) execute();
    }, [execute, immediate]);

    return { execute, status, value, error };
};

export default useAsync;