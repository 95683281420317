import React, { useState } from "react";
import { orderBy } from "lodash";
import { Box, Button, Typography } from "@material-ui/core";
import { AddIcon, RatingIcon } from "svgComponents";
import { FcrCoachingSkill, FcrCoachingSkillRating } from "types";
import { deleteFcrCoachingSkillRating } from "services/fcrCoachingSkillServices";

import useStyles from "../styles";
import SkillRatingForm from "./SkillRatingForm";
import SkillRatingPreview from "./SkillRatingPreview";

interface SkillRatingsProps {
    skill: FcrCoachingSkill;
    onSuccess: (skillId?: string) => Promise<void>;
}

function SkillRatings({ skill, onSuccess }: SkillRatingsProps) {
    const classes = useStyles();
    const [isSkillRatingFormOpen, setSkillRatingFormOpen] = useState<boolean>(false);
    const [editingSkillRating, setEditingSkillRating] = useState<FcrCoachingSkillRating | null>(null);

    const handleOpenRatingForm = (rating: FcrCoachingSkillRating | null) => {
        setSkillRatingFormOpen(true);
        setEditingSkillRating(rating);
    };

    const handleCloseRatingForm = () => {
        setSkillRatingFormOpen(false);
        setEditingSkillRating(null);
    };

    const handleDelete = (skillRating: FcrCoachingSkillRating) => (
        deleteFcrCoachingSkillRating(skillRating.id)
            .then(onSuccess)
            .then(handleCloseRatingForm)
    );

    const skillRatings = orderBy(skill.fcrCoachingSkillRatings ?? [], "min");

    return (
        <>
            <Box mb={3} fontWeight={600} display="flex" alignItems="center">
                <Typography variant="h2">
                    Skill Rating
                </Typography>
                {skillRatings.length > 0 && (
                    <Box ml={3.75}>
                        <Button
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => handleOpenRatingForm(null)}
                        >
                            New Rating
                        </Button>
                    </Box>
                )}
            </Box>
            {skillRatings.length === 0 && !isSkillRatingFormOpen && (
                <Box
                    className={classes.createRatingButton}
                    onClick={() => handleOpenRatingForm(null)}
                >
                    <RatingIcon />
                    Create Rating
                </Box>
            )}
            {isSkillRatingFormOpen && !editingSkillRating && (
                <SkillRatingForm
                    skillId={skill.id}
                    skillRating={null}
                    allRatings={skillRatings}
                    onClose={handleCloseRatingForm}
                    onSuccess={onSuccess}
                />
            )}
            {
                skillRatings.map((skillRating) => (
                    isSkillRatingFormOpen && skillRating.id === editingSkillRating?.id
                        ? (
                            <SkillRatingForm
                                key={skillRating.id}
                                skillId={skill.id}
                                skillRating={skillRating}
                                allRatings={skillRatings}
                                onClose={handleCloseRatingForm}
                                onSuccess={onSuccess}
                            />
                        ) : (
                            <SkillRatingPreview
                                key={skillRating.id}
                                skillRating={skillRating}
                                onEdit={handleOpenRatingForm}
                                onDelete={handleDelete}
                            />
                        )
                ))
            }
        </>
    )
}

export default SkillRatings;