import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";

import { CoachingPlanType } from "types";
import CoachingPlanResourceLink from "components/CoachingPlanModal/CoachingPlanResourceLink";
import RichText from "components/RichText";
import { ReminderIcon } from "svgComponents";
import { formatDate } from "helpers/dateHelpers";

import useStyles from "../styles";

interface CoachingPlanItemProps {
    coachingPlan: CoachingPlanType;
    fcrCoachingSkillName: string
}

function CoachingPlanItem({ coachingPlan: { coachingPlanData, dueDate, sendReminder, resources = [] }, fcrCoachingSkillName }: CoachingPlanItemProps) {
    const classes = useStyles();

    return (
        <Box pb={6}>
            <Paper className={classes.skillPaper}>
                <Box pt={6} pb={4} pl={5.5} pr={9}>
                    <Typography variant="h1">{fcrCoachingSkillName}</Typography>
                    <Box fontWeight="600" pt={3} pb={1.5}>
                        <Typography variant="body1">Coaching Plan</Typography>
                    </Box>
                    <Typography variant="body1" component="div" className={classes.observations}>
                        <RichText text={coachingPlanData}/>
                    </Typography>
                    {resources.length > 0 && (
                        <Box mt={3}>
                            <Typography variant="body2">
                                <Box component="span" fontWeight={500}>Resource(s):</Box>
                            </Typography>
                            <Box component="ul" mt={1.5} mb={0} pl={0} style={{ listStyle: "none" }}>
                                {resources.map((resource, idx) => (
                                    <Box key={resource.id} component="li" mt={idx > 0 ? 1 : 0}>
                                        <CoachingPlanResourceLink resource={resource} />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )}
                    <Box display="flex" flexDirection="row" alignItems="center" pt={3}>
                        <Typography variant="h3">Due Date:</Typography>
                        <Box pl={1} fontWeight="500">
                            <Typography>{formatDate(dueDate)}</Typography>
                        </Box>
                        {sendReminder &&
                            <Box display="flex" alignItems="center" pl={5} className={classes.opacityStyle}>
                                <Box pr={1}>
                                    <ReminderIcon />
                                </Box>
                                <Typography>Reminder Set</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default CoachingPlanItem;
