import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    content: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
    },
    contentSubmitted: {
        borderRadius: "8px",
    },
    sectionHeader: {
        color: theme.colors.text.darkBlue,
    },
    opacityStyle: {
        opacity: 0.8,
    },
    skillPaper: {
        border: "1px solid #E4E7EB",
        borderRadius: theme.spacing(1),
    },
    observations: {
        lineHeight: "27px",
    },
}));

export default useStyles;
