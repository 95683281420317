import { AppBar, Box, Button, CircularProgress, IconButton, Link, Toolbar, Tooltip, Typography } from "@material-ui/core";
import AvatarWithScore from "components/AvatarWithScore";
import CircleIcon from "components/CircleIcon";
import LoadingButton from "components/LoadingButton";
import NavigationLink from "components/NavigationLink";
import { ConfigurationContext, SnackbarContext } from "context";
import { formatShortDate } from "helpers/dateHelpers";

import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { resendEmailForEvaluationResult } from "services/certificationService";
import { BackArrow, Calendar, Menu } from "svgComponents";
import IconPersonCheck from "svgComponents/IconPersonCheck";
import { HeaderProps, UserProfile } from "types";
import { AlertSeverity, EvaluationResultStatusEnum, Routings, SizeEnum } from "types/constants";
import { FullEvaluationDto } from "types/Evaluation.dto";

import useStyles from "./styles";

interface TraineeEvaluationBarProps extends HeaderProps {
    evaluation: FullEvaluationDto;
    handleSave: () => void;
    isLoading: boolean;
    viewMode: boolean;
    completed: boolean;
}

const tooltip = "Resends confirmation email";

export default function TraineeEvaluationBar({
    isBarOpen,
    navBarOpen,
    evaluation,
    handleSave,
    isLoading,
    viewMode,
    completed,
}: TraineeEvaluationBarProps) {
    const { currentUser } = useContext(ConfigurationContext);
    const { openSnackbar } = useContext(SnackbarContext);
    // const history = useHistory();
    const classes = useStyles({ id: currentUser?.id ?? "1" });
    const [isSending, setSending] = useState<boolean>(false);

    const resultStatusIsComplete = evaluation.evaluationResult.evaluationResultStatusId === EvaluationResultStatusEnum.completed;
    const resultIsInProgress = evaluation.evaluationResult.evaluationResultStatusId === EvaluationResultStatusEnum.inProgress;

    const { isSelfEvaluated, trainee, evaluator } = evaluation.evaluationResult;
    const userNameToDisplay = isSelfEvaluated ? trainee : evaluator;
    const conditionalEvalStaedByString = resultIsInProgress ? `Started by ${userNameToDisplay ?? "unknown user"}` : "";

    const copyCurrentUserWithTraineeProfile = {
        ...currentUser,
        avatar: evaluation?.traineeProfile ?? "",
        id: evaluation.traineeId,
        userName: evaluation.trainee,
    } as UserProfile;

    const handleResendEmail = useCallback(() => {
        setSending(true);
        resendEmailForEvaluationResult(evaluation.evaluationResult.id)
            .then(() => openSnackbar("Email was successfully sent.", AlertSeverity.success))
            .catch((e) => openSnackbar(e.response.data || "Failed to send email.", AlertSeverity.error))
            .finally(() => setSending(false));
    }, [evaluation.evaluationResult.id, openSnackbar]);

    // const handleBackHistory = () => history.goBack();

    const formattedDate = useMemo(() => formatShortDate(new Date(evaluation.class.classDate)), [evaluation.class.classDate]);

    return (
        <AppBar position="sticky" color="inherit">
            <Toolbar disableGutters={true}>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    px={{ xs: 2, md: 8 }}
                    pt={{ xs: 1, md: 3 }}
                    pb={{ xs: 1, md: 2 }}
                    className={classes.header}>
                    {/*
                        Temp comment out until requirements are established
                        https://productiveedge.atlassian.net/browse/PLEVAL-167
                        */}
                    {/* {viewMode && (
                        <NavigationLink fakeLink={true} href={""} className={classes.back}>
                            <Box display="flex" alignItems="center" py={0.5} onClick={handleBackHistory}>
                                <BackArrow />
                                <Box ml={2.5}>Back</Box>
                            </Box>
                        </NavigationLink>
                    )} */}

                    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                        <Box display="flex" alignItems="center" mb={2}>
                            {!isBarOpen && (
                                <Box pr={1} ml={-1.5}>
                                    <IconButton className={classes.navBarOpen} onClick={navBarOpen}>
                                        <Menu />
                                    </IconButton>
                                </Box>
                            )}
                            <Box display="flex" justifyContent="space-between" width="100%">
                                <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems={{ xs: "right", md: "center" }}>
                                    <Box pr={2} fontWeight={600}>
                                        <Typography variant="h2">{resultStatusIsComplete ? "Summary:" : evaluation.class.title}</Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="row" alignItems="center" pt={{ xs: 1, md: 0 }} flexWrap="wrap">
                                        <AvatarWithScore user={copyCurrentUserWithTraineeProfile} size={SizeEnum.small} />
                                        <Box pl={1.5} pr={2} fontWeight={500}>
                                            <Typography variant="h3">{`${evaluation.trainee}`}</Typography>
                                        </Box>
                                        {viewMode && (
                                            <>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    py={1}
                                                    px={2}
                                                    className={classes.date}
                                                    fontWeight="500"
                                                    mr={2}>
                                                    <Calendar />
                                                    <Box pl={1}>{formattedDate}</Box>
                                                </Box>
                                                {evaluation.class.allowSelfEvaluation && (
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        mr={2}
                                                        py={1}
                                                        px={1}
                                                        fontWeight="500">
                                                        <CircleIcon>
                                                            <IconPersonCheck />
                                                        </CircleIcon>
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box display="flex" color="text.secondary" flex={1} justifyContent="flex-end" alignItems="flex-end" mb={2}>
                            {viewMode && completed ? (
                                <>
                                    <Box color="text.secondary" whiteSpace="nowrap">
                                        <Link
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="none"
                                            href={evaluation.evaluationResult.pdfUrl}>
                                            <Button variant="outlined" color="primary" size="medium">
                                                <Box lineHeight="30px">Download PDF</Box>
                                            </Button>
                                        </Link>
                                    </Box>

                                    <Tooltip title={tooltip} placement="bottom" arrow>
                                        <Box color="text.secondary" whiteSpace="nowrap" pl={2}>
                                            <LoadingButton
                                                variant="outlined"
                                                color="inherit"
                                                size="medium"
                                                disableElevation
                                                loading={isSending}
                                                loadingLabel="Sending..."
                                                onClick={handleResendEmail}>
                                                Resend Email
                                            </LoadingButton>
                                        </Box>
                                    </Tooltip>
                                </>
                            ) : (
                                <Box display="flex" color="text.secondary" flex={1} justifyContent="flex-end" alignItems="flex-end">
                                    <Button variant="outlined" color="inherit" size="large" onClick={handleSave} disabled={isLoading}>
                                        {isLoading ? <CircularProgress size={24} /> : "Finish Later"}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {resultIsInProgress && (
                        <Box display="flex" alignItems="center">
                            <Typography variant="h3">{conditionalEvalStaedByString}</Typography>
                        </Box>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
}
