import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import orderBy from "lodash/orderBy";

import CoachingPlanModal from "components/CoachingPlanModal";
import PaperSection from "components/PaperSection";
import CoachingPlan from "components/CoachingPlan";
import NoDataMessage from "components/NoDataMessage";
import useCoachingPlanModal from "hooks/useCoachingPlanModal";
import { getAllFcrCoachingPlanByUserId } from "services/wizardServices";
import { CoachingPlanType, TeamProfileRouteParams, UserProfile } from "types";

import useStyles from "../TeamProfileCoachingPlans/styles";
import BackToProfile from "../../BackToProfile";

interface CoachingPlanHistoryProps {
    user: UserProfile,
}

const STEP_SIZE = 10;

export default function CoachingPlanHistory({ user }: CoachingPlanHistoryProps) {
    const classes = useStyles();
    const { userId } = useParams<TeamProfileRouteParams>();
    const { data, isShown, open, close } = useCoachingPlanModal();
    const [shownNumb, setShownNumb] = useState(STEP_SIZE);
    const [isPlansFetching, setIsPlansFetching] = useState(false);
    const [plans, setPlans] = useState<CoachingPlanType[]>([]);
    const shownPlans = useMemo(() => plans.slice(0, shownNumb), [plans, shownNumb])

    useEffect(() => {
        const getPlans = async () => {
            setIsPlansFetching(true);
            const response = await getAllFcrCoachingPlanByUserId(user.id);
            const sorted = orderBy(response, "dueDate", "desc");
            setPlans(sorted);
            setIsPlansFetching(false);
        }
        getPlans();
    }, [user.id]);

    const onShowMore = useCallback(() => setShownNumb(shownNumb + STEP_SIZE), [shownNumb]);

    const onCoachingPlanChange = useCallback((newCoachingPlan: CoachingPlanType) => {
        const updatedPlans = plans.map(plan =>
            plan.id === newCoachingPlan.id ? newCoachingPlan : plan);
        setPlans(updatedPlans);
    }, [plans]);

    const renderPlans = () => {
        if (isPlansFetching) return (
            <Box display="flex" justifyContent="center" py={10}>
                <CircularProgress size={24} />
            </Box>
        );

        if (plans.length <= 0) return (
            <NoDataMessage pb={5} pt={7} />
        );

        return (
            <InfiniteScroll
                scrollableTarget="app"
                dataLength={shownPlans.length}
                next={onShowMore}
                hasMore={plans.length > shownNumb}
                loader={<></>}
                hasChildren
            >
                <Box display="flex" flexDirection="column" px={5} py={1}>
                    {shownPlans.map(plan => (
                        <Box key={plan.id} className={classes.planCell} py={1}>
                            <CoachingPlan
                                coachingPlan={plan}
                                onDetailOpen={open}
                                mx={-3}
                                px={3}
                                py={2}
                            />
                        </Box>
                    ))}
                </Box>
            </InfiniteScroll>
        );
    };

    return (
        <>
            <BackToProfile userId={userId} />
            <PaperSection
                title={<Title />}
                mb={{ xs: 3, sm: 6 }}
            >
                {renderPlans()}
            </PaperSection>
            <CoachingPlanModal
                data={data}
                isShown={isShown}
                onClose={close}
                onCoachingPlanChange={onCoachingPlanChange}
            />
        </>
    )
}

function Title() {
    return (
        <Box fontWeight={600} mr={1.5}>
            <Typography variant="h2">Coaching Plans</Typography>
        </Box>
    )
}
