import React, { useCallback, useEffect, useState } from "react";

import { ConfigurationContext } from "context";
import { Company, CompanyLevel, Team, UserProfile, FcrConfiguration, FcrSectionConfiguration, CoachingSession, User } from "types";
import { getCurrentTeam, getUser, getCurrentUserProfile } from "services/profileServices";
import authService from "services/authService";
import { getCompany, getCurrentFcrConfiguration } from "services/wizardServices";
import { getUsersForCompany } from "services/userServices";
import { FcrStatusEnum, LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW } from "types/constants";
import { getCertificationSectionConfiguration, getCompanyFcrSectionConfiguration, getCompanyLevels } from "services/companyServices";
import { DEFAULT_FCR_SECTION_CONFIGURATION } from "helpers/fcrHelper";
import { getAllCompanyTeams, getTeamByLeaderId, getTeamByMemberId } from "services/teamServices";
import { CertificationSectionConfiguration } from "types/Certification";

interface ConfigurationProviderProps {
    children: React.ReactNode;
}

const ConfigurationProvider = ({ children }: ConfigurationProviderProps) => {
    // ToDo: There must be an object of type User (currentUserProfile), since UserProfile and User are different types.
    const [currentUser, setCurrentUser] = useState<UserProfile | undefined>(); // ToDo: Should be currentUserProfile
    const [currentUserDto, setCurrentUserDto] = useState<User>(); // ToDo: Should be currentUser
    const [currentCompany, setCurrentCompany] = useState<Company | undefined>();
    const [companyUsers, setCompanyUsers] = useState<UserProfile[]>();
    const [currentTeam, setCurrentTeam] = useState<UserProfile[]>([]);
    const [companyLevels, setCompanyLevels] = useState<CompanyLevel[]>([]);
    const [allCompanyTeams, setAllCompanyTeams] = useState<Team[]>([]);
    const [fcrConfiguration, setFcrConfiguration] = useState<FcrConfiguration>();
    const [fcrSectionConfiguration, setFcrSectionConfiguration] = useState<Partial<FcrSectionConfiguration>>();
    const [coacheeTeam, setCoacheeTeam] = useState<Team>();
    const [coachedTeam, setCoachedTeam] = useState<Team>();
    const [isConfigurationFetching, setIsConfigurationFetching] = useState(false);
    const [isConfigurationFetched, setIsConfigurationFetched] = useState(false);
    const [companyCertificationConfiguration, setcompanyCertificationConfiguration] = useState<CertificationSectionConfiguration | null>(
        null
    );

    useEffect(() => {
        setIsConfigurationFetching(true);
        Promise.all([
            getCurrentUserProfile().then((userProfile) => {
                if (!userProfile) {
                    // TODO Instead redirect to a "no user found page, please contact admin"
                    authService.logout();
                    return null;
                }

                if (!userProfile?.isALeader && !userProfile?.isACoLeader) localStorage.setItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW, "true");

                setCurrentUser(userProfile);

                return Promise.all([
                    getUser(userProfile.id).then(setCurrentUserDto),
                    getCompany(userProfile.companyId).then(setCurrentCompany),
                    getUsersForCompany(userProfile.companyId).then(setCompanyUsers),
                    getAllCompanyTeams(userProfile.companyId).then(setAllCompanyTeams),
                    getCompanyLevels(userProfile.companyId).then(setCompanyLevels),
                    getCurrentFcrConfiguration(userProfile.companyId).then(setFcrConfiguration),
                    getCompanyFcrSectionConfiguration(userProfile.companyId)
                        .then(setFcrSectionConfiguration)
                        .catch(() => setFcrSectionConfiguration(DEFAULT_FCR_SECTION_CONFIGURATION)),
                    getTeamByMemberId(userProfile.id).then(setCoacheeTeam),
                    getTeamByLeaderId(userProfile.companyId, userProfile.id)
                        .then((teams) => teams[0] ?? null)
                        .then(setCoachedTeam),
                    getCertificationSectionConfiguration(userProfile.companyId).then(setcompanyCertificationConfiguration),
                ]);
            }),
            getCurrentTeam().then((team) => {
                setCurrentTeam(team);
            }),
        ]).finally(() => {
            setIsConfigurationFetching(false);
            setIsConfigurationFetched(true);
        });
    }, []);

    const hasCoachingReportAccess = useCallback(
        (coachingSession: CoachingSession) => {
            if (!currentUser || !coachingSession.fcrResult) return false;

            const isSubmittedByCoachee = !!coachingSession.fcrResult.submittedByCoacheeDate;
            const isCreator = currentUser.id === coachingSession.createdById;

            const isCoach = currentUser.id === coachingSession.coachId;
            const isCoachee = currentUser.id === coachingSession.assigneeId;

            const isCoachWithAccess = isCoach && isSubmittedByCoachee;
            const isCoacheeWithAccess = isCoachee && !isSubmittedByCoachee;

            const isCoachOrCoachee = isCreator || isCoachWithAccess || isCoacheeWithAccess;

            if (coachingSession.fcrResult.fcrResultStatusId !== FcrStatusEnum.completed) {
                return isCoachOrCoachee;
            }

            const isCoLeaderWithAccess =
                currentUser.isACoLeader &&
                coachedTeam?.coLeaders?.find((coLeader) => coLeader.id === currentUser.id)?.hasCoachingReportReadAccess;

            return !!(currentUser.isALeader || isCoachOrCoachee || isCoLeaderWithAccess);
        },
        [currentUser, coachedTeam]
    );

    const patchCurrentUser = (currentUserPatch: { [key in keyof UserProfile]?: UserProfile[key] }) =>
        setCurrentUser({
            ...currentUser,
            ...currentUserPatch,
        } as UserProfile);

    const patchCurrentUserDto = (currentUserDtoPatch: { [key in keyof User]?: User[key] }) =>
        setCurrentUserDto({
            ...currentUserDto,
            ...currentUserDtoPatch,
        } as User);

    return (
        <ConfigurationContext.Provider
            value={{
                currentUser,
                currentUserDto,
                currentCompany,
                currentTeam,
                allCompanyTeams,
                companyUsers,
                companyLevels,
                fcrConfiguration,
                fcrSectionConfiguration,
                coacheeTeam,
                coachedTeam,
                isConfigurationFetching,
                isConfigurationFetched,
                companyCertificationConfiguration,
                hasCoachingReportAccess,
                patchCurrentUser,
                patchCurrentUserDto,
            }}>
            {children}
        </ConfigurationContext.Provider>
    );
};

export default ConfigurationProvider;
