import React from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { FormInput } from "components/Form";

interface ClassesNavigationProps {
    isLoading: boolean;
}

export default function ClassesNavigation({ isLoading }: ClassesNavigationProps) {
    return (
        <Box p={4}>
            <Box fontWeight={600} mb={3} display="flex" alignItems="center">
                <Typography variant="h2">Classes Navigation</Typography>
                {isLoading && (
                    <Box ml={2}>
                        <CircularProgress size={17} />
                    </Box>
                )}
            </Box>
            <Box ml="11px" mt={3}>
                <FormInput name="certificationSectionConfiguration.section1Label" label="Label" />
            </Box>
        </Box>
    );
}
