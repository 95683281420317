import InfiniteScroll from "react-infinite-scroll-component";
import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import Header from "components/Header";
import NavigationLink from "components/NavigationLink";
import NoDataMessage from "components/NoDataMessage";
import PaperSection from "components/PaperSection";
import { ClassesContext, ConfigurationContext } from "context";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import BackArrow from "svgComponents/BackArrow";
import { Routings } from "types/constants";

import { HeaderProps } from "types";

import { CLASSES_STEP_SIZE, THROTTLE_CLIENT_SIDE_SCROLL_LOADING_SIMULATION } from "../shared-classes.data";

import AllMyClassesPageFilters from "./AllMyClassesPageFilters";
import AllMyClassesPageItem from "./AllMyClassesPageItem";

import useStyles from "./styles";

export default function AllMyClassesPage({ isBarOpen, navBarOpen }: HeaderProps) {
    const classes = useStyles();
    const { companyCertificationConfiguration } = useContext(ConfigurationContext);
    const {
        allMyClassNameFilter,
        allMyClassToShowCountFilter,
        setAllMyClassToShowCountFilter,
        allMyClassesSorting,
        fetchAllMyClasses,
        postSortFilterAllMyClasses,
        isFetchingAllMyClasses,
        resetAllMyClassesState,
    } = React.useContext(ClassesContext);

    const [canShowMoreClasses, setCanShowMoreClasses] = useState(true);

    const currentAllMyClassesCount = postSortFilterAllMyClasses?.length ?? 0;

    const currentShownCount = Math.min(currentAllMyClassesCount, allMyClassToShowCountFilter ?? 0);
    const itemsRef = useRef<HTMLDivElement>(null);

    const shownMyClasses = useMemo(
        () => (postSortFilterAllMyClasses ? postSortFilterAllMyClasses.slice(0, allMyClassToShowCountFilter) : []),
        [postSortFilterAllMyClasses, allMyClassToShowCountFilter]
    );

    const onShowMore = useCallback(() => {
        if (!setAllMyClassToShowCountFilter) {
            return;
        }
        setTimeout(() => {
            setAllMyClassToShowCountFilter(currentShownCount + CLASSES_STEP_SIZE);
        }, THROTTLE_CLIENT_SIDE_SCROLL_LOADING_SIMULATION);
    }, [setAllMyClassToShowCountFilter, currentShownCount]);

    useEffect(() => {
        if (!postSortFilterAllMyClasses && fetchAllMyClasses) {
            fetchAllMyClasses();
        }
    }, [postSortFilterAllMyClasses, fetchAllMyClasses]);

    useEffect(() => {
        if (resetAllMyClassesState) {
            return resetAllMyClassesState;
        }
    }, [resetAllMyClassesState]);

    useEffect(() => {
        if (currentShownCount < currentAllMyClassesCount) {
            setCanShowMoreClasses(true);
        } else {
            setCanShowMoreClasses(false);
        }
    }, [currentShownCount, currentAllMyClassesCount]);

    const dynamicCountString = `${currentShownCount} ${canShowMoreClasses ? `from ${postSortFilterAllMyClasses?.length} ` : ""}items`;
    const loading = isFetchingAllMyClasses ?? true;
    const canShowTable = !loading && shownMyClasses?.length > 0;
    const canShowFilter = loading || shownMyClasses;
    const canSortData = (postSortFilterAllMyClasses?.length ?? 0) > 0 ?? false;
    const canShowNoData = (postSortFilterAllMyClasses?.length ?? -1) === 0 && !loading;

    const sectionlabel = useMemo(
        () => companyCertificationConfiguration?.section1Label,
        [companyCertificationConfiguration?.section1Label]
    );

    return (
        <>
            <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
            <div>
                {
                    <NavigationLink href={{ pathname: Routings.classes }} className={classes.back}>
                        <Box display="flex" alignItems="center" px={6} pt={4} pb={4}>
                            <BackArrow />
                            <Box ml={2.5}>Back To {sectionlabel}</Box>
                        </Box>
                    </NavigationLink>
                }
            </div>
            <Box display="flex" justifyContent="center">
                <PaperSection
                    px={6}
                    width={1}
                    title={
                        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" width={1}>
                            <Box fontWeight={600} whiteSpace="nowrap">
                                <Typography variant="h2">All My {sectionlabel}</Typography>
                            </Box>
                            {canShowFilter && (
                                <AllMyClassesPageFilters
                                    allMyClassNameFilter={allMyClassNameFilter}
                                    dateSortDirection={allMyClassesSorting}
                                    isFetchingAllMyClasses={loading}
                                    sortEnabled={canSortData}
                                />
                            )}
                        </Box>
                    }>
                    {canShowTable && (
                        <InfiniteScroll
                            scrollableTarget="app"
                            dataLength={shownMyClasses.length}
                            next={onShowMore}
                            hasMore={canShowMoreClasses}
                            loader={
                                canShowMoreClasses ? (
                                    <Box p={4} textAlign="center">
                                        <CircularProgress size={40} />
                                    </Box>
                                ) : null
                            }
                            hasChildren>
                            <Box px={5} py={2}>
                                <Typography variant="caption">{dynamicCountString}</Typography>
                            </Box>
                            <Divider />
                            <div {...{ ref: itemsRef }}>
                                {shownMyClasses.map((item, i) => (
                                    <AllMyClassesPageItem key={i} myClass={item} />
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                    {canShowNoData && <NoDataMessage pb={5} pt={7} message="There's nothing to show here yet" />}
                    {loading && (
                        <Box p={4} textAlign="center">
                            <CircularProgress size={40} />
                        </Box>
                    )}
                </PaperSection>
            </Box>
        </>
    );
}
