import React, { useCallback, useMemo, useState } from "react";
import { Dayjs } from "dayjs";
import DayJsUtils from "@date-io/dayjs";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import SvgReminderIcon from "svgComponents/ReminderIcon";
import { addHour } from "helpers/dateHelpers";

import useStyles from "./styles";

interface CoachingSessionTimePickerProps {
    startTime?: Date | null;
    endTime?: Date | null;
    onStartTimeChange: (time: Date | null) => void;
    onEndTimeChange: (time: Date | null) => void;
    isAllDay: boolean;
    onAllDayChange: (value: boolean) => void;
}

const CoachingSessionTimePicker = ({ 
    startTime, 
    endTime, 
    onStartTimeChange,
    onEndTimeChange, 
    isAllDay,
    onAllDayChange,
}: CoachingSessionTimePickerProps) => {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);

    const handleTimepickerOpen = useCallback(() => setShowError(false), []);
    const handleTimepickerClose = useCallback(() => setShowError(true), []);

    const handleStartTimeChange = useCallback((value: Dayjs | null) => {
        onStartTimeChange(value ? value.toDate() : null);
    }, [onStartTimeChange]);

    const handleEndTimeChange = useCallback((value: Dayjs | null) => {
        onEndTimeChange(value ? value.toDate() : null);
    }, [onEndTimeChange]);

    const handleAllDayChange = useCallback(() => {
        onAllDayChange(!isAllDay);
    }, [isAllDay, onAllDayChange]);

    const error = useMemo(
        () => startTime && endTime && !isAllDay && startTime > endTime,
        [startTime, endTime, isAllDay]
    );

    const initialEndTime = useMemo(() => startTime && addHour(startTime), [startTime]);

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                border={1}
                borderColor={showError && error ? "error.main" : "#BDC6D0"}
                borderRadius="4px"
                bgcolor="common.white"
                py="5px" px={2}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={32}
                    width={32}
                    borderRadius="50%"
                    bgcolor="#F6F8F9"
                    mr={1}
                >
                    <SvgReminderIcon/>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexGrow={1}
                >
                    <Box display="flex" alignItems="center">
                        <MuiPickersUtilsProvider utils={DayJsUtils}>
                            <TimePicker
                                variant="inline"
                                inputVariant="standard"
                                value={startTime}
                                onChange={handleStartTimeChange}
                                emptyLabel="Start Time"
                                minutesStep={5}
                                className={classes.picker}
                                disabled={isAllDay}
                                onOpen={handleTimepickerOpen}
                                onClose={handleTimepickerClose}
                                autoOk
                            />
                            <Box mx={1.5} pb={0.5} color="text.secondary">
                                &#8211;
                            </Box>
                            <TimePicker
                                variant="inline"
                                inputVariant="standard"
                                value={endTime}
                                onChange={handleEndTimeChange}
                                emptyLabel="End Time"
                                minutesStep={5}
                                className={classes.picker}
                                disabled={isAllDay}
                                onOpen={handleTimepickerOpen}
                                onClose={handleTimepickerClose}
                                initialFocusedDate={initialEndTime}
                                autoOk
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                    <FormControlLabel
                        control={
                            <Checkbox checked={isAllDay} onChange={handleAllDayChange} color="primary"/>
                        }
                        label="All Day"
                    />
                </Box>
            </Box>
            {showError && error && (
                <Box mt={2}>
                    <Alert severity="error">
                        End time should be later than start time
                    </Alert>
                </Box>
            )}
        </>
    );
}

export default CoachingSessionTimePicker;