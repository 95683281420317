import React, { useContext, useState } from "react";
import { Box, Tab, Tabs, CircularProgress } from "@material-ui/core";

import DashboardContent from "routes/Dashboard/DashboardContent";
import { UserProfile } from "types";
import { isHighLevel } from "helpers/companyHelper";
import { ConfigurationContext } from "context";

interface LeaderDashboardProps {
    user?: UserProfile
}

enum LeaderDashboardTab {
    Level = "level",
    Coaching = "coaching",
}

export default function LeaderDashboard({ user }: LeaderDashboardProps) {
    const [value, setValue] = useState(LeaderDashboardTab.Level);
    const { companyLevels } = useContext(ConfigurationContext);

    if (!user || !companyLevels.length) {
        return (
            <Box>
                <Box display="flex" flexDirection="column" py={2.5} px={{ xs: 3, lg: 12 }}>
                    <Box m="auto">
                        <CircularProgress size={40}/>
                    </Box>
                </Box>
            </Box>
        );
    }

    if (!isHighLevel(user.level, companyLevels)) {
        return <DashboardContent user={user} teamMode={false} />;
    }

    return (
        <Box>
            <Box py={2.5} px={{ xs: 3, lg: 12 }}>
                <Tabs value={value} textColor="primary" onChange={(_, newValue) => { setValue(newValue) }}>
                    <Tab label={`My ${user.levelLabel}`} value={LeaderDashboardTab.Level}/>
                    <Tab label="My Coaching" value={LeaderDashboardTab.Coaching} />
                </Tabs>
            </Box>
            <DashboardContent user={user} teamMode={value === LeaderDashboardTab.Level} />
        </Box>
    );
}