import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Box, Typography, Button, Grid, CircularProgress, Card, Divider } from "@material-ui/core";
import { CompaniesContext } from "context";
import { PlusIcon } from "svgComponents";
import { SkillsIcon } from "svgComponents/MenuIcons";
import NoDataMessage from "components/NoDataMessage";
import CertificationClassListItem from "components/CertificationClassListItem";
import NavigationLink from "components/NavigationLink";
import LoadingButton from "components/LoadingButton";
import ImportResultAlert from "components/ImportResult";
import CertificationClassesFilter from "components/CertificationClassesFilter";
import { ImportResult } from "types";
import { AdminRoutings } from "types/constants";
import { CertificationClassInformation } from "types/Certification";
import { useHistory } from "react-router-dom";
import { getClassesForCompany } from "services/certificationService";
import { importClassRoster } from "services/importService";
import useOnCompanyChange from "hooks/useOnCompanyChange";

const AUTOCOMPLETE_LABEL = "Search all Classes";

function CertificationClasses() {
    const { currentCompany } = useContext(CompaniesContext);
    const history = useHistory();
    const importHistory = useHistory<{ importResult: ImportResult }>();
    const [CertificationClasses, setCertificationClasses] = useState<CertificationClassInformation[]>([]);
    const [filteredCertificationClasses, setFilteredCertificationClasses] = useState<CertificationClassInformation[]>([]);
    const [isCertificationClassesFetched, setIsCertificationClassesFetched] = useState<boolean>(false);
    const [importResult, setImportResult] = useState<ImportResult | undefined>(importHistory.location.state?.importResult);
    const [isRosterImporting, setIsRosterImporting] = useState<boolean>(false);
    const totalLabel = filteredCertificationClasses?.length === 1 ? "class" : "classes";
    const isMounted = useRef<boolean>(true);

    const fetchClasses = useCallback((companyId) => {
        setIsCertificationClassesFetched(false);
        if (companyId) {
            getClassesForCompany(companyId)
                .then(setCertificationClasses, setFilteredCertificationClasses)
                .finally(() => setIsCertificationClassesFetched(true));
        }
    }, []);

    useEffect(() => () => { isMounted.current = false; }, []);

    useEffect(() => {
        fetchClasses(currentCompany?.id);
    }, [fetchClasses, currentCompany, setFilteredCertificationClasses]);

    useOnCompanyChange(() => {
        if (importResult) setImportResult(undefined);
        history.push(AdminRoutings.certificationClasses);
    });

    const handleEdit = useCallback(
        (certificationClassId) => {
            history.push(`${AdminRoutings.certificationClasses}/${certificationClassId}`);
        },
        [history]
    );

    const showImportResult = (importResult: ImportResult) => {
        if (isMounted.current) {
            setImportResult(importResult);
        } else {
            importHistory.push(AdminRoutings.certificationClasses, { importResult });
        }
    };

    const handleImportResolved = (importPromise: Promise<ImportResult>) =>
        importPromise
            .then((response) => {
                showImportResult(response);
                if (isMounted.current) fetchClasses(currentCompany?.id);
            })
            .catch((error) => {
                if (typeof error.response.data === "string") {
                    showImportResult({
                        isSuccess: false,
                        hasCriticalError: true,
                        logs: [],
                        errors: [{ message: error.response.data }],
                    });
                } else {
                    showImportResult(error.response.data);
                }
            });

    const handleImportRoster = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (!file) return;

        setImportResult(undefined);
        setIsRosterImporting(true);

        handleImportResolved(importClassRoster(currentCompany!.id, file)).finally(() => setIsRosterImporting(false));
    };

    return (
        <>
            {importResult && (
                <Box mb={4}>
                    <ImportResultAlert
                        result={importResult}
                        onClose={() => setImportResult(undefined)}
                        errorTitle={`Import was unsuccessful and process was rolled back.
                                Please fix the following errors and import the file again.`}
                    />
                </Box>
            )}
            <Grid alignItems="center" container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h1">Class Management</Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box display="flex" height={1}>
                                <LoadingButton
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    disableElevation
                                    component="label"
                                    loadingLabel="Importing..."
                                    loading={isRosterImporting}
                                >
                                    <Box component="span" lineHeight="30px">
                                        Import Roster (.xlsx)
                                    </Box>
                                    <input type="file" accept=".xlsx" onChange={handleImportRoster} hidden />
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item>
                            <NavigationLink href={AdminRoutings.addCertificationClass} hoverUnderline={false}>
                                <Button variant="contained" color="primary" size="large" disableElevation startIcon={<PlusIcon />}>
                                    <Box component="span" lineHeight="30px">
                                        New Class
                                    </Box>
                                </Button>
                            </NavigationLink>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={5}>
                <Card>
                    {isCertificationClassesFetched && (
                        <>
                            <CertificationClassesFilter
                                certificationClasses={CertificationClasses}
                                onFilter={setFilteredCertificationClasses}
                                evaluationsAutocompleteLabel={AUTOCOMPLETE_LABEL}
                            />
                            <Divider />
                            <Box display="flex" py={2} px={4}>
                                <Box flexShrink={0}>
                                    <Typography
                                        color="textSecondary"
                                        variant="body1">{`${filteredCertificationClasses.length} ${totalLabel}`}</Typography>
                                </Box>
                            </Box>
                            <Divider />
                            {filteredCertificationClasses.map((certificationClass, idx) => (
                                <React.Fragment key={certificationClass.id}>
                                    {idx > 0 && <Divider />}
                                    <CertificationClassListItem
                                        certificationClass={certificationClass}
                                        key={certificationClass.id}
                                        icon={<SkillsIcon />}
                                        onEdit={() => {
                                            handleEdit(certificationClass.id);
                                        }}
                                        onClick={() => {
                                            handleEdit(certificationClass.id);
                                        }}
                                    />
                                </React.Fragment>
                            ))}
                        </>
                    )}
                    {isCertificationClassesFetched && filteredCertificationClasses.length === 0 && (
                        <Box px={6} py={4}>
                            <NoDataMessage />
                        </Box>
                    )}
                    {!isCertificationClassesFetched && (
                        <Box px={6} py={4} textAlign="center">
                            <CircularProgress size={40} />
                        </Box>
                    )}
                </Card>
            </Box>
        </>
    );
}

export default CertificationClasses;
