import * as React from "react";

function SvgRedirect(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.721 6.433v3.87c0 .71-.58 1.291-1.29 1.291H1.689c-.71 0-1.29-.58-1.29-1.29V2.562c0-.71.58-1.29 1.29-1.29h3.87M5.121 6.884l6.2-6.29M7.578.4h3.974v3.981"
        stroke="#7489A2"
        strokeWidth={1.25}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgRedirect;
