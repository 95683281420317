import * as React from "react";

export default function SvgProductImport(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-40.000000, -642.000000)">
                    <g id="Menu" transform="translate(18.000000, 80.000000)">
                        <g id="Product-Import" transform="translate(0.000000, 540.000000)">
                            <g id="Icon" transform="translate(22.000000, 22.000000)" fill="currentColor">
                                <path d="M12.7058824,0 L12.705,3.30545286 L14,3.30631932 L14,12.9023953 C14,14.553232 12.7118024,15.9039061 11.0870858,15.9950931 L10.9117647,16 L5.08823529,16 C3.44069553,16 2.09568621,14.7062513 2.00488597,13.0780784 L2,12.9023953 L2,2.50484119 L2.00604019,2.42864257 C2.06211369,1.78980835 2.50436911,1.24632017 3.15549238,1.05448227 L3.29815114,1.01884996 L12.7058824,0 Z M3,4.13946667 L3,12.9023953 C3,14.0131366 3.85629562,14.9214153 4.93921842,14.9982963 L5.08823529,15.0035677 L10.9117647,15.0035677 C12.013989,15.0035677 12.9182074,14.1435822 12.9947515,13.052546 L13,12.9023953 L13,4.30188513 L3.73232353,4.30275159 C3.48037356,4.30275159 3.24106184,4.24885596 3.02518471,4.15197935 L3,4.13946667 Z M8,6.64220127 L10.8215516,9.41294018 L10.1196249,10.1226482 L8.49944841,8.53389017 L8.5,12.1938902 L7.5,12.1938902 L7.49944841,8.53189017 L5.88037512,10.1226482 L5.17844841,9.41294018 L8,6.64220127 Z M11.705,1.11031256 L3.45505412,2.00170527 C3.22016568,2.05091531 3.04330776,2.2426233 3.00690585,2.47714412 L3,2.56690159 C3,2.94184707 3.27642509,3.25070879 3.63318186,3.29958195 L3.73232353,3.30631932 L11.705,3.30545286 L11.705,1.11031256 Z" id="Combined-Shape" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
