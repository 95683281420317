import React, { useCallback, useContext, useMemo, useState } from "react";
import orderBy from "lodash/orderBy"
import { Box, Link, Paper, Typography } from "@material-ui/core";

import { MessageType } from "types";
import { formatDateTime } from "helpers/dateHelpers";
import { ConfigurationContext } from "context";
import MessageHistoryItem from "components/MessageHistoryItem";
import MessageHistoryThreadReply from "components/MessageHistoryThreadReply";

interface MessageHistoryThreadProps {
    thread: MessageType[];
}

const LATEST_REPLIES_COUNT = 2;

const MessageHistoryThread = ({ thread }: MessageHistoryThreadProps) => {
    const { currentUser } = useContext(ConfigurationContext);
    const [messages, setMessages] = useState(orderBy(thread, "createdDate", "asc"));
    const [showAllReplies, setShowAllReplies] = useState(false);

    const { originalMessage, replies } = useMemo(() => ({
        originalMessage: messages.slice(0, 1)[0],
        replies: messages.slice(1, messages.length),
    }), [messages]);

    const latestReplies = useMemo(
        () => replies.slice(replies.length - LATEST_REPLIES_COUNT, replies.length),
        [replies]
    );

    const formattedDate = useMemo(() => formatDateTime(originalMessage!.createdDate), [originalMessage]);

    const onShowAll = useCallback((e) => {
        e.preventDefault();
        setShowAllReplies(true);
    }, []);

    const handleResponseSent = useCallback((message) => {
        setMessages((state) => [...state, message]);
    }, []);

    return (
        <Paper>
            <Box pt={3} px={5} pb={4}>
                <Box mb={3}>
                    <Box fontWeight={600}>
                        <Typography variant="h3">
                            Subject: {originalMessage!.subject}
                        </Typography>
                    </Box>
                    <Typography variant="caption">
                        {formattedDate}
                    </Typography>
                </Box>
                <Box mb={3}>
                    <MessageHistoryItem message={originalMessage!} />
                </Box>
                {!showAllReplies && replies.length > LATEST_REPLIES_COUNT ? (
                    <>
                        <Link href="" underline="always" onClick={onShowAll}>
                            Show {replies.length - LATEST_REPLIES_COUNT} more replies
                        </Link>
                        {latestReplies.map((reply, index) => (
                            <Box mt={index ? 0 : 3} mb={3} key={reply.id}>
                                <MessageHistoryItem message={reply} />
                            </Box>
                        ))}
                    </>
                ) : (
                    replies.map((reply) => (
                        <Box mb={3} key={reply.id}>
                            <MessageHistoryItem message={reply} />
                        </Box>
                    ))
                )}
                {currentUser && (
                    <MessageHistoryThreadReply
                        currentUser={currentUser}
                        originalMessage={originalMessage!}
                        onResponseSent={handleResponseSent}
                    />
                )}
            </Box>
        </Paper>
    );
};

export default MessageHistoryThread;