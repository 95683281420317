import * as React from "react";

function SvgResources(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={14}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g opacity={0.7} clipPath="url(#resources_svg__clip0)" fill="currentColor">
                <path d="M5.86 8.07a4.035 4.035 0 01-3.25-6.42A4.05 4.05 0 018.25.78c1.79 1.31 2.18 3.85.87 5.64-.64.87-1.58 1.44-2.64 1.6-.21.04-.42.05-.62.05zm.01-6.82c-.86 0-1.7.39-2.25 1.14a2.78 2.78 0 00.6 3.89 2.776 2.776 0 003.89-.6 2.78 2.78 0 00-.6-3.89 2.71 2.71 0 00-1.64-.54z" />
                <path d="M9.206 5.815l-.74 1.007 4.023 2.952.74-1.007-4.023-2.952zM13.11 13.818H0v1.25h13.11v-1.25zM9.36 10.209H0v1.25h9.36v-1.25z" />
            </g>
            <defs>
                <clipPath id="resources_svg__clip0">
                    <path fill="#fff" d="M0 0h13.23v15.07H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgResources;
