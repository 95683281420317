import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    field: {
        "& .MuiInputBase-root, & .MuiSelect-select:focus": {
            backgroundColor: theme.palette.common.white,
        },
    },
}));

export default useStyles;
