import * as React from "react";

function GradientHigh() {
    return (
        <linearGradient id="gradientHigh">
            <stop stopColor="#1BBAB0"/>
            <stop offset="0.5" stopColor="#0CC2CF"/>
            <stop offset="1" stopColor="#21DEFA"/>
        </linearGradient>
    );
}

export default GradientHigh;
