import * as React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import useStyles from "./styles";

export const CompaniesSkeleton = (): React.ReactElement => {
    const classes = useStyles();

    return (
        <>
            {[1, 2, 3, 4, 5, 6].map((index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Skeleton
                        variant="rect"
                        width="100%"
                        height={208}
                        className={classes.skeleton}
                    />
                </Grid>
            ))}
        </>
    );
};
