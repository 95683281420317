import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CompaniesContext } from "context";
import { LEAVE_PAGE_CONFIRMATION } from "types/constants";

export default function useLeavePageConfirmation(enabled: boolean) {
    const history = useHistory();
    const { setOnBeforeCompanyChange } = useContext(CompaniesContext);

    useEffect(() => {
        if (enabled) {
            setOnBeforeCompanyChange(() => window.confirm(LEAVE_PAGE_CONFIRMATION));
            const unblock = history.block(LEAVE_PAGE_CONFIRMATION);

            return () => {
                setOnBeforeCompanyChange(undefined);
                unblock();
            };
        }
    }, [history, enabled, setOnBeforeCompanyChange]);
}
