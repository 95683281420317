import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    planCell: {
        "&:not(:first-child)": {
            borderTopColor: theme.colors.structure.rulesAndBorders,
            borderTopStyle: "solid",
            borderTopWidth: 1,
        },
    },
}));

export default useStyles;
