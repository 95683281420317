import { v4 as uuidv4 } from "uuid";
import orderBy from "lodash/orderBy";
import { FcrItemConfiguration, IdValue } from "types";
import { ComponentType } from "types/constants";

export const buildIdValue = (data: Partial<IdValue>): IdValue => ({
    id: data.id ?? uuidv4(),
    value: data.value ?? "",
    order: data.order ?? 0,
    isActive: data.isActive ?? true,
});

export const getFcrItemConfigurationFormData = (item: FcrItemConfiguration | null, fcrConfigurationId: string): FcrItemConfiguration => ({
    id: item?.id ?? uuidv4(),
    fcrConfigurationId: item?.fcrConfigurationId ?? fcrConfigurationId,
    fcrItemTypeId: item?.fcrItemTypeId ?? ComponentType.multiselectCheckbox,
    elementHeader: item?.elementHeader ?? "",
    elementBody: item?.elementBody ?? "",
    isRequired: item?.isRequired ?? false,
    isVisibleToRep: item?.isVisibleToRep ?? true,
    itemValues: item?.itemValues ? orderBy(item?.itemValues, "order") : [buildIdValue({ order: 1 }), buildIdValue({ order: 2 })],
    order: item?.order ?? 0,
});

export const TYPES_DATA: [ComponentType, string, string][] = [
    [ComponentType.multiselectCheckbox, "Multiselect Checkbox", "Users can select one to many options from values entered."],
    [ComponentType.singleCheckbox, "Single Y/N Checkbox", "User can only select/deselect this option and Yes/No will be saved."],
    [ComponentType.radioButtons, "Radiobuttons", "Users can select only one option from multiple values entered."],
    [ComponentType.dropdownList, "Dropdown", "Users can select only one option from the values entered."],
    [ComponentType.freeInput, "Text Entry", "User will enter a written answer."],
    [ComponentType.compliance, "Compliance", "User can only select/deselect this one option. Always Required."],
];

export const ERROR_MESSAGES: Record<ComponentType.multiselectCheckbox | ComponentType.radioButtons | ComponentType.dropdownList, string> = {
    [ComponentType.multiselectCheckbox]: "At least 2 values are needed for Multiselect Checkboxes.",
    [ComponentType.radioButtons]: "At least 2 values are needed for Radiobuttons.",
    [ComponentType.dropdownList]: "At least 2 values are needed for Dropdown.",
};
