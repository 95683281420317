import * as React from "react";

function SvgTarget(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={20}
            height={19}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M6.9 5.50986C7.55 5.33986 8.33 5.70986 8.93 6.46986C9.14 6.73986 9.54 6.78986 9.81 6.57986C10.08 6.36986 10.13 5.97986 9.92 5.69986C9.01 4.52986 7.73999 3.99986 6.58999 4.28986C5.68999 4.51986 4.99 5.20986 4.6 6.21986C4.24 7.15986 4.2 8.27986 4.48 9.37986C4.77 10.4799 5.34 11.4399 6.11 12.0899C6.74 12.6199 7.44 12.8899 8.13 12.8899C8.35 12.8899 8.57 12.8599 8.78 12.8099C9.97 12.4999 10.83 11.3599 11.03 9.83986C11.07 9.49986 10.83 9.17986 10.49 9.13986C10.14 9.09986 9.83 9.33986 9.79 9.67986C9.66 10.6899 9.15 11.4299 8.47 11.5999C7.98 11.7299 7.43 11.5599 6.92 11.1299C6.35 10.6499 5.91 9.91986 5.69 9.06986C5.47 8.21986 5.5 7.36986 5.76 6.66986C6 6.04986 6.4 5.63986 6.9 5.50986Z" fill="currentColor" />
            <path d="M13.29 8.86018C13.26 8.52018 12.95 8.26018 12.61 8.29018C12.27 8.32018 12.01 8.62019 12.04 8.97019C12.33 12.2002 10.96 14.9502 8.78 15.5102C6.14 16.2002 3.2 13.6202 2.21 9.79018C1.72 7.89018 1.78 5.99019 2.38 4.42019C2.95 2.92019 3.95 1.92019 5.18 1.60019C7.31 1.05019 9.73999 2.65019 11.09 5.49019C11.24 5.80019 11.61 5.93019 11.92 5.79019C12.23 5.64019 12.36 5.27019 12.22 4.96019C10.61 1.57019 7.58 -0.299815 4.87 0.390185C3.23 0.810185 1.93 2.08019 1.21 3.97019C0.519997 5.78019 0.439996 7.96019 0.989996 10.1002C1.48 12.0102 2.41999 13.6402 3.58999 14.8202L1.83 17.6502C1.65 17.9402 1.74 18.3302 2.03 18.5102C2.13 18.5702 2.25 18.6002 2.36 18.6002C2.57 18.6002 2.77 18.5002 2.89 18.3102L4.54 15.6502C5.59 16.4202 6.76 16.8602 7.92 16.8602C8.31 16.8602 8.69 16.8102 9.07 16.7102C9.21 16.6702 9.35 16.6302 9.48 16.5802L10.56 18.3102C10.68 18.5002 10.88 18.6002 11.09 18.6002C11.2 18.6002 11.32 18.5702 11.42 18.5102C11.71 18.3302 11.8 17.9402 11.62 17.6502L10.6 16.0002C12.51 14.7002 13.57 12.0002 13.29 8.86018Z" fill="currentColor" />
            <path d="M19.04 4.80992L18.21 4.26992L18.34 3.30992C18.37 3.07992 18.27 2.85992 18.09 2.72992C17.91 2.58992 17.67 2.56992 17.46 2.66992L15.01 3.79992C14.81 3.88992 14.68 4.06992 14.65 4.28992L14.52 5.30992L7.83999 8.39992C7.52999 8.54992 7.39 8.91992 7.54 9.22992C7.65 9.45992 7.87 9.58992 8.11 9.58992C8.2 9.58992 8.29 9.56992 8.37 9.52992L15.05 6.43992L15.91 6.99992C16.01 7.06992 16.13 7.09992 16.25 7.09992C16.34 7.09992 16.43 7.07992 16.51 7.03992L18.96 5.90992C19.17 5.80992 19.3 5.61992 19.32 5.38992C19.34 5.14992 19.23 4.92992 19.04 4.80992ZM16.3 5.74992L15.77 5.39992L15.85 4.76992L16.95 4.25992L16.92 4.47992C16.89 4.71992 17 4.94992 17.2 5.07992L17.42 5.21992L16.3 5.74992Z" fill="currentColor" />
        </svg>
    );
}

export default SvgTarget;