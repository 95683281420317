import * as React from "react";

function SvgLearningLibrary(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons-/-16-/-Class" fill="currentColor">
          <path
            d="M16,9 L16,10.2 L2.8,10.2 C1.80588745,10.2 1,11.0058875 1,12 C1,12.9444069 1.72731342,13.7189407 2.65237201,13.7940331 L2.8,13.8 L16,13.8 L16,15 L3,15 C1.34314575,15 2.02906125e-16,13.6568542 0,12 C-2.02906125e-16,10.3431458 1.34314575,9 3,9 L16,9 Z M12,1 C13.6568542,1 15,2.34314575 15,4 C15,5.59768088 13.75108,6.90366088 12.1762728,6.99490731 L12,7 L2,7 L2,6 L12,6 C13.1045695,6 14,5.1045695 14,4 C14,2.8954305 13.1045695,2 12,2 L2,2 L2,1 L12,1 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SvgLearningLibrary;
