import React, { useContext, useEffect, useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import {
    FcrCoachingSkillObservation,
    DevelopmentGoals as DevelopmentGoalsType,
    CoachingPlanType,
    FcrCoachingSkill,
    FcrItemConfiguration,
    FcrItemResult,
    FcrResult,
    UserProfile,
    FcrCoachingSkillHistory,
    CoachingSession,
    FcrConfiguration,
    ProductSalesData,
} from "types";
import { ScrollContext, ConfigurationContext } from "context";
import { formatFullDate } from "helpers/dateHelpers";
import { isValidFcrCoachingSkillObservation } from "helpers/fcrHelper";

import Observations from "./Observations";
import DevelopmentGoals from "./DevelopmentGoals";
import CoachingPlans from "./CoachingPlans";
import Review from "./Review";
import Messages from "./Messages";

interface WizardContentProps {
    user: UserProfile;
    developmentGoals: DevelopmentGoalsType;
    fcrCoachingSkills: Array<FcrCoachingSkill>;
    fcrCoachingSkillObservations: Array<FcrCoachingSkillObservation>;
    isCoachingSkillObservationsFetching?: boolean;
    coachingPlans: Array<CoachingPlanType>;
    fcrItemConfigurations: Array<FcrItemConfiguration>;
    fcrItemResults: Array<FcrItemResult>;
    step: number;
    fcrCoachingSkillsHistory: FcrCoachingSkillHistory[];
    onChange: (value: DevelopmentGoalsType | FcrCoachingSkillObservation | Array<CoachingPlanType> | Array<FcrItemResult>) => void;
    moveToStep: (stepNumber: number) => void;
    addFcrCoachingSkillObservation: (value: FcrCoachingSkillObservation) => void;
    removeFcrCoachingSkillObservation: (id: string) => void;
    fcrResult?: FcrResult;
    showError: boolean;
    isSubmitLoading: boolean;
    coachingSession: CoachingSession;
    fcrConfiguration: FcrConfiguration;
    productSalesData?: ProductSalesData | null;
}

const defaultPX = { xs: 2, md: 8 };

function WizardContent({
    user,
    developmentGoals,
    fcrCoachingSkills,
    fcrCoachingSkillObservations,
    isCoachingSkillObservationsFetching,
    coachingPlans,
    fcrItemConfigurations,
    fcrItemResults,
    step,
    fcrCoachingSkillsHistory,
    onChange,
    moveToStep,
    addFcrCoachingSkillObservation,
    removeFcrCoachingSkillObservation,
    fcrResult,
    showError,
    isSubmitLoading,
    coachingSession,
    fcrConfiguration,
    productSalesData,
}: WizardContentProps) {
    const { scrollToTop } = useContext(ScrollContext);
    const { currentUser, fcrSectionConfiguration } = useContext(ConfigurationContext);

    const stepNames = useMemo(() => [
        fcrSectionConfiguration?.section1Label,
        fcrSectionConfiguration?.section2Label,
        fcrSectionConfiguration?.section3Label,
        "Review & Submit",
    ], [fcrSectionConfiguration]);

    useEffect(() => {
        scrollToTop(false);
    }, [scrollToTop, step]);

    const submitted = useMemo(() => step === 4, [step]);
    const filteredFcrCoachingSkillObservations = fcrCoachingSkillObservations.filter(isValidFcrCoachingSkillObservation);
    const filteredFcrCoachingSkillObservationsIds = filteredFcrCoachingSkillObservations.map((skill) => skill.id);
    const filteredCoachingPlans = coachingPlans.filter((plan) => filteredFcrCoachingSkillObservationsIds
        .includes(plan.fcrCoachingSkillResultId));

    const Content = useMemo(() => {
        switch (step) {
            case 0:
                return (
                    <Box px={defaultPX}>
                        <DevelopmentGoals
                            developmentGoals={developmentGoals}
                            onChange={onChange}
                            fcrResultStatusId={fcrResult?.fcrResultStatusId}
                            coachingSession={coachingSession}
                            productSalesData={productSalesData}
                            fcrConfiguration={fcrConfiguration}
                        />
                    </Box>
                )
            case 1:
                return (
                    <Box px={defaultPX}>
                        <Observations
                            fcrCoachingSkills={fcrCoachingSkills}
                            fcrCoachingSkillObservations={fcrCoachingSkillObservations}
                            fcrCoachingSkillsHistory={fcrCoachingSkillsHistory}
                            fcrConfiguration={fcrConfiguration}
                            isCoachingSkillObservationsFetching={isCoachingSkillObservationsFetching}
                            onChange={onChange}
                            addFcrCoachingSkillObservation={addFcrCoachingSkillObservation}
                            removeFcrCoachingSkillObservation={removeFcrCoachingSkillObservation}
                        />
                    </Box>
                )
            case 2:
                return (
                    <Box px={defaultPX}>
                        <CoachingPlans
                            fcrCoachingSkills={fcrCoachingSkills}
                            coachingPlans={filteredCoachingPlans}
                            fcrCoachingSkillObservations={filteredFcrCoachingSkillObservations}
                            fcrResultId={fcrResult?.id}
                            fcrCoachingSkillsHistory={fcrCoachingSkillsHistory}
                            onChange={onChange}
                            coachingSession={coachingSession}
                        />
                    </Box>
                )
            case 3:
            case 4:
                return (
                    <Box px={step === 3 ? 0 : defaultPX}>
                        <Review
                            submitted={submitted}
                            user={user}
                            developmentGoals={developmentGoals}
                            fcrCoachingSkillObservations={filteredFcrCoachingSkillObservations}
                            coachingPlans={filteredCoachingPlans}
                            fcrItemConfigurations={fcrItemConfigurations}
                            fcrItemResults={fcrItemResults}
                            fcrResult={fcrResult}
                            moveToStep={moveToStep}
                            onChange={onChange}
                            showError={showError}
                            isSubmitLoading={isSubmitLoading}
                            fcrConfiguration={fcrConfiguration}
                            productSalesData={productSalesData}
                            coachingSession={coachingSession}
                        />
                    </Box>
                )
        }
    }, [
        step,
        user,
        submitted,
        developmentGoals,
        fcrCoachingSkillsHistory,
        onChange,
        fcrCoachingSkills,
        fcrCoachingSkillObservations,
        isCoachingSkillObservationsFetching,
        filteredFcrCoachingSkillObservations,
        fcrItemConfigurations,
        fcrItemResults,
        addFcrCoachingSkillObservation,
        removeFcrCoachingSkillObservation,
        filteredCoachingPlans,
        moveToStep,
        fcrResult,
        showError,
        isSubmitLoading,
        coachingSession,
        fcrConfiguration,
        productSalesData,
    ]);

    const userName = useMemo(() =>
        currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "",
    [currentUser]
    );

    return (
        <Box pt={3} pb={4}>
            <Box px={defaultPX}>
                {showError && <Messages.ValidationError />}
                {submitted && <Messages.Submit />}
                {
                    !(submitted) ?
                        <>
                            <Typography variant="caption">Step {step + 1} of 4</Typography>
                            <Box mt={1} mb={3}>
                                <Typography variant="h1">
                                    {stepNames[step]}
                                </Typography>
                            </Box>
                        </> :
                        <Box pb={5.5}>
                            <Typography variant="h1">Coaching Report</Typography>
                            <Typography variant="caption">
                                Submitted by <b>{userName} &middot;</b> {formatFullDate(fcrResult?.modifiedDate || new Date())}
                            </Typography>
                        </Box>
                }
            </Box>
            {Content}
        </Box>
    );
}

export default WizardContent;