import React, { useMemo, MouseEventHandler } from "react";
import {
    ListItem,
    ListItemIcon,
    Checkbox,
    Typography,
} from "@material-ui/core";

import { TickIcon, ChevronRight } from "svgComponents";
import clsx from "clsx";
import { UserProfile, CompanyLevel } from "types";

import { buildLevelHierarchyObjectsToLevel, LevelHierarchy } from "helpers/companyHelper";

import useStyles from "./styles";

interface CustomListProps {
    user: UserProfile;
    checked: string[];
    handleToggle: (value: string) => MouseEventHandler<HTMLDivElement>;
    companyLevels: CompanyLevel[];
}

const CustomListItem = ({ user, checked, handleToggle, companyLevels }: CustomListProps) => {
    const classes = useStyles();

    const levelHierarchy: LevelHierarchy<CompanyLevel | null> = useMemo(() => (
        user.companyLevelId ? buildLevelHierarchyObjectsToLevel(user.companyLevelId, companyLevels) : {}
    ), [user.companyLevelId, companyLevels]);

    return (
        <ListItem
            role="listitem"
            button
            onClick={handleToggle(user.id)}
            className={clsx(
                { [classes.listItemActive]: checked.indexOf(user.id) !== -1 },
                { [classes.listItemDisabled]: !user.isActive },
                classes.listItem
            )}
        >
            <div className={clsx(
                { [classes.listItemWrapActive]: checked.indexOf(user.id) !== -1 },
                classes.listItemWrap
            )}>
                <div className={classes.listItemContent}>
                    <div className={classes.listItemName}>
                        <Typography variant="body1">{`${user.firstName} ${user.lastName}`}</Typography>
                    </div>
                    {user.level && 
                        <div className={classes.lvl}>
                            <div className={classes.lvlItem}>
                                {levelHierarchy[user.level]?.name && 
                                    <>
                                        <div className={classes.lvlArrow}>
                                            <ChevronRight height="12px" width="12px" />
                                        </div>
                                        <Typography className={classes.lvlLabel} variant="body2">
                                            {levelHierarchy[user.level]?.name}
                                        </Typography>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className={classes.listItemTick}>
                    <ListItemIcon className={classes.checkbox}>
                        <Checkbox
                            checked={checked.indexOf(user.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemIcon className={clsx(
                        { [classes.checkActive]: checked.indexOf(user.id) !== -1 },
                        classes.check
                    )}>
                        <TickIcon />
                    </ListItemIcon>
                </div>
            </div>
        </ListItem>
    );
};

export default CustomListItem;
