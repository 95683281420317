import React, { useContext, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import differenceWith from "lodash/differenceWith";
import LoadingButton from "components/LoadingButton";
import { useFormik, FormikProvider, FormikHelpers } from "formik";
import { Box, Button, Typography, Grid, MenuItem, FormHelperText } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { TickIcon } from "svgComponents";
import LevelFilter from "components/LevelFilter";
import PaperCard from "components/PaperCard";
import { FormSelect } from "components/Form";
import AssignTeamMembers from "components/AssignTeamMembers";
import { getResourceAssignments, getResourcesForCompany, updateResourceAssignments } from "services/resourceServices";
import { CompanyLevel, FcrResource, UserProfile, UserList } from "types";
import { AlertSeverity, ResourceTypeEnum } from "types/constants";
import { CompaniesContext, SnackbarContext } from "context";
import useLeavePageConfirmation from "hooks/useLeavePageConfirmation";
import { orderArrayUsers } from "helpers/usersHelpers";
import { AssignmentCaptionMessage } from "components/AssignmentMessages";

interface ResourceDistributionFormProps {
    resourceId: string;
    resourceType: ResourceTypeEnum;
}

const ResourceDistribution = () => {
    const { openSnackbar } = useContext(SnackbarContext);
    const { currentCompany, companyLevels } = useContext(CompaniesContext);
    const [resources, setResources] = useState<FcrResource[]>([]);
    const [isFetchingResources, setIsFetchingResources] = useState<boolean>(false);
    const [membersLevelIds, setMembersLevelIds] = useState<Array<CompanyLevel["id"]>>([]);
    const [userIds, setUserIds] = useState<UserProfile[]>([]);
    const [resourceData, setResourceData] = useState<FcrResource | null>(null);

    const [availableMembers, setAvailableMembers] = useState<UserProfile[] | null>(null);
    const [totalAmountAvailableMembers, setTotalAmountAvailableMembers] = useState<number>(0);
    const [distributionMembers, setDistributionMembers] = useState<UserProfile[] | null>(null);
    const [totalAmountDistributionMembers, setTotalAmountDistributionMembers] = useState<number>(0);

    const [availableMembersPage, setAvailableMembersPage] = useState<number | null>(null);
    const [distributionMemberPage, setDistributionMemberPage] = useState<number | null>(null);

    const [leftCheckedUserIds, setLeftCheckedUserIds] = useState<string[]>([]);
    const [rightCheckedUserIds, setRightCheckedUserIds] = useState<string[]>([]);

    const setCheckedUserIds = (leftUserIds: string[] | null, rightUserIds: string[] | null) => {
        if (leftUserIds) {
            setLeftCheckedUserIds([...leftCheckedUserIds, ...leftUserIds]);
            setRightCheckedUserIds(differenceWith(rightCheckedUserIds, leftUserIds));
        }
        if (rightUserIds) {
            setRightCheckedUserIds([...rightCheckedUserIds, ...rightUserIds]);
            setLeftCheckedUserIds(differenceWith(leftCheckedUserIds, rightUserIds));
        }
    };

    const getDistributionMembers = (resourceType: ResourceTypeEnum, resourceId: string, pageNumber = 1) =>
        getResourceAssignments(resourceType, resourceId, pageNumber);

    const getAvailableMembers = (resourceType: ResourceTypeEnum, resourceId: string, pageNumber = 1) =>
        getResourceAssignments(resourceType, resourceId, pageNumber, false);

    const handleSubmit = (
        { resourceId, resourceType }: ResourceDistributionFormProps,
        { setSubmitting }: FormikHelpers<ResourceDistributionFormProps>
    ) => {
        Promise.all([
            // Remove users from Available Members
            updateResourceAssignments(resourceType, resourceId, leftCheckedUserIds, false),

            // Add users to Distribution Members
            updateResourceAssignments(resourceType, resourceId, rightCheckedUserIds),
        ])
            .then(() => {
                openSnackbar("Resources distribution was successfully saved.", AlertSeverity.success);

                getAvailableMembers(resourceType, resourceId).then((userList: UserList) => {
                    setAvailableMembers(orderArrayUsers(userList.results) || null);
                    setTotalAmountAvailableMembers(userList.totalAmount);
                });
                getDistributionMembers(resourceType, resourceId).then((userList: UserList) => {
                    setDistributionMembers(orderArrayUsers(userList.results) || null);
                    setTotalAmountDistributionMembers(userList.totalAmount);
                });
            })
            .catch(() => openSnackbar("Unexpected error occurred.", AlertSeverity.error))
            .finally(() => {
                setSubmitting(false);
            });
    };

    const formik = useFormik({
        initialValues: {
            resourceId: "",
            resourceType: ResourceTypeEnum.document,
        },
        validationSchema: Yup.object().shape({
            resourceId: Yup.string().required("Required"),
        }),
        onSubmit: handleSubmit,
    });

    const { values, errors, touched, submitCount, isSubmitting, resetForm, submitForm, setValues } = formik;

    const hasChanges = useMemo(
        () =>
            !!values.resourceId &&
            !isEqual(userIds.map(({ id }) => id).sort(), distributionMembers && distributionMembers.map(({ id }) => id).sort()),
        [values.resourceId, userIds, distributionMembers]
    );

    useLeavePageConfirmation(hasChanges);

    useEffect(() => {
        if (currentCompany) {
            setIsFetchingResources(true);
            getResourcesForCompany(currentCompany.id)
                .then((resources) => setResources(orderBy(resources, "name")))
                .finally(() => setIsFetchingResources(false));
        }

        resetForm();
    }, [resetForm, currentCompany]);

    const handleResourceChange: SelectInputProps["onChange"] = (e) => {
        const resource = resources.find((resource) => resource.id === e.target.value);
        setResourceData(resource || null);

        if (resource) {
            setValues({ resourceId: resource.id, resourceType: resource.type });
            setDistributionMembers(null);
            setAvailableMembers(null);
            setAvailableMembersPage(1);
            setDistributionMemberPage(1);
            getDistributionMembers(resource.type, resource.id).then((userList: UserList) => {
                setDistributionMembers(orderArrayUsers(userList.results) || null);
                setTotalAmountDistributionMembers(userList.totalAmount);
            });
            getAvailableMembers(resource.type, resource.id).then((userList: UserList) => {
                setAvailableMembers(orderArrayUsers(userList.results) || null);
                setTotalAmountAvailableMembers(userList.totalAmount);
            });
        }
    };

    const fetchMembers = (pageNumber: number, getAssigned: boolean) => {
        if (!resourceData) return;

        getResourceAssignments(resourceData.type, resourceData.id, pageNumber, getAssigned).then((userList: UserList) => {
            if (getAssigned) {
                const newDistributionMembers =
                    distributionMembers && distributionMembers.length && userList.results.length
                        ? orderArrayUsers([...distributionMembers, ...userList.results])
                        : userList.results;
                setDistributionMembers(newDistributionMembers);
            } else {
                const newAvailableMembers =
                    availableMembers && availableMembers.length && userList.results.length
                        ? orderArrayUsers([...availableMembers, ...userList.results])
                        : userList.results;
                setAvailableMembers(newAvailableMembers);
            }
        });
    };

    const showError = !!errors.resourceId && submitCount > 0 && touched.resourceId;

    useEffect(() => {
        if (!resourceData) return;

        if (availableMembers && !availableMembers?.length && availableMembersPage && totalAmountAvailableMembers) {
            getAvailableMembers(resourceData.type, resourceData.id, availableMembersPage + 1).then((userList: UserList) => {
                setAvailableMembers(orderArrayUsers(userList.results) || null);
                setAvailableMembersPage(availableMembersPage + 1);
            });
        }
    }, [availableMembers, availableMembersPage, resourceData, totalAmountAvailableMembers]);

    useEffect(() => {
        if (!resourceData) return;

        if (distributionMembers && !distributionMembers?.length && distributionMemberPage && totalAmountDistributionMembers) {
            getDistributionMembers(resourceData.type, resourceData.id, distributionMemberPage + 1).then((userList: UserList) => {
                setDistributionMembers(orderArrayUsers(userList.results) || null);
                setDistributionMemberPage(distributionMemberPage + 1);
            });
        }
    }, [distributionMemberPage, distributionMembers, resourceData, totalAmountDistributionMembers]);

    return (
        <>
            <Box mb={6} display="flex" alignItems="center" justifyContent="space-between" minHeight="46px" flexWrap="wrap">
                <Typography variant="h1">Resources Distribution</Typography>
                {values.resourceId && (
                    <Box display="flex">
                        <Box mr={2}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                loading={isSubmitting}
                                loadingLabel="Updating..."
                                startIcon={<TickIcon />}
                                onClick={submitForm}>
                                Save updates
                            </LoadingButton>
                        </Box>
                        <Button variant="outlined" size="large" onClick={() => resetForm()}>
                            Cancel
                        </Button>
                    </Box>
                )}
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <PaperCard>
                        <FormikProvider value={formik}>
                            <Box fontWeight={600} mb={3}>
                                <Typography variant="h2">Distribution</Typography>
                            </Box>
                            <Box mb={3}>
                                <Typography variant="caption">Select a resource to assign team members below.</Typography>
                            </Box>
                            <Box mb={4}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <FormSelect
                                            name="resourceId"
                                            label={!isFetchingResources && !resources.length ? "No Resources" : "Resource"}
                                            error={showError}
                                            disabled={isFetchingResources || !resources.length}
                                            onChange={handleResourceChange}
                                            loading={isFetchingResources}>
                                            {resources.map((resource) => (
                                                <MenuItem key={resource.id} value={resource.id}>
                                                    {resource.name}
                                                </MenuItem>
                                            ))}
                                        </FormSelect>
                                        {showError && <FormHelperText error>{errors.resourceId}</FormHelperText>}
                                    </Grid>
                                </Grid>
                            </Box>
                            {values.resourceId && (
                                <AssignTeamMembers
                                    availableMembers={availableMembers}
                                    totalAmountAvailableMembers={totalAmountAvailableMembers}
                                    setTotalAmountAvailableMembers={setTotalAmountAvailableMembers}
                                    setAvailableMembers={setAvailableMembers}
                                    availableMembersPage={availableMembersPage}
                                    setAvailableMembersPage={setAvailableMembersPage}
                                    distributionMembers={distributionMembers}
                                    totalAmountDistributionMembers={totalAmountDistributionMembers}
                                    setTotalAmountDistributionMembers={setTotalAmountDistributionMembers}
                                    setDistributionMembers={setDistributionMembers}
                                    distributionMemberPage={distributionMemberPage}
                                    setDistributionMemberPage={setDistributionMemberPage}
                                    fetchMembers={fetchMembers}
                                    setCheckedUserIds={setCheckedUserIds}
                                    membersLevelIds={membersLevelIds}
                                    companyLevels={companyLevels}
                                    setUserIds={setUserIds}
                                    isSubmitting={isSubmitting}
                                    description={
                                        <Box mb={2}>
                                            <AssignmentCaptionMessage />
                                        </Box>
                                    }
                                />
                            )}
                        </FormikProvider>
                    </PaperCard>
                </Grid>
                {values.resourceId && (
                    <Grid item xs={12} md={4}>
                        <PaperCard>
                            <LevelFilter<true>
                                multiple
                                companyLevels={companyLevels}
                                onFilter={setMembersLevelIds}
                                isSubmitting={isSubmitting}
                            />
                        </PaperCard>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ResourceDistribution;
