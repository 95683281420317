import React, { useMemo } from "react";
import { Box, Paper, Typography } from "@material-ui/core";

import MultilineInput from "components/MultilineInput";
import RichText from "components/RichText";
import PerformanceMetrics, { PerformanceMetricsVariant } from "routes/Dashboard/DashboardContent/PerformanceMetrics";
import { DevelopmentGoals as DevelopmentGoalsType, CoachingSession, FcrConfiguration, ProductSalesData } from "types";
import { FcrStatusEnum, LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW } from "types/constants";

import { getDevGoalsFields } from "../../config";

interface DevelopmentGoalsProps {
    developmentGoals: DevelopmentGoalsType;
    onChange: (value: DevelopmentGoalsType) => void;
    fcrResultStatusId?: string;
    coachingSession: CoachingSession;
    productSalesData?: ProductSalesData | null;
    fcrConfiguration: FcrConfiguration;
}

function DevelopmentGoals({
    developmentGoals,
    onChange,
    fcrResultStatusId,
    coachingSession,
    productSalesData,
    fcrConfiguration,
}: DevelopmentGoalsProps) {
    const mapValue = new Map(Object.entries(developmentGoals));
    const { fcrResult } = coachingSession;

    const isTeamMemberView = localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW);

    const isNotEditable = useMemo(
        () =>
            !!isTeamMemberView &&
            (fcrResult?.submittedByCoacheeDate || fcrResult?.createdById === fcrResult?.coachId ||
                fcrResult?.createdById !== fcrResult?.coachId &&
                fcrResultStatusId !== FcrStatusEnum.new && fcrResultStatusId !== FcrStatusEnum.development_goals),
        [fcrResult?.submittedByCoacheeDate, fcrResultStatusId, isTeamMemberView, fcrResult?.createdById, fcrResult?.coachId]
    );

    return (
        <Paper>
            <Box px={{ xs: 3, md: 6 }} py={{ xs: 1.5, md: 3 }}>
                {getDevGoalsFields(fcrConfiguration).map(({ fieldName, label, placeholder }, index) =>
                    isNotEditable ?
                        <Typography key={fieldName} variant="body1" component="div">
                            <Box mt={4} mb={2} fontWeight={600}>
                                {label}
                            </Box>
                            <RichText
                                text={
                                    developmentGoals &&
                                    developmentGoals[fieldName as keyof typeof DevelopmentGoals] || ""
                                } />
                        </Typography> :
                        <MultilineInput
                            key={index}
                            name={label}
                            placeholder={placeholder}
                            value={mapValue.get(fieldName) || ""}
                            onChange={(value: string) => onChange({
                                ...developmentGoals,
                                [fieldName]: value,
                            } as DevelopmentGoalsType)}
                        />
                )}
                {productSalesData && (
                    <Box mt={2}>
                        <PerformanceMetrics
                            productSalesData={productSalesData}
                            variant={PerformanceMetricsVariant.FCR}
                        />
                    </Box>
                )}
            </Box>
        </Paper>
    )
}

export default DevelopmentGoals;
