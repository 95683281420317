import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { Box, Stepper, Step, StepLabel, Typography, useMediaQuery, Theme } from "@material-ui/core";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { Checkmark } from "svgComponents";
import { ConfigurationContext } from "context";
import { buildSectionLabelsArray } from "helpers/fcrHelper";

import useStyles from "./styles";

interface WizardStepperProps {
    step: number;
    moveToStep: (step: number) => void;
    completedSteps?: { [k: number]: boolean; };
}

function WizardStepper({ step, moveToStep, completedSteps }: WizardStepperProps) {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

    const { fcrSectionConfiguration } = useContext(ConfigurationContext);

    const sections = useMemo(() => buildSectionLabelsArray(fcrSectionConfiguration), [fcrSectionConfiguration]);

    const WizardStepIcon = useMemo(() => (index: number) => (props: StepIconProps) => {
        const { active, completed } = props;

        const Icon = () => {
            switch (true) {
                case completed:
                case active && completedSteps && completedSteps[index]:
                    return <Checkmark />
                case active:
                    return (
                        <Typography className={classes.activeText}>
                            {index + 1}
                        </Typography>
                    )
                default:
                    return (
                        <Typography className={classes.disabledText}>
                            {index + 1}
                        </Typography>
                    )
            }
        };

        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="32" height="32"
                borderRadius="4"
                fontSize="12"
                className={clsx(classes.icon,
                    active && classes.active,
                    completed && classes.completed,
                    !(active || completed) && classes.disabled)}>
                {Icon()}
            </Box>
        )
    }, [classes, completedSteps]);

    const getCompletedStep = useCallback((index) => completedSteps && completedSteps[index], [completedSteps])

    return (
        <Box pt={1} pb={2}>
            <Stepper
                orientation={isMobile ? "vertical" : "horizontal"}
                activeStep={step}
            >
                {sections.map((label, index) => (
                    <Step key={index} completed={getCompletedStep(index)}>
                        <StepLabel
                            className={classes.stepLabel}
                            onClick={() => (index < step) && moveToStep(index)}
                            StepIconComponent={WizardStepIcon(index)}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export default WizardStepper