import React, { useCallback, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import CoachingSessionName from "components/CoachingSessionName";
import { CoachingSession } from "types";
import { formatDay, formatMonth, formatTime } from "helpers/dateHelpers";

import useStyles from "./styles";

interface SelectCoachingSessionItemProps {
    coachingSession: CoachingSession;
    onSelect: (session: CoachingSession) => void;
    isSelected: boolean;
}

const SelectCoachingSessionItem = ({ onSelect, coachingSession, isSelected }: SelectCoachingSessionItemProps) => {
    const classes = useStyles();

    const handleSelect = useCallback(
        () => onSelect(coachingSession),
        [onSelect, coachingSession]
    );
    
    const [
        formattedMonth,
        formattedDay,
        formattedTime,
    ] = useMemo(() => [
        formatMonth(coachingSession.scheduleStartDate),
        formatDay(coachingSession.scheduleStartDate),
        coachingSession.isAllDayEvent ? "All Day" : formatTime(coachingSession.scheduleStartDate),
    ], [coachingSession.scheduleStartDate, coachingSession.isAllDayEvent]);

    return (
        <Box display="flex" p={1} borderRadius={5} onClick={handleSelect} className={clsx(classes.coachingSessionItem, {
            selected: isSelected,
        })}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-end"
                borderRadius={5}
                className={classes.dateBlock}
                fontWeight={500}
                height={65}
                width={65}
                mr={3}
            >
                <Typography variant="body2">
                    {formattedMonth}
                </Typography>
                <Typography variant="h6" component="span">
                    {formattedDay}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography variant="caption">
                    Coaching Session
                </Typography>
                <Box display="flex" fontWeight={500} mt={0.5}>
                    <Box display="flex" mr={0.5}>
                        <CoachingSessionName coachingSessionType={coachingSession.coachingSessionType} />
                    </Box>
                    <Typography variant="h3" component="span">
                        &middot; {formattedTime}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default SelectCoachingSessionItem;