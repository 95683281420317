import React, { useCallback, useContext, useMemo, useState } from "react";
import {
    Dialog,
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
    CircularProgress,
} from "@material-ui/core";
import uniqBy from "lodash/uniqBy";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MultilineInput from "components/MultilineInput";
import { UsersAutocomplete } from "components/UsersAutocomplete";
import Input from "components/Input";
import { getSortedUserProfiles } from "helpers/userProfileHelper";
import { addMessage } from "services/messageServices";
import { getUsersBelow } from "services/userServices";
import { ConfigurationContext, SnackbarContext } from "context";
import { MessageType, UserProfile } from "types";
import useAsync from "hooks/useAsync";

import useStyles from "./styles";

interface MessageModalProps {
    user?: UserProfile;
    usersBelow?: UserProfile[] | null;
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: (messages: MessageType[]) => void;
}

function MessageModal({ isOpen, user, usersBelow: preLoadedUsersBelow, onClose, onSuccess }: MessageModalProps) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { openSnackbar } = useContext(SnackbarContext);
    const { currentUser, coacheeTeam, coachedTeam, isConfigurationFetching } = useContext(ConfigurationContext);

    const { value: usersBelow } = useAsync<UserProfile[] | null>(async () => {
        if (preLoadedUsersBelow === undefined) return currentUser?.id ? await getUsersBelow(currentUser.id) : null;
        return preLoadedUsersBelow;
    }, [currentUser?.id, preLoadedUsersBelow]);

    const isDataFetching = !usersBelow || isConfigurationFetching;

    const users = useMemo(() => {
        if (isDataFetching) return [];

        const lowerLevelUsers = usersBelow ?? [];
        const asCoachTeamMembers = coachedTeam?.userMembers ?? [];
        const asCoachTeamCoLeaders = coachedTeam?.coLeaders ?? [];
        const asCoacheeTeamMembers = coacheeTeam?.userMembers ?? [];
        const asCoacheeTeamCoLeaders = coacheeTeam?.coLeaders ?? [];
        const asCoacheeTeamLeaders = coacheeTeam?.teamLeader ? [coacheeTeam?.teamLeader] : [];

        const allUsers = uniqBy([
            ...(user ? [user] : []),
            ...asCoachTeamMembers,
            ...asCoachTeamCoLeaders,
            ...asCoacheeTeamMembers,
            ...asCoacheeTeamCoLeaders,
            ...asCoacheeTeamLeaders,
            ...lowerLevelUsers,
        ], "id").filter((user) => (
            user.id !== currentUser?.id
        ));

        return getSortedUserProfiles(allUsers);
    }, [coachedTeam?.coLeaders, coachedTeam?.userMembers, coacheeTeam?.coLeaders, coacheeTeam?.teamLeader, coacheeTeam?.userMembers, currentUser?.id, isDataFetching, user, usersBelow]);

    const [isSending, setIsSending] = useState<boolean>(false);
    const [messageContent, setMessageContent] = useState<string>("");
    const [asignees, setAsignees] = useState<Array<UserProfile["id"]>>(user ? [user.id] : []);
    const [subject, setSubject] = useState<string>("");

    const sendMessage = useCallback(() => {
        if (!asignees.length) return;

        setIsSending(true);
        Promise.all(asignees.map((asignee) => addMessage(subject, messageContent, asignee)))
            .then((messages) => {
                openSnackbar("Message was sent to team member.");
                onSuccess && onSuccess(messages);
                onClose();
            })
            .catch(() => {
                setIsSending(false);
            });
    }, [subject, messageContent, asignees, onClose, openSnackbar, onSuccess]);

    return (
        <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} fullWidth>
            <Box px={4} pt={3} pb={4.5}>
                <Box pb={4}>
                    <Typography variant="h1">New Message</Typography>
                </Box>
                <UsersAutocomplete<true>
                    className={classes.usersAutocomplete}
                    multiple={true}
                    label="Assignee"
                    noOptionsText="No matches"
                    userProfiles={users}
                    value={isDataFetching ? [] : asignees}
                    disableClearable={false}
                    onChange={setAsignees}
                    hasAvatar={true}
                    popupIcon={<ExpandMore />}
                    loading={isDataFetching}
                />
                <Box py={3}>
                    <Input onChangeWithError={setSubject} placeholder="Subject" />
                </Box>
                <MultilineInput value={messageContent} onChange={setMessageContent} placeholder="Write a message..." />
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    pt={4}
                >
                    <Button
                        variant="contained" color="primary" size="large"
                        onClick={sendMessage}
                        disabled={!(asignees.length && messageContent && subject) || isSending}
                    >
                        {isSending ? <CircularProgress size={24} /> : "Send Message"}
                    </Button>
                    <Box pl={2} color="text.secondary">
                        <Button
                            variant="outlined" color="inherit" size="large"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

export default MessageModal;
