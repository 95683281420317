import React from "react";
import { Box, Typography, Dialog, CircularProgress } from "@material-ui/core";

interface SubmitLoadingModalProps {
    open: boolean;
    message: string;
}

function SubmitLoadingModal({ open, message }: SubmitLoadingModalProps) {
    return (
        <Dialog open={open}>
            <Box pt={7.5} px={13.5} pb={9}>
                <Box display="flex" flexDirection="column" justifyItems="center">
                    <Typography variant="h1">{message}</Typography>
                    <Box display="flex" justifyContent="center" pt={1} pb={6}>
                        <Typography variant="caption">This will only take a few moments...</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                    <Box display="flex" justifyContent="center" fontWeight="500" pt={3}>
                        <Typography variant="body2">Submitting...</Typography>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}

export default SubmitLoadingModal;
