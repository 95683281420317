import React, { useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { Box, Divider, Typography } from "@material-ui/core";

import AvatarWithScore from "components/AvatarWithScore";
import SvgCloseCross from "svgComponents/CloseCross";
import { getRelativeTime } from "helpers/dateHelpers";
import { NotificationType } from "types";
import { SizeEnum } from "types/constants";
import { ConfigurationContext, CoachingHubContext } from "context";

import useStyles from "./styles";

interface NotificationItemProps {
    notification: NotificationType;
    handleClose: () => void;
    onDelete: (id: string) => void;
    isDividerShown?: boolean;
}

const NotificationItem = ({ notification, isDividerShown = false, onDelete, handleClose }: NotificationItemProps) => {
    const classes = useStyles();
    const { fetchMessages } = useContext(CoachingHubContext);
    const { createdAt, mainTextBeforeLink, mainTextAfterLink, linkText, href, from, id } = notification;
    const { companyUsers } = useContext(ConfigurationContext);

    const relativeTime = useMemo(
        () => createdAt && getRelativeTime(createdAt),
        [createdAt]
    );

    const user = useMemo(
        () => companyUsers && companyUsers.find(e => e.id === from),
        [companyUsers, from]
    );

    const handleDelete = useCallback(() => {
        onDelete(id);
    }, [id, onDelete]);

    const handleLinkClick = useCallback(() => {
        if (linkText === "message") fetchMessages();
        handleClose();
    }, [fetchMessages, handleClose, linkText]);

    return user ? (
        <>
            <div className={classes.notificationItem}>
                <AvatarWithScore className={classes.avatar} user={user} size={SizeEnum.small} />
                <div className={classes.notificationContent}>
                    <Typography variant="body1" className={classes.notificationName}>
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Typography variant="body1" component="div" paragraph>
                        {mainTextBeforeLink}
                        {linkText && href && (
                            <Box display="inline" color="primary.main" mx={0.5}>
                                <Link to={href} onClick={handleLinkClick} className={classes.link}>{linkText}</Link>
                            </Box>
                        )}
                        {mainTextAfterLink}
                    </Typography>
                    <Typography variant="caption">
                        {relativeTime}
                    </Typography>
                </div>
                <div className="close-icon">
                    <SvgCloseCross onClick={handleDelete}/>
                </div>
            </div>
            {isDividerShown && <Divider/>}
        </>
    ) : null;
}

export default React.memo(NotificationItem);