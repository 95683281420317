import React, { useState, useContext, useEffect } from "react";
import { CompaniesContext } from "context";
import { Grid, Button } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "svgComponents";
import { CompanyLevel, UserProfile } from "types";
import unionBy from "lodash/unionBy";
import filter from "lodash/filter";
import map from "lodash/map";
import without from "lodash/without";
import intersectionWith from "lodash/intersectionWith";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";

import usePrevious from "hooks/usePrevious";

import CustomList from "./CustomList";
import useStyles from "./styles";

const getUsersByIds = (users: UserProfile[], ids: UserProfile["id"][]): UserProfile[] => (
    intersectionWith(users, ids, (user, id) => user.id === id)
);

const getActiveUsersByIds = (users: UserProfile[], ids: UserProfile["id"][]): UserProfile[] => (
    intersectionWith(users, ids, (user, id) => user.id === id && user.isActive !== false)
);

interface TransferListProps {
    potentialMembers?: UserProfile[];
    distributionMembers?: UserProfile[];
    leftTitle?: string;
    rightTitle?: string;
    membersLevelIds?: Array<CompanyLevel["id"]>;
    companyLevels: CompanyLevel[];
    setUserIds: (ids: UserProfile[]) => void;
}

export default function TransferList({
    leftTitle,
    rightTitle,
    membersLevelIds = [],
    potentialMembers,
    distributionMembers,
    companyLevels,
    setUserIds,
}: TransferListProps) {
    const classes = useStyles();
    const previousPotentialMembers = usePrevious(potentialMembers);
    const previousDistributionMembers = usePrevious(distributionMembers);
    const { companyUsers } = useContext(CompaniesContext);
    const fallbackedPotentialUsers = potentialMembers ? potentialMembers : companyUsers;
    const allUsers = orderBy(unionBy(fallbackedPotentialUsers, distributionMembers, "id"), ["isActive", "firstName", "lastName"], ["desc"]);
    const filteredAllUsers = membersLevelIds.length ? filter(allUsers, (user) => membersLevelIds.includes(user.companyLevelId!)) : allUsers;
    const availableUsers = (potentialMembers && !potentialMembers.length) ? [] : filteredAllUsers;
    const [rightUsersIds, setRightUsersIds] = useState<UserProfile["id"][]>(map(distributionMembers ?? [], "id"));
    const [rightUsersCheckedIds, setRightUsersCheckedIds] = useState<UserProfile["id"][]>([]);
    const leftUsersIds = without(map(availableUsers, "id"), ...rightUsersIds);
    const [leftUsersCheckedIds, setLeftUsersCheckedIds] = useState<UserProfile["id"][]>([]);

    const handleMoveLeft = () => {
        const rightUncheckedUsersIds = without(rightUsersIds, ...rightUsersCheckedIds);
        const rightUncheckedUsers = getUsersByIds(allUsers, rightUncheckedUsersIds);

        setRightUsersIds(rightUncheckedUsersIds);
        setRightUsersCheckedIds([]);
        setUserIds(rightUncheckedUsers);
    };

    const handleMoveRight = () => {
        const rightUsersChecked = [...rightUsersIds, ...leftUsersCheckedIds];

        setRightUsersIds(rightUsersChecked);
        setLeftUsersCheckedIds([]);
        setUserIds(getUsersByIds(allUsers, rightUsersChecked));
    };

    useEffect(() => {
        const previousPotentialMembersIds = map(previousPotentialMembers, "id");
        const previousDistributionMembersId = map(previousDistributionMembers, "id");
        const newPotentialMembersIds = map(potentialMembers, "id");
        const newDistributionMembersIds = map(distributionMembers, "id");

        const reset = () => {
            setLeftUsersCheckedIds([]);
            setRightUsersIds(newDistributionMembersIds);
            setRightUsersCheckedIds([]);
            setUserIds(distributionMembers ?? []);
        };

        if (
            !isEqual(previousPotentialMembersIds, newPotentialMembersIds) ||
            !isEqual(previousDistributionMembersId, newDistributionMembersIds)
        ) return reset();
    }, [previousPotentialMembers, previousDistributionMembers, potentialMembers, distributionMembers, setUserIds]);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item className={classes.column}>
                <CustomList
                    title={leftTitle ?? "Available Members"}
                    usersIds={leftUsersIds}
                    users={getActiveUsersByIds(availableUsers, leftUsersIds)}
                    checked={leftUsersCheckedIds}
                    companyLevels={companyLevels}
                    setChecked={setLeftUsersCheckedIds}
                    resetChecked={setRightUsersCheckedIds}
                />
            </Grid>
            <Grid item className={classes.columnCenter}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        className={classes.arrowBtn}
                        onClick={handleMoveRight}
                        disabled={leftUsersCheckedIds.length === 0}
                        aria-label="move selected right"
                    >
                        <ArrowRight />
                    </Button>
                    <Button
                        className={classes.arrowBtn}
                        onClick={handleMoveLeft}
                        disabled={rightUsersCheckedIds.length === 0 || availableUsers.length === 0}
                        aria-label="move selected left"
                    >
                        <ArrowLeft />
                    </Button>
                </Grid>
            </Grid>
            <Grid item className={classes.column}>
                <CustomList
                    title={rightTitle ?? "Distribution Members"}
                    usersIds={rightUsersIds}
                    users={getUsersByIds(allUsers, rightUsersIds)}
                    checked={rightUsersCheckedIds}
                    companyLevels={companyLevels}
                    setChecked={setRightUsersCheckedIds}
                    resetChecked={setLeftUsersCheckedIds}
                />
            </Grid>
        </Grid>
    );
}
