import * as React from "react";

function SvgCalendarList(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={16}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.82 2.51H12.37V1.37C12.37 1.03 12.09 0.75 11.75 0.75C11.41 0.75 11.13 1.03 11.13 1.37V2.51H4.5V1.41C4.5 1.07 4.22 0.790001 3.88 0.790001C3.54 0.790001 3.25999 1.07 3.25999 1.41V2.51H2.81C1.36 2.51 0.190002 3.69 0.190002 5.13V15.17C0.190002 16.62 1.37 17.79 2.81 17.79H12.85C14.3 17.79 15.47 16.61 15.47 15.17V5.14C15.45 3.69 14.27 2.51 12.82 2.51ZM14.2 15.17C14.2 15.93 13.58 16.55 12.82 16.55H2.78C2.02 16.55 1.39999 15.93 1.39999 15.17V5.14C1.39999 4.38 2.02 3.76 2.78 3.76H3.23V4.66C3.23 5 3.51001 5.28 3.85001 5.28C4.19001 5.28 4.47 5 4.47 4.66V3.76H11.1V4.62C11.1 4.96 11.38 5.24 11.72 5.24C12.06 5.24 12.34 4.96 12.34 4.62V3.76H12.79C13.55 3.76 14.17 4.38 14.17 5.14V15.17H14.2Z" fill="currentColor" />
            <path d="M11.8 7.75977H3.82001C3.48001 7.75977 3.2 8.03977 3.2 8.37977C3.2 8.71977 3.48001 8.99977 3.82001 8.99977H11.8C12.14 8.99977 12.42 8.71977 12.42 8.37977C12.42 8.03977 12.14 7.75977 11.8 7.75977Z" fill="currentColor" />
            <path d="M11.8 10.3398H3.82001C3.48001 10.3398 3.2 10.6198 3.2 10.9598C3.2 11.2998 3.48001 11.5798 3.82001 11.5798H11.8C12.14 11.5798 12.42 11.2998 12.42 10.9598C12.42 10.6198 12.14 10.3398 11.8 10.3398Z" fill="currentColor" />
            <path d="M9.85001 12.9297H3.82001C3.48001 12.9297 3.2 13.2097 3.2 13.5497C3.2 13.8897 3.48001 14.1697 3.82001 14.1697H9.85001C10.19 14.1697 10.47 13.8897 10.47 13.5497C10.47 13.2097 10.19 12.9297 9.85001 12.9297Z" fill="currentColor" />
        </svg>
    );
}

export default SvgCalendarList;
