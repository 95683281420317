import React, { useMemo } from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { Spinner } from "svgComponents";

interface LoadingButton extends ButtonProps {
    loading?: boolean;
    loadingLabel?: string | React.ReactNode;
    component?: string;
}

import useStyles from "./styles";

export default function LoadingButton({
    children,
    color = "primary",
    disableElevation = true,
    loading = false,
    loadingLabel = "Loading...",
    size = "large",
    startIcon,
    variant = "contained",
    ...props
}: LoadingButton) {
    const classes = useStyles();
    const icon = useMemo(() => loading ? <Spinner className={classes.spinner} /> : startIcon, [ classes.spinner, loading, startIcon ]);

    return (
        <Button
            {...props}
            color={color}
            disableElevation={disableElevation}
            size={size}
            startIcon={icon}
            variant={variant}
        >
            <span className={classes.label}>{loading ? loadingLabel : children}</span>
        </Button>
    )
}
