import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },
        back: {
            display: "inline-block",
        },
        selectedTab: {
            color: theme.palette.primary.light,
        },
        title: {
            height: theme.spacing(5.75),
        },
    })
);

export default useStyles;
