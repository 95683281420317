import React from "react";
import { Box, Typography } from "@material-ui/core";
import IconBox from "components/IconBox";
import { SizeEnum } from "types/constants";

interface IconBlockProps {
    label: string;
    icon: React.ReactNode;
    number: number | string;
}

const IconBlock = ({ label, icon, number }: IconBlockProps) => (
    <Box display="flex" alignItems="center" flexShrink={0}>
        <IconBox size={SizeEnum.large} icon={icon} color="primary.light" bgcolor="rgba(81, 169, 190, 0.2)" />
        <Box display="flex" flexDirection="column" justifyContent="space-between" mx={3}>
            <Typography variant="caption">
                {label}
            </Typography>
            <Typography variant="subtitle2">
                {number}
            </Typography>
        </Box>
    </Box>
);

export default IconBlock;