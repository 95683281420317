import { makeStyles } from "@material-ui/core"; 

const useStyles = makeStyles(() => ({
    link: {
        "&, &:visited": {
            color: "inherit",
        },
        "&:hover": {
            textDecoration: "none",
        },
    },
}));

export default useStyles;
