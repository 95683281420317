import React from "react";
import { Box, Typography } from "@material-ui/core";
import { formatFullDate } from "helpers/dateHelpers";
import { FullEvaluationDto } from "types/Evaluation.dto";

import Messages from "./Messages";
interface TraineeEvaluationHeaderProps {
    evaluation: FullEvaluationDto;
    isSubmitted: boolean;
    completed: boolean;
}

export default function TraineeEvaluationHeader({ evaluation, isSubmitted, completed }: TraineeEvaluationHeaderProps) {
    return (
        <Box mx={2} px={{ xs: 2, md: 8, lg: 13.5 }} pb={{ xs: 1, lg: 3 }} pt={4}>
            {isSubmitted && <Messages.Submitted />}
            <Box>
                <Typography variant="h1">{evaluation.class.title}</Typography>
                <Typography variant="caption">
                    {completed && (
                        <>
                            Submitted by <b>{evaluation.evaluationResult.submittedBy} &middot; </b>
                        </>
                    )}
                    {formatFullDate(evaluation.evaluationResult.submittedDate || new Date())}
                </Typography>
            </Box>
        </Box>
    );
}
