import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    link: {
        color: theme.palette.primary.main,
        textDecoration: "underline",
    },
    coachFullName: {
        textDecoration: "underline",
    },
    isExpanded: {
        transform: "rotate(180deg)",
    },
}));

export default useStyles;
