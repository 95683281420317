import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, MenuItem, Select } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { EvaluationsAutocomplete } from "components/EvaluationsAutocomplete";
import { SortDirectionEnum } from "types/constants";
import { TraineeClassInfoDto } from "types/Certification";
import { TuneIcon } from "svgComponents";

import useStyles from "./styles";

interface TraineeClassesFiltersProps {
    classes: TraineeClassInfoDto[];
    onFilter: (filteredtrainees: TraineeClassInfoDto[]) => void;
}

function TraineeClassesFilters({ classes, onFilter }: TraineeClassesFiltersProps) {
    const styles = useStyles();
    const [classId, setClassId] = useState<TraineeClassInfoDto["id"] | null>(null);
    const [sortDirection, setSortDirection] = useState<SortDirectionEnum>(SortDirectionEnum.desc);

    const autocompleteItems = useMemo(() => classes.map(({ id, className }) => ({ id, label: className })), [classes]);

    const filter = useCallback(
        (classId?: TraineeClassInfoDto["id"] | null) => {
            const filtered = classId ? classes.filter(({ id }) => id === classId) : classes;
            filtered.sort((a, b) => {
                const timeA = new Date(a.classDate).getTime();
                const timeB = new Date(b.classDate).getTime();
                return sortDirection === SortDirectionEnum.desc ? timeB - timeA : timeA - timeB;
            });

            return onFilter([...filtered]);
        },
        [classes, onFilter, sortDirection]
    );

    const handleClassFilter = (classId: TraineeClassInfoDto["id"] | null) => {
        setClassId(classId);
        filter(classId);
    };

    const handleSortDirectionFilter = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = e.target.value as SortDirectionEnum;
        setSortDirection(value);
        filter();
    };

    useEffect(() => {
        filter();
    }, [filter]);

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box minWidth={250} mr={2}>
                <EvaluationsAutocomplete
                    label={"All Classes"}
                    items={autocompleteItems}
                    value={classId}
                    popupIcon={<FilterListIcon />}
                    onChange={handleClassFilter}
                />
            </Box>
            <Select
                variant="outlined"
                value={sortDirection}
                onChange={handleSortDirectionFilter}
                IconComponent={TuneIcon}
                className={styles.filter}>
                <MenuItem value={SortDirectionEnum.desc}>Most Recent</MenuItem>
                <MenuItem value={SortDirectionEnum.asc}>Oldest</MenuItem>
            </Select>
        </Box>
    );
}

export default React.memo(TraineeClassesFilters) as typeof TraineeClassesFilters;
