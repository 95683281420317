import React, { useContext, useState, useMemo, useEffect } from "react";
import { Box, Typography, Grid, Button, MenuItem } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { useFormik, FormikProvider, FormikHelpers } from "formik";
import * as Yup from "yup";
import isEqual from "lodash/isEqual";
import differenceWith from "lodash/differenceWith";
import { CompaniesContext, SnackbarContext } from "context";
import LoadingButton from "components/LoadingButton";
import { FormSelect } from "components/Form";
import PaperCard from "components/PaperCard";
import LevelFilter from "components/LevelFilter";
import AssignTeamMembers from "components/AssignTeamMembers";
import { UserProfile, CompanyLevel, UserList } from "types";
import { AlertSeverity } from "types/constants";
import { TickIcon } from "svgComponents";
import { getFcrCoachingSkillUsers, updateAssignments } from "services/fcrCoachingSkillServices";
import useLeavePageConfirmation from "hooks/useLeavePageConfirmation";
import { orderArrayUsers } from "helpers/usersHelpers";
import { AssignmentCaptionMessage } from "components/AssignmentMessages";

interface SkillDistributionFormProps {
    skillId: string;
}

function SkillDistribution() {
    const { openSnackbar } = useContext(SnackbarContext);
    const { fcrCoachingSkillsForCompany, isFetchingCompanyData, currentCompany, companyLevels } = useContext(CompaniesContext);
    const [membersLevelIds, setMembersLevelIds] = useState<Array<CompanyLevel["id"]>>([]);
    const [userIds, setUserIds] = useState<UserProfile[]>([]);
    const [fcrCoachingSkillId, setFcrCoachingSkillId] = useState<string | null>(null);

    const [availableMembers, setAvailableMembers] = useState<UserProfile[] | null>(null);
    const [totalAmountAvailableMembers, setTotalAmountAvailableMembers] = useState<number>(0);
    const [distributionMembers, setDistributionMembers] = useState<UserProfile[] | null>(null);
    const [totalAmountDistributionMembers, setTotalAmountDistributionMembers] = useState<number>(0);

    const [availableMembersPage, setAvailableMembersPage] = useState<number>(1);
    const [distributionMemberPage, setDistributionMemberPage] = useState<number>(1);

    const [leftCheckedUserIds, setLeftCheckedUserIds] = useState<string[]>([]);
    const [rightCheckedUserIds, setRightCheckedUserIds] = useState<string[]>([]);

    const setCheckedUserIds = (leftUserIds: string[] | null, rightUserIds: string[] | null) => {
        if (leftUserIds) {
            setLeftCheckedUserIds([...leftCheckedUserIds, ...leftUserIds]);
            setRightCheckedUserIds(differenceWith(rightCheckedUserIds, leftUserIds));
        }
        if (rightUserIds) {
            setRightCheckedUserIds([...rightCheckedUserIds, ...rightUserIds]);
            setLeftCheckedUserIds(differenceWith(leftCheckedUserIds, rightUserIds));
        }
    };

    const getDistributionMembers = (skillId: string, pageNumber = 1) => getFcrCoachingSkillUsers(skillId, pageNumber);

    const getAvailableMembers = (skillId: string, pageNumber = 1) => getFcrCoachingSkillUsers(skillId, pageNumber, false);

    const handleSubmit = ({ skillId }: SkillDistributionFormProps, { setSubmitting }: FormikHelpers<SkillDistributionFormProps>) => {
        const getAssignments = (userIds: string[], assign = true) => ({
            fcrCoachingSkillId: skillId,
            userIds: userIds,
            assign: assign,
        });

        Promise.all([
            // Remove users from Available Members
            updateAssignments(getAssignments(leftCheckedUserIds, false)),

            // Add users to Distribution Members
            updateAssignments(getAssignments(rightCheckedUserIds)),
        ])
            .then(() => {
                openSnackbar("Skill distribution was successfully saved.", AlertSeverity.success);

                getAvailableMembers(skillId).then((userList: UserList) => {
                    setAvailableMembers(orderArrayUsers(userList.results) || null);
                    setTotalAmountAvailableMembers(userList.totalAmount);
                });

                getDistributionMembers(skillId).then((userList: UserList) => {
                    setDistributionMembers(orderArrayUsers(userList.results) || null);
                    setTotalAmountDistributionMembers(userList.totalAmount);
                });
            })
            .catch(() => openSnackbar("Unexpected error occurred.", AlertSeverity.error))
            .finally(() => {
                setSubmitting(false);
            });
    };

    const formik = useFormik({
        initialValues: {
            skillId: "",
        },
        validationSchema: Yup.object().shape({
            skillId: Yup.string().required("Required"),
        }),
        onSubmit: handleSubmit,
    });

    const { values, errors, touched, submitCount, isSubmitting, resetForm, submitForm, setValues } = formik;

    const hasChanges = useMemo(
        () => !!values.skillId && !isEqual(userIds.map(({ id }) => id).sort(), distributionMembers?.map(({ id }) => id).sort()),
        [values.skillId, userIds, distributionMembers]
    );

    useLeavePageConfirmation(hasChanges);

    useEffect(resetForm, [resetForm, currentCompany]);

    const handleSkillChange: SelectInputProps["onChange"] = (e) => {
        const skillId = e.target.value as string;
        setValues({ skillId });
        setFcrCoachingSkillId(skillId || null);

        if (skillId) {
            setDistributionMembers(null);
            setAvailableMembers(null);
            setAvailableMembersPage(1);
            setDistributionMemberPage(1);
            getDistributionMembers(skillId).then((userList: UserList) => {
                setDistributionMembers(orderArrayUsers(userList.results) || null);
                setTotalAmountDistributionMembers(userList.totalAmount);
            });
            getAvailableMembers(skillId).then((userList: UserList) => {
                setAvailableMembers(orderArrayUsers(userList.results) || null);
                setTotalAmountAvailableMembers(userList.totalAmount);
            });
        }
    };

    const fetchMembers = (pageNumber: number, getAssigned: boolean) => {
        if (!fcrCoachingSkillId) return;

        getFcrCoachingSkillUsers(fcrCoachingSkillId, pageNumber, getAssigned).then((userList: UserList) => {
            if (getAssigned) {
                const newDistributionMembers =
                    distributionMembers && distributionMembers.length && userList.results.length
                        ? orderArrayUsers([...distributionMembers, ...userList.results])
                        : userList.results;
                setDistributionMembers(newDistributionMembers);
            } else {
                const newAvailableMembers =
                    availableMembers && availableMembers.length && userList.results.length
                        ? orderArrayUsers([...availableMembers, ...userList.results])
                        : userList.results;
                setAvailableMembers(newAvailableMembers);
            }
        });
    };

    const showError = !!errors.skillId && submitCount > 0 && touched.skillId;

    useEffect(() => {
        if (!fcrCoachingSkillId) return;

        if (availableMembers && !availableMembers?.length && availableMembersPage && totalAmountAvailableMembers) {
            getAvailableMembers(fcrCoachingSkillId, availableMembersPage + 1).then((userList: UserList) => {
                setAvailableMembers(orderArrayUsers(userList.results) || null);
                setAvailableMembersPage(availableMembersPage + 1);
            });
        }
    }, [availableMembers, availableMembersPage, fcrCoachingSkillId, totalAmountAvailableMembers]);

    useEffect(() => {
        if (!fcrCoachingSkillId) return;

        if (distributionMembers && !distributionMembers?.length && distributionMemberPage && totalAmountDistributionMembers) {
            getDistributionMembers(fcrCoachingSkillId, distributionMemberPage + 1).then((userList: UserList) => {
                setDistributionMembers(orderArrayUsers(userList.results) || null);
                setDistributionMemberPage(distributionMemberPage + 1);
            });
        }
    }, [distributionMemberPage, distributionMembers, fcrCoachingSkillId, totalAmountDistributionMembers]);

    return (
        <>
            <Box mb={6} display="flex" alignItems="center" justifyContent="space-between" minHeight="46px" flexWrap="wrap">
                <Typography variant="h1">Skill Distribution</Typography>
                {values.skillId && (
                    <Box display="flex">
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            startIcon={<TickIcon />}
                            loading={isSubmitting}
                            loadingLabel="Saving..."
                            onClick={submitForm}>
                            <Box lineHeight="30px">Save updates</Box>
                        </LoadingButton>

                        <Box ml={2.5}>
                            <Button variant="outlined" size="large" onClick={() => resetForm()}>
                                <Box lineHeight="30px">Cancel</Box>
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Box mb={2.5}>
                        <PaperCard>
                            <FormikProvider value={formik}>
                                <Box display="flex" alignItems="center" fontWeight={600} minHeight="36px">
                                    <Typography variant="h2">Distribution</Typography>
                                </Box>
                                <Box mb={3} mt={2}>
                                    <Typography variant="caption">Select a skill to assign team members below.</Typography>
                                </Box>
                                <Box mb={4} mt={2}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <FormSelect
                                                name="skillId"
                                                label={
                                                    !isFetchingCompanyData && !fcrCoachingSkillsForCompany.length ? "No Skills" : "Skill"
                                                }
                                                error={showError}
                                                disabled={isFetchingCompanyData || !fcrCoachingSkillsForCompany.length}
                                                onChange={handleSkillChange}
                                                loading={isFetchingCompanyData}>
                                                {fcrCoachingSkillsForCompany.map((skill) => (
                                                    <MenuItem key={skill.id} value={skill.id}>
                                                        {skill.title}
                                                    </MenuItem>
                                                ))}
                                            </FormSelect>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {values.skillId && (
                                    <AssignTeamMembers
                                        availableMembers={availableMembers}
                                        totalAmountAvailableMembers={totalAmountAvailableMembers}
                                        setTotalAmountAvailableMembers={setTotalAmountAvailableMembers}
                                        setAvailableMembers={setAvailableMembers}
                                        availableMembersPage={availableMembersPage}
                                        setAvailableMembersPage={setAvailableMembersPage}
                                        distributionMembers={distributionMembers}
                                        totalAmountDistributionMembers={totalAmountDistributionMembers}
                                        setTotalAmountDistributionMembers={setTotalAmountDistributionMembers}
                                        setDistributionMembers={setDistributionMembers}
                                        distributionMemberPage={distributionMemberPage}
                                        setDistributionMemberPage={setDistributionMemberPage}
                                        fetchMembers={fetchMembers}
                                        setCheckedUserIds={setCheckedUserIds}
                                        membersLevelIds={membersLevelIds}
                                        companyLevels={companyLevels}
                                        setUserIds={setUserIds}
                                        isSubmitting={isSubmitting}
                                        description={
                                            <Box mb={2}>
                                                <AssignmentCaptionMessage />
                                            </Box>
                                        }
                                    />
                                )}
                            </FormikProvider>
                        </PaperCard>
                    </Box>
                </Grid>
                {values.skillId && (
                    <Grid item xs={12} md={4}>
                        <PaperCard>
                            <LevelFilter<true> multiple companyLevels={companyLevels} onFilter={setMembersLevelIds} />
                        </PaperCard>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default SkillDistribution;
