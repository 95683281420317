import * as React from "react";

function SvgMenuLink(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g opacity={0.8}>
                <g opacity={0.7} fill="#7C8EA3">
                    <path d="M16.9 10.38H7.19v1.25h9.71v-1.25zM16.9 0H7.19v1.25h9.71V0zM17.08 5.19H7.37v1.25h9.71V5.19z" />
                </g>
                <path
                    d="M2.79 8.776L0 5.973 2.79 3.18l.88.884-1.9 1.909 1.9 1.919-.88.884z"
                    fill="#7C8EA3"
                    opacity={0.7}
                />
            </g>
        </svg>
    );
}

export default SvgMenuLink;
