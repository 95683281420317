import * as React from "react";

function SvgBubbleWithCheckmarkRedDot(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.211 16.26v-2.52h-1.26c-.69 0-1.26-.57-1.26-1.26V4.92c0-.69.57-1.26 1.26-1.26h10.08c.691 0 1.26.57 1.26 1.26v7.56c0 .69-.569 1.26-1.26 1.26h-5.46l-3.36 2.52z"
        stroke="#51A9BE"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <g clipPath="url(#BubbleWithCheckmarkRedDot_svg__clip0_0_1)">
        <path
          d="M5.563 8.758l1.625 1.693 3.285-3.387"
          stroke="#51A9BE"
          strokeWidth={1.5}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <circle cx={15} cy={4} r={4} fill="#DD3328" />
      <defs>
        <clipPath id="BubbleWithCheckmarkRedDot_svg__clip0_0_1">
          <path
            fill="#fff"
            transform="translate(5 6.5)"
            d="M0 0h6.04v4.516H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBubbleWithCheckmarkRedDot;
