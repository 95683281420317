import React from "react";
import { RadioGroup, RadioGroupProps } from "@material-ui/core";
import { useField } from "formik";

function FormRadioGroup({ name = "", children, ...props }: RadioGroupProps) {
    const [field] = useField(name);

    return (
        <RadioGroup {...field} {...props}>
            {children}
        </RadioGroup>
    );
}

export default FormRadioGroup;
