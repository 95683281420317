import React, { useMemo, useState } from "react";
import { Box, Paper, Button, useTheme, CircularProgress, FormHelperText } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { FcrCoachingSkillRating } from "types";
import { TickIcon, DeleteIcon } from "svgComponents";
import { FormInput, FormMultilineInput } from "components/Form";
import LoadingButton from "components/LoadingButton";
import { getSkillRatingFormData } from "helpers/skillHelper";
import { deleteFcrCoachingSkillRating, updateFcrCoachingSkillRating, updateFcrCoachingSkillRatingLeadingInfo } from "services/fcrCoachingSkillServices";

import useStyles from "./styles";

export interface SkillRatingFormProps {
    skillId: string;
    skillRating: FcrCoachingSkillRating | null;
    allRatings?: FcrCoachingSkillRating[];
    onSuccess: (skillId?: string) => Promise<void>;
    onClose: () => void;
}

const RANGE_ERROR = <Box component="span" whiteSpace="nowrap">Entered range cannot intersect with existing ranges</Box>;
const MIN_LESS_THAN_MAX_ERROR = <Box component="span" whiteSpace="nowrap">Min should be less than Max</Box>;

const getSkillRatingFormSchema = (existingRanges: number[][]) => Yup.object().shape({
    fcrCoachingSkillRatingLeadingInfos: Yup.array().of(
        Yup.object().shape({
            title: Yup.string().required("Required"),
        })
    ),
    min: Yup.lazy((min: number) => (
        Yup.number().min(0, "Required").required("Required").when("max", (max: number, schema) => {
            if (max > 0) {
                const validateIntersections = () => !existingRanges.some(([rangeMin, rangeMax]) =>
                    min >= rangeMin && min < rangeMax
                    || max > rangeMin && max <= rangeMax
                    || min <= rangeMin && max >= rangeMax
                );

                return schema
                    .lessThan(max, MIN_LESS_THAN_MAX_ERROR)
                    .test({ test: validateIntersections, message: RANGE_ERROR });
            }
            return schema;
        })
    )),
    max: Yup.number().positive("Required").required("Required"),
});

function SkillRatingForm({
    skillId,
    skillRating,
    allRatings,
    onClose,
    onSuccess,
}: SkillRatingFormProps) {
    const theme = useTheme();
    const classes = useStyles();
    const [isDeleting, setDeleting] = useState<boolean>(false);

    const initialValues = useMemo(
        () => getSkillRatingFormData(skillRating, skillId),
        [skillRating, skillId]
    );

    const existingRanges = useMemo(() => {
        if (!allRatings) return [];

        return allRatings
            .filter((rating) => rating.id !== skillRating?.id)
            .map((rating) => ([rating.min, rating.max]));
    }, [allRatings, skillRating]);

    const formik = useFormik<FcrCoachingSkillRating>({
        enableReinitialize: true,
        initialValues,
        validationSchema: getSkillRatingFormSchema(existingRanges),
        onSubmit(values, { setSubmitting }) {
            updateFcrCoachingSkillRating(values)
                .then(() => updateFcrCoachingSkillRatingLeadingInfo(values.fcrCoachingSkillRatingLeadingInfos[0]))
                .then(() => onSuccess())
                .then(onClose)
                .finally(() => setSubmitting(false));
        },
    });

    const handleDelete = () => {
        if (skillRating) {
            setDeleting(true);
            deleteFcrCoachingSkillRating(skillRating.id)
                .then(onSuccess)
                .then(onClose)
                .finally(() => setDeleting(false));
        }
    };

    const { isSubmitting, submitForm } = formik;

    return (
        <FormikProvider value={formik}>
            <Paper className={classes.paperEdit}>
                <Box px={4} py={3}>
                    <Box mb={2}>
                        <FormInput
                            name="fcrCoachingSkillRatingLeadingInfos[0].title"
                            label="Title"
                        />
                    </Box>
                    <Box>
                        <FormMultilineInput
                            name="fcrCoachingSkillRatingLeadingInfos[0].body"
                            placeholder="Description"
                        />
                    </Box>
                    {skillRating && (
                        <Box mt={2}>
                            <FormHelperText error>
                                Warning: Any updates to Skill Rating Ranges will impact all completed reports and calculations.
                            </FormHelperText>
                        </Box>
                    )}
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Box flexWrap="no-wrap" flex="1 1 auto" mt={2}>
                            <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="space-between"
                            >
                                <Box
                                    display="flex"
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    width="140px"
                                    flexWrap="no-wrap"
                                    flex="1 1 auto"
                                    pr={1.75}
                                >
                                    <Box width="50px" flex="1 1 auto">
                                        <FormInput
                                            name="min"
                                            label="Min"
                                            type="number"
                                            className={classes.numberField}
                                        />
                                    </Box>
                                    <Box mx={0.5} flex="1 1 30px" textAlign="center" mt={1.5}>
                                        -
                                    </Box>
                                    <Box width="50px" flex="1 1 auto">
                                        <FormInput
                                            name="max"
                                            label="Max"
                                            type="number"
                                            className={classes.numberField}
                                        />
                                    </Box>
                                </Box>
                                <Box px={1.75} minWidth="140px" flex="1 1 auto" />
                            </Box>
                        </Box>
                        <Box flexWrap="no-wrap" flex="1 1 auto" mt={2}>
                            <Box
                                display="flex"
                                alignItems="stretch"
                                justifyContent="flex-end"
                                height="46px"
                                gridGap={theme.spacing(1.75)}
                            >
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    loading={isSubmitting}
                                    loadingLabel={skillRating ? "Saving..." : "Creating..."}
                                    disableElevation
                                    startIcon={<TickIcon />}
                                    onClick={submitForm}
                                >
                                    {skillRating ? "Save" : "Create"}
                                </LoadingButton>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    onClick={onClose}
                                >
                                    <Box>Cancel</Box>
                                </Button>
                                {skillRating && (
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        onClick={handleDelete}
                                        disabled={isDeleting}
                                    >
                                        {isDeleting ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <DeleteIcon
                                                width={20}
                                                height={20}
                                                color={theme.palette.text.secondary}
                                            />
                                        )}
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </FormikProvider>
    );
}

export default SkillRatingForm;
