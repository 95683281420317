import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface AutocompleteStylesProps {
    isPopupIconFixed: boolean;
}

const useStyles = makeStyles<Theme, AutocompleteStylesProps>((theme: Theme) => createStyles({
    root: {
        "& .MuiAutocomplete-inputRoot": {
            paddingTop: "7px",
            paddingBottom: "7px",
            background: "#fff",
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            paddingTop: "5px",
            paddingBottom: "5px",
        },
    },
    paper: {
        boxShadow: theme.shadows[8],
    },
    tag: {
        "&.MuiChip-root": {
            margin: "2px",
            height: "28px",
            border: `1px solid ${theme.palette.grey[400]}`,
            fontSize: "12px",
            background: "none",
        },
        "& ~ input": {
            position: "absolute",
        },
    },
    popupIndicatorOpen: ({ isPopupIconFixed }) => ({
        transform: isPopupIconFixed ? "none" : undefined,
    }),
    focused: {
        "& input": {
            position: "static",
        },
    },
    endAdornment: {
        top: "9px",
    },
}));

export default useStyles;
