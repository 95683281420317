import React, { useMemo } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";

import { SizeEnum } from "types/constants";
import { calculatePercentFromRange } from "helpers/numberHelper";

import useStyles from "./styles";

export enum ScoreBarMode {
    LIGHT = "light",
    DARK = "dark",
}

interface ScoreBarProps {
    score?: number;
    scoreCaption?: string;
    displayNumericalScore: boolean;
    size: SizeEnum;
    minRating: number;
    maxRating: number;
    children: React.ReactNode;
    mode?: ScoreBarMode;
}

const ScoreBar = ({ score, scoreCaption = "", size, displayNumericalScore, minRating, maxRating, children, mode = ScoreBarMode.LIGHT }: ScoreBarProps) => {
    const classes = useStyles({ mode });
    const progressValue = useMemo(
        () => score && calculatePercentFromRange(score, minRating, maxRating),
        [score, minRating, maxRating]
    );

    const graphColorClass = useMemo(() => {
        if (!progressValue) return;
        switch (true) {
            case progressValue < 40:
                return classes.graphLowest;
            case progressValue < 60:
                return classes.graphLow;
            case progressValue < 80:
                return classes.graphMedium;
            default:
                return classes.graphHigh;
        }
    }, [progressValue, classes]);

    const progressBarSize = useMemo(() => {
        switch (size) {
            case SizeEnum.xs:
                return 32;
            case SizeEnum.small:
                return 40;
            case SizeEnum.mediumSmaller:
                return 48;
            case SizeEnum.mediumSmall:
                return 64;
            case SizeEnum.medium:
                return 96;
            case SizeEnum.large:
            default:
                return 112;
        }
    }, [size]);

    const progressBarThickness = useMemo(() => {
        switch (size) {
            case SizeEnum.xs:
            case SizeEnum.small:
                return 2;
            case SizeEnum.mediumSmall:
            case SizeEnum.medium:
            case SizeEnum.large:
            default:
                return 1;
        }
    }, [size]);

    return (
        <div className={classes.scoreBar}>
            <CircularProgress
                variant="determinate"
                value={100}
                size={progressBarSize}
                thickness={progressBarThickness}
                className={classes.graphBottom}
            />
            <CircularProgress
                variant="determinate"
                value={progressValue}
                size={progressBarSize}
                thickness={progressBarThickness}
                className={classes.graphBottom}
                classes={{ circle: clsx(classes.graphCircle, graphColorClass) }}
            />
            {displayNumericalScore && (
                <div
                    className={classes.scoreWrapper}>
                    <Typography className={classes.scoreValue} variant="h3">
                        {score?.toFixed(1)}
                    </Typography>
                    {scoreCaption.length > 0 && (
                        <Typography className={classes.scoreCaption} variant="caption">
                            {scoreCaption}
                        </Typography>
                    )}
                </div>
            )}
            {children}
        </div>
    );
};

export default React.memo(ScoreBar);