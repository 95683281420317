import React from "react";
import { Route, Switch } from "react-router-dom";
import { AdminRoutings } from "types/constants";

import MemberListPage from "./MemberListPage";
import MemberPage from "./MemberPage";

export default function AdminMembers() {
    
    return (
        <Switch>
            <Route path={[
                AdminRoutings.addMember,
                `${AdminRoutings.members}/:userId`,
            ]}>
                <MemberPage />
            </Route>
            <Route>
                <MemberListPage />
            </Route>
        </Switch>
    )
}
