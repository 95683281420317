import React, { useState, useCallback } from "react";
import { Box, Button, Divider, Grid, Typography, CircularProgress } from "@material-ui/core";

import { PlusIcon } from "svgComponents";
import AdminListItem from "components/AdminListItem";

import BccEmailsModal from "./BccEmailsModal";

interface BccEmailsProps {
    bccEmails?: Array<string> | null;
    onSuccess: (bccEmails: Array<string> | null) => void;
    isFetching: boolean;
}

export default function BccEmails({ bccEmails, onSuccess, isFetching }: BccEmailsProps) {
    const [isModalOpened, setModalOpened] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<string | null>(null);

    const addEmail = useCallback((item: string) => {
        bccEmails ? onSuccess([...bccEmails, item]) : onSuccess([item])
    }, [bccEmails, onSuccess]);

    const editEmail = useCallback((item: string, editedItem: string) => {
        if (bccEmails?.length) {
            const emailIndex = bccEmails.indexOf(`${item}`);
            const emailsAfterEditItem = bccEmails
            // eslint-disable-next-line functional/immutable-data
            emailsAfterEditItem[emailIndex] = editedItem
            onSuccess(emailsAfterEditItem);
            setEditItem(null);
        }
    }, [bccEmails, onSuccess]);

    const handleDelete = useCallback((item: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
       
        if (bccEmails) {
            const emailAfterDeleteItem = bccEmails.length > 1 ? bccEmails.filter(((email) => email !== item)) : [];
            onSuccess(emailAfterDeleteItem);
            setEditItem(null);
            setModalOpened(false);
        }
    }, [bccEmails, onSuccess]);

    const handleEditList = useCallback((item: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setModalOpened(true);
        setEditItem(item);
    }, []);

    const handleClose = useCallback(() => {
        setModalOpened(false);
        setEditItem(null);
    }, []);

    const handleCreate = useCallback(() => {
        setModalOpened(true);
        setEditItem(null);
    }, []);

    return (
        <>
            <Box py={{ xs: 3, md: 3 }} px={{ xs: 3, md: 5 }}>
                <Grid alignItems="center" justifyContent="space-between" container spacing={1}>
                    <Grid item>
                        <Typography variant="h2">
                            <Box component="span" fontWeight={600}>Registration Email BCC</Box>
                        </Typography>
                    </Grid>
                    {!isFetching && 
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                disableFocusRipple
                                startIcon={<PlusIcon />}
                                onClick={handleCreate}
                            >
                                <Box lineHeight="30px">New Email BCC</Box>
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Box>
            {!isFetching && bccEmails &&
                bccEmails.map((email) => (
                    <React.Fragment key={email}>
                        <Divider />
                        <AdminListItem
                            bodyProps={{ py: 2.5 }}
                            title={email}
                            onDelete={handleDelete(email)}
                            onEdit={handleEditList(email)}
                            onClick={handleEditList(email)}
                        />
                    </React.Fragment>
                ))
            }
            {isModalOpened && (
                <BccEmailsModal
                    item={editItem}
                    open={isModalOpened}
                    onClose={handleClose}
                    onSuccess={addEmail}
                    onEdit={editEmail}
                    onDelete={handleDelete(editItem!)}
                />
            )}
            {isFetching && 
                <Box px={6} pt={4} pb={3} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            }  
        </>
    )
}
