import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { FormInput } from "components/Form";
import { COMPANY_LEVELS } from "helpers/companyHelper";
import SvgStructure from "svgComponents/Structure";
import { AdminRoutings } from "types/constants";
import { CompaniesContext } from "context";

interface LevelLabelsFormProps {
    isLoading: boolean;
}

function LevelLabelsForm({ isLoading }: LevelLabelsFormProps) {
    const history = useHistory();
    const { currentCompany } = useContext(CompaniesContext);

    const handleManageStructure = () => {
        history.push(AdminRoutings.companyStructure);
    };

    return (
        <Box p={4}>
            <Box fontWeight={600} mb={3} display="flex" alignItems="center">
                <Typography variant="h2">
                    Level Labels
                </Typography>
                {isLoading && (
                    <Box ml={2}>
                        <CircularProgress size={17} />
                    </Box>
                )}
            </Box>
            <Box mb={3}>
                <Typography variant="caption">
                    All level labels are required, even if all levels are not used within the team hierarchies.
                    This is to facilitate the management of the organizational structure.
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {COMPANY_LEVELS.map((level) => (
                    <Grid item xs={12} sm={6} key={level}>
                        <FormInput
                            name={`levelLabels[${level - 1}].name`}
                            label={`Level ${level} Label`}
                            required
                        />
                    </Grid>
                ))}
                {currentCompany && (
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth
                            startIcon={<SvgStructure />}
                            disabled={!currentCompany.isActive}
                            onClick={handleManageStructure}
                        >
                            Manage Organizational Structure
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default LevelLabelsForm;
