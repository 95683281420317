import React, { useContext, useState } from "react";
import { Box, FormControlLabel, Typography, Tooltip, FormHelperText } from "@material-ui/core";
import { FormikHelpers, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ImportResult } from "types";
import { CompaniesContext } from "context";
import { importProductSalesData } from "services/importService";
import useOnCompanyChange from "hooks/useOnCompanyChange";
import LoadingButton from "components/LoadingButton";
import FileUploader from "components/FileUploader";
import PaperCard from "components/PaperCard";
import Checkbox from "components/Checkbox";
import ImportResultAlert from "components/ImportResult";
import { formatDateTime } from "helpers/dateHelpers";

interface ProductImportForm {
    file?: File;
    replaceLatestImport: boolean;
}

export default function ProductImport() {
    const { currentCompany, getAllCompaniesList } = useContext(CompaniesContext);
    const [importResult, setImportResult] = useState<ImportResult>();

    const handleSubmit = (
        { file, replaceLatestImport }: ProductImportForm,
        { setSubmitting }: FormikHelpers<ProductImportForm>
    ) => {
        if (!file || !currentCompany) return;

        setImportResult(undefined);
        importProductSalesData(currentCompany.id, replaceLatestImport, file)
            .then((result) => {
                setImportResult(result);
                getAllCompaniesList(currentCompany.id);
            })
            .catch((error) => {
                if (typeof error.response.data === "string") {
                    setImportResult({
                        isSuccess: false,
                        hasCriticalError: true,
                        logs: [],
                        errors: [{ message: error.response.data }],
                    });
                } else {
                    setImportResult(error.response.data);
                }
            })
            .finally(() => setSubmitting(false));
    };

    const formik = useFormik<ProductImportForm>({
        initialValues: { replaceLatestImport: false },
        validationSchema: Yup.object().shape({
            file: Yup.mixed().required("Required"),
        }),
        onSubmit: handleSubmit,
    });

    const handleFileChange = (file?: File) => {
        setImportResult(undefined);
        formik.setFieldValue("file", file);
        formik.setFieldTouched("file");
    };

    useOnCompanyChange(() => {
        setImportResult(undefined);
        formik.resetForm();
    });

    return (
        <FormikProvider value={formik}>
            {importResult && (
                <Box mb={4}>
                    <ImportResultAlert
                        result={importResult}
                        onClose={() => setImportResult(undefined)}
                    />
                </Box>
            )}
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h1">
                    Product Import
                </Typography>
                <LoadingButton
                    color="primary"
                    size="large"
                    disableElevation
                    component="label"
                    loading={formik.isSubmitting}
                    loadingLabel={<Box component="span" lineHeight="30px">Importing...</Box>}
                    onClick={formik.submitForm}
                >
                    <Box component="span" lineHeight="30px">Import</Box>
                </LoadingButton>
            </Box>
            <PaperCard>
                {currentCompany?.lastProductImportDocumentName && (
                    <Box mb={2.5}>
                        <Box fontWeight={500}>Last Import:</Box>
                        {currentCompany.lastProductImportDocumentName}
                        {currentCompany.lastProductImportDocumentDate && (
                            <Box>{formatDateTime(new Date(currentCompany.lastProductImportDocumentDate))}</Box>
                        )}
                    </Box>
                )}
                <Box mb={2.5}>
                    <Box display="flex">
                        <FileUploader
                            file={formik.values.file}
                            onChange={handleFileChange}
                            btnText="Select File (.xlsx)"
                            btnVariant="outlined"
                            allowedExtensions=".xlsx"
                        />
                    </Box>
                    {formik.submitCount > 0 && formik.errors.file && (
                        <FormHelperText error>{formik.errors.file}</FormHelperText>
                    )}
                </Box>
                <Tooltip
                    arrow
                    placement="top"
                    title={
                        <>
                            This option is will enable you to
                            {" "}<Box component="span" fontWeight={600}>replace the latest import</Box>{" "}
                            again in order to make data corrections.
                        </>
                    }
                >
                    <FormControlLabel
                        control={<Checkbox name="replaceLatestImport" color="primary" />}
                        checked={formik.values.replaceLatestImport}
                        onChange={formik.handleChange}
                        label="Replace the latest upload data with this file"
                    />
                </Tooltip>
            </PaperCard>
        </FormikProvider>
    );
}
