import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    footer: {
        bottom: 0,
    },
    footerContent: {
        background: theme.palette.common.white,
        borderTop: "1px solid #EEF0F3", // TODO: value dublicated in Header
    },
}));

export default useStyles;
