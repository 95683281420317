import React, { useCallback, useRef, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import clsx from "clsx";

import AdminProvider from "components/AdminProvider";
import ConfigurationProvider from "components/ConfigurationProvider";
import GlobalAdminRoute from "components/GlobalAdminRoute";
import NavBar from "components/NavBar";
import AdminNavBar from "components/NavBar/AdminNavBar";
import NotificationsProvider from "components/NotificationsProvider";
import ScrollRestoration from "components/ScrollRestoration";
import Snackbar from "components/Snackbar";
import { CompaniesContext, ConfigurationContext } from "context";
import { AdminRoutings, Routings } from "types/constants";

import ClientComponent from "../ClientComponent";
import AdminComponent from "../AdminComponent";

import useStyles from "./styles";
import AppDialog from "./AppDialog";

const MainComponent = () => {
    const classes = useStyles();
    const appRef = useRef<HTMLDivElement>(null);
    const [isBarOpen, setIsBarOpen] = useState(true);
    const isAdmin = useRouteMatch(AdminRoutings.admin);
    const navBarOpen = useCallback(() => setIsBarOpen(true), []);

    const Provider = isAdmin ? AdminProvider : ConfigurationProvider;
    const Context = isAdmin ? CompaniesContext : ConfigurationContext;

    return (
        <Provider>
            <div id="app" className={classes.app} ref={appRef}>
                {isAdmin ? (
                    <AdminNavBar open={isBarOpen} navBarClose={() => setIsBarOpen(false)} />
                ) : (
                    <NavBar open={isBarOpen} navBarClose={() => setIsBarOpen(false)} />
                )}
                <ScrollRestoration appRef={appRef}>
                    <Context.Consumer>
                        {({ currentUser }) => (
                            <>
                                <NotificationsProvider currentUser={currentUser}>
                                    <Snackbar>
                                        <main className={clsx(classes.main, isBarOpen && classes.mainOpened)}>
                                            <Switch>
                                                <GlobalAdminRoute path={AdminRoutings.admin} currentUser={currentUser}>
                                                    <AdminComponent isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
                                                </GlobalAdminRoute>
                                                <Route path={Routings.dashboard}>
                                                    <ClientComponent isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
                                                </Route>
                                            </Switch>
                                        </main>
                                    </Snackbar>
                                </NotificationsProvider>
                                <AppDialog />
                            </>
                        )}
                    </Context.Consumer>
                </ScrollRestoration>
            </div>
        </Provider>
    );
};

export default MainComponent;
