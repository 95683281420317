import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";

import { DATE_FORMAT } from "helpers/dateHelpers";

interface InlineDatePickerProps {
    date: Date,
    onChange: (date: Date) => void
}

function InlineDatePicker({ date, onChange }: InlineDatePickerProps) {
    return (
        <MuiPickersUtilsProvider utils={DayJsUtils}>
            <DatePicker
                variant="inline"
                format={DATE_FORMAT}
                value={date}
                InputProps={{
                    disableUnderline: true,
                    style: { textDecoration: "underline" },
                }}
                disablePast={true}
                leftArrowButtonProps={{ style: { order: 2, padding: 4 }}}
                rightArrowButtonProps={{ style: { order: 3, padding: 4 }}}
                onChange={(newDate) => newDate && onChange(newDate.toDate())}
            />
        </MuiPickersUtilsProvider>
    )
}

export default InlineDatePicker;