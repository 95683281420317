import React from "react";
import { Dialog, Box, Typography, useMediaQuery, useTheme, Button } from "@material-ui/core";

import TraineeEvaluationForm from "routes/MainClassListingsPage/AllMyClassesPage/TraineeEvaluationFormPage/TraineeEvaluationForm";
import { EvaluationConfiguration } from "types/Certification";

interface PreviewEvaluationFormModalProps {
    isOpen: boolean;
    onClose: () => void;
    evaluationConfiguration: EvaluationConfiguration;
}

export default function PreviewEvaluationFormModal({ isOpen, onClose, evaluationConfiguration }: PreviewEvaluationFormModalProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth={"md"}>
            <Box px={4} pt={3} pb={4.5}>
                <Box pb={4}>
                    <Typography variant="h1">{evaluationConfiguration.name}</Typography>
                </Box>
                <TraineeEvaluationForm disabled evaluationItemConfigurations={evaluationConfiguration.evaluationItemConfigurations} />
                <Box display="flex" justifyContent="flex-end" pt={4}>
                    <Box pl={2} color="text.secondary">
                        <Button variant="outlined" color="inherit" size="large" onClick={onClose}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}
