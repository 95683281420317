import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        link: {
            color: theme.palette.primary.main,
            textDecoration: "none",
            position: "relative",

            "&:hover": {
                textDecoration: "no-underline",
            },
        },

        spinner: {
            backgroundColor: "rgba(246,248,249, .5)",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    })
);

export default useStyles;
