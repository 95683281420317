import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";

import MultilineInput from "components/MultilineInput";
import Slider from "components/Slider";
import BarGraph from "components/BarGraph";
import RichText from "components/RichText";
import RatingMeaning from "components/RatingMeaning";
import { Calendar, CoachingPlans, Preformance } from "svgComponents";
import { getFcrRatingDescription } from "helpers/fcrHelper";
import { FcrCoachingSkillObservation, FcrCoachingSkillHistory, FcrCoachingSkill, FcrConfiguration } from "types";

import useStyles from "./style";

import WizardPaper from "../../WizardPaper";

interface CompetencyCardProps {
    fcrCoachingSkillObservation: FcrCoachingSkillObservation;
    fcrCoachingSkillsHistory: FcrCoachingSkillHistory[];
    onChange: (value: FcrCoachingSkillObservation) => void;
    fcrCoachingSkills: FcrCoachingSkill[];
    fcrConfiguration: FcrConfiguration;
}

function CompetencyCard({ fcrCoachingSkillObservation, fcrCoachingSkillsHistory, onChange, fcrCoachingSkills, fcrConfiguration }: CompetencyCardProps) {
    const classes = useStyles();

    const fcrCoachingSkillHistory = useMemo(() =>
        fcrCoachingSkillsHistory.find(e => e.fcrCoachingSkill?.id === fcrCoachingSkillObservation.fcrCoachingSkill?.id),
    [fcrCoachingSkillObservation.fcrCoachingSkill?.id, fcrCoachingSkillsHistory]
    );

    const leftSide = useMemo(() => {
        const { observation, rating } = fcrCoachingSkillObservation;
        const fcrCoachingSkill = fcrCoachingSkills
            .find(e => e.id === fcrCoachingSkillObservation.fcrCoachingSkillId) || {} as FcrCoachingSkill;

        const ratingDescription = getFcrRatingDescription(fcrCoachingSkill, rating);
        const restSliderProps = fcrConfiguration.wholeNumberRating
            ? { step: 1, minRating: Math.ceil(fcrCoachingSkill.minRating), maxRating: Math.floor(fcrCoachingSkill.maxRating) }
            : { step: 0.1, minRating: fcrCoachingSkill.minRating, maxRating: fcrCoachingSkill.maxRating }

        return (
            <Box>
                <Typography variant="h1" component="div">
                    {fcrCoachingSkill.title}
                </Typography>
                <Typography variant="h3" component="div">
                    <RichText text={fcrCoachingSkill.description} />
                </Typography>
                <Box pb={4}>
                    <Box pt={5} pb={fcrConfiguration.hideSkillRatingNumbers ? 5 : 9} fontSize={14} fontWeight={600} lineHeight={21}>
                        <Typography variant="h2">Overall Rating</Typography>
                    </Box>
                    <Slider
                        defaultValue={0}
                        value={rating}
                        valueLabelDisplay={fcrConfiguration.hideSkillRatingNumbers ? "off" : "on"}
                        onChange={(value: number) => onChange({
                            ...fcrCoachingSkillObservation,
                            rating: value,
                        })}
                        {...restSliderProps}
                    />
                </Box>
                <Box display="flex" justifyContent="center">
                    <div className={classes.arrow} />
                </Box>
                {ratingDescription && (
                    <RatingMeaning title={ratingDescription.title} body={ratingDescription.body} />
                )}
                <Box pt={3}>
                    <MultilineInput
                        name="Coaching Notes"
                        placeholder="Enter a summary of the coaching conversation based on the skill in focus and observations during customer interactions."
                        value={observation}
                        onChange={(value: string) => onChange({
                            ...fcrCoachingSkillObservation,
                            observation: value,
                        })}
                    />
                </Box>
            </Box>
        );
    }, [fcrCoachingSkillObservation, fcrCoachingSkills, classes.arrow, onChange, fcrConfiguration]);

    const rightSide = useMemo(() => {
        const isDataExist = fcrCoachingSkillHistory &&
            fcrCoachingSkillHistory.resultsScores &&
            fcrCoachingSkillHistory.resultsScores?.length > 0;

        return (
            <Box>
                <Box fontWeight={600}>
                    <Typography variant="h3">History</Typography>
                </Box>
                <Box pt={1} pb={isDataExist ? 2 : 17}>
                    <Typography variant="body2" className={classes.graphInfo}>
                        {isDataExist ? "Last 12 months" : "No previous scores."}
                    </Typography>
                </Box>
                {isDataExist &&
                    <Box>
                        <BarGraph scores={fcrCoachingSkillHistory ? fcrCoachingSkillHistory.resultsScores : []} />
                        <Box pt={8} width="100%">
                            <Box display="flex" flexDirection="raw">
                                <Box
                                    display="flex" alignItems="center" justifyContent="center"
                                    mr={3} mb={5}
                                    className={classes.skillLogo} color="primary.light"
                                >
                                    <Calendar />
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="body2" className={classes.skillInfoName}>Times Coached</Typography>
                                    <Typography variant="subtitle2">{fcrCoachingSkillHistory?.timesCoached}</Typography>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="raw">
                                <Box
                                    display="flex" alignItems="center" justifyContent="center"
                                    mr={3} mb={5}
                                    className={classes.skillLogo} color="primary.light"
                                >
                                    <CoachingPlans />
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="body2" className={classes.skillInfoName}>Coaching Plans</Typography>
                                    <Typography variant="subtitle2">{fcrCoachingSkillHistory?.coachingPlansAmount}</Typography>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="raw">
                                <Box
                                    display="flex" alignItems="center" justifyContent="center"
                                    mr={3} mb={5}
                                    className={classes.skillLogo} color="primary.light"
                                >
                                    <Preformance />
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="body2" className={classes.skillInfoName}>{fcrCoachingSkillHistory?.monthlyTimePeriod} Month Trend</Typography>
                                    <Typography variant="subtitle2">{fcrCoachingSkillHistory?.trend}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
        );
    }, [classes, fcrCoachingSkillHistory]);

    return (
        <WizardPaper
            contentLeft={leftSide}
            contentRight={rightSide}
        />
    )
}

export default CompetencyCard;
