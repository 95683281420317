import React, { useCallback, useMemo, useState } from "react";
import * as Yup from "yup";
import { Box, Divider, Typography, FormControlLabel, CircularProgress } from "@material-ui/core";
import { postFcrConfiguration } from "services/wizardServices";
import { Form, useFormik, FormikProvider } from "formik";
import { FormInput, FormSwitcher } from "components/Form";
import ConfirmationModal from "components/ConfirmationModal";
import { FcrConfiguration } from "types";

interface SettingsFormValues {
    wholeNumberRating: boolean;
    prePopulateDevelopmentGoal: boolean;
    displayOpenNotes: boolean;
    hideSkillRatingNumbers: boolean;
    openNotesTitle: string;
}

interface SettingsFormProps {
    fcrConf: FcrConfiguration | null;
    onSuccess: (fcrConf: FcrConfiguration) => void;
    isFetching: boolean;
}

export default function SettingsForm({ fcrConf, isFetching, onSuccess }: SettingsFormProps) {
    const [loading, setLoading] = useState(false);
    const [showWholeNumberModal, setShowWholeNumberModal] = useState<boolean>(false);
    const [showPrepopulateGoalModal, setShowPrepopulateGoalModal] = useState<boolean>(false);
    const [showHideSkillRatingNumbers, setShowHideSkillRatingNumbers] = useState<boolean>(false);

    const handleFormSubmit = useCallback((values: SettingsFormValues) => {
        if (loading || !fcrConf) return;

        const payload = {
            ...fcrConf,
            wholeNumberRating: values.wholeNumberRating,
            hideSkillRatingNumbers: values.hideSkillRatingNumbers,
            prepopulateDevelopmentGoal: values.prePopulateDevelopmentGoal,
            displayOpenNotes: values.displayOpenNotes,
            ...(values.displayOpenNotes ? {
                openNotesTitle: values.openNotesTitle,
            } : {}),
        };

        setLoading(true);

        // TODO add authorization provider
        postFcrConfiguration(payload)
            .then(onSuccess)
            .finally(() => setLoading(false));
    }, [fcrConf, loading, onSuccess]);

    const initialValues = useMemo<SettingsFormValues>(() => ({
        wholeNumberRating: fcrConf?.wholeNumberRating ?? false,
        prePopulateDevelopmentGoal: fcrConf?.prePopulateDevelopmentGoal ?? false,
        displayOpenNotes: fcrConf?.displayOpenNotes ?? false,
        hideSkillRatingNumbers: fcrConf?.hideSkillRatingNumbers ?? false,
        openNotesTitle: fcrConf?.openNotesTitle ?? "",
    }), [fcrConf]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: Yup.object().shape({
            displayOpenNotes: Yup.boolean(),
            openNotesTitle: Yup.string().when(["displayOpenNotes"], {
                is: true,
                then: Yup.string().nullable().required("Field is required"),
                otherwise: Yup.string().nullable(),
            }),
        }),
        onSubmit: handleFormSubmit,
    });

    const { values, submitForm, setFieldValue, setFieldTouched } = formik;

    const handleCloseWholeNumberModal = useCallback(() => {
        setShowWholeNumberModal(false);
    }, []);

    const handleClosePrepopulateGoalModal = useCallback(() => {
        setShowPrepopulateGoalModal(false);
    }, []);

    const handleCloseHideSkillRatingNumbersModal = useCallback(() => {
        setShowHideSkillRatingNumbers(false);
    }, []);

    const handleWholeNumberChange = useCallback(() => {
        if (!values.wholeNumberRating) {
            setShowWholeNumberModal(true);
        } else {
            setFieldValue("wholeNumberRating", false);
            submitForm();
        }
    }, [values, setFieldValue, submitForm]);

    const handlePrepopulateGoalChange = useCallback(() => {
        if (!values.prePopulateDevelopmentGoal) {
            setShowPrepopulateGoalModal(true);
        } else {
            setFieldValue("prePopulateDevelopmentGoal", false);
            submitForm();
        }
    }, [values, setFieldValue, submitForm]);

    const handleHideSkillRatingNumbersChange = useCallback(() => {
        setShowHideSkillRatingNumbers(true);
    }, []);

    const handleDisplayOpenNotesChange = useCallback(() => {
        if (values.displayOpenNotes) {
            setFieldValue("displayOpenNotes", false, false).then(submitForm);
        } else {
            setFieldValue("displayOpenNotes", true, false).then(() => {
                setFieldTouched("openNotesTitle", true, false);
                if (values.openNotesTitle) submitForm();
            });
        }
    }, [values, setFieldValue, setFieldTouched, submitForm]);

    const handleEnableWholeNumber = useCallback(() => {
        setFieldValue("wholeNumberRating", true);
        submitForm();
        handleCloseWholeNumberModal();
    }, [setFieldValue, submitForm, handleCloseWholeNumberModal]);

    const handleEnablePrepopulateGoal = useCallback(() => {
        setFieldValue("prePopulateDevelopmentGoal", true);
        submitForm();
        handleClosePrepopulateGoalModal();
    }, [setFieldValue, submitForm, handleClosePrepopulateGoalModal]);

    const handleToggleHideSkillRatingNumbers = useCallback(() => {
        setFieldValue("hideSkillRatingNumbers", !values.hideSkillRatingNumbers);
        submitForm();
        handleCloseHideSkillRatingNumbersModal();
    }, [setFieldValue, values.hideSkillRatingNumbers, submitForm, handleCloseHideSkillRatingNumbersModal]);

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <Box px={4} py={3}>
                        <Typography variant="h2">
                            <Box component="span" fontWeight={500}>Settings</Box>
                        </Typography>
                        {!isFetching && fcrConf ? 
                            <>
                                <Box ml="11px" mt={3}>
                                    <FormControlLabel
                                        onChange={handleWholeNumberChange}
                                        control={<FormSwitcher name="wholeNumberRating" disabled={values.hideSkillRatingNumbers} />}
                                        label={
                                            <Typography color="textSecondary" variant="body1">
                                                Display Whole Number Competency Ratings
                                            </Typography>
                                        }
                                    />
                                </Box>
                                <Box ml="11px" mt={3}>
                                    <FormControlLabel
                                        onChange={handlePrepopulateGoalChange}
                                        control={<FormSwitcher name="prePopulateDevelopmentGoal" />}
                                        label={
                                            <Typography color="textSecondary" variant="body1">
                                                Prepopulate Development Goal
                                            </Typography>
                                        }
                                    />
                                </Box>
                                <Box ml="11px" mt={3}>
                                    <FormControlLabel
                                        onChange={handleHideSkillRatingNumbersChange}
                                        control={<FormSwitcher name="hideSkillRatingNumbers" />}
                                        label={
                                            <Typography color="textSecondary" variant="body1">
                                                Hide Skill Rating Numbers
                                            </Typography>
                                        }
                                    />
                                </Box>
                                <Box mt={3}>
                                    <Divider />
                                </Box>
                                <Box ml="11px" mt={3}>
                                    <FormControlLabel
                                        onChange={handleDisplayOpenNotesChange}
                                        control={<FormSwitcher name="displayOpenNotes" />}
                                        label={
                                            <Typography color="textSecondary" variant="body1">
                                                Display open notes field on Step 1
                                            </Typography>
                                        }
                                    />
                                </Box>
                                {(values.displayOpenNotes && !loading) && (
                                    <>
                                        <Box mt={3}>
                                            <FormInput
                                                name="openNotesTitle"
                                                type="text"
                                                label="Field title"
                                                required={true}
                                                onBlur={submitForm}
                                            />
                                        </Box>
                                    </>
                                )}
                            </> : 
                            <Box px={6} pt={4} textAlign="center">
                                <CircularProgress size={40} />
                            </Box>
                        }
                    </Box>
                </Form>
            </FormikProvider>
            <ConfirmationModal
                isOpen={showWholeNumberModal}
                header="Display Whole Number Competency Ratings"
                body={<>
                    Please note: If any Skill Rating min/max ranges do not fall within a whole value,
                    the system will be unable to determine the whole value and it will not be available on the Coaching Report.
                    Ok to proceed?
                </>}
                confirmBtn="Proceed"
                onConfirm={handleEnableWholeNumber}
                onClose={handleCloseWholeNumberModal}
            />
            <ConfirmationModal
                isOpen={showPrepopulateGoalModal}
                header="Prepopulate Development Goal"
                body={<>
                    Please note: Turning this option on will prepopulate the Development Goal field
                    for every new Coaching Report with data from the last completed Report for the Coachee.
                    Ok to proceed?
                </>}
                confirmBtn="Proceed"
                onConfirm={handleEnablePrepopulateGoal}
                onClose={handleClosePrepopulateGoalModal}
            />
            <ConfirmationModal
                isOpen={showHideSkillRatingNumbers}
                header={values.hideSkillRatingNumbers ? "Display Skill Rating Numbers" : "Hide Skill Rating Numbers"}
                body={<>
                    Please note: This will {values.hideSkillRatingNumbers ? "display" : "hide"}{" "}
                    all Skill Ratings scores across the site, including the Coaching Reports.
                    Ok to proceed?
                </>}
                confirmBtn="Proceed"
                onConfirm={handleToggleHideSkillRatingNumbers}
                onClose={handleCloseHideSkillRatingNumbersModal}
            />
        </>
    )
}
