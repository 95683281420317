import React, { SyntheticEvent, useCallback, useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { fcrResourceToBase64 } from "helpers/fileHelper";
import { getFcrResource } from "services/resourceServices";
import { FcrResource } from "types";
import { ResourceTypeEnum } from "types/constants";
import ResourceIcon from "components/ResourceIcon";
import TargetBlank from "svgComponents/TargetBlank";
import clsx from "clsx";

import useStyles from "./style";

interface CoachingPlanResourceLinkProps {
    resource: FcrResource,
}

export default function CoachingPlanResourceLink({ resource }: CoachingPlanResourceLinkProps) {
    const classes = useStyles();
    const [ href, setHref ] = useState(resource.url);
    const [ disabled, setDisabled ] = useState(resource.type === ResourceTypeEnum.link && !href);
    const [ download, setDownload ] = useState<string>();

    const onDownload = useCallback(async (event: SyntheticEvent<HTMLAnchorElement>) => {
        if (href || resource.type === ResourceTypeEnum.link) return;

        event.preventDefault();
        const file = await getFcrResource(resource.type, resource.id);
        if (!file) return setDisabled(true);
    
        setHref(fcrResourceToBase64(file));
        setDownload(resource.documentFilename ?? "");
        if (event.target instanceof HTMLElement) event.target.closest("a")?.click();
    }, [ href, resource.type, resource.id, resource.documentFilename ]);

    useEffect(() => {
        const getFcrResourceUrl = async () => {
            const { url } = await getFcrResource(resource.type, resource.id);
            setHref(url)
        };

        if (!resource.url) {
            getFcrResourceUrl()
        }
    }, [resource]);

    return (
        <a
            className={clsx([ classes.link, disabled && classes.disabled ])}
            href={href}
            rel="noreferrer"
            target="_blank"
            onClick={onDownload}
            download={download}
        >
            <Box>
                <ResourceIcon display="block" type={resource.type} />
            </Box>
            <Box ml={2}>
                <Typography className={classes.name} component="span" variant="body1">{resource.name}</Typography>
            </Box>
            <Box ml={2} color="text.secondary">
                <TargetBlank display="block" />
            </Box>
        </a>
    )
}