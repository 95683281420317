import { Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        "& .MuiInputBase-root": {
            backgroundColor: theme.palette.common.white,
        },
    },
}));

export default useStyles;
