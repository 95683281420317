import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { Box, MenuItem, Typography } from "@material-ui/core";

import { ConfigurationContext } from "context";
import PaperSection from "components/PaperSection";
import SummaryCardSessions from "components/SummaryCard/SummaryCardSessions";
import SummaryCardGoals from "components/SummaryCard/SummaryCardGoals";
import SummaryCardFocus from "components/SummaryCard/SummaryCardFocus";
import SummaryCardPlans from "components/SummaryCard/SummaryCardPlans";
import SummarySelect from "components/SummarySelect";
import { UserProfile, TeamProfileRouteParams, UserCoachingDevelopment, CoachingDevelopment } from "types";
import { CoachingPeriodEnum, Routings } from "types/constants";
import useCoachingPeriod, { COACHING_PERIOD_OPTS } from "hooks/useCoachingPeriod";
import { getUserCoachingDevelopment } from "services/wizardServices";
import { byToDate, calcScore } from "helpers/coachingHistoryHelper";

import useStyles from "./styles";

interface TeamProfileCoaching {
    userData: UserProfile,
}

const initialHistory: UserCoachingDevelopment = {
    userId: "",
    userName: "",
    coachingDevelopment: [],
}

export default function TeamProfileCoaching({ userData }: TeamProfileCoaching) {
    const { fcrSectionConfiguration } = useContext(ConfigurationContext);
    const { url } = useRouteMatch();
    const classes = useStyles();
    const { userId } = useParams<TeamProfileRouteParams>();
    const { firstName } = userData;
    const [isFetching, setIsFetching] = useState(false);
    const [history, setHistory] = useState<CoachingDevelopment[]>([]);
    const [historyLoadDate, setHistoryLoadDate] = useState<Date>(new Date());
    const { period, periodLabel, setPeriod } = useCoachingPeriod();
    const allHistory = useMemo(() => history.filter(({ reportType }) => reportType === period), [history, period]);
    const curHistory = useMemo(() => allHistory.filter(byToDate(historyLoadDate)), [allHistory, historyLoadDate]);
    const prevHistory = useMemo(() => allHistory.filter(byToDate(historyLoadDate, false)), [allHistory, historyLoadDate]);
    const sessions = useMemo(() => calcScore(curHistory, prevHistory, "coachingSessions"), [curHistory, prevHistory]);
    const goals = useMemo(() => calcScore(curHistory, prevHistory, "developmentGoalsInitiated"), [curHistory, prevHistory]);
    const focus = useMemo(() => calcScore(curHistory, prevHistory, "skillsAverageRating", 2), [curHistory, prevHistory]);
    const plans = useMemo(() => calcScore(curHistory, prevHistory, "coachingPlansCompleted"), [curHistory, prevHistory]);

    const pathForRouting = useMemo(() => url === "/" ? "" : url, [url]);

    useEffect(() => {
        if (!userData.id) return;
        const getHistory = async () => {
            setIsFetching(true);
            const { coachingDevelopment } = await getUserCoachingDevelopment(userData.id)
                .catch(() => initialHistory);
            setHistory(coachingDevelopment);
            setHistoryLoadDate(new Date());
            setIsFetching(false);
        };
        getHistory();
    }, [userData]);

    const handlePeriodChange = useCallback((event: React.ChangeEvent<{ name?: string; value: CoachingPeriodEnum }>) =>
        setPeriod(event.target.value), [setPeriod]);

    return (
        <PaperSection
            title={(
                <Box mr={1.5} display="flex" alignItems="center">
                    <Box>
                        <Typography className={classes.title} variant="h2">
                            {userId ? `${firstName}'s` : "My"} Development
                        </Typography>
                    </Box>
                    <Box ml={2}>
                        <SummarySelect value={period} onChange={handlePeriodChange}>
                            {Object.entries(COACHING_PERIOD_OPTS).map(([key, value]) => (
                                <MenuItem key={key} value={key}>{value}</MenuItem>
                            ))}
                        </SummarySelect>
                    </Box>
                </Box>
            )}
            mb={{ xs: 3, sm: 6 }}
            href={`${pathForRouting}${Routings.coachingDevelopment}`}
            label="See More Details"
        >
            <Box className={classes.cards} p={{ xs: 3, md: 5 }} display="grid">
                <SummaryCardSessions
                    signature={periodLabel}
                    label="Coaching Sessions"
                    score={sessions.total}
                    isIncreased={sessions.isIncreased}
                    isFetching={isFetching}
                    tooltip={userId ?
                        `This is the number of coaching sessions completed by ${firstName}` :
                        "This is the number of coaching sessions you have completed"
                    }
                    type="ghost"
                />
                <SummaryCardGoals
                    signature={periodLabel}
                    label={fcrSectionConfiguration?.section1Label}
                    subLabel="Reports Initiated"
                    score={`${goals.score}%`}
                    isIncreased={goals.isIncreased}
                    isFetching={isFetching}
                    tooltip={userId ?
                        `This is the % of completed coaching reports initiated by ${firstName}` :
                        "This is the % of completed coaching reports you have initiated"
                    }
                    type="ghost"
                />
                <SummaryCardFocus
                    signature={periodLabel}
                    label={fcrSectionConfiguration?.section2Label}
                    subLabel="Skill Development"
                    score={focus.dynamic}
                    isIncreased={focus.isIncreased}
                    isFetching={isFetching}
                    tooltip={userId ?
                        `This is the increase or decrease in ${firstName}'s  overall average skill rating` :
                        "This is the increase or decrease in your overall average skill rating"
                    }
                    type="ghost"
                />
                <SummaryCardPlans
                    signature={periodLabel}
                    label={fcrSectionConfiguration?.section3Label}
                    subLabel="Plans Completed"
                    score={`${plans.score}%`}
                    isIncreased={plans.isIncreased}
                    isFetching={isFetching}
                    tooltip={userId ?
                        `This is the % of assigned coaching plans completed by ${firstName}` :
                        "This is the % of assigned coaching plans you have completed"
                    }
                    type="ghost"
                />
            </Box>
        </PaperSection>
    )
}
