import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    popover: {
        marginTop: theme.spacing(2),
    },
    paper: {
        borderRadius: "8px",
        border: `1px solid ${theme.colors.structure.rulesAndBorders}`,
        boxShadow: "0px 3px 9px rgba(37, 58, 84, 0.1)",
    },
    position: {
        color: theme.colors.text.darkBlue,
    },
    logo: {
        display: "block",
        maxHeight: theme.spacing(5),
    },
}));

export default useStyles;
