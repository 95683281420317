import React, { useState, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { UserRoles } from "types/constants";
import { UserProfile } from "types";

interface GlobalAdminRouteProps extends RouteProps {
    currentUser?: UserProfile;
}

const GlobalAdminRoute = ({ currentUser, ...props }: GlobalAdminRouteProps) => {
    const [isAccessByRole, setAccessByRole] = useState<boolean | null>(null);

    useEffect(() => {
        if (currentUser && currentUser.roles?.length) {
            setAccessByRole(currentUser.roles.indexOf(UserRoles.globalAdmin) > -1)
        }
    }, [currentUser]);

    if (isAccessByRole === null) return null;

    return isAccessByRole ? <Route {...props} /> : <Redirect to="/" />
};

export default GlobalAdminRoute;