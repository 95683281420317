import React from "react";
import { Box, Paper } from "@material-ui/core";

import teamDevelopmentHistory from "./teamDevelopmentHistory.svg";
import BackToDashboard from "../BackToDashboard";

function TeamDevelopmentHistory() {
    return (
        <Box px={7.5}>
            <BackToDashboard />
            <Paper>
                <Box display="flex" justifyContent="center" pt={3} pb={4}>
                    <img src={teamDevelopmentHistory} alt="" />
                </Box>
            </Paper>
        </Box>
    )
}

export default TeamDevelopmentHistory;
