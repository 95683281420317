import { makeStyles, Theme } from "@material-ui/core";

import { PerformanceMetricsVariant } from ".";

interface PerformanceMetricsStylesProps {
    variant?: PerformanceMetricsVariant;
}

const getVariantHeadingStyles = (theme: Theme, variant?: PerformanceMetricsVariant) => {
    switch (variant) {
        case (PerformanceMetricsVariant.Paper):
            return {
                ...theme.typography.h2,
                marginBottom: 0,
            }
        case (PerformanceMetricsVariant.FCR):
            return {
                ...theme.typography.h3,
                marginBottom: theme.spacing(2),
            };
        case (PerformanceMetricsVariant.FcrResult):
            return {
                ...theme.typography.body1,
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(2),
            };
        default: return theme.typography.h1;
    }
}

const useStyles = makeStyles<Theme, PerformanceMetricsStylesProps>(theme => ({
    heading: ({ variant }) => ({
        marginBottom: theme.spacing(5),
        ...getVariantHeadingStyles(theme, variant),
        fontWeight: 600,
    }),
    list: (props) => (
        (props.variant === PerformanceMetricsVariant.Paper) ? ({
            columnGap: theme.spacing(4),
            rowGap: theme.spacing(8),
            gridTemplateColumns: "repeat(1, 1fr)",
            [theme.breakpoints.up("md")]: {
                gridTemplateColumns: "repeat(2, 1fr)",
            },
            [theme.breakpoints.up("lg")]: {
                gridTemplateColumns: "repeat(3, 1fr)",
            },
        }) : ({
            gap: theme.spacing(3),
            gridTemplateColumns: "repeat(1, 1fr)",
            [theme.breakpoints.up("sm")]: {
                gridTemplateColumns: "repeat(2, 1fr)",
            },
            [theme.breakpoints.up("lg")]: {
                gridTemplateColumns: "repeat(4, 1fr)",
            },
        })
    ),
}));

export default useStyles;
