import React, { useMemo } from "react";
import { Box, Typography, useTheme } from "@material-ui/core";

import AvatarWithScore from "components/AvatarWithScore";
import RichText from "components/RichText";
import { MessageType, UserProfile } from "types";
import { SizeEnum } from "types/constants";
import { formatDateTime } from "helpers/dateHelpers";

interface MessageHistoryItemProps {
    message: MessageType;
    currentUser?: UserProfile;
}

const MessageHistoryItem = ({ message, currentUser }: MessageHistoryItemProps) => {
    const theme = useTheme();
    const userName = useMemo(() => `${message.fromUser.firstName} ${message.fromUser.lastName}`, [message]);
    const formattedDate = useMemo(() => formatDateTime(message.createdDate), [message]);
    const userWithAvatar = useMemo(() => ({
        ...message.fromUser,
        avatar: message.avatar,
    }), [message]);

    return (
        <Box display="flex">
            <AvatarWithScore user={userWithAvatar} size={SizeEnum.small} />
            <Box ml={2}>
                <Box display="flex" height={theme.spacing(3)} alignItems="center" mb={0.5}>
                    <Box fontWeight={600} mr={1.5}>
                        <Typography variant="body1">
                            {userName}
                        </Typography>
                    </Box>
                    <Typography variant="caption">
                        {formattedDate}
                    </Typography>
                    {currentUser && message.isNew && message.toUserId === currentUser.id && (
                        <Box
                            bgcolor={theme.colors.statusLabel.background.green}
                            color={theme.palette.text.secondary}
                            fontWeight={500}
                            borderRadius={4}
                            fontSize="0.75rem"
                            px={1} py={0.25} ml={1.5}
                        >
                            New
                        </Box>
                    )}
                </Box>
                <Typography variant="body2" component="div">
                    <RichText text={message.messageContent} />
                </Typography>
            </Box>
        </Box>
    );
};

export default React.memo(MessageHistoryItem);