import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { BAR_WIDTH } from "types/constants";

const useStyles = makeStyles((theme: Theme) => createStyles({
    drawer: {
        position: "fixed",
        width: BAR_WIDTH,
        background: "#FFFFFF",
        boxShadow: "3px 0px 9px rgba(37, 58, 84, 0.1)",
    },
    section: {
        verticalAlign: "baseline",
        lineHeight: "21px",
        color: theme.colors.text.darkGray,
        cursor: "pointer",
        display: "block",

        "&:hover": {
            background: "#F6F8F9",
            color: theme.colors.text.darkGray,
            fontWeight: 500,
            borderRadius: "6px",
        },
    },
    disabledSection: {
        opacity: "0.5",
        cursor: "default",
    },
    selectedSection: {
        background: "#F6F8F9",
        fontWeight: 500,
        borderRadius: "6px",
    },
    menuSubItem: {
        height: 40,
        fontSize: 13,
        color: "#798696",
    },
    selectedMenuSubItem: {
        background: "rgba(37, 58, 84, 0.04)",
        color: theme.colors.text.darkGray,
    },
    iconTeamMember: {
        lineHeight: "14px",
    },
    helpIcon: {
        opacity: .7,
    },
}));

export default useStyles;
