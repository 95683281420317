import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    select: {
        padding: "15px 14px",
        backgroundColor: theme.palette.common.white,

        "&:focus": {
            backgroundColor: theme.palette.common.white,
        },
    },
    autocomplete: {
        "& .MuiAvatar-root": {
            width: "32px",
            height: "32px",
        },
        "& .MuiInputLabel-root": {
            transform: "translate(16px, 19px) scale(1)",
            fontSize: "15px",

            "&.MuiInputLabel-shrink": {
                display: "none",
            },
        },
        "& .MuiOutlinedInput-notchedOutline": {
            top: 0,
            "& legend": {
                display: "none",
            },
        },
        "& .MuiInputBase-root": {
            paddingTop: "11px",
            paddingBottom: "11px",
        },
        "& .MuiInputBase-input": {
            fontSize: "15px",
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "50%",
            transform: "translateY(-50%)",
        },
        "& .MuiAutocomplete-popupIndicator": {
            marginRight: "-4px",
        },
    },
}));
