import { makeStyles, alpha } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    actions: {
        color: theme.colors.text.mediumGray,
        [theme.breakpoints.up("md")]: {
            opacity: 0,
            transition: theme.transitions.create("opacity", {
                duration: 300,
                delay: 100,
                easing: "linear",
            }),
            "$body:hover &": {
                opacity: 1,
            },
        },
    },
    body: {
        cursor: "pointer",
        position: "relative",
        transition: theme.transitions.create("background-color", {
            duration: 300,
            easing: "linear",
        }),
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
        },
    },
    bodyInactive: {
        opacity: 0.5,
    },
    content: {
        flexGrow: 1,
    },
    icon: {
        transition: theme.transitions.create(["background-color", "color"], {
            duration: 300,
            delay: 100,
            easing: "linear",
        }),
        "$body:hover &": {
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.colors.identity.teal, .1),
        },
    },
}), {
    name: "AdminListItem",
});

export default useStyles;