import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dragHandleBtn: {
        minWidth: theme.spacing(3),
        width: theme.spacing(3),
        height: theme.spacing(4),
        padding: 0,
        position: "absolute",
        top: "50%",
        left: theme.spacing(0.5),
        transform: "translateY(-50%)",
        color: theme.colors.text.lightGray,
        display: "none",
        "$categoryItemHover:hover &, $categoryItemDragging &": {
            display: "flex",
        },
    },
    categoryItem: {
        position: "relative",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.text.secondary,
        borderTop: `1px solid ${theme.colors.structure.rulesAndBorders}`,

        "& .control-icon": {
            visibility: "hidden",
            padding: "8px",
            borderRadius: "4px",
            marginLeft: "8px",
        },
    },
    categoryItemDragging: {
        backgroundColor: theme.colors.structure.pageBackground,
        border: 0,
    },
    categoryItemHover: {
        "&:hover": {
            color: theme.palette.primary.main,

            "& .category-name": {
                fontWeight: 500,
            },

            "& .control-icon": {
                visibility: "visible",

                "&:hover": {
                    backgroundColor: theme.colors.structure.pageBackground,
                },
            },
        },
    },
    selected: {
        color: theme.palette.text.primary,

        "& .category-name": {
            fontWeight: 500,
        },

        "& .folder-icon": {
            color: theme.palette.primary.main,
        },
    },
}));

export default useStyles;
