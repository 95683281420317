import React, { useMemo } from "react";

import { CoachingSession } from "types";
import { AppBar, Toolbar, Box, Button, CircularProgress } from "@material-ui/core";
import { LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW } from "types/constants";

import useStyles from "./styles";

interface WizardFooterProps {
    step: number;
    prevStep: () => void;
    nextStep: () => void;
    nextStepDisabled?: boolean;
    prevStepDisabled?: boolean;
    coachingSession: CoachingSession;
    isCoachingSkillObservationsSaving: boolean;
    isCoachingSkillObservationsDeleting: boolean;
}

function WizardFooter({ step, prevStep, nextStep, nextStepDisabled, prevStepDisabled, coachingSession }: WizardFooterProps) {
    const classes = useStyles();
    const { fcrResult } = coachingSession;

    const isTeamMemberView = localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW);

    const buttonText = useMemo(() => {
        switch (true) {
            case step === 0 && isTeamMemberView && !fcrResult?.submittedByCoacheeDate && fcrResult?.createdById !== fcrResult?.coachId:
                return "Submit";
            case step === 0 && isTeamMemberView && (!!fcrResult?.submittedByCoacheeDate || fcrResult?.createdById === fcrResult?.coachId):
                return "Back";
            case step === 0 && !isTeamMemberView:
                return "Next Step";
            case step === 3:
                return "Submit Report";
            default:
                return "Next Step";
        }
    }, [step, isTeamMemberView, fcrResult?.submittedByCoacheeDate, fcrResult?.createdById, fcrResult?.coachId])

    return (
        <AppBar position="sticky" color="inherit" className={classes.footer}>
            <Toolbar disableGutters={true}>
                <Box display="flex" flexDirection="row" width="100%" justifyContent="flex-end" px={{ xs: 2, md: 8 }} py={3} className={classes.footerContent} color="text.secondary">
                    {(step !== 0) && <Button variant="outlined" color="inherit" size="large" disableElevation onClick={prevStep} disabled={prevStepDisabled}>
                        Previous Step
                    </Button>}
                    <Box pl={1}>
                        <Button variant="contained" color="primary" size="large" disableElevation onClick={nextStep} disabled={nextStepDisabled}>
                            {nextStepDisabled ? <CircularProgress size={24} /> : buttonText}
                        </Button>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default WizardFooter;