import React from "react";
import {
    Select as SelectMaterial,
    SelectProps as SelectMaterialProps,
    FormControl,
    InputLabel,
    Typography,
    MenuItem,
    Box,
    CircularProgress,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useField } from "formik";

import useStyles from "./styles";

export interface SelectItemProps {
    title: string;
    id: string;
}

export interface SelectProps extends SelectMaterialProps {
    options?: SelectItemProps[];
    loading?: boolean;
}

const SelectLoader = () => (<Box display="flex" alignItems="center" mr={1.5}><CircularProgress size={20} /></Box>);

export function Select({
    children,
    label = "",
    variant = "outlined",
    options,
    loading = false,
    required = false,
    IconComponent = ExpandMore,
    ...props
}: SelectProps) {
    const classes = useStyles();

    return (
        <FormControl
            fullWidth
            required={required}
            classes={{
                root: classes.root,
            }}
        >
            {label && (
                <InputLabel
                    classes={{
                        root: classes.label,
                        shrink: classes.shrink,
                    }}
                >
                    <Typography component="span">{label}</Typography>
                </InputLabel>
            )}
            <SelectMaterial
                {...props}
                disabled={loading || props.disabled}
                variant={variant}
                fullWidth
                IconComponent={loading ? SelectLoader : IconComponent}
                classes={{
                    root: classes.select,
                }}
            >
                {children ? children :
                    !!options?.length && options.map(({ title, id }, index) => (
                        <MenuItem value={id} key={index}>
                            <Typography>{title}</Typography>
                        </MenuItem>
                    ))}
            </SelectMaterial>
        </FormControl>
    );
}

export function FormSelect({
    children,
    name = "",
    variant = "outlined",
    ...props
}: SelectProps) {
    const [field] = useField(name);

    return (
        <Select {...field} {...props} variant={variant} fullWidth={true}>
            {children}
        </Select>
    );
}
