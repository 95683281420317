import React, { useEffect, useState } from "react"
import { getCoachingHistoryForCoach, getCoachingHistoryForHighLevelLead } from "services/wizardServices";
import { UserCoachingHistory, UserProfile } from "types";

import CoachSummary from "../CoachSummary";
import TeamSummary from "../TeamSummary";

interface SummaryOverviewProps {
    user: UserProfile;
    teamMode: boolean;
    onDataIsFetched: () => void;
}

const INITIAL_HISTORY: UserCoachingHistory = {
    userId: "",
    userName: "",
    coachingHistory: [],
}

function SummaryOverview({ user, teamMode, onDataIsFetched }: SummaryOverviewProps) {
    const [isFetching, setIsFetching] = useState(false);
    const [history, setHistory] = useState(INITIAL_HISTORY.coachingHistory);
    const [historyLoadDate, setHistoryLoadDate] = useState<Date>(new Date());

    useEffect(() => {
        const getHistory = async (userId: string) => {
            setIsFetching(true);
            const getCoachingHistory = teamMode
                ? getCoachingHistoryForHighLevelLead
                : getCoachingHistoryForCoach;
            const response = await getCoachingHistory(userId).catch(() => INITIAL_HISTORY);
            setHistory(response.coachingHistory);
            setHistoryLoadDate(new Date());
            setIsFetching(false);
            onDataIsFetched();
        };

        user.id && getHistory(user.id);
    }, [user.id, teamMode, onDataIsFetched]);

    return (
        <>
            <CoachSummary isFetching={isFetching} data={history} currentDate={historyLoadDate} teamMode={teamMode} />
            <TeamSummary isFetching={isFetching} data={history} currentDate={historyLoadDate} teamMode={teamMode} />
        </>
    )
}

export default React.memo(SummaryOverview);
