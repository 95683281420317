import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    link: {
        display: "flex",
        color: theme.colors.text.mediumGray,
        "&:hover": {
            textDecoration: "underline",
        },
    },
    noUnderline: {
        textDecoration: "none",
    },
    noHoverUnderline: {
        "&:hover": {
            textDecoration: "none",
        },
    },
    fakeLink: {
        cursor: "pointer"
    }
}));
