import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => createStyles({
    iconButton: {
        minWidth: "initial",
        padding: 0,
    },
}));

export default useStyles;
