import React, { useState, useCallback, useEffect, useRef, useContext } from "react";
import clsx from "clsx";
import { Box, Link, IconButton, Typography, Tooltip, Divider } from "@material-ui/core";

import MultilineInput from "components/MultilineInput";
import DatePicker from "components/DatePicker";
import ResourceIcon from "components/ResourceIcon";
import { CoachingPlanType, FcrResource } from "types";
import { ReminderIcon } from "svgComponents";
import { getFcrResourcesForFcrCoachingPlan, updateFcrResourcesForFcrCoachingPlan, deleteFcrResource, getResourceCategoriesForCompany, getFcrResourcesForUser } from "services/resourceServices";
import { ResourceTypeEnum } from "types/constants";
import FcrResourceCategory from "types/FcrResourceCategory";
import orderBy from "lodash/orderBy";
import { ConfigurationContext } from "context";

import AddLinkModal from "./AddLinkModal";
import AddResourceModal from "./AddResourceModal";
import useStyles from "./styles";

interface CoachingPlanDataProps {
    coachingPlan: CoachingPlanType;
    assigneeId: string;
    onChange: (cpId: string, field: string, value: string | number | boolean | FcrResource[] ) => void;
}

function CoachingPlanData({ coachingPlan, assigneeId, onChange }: CoachingPlanDataProps) {
    const classes = useStyles();
    const { currentUser } = useContext(ConfigurationContext);

    const dataChange = useCallback((value: string) => onChange(coachingPlan.id, "coachingPlanData", value), [onChange, coachingPlan.id]);
    const dateChange = useCallback((date: Date) => onChange(coachingPlan.id, "dueDate", date.toISOString()), [onChange, coachingPlan.id]);
    const sendReminderChange = useCallback(() => onChange(coachingPlan.id, "sendReminder", !coachingPlan.sendReminder), [onChange, coachingPlan.id, coachingPlan.sendReminder]);
    const onResourceUpdate = useCallback((updatedResources: FcrResource[]) => onChange(coachingPlan.id, "resources", updatedResources) , [ onChange, coachingPlan.id ]);
    
    const [userAvailableResources, setUserAvailableResources] = useState<Array<FcrResource>>([]);
    const [resourceCategories, setResourceCategories] = useState<Array<FcrResourceCategory>>([]);
    const [resources, setResources] = useState<Array<FcrResource>>([]);
    const [hoverResource, setHoverResource] = useState<string | undefined>();
    
    const onResourceHover = useCallback((id: string) => () => setHoverResource(id), []);
    const onResourceLeave = useCallback(() => setHoverResource(undefined), []);
    
    useEffect(() => {
        const fetchData = async () => {
            const [userResources, categories, coachingPlanResource] = await Promise.all([
                getFcrResourcesForUser(assigneeId),
                getResourceCategoriesForCompany(currentUser?.companyId as string),
                getFcrResourcesForFcrCoachingPlan(coachingPlan.id)]);
            setUserAvailableResources(userResources);
            setResourceCategories(orderBy(categories, "order", "asc"));
            setResources(coachingPlanResource);
        }
        fetchData();
    }, [coachingPlan.id, currentUser?.companyId, assigneeId]);

    const saveResources = useCallback(async (resourcesForSave: FcrResource[]) => {
        await updateFcrResourcesForFcrCoachingPlan(
            coachingPlan.id,
            [...resources, ...resourcesForSave]
        );
        const updatedResources = await getFcrResourcesForFcrCoachingPlan(coachingPlan.id);
        onResourceUpdate(updatedResources);
        setResources(updatedResources);
    }, [coachingPlan.id, resources, onResourceUpdate]);

    const updateResource = useCallback(async (resourceForUpdate: FcrResource) => {
        await updateFcrResourcesForFcrCoachingPlan(
            coachingPlan.id,
            resources.map(resource => resource.id === resourceForUpdate.id ? resourceForUpdate : resource)
        );
        const updatedResources = await getFcrResourcesForFcrCoachingPlan(coachingPlan.id);
        onResourceUpdate(updatedResources);
        setResources(updatedResources);
    }, [coachingPlan.id, resources, onResourceUpdate]);

    const deleteResource = useCallback((resourceForDelete: FcrResource) => async () => {
        const newResources = resources.filter(resource => resource.id !== resourceForDelete.id);
        setResources(newResources);

        resourceForDelete.type === ResourceTypeEnum.link && await deleteFcrResource(resourceForDelete.type, resourceForDelete.id);
        await updateFcrResourcesForFcrCoachingPlan(coachingPlan.id, newResources);

        const updatedResources = await getFcrResourcesForFcrCoachingPlan(coachingPlan.id);
        onResourceUpdate(updatedResources);
        setResources(updatedResources);
    }, [coachingPlan.id, resources, onResourceUpdate]);

    const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);
    const [resourceForEdit, setResourceForEdit] = useState<FcrResource | undefined>();
    const [isOpenResourceModal, setIsOpenResourceModal] = useState(false);

    const anchorLink = useRef(null);
    const anchorResource = useRef(null);

    const openLinkModal = useCallback((resource?: FcrResource) => () => {
        setResourceForEdit(resource);
        setIsOpenLinkModal(true);
    }, []);
    const closeLinkModal = useCallback(() => setIsOpenLinkModal(false), []);

    const openResourceModal = useCallback(() => {
        setIsOpenResourceModal(true);
    }, []);
    const closeResourceModal = useCallback(() => setIsOpenResourceModal(false), []);

    return (
        <Box key={coachingPlan.id}>
            <MultilineInput
                name="Coaching Plan"
                placeholder="Document action steps to advance skills including potential resources and timeline."
                value={coachingPlan.coachingPlanData}
                onChange={dataChange}>
                <Box display="flex" alignItems="center" justifyContent="space-between" px={1}>
                    <Box display="flex" alignItems="center" flexDirection="row" height="100%">
                        <Box pr={0.5}>
                            <Typography variant="h3">Due Date:</Typography>
                        </Box>
                        <DatePicker date={new Date(coachingPlan.dueDate)} onChange={dateChange}/>
                    </Box>
                    <Tooltip title="Add reminder">
                        <IconButton
                            disableRipple
                            className={clsx(classes.reminder, coachingPlan.sendReminder && classes.sendReminder)}
                            onClick={sendReminderChange}
                        >
                            <ReminderIcon cursor="pointer" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </MultilineInput>
            {resources.length > 0 &&
                <Box pt={5} fontWeight="600">
                    <Typography variant="h3">Resources</Typography>
                    {resources.map(resource =>
                        <Box
                            key={resource.id}
                            display="flex" alignItems="center" pt={1} fontWeight="initial"
                            onMouseEnter={onResourceHover(resource.id)}
                            onMouseLeave={onResourceLeave}
                        >
                            <Box pr={2} display="flex" alignItems="center" color="text.secondary">
                                <ResourceIcon type={resource.type} />
                            </Box>
                            <Typography variant="h3">
                                {resource.name}
                            </Typography>
                            {hoverResource === resource.id &&
                                <>
                                    {resource.type === ResourceTypeEnum.link && <Box pl={2}>
                                        <Link
                                            component="button"
                                            underline="always"
                                            className={classes.resourceActions}
                                            onClick={openLinkModal(resource)}
                                        >
                                            Edit
                                        </Link>
                                    </Box>}
                                    <Box pl={2}>
                                        <Link
                                            component="button"
                                            underline="always"
                                            className={classes.resourceActions}
                                            onClick={deleteResource(resource)}
                                        >
                                            Remove
                                        </Link>
                                    </Box>
                                </>
                            }
                        </Box>
                    )}
                </Box>
            }
            <Box py={resources.length ? 1 : 4} display="flex">
                <Link
                    component="button"
                    underline="always"
                    onClick={openResourceModal}
                    ref={anchorResource}
                >
                    Add Resources
                </Link>
                <Box pl={1}>
                    <Link
                        component="button"
                        underline="always"
                        onClick={openLinkModal()}
                        ref={anchorLink}
                    >
                        Add Link
                    </Link>
                </Box>
                {isOpenResourceModal &&
                    <AddResourceModal
                        anchorEl={anchorResource.current}
                        resources={resources}
                        resourceCategories={resourceCategories}
                        userAvailableResources={userAvailableResources}
                        saveResources={saveResources}
                        closeModal={closeResourceModal}
                    />}
                {isOpenLinkModal &&
                    <AddLinkModal
                        anchorEl={anchorLink.current}
                        resourceForEdit={resourceForEdit}
                        updateResource={updateResource}
                        saveResources={saveResources}
                        defaultCategory={resourceCategories[0]}
                        closeModal={closeLinkModal}
                    />}
            </Box>
            <Box pb={5}>
                <Divider />
            </Box>
        </Box>
    )
}

export default CoachingPlanData;
