import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useParams, useHistory } from "react-router-dom";

import { AdminRouteTeamParams, Team, UserProfile } from "types";
import { AdminRoutings } from "types/constants";
import useOnCompanyChange from "hooks/useOnCompanyChange";
import { CompaniesContext } from "context";
import { getTeam } from "services/teamServices";
import { getAvailableTeamCoLeaders } from "services/coLeadService";
import { getAvailableLeadersForTeam, getUsersByTeamId } from "services/userServices";
import { getAmountOfIncompleteReportsForCoach, getAmountOfIncompleteReportsForCoachee } from "services/coachingSessionServices";

import TeamForm from "./TeamForm";

export default function TeamPage() {
    const history = useHistory();
    const { teamId } = useParams<AdminRouteTeamParams>();
    const { currentCompany, companyLevels, isFetchingCompanyData } = useContext(CompaniesContext);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [team, setTeam] = useState<Team>();
    const [leaders, setLeaders] = useState<UserProfile[]>([]);
    const [coLeaders, setCoLeaders] = useState<UserProfile[]>([]);
    const [potentialMembers, setPotentialMembers] = useState<UserProfile[]>([]);
    const [amountOfIncompleteReportsForCoach, setAmountOfIncompleteReportsForCoach] = useState<number>(0);
    const [amountOfIncompleteReportsForCoachees, setAmountOfIncompleteReportsForCoachees] = useState<{ [key: string]: number }>({});

    useOnCompanyChange(() => history.push(AdminRoutings.teams));

    const handleTeamFetched = useCallback((team: Team) => {
        setTeam(team);
        if (team.teamLeader) {
            getAmountOfIncompleteReportsForCoach(team.teamLeader.id)
                .then(setAmountOfIncompleteReportsForCoach);
        }
    }, []);

    useEffect(() => {
        if (isFetchingCompanyData || !currentCompany) return;

        setIsFetching(true);

        Promise.all([
            getTeam(teamId).then(handleTeamFetched),
            getUsersByTeamId(teamId).then(setPotentialMembers),
            getAvailableTeamCoLeaders(teamId).then(setCoLeaders),
            getAvailableLeadersForTeam(currentCompany.id, teamId).then(setLeaders),
        ]).finally(() =>
            setIsFetching(false)
        );
    }, [teamId, isFetchingCompanyData, currentCompany, handleTeamFetched]);

    const fetchAmountOfIncompleteReportsForCoachee = useCallback((userId: string) => {
        if (typeof amountOfIncompleteReportsForCoachees[userId] === "number") return;

        getAmountOfIncompleteReportsForCoachee(userId).then((amount: number) => {
            setAmountOfIncompleteReportsForCoachees((prevAmounts) => ({ ...prevAmounts, [userId]: amount }));
        });
    }, [amountOfIncompleteReportsForCoachees]);

    if (isFetching || isFetchingCompanyData) return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>
    );

    if (!team) return (
        <Alert severity="error">
            Something went wrong. Try to <Link onClick={() => history.go(0)}>reload the page</Link>.
        </Alert>
    );

    return (
        <TeamForm
            team={team}
            leaders={leaders}
            coLeaders={coLeaders}
            potentialMembers={potentialMembers}
            companyLevels={companyLevels}
            amountOfIncompleteReportsForCoach={amountOfIncompleteReportsForCoach}
            amountOfIncompleteReportsForCoachees={amountOfIncompleteReportsForCoachees}
            fetchAmountOfIncompleteReportsForCoachee={fetchAmountOfIncompleteReportsForCoachee}
        />
    )
}
