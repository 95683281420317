import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
    item: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
            cursor: "pointer",
        },
    },
    itemText: {
        paddingLeft: theme.spacing(3),
        fontWeight: 500,
    },
    itemNotAllowed: {
        "&:hover": {
            backgroundColor: "initial",
            cursor: "default",
        },
    },
}));