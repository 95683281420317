import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        "& .MuiTableCell-head": {
            fontWeight: 600,
            fontSize: theme.typography.h3.fontSize,
        },
        "& .MuiTableCell-root": {
            borderColor: theme.colors.structure.rulesAndBorders,
        },
        "& .MuiTableCell-root:first-child": {
            paddingLeft: theme.spacing(4),
        },
        "& .MuiTableCell-root:last-child": {
            paddingRight: theme.spacing(4),
        },
        "& .MuiTableBody-root": {
            "& .MuiTableRow-root:last-child .MuiTableCell-root": {
                borderBottom: 0,
            },
        },
    },
}));

export default useStyles;
