import * as React from "react";

function SvgTeamProfiles(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g opacity={0.7} fill="currentColor">
                <path d="M8.12 16.25C3.65 16.25 0 12.6 0 8.12S3.65 0 8.12 0c4.47 0 8.12 3.65 8.12 8.12 0 4.47-3.64 8.13-8.12 8.13zm0-15c-3.79 0-6.88 3.08-6.88 6.88S4.33 15 8.12 15 15 11.92 15 8.12s-3.08-6.87-6.88-6.87z" />
                <path d="M8.12 8.95c-.79 0-1.55-.27-2.16-.79a3.364 3.364 0 01-1.2-2.29c-.08-.89.2-1.77.78-2.46.58-.69 1.39-1.12 2.29-1.2.89-.08 1.77.19 2.46.77a3.374 3.374 0 01-1.86 5.95c-.11.01-.21.02-.31.02zm.01-5.5c-.06 0-.13 0-.19.01-.57.05-1.08.31-1.45.75a2.135 2.135 0 00.27 2.99c.44.37.99.54 1.55.49a2.13 2.13 0 001.93-2.31c-.1-1.1-1.03-1.93-2.11-1.93zM12.92 14.14c-.86-2.14-2.98-3.43-5.28-3.23-1.9.17-3.55 1.37-4.31 3.12l-1.15-.5a6.458 6.458 0 015.35-3.87 6.406 6.406 0 016.55 4.01l-1.16.47z" />
            </g>
        </svg>
    );
}

export default SvgTeamProfiles;
