import React, { useContext } from "react";

import { Box, Typography } from "@material-ui/core";
import { Routings } from "types/constants";
import LinkButton from "components/LinkButton";
import { Success } from "svgComponents";
import { ConfigurationContext } from "context";

const Submitted = () => {
    const { companyCertificationConfiguration } = useContext(ConfigurationContext);

    return (
        <Box mb={4}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={4}
                py={3}
                bgcolor="#ECF8EC"
                border="1px solid #3EB943"
                borderRadius="8px">
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                        <Success stroke="#3EB943" />
                        <Box pl={3.5} fontWeight="600">
                            <Typography variant="h2">This form has been submitted.</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box mr={2} color="text.secondary">
                        <LinkButton href={Routings.classes} variant="outlined" color="inherit" size="large">
                            Go to {companyCertificationConfiguration?.section1Label}
                        </LinkButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default { Submitted };
