import * as React from "react";

function SvgRightArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={7}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                opacity={0.7}
                d="M6.31 6.223l-5.25 5.25-.88-.88 4.36-4.37-4.36-4.36.88-.88 5.25 5.24z"
                fill="#5A6F8A"
            />
        </svg>
    );
}

export default SvgRightArrow;
