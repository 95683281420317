import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react"
import { Box, CircularProgress, Divider, Grid, Paper, Typography, Link } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { ChevronLeft } from "svgComponents";
import { MessageType, MessageThread } from "types";
import { Routings, SizeEnum } from "types/constants";
import { formatDateTime } from "helpers/dateHelpers";
import { CoachingHubContext, ConfigurationContext } from "context";
import RichText from "components/RichText";
import AvatarWithScore from "components/AvatarWithScore";
import MessageHistoryItem from "components/MessageHistoryItem";
import NavigationLink from "components/NavigationLink";
import MessageHistoryThreadReply from "components/MessageHistoryThreadReply";
import { markThreadAsReadForUser, getMessageThread } from "services/messageServices";

const LATEST_REPLIES_COUNT = 2;

export default function MessageDetail() {
    const { threadId } = useParams<{ threadId: string }>();
    const { currentUser } = useContext(ConfigurationContext);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const { handleMessagesSaved, handleMarkThreadAsRead, clearMessagesThreads } = useContext(CoachingHubContext);
    const [originalMessage, setOriginalMessage] = useState<MessageThread | null>(null);
    const [replies, setReplies] = useState<MessageType[] | null>(null);
    const isMarkedAsRead = useRef<boolean>(false);
    const [showAllReplies, setShowAllReplies] = useState(false);

    const latestReplies = useMemo(
        () => replies && replies.slice(replies.length - LATEST_REPLIES_COUNT, replies.length),
        [replies]
    );
    
    useEffect(() => {
        if (!originalMessage) {            
            setIsFetching(true);
            getMessageThread(threadId).then((message) => {
                setOriginalMessage(message);
                setReplies(
                    message.messages && message.messages.length > 0 ? 
                        message.messages.slice(1): 
                        null
                );
                setIsFetching(false)
            });
            
        }
    }, [originalMessage, threadId]);

    const isLoading = !currentUser || isFetching;    

    useEffect(() => {
        if (!isMarkedAsRead.current && originalMessage && originalMessage.totalNewMessages > 0 && currentUser?.id) {
            markThreadAsReadForUser(threadId, currentUser.id).then(() => {
                handleMarkThreadAsRead(threadId);
                isMarkedAsRead.current = true;
            });
        }
    }, [currentUser?.id, handleMarkThreadAsRead, originalMessage, threadId]);

    const handleResponseSent = useCallback((message: MessageType) => {
        handleMessagesSaved([message]);

        if (currentUser?.id) {
            markThreadAsReadForUser(threadId, currentUser.id).then(() => {
                handleMarkThreadAsRead(threadId);
                isMarkedAsRead.current = true;
            });
        }
        
        replies && setReplies([...replies, message]);
    }, [currentUser?.id, handleMarkThreadAsRead, handleMessagesSaved, replies, threadId]);

    const onShowAll = useCallback((e) => {
        e.preventDefault();
        setShowAllReplies(true);
    }, []);

    return (
        <Box width={1} px={6}>
            <Paper>
                <Box px={5} py={3} fontWeight={600}>
                    <NavigationLink hoverUnderline={false} href={`${Routings.coachingHub}/messages`}>
                        <Typography variant="h2" onClick={() => clearMessagesThreads()}>
                            <Box display="flex" alignItems="center" gridGap={12}>
                                <ChevronLeft display="block" height={24} width={24} />
                                Back To Messages
                            </Box>
                        </Typography>
                    </NavigationLink>
                </Box>
                <Divider />
                {!isLoading && originalMessage && (
                    <>
                        <Box px={5} py={6}>
                            <Grid container>
                                <Grid item md={2} />
                                <Grid item xs={12} md={8}>
                                    <Box display="grid" gridTemplateColumns="100px 1fr" alignItems="center" mb={3}>
                                        <Typography variant="caption">Subject:</Typography>
                                        <Typography variant="h1">{originalMessage.messages[0].subject}</Typography>
                                    </Box>
                                    <Box display="grid" gridTemplateColumns="100px 1fr" alignItems="center" mb={3}>
                                        <Typography variant="caption">Sent:</Typography>
                                        <Typography variant="body1">{formatDateTime(new Date(originalMessage.messages[0].createdDate))}</Typography>
                                    </Box>
                                    <Box display="grid" gridTemplateColumns="100px 1fr" alignItems="center" mb={3}>
                                        <Typography variant="caption">From:</Typography>
                                        <Box display="flex">
                                            <AvatarWithScore user={originalMessage.fromUser} size={SizeEnum.small} />
                                        </Box>
                                    </Box>
                                    <Box display="grid" gridTemplateColumns="100px 1fr">
                                        <Typography variant="caption">Message:</Typography>
                                        <Typography component="div" variant="body1">
                                            <RichText text={originalMessage.messages[0].messageContent} />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={2} />
                            </Grid>
                        </Box>
                        <Divider />
                        <Box px={5} py={6}>
                            <Grid container>
                                <Grid item md={2} />
                                <Grid item xs={12} md={8}>
                                    {replies && replies.length > 0 &&
                                        <>
                                            <Box fontWeight={600}>
                                                <Typography variant="h2">Responses ({replies?.length})</Typography>
                                            </Box>
                                            {!showAllReplies && latestReplies && replies && replies.length > LATEST_REPLIES_COUNT ? (
                                                <Box mt={3}>
                                                    <Link href="" underline="always" onClick={onShowAll}>
                                                        Show {replies.length - LATEST_REPLIES_COUNT} more replies
                                                    </Link>
                                                    {latestReplies.map((reply) => (
                                                        <React.Fragment key={reply.id}>
                                                            <Box py={3}>
                                                                <MessageHistoryItem message={reply} currentUser={currentUser} />
                                                            </Box>
                                                            <Divider />
                                                        </React.Fragment>
                                                    ))}
                                                </Box>
                                            ) : (
                                                replies.map((reply: MessageType) => (
                                                    <React.Fragment key={reply.id}>
                                                        <Box py={3} key={reply.id}>
                                                            <MessageHistoryItem message={reply} currentUser={currentUser} />
                                                        </Box>
                                                        <Divider />
                                                    </React.Fragment>
                                                ))
                                            )}
                                        </>
                                    }
                                    {currentUser && (
                                        <Box pt={3}>
                                            <MessageHistoryThreadReply
                                                currentUser={currentUser}
                                                originalMessage={originalMessage.messages[0]}
                                                onResponseSent={handleResponseSent}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item md={2} />
                            </Grid>
                        </Box>
                    </>
                )}
                {isLoading && (
                    <Box p={4} textAlign="center">
                        <CircularProgress size={40} />
                    </Box>
                )}
            </Paper>
        </Box>
    );
}
