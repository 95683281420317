/* eslint-disable functional/immutable-data */

import { FcrResource } from "types";

export const toBase64 = async (file: File): Promise<{ base64: string, url: string }> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({
            base64: String(reader.result).split("base64,")[1],
            url: String(reader.result),
        });
        reader.onerror = error => reject(error);
    });

export function downloadBase64(
    file: FcrResource,
    linkEl: HTMLAnchorElement = document.createElement("a")
): void {
    if (!file.contentType || !file.documentBytes) return;
    linkEl.setAttribute("href", fcrResourceToBase64(file));
    linkEl.setAttribute("download", file.documentFilename ?? "")
    linkEl.click();
}

export function fcrResourceToBase64(file: FcrResource) {
    return `data:${file.contentType};base64,${file.documentBytes}`;
}