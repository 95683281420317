import React, { useContext, useMemo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Header from "components/Header";
import { HeaderProps } from "types";
import { Routings, TeamSkillsRouting, LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW } from "types/constants";
import { ConfigurationContext } from "context";

import useStyles from "./styles";
import TeamProfileWrapper from "../TeamProfileWrapper";
import TeamSkillHistory from "./TeamSkillHistory";
import TeamDevelopmentHistory from "./TeamDevelopmentHistory";
import CoachingHistory from "./CoachingHistory";
import CoachingReports from "./CoachingReports";
import Messages from "./Messages";
import Sessions from "./Sessions";
import LeaderDashboard from "./DashboardContent/LeaderDashboard";

function Dashboard({ isBarOpen, navBarOpen }: HeaderProps) {
    const { path } = useRouteMatch();
    const classes = useStyles();
    const { currentUser } = useContext(ConfigurationContext);

    const pathForRouting = useMemo(() => path === "/" ? "" : path, [path]);
    const isTeamMemberView = localStorage.getItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW);

    return currentUser ? (
        <>
            {!isTeamMemberView ? (
                <>
                    <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
                    <Switch>
                        <Route exact path={path}>
                            <LeaderDashboard user={currentUser} />
                        </Route>
                        <Route path={`${TeamSkillsRouting.highLevel}/:userId/:skillId`}>
                            <TeamSkillHistory isHighLevel={true} />
                        </Route>
                        <Route path={`${TeamSkillsRouting.personal}/:userId/:skillId`}>
                            <TeamSkillHistory />
                        </Route>
                        <Route path={Routings.teamDevelopment}>
                            <TeamDevelopmentHistory />
                        </Route>
                        <Route path={Routings.coaching}>
                            <CoachingHistory />
                        </Route>
                    </Switch>
                </>
            ) : (
                <Switch>
                    <Route path={`${pathForRouting}${Routings.allReports}`}>
                        <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
                        <CoachingReports />
                    </Route>
                    <Route path={`${pathForRouting}${Routings.allMessages}`}>
                        <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
                        <Messages />
                    </Route>
                    <Route path={`${pathForRouting}${Routings.sessions}`}>
                        <Header isBarOpen={isBarOpen} navBarOpen={navBarOpen} className={classes.header} />
                        <Sessions />
                    </Route>
                    <Route path={path}>
                        <TeamProfileWrapper isBarOpen={isBarOpen} navBarOpen={navBarOpen}/>
                    </Route>
                </Switch>
            )}
        </>
    ) : null;
}

export default Dashboard;
