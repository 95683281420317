import React, { useCallback } from "react";
import {
    List,
    Button,
    Paper,
    Typography,
    Box,
    Tooltip,
} from "@material-ui/core";
import union from "lodash/union";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import { UserProfile, CompanyLevel } from "types";

import CustomListItem from "./CustomListItem";
import useStyles from "../styles";

interface CustomListProps {
    title?: string;
    usersIds?: string[];
    users?: UserProfile[];
    checked: string[];
    companyLevels: CompanyLevel[];
    setChecked: (checked: string[]) => void;
    resetChecked: (checked: string[]) => void;
}

const CustomList = ({
    title,
    usersIds,
    users,
    checked,
    companyLevels,
    setChecked,
    resetChecked,
}: CustomListProps) => {
    const classes = useStyles();

    const handleToggle = useCallback(
        (value: string) => () => {
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            resetChecked([])
            setChecked(newChecked);
        },
        [checked, resetChecked, setChecked]
    );

    const numberOfChecked = useCallback(
        (usersIds) => intersection(checked, usersIds).length,
        [checked]
    );

    const handleToggleAll = useCallback(
        (usersIds?: string[]) => () => {
            resetChecked([])
            if (!!usersIds?.length && numberOfChecked(usersIds) === usersIds.length) {
                setChecked(difference(checked, usersIds));
            } else {
                setChecked(union(checked, usersIds));
            }
        },
        [checked, numberOfChecked, resetChecked, setChecked]
    );

    return (
        <>
            <Box
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography noWrap>{title}</Typography>
                <Tooltip
                    arrow
                    placement="top"
                    title={"When using Select All, this will only select those that are visible in the list."}
                >
                    <Button color="primary" onClick={handleToggleAll(usersIds)}>
                        <Box whiteSpace="nowrap">Select all</Box>
                    </Button>
                </Tooltip>
            </Box>
            <Paper className={classes.paper}>
                <List dense component="div" role="list" className={classes.list}>
                    {users?.map((user) => 
                        <CustomListItem
                            key={user.id}
                            user={user}
                            checked={checked}
                            handleToggle={handleToggle}
                            companyLevels={companyLevels}
                        />
                    )}
                </List>
            </Paper>
        </>
    );
};

export default CustomList;
