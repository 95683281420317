import React, { useMemo } from "react";
import { Box, FormControlLabel } from "@material-ui/core";
import { useFormikContext } from "formik";

import { EmailConfigurationForm } from "types";
import { FormInput, FormSwitcher } from "components/Form";
interface OtherFormSwitcherProps {
    name: string;
}

export default function OtherFormSwitcher({ name }: OtherFormSwitcherProps) {
    const formik = useFormikContext<EmailConfigurationForm>();
    const switcherName = useMemo(() => `${name}Checked`, [name]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        formik.setFieldValue(name, "");
        formik.setFieldValue(switcherName, checked);
    };

    return (
        <>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <FormSwitcher
                            name={switcherName}
                            onChange={handleChange}
                            value={formik.values[switcherName as keyof EmailConfigurationForm]}
                        />
                    }
                    label="Other"
                />
            </Box>
            <Box ml={4}>
                <FormInput
                    name={name}
                    label="Enter semicolon delimited email"
                    disabled={!formik.values[switcherName as keyof EmailConfigurationForm]}
                />
            </Box>
        </>
    );
}
