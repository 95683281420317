import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    footer: {
        borderRadius: "0px 0px 6px 6px",
        borderColor: theme.colors.structure.fieldsOutline,
        borderWidth: "0px 1px 1px",
        borderStyle: "solid",
    },
    textarea: {
        "&.extendedBorder .ql-container": {
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
        },
        "& .ql-toolbar": {
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            padding: "8px 12px",
        },
        "& .ql-container": {
            fontFamily: "inherit",
            fontSize: "15px",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            padding: "8px 6px",
            minHeight: "130px",
        },
        "& .ql-editor.ql-blank::before": {
            left: "21px",
            color: "#B9C0C9",
            fontStyle: "normal",
        },
    },
    textareaError: {
        "& .ql-toolbar, & .ql-container": {
            borderColor: theme.palette.secondary.main,
        },
    },
}));

export default useStyles;
