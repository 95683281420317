import React from "react";
import SvgFocus from "svgComponents/Focus";
import clsx from "clsx";

import useStyles from "./styles";

const FocusIcon = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
    const classes = useStyles()

    return <SvgFocus className={clsx(classes.root, className)} {...props} />
} 

export default FocusIcon;
