import React from "react";
import { Box, Typography, Paper, CircularProgress } from "@material-ui/core";
import { UserProfile, CompanyLevel } from "types";
import TransferList from "components/TransferList";

import { AssignmentWarningMessage } from "components/AssignmentMessages";

import useStyles from "./styles";

interface AssignTeamMembersProps {
    setCheckedUserIds?: (leftUserIds: string[] | null, rightUserIds: string[] | null) => void;
    fetchMembers?: (pageNumber: number, getAssigned: boolean) => void;
    membersLevelIds: Array<CompanyLevel["id"]>;
    availableMembers: UserProfile[] | null;
    totalAmountAvailableMembers?: number;
    setTotalAmountAvailableMembers?: (totalAmount: number) => void;
    setAvailableMembers?: (users: UserProfile[]) => void;
    availableMembersPage: number | null;
    setAvailableMembersPage: (page: number) => void;
    distributionMembers: UserProfile[] | null;
    totalAmountDistributionMembers?: number;
    setTotalAmountDistributionMembers?: (totalAmount: number) => void;
    setDistributionMembers?: (users: UserProfile[]) => void;
    distributionMemberPage: number | null;
    setDistributionMemberPage: (page: number) => void;
    potentialMembers?: UserProfile[];
    companyLevels: CompanyLevel[];
    setUserIds: (ids: UserProfile[]) => void;
    description?: string | React.ReactNode;
    leftTitle?: string;
    rightTitle?: string;
    isSubmitting?: boolean;
}

function AssignTeamMembers({
    membersLevelIds,
    availableMembers,
    setAvailableMembers,
    totalAmountAvailableMembers,
    setTotalAmountAvailableMembers,
    availableMembersPage,
    setAvailableMembersPage,
    distributionMembers,
    setDistributionMembers,
    totalAmountDistributionMembers,
    setTotalAmountDistributionMembers,
    distributionMemberPage,
    setDistributionMemberPage,
    companyLevels,
    setUserIds,
    description,
    leftTitle,
    rightTitle,
    potentialMembers,
    isSubmitting,
    fetchMembers,
    setCheckedUserIds,
}: AssignTeamMembersProps) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Box display="flex" flexDirection="column" py={2} px={3}>
                <Box mb={2} fontWeight={600}>
                    <Typography variant="h3">Assign Team Members</Typography>
                </Box>
                {description}
                <Box mb={3}>
                    <AssignmentWarningMessage />
                </Box>
                {!isSubmitting && distributionMembers && availableMembers ? (
                    <TransferList
                        availableMembers={availableMembers}
                        totalAmountAvailableMembers={totalAmountAvailableMembers}
                        setTotalAmountAvailableMembers={setTotalAmountAvailableMembers}
                        setAvailableMembers={setAvailableMembers}
                        availableMembersPage={availableMembersPage}
                        setAvailableMembersPage={setAvailableMembersPage}
                        distributionMembers={distributionMembers}
                        totalAmountDistributionMembers={totalAmountDistributionMembers}
                        setTotalAmountDistributionMembers={setTotalAmountDistributionMembers}
                        setDistributionMembers={setDistributionMembers}
                        distributionMemberPage={distributionMemberPage}
                        setDistributionMemberPage={setDistributionMemberPage}
                        setCheckedUserIds={setCheckedUserIds}
                        fetchMembers={fetchMembers}
                        leftTitle={leftTitle}
                        rightTitle={rightTitle}
                        setUserIds={setUserIds}
                        membersLevelIds={membersLevelIds}
                        companyLevels={companyLevels}
                        potentialMembers={potentialMembers}
                    />
                ) : (
                    <Box display="flex" justifyContent="center" py={10}>
                        <CircularProgress size={24} />
                    </Box>
                )}
            </Box>
        </Paper>
    );
}

export default AssignTeamMembers;
