import { ImportResult } from "types";

import API from "./apiService";

const prepareFormDataBody = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    return formData;
};

export const importMembers = async (companyId: string, file: File): Promise<ImportResult> => {
    const result = await API.post(`/import/roster/${companyId}`, prepareFormDataBody(file));

    return result.data;
}

export const importCompany = async (file: File): Promise<ImportResult> => {
    const result = await API.post("/import/company", prepareFormDataBody(file));
    return result.data;
}

export const importUserProfilesImages = async(companyId: string, file: File): Promise<ImportResult> => {
    const result = await API.post(`/import/userProfileImages/${companyId}`, prepareFormDataBody(file));
    return result.data;
}

export const importProductSalesData = async (companyId: string, replaceLatestImport: boolean, file: File): Promise<ImportResult> => {
    const result = await API.post(`/import/productSalesData/${companyId}/${replaceLatestImport}`, prepareFormDataBody(file));
    return result.data;
}

export const importClassRoster = async (companyId: string, file: File): Promise<ImportResult> => {
    const result = await API.post(`/import/companyClassesRoster/${companyId}`, prepareFormDataBody(file));

    return result.data;
}