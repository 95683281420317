import * as React from "react";

function SvgSwitch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.94 9.25v1.73c0 1.39-1.13 2.51-2.51 2.51H2.77l1.94-1.94-.88-.88-3.45 3.45 3.45 3.45.88-.88-1.94-1.94h8.65c2.07 0 3.76-1.69 3.76-3.76V9.25h-1.24zM1.25 6.59c0-1.39 1.13-2.51 2.51-2.51h8.65l-1.94 1.94.88.88 3.45-3.45L11.35 0l-.88.88 1.94 1.94H3.76A3.777 3.777 0 000 6.59v1.73h1.25V6.59z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSwitch;
