import { makeStyles, Theme } from "@material-ui/core/styles";

import { ScoreBarMode } from ".";

interface ScoreBarStylesProps {
    mode?: ScoreBarMode;
}

export default makeStyles<Theme, ScoreBarStylesProps>((theme: Theme) => ({
    scoreBar: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    scoreWrapper: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        fontWeight: 600,
        height: "44px",
        width: "44px",
        background: ({ mode }) => (
            (mode === ScoreBarMode.DARK) ? theme.palette.text.primary : theme.palette.primary.contrastText
        ),
        boxShadow: "0px 0px 5px rgba(39, 71, 110, 0.3)",
        marginLeft: "82px",
        marginTop: "68px",
        zIndex: 1,
        "& *": {
            color: ({ mode }) => (
                (mode === ScoreBarMode.DARK) ? theme.palette.primary.contrastText : theme.palette.text.primary
            ),
        },
    },
    scoreValue: {
        lineHeight: "1",
    },
    scoreCaption: {
        fontSize: "8px",
        lineHeight: "1.2",
        letterSpacing: "0.11em",
        textTransform: "uppercase",
    },
    graphCircle: {
        strokeLinecap: "round",
    },
    graphBottom: {
        position: "absolute",
        color: theme.colors.structure.rulesAndBorders,
    },
    graphHigh: {
        stroke: "url(#gradientHigh)",
    },
    graphMedium: {
        stroke: "url(#gradientMedium)",
    },
    graphLow: {
        stroke: "url(#gradientLow)",
    },
    graphLowest: {
        stroke: "url(#gradientLowest)",
    },
}));
