import * as React from "react";

function SvgGoals(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg  height="27" width="31"viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M30.4003 25.13L23.2503 13.57C23.1403 13.39 22.9303 13.27 22.7203 13.27C22.5003 13.27 22.3003 13.38 22.1903 13.57L18.9803 18.84L12.0203 7.59V4.12H16.0703C16.3003 4.12 16.5203 3.99 16.6203 3.78C16.7303 3.57 16.7103 3.32 16.5803 3.13L15.8003 2.04L16.5603 0.99C16.7003 0.8 16.7203 0.55 16.6103 0.34C16.5103 0.13 16.2903 0 16.0603 0H11.3803C11.0303 0 10.7603 0.28 10.7603 0.62V3.49C10.7603 3.5 10.7703 3.51 10.7703 3.52V7.6L0.0903142 25.13C-0.0296858 25.32 -0.0296858 25.56 0.0803142 25.76C0.190314 25.96 0.400314 26.08 0.620314 26.08H15.6703H15.8803H29.8703C30.1003 26.08 30.3103 25.96 30.4203 25.76C30.5203 25.56 30.5203 25.32 30.4003 25.13ZM22.7203 15.09L24.2103 17.5H21.2503L22.7203 15.09ZM11.4003 8.97L14.5103 13.99H8.34031L11.4003 8.97ZM14.8303 1.25L14.5303 1.66C14.3703 1.88 14.3703 2.17 14.5203 2.39L14.8503 2.87H12.0103V2.55C12.0103 2.54 12.0103 2.53 12.0103 2.53V1.25H14.8303ZM7.58031 15.24H15.2403C15.2503 15.24 15.2603 15.23 15.2703 15.23L18.2403 20.03L15.3203 24.83H1.74031L7.58031 15.24ZM16.7803 24.83L19.4803 20.39L19.4903 20.38L20.4803 18.75H24.9803L28.7403 24.83H16.7803Z" />
        </svg>
    );
}

export default SvgGoals;
