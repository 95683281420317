import * as React from "react";

function SvgRating(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16} 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <rect id="path-1" x="0" y="0" width="780" height="796" rx="14"></rect>
        <filter x="-3.0%" y="-2.3%" width="106.0%" height="105.9%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset dx="0" dy="5" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="7" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.06 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
      </defs>
      <g id="Skills" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="New-Skill" transform="translate(-342.000000, -605.000000)">
          <g id="form" transform="translate(14.000000, 9.000000)">
            <g id="bg">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
              <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"></use>
            </g>
            <g id="body" transform="translate(0.000000, 92.000000)">
              <g id="Ratings" transform="translate(32.000000, 414.000000)">
                <g id="New-Rating" transform="translate(0.000000, 48.000000)">
                  <path d="M6,0 L710,0 C713.313708,-6.08718376e-16 716,2.6862915 716,6 L716,94 C716,97.3137085 713.313708,100 710,100 L6,100 C2.6862915,100 4.05812251e-16,97.3137085 0,94 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z" id="dnd_area" strokeOpacity="0.4" stroke="#52AABE" fillOpacity="0.06" fill="#52AABE" strokeDasharray="6,4"></path>
                  <g id="Buttons-/-Ghost-/--Icon-+-Label" transform="translate(296.000000, 38.000000)" fill="#1FACC1">
                    <g id="Icon" transform="translate(0.000000, 4.000000)">
                      <path d="M0,16 L0,15 L1,15 L1,10 L5,10 L5,15 L6,15 L6,5 L10,5 L10,15 L11,15 L11,0 L15,0 L15,15 L16,15 L16,16 L0,16 Z M4,11 L2,11 L2,15 L4,15 L4,11 Z M9,6 L7,6 L7,15 L9,15 L9,6 Z M14,1 L12,1 L12,15 L14,15 L14,1 Z" id="Combined-Shape"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgRating;
