import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: "100%",
            height: 280,
            padding: 0,
            overflow: "auto",
            border: `1px solid ${theme.colors.structure.background}`,
            borderRadius: "6px",
            background: "linear - gradient(180deg, #EEEEEE 0 %, #DADADA 85.38 %, rgba(216, 216, 216, 0) 100 %)",
        },
        list: {
            padding: 0,
        },
    })
);

export default useStyles;
