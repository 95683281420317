import React from "react";
import { Box, BoxProps, Divider, Paper } from "@material-ui/core";

import { RightArrow } from "svgComponents";

import NavigationLink from "../NavigationLink";
import useStyles from "./styles";

interface ViewAllSectionProps extends Omit<BoxProps, "title"> {
    title: React.ReactNode;
    href?: string;
    label?: string;
    divider?: boolean;
    children?: React.ReactNode;
}

const PaperSection = ({ title, href, label = "View all", divider = true, children, ...props }: ViewAllSectionProps) => {
    const classes = useStyles();

    return (
        <Box {...props}>
            <Paper className={classes.paper}>
                <Box display="flex" flexDirection="column" justifyContent="space-between" height={1}>
                    <Box
                        display="flex" flexDirection="row" justifyContent="space-between"
                        px={{ xs: 3, md: 5 }} py={{ xs: 2, md: 3 }}
                    >
                        {title}
                        {href && (
                            <NavigationLink href={href}>
                                <Box display="flex" alignItems="center">
                                    <Box mr={1}>
                                        {label}
                                    </Box>
                                    <RightArrow/>
                                </Box>
                            </NavigationLink>
                        )}
                    </Box>
                    {divider && <Divider orientation="horizontal"/>}
                    {children}
                </Box>
            </Paper>
        </Box>
    );
}

export default React.memo(PaperSection);