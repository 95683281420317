import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { Box } from "@material-ui/core";

import { AdminRoutings } from "types/constants";
import { HeaderProps } from "types";

import SkillsManagement from "./Skills/Management";
import SkillsDistribution from "./Skills/Distribution";
import CompaniesHeader from "./CompaniesHeader";
import ResourceManagement from "./Resource/Management";
import ResourceDistribution from "./Resource/Distribution";
import AdminMembers from "./Users/Members";
import FcrSettings from "./FcrSettings";
import AdminTeams from "./Users/Teams";
import Companies from "./Companies";
import CompanyStructure from "./CompanyStructure";
import Company from "./Company";
import ProductImport from "./ProductImport";
import EmailConfiguration from "./EmailConfiguration";
import AdminCertification from "./Certification";

const AdminComponent = ({ isBarOpen, navBarOpen }: HeaderProps) => {
    const match = useRouteMatch(AdminRoutings.companies);

    return (
        <>
            {!match && <CompaniesHeader isBarOpen={isBarOpen} navBarOpen={navBarOpen} />}
            <Box px={8} py={5}>
                <Switch>
                    <Route path={AdminRoutings.fcrSettings}>
                        <FcrSettings />
                    </Route>
                    <Route path={AdminRoutings.skillsDistribution}>
                        <SkillsDistribution />
                    </Route>
                    <Route path={AdminRoutings.skillsManagement}>
                        <SkillsManagement />
                    </Route>
                    <Route path={AdminRoutings.certification}>
                        <AdminCertification />
                    </Route>
                    <Route path={AdminRoutings.resourceManagement}>
                        <ResourceManagement />
                    </Route>
                    <Route path={AdminRoutings.resourceDistribution}>
                        <ResourceDistribution />
                    </Route>
                    <Route path={AdminRoutings.members}>
                        <AdminMembers />
                    </Route>
                    <Route path={AdminRoutings.teams}>
                        <AdminTeams />
                    </Route>
                    <Route path={AdminRoutings.companies}>
                        <Companies isBarOpen={isBarOpen} navBarOpen={navBarOpen} />
                    </Route>
                    <Route path={AdminRoutings.companyStructure}>
                        <CompanyStructure />
                    </Route>
                    <Route path={AdminRoutings.company}>
                        <Company />
                    </Route>
                    <Route path={AdminRoutings.productImport}>
                        <ProductImport />
                    </Route>
                    <Route path={AdminRoutings.emailConfiguration}>
                        <EmailConfiguration />
                    </Route>
                    <Route>
                        <Redirect to={AdminRoutings.companies} />
                    </Route>
                </Switch>
            </Box>
        </>
    );
};

export default AdminComponent;
