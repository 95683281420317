import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    root: {
        background: "#fff",
        borderColor: "#eef0f3",
    },
    cards: {
        columnGap: theme.spacing(3),
        gridTemplateColumns: "repeat(1, 1fr)",
        rowGap: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
    },
}));
