import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    lvl: {
        flexWrap: "nowrap",
    },
    lvlArrow: {
        color: theme.colors.structure.fieldsOutline,
        transform: "translateY(-50%)",
        position: "absolute",
        top: "50%",
        left: "2px",
        "& svg": {
            display: "block",
        },
    },
    lvlLabel: {
        color: theme.colors.text.mediumGray,
        whiteSpace: "nowrap",
    },
}));

export default useStyles;