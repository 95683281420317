import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import NavigationLink from "components/NavigationLink";
import { Routings } from "types/constants";
import { BackArrow } from "svgComponents";
import { ConfigurationContext } from "context";
import { UserProfile } from "types";

interface BackToDashboardProps {
    userId?: UserProfile["id"] | null;
    state?: unknown;
}

function BackToDashboard({ userId, state }: BackToDashboardProps) {
    const { currentUser } = useContext(ConfigurationContext);
    const href = (!userId || currentUser?.id === userId) ? Routings.dashboard : `${Routings.teamProfile}/${userId}`;

    return (
        <Box my={4}>
            <NavigationLink href={{ pathname: href, state }}>
                <Box display="flex" alignItems="center">
                    <BackArrow />
                    <Box ml={2.5}>Back to Dashboard</Box>
                </Box>
            </NavigationLink>
        </Box>
    )
}

export default BackToDashboard;
