import React, { useMemo } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import orderBy from "lodash/orderBy";

import { CoachingPlanType, FcrCoachingSkillHistory } from "types";
import PaperSection from "components/PaperSection";
import CoachingPlan from "components/CoachingPlan";
import NoDataMessage from "components/NoDataMessage";
import useCoachingPlanModal from "hooks/useCoachingPlanModal";
import CoachingPlanModal from "components/CoachingPlanModal";
import { useRouteMatch } from "react-router";

import useStyles from "./styles";

interface CoachingPlansProps {
    isFcrCoachingSkillHistoryFetching?: boolean;
    fcrCoachingSkillHistory?: FcrCoachingSkillHistory[],
    onCoachingPlanChange: (newCoachingPlan: CoachingPlanType) => void,
}

export default function TeamProfileCoachingPlans({
    isFcrCoachingSkillHistoryFetching = false,
    fcrCoachingSkillHistory = [],
    onCoachingPlanChange,
}: CoachingPlansProps) {
    const classes = useStyles();
    const plans = useMemo(() => {
        const allPlans = fcrCoachingSkillHistory.flatMap(skillHistory => skillHistory.fcrCoachingPlans);
        return orderBy(allPlans, "dueDate", "desc").slice(0, 5)
    }, [fcrCoachingSkillHistory]);
    const { data, isShown, open, close } = useCoachingPlanModal();
    const { url } = useRouteMatch();
    const viewAllUrl = useMemo(() => {
        if (plans.length === 0) {
            return "";
        }
        const base = url === "/" ? "" : url;
        return `${base}/coaching-plans`
    }, [plans, url])

    const renderPlans = () => {
        if (isFcrCoachingSkillHistoryFetching) return (
            <Box display="flex" justifyContent="center" py={10}>
                <CircularProgress size={24} />
            </Box>
        );

        if (plans.length <= 0) return (
            <NoDataMessage pb={5} pt={7} />
        );

        return plans.map(plan => (
            <Box key={plan.id} className={classes.planCell} py={1}>
                <CoachingPlan coachingPlan={plan} onDetailOpen={open} mx={-3} px={3} py={2} />
            </Box>
        ));
    };

    return (
        <>
            <PaperSection
                title={<Title />}
                mb={{ xs: 3, sm: 6 }}
                href={viewAllUrl}
                label="View All"
            >
                <Box display="flex" flexDirection="column" px={5} py={1}>
                    {renderPlans()}
                </Box>
            </PaperSection>
            <CoachingPlanModal data={data} isShown={isShown} onClose={close} onCoachingPlanChange={onCoachingPlanChange} />
        </>
    )
}

function Title() {
    return (
        <Box fontWeight={600} mr={1.5}>
            <Typography variant="h2">Coaching Plans</Typography>
        </Box>
    )
}
