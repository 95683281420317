import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    back: {
        display: "inline-block",
    },
    navBarOpen: {
        cursor: "pointer",
    },
    header: {
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.colors.structure.rulesAndBorders}`,
    },
    date: {
        color: theme.palette.text.primary,
        borderRadius: "6px",
        background: theme.palette.info.main,
        cursor: "pointer",
    },
}));

export default useStyles;
