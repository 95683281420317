import React, { useMemo } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import orderBy from "lodash/orderBy";
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Theme,
    Typography,
    useMediaQuery,
} from "@material-ui/core";

import PaperSection from "components/PaperSection";
import NoDataMessage from "components/NoDataMessage";
import { FcrCoachingSkillHistory, TeamProfileRouteParams } from "types";

import TeamProfileSkill from "./TeamProfileSkill";
import useStyles from "./styles";

interface TeamProfileSkillsProps {
    fcrCoachingSkillHistory?: FcrCoachingSkillHistory[];
}

const TeamProfileSkills = ({ fcrCoachingSkillHistory }: TeamProfileSkillsProps) => {
    const { url } = useRouteMatch();
    const { userId } = useParams<TeamProfileRouteParams>();
    const classes = useStyles();
    const isNotPortable = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

    const itemsPerRow = useMemo(() => isNotPortable ? 2 : 1, [isNotPortable]);

    const skillsOrdered = useMemo(() =>
        orderBy(
            fcrCoachingSkillHistory,
            [({ averageScore }) => averageScore || "", "sortOrder"],
            ["desc", "asc"]
        ),
    [fcrCoachingSkillHistory]
    );

    return (
        <PaperSection title={
            <Box
                fontWeight={600}
                display="flex"
                alignItems={{ xs: "start", lg: "center" }}
                flexDirection={{ xs: "column", lg: "row" }}
            >
                <Box mr={1.5}>
                    <Typography variant="h2">{userId ? "Skills" : "My Skills"}</Typography>
                </Box>
                <Typography variant="caption">Last 12 months</Typography>
            </Box>
        } mb={{ xs: 3, sm: 6 }} href={`${url === "/" ? "" : url}/skills`} label="View Details">
            <Grid container>
                {skillsOrdered.map((skill, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={12} sm={6}>
                            <TeamProfileSkill skill={skill} />
                        </Grid>
                        {(index + 1) % itemsPerRow ? (
                            <Divider orientation="vertical" flexItem/>
                        ) : (
                            index !== skillsOrdered.length - 1 && (
                                <Divider orientation="horizontal" className={classes.horizontalDivider}/>
                            )
                        )}
                    </React.Fragment>
                ))}
                {!skillsOrdered.length && (
                    <Grid item xs={12}>
                        {fcrCoachingSkillHistory ? (
                            <NoDataMessage py={5} />
                        ) : (
                            <Box display="flex" justifyContent="center" py={10}>
                                <CircularProgress size={24}/>
                            </Box>
                        )}
                    </Grid>
                )}
            </Grid>
        </PaperSection>
    );
}

export default TeamProfileSkills;