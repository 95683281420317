import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@material-ui/core";
import queryString from "query-string";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

import { ClassesContext, ConfigurationContext } from "context";
import { buildEvalResultSaveDto, EvalResultsSaveOptionsEnum } from "helpers/certificationHelper";
import SubmitLoadingModal from "routes/CoachingReport/Wizard/WizardContent/Review/SubmitLoadingModal";
import { GetCertificationEvaluationResultInputs } from "services/certificationService";
import { ComponentTypeEvaluation, EvaluationResultStatusEnum, NULL_GUID } from "types/constants";
import { EvaluationItemResultSaveDto, FullEvaluationDto } from "types/Evaluation.dto";
import { EvaluationResultParams } from "types/interfaces";

import TraineeEvaluationForm from "./TraineeEvaluationForm";
import TraineeEvaluationFooter from "./TraineeEvaluationFormFooter";
import TraineeEvaluationBar from "./TraineeEvaluationBar";
import TraineeEvaluationHeader from "./TraineeEvaluationHeader";

import useStyles from "./styles";
interface TraineeEvaluationFormPageProps<ViewMode extends boolean | undefined> {
    isBarOpen: boolean;
    navBarOpen: () => void;
    viewMode?: ViewMode;
}

export default function TraineeEvaluationFormPage<ViewMode extends boolean | undefined = false>({
    isBarOpen,
    navBarOpen,
    viewMode,
}: TraineeEvaluationFormPageProps<ViewMode>) {
    const history = useHistory();
    const classes = useStyles();

    const { currentUser } = React.useContext(ConfigurationContext);
    const {
        fetchFullEvaluation,
        postEvaluationResults,
        isAwaitingPostEvaluationResultSave,
        isAwaitingPostEvaluationResultSubmit,
        showError,
        setShowError,
        resetEvaluationState,
    } = React.useContext(ClassesContext);

    const { classId, evaluationResultId } = useParams<EvaluationResultParams>();
    const { search } = useLocation();
    const queryParams = queryString.parse(search);
    const { cid, tid } = queryParams;

    const [evaluation, setEvaluation] = useState<FullEvaluationDto>();
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const fetchTraineeEvaluationParams: GetCertificationEvaluationResultInputs | null = useMemo(() => {
        if (currentUser) {
            return {
                evaluationResultId: evaluationResultId ?? NULL_GUID,
                evaluationConfigurationId: cid as string,
                classId,
                traineeId: tid as string,
                evaluatorId: currentUser.id,
            };
        }
        return null;
    }, [currentUser, evaluationResultId, classId, cid, tid]);

    useEffect(() => {
        if (!evaluation && fetchFullEvaluation && fetchTraineeEvaluationParams) {
            fetchFullEvaluation(fetchTraineeEvaluationParams, setEvaluation);
        }
    }, [fetchFullEvaluation, fetchTraineeEvaluationParams, evaluation, history, classId]);

    const evalResultId = useMemo(() => {
        const { id } = evaluation?.evaluationResult ?? {};
        const conditionalId = id && id !== NULL_GUID ? id : uuidv4();
        return conditionalId;
    }, [evaluation]);

    const evalResultsForState = useMemo(
        () => evaluation?.evaluationResult?.evaluationItemResults ?? [],
        [evaluation?.evaluationResult?.evaluationItemResults]
    );

    const evalItemConfigsForUi = useMemo(
        () => evaluation?.evaluationConfiguration?.evaluationItemConfigurations ?? [],
        [evaluation?.evaluationConfiguration?.evaluationItemConfigurations]
    );

    const [evalItemResultSaveDtos, setEvalItemResultSaveDtos] = useState<EvaluationItemResultSaveDto[]>(evalResultsForState);

    /** There are two ways of determining if the user has interacted with the data in the UI, either it's empty, or they emptied it which will be indicated by an entity with a zeroed out GUID */
    const useStateOverServerProvidedData = useMemo(
        () => evalItemResultSaveDtos.length > 0 || evalItemResultSaveDtos[0]?.id === NULL_GUID,
        [evalItemResultSaveDtos]
    );

    /** At any given point an evaluation might be saved or submitted, and we need to either use server provided data or the data that was modified by the user in the UI. */
    const currentDataToUse = useMemo(
        () =>
            useStateOverServerProvidedData
                ? evalItemResultSaveDtos.filter((r) => r.id !== NULL_GUID)
                : evaluation?.evaluationResult?.evaluationItemResults ?? [],
        [evalItemResultSaveDtos, evaluation?.evaluationResult?.evaluationItemResults, useStateOverServerProvidedData]
    );

    const validateData = useCallback(
        () =>
            evalItemConfigsForUi.every((configuration) =>
                configuration.isRequired && configuration.evaluationItemTypeId !== ComponentTypeEvaluation.staticText
                    ? currentDataToUse?.find((evalItemResult) => evalItemResult.evaluationItemConfigurationId === configuration.id)
                          ?.evaluationItemValueId
                    : true
            ),
        [currentDataToUse, evalItemConfigsForUi]
    );

    const handleSubmit = useCallback(async () => {
        if (postEvaluationResults && currentDataToUse && evaluation && currentUser) {
            const resultDto = buildEvalResultSaveDto(evaluation, currentDataToUse, evalResultId);
            await postEvaluationResults(
                {
                    ...resultDto,
                    submittedById: currentUser.id,
                    submittedDate: new Date().toISOString(),
                },
                EvalResultsSaveOptionsEnum.submit
            );

            if (fetchFullEvaluation && fetchTraineeEvaluationParams) {
                setEvaluation(undefined);
                fetchFullEvaluation(fetchTraineeEvaluationParams, setEvaluation);
                setIsSubmitted(true);
            }
        }
    }, [postEvaluationResults, currentDataToUse, evaluation, currentUser, evalResultId, fetchFullEvaluation, fetchTraineeEvaluationParams]);

    const handleSubmitClick = useCallback(() => {
        const result = validateData();
        if (result) {
            handleSubmit();
        } else {
            setShowError && setShowError(true);
        }
    }, [handleSubmit, setShowError, validateData]);

    const handleFinishLater = useCallback(() => {
        if (postEvaluationResults && currentDataToUse && evaluation) {
            const resultDto = buildEvalResultSaveDto(evaluation, currentDataToUse, evalResultId);
            postEvaluationResults(resultDto, EvalResultsSaveOptionsEnum.save);
        }
    }, [evalResultId, evaluation, postEvaluationResults, currentDataToUse]);

    useEffect(() => {
        if (resetEvaluationState) {
            return resetEvaluationState;
        }
    }, [resetEvaluationState]);

    const completed: boolean = useMemo(
        () => evaluation?.evaluationResult.evaluationResultStatusId === EvaluationResultStatusEnum.completed,
        [evaluation?.evaluationResult.evaluationResultStatusId]
    );

    if (!evaluation)
        return (
            <Box py={4} textAlign="center">
                <CircularProgress size={40} />
            </Box>
        );

    return (
        <>
            <TraineeEvaluationBar
                isBarOpen={isBarOpen}
                navBarOpen={navBarOpen}
                evaluation={evaluation}
                handleSave={handleFinishLater}
                isLoading={isAwaitingPostEvaluationResultSave ?? false}
                viewMode={!!viewMode}
                completed={completed}
            />
            {viewMode && <TraineeEvaluationHeader evaluation={evaluation} isSubmitted={isSubmitted} completed={completed} />}
            <Box className={clsx(classes.content, completed && classes.contentSubmitted)}>
                <Box mx={2} px={{ xs: 2, md: 8, lg: 13.5 }} pt={{ xs: 3, lg: 6.5 }} pb={{ xs: 1, lg: 3 }}>
                    <TraineeEvaluationForm
                        completed={completed}
                        disabled={completed}
                        isQuestionBased={evaluation.evaluationConfiguration.isQuestionBasedForm}
                        evaluationResultId={evalResultId}
                        showError={showError}
                        evaluationItemConfigurations={evalItemConfigsForUi}
                        evalItemResultSaveDtos={currentDataToUse}
                        setEvalItemResultSaveDtos={setEvalItemResultSaveDtos}
                    />
                </Box>
            </Box>
            {!completed && (
                <TraineeEvaluationFooter
                    validateData={validateData}
                    setShowError={setShowError}
                    submitClickHandler={() => handleSubmitClick()}
                    loading={isAwaitingPostEvaluationResultSubmit ?? false}
                />
            )}
            <SubmitLoadingModal open={isAwaitingPostEvaluationResultSubmit} message={"We’re submitting your form."} />
        </>
    );
}
