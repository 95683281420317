import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    avatarRoot: {
        position: "relative",
        "& .MuiInputBase-root": {
            paddingLeft: "52px",
        },
    },
    avatar: {
        zIndex: 1,
        position: "absolute",
        left: "14px",
        top: "50%",
        transform: "translateY(-50%)",
    },
}));

export default useStyles;