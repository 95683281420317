import React, { useMemo } from "react";
import clsx from "clsx";
import { useFormikContext, ErrorMessage } from "formik";
import { Box, Grid, Typography, Button, useTheme, MenuItem, FormHelperText, FormControlLabel } from "@material-ui/core";
import { FormInput, FormSelect, FormSwitcher } from "components/Form";
import { ACCEPTED_LOGO_FORMATS, US_STATES } from "helpers/companyHelper";
import { CompanyFormProps } from "types";
import { AuthenticationTypeEnum } from "types/constants";

import useStyles from "./styles";
import companyLogoPlaceholder from "./companyLogoPlaceholder.png";

function CompanyDetailsForm() {
    const classes = useStyles();
    const theme = useTheme();

    const { values, setFieldValue, errors, touched } = useFormikContext<CompanyFormProps>();

    const logoPreview = useMemo(() => {
        if (values.logoFile) return URL.createObjectURL(values.logoFile);
        return values.logo || companyLogoPlaceholder;
    }, [values.logo, values.logoFile]);

    const handleLogoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setFieldValue("logoFile", file);
        }
    };

    return (
        <Box p={4}>
            <Box mb={3} display="flex" alignItems="center" justifyContent="space-between">
                <Box fontWeight={600}>
                    <Typography variant="h2">Company Details</Typography>
                </Box>
                <Box mr="-16px">
                    <FormControlLabel
                        control={<FormSwitcher name="isActive" />}
                        label={<Typography>{values.isActive ? "Active" : "Inactive"}</Typography>}
                    />
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box pb={2}>
                        <FormInput name="name" label="Company name" required />
                    </Box>
                    <Box pb={2}>
                        <FormSelect
                            name="authenticationTypeId"
                            label="Authentication"
                            variant="outlined"
                            required
                        >
                            <MenuItem value={AuthenticationTypeEnum.default}>Default</MenuItem>
                            <MenuItem value={AuthenticationTypeEnum.openId}>OpenId</MenuItem>
                        </FormSelect>
                    </Box>
                    <Box pb={2}>
                        <FormInput name="address1" label="Address 1" />
                    </Box>
                    <Box pb={2}>
                        <FormInput name="address2" label="Address 2" />
                    </Box>
                    <Box pb={2}>
                        <FormInput name="city" label="City" />
                    </Box>
                    <Box pb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormSelect
                                    name="state"
                                    label="State"
                                    variant="outlined"
                                >
                                    <MenuItem value="" classes={{ root: classes.defaultMenuItem }} />
                                    {US_STATES.map((state) => (
                                        <MenuItem key={state} value={state}>{state}</MenuItem>
                                    ))}
                                </FormSelect>
                            </Grid>
                            <Grid item xs={6}>
                                <FormInput name="zip" label="Zip" />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box pb={2}>
                        <FormInput name="phoneNumber" label="Phone" />
                    </Box>
                    <Box>
                        <FormInput name="contactEmail" label="Contact Email" required />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label htmlFor="company-logo">
                        <Box display="none">
                            <input
                                id="company-logo"
                                accept={ACCEPTED_LOGO_FORMATS}
                                name="logoFile"
                                type="file"
                                onChange={handleLogoChange}
                            />
                        </Box>
                        <Box position="relative">
                            <Box
                                className={clsx(classes.logoPreviewBoxBase, {
                                    [classes.logoPreviewBoxPlaceholder]: logoPreview === companyLogoPlaceholder,
                                    [classes.logoPreviewBoxCompany]: logoPreview !== companyLogoPlaceholder,
                                    [classes.logoPreviewBoxError]: errors.logoFile && touched.logoFile,
                                })}
                            >
                                <img className={classes.logo} src={logoPreview} />
                            </Box>
                            <Box pb="50%" />
                        </Box>
                        <ErrorMessage name="logoFile">
                            {(message) => (<FormHelperText error>{message}</FormHelperText>)}
                        </ErrorMessage>
                        <Box mt={2}>
                            <Button
                                color="primary"
                                size="large"
                                variant="outlined"
                                fullWidth
                                component="span"
                            >
                                {logoPreview === companyLogoPlaceholder ? "Upload Logo" : "Update Logo"}
                            </Button>
                        </Box>
                    </label>
                    <Box mt={2}>
                        <Typography variant="caption">
                            Logo must be at least <Box component="span" color={theme.palette.text.primary} fontWeight={500}>140 x 70 pixels</Box> in
                            size on <Box component="span" color={theme.palette.text.primary} fontWeight={500}>white or transparent</Box> background.
                            Recommended 280 x 140 pixels to look crisp. The image should be
                            in <Box component="span" color={theme.palette.text.primary} fontWeight={500}>JPEG or PNG format</Box>.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CompanyDetailsForm;
