import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: theme.colors.structure.pageBackground,
        },
        arrowBtn: {
            minWidth: 40,
            width: 40,
            height: 40,
            boxShadow: "none",
            border: "none",
            display: "flex",

            "& span": {
                margin: "auto",
            },

            "& path": {
                fill: theme.palette.primary.main,
            },

            "&.Mui-disabled path": {
                fill: theme.colors.structure.fieldsOutline,
            },
        },
        column: {
            [theme.breakpoints.up("xs")]: {
                flexGrow: 0,
                maxWidth: "100%",
                flexBasis: "100%",
                marginBottom: "15px",
            },
            [theme.breakpoints.up("md")]: {
                flexGrow: 0,
                maxWidth: "100%",
                flexBasis: "100%",
                marginBottom: "15px",
            },
            [theme.breakpoints.up("lg")]: {
                flexGrow: 0,
                maxWidth: "45%",
                flexBasis: "45%",
            },
        },
        columnCenter: {
            flexGrow: 0,
            maxWidth: "10%",
            flexBasis: "10%",
        },
    })
);

export default useStyles;
