import React, { Dispatch, useMemo } from "react";
import clsx from "clsx";
import { Box } from "@material-ui/core";

import { TeamMember } from "svgComponents";
import { UserProfile } from "types";

import useStyles from "../styles";

interface MenuSubItemProps {
  user: UserProfile,
  selectedProfile?: string,
  onClick: Dispatch<string>
}

function MenuSubItem({ user, selectedProfile, onClick } : MenuSubItemProps) {
    const classes = useStyles();

    const userName = useMemo(() => `${user.firstName} ${user.lastName}`, [user]);

    const selected = user.id === selectedProfile;
    return (
        <Box
            display="flex" alignItems="center" px={2.5}
            className={clsx(classes.menuSubItem, selected && classes.selectedMenuSubItem)}
            onClick={() => onClick(user.id)}
            role="listitem"
        >
            <Box width={43}>
                <TeamMember style={{ paddingLeft: 5 }}/>
            </Box>
            <div>{userName}</div>
        </Box>
    );
}

export default MenuSubItem;
