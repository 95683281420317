import React, { useCallback, useMemo } from "react";

import { CoachingSession } from "types";
import { AppBar, Toolbar, Box, Button, CircularProgress } from "@material-ui/core";
import { LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW } from "types/constants";

import useStyles from "./styles";

interface TraineeEvaluationFooterProps {
    loading: boolean;
    submitClickHandler: () => void;
    validateData: () => boolean | undefined;
    setShowError: ((showError: boolean) => void) | undefined;
}

function TraineeEvaluationFooter({ loading, validateData, submitClickHandler, setShowError }: TraineeEvaluationFooterProps) {
    const classes = useStyles();

    return (
        <AppBar position="sticky" color="inherit" className={classes.footer}>
            <Toolbar disableGutters={true}>
                <Box
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    justifyContent="flex-end"
                    px={{ xs: 2, md: 8 }}
                    py={3}
                    className={classes.footerContent}
                    color="text.secondary">
                    <Box pl={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            onClick={submitClickHandler}
                            disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : "Submit"}
                        </Button>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default TraineeEvaluationFooter;
