import React, { useEffect, useState } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import authService from "services/authService";

import { SESSION_STORAGE_INITIAL_REQUEST, Routings } from "types/constants";

interface UnprotectedRouteAuthedProps extends RouteProps {
    isAuthed: boolean;
}

/** Protected rout exist to redirect to sign in page if user isn't authenticated. */
const ProtectedRoute: React.FC<UnprotectedRouteAuthedProps> = ({ isAuthed, ...props }: UnprotectedRouteAuthedProps) => {
    const initialRequestUrl = sessionStorage.getItem(SESSION_STORAGE_INITIAL_REQUEST);
    const pathNameIsForApp =
        window.location.pathname !== "" &&
        window.location.pathname !== "/" &&
        window.location.pathname.indexOf(Routings.signedout) === -1 &&
        window.location.pathname.indexOf(Routings.userNotFound) === -1 &&
        window.location.pathname.indexOf(Routings.signin) === -1;
    if (isAuthed && initialRequestUrl) {
        sessionStorage.removeItem(SESSION_STORAGE_INITIAL_REQUEST);
        return <Redirect to={{ pathname: initialRequestUrl }} />;
    }
    if (isAuthed) {
        return <Route {...props} />;
    }
    //Only save if route is for app and not auth layer
    if (pathNameIsForApp) {
        sessionStorage.setItem(SESSION_STORAGE_INITIAL_REQUEST, window.location?.pathname);
    }
    return <Redirect to={{ pathname: Routings.signin }} />;
};

export default ProtectedRoute;
