import { v4 as uuidv4 } from "uuid";
import orderBy from "lodash/orderBy";

const isBoolean = (val: unknown) => "boolean" === typeof val;

const getQuestionBasedFormValue = (evaluation: EvaluationConfiguration | undefined) =>
    evaluation?.isQuestionBasedForm ? QuestionBasedForm.Flagged : QuestionBasedForm.Aggregated;

import {
    EvaluationConfiguration,
    EvaluationFormProps,
    EvaluationItemConfiguration,
    EvaluationItemValue,
    IClassBaseForm,
    CertificationClass,
    CertificationUser,
    CertificationClassRoster,
    CertificationClassEvaluationAssignments,
    CertificationClassEvaluationAssignment,
    QuestionBasedForm,
} from "types/Certification";

import { EvaluationItemResultDto, EvaluationItemResultSaveDto, EvaluationResultSaveDto, FullEvaluationDto } from "types/Evaluation.dto";

import { ComponentTypeEvaluation, EvaluationResultStatusEnum, Routings, ClassesRouting } from "types/constants";
import { postEvaluationResultSaveDto } from "services/certificationService";

export const buildEvaluationItemValue = (data: Partial<EvaluationItemValue>): EvaluationItemValue => ({
    id: data.id ?? uuidv4(),
    name: data.name ?? "",
    value: data.value ?? 0,
    order: data.order ?? 0,
    evaluationItemConfigurationId: data.evaluationItemConfigurationId,
});

export const getEvaluationFormData = (
    evaluation: EvaluationConfiguration | undefined,
    companyId: string | undefined
): EvaluationFormProps => ({
    ...(evaluation ?? {}),
    id: evaluation?.id ?? uuidv4(),
    companyId: evaluation?.companyId ?? companyId,
    name: evaluation?.name ?? "",
    description: evaluation?.description ?? "",
    version: 1,
    versionName: "version1",
    isActive: evaluation?.isActive ?? true,
    isQuestionBasedForm: evaluation?.isQuestionBasedForm ?? true,
    questionBasedForm: isBoolean(evaluation?.isQuestionBasedForm) ? getQuestionBasedFormValue(evaluation) : QuestionBasedForm.Flagged,
});

/** Why does this function exist it make no sense that we get a config and return a config with a potential override of the config id?? */
export const getEvaluationItemFormData = (
    item: EvaluationItemConfiguration | null,
    evaluationConfigurationId: string
): EvaluationItemConfiguration => ({
    id: item?.id ?? uuidv4(),
    evaluationConfigurationId: item?.evaluationConfigurationId ?? evaluationConfigurationId,
    // Why would we default to a checkbox list if there is no item type if, why would there be no item type?
    evaluationItemTypeId: item?.evaluationItemTypeId ?? ComponentTypeEvaluation.checkboxList,
    elementHeader: item?.elementHeader ?? "",
    elementBody: item?.elementBody ?? "",
    evaluationItemValues: item?.evaluationItemValues
        ? orderBy(item?.evaluationItemValues, "order")
        : [buildEvaluationItemValue({ order: 1 }), buildEvaluationItemValue({ order: 2 })],
    isRequired: item?.isRequired ?? false,
});

export const TYPES_DATA: [ComponentTypeEvaluation, string, string][] = [
    [ComponentTypeEvaluation.checkboxList, "Multiselect Checkbox", "Users can select one to many options from values entered."],
    [ComponentTypeEvaluation.staticText, "Static Text", ""],
    [ComponentTypeEvaluation.radioButtonList, "Radiobuttons", "Users can select only one option from multiple values entered."],
    [ComponentTypeEvaluation.dropdownList, "Dropdown", "Users can select only one option from the values entered."],
    [ComponentTypeEvaluation.textEntry, "Text Entry", "User will enter a written answer."],
];

export const ERROR_MESSAGES: Record<
    ComponentTypeEvaluation.checkboxList | ComponentTypeEvaluation.radioButtonList | ComponentTypeEvaluation.dropdownList | string,
    string
> = {
    [ComponentTypeEvaluation.checkboxList]: "At least 2 values are needed for Multiselect Checkboxes.",
    [ComponentTypeEvaluation.radioButtonList]: "At least 2 values are needed for Radiobuttons.",
    [ComponentTypeEvaluation.dropdownList]: "At least 2 values are needed for Dropdown.",
    evaluationItemValues:
        "The Answer Format for this element requires that the Value be restricted to 0 or 1. Please correct the values accordingly.",
};

export const getClassBaseFormData = (
    certificationClass: CertificationClass | undefined,
    companyId: string,
    evaluationAssignments: CertificationClassEvaluationAssignment[]
): IClassBaseForm => ({
    id: certificationClass?.id ?? uuidv4(),
    companyId: certificationClass?.companyId ?? companyId,
    title: certificationClass?.title ?? "",
    description: certificationClass?.description ?? "",
    allowSelfEvaluation: certificationClass?.allowSelfEvaluation ?? false,
    isActive: certificationClass?.isActive ?? true,
    allowMultipleSubmissions: certificationClass?.allowMultipleSubmissions ?? false,
    classDate: certificationClass?.classDate ?? new Date().toISOString(),
    releaseDate: certificationClass?.releaseDate ?? new Date().toISOString(),
    evaluationForm: evaluationAssignments[0]?.evaluationConfigurationId ?? "",
    reportingUrl: certificationClass?.reportingUrl ?? "",
});

export const buildClassEvaluationAssignments = (
    classId: string,
    evaluationIds: Array<EvaluationConfiguration["id"]>,
    assign: boolean
): CertificationClassEvaluationAssignments => ({
    classId,
    evaluationConfigurationIds: evaluationIds,
    assign,
});

export const buildClassEvaluators = (classId: string, evaluators: CertificationUser[], assign: boolean): CertificationClassRoster => ({
    classId,
    userIds: evaluators.map(({ id }) => id),
    assign,
    areEvaluators: true,
});

export const buildClassTrainees = (classId: string, trainees: CertificationUser[], assign: boolean): CertificationClassRoster => ({
    classId,
    userIds: trainees.map(({ id }) => id),
    assign,
    areEvaluators: false,
});

export const buildEvaluationItemResultDto = (
    evaluationItemConfigurationId: string,
    evaluationItem: EvaluationItemValue,
    evaluationItemTypeId: ComponentTypeEvaluation,
    conditionalInput?: string
): EvaluationItemResultDto => ({
    evaluationItemConfigurationId,
    //TODO Verify that the result id and item value id should be client side generated
    evaluationItemValueId: evaluationItem.id,
    evaluationResultId: uuidv4(),
    id: evaluationItem.id ?? uuidv4(),
    input: evaluationItemTypeId === ComponentTypeEvaluation.textEntry ? conditionalInput ?? "" : null,
    evaluationItemTypeId,
});

export enum EvalResultsSaveOptionsEnum {
    save = "saved",
    submit = "submitted",
}

export const buildEvalResultSaveDto = (
    fullEvalDto: FullEvaluationDto,
    newEvalItemResultList: EvaluationItemResultSaveDto[],
    evalResultId: string
): EvaluationResultSaveDto => ({
    id: evalResultId,
    classId: fullEvalDto.class.id,
    evaluationConfigurationId: fullEvalDto.evaluationConfiguration.id,
    evaluatorId: fullEvalDto.evaluatorId,
    traineeId: fullEvalDto.traineeId,
    evaluationItemResults: newEvalItemResultList,
    //This will only be populated when submitted and remains null when saved
    submittedById: null,
    submittedDate: null,
});

export const convertEvalItemResultsToEvaluationItemResultSaveDto = (
    evaluationItemResults: EvaluationItemResultDto[]
): EvaluationItemResultSaveDto[] =>
    evaluationItemResults.map((item) => ({
        evaluationItemConfigurationId: item.evaluationItemConfigurationId,
        evaluationItemValueId: item.evaluationItemValueId,
        evaluationResultId: item.evaluationResultId,
        id: item.id,
        input: item.input,
        evaluationItemTypeId: item.evaluationItemTypeId,
    }));

export const getEvaluationStatusDateCaption = (status: EvaluationResultStatusEnum): string => {
    switch (status) {
        case EvaluationResultStatusEnum.new:
            return "";
        case EvaluationResultStatusEnum.inProgress:
            return "Modified";
        case EvaluationResultStatusEnum.completed:
            return "Submitted";
        default:
            return "";
    }
};

export const getClassStatusDateCaption = (status: EvaluationResultStatusEnum): string => {
    switch (status) {
        case EvaluationResultStatusEnum.new:
            return "";
        case EvaluationResultStatusEnum.inProgress:
            return "Edited";
        case EvaluationResultStatusEnum.completed:
            return "Completed";
        default:
            return "";
    }
};

interface EvaluationLinkProps {
    evaluationConfigurationId: string;
    evaluationResultId: string;
    id: string;
    traineeId: string;
    evaluationStatusId: string;
}

export const getEvaluationLink = ({
    evaluationConfigurationId,
    evaluationResultId,
    id,
    traineeId,
    evaluationStatusId,
}: EvaluationLinkProps): string => {
    const baseUrl = `${Routings.classes}/${id}/${ClassesRouting.evaluations}`;

    if (evaluationResultId) {
        const action = evaluationStatusId === EvaluationResultStatusEnum.completed ? "" : "edit";
        return `${baseUrl}/${evaluationResultId}/${action}`;
    } else {
        return `${baseUrl}/new?cid=${evaluationConfigurationId}&tid=${traineeId}`;
    }
};

export const getEvaluationUrl = async (
    { evaluationConfigurationId, evaluationResultId, id, traineeId }: EvaluationLinkProps,
    evaluatorId: string
): Promise<string> => {
    const baseUrl = `${Routings.classes}/${id}/${ClassesRouting.evaluations}`;

    if (evaluationResultId) {
        return `${baseUrl}/${evaluationResultId}`;
    } else {
        const initialEvaluationResult: EvaluationResultSaveDto = {
            id: uuidv4(),
            classId: id,
            evaluationConfigurationId,
            evaluatorId,
            traineeId,
            evaluationItemResults: [],
            submittedById: null,
            submittedDate: null,
        };

        await postEvaluationResultSaveDto(initialEvaluationResult);
        return `${baseUrl}/${initialEvaluationResult.id}`;
    }
};
