import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    reminder: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: theme.palette.text.secondary,
    },
    sendReminder: {
        background: theme.colors.structure.pageBackground,
        color: theme.palette.text.primary,
    },
    resourceActions: {
        fontSize: "14px",
    },
}));

export default useStyles;
