import * as React from "react";

function SvgWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={19}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#warning_svg__clip0)">
        <path
          d="M17.77 15.82H.63a.63.63 0 01-.54-.31.643.643 0 010-.63L8.66.29c.22-.38.85-.38 1.08 0l8.57 14.59c.11.19.11.43 0 .63a.63.63 0 01-.54.31zM1.72 14.57h14.96L9.2 1.84 1.72 14.57z"
          fill="#DD3328"
        />
        <path
          d="M9.2 5.16v4.66M9.2 11.31v2.08"
          stroke="#DD3328"
          strokeWidth={1.25}
          strokeMiterlimit={10}
        />
      </g>
      <defs>
        <clipPath id="warning_svg__clip0">
          <path fill="#fff" d="M0 0h18.39v15.82H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgWarning;
