import React from "react";
import Slider from "react-slick";
import { Company } from "types";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";

import useStyles from "../styles";

export interface CompaniesSliderProps {
    sliderRef: React.RefObject<Slider>;
    companiesList: Company[];
    currentCompanyIndex: number;
    handleCompany: (company: Company) => void;
}

const SLIDER_SETTINGS = {
    speed: 1000,
    centerMode: true,
    slidesToShow: 6,
    swipeToSlide: true,
    accessibility: true,
    arrows: false,
    infinite: true,
    dots: false,
    initialSlide: 3,
};

export const CompaniesSlider = ({
    sliderRef,
    currentCompanyIndex,
    companiesList,
    handleCompany,
}: CompaniesSliderProps) => {
    const classes = useStyles();

    return (
        <Slider ref={sliderRef} {...SLIDER_SETTINGS}>
            {companiesList.map((company, index) => (
                <Box
                    key={company.id}
                    className={clsx(
                        {
                            [classes.slideActive]: index === currentCompanyIndex,
                        },
                        classes.slide
                    )}
                >
                    <Button
                        className={classes.content}
                        onClick={() => handleCompany(company)}
                    >
                        {company.logo ? (
                            <img src={company.logo} alt={company.name} />
                        ) : (
                            company.name
                        )}
                    </Button>
                </Box>
            ))}
        </Slider>
    );
};
