import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Divider, Paper, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import NoDataMessage from "components/NoDataMessage";
import { getFcrResourcesForCategory } from "services/resourceServices";
import { FcrResource, FcrResourceCategory } from "types";

import ResourceListItem from "./ResourceListItem";
import EditResource from "./EditResource";

interface ResourceListProps {
    categories?: FcrResourceCategory[];
    selectedCategory?: FcrResourceCategory;
    isAddResourceOpen: boolean;
    toggleIsAddResourceOpen: () => void;
    currentPage: number;
    setCurrentPage: (currentPage: number) => void;
}

const RESOURCES_PER_PAGE = 10;

const ResourceList = ({ 
    categories, 
    selectedCategory, 
    isAddResourceOpen, 
    toggleIsAddResourceOpen,
    currentPage,
    setCurrentPage,
}: ResourceListProps) => {
    const [resources, setResources] = useState<FcrResource[]>();

    const pageCount = useMemo(
        () => resources && Math.ceil(resources.length / RESOURCES_PER_PAGE),
        [resources]
    );

    const shownResources = useMemo(() => {
        const startPosition = (currentPage - 1) * RESOURCES_PER_PAGE;
        return resources && resources.slice(startPosition, startPosition + RESOURCES_PER_PAGE);
    }, [resources, currentPage]);

    const onPageChange = useCallback((e, page) => {
        setCurrentPage(page);
    }, [setCurrentPage]);

    const onAddResource = useCallback((resource: FcrResource) => {
        selectedCategory && resource.resourceCategoryId === selectedCategory.id &&
            setResources((state) => [...state!, resource]);
    }, [selectedCategory]);

    const onDeleteResource = useCallback((resource: FcrResource) => {
        setResources((state) =>
            state!.filter((existingResource) => existingResource.id !== resource.id));
    }, []);

    const onEditResource = useCallback((resource: FcrResource) => {
        selectedCategory && resource.resourceCategoryId === selectedCategory.id ?
            setResources((state) =>
                state!.map((existingResource) =>
                    existingResource.id === resource.id ? resource : existingResource)) :
            onDeleteResource(resource);
    }, [onDeleteResource, selectedCategory]);

    useEffect(() => {
        const fetchResources = async () => {
            setResources(undefined);
            setResources(await getFcrResourcesForCategory(selectedCategory!.id));
        };

        selectedCategory && fetchResources();
    }, [selectedCategory]);

    return (
        <>
            <Paper>
                <Box py={2.5} px={4} color="text.secondary">
                    <Typography variant="body1">
                        {resources ? resources.length : 0} resource(s)
                    </Typography>
                </Box>
                <Divider/>
                <Box>
                    {!selectedCategory || (resources && !resources.length) ? (
                        <Box py={3}>
                            <NoDataMessage message="Nothing to show here"/>
                        </Box>
                    ) : (
                        <>
                            {resources && categories && shownResources ? shownResources.map((resource, index) => (
                                <React.Fragment key={resource.id}>
                                    <ResourceListItem
                                        categories={categories}
                                        resource={resource}
                                        onEditResource={onEditResource}
                                        onDeleteResource={onDeleteResource}
                                    />
                                    {index !== shownResources.length - 1 && (
                                        <Divider/>
                                    )}
                                </React.Fragment>
                            )) : (
                                <Box display="flex" flexDirection="column" alignItems="center" py={3}>
                                    <CircularProgress size={40}/>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
                {resources && resources?.length > RESOURCES_PER_PAGE && (
                    <>
                        <Divider/>
                        <Box display="flex" justifyContent="center" p={2}>
                            <Pagination
                                count={pageCount}
                                page={currentPage}
                                onChange={onPageChange}
                                shape="rounded"
                            />
                        </Box>
                    </>
                )}
            </Paper>
            {isAddResourceOpen && categories && (
                <EditResource
                    categories={categories}
                    isOpen={isAddResourceOpen}
                    onSubmit={onAddResource}
                    onClose={toggleIsAddResourceOpen}
                />
            )}
        </>
    );
};

export default ResourceList;