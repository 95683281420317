import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { TeamMember } from "svgComponents";

import { MenuSubItemProps } from "../index";
import useStyles from "../../styles";

interface MenuItemProps {
    title: string;
    enableColor: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement;
    href?: string;
    subMenu?: Array<MenuSubItemProps>;
    selectedMenuItem: string;
    disabled?: boolean;
    setSelectedMenuItem: (item: string) => void;
}

function MenuItem({
    title,
    enableColor,
    icon,
    href,
    subMenu,
    selectedMenuItem,
    disabled,
    setSelectedMenuItem,
}: MenuItemProps) {
    const classes = useStyles();
    const history = useHistory();
    const [selectedSubMenuItem, setSelectedSubMenuItem] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);

    const handleMenuItemClick = useCallback(
        (key: string, href?: string) => {
            setSelectedMenuItem(key);
            !!subMenu?.length && setOpen(true);
            href && history.push(href);
        },
        [history, setSelectedMenuItem, subMenu]
    );

    const handleSubMenuItemClick = useCallback(
        (key: string, href?: string) => {
            setSelectedSubMenuItem(key);
            href && history.push(href);
        },
        [history]
    );

    return (
        <Tooltip arrow placement="top" title={disabled ? "Company has been set to inactive" : ""}>
            <Box
                className={clsx(
                    classes.section,
                    selectedMenuItem === title && classes.selectedSection,
                    disabled && classes.disabledSection
                )}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    px={2.5}
                    py={selectedMenuItem !== title ? 2 : 2.5}
                    onClick={() => !disabled && handleMenuItemClick(title, href)}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        color={selectedMenuItem === title ? enableColor : "text.secondary"}
                        width={43}
                    >
                        {icon}
                    </Box>
                    <Typography>{title}</Typography>
                </Box>
                {open &&
                    selectedMenuItem === title &&
                    !!subMenu?.length &&
                    <Box pb={2.5}>
                        {subMenu.map(({ title, href }: MenuSubItemProps) => (
                            <Box
                                key={title}
                                display="flex"
                                alignItems="center"
                                px={2.5}
                                className={clsx(classes.menuSubItem, {
                                    [classes.selectedMenuSubItem]: selectedSubMenuItem === title,
                                })}
                                onClick={() => handleSubMenuItemClick(title, href)}
                                role="listitem"
                            >
                                <Box width={43}>
                                    <TeamMember style={{ paddingLeft: 5 }} />
                                </Box>
                                <div>{title}</div>
                            </Box>
                        ))}
                    </Box>
                }
            </Box>
        </Tooltip>
    );
}

export default MenuItem;
