import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => createStyles({
    listItem: {
        cursor: "pointer",
        paddingTop: 0,
        paddingBottom: 0,
    },
    selectedListItem: {
        background: "rgba(31, 172, 193, 0.1)",
        borderRadius: "6px",
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0,
    },
}));

export default useStyles;
