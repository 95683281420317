import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        boxSizing: "border-box",
    },
    content: {
        transition: ".3s",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "32px 40px",
        boxShadow: "0 0 5px 0 rgba(37, 58, 84, 0.06)",
        minHeight: "208px",
        borderRadius: "14px",
        background: theme.palette.primary.contrastText,
        textDecoration: "none",
        fontWeight: 600,
        height: "100%",
        textAlign: "center",

        "&:hover": {
            boxShadow: "0 0 5px 0 rgba(37, 58, 84, 0.5)",
            cursor: "pointer",
        },

        "& img": {
            maxWidth: "100%",
            maxHeight: "104px",
        },
    },
}));

export default useStyles;
