import React from "react";

import { cleanupRichText, sanitizeRichText } from "helpers/stringHelpers";

import useStyles from "./styles";

interface RichTextProps {
    text: string;
    removeTags?: boolean;
    charactersToShow?: number;
}

const RichText = ({ text, removeTags = false, charactersToShow }: RichTextProps) => {
    const classes = useStyles();

    return (
        <div className={classes.richText} dangerouslySetInnerHTML={{
            __html: removeTags ? cleanupRichText(text, charactersToShow) : sanitizeRichText(text),
        }}/>
    );
};

export default React.memo(RichText);