import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    coachingSessionItem: {
        cursor: "pointer",

        "&.selected, &.selected:hover": {
            backgroundColor: "#E2EFF2",
        },
        "&:hover": {
            backgroundColor: theme.colors.structure.pageBackground,
        },
    },
    dateBlock: {
        backgroundColor: "#EEF6F8",

        ".selected &": {
            backgroundColor: "#B3D8E1",
        },
    },
}));
