import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "& fieldset": {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.colors.structure.fieldsOutline,
            borderRadius: "6px",
        },
    },
    select: {
        padding: "12px 14px",
        backgroundColor: "transparent",
        minHeight: "22px",
        lineHeight: "22px",

        "&:focus": {
            backgroundColor: "transparent",
        },

        "& .MuiTypography-body1": {
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    },
    label: {
        padding: "0 32px 0 14px",
        transform: "translate(0, 13px) scale(1)",
    },
    shrink: {
        padding: "0 7px",
        transform: "translate(0, -7px) scale(0.75)",
        backgroundColor: theme.palette.common.white,
        zIndex: 1,
        marginLeft: "7px",
    },
}));
