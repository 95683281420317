import React, { useMemo, useContext } from "react";
import isNil from "lodash/isNil";
import { Box, Typography, BoxProps } from "@material-ui/core";
import { ConfigurationContext } from "context";

import { SizeEnum } from "types/constants";

import LinearProgressGradient from "../LinearProgressGradient";

interface CaptionScoreBarProps extends BoxProps {
    score?: number;
    label: string;
    size?: SizeEnum;
    minRating: number;
    maxRating: number;
    displayNumericalScore?: boolean;
    scoreDescription?: string;
}

const LabelScoreBar = ({ score, label, size = SizeEnum.small, minRating, displayNumericalScore = false, maxRating, scoreDescription, ...props }: CaptionScoreBarProps) => {
    const { fcrConfiguration } = useContext(ConfigurationContext);
    const formattedScore = useMemo(() => score && score.toFixed(1), [score]);
    const isScoreSet = useMemo(() => !isNil(score), [score]);
    const isDisplayNumericalScore = useMemo(() => displayNumericalScore || !(fcrConfiguration?.hideSkillRatingNumbers ?? true), [displayNumericalScore, fcrConfiguration?.hideSkillRatingNumbers]);
    const scoreNumber = isScoreSet ? formattedScore : "—";
    const scoreTitle = scoreDescription ? scoreDescription : "—";

    return (
        <Box display="flex" flexDirection="column" {...props}>
            {size === SizeEnum.small ? (
                <Box display="flex" alignItems="flex-end" justifyContent="space-between" mb={1}>
                    <Box width="65%">
                        <Typography variant="h3">
                            {label}
                        </Typography>
                    </Box>
                    {isDisplayNumericalScore && <Typography variant="h2">{scoreNumber}</Typography>}
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" mb={isDisplayNumericalScore ? 1 : 3}>
                    <Typography
                        variant={size === SizeEnum.medium || !isDisplayNumericalScore ? "subtitle2" : "h5"}
                        component="span"
                    >
                        {isDisplayNumericalScore ? scoreNumber : scoreTitle}
                    </Typography>
                    <Typography variant="caption">
                        {label}
                    </Typography>
                </Box>
            )}
            <LinearProgressGradient score={score} minRating={minRating} maxRating={maxRating}/>
        </Box>
    );
}

export default React.memo(LabelScoreBar);