import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { Box, Button, CircularProgress, Link, Typography, useTheme } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import InfiniteScroll from "react-infinite-scroll-component";

import NoDataMessage from "components/NoDataMessage";
import { getCoachingSessionsForAssignee, saveCoachingSession } from "services/coachingSessionServices";
import { ConfigurationContext } from "context";
import { CoachingSession, FcrConfiguration, UserProfile } from "types";
import { FcrStatusEnum } from "types/constants";
import usePrevious from "hooks/usePrevious";

import CoachingSessionUserSelect from "../ScheduleCoachingSession/CoachingSessionUserSelect";
import SelectCoachingSessionItem from "./SelectCoachingSessionItem";
import useStyles from "./styles";
import UserLabel from "../UserLabel";

interface SelectCoachingSessionProps {
    user?: UserProfile;
    usersBelow?: UserProfile[] | null;
    fcrConfiguration?: FcrConfiguration;
    toggleNewSession?: (e: React.SyntheticEvent) => void;
    onSuccess?: (coachingSession: CoachingSession) => void;
    onClose: () => void;
    selectedUserIdExternal: string | null;
    setSelectedUserIdExternal: (selectedUserId: string | null) => void;
}

const STEP_SIZE = 50;

const coachingReportsFilters = {
    userId: null,
    status: null,
    orderDescending: true,
    returnStarted: false,
    getOnlyUpcoming: false,
    getOnlyIncomplete: false,
    state: null,
    excludeAssigneesMarkedFinishLater: false,
}

const SelectCoachingSession = ({ 
    user, 
    usersBelow, 
    fcrConfiguration, 
    toggleNewSession, 
    onSuccess, 
    onClose, 
    selectedUserIdExternal,
    setSelectedUserIdExternal, 
}: SelectCoachingSessionProps) => {
    const theme = useTheme();
    const classes = useStyles();
    const { currentUser } = useContext(ConfigurationContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [coachingSessions, setCoachingSessions] = useState<CoachingSession[] | null>(null);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [shownCount, setShownCount] = useState(STEP_SIZE);
    const [selectedSession, setSelectedSession] = useState<CoachingSession>();
    const [selectedUserId, setSelectedUserId] = useState<string | null>(selectedUserIdExternal || user?.id || null);
    const previousSelectedUserId = usePrevious(selectedUserId);
    const shownSessions = useMemo(() => coachingSessions ? coachingSessions.slice(0, shownCount) : [], [coachingSessions, shownCount]);

    const fetchCoachingSessions = useCallback(async (pageNumber: number, selectedUserId: string) => {
        setIsLoading(true);

        getCoachingSessionsForAssignee(selectedUserId, pageNumber, coachingReportsFilters)
            .then((sessions) => {
                const newCoachingSessions = (coachingSessions?.length || (coachingSessions?.length && sessions.results.length)) ? [...coachingSessions, ...sessions.results] : sessions.results
                setCoachingSessions(newCoachingSessions);
                !coachingSessions?.length && setTotalAmount(sessions.totalFilteredAmount);
            })
            .finally(() => setIsLoading(false));
    }, [coachingSessions]);

    useEffect(() => {
        if (selectedUserId && selectedUserId === null && previousSelectedUserId === null && !coachingSessions) {
            fetchCoachingSessions(page, selectedUserId)
        }
    }, [coachingSessions, fetchCoachingSessions, page, previousSelectedUserId, selectedUserId]);

    useEffect(() => {
        if (selectedUserId && !coachingSessions) {
            fetchCoachingSessions(page, selectedUserId) 
            setSelectedUserIdExternal(selectedUserId)
        }
    }, [coachingSessions, fetchCoachingSessions, page, selectedUserId, setSelectedUserIdExternal]);

    useEffect(() => {
        if (selectedUserId && selectedUserId !== previousSelectedUserId && previousSelectedUserId !== null) {            
            setPage(1);
            setCoachingSessions(null)
            setSelectedUserId(selectedUserId)
            setSelectedUserIdExternal(selectedUserId)
        }
    }, [previousSelectedUserId, selectedUserId, setSelectedUserIdExternal]);

    const onShowMore = useCallback(() => {
        setPage(page + 1)
        setShownCount(shownCount + STEP_SIZE)
        selectedUserId && fetchCoachingSessions(page + 1, selectedUserId)
    }, [fetchCoachingSessions, page, selectedUserId, shownCount]);

    const handleSubmit = useCallback(() => {
        if (!selectedUserId || !selectedSession || !fcrConfiguration || isLoading) return;

        const updatedCoachingSession = {
            id: selectedSession.id,
            // TODO: many redundant fields here, remove after backend updates
            assigneeId: selectedUserId,
            coachId: selectedSession.coachId,
            coachingSessionTypeId: selectedSession.coachingSessionTypeId,
            isAllDayEvent: selectedSession.isAllDayEvent,
            scheduleStartDate: selectedSession.scheduleStartDate,
            scheduleEndDate: selectedSession.scheduleEndDate,
            createdById: selectedSession.createdById,
            modifiedById: currentUser!.id,
            fcrResult: {
                id: uuidv4(),
                fcrConfigurationId: fcrConfiguration.id,
                assigneeId: selectedUserId,
                coachId: selectedSession.coachId,
                companyId: currentUser!.companyId,
                createdById: selectedSession.createdById,   // TODO: redundant, remove later
                modifiedById: currentUser!.id,   // TODO: redundant, remove later
                fcrResultStatusId: FcrStatusEnum.new,
            },
        };

        setIsLoading(true);
        saveCoachingSession(updatedCoachingSession)
            .then((savedCoachingSession) => {
                onClose();
                if (onSuccess) onSuccess(savedCoachingSession);
            })
            .catch(() => setIsLoading(false));
    }, [selectedSession, fcrConfiguration, selectedUserId, currentUser, isLoading, onSuccess, onClose]);

    return (
        <>
            <Box display="flex" alignItems="center" py={3} px={4}>
                <Typography variant="h1" component="span">
                    New Coaching Report{user ? ":" : ""}
                </Typography>
                {user && <UserLabel user={user} />}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                py={3} px={4}
                bgcolor={theme.colors.structure.pageBackground}
            >
                {!user && (
                    <Box width={1}>
                        <CoachingSessionUserSelect
                            value={selectedUserId}
                            userProfiles={usersBelow ?? []}
                            loading={!usersBelow}
                            onChange={setSelectedUserId}
                        />
                    </Box>
                )}
                <Box
                    borderRadius={8}
                    bgcolor="common.white"
                    height="265px"
                    width={1}
                    mt={3.5}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="90%"
                        my={1.5} ml={1.5} mr={1} pr={0.5}
                        className={classes.scrollContainer}
                        id="scrollableDiv"
                    >
                        {!!coachingSessions?.length && (
                            <InfiniteScroll
                                scrollableTarget="scrollableDiv"
                                dataLength={shownSessions.length}
                                next={onShowMore}
                                hasMore={totalAmount > shownCount}
                                loader={<Box m="auto"><CircularProgress size={40} /></Box>}
                                hasChildren
                            >
                                {shownSessions.map((coachingSession, index) => (
                                    <Box key={coachingSession.id} mb={index !== coachingSessions.length - 1 ? 0.5 : 0}>
                                        <SelectCoachingSessionItem
                                            coachingSession={coachingSession}
                                            onSelect={setSelectedSession}
                                            isSelected={!!selectedSession && coachingSession.id === selectedSession.id}
                                        />
                                    </Box>
                                ))}
                            </InfiniteScroll>
                        )}
                        {!coachingSessions?.length && isLoading && (
                            <Box m="auto"><CircularProgress size={40} /></Box>
                        )}
                        {!coachingSessions?.length && !isLoading && (
                            <NoDataMessage
                                icon={null}
                                title="There are no scheduled sessions."
                                message="You can create a new session below."
                                m="auto"
                            />
                        )}
                    </Box>
                </Box>
                {toggleNewSession && (
                    <Box mt={3} mb={1}>
                        <Link href="#" onClick={toggleNewSession} underline="always">
                            Create new session
                        </Link>
                    </Box>
                )}
            </Box>
            <Box display="flex" justifyContent="flex-end" p={3}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disableElevation
                    disabled={!selectedUserId || !selectedSession || !fcrConfiguration || isLoading}
                    onClick={handleSubmit}
                >
                    Start Coaching Report
                </Button>
                <Box
                    ml={2}
                    color="text.secondary"
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        size="large"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default SelectCoachingSession;