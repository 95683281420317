import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    columnName: {
        [theme.breakpoints.down("md")]: {
            wordBreak: "break-all",
        },
    },
}));

export default useStyles;
