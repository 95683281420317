import React from "react";
import { getUserFullName } from "helpers/userProfileHelper";

import { CoachingSession, FcrResult, HeaderProps, EmailConfiguration } from "types";

import WizardHeader from "../Wizard/WizardHeader";
import CoachingReportViewerContent from "./CoachingReportViewerContent";

interface CoachingReportViewerProps extends HeaderProps {
    coachingSession: CoachingSession;
    fcrResult: FcrResult;
    emailConfiguration?: EmailConfiguration;
}

const CoachingReportViewer = ({ coachingSession, fcrResult, isBarOpen, navBarOpen, emailConfiguration }: CoachingReportViewerProps) => (
    <>
        <WizardHeader user={coachingSession.assignee} isBarOpen={isBarOpen} navBarOpen={navBarOpen} coachingSession={coachingSession} isViewing={emailConfiguration?.coachingReportCompleted} />
        <CoachingReportViewerContent
            createdBy={getUserFullName(coachingSession.createdBy)}
            coachedBy={getUserFullName(coachingSession.coach)}
            fcrResult={fcrResult}
            productSalesData={coachingSession.productSalesData}
        />
    </>
);

export default CoachingReportViewer;