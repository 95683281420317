import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { Box, ListItem, ListItemText } from "@material-ui/core";

import Checkbox from "components/Checkbox";
import { FcrResource } from "types";

import useStyles from "./styles";

interface ResourceItemProps {
    resource: FcrResource;
    checkedResources: FcrResource[];
    handleToggle: (resource: FcrResource) => void;
}

const ResourceItem = ({ resource, checkedResources, handleToggle }: ResourceItemProps) => {
    const classes = useStyles();

    const checked = useMemo(
        () => !!checkedResources.find(cr => cr.id === resource.id), 
        [checkedResources, resource.id]
    );
    
    const handleCheck = useCallback(() => {
        handleToggle(resource);
    }, [handleToggle, resource]);
    
    return (
        <ListItem
            className={clsx(
                classes.listItem,
                checked && classes.selectedListItem
            )}
            onClick={handleCheck}
        >
            <Checkbox edge="start" checked={checked}/>
            <ListItemText className={classes.listItemText}>
                <Box pl={1.5} fontWeight={checked ? 600 : 500}>
                    {resource.name}
                </Box>
            </ListItemText>
        </ListItem>
    );
};

export default ResourceItem;