import { FcrCoachingSkill, FcrCoachingSkillRating, FcrCoachingSkillRatingLeadingInfo, UserList } from "types";

import API from "./apiService";

export interface Assignment {
    fcrCoachingSkillId: string,
    userIds: string[],
    assign: boolean
}

export interface FcrCoachingSkillOrder {
    id: string,
    order: number,
}

// fcrCoachingSkill
export const getFcrCoachingSkillById = async (fcrCoachingSkillId: string): Promise<FcrCoachingSkill> => {
    const result = await API.get(`/fcrCoachingSkill/${fcrCoachingSkillId}`);
    return result.data;
};

export const updateOrdersOfFcrCoachingSkills = async (fcrCoachingSkillOrders: FcrCoachingSkillOrder[]): Promise<string> => {
    const result = await API.post("/fcrCoachingSkill/updateOrder", fcrCoachingSkillOrders);
    return result.data;
};

export const deleteFcrCoachingSkill = async (fcrCoachingSkillId: string): Promise<string> => {
    const result = await API.delete(`/fcrCoachingSkill/${fcrCoachingSkillId}`);
    return result.data;
};

export const updateFcrCoachingSkill = async (fcrCoachingSkill: FcrCoachingSkill): Promise<FcrCoachingSkill> => {
    const result = await API.post(`/fcrCoachingSkill/${fcrCoachingSkill.id}`, fcrCoachingSkill);
    return result.data;
};

export const getFcrCoachingSkillsForCompany = async (companyId: string): Promise<FcrCoachingSkill[]> => {
    const result = await API.get(`fcrCoachingSkill/getForCompany/${companyId}`);
    return result.data;
}

export const updateAssignments = async (assignment: Assignment): Promise<boolean> => {
    const result = await API.post("/fcrCoachingSkill/updateAssignments", assignment);
    return result.data;
};

export const getFcrCoachingSkillUsers = async (fcrCoachingSkillId: string, pageNumber: number, getAssigned = true, recordsAmount = 100): Promise<UserList> => {
    const result = await API.get(`fcrCoachingSkill/${fcrCoachingSkillId}/users?pageNumber=${pageNumber}&getAssigned=${getAssigned}&recordsAmount=${recordsAmount}`);
    return result.data;
}

// fcrCoachingSkillRating
export const updateFcrCoachingSkillRating = async (fcrCoachingSkillRating: FcrCoachingSkillRating): Promise<FcrCoachingSkillRating> => {
    const result = await API.post(`/fcrCoachingSkillRating/${fcrCoachingSkillRating.id}`, fcrCoachingSkillRating);
    return result.data;
};

export const deleteFcrCoachingSkillRating = async (fcrCoachingSkillRatingId: string): Promise<string> => {
    const result = await API.delete(`/fcrCoachingSkillRating/${fcrCoachingSkillRatingId}`);
    return result.data;
};

export const getFcrCoachingSkillRatingForFcrCoachingSkill = async (fcrCoachingSkillId: string): Promise<FcrCoachingSkillRating[]> => {
    const result = await API.get(`/fcrCoachingSkillRating/getForFcrCoachingSkill/${fcrCoachingSkillId}`);
    return result.data;
};

// fcrCoachingSkillRatingLeadingInfo
export const updateFcrCoachingSkillRatingLeadingInfo = async (fcrCoachingSkillRatingLeadingInfo: FcrCoachingSkillRatingLeadingInfo): Promise<FcrCoachingSkillRatingLeadingInfo> => {
    const result = await API.post(`/fcrCoachingSkillRatingLeadingInfo/${fcrCoachingSkillRatingLeadingInfo.id}`, fcrCoachingSkillRatingLeadingInfo);
    return result.data;
};