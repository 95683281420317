import React, { useState, useContext, useEffect, useMemo } from "react";
import { CompaniesContext } from "context";
import { Grid, Button } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "svgComponents";
import { CompanyLevel, UserProfile } from "types";
import unionBy from "lodash/unionBy";
import filter from "lodash/filter";
import map from "lodash/map";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";

import usePrevious from "hooks/usePrevious";
import { orderArrayUsers, getUsersByIds } from "helpers/usersHelpers";

import CustomList from "./CustomList";
import useStyles from "./styles";

interface TransferListProps {
    setCheckedUserIds?: (leftUserIds: string[] | null, rightUserIds: string[] | null) => void;
    fetchMembers?: (pageNumber: number, getAssigned: boolean) => void;
    potentialMembers?: UserProfile[];
    availableMembers: UserProfile[] | null;
    totalAmountAvailableMembers?: number;
    setTotalAmountAvailableMembers?: (totalAmount: number) => void;
    setAvailableMembers?: (users: UserProfile[]) => void;
    availableMembersPage?: number | null;
    setAvailableMembersPage: (page: number) => void;
    distributionMembers: UserProfile[] | null;
    totalAmountDistributionMembers?: number;
    setTotalAmountDistributionMembers?: (totalAmount: number) => void;
    setDistributionMembers?: (users: UserProfile[]) => void;
    distributionMemberPage?: number | null;
    setDistributionMemberPage: (page: number) => void;
    leftTitle?: string;
    rightTitle?: string;
    membersLevelIds?: Array<CompanyLevel["id"]>;
    companyLevels: CompanyLevel[];
    setUserIds: (ids: UserProfile[]) => void;
}

export default function TransferList({
    leftTitle,
    rightTitle,
    membersLevelIds = [],
    potentialMembers,
    companyLevels,
    setUserIds,
    fetchMembers,
    setCheckedUserIds,
    availableMembers,
    setAvailableMembers,
    totalAmountAvailableMembers,
    setTotalAmountAvailableMembers,
    setAvailableMembersPage,
    distributionMembers,
    setDistributionMembers,
    setDistributionMemberPage,
    totalAmountDistributionMembers,
    setTotalAmountDistributionMembers,
}: TransferListProps) {
    const classes = useStyles();
    const previousPotentialMembers = usePrevious(potentialMembers);
    const previousDistributionMembers = usePrevious(distributionMembers);
    const previousAvailableMembers = usePrevious(availableMembers);
    const { companyUsers } = useContext(CompaniesContext);
    const fallbackedPotentialUsers = potentialMembers ? potentialMembers : companyUsers;
    
    const allUsers = orderBy(unionBy(fallbackedPotentialUsers, distributionMembers, "id"), ["isActive", "firstName", "lastName"], ["desc"]);
    const filteredAllUsers = membersLevelIds.length ? filter(allUsers, (user) => membersLevelIds.includes(user.companyLevelId!)) : allUsers;
    const availableUsers = (potentialMembers && !potentialMembers.length) ? [] : filteredAllUsers;

    // left
    const filteredAvailableMembers = useMemo(() => availableMembers?.length ? orderArrayUsers(availableMembers) : [], [availableMembers])
    const [leftUsers, setLeftUsers] = useState<UserProfile[]>(filteredAvailableMembers ?? []);
    const [leftUsersIds, setLeftUsersIds] = useState<UserProfile["id"][]>(map(availableMembers ?? [], "id"));
    const [leftUsersCheckedIds, setLeftUsersCheckedIds] = useState<UserProfile["id"][]>([]);    
    
    useEffect(() => {
        if (previousAvailableMembers !== availableMembers && availableMembers) {
            setLeftUsers(availableMembers)
            setLeftUsersIds(map(availableMembers ?? [], "id"))
        }
    }, [availableMembers, previousAvailableMembers]);
    
    // right
    const filteredDistributionMember = useMemo(() => distributionMembers?.length ? orderArrayUsers(distributionMembers) : [], [distributionMembers])
    const [rightUsers, setRightUsers] = useState<UserProfile[]>(filteredDistributionMember ?? []);
    const [rightUsersIds, setRightUsersIds] = useState<UserProfile["id"][]>(map(distributionMembers ?? [], "id"));
    const [rightUsersCheckedIds, setRightUsersCheckedIds] = useState<UserProfile["id"][]>([]);

    useEffect(() => {
        if (previousDistributionMembers !== distributionMembers && distributionMembers) {
            setRightUsers(distributionMembers)
            setRightUsersIds(map(distributionMembers ?? [], "id"))
        }
    }, [distributionMembers, previousDistributionMembers]);

    const filteredLeftUsers = useMemo(() => 
        membersLevelIds.length ? filter(leftUsers, (user) => membersLevelIds.includes(user.companyLevelId!)) : leftUsers, 
    [leftUsers, membersLevelIds]
    );

    const handleMoveLeft = () => {
        const leftUsersCheckedIds = [...leftUsersIds, ...rightUsersCheckedIds];
        const leftUsersChecked = [...leftUsers, ...getUsersByIds(rightUsers || [], rightUsersCheckedIds)];
        const orderLeftUsersChecked = orderArrayUsers(leftUsersChecked);
        const rightUncheckedUsers = differenceWith(rightUsers, getUsersByIds(rightUsers || [], rightUsersCheckedIds))

        setCheckedUserIds && setCheckedUserIds(rightUsersCheckedIds, null);
        setAvailableMembers && setAvailableMembers(orderLeftUsersChecked);

        if (setTotalAmountAvailableMembers && totalAmountAvailableMembers) {
            setTotalAmountAvailableMembers(totalAmountAvailableMembers + rightUsersCheckedIds.length);
        }

        setDistributionMembers && setDistributionMembers(rightUncheckedUsers);

        if (setTotalAmountDistributionMembers && totalAmountDistributionMembers) {
            setTotalAmountDistributionMembers(totalAmountDistributionMembers - rightUsersCheckedIds.length);
        }

        setLeftUsersIds(leftUsersCheckedIds);
        setLeftUsers(orderLeftUsersChecked)
        setRightUsers(rightUncheckedUsers);
        setRightUsersCheckedIds([]);
        setUserIds(rightUncheckedUsers);
    };

    const handleMoveRight = () => {
        const rightUsersCheckedIds = [...rightUsersIds, ...leftUsersCheckedIds];
        const rightUsersChecked = [...rightUsers, ...getUsersByIds(leftUsers || [], leftUsersCheckedIds)];
        const orderCheckedRightUsers = orderArrayUsers(rightUsersChecked);
        const leftUncheckedUsers = differenceWith(leftUsers, getUsersByIds(leftUsers || [], leftUsersCheckedIds))

        setCheckedUserIds && setCheckedUserIds(null, leftUsersCheckedIds);
        setAvailableMembers && setAvailableMembers(leftUncheckedUsers)
        
        if (setTotalAmountAvailableMembers && totalAmountAvailableMembers) {
            setTotalAmountAvailableMembers(totalAmountAvailableMembers - leftUsersCheckedIds.length);
        }
        
        setDistributionMembers && setDistributionMembers(orderCheckedRightUsers)

        if (setTotalAmountDistributionMembers && totalAmountDistributionMembers) {
            setTotalAmountDistributionMembers((totalAmountDistributionMembers + leftUsersCheckedIds.length));
        }

        setRightUsersIds(rightUsersCheckedIds);
        setRightUsers(orderCheckedRightUsers)
        setLeftUsers(leftUncheckedUsers)
        setLeftUsersCheckedIds([]);
        setUserIds(orderCheckedRightUsers);
    };

    useEffect(() => {
        const previousPotentialMembersIds = map(previousPotentialMembers, "id");
        const previousDistributionMembersId = map(previousDistributionMembers, "id");
        const newPotentialMembersIds = map(potentialMembers, "id");
        const newDistributionMembersIds = map(distributionMembers, "id");

        const reset = () => {
            setLeftUsersCheckedIds([]);
            setRightUsersIds(newDistributionMembersIds);
            setRightUsersCheckedIds([]);
            setUserIds(distributionMembers ?? []);
        };

        if (
            !isEqual(previousPotentialMembersIds, newPotentialMembersIds) ||
            !isEqual(previousDistributionMembersId, newDistributionMembersIds)
        ) return reset();
    }, [previousPotentialMembers, previousDistributionMembers, potentialMembers, distributionMembers, setUserIds]);

    const fetchAvailableMembers = (pageNumber: number) => 
        fetchMembers && fetchMembers(pageNumber, false)

    const fetchDistributionMembers = (pageNumber: number) => 
        fetchMembers && fetchMembers(pageNumber, true)

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item className={classes.column}>
                <CustomList
                    type={"availableMembers"}
                    fetchMembers={fetchAvailableMembers}
                    title={leftTitle ?? "Available Members"}
                    usersIds={leftUsersIds}
                    users={filteredLeftUsers}
                    totalAmountUsers={totalAmountAvailableMembers ?? 0}
                    checked={leftUsersCheckedIds}
                    companyLevels={companyLevels}
                    setChecked={setLeftUsersCheckedIds}
                    resetChecked={setRightUsersCheckedIds}
                    setPaging={setAvailableMembersPage}
                />
            </Grid>
            <Grid item className={classes.columnCenter}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        className={classes.arrowBtn}
                        onClick={handleMoveRight}
                        disabled={leftUsersCheckedIds.length === 0}
                        aria-label="move selected right"
                    >
                        <ArrowRight />
                    </Button>
                    <Button
                        className={classes.arrowBtn}
                        onClick={handleMoveLeft}
                        disabled={rightUsersCheckedIds.length === 0 || availableUsers.length === 0}
                        aria-label="move selected left"
                    >
                        <ArrowLeft />
                    </Button>
                </Grid>
            </Grid>
            <Grid item className={classes.column}>
                <CustomList
                    type={"distributionMembers"}
                    fetchMembers={fetchDistributionMembers}
                    title={rightTitle ?? "Distribution Members"}
                    usersIds={rightUsersIds}
                    users={rightUsers}
                    totalAmountUsers={totalAmountDistributionMembers ?? 0}
                    checked={rightUsersCheckedIds}
                    companyLevels={companyLevels}
                    setChecked={setRightUsersCheckedIds}
                    resetChecked={setLeftUsersCheckedIds}
                    setPaging={setDistributionMemberPage}
                />
            </Grid>
        </Grid>
    );
}
