import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    barHigh: {
        backgroundImage: theme.colors.graph.high,
    },
    barMedium: {
        backgroundImage: theme.colors.graph.medium,
        backgroundSize: "80%",
    },
    barLow: {
        backgroundImage: theme.colors.graph.low,
        backgroundSize: "60%",
    },
    barLowest: {
        backgroundImage: theme.colors.graph.lowest,
        backgroundSize: "40%",
    },
}));
