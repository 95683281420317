import * as React from "react";

function SvgActionable(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Hide" fill="currentColor">
                    <path
                        d="M14.6464466,0.646446609 L15.3535534,1.35355339 L12.2776529,4.42955583 C13.5184353,5.258874 14.7592176,6.44902206 16,8 C13.3333333,11.3333333 10.6666667,13 8,13 C6.86908032,13 5.73816063,12.7002392 4.60724095,12.1007177 L1.35355339,15.3535534 L0.646446609,14.6464466 L14.6464466,0.646446609 Z M11.5545571,5.15100755 L10.4454251,6.26180468 C10.7946246,6.75220086 11,7.35211643 11,8 C11,9.65685425 9.65685425,11 8,11 C7.35211643,11 6.75220086,10.7946246 6.26180468,10.4454251 L5.35179712,11.3566594 C6.16816069,11.7492006 6.97379279,11.9605577 7.76824774,11.9949751 L8,12 C10.0829017,12 12.2433566,10.779897 14.4732666,8.26256642 L14.701,8 L14.4732666,7.73743358 C13.4865297,6.62351292 12.5133927,5.76359839 11.5545571,5.15100755 Z M8,3 C8.80699126,3 9.61398252,3.15263318 10.4209738,3.45789954 L9.63206646,4.24633273 C9.16155756,4.10523907 8.69475675,4.02508318 8.23175226,4.00502492 L8,4 C5.91709834,4 3.75664342,5.22010297 1.52673344,7.73743358 L1.298,8 L1.52673344,8.26256642 C2.2309183,9.05751604 2.92817683,9.72310123 3.61825402,10.2617508 L2.90636704,10.9730393 C1.93757803,10.2019994 0.968789014,9.21098627 0,8 C2.66666667,4.66666667 5.33333333,3 8,3 Z M9.72338083,6.98450789 L6.98489106,9.72360704 C7.2824531,9.89923291 7.62945884,10 8,10 C9.1045695,10 10,9.1045695 10,8 C10,7.62929978 9.89914638,7.28215519 9.72338083,6.98450789 Z M8,5 C8.26880584,5 8.52935444,5.03535346 8.77724974,5.10166433 L7.87442947,6.00387801 C6.82835758,6.06868322 6,6.93760497 6,8 L6.004,7.87444661 L5.10166433,8.77724974 C5.04995075,8.58392468 5.01706518,8.382904 5.00509269,8.17627279 L5,8 C5,6.34314575 6.34314575,5 8,5 Z"
                        id="Combined-Shape"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default SvgActionable;
