import React, { useContext } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import PaperSection from "components/PaperSection";

import { ConfigurationContext } from "context";

import { EvaluatorClassesViewAllRouting } from "types/constants";

import useStyles from "./styles";
import TeamMemberCard from "./TeamMemberCard";

function MyTeam() {
    const { currentTeam, isConfigurationFetched } = useContext(ConfigurationContext);
    const classes = useStyles();
    return (
        <PaperSection
            px={6}
            title={
                <Box mr={1.5} display="flex" alignItems="center">
                    <Typography className={classes.title} variant="h2">
                        My Team
                    </Typography>
                </Box>
            }
            mb={3}>
            {isConfigurationFetched ? (
                <Box display="grid" p={2} className={classes.memberList}>
                    {currentTeam.map((member) => (
                        <Link className={classes.link} key={member.id} to={`${EvaluatorClassesViewAllRouting.traineeClasses}/${member.id}`}>
                            <TeamMemberCard member={member} />
                        </Link>
                    ))}
                </Box>
            ) : (
                <Box p={4} textAlign="center">
                    <CircularProgress size={40} />
                </Box>
            )}
        </PaperSection>
    );
}

export default React.memo(MyTeam);
