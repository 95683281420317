import React from "react";
import { SwitchProps } from "@material-ui/core";
import Switcher from "components/Switcher";
import { useField } from "formik";

function FormSwitcher({ name = "", ...props }: SwitchProps) {
    const [field] = useField({
        name,
        type: props?.type,
    });

    return <Switcher checked={field.value} {...field} {...props} />;
}

export default FormSwitcher;
