import React, { useCallback, useContext, useMemo } from "react";
import { Box, FormHelperText } from "@material-ui/core";
import PaperCard from "components/PaperCard";
import LevelFilter from "components/LevelFilter";
import { CompanyLevel, MemberFormProps, UserProfile } from "types";
import { buildLevelHierarchyObjectsToLevel, buildLevelPath, LevelHierarchy } from "helpers/companyHelper";
import { useFormikContext } from "formik";
import { CompaniesContext } from "context";

interface TeamAssignmentProps {
    member?: UserProfile;
    leaderTeamName?: string;
    onEvaluatorChange: () => void;
}

export default function TeamAssignment({ member, leaderTeamName, onEvaluatorChange }: TeamAssignmentProps) {
    const { companyLevels } = useContext(CompaniesContext);
    const { values, touched, setFieldValue, setFieldTouched } = useFormikContext<MemberFormProps>();

    const handleLevelChange = useCallback(
        (companyLevelId: string | null) => {
            setFieldValue("companyLevelId", companyLevelId);
            setFieldTouched("companyLevelId");
        },
        [setFieldValue, setFieldTouched]
    );

    const levelHierarchy: LevelHierarchy<CompanyLevel | null> = useMemo(
        () => (values.companyLevelId ? buildLevelHierarchyObjectsToLevel(values.companyLevelId, companyLevels) : {}),
        [values.companyLevelId, companyLevels]
    );

    const caption =
        companyLevels.length > 0 ? (
            <>
                Assign user to a level hierarchy node by selecting from the lists below. The second-to-last dropdown will be the hierarchy
                in which they will be eligible for a Team assignment and the last dropdown will be the hierarchy in which they will be
                eligible for a Team Leader selection.
                {leaderTeamName && (
                    <Box mt={3}>
                        If you change the team hierarchy for this user, they will be removed as a Leader from their team and it will affect
                        the team's ability to be coached until a new Team Leader is assigned to "{leaderTeamName}" within the Teams
                        Management page.
                    </Box>
                )}
                {touched.companyLevelId && member && member.companyLevelId !== values.companyLevelId && (
                    <Box mt={3}>
                        {levelHierarchy[1]?.id !== values.companyLevelId && (
                            <FormHelperText error>
                                This user will be assigned to the{" "}
                                {buildLevelPath(levelHierarchy, (companyLevel) => companyLevel?.id !== values.companyLevelId)}
                            </FormHelperText>
                        )}
                        {levelHierarchy[5]?.id !== values.companyLevelId && (
                            <FormHelperText error>
                                This user will be eligible as a Team Lead for {buildLevelPath(levelHierarchy)}
                            </FormHelperText>
                        )}
                    </Box>
                )}
            </>
        ) : (
            <>
                Team Assignment is unavailable until the Company's Level Labels and Organizational Structure have been configured under the
                Company section.
            </>
        );

    return (
        <PaperCard>
            <LevelFilter
                title="Team Assignment"
                caption={caption}
                submitOnChange
                companyLevels={companyLevels}
                initialLevelId={member?.companyLevelId ?? null}
                onFilter={handleLevelChange}
                onLevelChangeCallback={onEvaluatorChange}
            />
        </PaperCard>
    );
}
