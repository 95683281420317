import React, { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, CircularProgress } from "@material-ui/core";
import groupBy from "lodash/groupBy";

import { getAllMessages } from "services/messageServices";
import { MessageType, UserProfile, MessageList } from "types";

import BackToProfile from "../../BackToProfile";
import MessageHistoryThread from "./MessageHistoryThread";

interface MessageHistoryProps {
    user: UserProfile;
}

const THREADS_TO_SHOW = 3;

const MessageHistory = ({ user }: MessageHistoryProps) => {
    const [messages, setMessages] = useState<MessageType[]>();
    const [threadCountToShow, setThreadCountToShow] = useState(THREADS_TO_SHOW);

    const threads = useMemo(
        () => Object.entries(groupBy(messages, "threadId")),
        [messages]
    );

    const threadsToShow = useMemo(
        () => threads.slice(0, threadCountToShow),
        [threadCountToShow, threads]
    );

    const onShowMore = useCallback(() => {
        setThreadCountToShow((state) => state + THREADS_TO_SHOW);
    }, []);

    useEffect(() => {
        setMessages(undefined);
        getAllMessages(user.id, 0).then((messageList: MessageList) => setMessages(messageList.results))
    }, [user.id]);

    return (
        <>
            <BackToProfile userId={user.id} />

            {messages ? (
                <InfiniteScroll
                    scrollableTarget="app"
                    dataLength={threadsToShow.length}
                    next={onShowMore}
                    hasMore={threads.length > threadCountToShow}
                    loader={<></>}
                    hasChildren
                >
                    {threadsToShow.map(([threadId, thread]) => (
                        <Box key={threadId} mb={4}>
                            <MessageHistoryThread thread={thread} />
                        </Box>
                    ))}
                </InfiniteScroll>
            ) : (
                <Box display="flex" justifyContent="center" py={10}>
                    <CircularProgress size={24} />
                </Box>
            )}
        </>
    )
};

export default MessageHistory;
