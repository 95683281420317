import React from "react";
import * as Msal from "@azure/msal-browser"

import {
    AuthProviderEnum,
    LOCAL_STORAGE_AUTH_SESSION_EXPIRES_AT,
    LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW,
    LOCAL_STORAGE_LAST_SELECTED_COMPANY,
    LOCAL_STORAGE_EMAIL,
    LOCAL_STORAGE_SCOPE,
    Routings,
    SESSION_STORAGE_INITIAL_REQUEST,
    AUTH_STATE_CHANGE_ACTION_KEY,
    CrossBrowserStateIndicator,
} from "types/constants";

/* eslint-disable */

/** We need a container for auth state, so we're using an IIFE to create a singleton, with private internals. */
const authService = function () {

    let msalAuthClient: Msal.IPublicClientApplication | null = null
    let config: Msal.Configuration | null = null
    let accessToken: string | null = null
    let authCb: React.Dispatch<React.SetStateAction<boolean>> | null = null
    let urlHasStateAtTokenRedirect = false
    let activeAccountResult: Msal.AccountInfo | null = null

    const signedOutPath = `${window.location.origin}${Routings.signedout}`
    const signInPath = `${window.location.origin}${Routings.signin}`

    const isAuthenticated = (): boolean => !!accessToken

    const getEnvVarOrWarn = (envVarName: string) => {
        const envVar = process.env[envVarName] ?? ""
        if (!envVar) {
            console.warn(`AUTH SERVICE: Env var ${envVarName} not defined. Required for Auth!`)
        }
        return envVar
    }

    // TODO Once this type of logging for missing env vars is necessary elsewhere, 
    // move it to a common place, call it app config service, or something like that.
    const getClientId = () => getEnvVarOrWarn("REACT_APP_CLIENT_ID")
    const getTenantId = () => getEnvVarOrWarn("REACT_APP_TENANT_ID")
    const getPolicy = () => getEnvVarOrWarn("REACT_APP_POLICY")
    const getScope = () => getEnvVarOrWarn("REACT_APP_SCOPE")
    const getB2cAuthorityUrl = () => getEnvVarOrWarn("REACT_APP_KNOWN_AUTHORITY")
    const getTenantAuthorityOnMs = () => getEnvVarOrWarn("REACT_APP_AUTHORITY_ON_MS")

    const buildAcquireTokenSilentRequest = (account: Msal.AccountInfo): Msal.SilentRequest => {
        return {
            scopes: [`https://${getScope()}`],
            account
        }
    }

    const throwAuthClientNotDefined = () => {
        console.warn("authClient not defined, dev error!")
    }

    /** Pulls all relevant details from process.env and creates an msal public client app instance. Will add warnings to console if anything required is missing. */
    const initialize = async (cb: React.Dispatch<React.SetStateAction<boolean>>): Promise<void> => {
        //Handle auth state across browser tabs
        window.addEventListener('storage', handleStorageAuthStateChange)
        //Save off the auth flow callback to notify external react context when auth flow is complete
        authCb = cb
        //Save if url has state param
        urlHasStateAtTokenRedirect = window.location.href.includes(`${Routings.signin}#state=`)
        const logInAuthority = `https://${getB2cAuthorityUrl()}/${getTenantAuthorityOnMs()}/${getPolicy()}`
        config = {
            auth: {
                clientId: getClientId(),
                authority: logInAuthority,
                knownAuthorities: [getB2cAuthorityUrl()],
                redirectUri: signInPath,
                postLogoutRedirectUri: signedOutPath,
            },
            cache: {
                cacheLocation: Msal.BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: false,

            }
        }
        msalAuthClient = new Msal.PublicClientApplication(config);
        const userOnPageThatDoesntRequireLoginRedirect = window.location.pathname.indexOf(Routings.signedout) > -1 ||
            window.location.pathname.indexOf(Routings.userNotFound) > -1
        if (!userOnPageThatDoesntRequireLoginRedirect) {
            await setUpMsalPromise();
        }

    };

    const pendingRequestInProgress = (): boolean => {
        return urlHasStateAtTokenRedirect
    }

    const loginRedirect = async () => {
        if (!msalAuthClient) {
            throwAuthClientNotDefined()
            return
        }
        //Pass offline access so that msal caches the open id well known config
        //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2291
        await msalAuthClient.loginRedirect();
        try {
        } catch (err) {
            console.warn("msal login redirect exception caught")
            console.warn(err)
        }
    };

    const clearAuthLocalStorage = () => {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_SESSION_EXPIRES_AT);
        localStorage.removeItem(LOCAL_STORAGE_IS_TEAM_MEMBER_VIEW);
        localStorage.removeItem(LOCAL_STORAGE_LAST_SELECTED_COMPANY);
        localStorage.removeItem(LOCAL_STORAGE_EMAIL);
        localStorage.removeItem(LOCAL_STORAGE_SCOPE);

        sessionStorage.removeItem(SESSION_STORAGE_INITIAL_REQUEST)
    }

    const logout = async (redirectUrl?: string) => {
        if (!msalAuthClient) {
            throwAuthClientNotDefined()
            return
        }
        //Reset token ref, url indicator of pending request, and active account
        accessToken = null
        urlHasStateAtTokenRedirect = false
        activeAccountResult = null

        //Clear local storage
        clearAuthLocalStorage()

        //Indicate new cross browser tab auth state
        localStorage.setItem(AUTH_STATE_CHANGE_ACTION_KEY, CrossBrowserStateIndicator.LoggingOut)

        //Log out of identity provider
        if (redirectUrl) {
            msalAuthClient.logoutRedirect({ postLogoutRedirectUri: redirectUrl })
        } else {
            msalAuthClient.logoutRedirect()
        }
    }

    const handleRedirectPromiseSuccess = async (tokenResponse: Msal.AuthenticationResult | null): Promise<void> => {
        if (!tokenResponse) {
            const accounts = msalAuthClient?.getAllAccounts() ?? []
            if (accounts.length === 0) return

            msalAuthClient?.setActiveAccount(accounts[0])
            await getToken()
            return
        }
        if (tokenResponse?.accessToken) {
            handleToken(tokenResponse.accessToken)
            return
        }
        if (tokenResponse?.account) {
            msalAuthClient?.setActiveAccount(tokenResponse.account)
            await getToken()
            return
        }

    }
    const handleToken = (token: string): void => {
        if (token) {
            accessToken = token
            authCb && authCb(true)
        }
    }
    const handleRedirectPromiseFailure = async (err: unknown) => {
        console.warn('handle redirect promise failure')
        console.warn(err)
        // window.location.assign(Routings.userNotFound)
        accessToken = null
        urlHasStateAtTokenRedirect = false
        activeAccountResult = null

        //Clear local storage
        clearAuthLocalStorage()

        //Indicate new cross browser tab auth state
        localStorage.setItem(AUTH_STATE_CHANGE_ACTION_KEY, CrossBrowserStateIndicator.LoggingOut)
    }

    /** This is the main set up for msal redirect, it assumes that all relevant details were in process.env at the time of app instantiation */
    const setUpMsalPromise = async () => {
        if (!msalAuthClient) {
            throwAuthClientNotDefined()
            return
        }
        await msalAuthClient.handleRedirectPromise()
            .then(handleRedirectPromiseSuccess)
            .catch(handleRedirectPromiseFailure)
        activeAccountResult = msalAuthClient?.getAllAccounts()[0]
        if (!pendingRequestInProgress() && !activeAccountResult) {
            loginRedirect()
            return
        }
    }

    const handleAuthenticationResultSuccess = (res: Msal.AuthenticationResult): void => {
        localStorage.setItem(AUTH_STATE_CHANGE_ACTION_KEY, CrossBrowserStateIndicator.FullyAuthenticated)
        handleToken(res.accessToken)
    }
    const handleAuthenticationResultFail = (res: Msal.AuthenticationResult): void => {
        console.log('caught error res in acquire token silent')
        console.log(res)
        loginRedirect()
    }
    const getToken = () => {
        if (!msalAuthClient) {
            throwAuthClientNotDefined()
            return
        }
        const account = msalAuthClient.getAllAccounts()
        if (account) {
            const request = buildAcquireTokenSilentRequest(account[0])
            return msalAuthClient.acquireTokenSilent(request)
                .then(handleAuthenticationResultSuccess).catch(handleAuthenticationResultFail)
        }

    }

    /** Attach the token to all api network calls. */
    const getAuthHeaders = () => {
        if (!msalAuthClient) {
            throwAuthClientNotDefined()
            return
        }
        return {
            AuthorizationProvider: AuthProviderEnum.B2C,
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined
        };
    };

    /** Handle cross sync tab auth session preservation. */
    const handleStorageAuthStateChange = (
        //https://developer.mozilla.org/en-US/docs/Web/API/StorageEvent
        storageEvent: StorageEvent
    ): boolean | undefined => {
        if (storageEvent.key === AUTH_STATE_CHANGE_ACTION_KEY) {
            const { newValue } = storageEvent
            const userIsFullyAuthed = isAuthenticated()
            if (newValue === CrossBrowserStateIndicator.LoggingOut && userIsFullyAuthed) {
                logout()
                return true
            } else if (newValue === CrossBrowserStateIndicator.FullyAuthenticated && !userIsFullyAuthed) {
                window.location.assign("/")
                return true
            }
            return false
        }
        return undefined
    }
    return {
        getAuthHeaders, isAuthenticated, initialize, logout, loginRedirect
    }
}()

export default authService