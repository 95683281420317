import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
}));

export default useStyles;
