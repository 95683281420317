import React, { useCallback, useContext, useMemo, useState } from "react";
import { ErrorMessage, Form, FormikHelpers, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
    useTheme,
    Box,
    Button,
    Dialog,
    FormControlLabel,
    MenuItem,
    Radio,
    Typography,
    FormHelperText,
} from "@material-ui/core";

import FileUploader from "components/FileUploader";
import {
    FormInput,
    FormMultilineInput,
    FormRadioGroup,
    FormSelect,
    FormSwitcher,
} from "components/Form";
import LoadingButton from "components/LoadingButton";
import Checkmark from "svgComponents/Checkmark";
import DeleteIcon from "svgComponents/DeleteIcon";
import Link from "svgComponents/Link";
import { createOrUpdateFcrResource } from "services/resourceServices";
import { FcrResource, FcrResourceCategory } from "types";
import { ResourceTypeEnum } from "types/constants";
import { CompaniesContext } from "context";
import { getResourceFormData } from "helpers/resourceHelper";

import useStyles from "./styles";

interface EditResourceModalProps {
    resource?: FcrResource;
    categories: FcrResourceCategory[];
    isOpen: boolean;
    onSubmit: (resource: FcrResource) => void;
    onClose: () => void;
    onDelete?: () => void;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    resourceCategoryId: Yup.string().required("Required"),
    url: Yup.string().when("type", {
        is: ResourceTypeEnum.link,
        then: (schema) => schema.required("Required"),

    }),
    documentBytes: Yup.string().when(["type", "documentFilename"], {
        is: (type: ResourceTypeEnum, documentFilename: string) => type === ResourceTypeEnum.document && !documentFilename,
        then: (schema) => schema.required("Required"),
    }),
});

const EditResource = ({ resource, categories, isOpen, onSubmit, onClose, onDelete }: EditResourceModalProps) => {
    const { currentUser, currentCompany } = useContext(CompaniesContext);

    const theme = useTheme();
    const classes = useStyles();

    const [selectedFile, setSelectedFile] = useState<File>();

    const handleSubmit = useCallback((values: FcrResource, { setSubmitting }: FormikHelpers<FcrResource>) => {
        createOrUpdateFcrResource(values)
            .then(onSubmit)
            .then(onClose)
            .catch(() => setSubmitting(false));
    }, [onSubmit, onClose]);

    const handleDelete = useCallback(() => {
        onDelete && onDelete();
        onClose();
    }, [onClose, onDelete]);

    const initialValues = useMemo(
        () => getResourceFormData(
            resource ?? null,
            currentUser?.id ?? "",
            currentCompany?.id ?? "",
            categories[0] ? categories[0].id : ""
        ),
        [resource, currentUser, currentCompany, categories]
    );

    const formik = useFormik<FcrResource>({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    const { values, isSubmitting, setFieldValue } = formik;

    const handleFileChange = useCallback((file, fileBytes) => {
        setFieldValue("documentBytes", fileBytes ?? "");
        setFieldValue("documentFilename", file?.name ?? "");
        setFieldValue("contentType", file?.type ?? "");
        setSelectedFile(file);
    }, [setFieldValue]);

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <FormikProvider value={formik}>
                <Form>
                    <Box p={4}>
                        <Typography variant="h1" component="span">
                            {resource ? "Edit Resource Details" : "Create New Resource"}
                        </Typography>
                    </Box>
                    <Box p={4} bgcolor={theme.colors.structure.pageBackground}>
                        <Box className={classes.input} mb={2.5}>
                            <FormSelect name="resourceCategoryId" placeholder="Category">
                                {categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.value}
                                    </MenuItem>
                                ))}
                            </FormSelect>
                        </Box>
                        <Box mb={2.5}>
                            <FormInput classes={{ root: classes.input }} name="name" label="title" />
                        </Box>
                        <Box className={classes.multilineInput} mb={2.5}>
                            <FormMultilineInput name="description" placeholder="Description..." />
                        </Box>
                        <Box display="flex" alignItems="center" color="text.secondary" mb={2.5}>
                            <Box mr={3}>
                                <Typography variant="body1">
                                    Type:
                                </Typography>
                            </Box>
                            <FormRadioGroup defaultValue={values.type} row name="type">
                                <FormControlLabel
                                    value={ResourceTypeEnum.document}
                                    control={<Radio color="primary" />}
                                    label="File"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={ResourceTypeEnum.link}
                                    control={<Radio color="primary" />}
                                    label="Link"
                                    labelPlacement="end"
                                />
                            </FormRadioGroup>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            {values.type === ResourceTypeEnum.document && (
                                <FileUploader
                                    initialFilename={values.documentFilename}
                                    initialUrl={values.url}
                                    file={selectedFile}
                                    onChange={handleFileChange}
                                />
                            )}
                            {values.type === ResourceTypeEnum.link && (
                                <FormInput
                                    name="url"
                                    label="Enter URL"
                                    classes={{ root: classes.input }}
                                    InputProps={{
                                        endAdornment: (
                                            <Box display="flex" color="text.secondary">
                                                <Link />
                                            </Box>
                                        ),
                                    }}
                                />
                            )}
                        </Box>
                        <ErrorMessage name="documentBytes">
                            {(message) => <FormHelperText error>{message}</FormHelperText>}
                        </ErrorMessage>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" p={4}>
                        <Box ml={2}>
                            <FormControlLabel
                                control={<FormSwitcher name="isActive" />}
                                label={<Typography variant="body2">Active</Typography>}
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="stretch"
                            height="46px"
                            gridGap={theme.spacing(1.75)}
                            justifyContent="flex-end"
                        >
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={resource ? <Checkmark /> : null}
                                disableElevation
                                loading={isSubmitting}
                                loadingLabel={resource ? "Saving..." : "Creating..."}
                            >
                                {resource ? "Save" : "Create"}
                            </LoadingButton>
                            <Button variant="outlined" size="large" onClick={onClose}>
                                Cancel
                            </Button>
                            {resource && (
                                <Button variant="outlined" size="large" onClick={handleDelete}>
                                    <DeleteIcon width={20} height={20} color={theme.palette.text.secondary} />
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Form>
            </FormikProvider>
        </Dialog>
    );
};

export default EditResource;