import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontWeight: 600,
    },
    memberList: {
        backgroundColor: theme.colors.structure.pageBackground,
        columnGap: theme.spacing(3),
        gridTemplateColumns: "repeat(1, 1fr)",
        rowGap: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
    },
    label: {
        color: theme.palette.text.secondary,
    },
    link: {
        textDecoration: "none",
    },
}));

export default useStyles;
