import React from "react";
import { Box, Typography } from "@material-ui/core";

import { FcrConfiguration } from "types";
import RichText from "components/RichText";

import { getDevGoalsFields } from "../../../config";
import useStyles from "../styles";

interface DevelopmentGoalItemProps {
    value: [string, string];
    fcrConfiguration: FcrConfiguration;
}

function DevelopmentGoalItem({ value, fcrConfiguration }: DevelopmentGoalItemProps) {
    const classes = useStyles();

    return (
        <Box>
            <Box fontWeight="600">
                <Typography variant="h3">{getDevGoalsFields(fcrConfiguration).find(dg => dg.fieldName === value[0])?.label}</Typography>
            </Box>
            <Box pt={1.5} pb={4}>
                <Typography component="div" className={classes.opacityStyle}>
                    <RichText text={value[1]}/>
                </Typography>
            </Box>
        </Box>
    )
}

export default DevelopmentGoalItem;
