import React, { useCallback, useMemo } from "react";
import { Box, Typography, Grid, Button, FormHelperText } from "@material-ui/core";
import { FormikProvider, Form, useFormik, FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import NavigationLink from "components/NavigationLink";
import LoadingButton from "components/LoadingButton";
import LevelHierarchy from "components/LevelHierarchy";
import { ChevronLeft, TickIcon } from "svgComponents";
import { CompanyLevel, Team, TeamFormValues, UserProfile, CoLeaderProfile } from "types";
import { AdminRoutings } from "types/constants";
import { updateCoLeaders, updateTeam, updateTeamMembers } from "services/teamServices";
import { excludeSelectedCoLeaders, getTeamFormData } from "helpers/teamHelpers";
import { getUserFullName } from "helpers/userProfileHelper";

import TeamDetails from "../TeamDetails";

const TeamValidationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
});

interface TeamFormProps {
    team: Team;
    leaders: UserProfile[];
    coLeaders: UserProfile[];
    potentialMembers: UserProfile[];
    companyLevels: CompanyLevel[];
    amountOfIncompleteReportsForCoach: number;
    amountOfIncompleteReportsForCoachees: { [key: string]: number };
    fetchAmountOfIncompleteReportsForCoachee: (userId: string) => void;
}

export default function TeamForm({
    team,
    leaders,
    coLeaders,
    potentialMembers,
    companyLevels,
    amountOfIncompleteReportsForCoach,
    amountOfIncompleteReportsForCoachees,
    fetchAmountOfIncompleteReportsForCoachee,
}: TeamFormProps) {
    const history = useHistory();

    const getRemovedTeamMembers = useCallback((members: UserProfile[]) => (
        team.userMembers?.filter((teamMember) => !members.find((member) => member.id === teamMember.id)) ?? []
    ), [team.userMembers]);

    const handleSubmit = (values: TeamFormValues, { setSubmitting }: FormikHelpers<TeamFormValues>) => {
        const { coLeaders, members, ...updatedTeamFields } = values;

        const selectedCoLeaders = coLeaders
            .filter((coLeader) => !!coLeader.id) as Pick<CoLeaderProfile, "id" | "hasCoachingReportReadAccess">[];

        Promise.all([
            updateTeam({ ...team, ...updatedTeamFields }),
            updateTeamMembers(team.id, members.map((member) => member.id)),
            updateCoLeaders(team.id, selectedCoLeaders),
        ]).finally(() => {
            setSubmitting(false);
            history.push(AdminRoutings.teams);
        });
    };

    const initialValues = useMemo(() => getTeamFormData(team), [team]);

    const formik = useFormik<TeamFormValues>({
        enableReinitialize: true,
        initialValues,
        validationSchema: TeamValidationSchema,
        validateOnChange: false,
        onSubmit: handleSubmit,
    });

    const { setFieldValue } = formik;

    const handleUpdateMembers = useCallback((members: UserProfile[]) => {
        setFieldValue("members", members);

        const removedTeamMembers = getRemovedTeamMembers(members);
        removedTeamMembers.forEach(({ id }) => fetchAmountOfIncompleteReportsForCoachee(id));
    }, [setFieldValue, getRemovedTeamMembers, fetchAmountOfIncompleteReportsForCoachee]);

    const availableLeaders = excludeSelectedCoLeaders(leaders, formik.values.coLeaders);

    const availablePotentialMembers = excludeSelectedCoLeaders(potentialMembers, formik.values.coLeaders);

    const allCoLeaders = [...coLeaders, ...(team.coLeaders ?? []), ...(team.teamLeader ? [team.teamLeader] : [])];
    const availableCoLeaders = allCoLeaders.filter((coLeader) => coLeader.id !== formik.values.teamLeaderId);

    const warnings = useMemo(() => {
        const warningsArray = [];

        const removedTeamMembers = getRemovedTeamMembers(formik.values.members);

        if (!team.teamLeader && !formik.values.teamLeaderId || removedTeamMembers.length > 0) {
            warningsArray.push(
                <FormHelperText error>
                    Please note that removing any team members from the team will orphan them and they will need to be reassigned under the Admin Member Edit page.
                </FormHelperText>
            );
        }

        if (team.teamLeader && !formik.values.teamLeaderId && amountOfIncompleteReportsForCoach > 0) {
            warningsArray.push(
                <Box mt={warningsArray.length > 0 ? 1 : 0}>
                    <FormHelperText error>
                        Warning: There are {amountOfIncompleteReportsForCoach} open Coaching Reports assigned to {getUserFullName(team.teamLeader)}.
                        These will be orphaned and inaccessible until another Team Leader is assigned.
                    </FormHelperText>
                </Box>
            );
        }

        removedTeamMembers.forEach((teamMember) => {
            warningsArray.push(
                <Box mt={warningsArray.length > 0 ? 1 : 0}>
                    <FormHelperText error>
                        Warning: There are {amountOfIncompleteReportsForCoachees[teamMember.id]} open Coaching Reports assigned to {getUserFullName(teamMember)}.
                        These will be orphaned and inaccessible until the member is assigned to a new Team.
                    </FormHelperText>
                </Box>
            );
        });

        return warningsArray;
    }, [
        team.teamLeader,
        formik.values.members,
        getRemovedTeamMembers,
        formik.values.teamLeaderId,
        amountOfIncompleteReportsForCoach,
        amountOfIncompleteReportsForCoachees,
    ]);

    return (
        <>
            <Box
                mb={6}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                minHeight="46px"
            >
                <Box pr={2}>
                    <NavigationLink href={AdminRoutings.teams} hoverUnderline={false}>
                        <Typography variant="h1">
                            <Grid alignItems="center" container>
                                <Grid component="span" item>
                                    <ChevronLeft display="block" height={24} width={24} />
                                </Grid>
                                <Grid item>
                                    <Box display="flex" flexDirection="column" ml={1.5}>
                                        <Box component="span">{team.name}</Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Typography>
                    </NavigationLink>
                    <Box display="flex" width="100%" flexDirection="column" ml={2.5}>
                        <Grid container>
                            <LevelHierarchy
                                companyLevelId={team.companyLevelId}
                                companyLevels={companyLevels}
                            />
                        </Grid>
                    </Box>
                </Box>
                <Box display="flex">
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        type="submit"
                        loading={formik.isSubmitting}
                        startIcon={<TickIcon />}
                        loadingLabel={"Updating..."}
                        onClick={formik.submitForm}
                    >
                        <Box component="span" lineHeight="30px">{"Save Updates"}</Box>
                    </LoadingButton>

                    <Box ml={2.5}>
                        <NavigationLink href={AdminRoutings.teams} hoverUnderline={false}>
                            <Button variant="outlined" size="large">
                                <Box component="span" lineHeight="30px">
                                    Cancel
                                </Box>
                            </Button>
                        </NavigationLink>
                    </Box>
                </Box>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box mb={2.5}>
                        <FormikProvider value={formik}>
                            <Form>
                                <TeamDetails
                                    leaders={availableLeaders}
                                    coLeaders={availableCoLeaders}
                                    potentialMembers={availablePotentialMembers}
                                    members={team.userMembers || null}
                                    companyLevels={companyLevels}
                                    warnings={warnings}
                                    setUserIds={handleUpdateMembers}
                                />
                            </Form>
                        </FormikProvider>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}