import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
    scrollContainer: {
        overflowY: "auto",

        "&::-webkit-scrollbar": {
            width: "10px",
        },

        "&::-webkit-scrollbar-thumb": {
            background: "#EFF2F4",
            borderRadius: "10px",
        },

        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
    },
}));
