import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    "@keyframes spinnerRotation": {
        from: {
            transform: "rotate(0)",
        },
        to: {
            transform: "rotate(360deg)",
        },
    },
    "@keyframes spinnerWrapping": {
        from: {
            strokeDasharray: "1px, 200px",
            strokeDashoffset: 0,
        },
        "50%": {
            strokeDasharray: "100px, 200px",
            strokeDashoffset: "-15px",
        },
        to: {
            strokeDasharray: "1px, 200px",
            strokeDashoffset: "-125px",
        },
    },
    spinner: {
        animation: "$spinnerRotation 1.4s linear infinite",
        "& circle": {
            animation: "$spinnerWrapping 1.4s ease-in-out infinite",
        },
    },
    label: {
        lineHeight: "30px",
        display: "flex",
        whiteSpace: "nowrap",
    },
})

export default useStyles;