import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    root: {
        "& path": {
            fill: "currentColor",
        },
    },
}, {
    name: "SessionsIcon",
});
