import { Team, TeamFormValues, UserProfile } from "types";
import orderBy from "lodash/orderBy";

export const CO_LEADER_INDEXES = [0, 1, 2];

export const getTeamFormData = (team: Team | null): TeamFormValues => ({
    isActive: team?.isActive ?? true,
    name: team?.name || "",
    teamLeaderId: team?.teamLeaderId || null,
    members: team?.userMembers || [],
    coLeaders: CO_LEADER_INDEXES.map((idx) => ({
        id: team?.coLeaders?.[idx]?.id || null,
        hasCoachingReportReadAccess: team?.coLeaders?.[idx]?.hasCoachingReportReadAccess ?? false,
    })),
});

export const excludeSelectedCoLeaders = (
    allCoLeaders?: UserProfile[] | null,
    selectedCoLeaders?: TeamFormValues["coLeaders"] | null
) => {
    if (!allCoLeaders) return [];
    if (!selectedCoLeaders) return allCoLeaders;

    return allCoLeaders.filter((coLeader) => !selectedCoLeaders.find((selectedCoLeader) => selectedCoLeader.id === coLeader.id));
};

export const extractTeamLeaders = (teams: Team[]) => (
    teams
        .map((team) => team.teamLeader)
        .filter((teamLeader) => teamLeader) as UserProfile[] | []
);

export const findTeamByLeader = (userId: UserProfile["id"], teams: Team[]) => teams.find(
    (team) => team.teamLeaderId === userId
) ?? null;

export const findTeamByMember = (userId: UserProfile["id"], teams: Team[]) => teams.find((team) => {
    const teamMembersIds = team?.userMembers?.map((member) => member.id) ?? [];
    return teamMembersIds.includes(userId!);
}) ?? null;

export const sortTeamsByOrder = (teams: Team[]) =>
    orderBy(teams, [
        "isActive",
        team => team?.teamLeaderId === null,
        team => team?.companyLevel?.level,
        team => team?.teamLeader?.firstName,
    ], ["desc", "desc", "asc", "asc"])
