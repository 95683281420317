import React, { useContext, useMemo } from "react";
import { useHistory, useParams, Link, Route, Switch } from "react-router-dom";
import { Tab, Tabs, Box, Paper, CircularProgress } from "@material-ui/core";

import DashboardContent from "routes/Dashboard/DashboardContent";
import TeamProfile from "routes/TeamProfileWrapper/TeamProfile";
import { Team, TeamProfileRouteParams, UserProfile } from "types";
import { getUserProfile } from "services/userServices";
import { getTeamByMemberId } from "services/teamServices";
import { isHighLevel } from "helpers/companyHelper";
import useAsync, { PromiseStatus } from "hooks/useAsync";
import { ConfigurationContext } from "context";
import UserPreview from "components/UserPreview";
import { Routings } from "types/constants";
import useLocationStateParam from "hooks/useLocationStateParam";

enum TeamProfileTab {
    Level = "level",
    Coaching = "coaching",
    Profile = "profile",
}

export default function TeamProfileDashboard() {
    const { currentUser, companyLevels } = useContext(ConfigurationContext);
    const isScrollIntoLastSession = !!useLocationStateParam("sessionId");
    const { userId } = useParams<TeamProfileRouteParams>();
    const history = useHistory();
    const { location } = history;

    const tabLinks = useMemo(() => ({
        [TeamProfileTab.Level]: `${Routings.teamProfile}/${userId}/level`,
        [TeamProfileTab.Coaching]: `${Routings.teamProfile}/${userId}/coaching`,
        [TeamProfileTab.Profile]: `${Routings.teamProfile}/${userId}`,
    }), [userId]);

    const setInitialCurrentTab = (user: UserProfile) => {
        if (location.pathname !== tabLinks[TeamProfileTab.Profile] || isScrollIntoLastSession) return;
        if (user.isALeader || user.isACoLeader) {
            history.replace(
                isHighLevel(user.level, companyLevels)
                    ? tabLinks[TeamProfileTab.Level]
                    : tabLinks[TeamProfileTab.Coaching]
            );
        }
    };

    const { status: userFetchStatus, value: user } = useAsync<UserProfile | null>(() => {
        if (!userId) return Promise.resolve(null);

        if (currentUser && (userId === currentUser?.id)) {
            setInitialCurrentTab(currentUser);
            return Promise.resolve(currentUser);
        }

        return getUserProfile(userId)
            .then((user) => {
                setInitialCurrentTab(user);
                return user;
            });
    }, [userId]);

    const { status: teamFetchStatus, value: team } = useAsync<Team | null>(() => (
        userId ? getTeamByMemberId(userId) : Promise.resolve(null)
    ), [userId]);

    if (!user) {
        return (
            <Box display="flex" flexDirection="column" p={4}>
                {(userFetchStatus === PromiseStatus.PENDING || teamFetchStatus === PromiseStatus.PENDING || !companyLevels.length) ? (
                    <Box m="auto">
                        <CircularProgress size={40} />
                    </Box>
                ) : (
                    <Paper>
                        <Box p={4}>
                            This user does not exist
                        </Box>
                    </Paper>
                )}
            </Box>
        );
    }

    if (!user.isALeader && !user.isACoLeader) {
        return (
            <TeamProfile user={user} />
        );
    }

    const renderTabs = () => {
        const value = Object.values(tabLinks).includes(location.pathname)
            ? location.pathname
            : tabLinks[TeamProfileTab.Profile];

        return (
            <Tabs textColor="primary" value={value}>
                {isHighLevel(user.level, companyLevels) && (
                    <Tab
                        component={Link}
                        label={user.levelLabel}
                        to={tabLinks[TeamProfileTab.Level]}
                        value={tabLinks[TeamProfileTab.Level]}
                    />
                )}
                <Tab
                    component={Link}
                    label="Coaching"
                    to={tabLinks[TeamProfileTab.Coaching]}
                    value={tabLinks[TeamProfileTab.Coaching]}
                />
                <Tab
                    component={Link}
                    label="Development"
                    to={tabLinks[TeamProfileTab.Profile]}
                    value={tabLinks[TeamProfileTab.Profile]}
                />
            </Tabs>
        );
    };    

    return (
        <Switch>
            <Route path={[
                tabLinks[TeamProfileTab.Coaching],
                ...(isHighLevel(user.level, companyLevels) ? [tabLinks[TeamProfileTab.Level]] : []),
            ]}>
                <Box display="flex" alignItems="center" py={2.5} px={{ xs: 3, lg: 12 }}>
                    <Box mr={{ xs: 5, lg: 10 }}>
                        <UserPreview user={user} coachedBy={team?.teamLeader} />
                    </Box>
                    {renderTabs()}
                </Box>
                <DashboardContent
                    user={user}
                    teamMode={location.pathname === tabLinks[TeamProfileTab.Level]}
                />
            </Route>
            <Route>
                <TeamProfile user={user} navigation={renderTabs()} />
            </Route>
        </Switch>
    );
}