import React from "react";
import { Box, Typography, Grid, FormControlLabel } from "@material-ui/core";
import { useFormikContext } from "formik";

import PaperCard from "components/PaperCard";
import { FormUsersAutocomplete } from "components/UsersAutocomplete";
import { FormInput, FormSwitcher } from "components/Form";
import { UserProfile, Team, CompanyLevel, TeamFormValues } from "types";
import { CO_LEADER_INDEXES, excludeSelectedCoLeaders } from "helpers/teamHelpers";

import { AssignmentCaptionMessage } from "components/AssignmentMessages";

import AssignTeamMembers from "../AssignTeamMembers";

interface TeamDetailsProps {
    teamId?: Team["id"];
    leaders: UserProfile[];
    coLeaders: UserProfile[];
    isLeadersFetching?: boolean;
    membersLevelId?: CompanyLevel["id"] | null;
    potentialMembers?: UserProfile[];
    members: UserProfile[] | null;
    companyLevels: CompanyLevel[];
    onLeaderChange?: (value: UserProfile["id"] | null) => void;
    warnings: Array<string | React.ReactNode>;
    setUserIds: (ids: UserProfile[]) => void;
}

export default function TeamDetails({
    leaders,
    coLeaders,
    isLeadersFetching = false,
    membersLevelId,
    potentialMembers,
    members,
    companyLevels,
    onLeaderChange,
    warnings,
    setUserIds,
}: TeamDetailsProps) {
    const {
        values: { coLeaders: selectedCoLeaders },
    } = useFormikContext<TeamFormValues>();

    const getCoLeaders = (coLeaderIndex: number): UserProfile[] => {
        const coLeaderId = selectedCoLeaders[coLeaderIndex].id;
        const coLeaderProfile = coLeaderId && coLeaders.find((coLeader) => coLeader.id === coLeaderId);

        const filteredCoLeaders = excludeSelectedCoLeaders(coLeaders, selectedCoLeaders);

        if (!coLeaderProfile) return filteredCoLeaders;

        return [...filteredCoLeaders, coLeaderProfile];
    };

    return (
        <PaperCard>
            <Box display="flex" flexWrap="wrap" justifyContent="spaceBetween" alignItems="center" fontWeight={600} minHeight="36px">
                <Typography variant="h2">Team Details</Typography>
            </Box>
            {warnings.map((warning, idx) => (
                <React.Fragment key={idx}>{warning}</React.Fragment>
            ))}
            <Box mb={4} mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <FormInput name="name" label="Enter Team Name" required={true} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormUsersAutocomplete
                            name="teamLeaderId"
                            label="Select Leader"
                            userProfiles={leaders ?? []}
                            loading={isLeadersFetching}
                            onChange={onLeaderChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {CO_LEADER_INDEXES.map((index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <FormUsersAutocomplete
                                name={`coLeaders[${index}].id`}
                                label="Select Co-Leader"
                                userProfiles={getCoLeaders(index)}
                            />
                            <Box mt={1} ml="11px">
                                <FormControlLabel
                                    control={<FormSwitcher name={`coLeaders[${index}].hasCoachingReportReadAccess`} />}
                                    label={<Typography variant="body2">Coaching Report Read Access</Typography>}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <AssignTeamMembers
                membersLevelIds={membersLevelId ? [membersLevelId] : []}
                potentialMembers={potentialMembers}
                distributionMembers={members}
                companyLevels={companyLevels}
                setUserIds={setUserIds}
                rightTitle="Team Members"
                description={
                    <Box mb={3}>
                        <AssignmentCaptionMessage />
                    </Box>
                }
            />
        </PaperCard>
    );
}
