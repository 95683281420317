import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { SizeEnum } from "types/constants";
import { UserProfile } from "types";
import { getUserFullName } from "helpers/userProfileHelper";
import AvatarWithScore from "components/AvatarWithScore";
import CoachedBy from "components/CoachedBy";

import useStyles from "./styles";

interface UserPreviewProps {
    user: UserProfile;
    coachedBy?: UserProfile | null;
}

export default function UserPreview({ user, coachedBy }: UserPreviewProps) {
    const classes = useStyles();
    const fullName = useMemo(() => getUserFullName(user), [user]);

    return (
        <Box display="flex" alignItems="center">
            <Box mr={2.5}>
                <AvatarWithScore user={user} score={user.averageScore} size={SizeEnum.mediumSmaller} />
            </Box>
            <Box>
                <Typography className={classes.heading} variant="h1">{fullName}</Typography>
                {coachedBy && <CoachedBy user={coachedBy} />}
            </Box>
        </Box>
    );
}