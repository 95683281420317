import * as React from "react";

export default function SvgUsers(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M5.5,8.75 C6.9912379,8.75 8.34386085,9.3434812 9.33456051,10.3071353 C9.9892058,9.94387266 10.730594,9.75 11.5,9.75 C13.9142733,9.75 15.8844891,11.6512365 15.995102,14.0381643 L16,14.25 L15,14.25 C15,12.3170034 13.4329966,10.75 11.5,10.75 C10.9718129,10.75 10.461794,10.8669444 9.99868764,11.0872518 C10.5906591,11.9260623 10.9524268,12.9364826 10.9956328,14.0287929 L11,14.25 L10,14.25 C10,11.7647186 7.98528137,9.75 5.5,9.75 C3.08572667,9.75 1.11551091,11.6512365 1.00489799,14.0381643 L1,14.25 L0,14.25 C0,11.2124339 2.46243388,8.75 5.5,8.75 Z M11.5,3.75 C12.8807119,3.75 14,4.86928813 14,6.25 C14,7.63071187 12.8807119,8.75 11.5,8.75 C10.1192881,8.75 9,7.63071187 9,6.25 C9,4.86928813 10.1192881,3.75 11.5,3.75 Z M5.5,1.75 C7.15685425,1.75 8.5,3.09314575 8.5,4.75 C8.5,6.40685425 7.15685425,7.75 5.5,7.75 C3.84314575,7.75 2.5,6.40685425 2.5,4.75 C2.5,3.09314575 3.84314575,1.75 5.5,1.75 Z M11.5,4.75 C10.6715729,4.75 10,5.42157288 10,6.25 C10,7.07842712 10.6715729,7.75 11.5,7.75 C12.3284271,7.75 13,7.07842712 13,6.25 C13,5.42157288 12.3284271,4.75 11.5,4.75 Z M5.5,2.75 C4.3954305,2.75 3.5,3.6454305 3.5,4.75 C3.5,5.8545695 4.3954305,6.75 5.5,6.75 C6.6045695,6.75 7.5,5.8545695 7.5,4.75 C7.5,3.6454305 6.6045695,2.75 5.5,2.75 Z"
            />
        </svg>
    );
}
