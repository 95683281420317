import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    listSubHeader: {
        paddingLeft: `${theme.spacing(1.5)}px`,
        paddingRight: `${theme.spacing(1.5)}px`,
    },
}));

export default useStyles;
