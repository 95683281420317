import React from "react";
import { Doc, Link, Pdf } from "svgComponents";
import { ResourceTypeEnum } from "types/constants";

const RESOURCE_ICONS: Record<ResourceTypeEnum | "pdf", React.FC<React.SVGProps<SVGSVGElement>>> = {
    [ResourceTypeEnum.link]: Link,
    [ResourceTypeEnum.document]: Doc,
    "pdf": Pdf, // TODO: not sure if this will be added
}

interface ResourceIconProps extends React.SVGProps<SVGSVGElement> {
    type?: ResourceTypeEnum
}

const ResourceIcon = ({ type = ResourceTypeEnum.link, ...props }: ResourceIconProps) => {
    const Component = RESOURCE_ICONS[type];
    return <Component {...props} />;
};

export default ResourceIcon;
