import * as React from "react";

function SvgDoc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={20}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.49 5.71c0-.27-.17-.49-.41-.58L12.22.19a.597.597 0 00-.44-.19H2.25C1.35 0 .63.73.63 1.62v16.53c0 .9.73 1.62 1.62 1.62h13.62c.9 0 1.62-.73 1.62-1.62V5.8c0-.02-.01-.03-.01-.05 0-.01.01-.02.01-.04zm-5.08-3.56l2.88 2.93h-2.51c-.2 0-.38-.17-.38-.38V2.15h.01zm3.46 16.38H2.25c-.2 0-.38-.17-.38-.38V1.62c0-.21.17-.38.38-.38h8.91V4.7c0 .9.73 1.62 1.62 1.62h3.46v11.82c0 .21-.17.39-.37.39z"
        fill="currentColor"
      />
      <path
        d="M10.891 9.12h.7l-.77 3.42c-.03.13-.05.26-.05.36l-.02.16h-.03c0-.05-.02-.11-.02-.16-.02-.1-.04-.23-.07-.36l-1.13-4.26h-.89l-1.13 4.26c-.03.13-.04.26-.06.36l-.03.16h-.03l-.02-.16c-.01-.1-.02-.23-.05-.36l-.77-3.42h.7v-.84h-2.35v.84h.55l1.28 5.17h1.24l1-3.79c.04-.12.06-.23.08-.36.01-.06.02-.13.02-.19h.03l.02.19c.02.11.03.23.07.36l1 3.79h1.24l1.28-5.17h.55v-.84h-2.35v.84h.01z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDoc;
