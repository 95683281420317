import { FcrConfiguration } from "types";

export const devGoalsFields = [
    {
        fieldName: "developmentGoals",
        label: "Development Goals",
        placeholder: "How do you want to improve your skills?  What is your long-term development goal?",
    },
    {
        fieldName: "actionTaken",
        label: "Actions Taken",
        placeholder: "What actions have you taken towards your goal since our last conversation?",
    },
    {
        fieldName: "resultsSeen",
        label: "Results Seen",
        placeholder: "What results have you seen as you have been working towards this goal?",
    },
    {
        fieldName: "coachingInteractionGoals",
        label: "Goals for this Coaching Interaction",
        placeholder: "Think about the incremental steps you will take to reach your development goal. What small step do you want to work on today?",
    },
];

export function getDevGoalsFields(fcrConfiguration: FcrConfiguration) {
    if (fcrConfiguration.displayOpenNotes) {
        return [
            ...devGoalsFields,
            {
                fieldName: "openNotes",
                label: fcrConfiguration.openNotesTitle,
                placeholder: "",
            },
        ];
    }

    return devGoalsFields;
}
