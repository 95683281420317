
import { useMemo, useState } from "react";
import { CoachingHistory } from "types/CoachHistory";
import { CoachingPeriodEnum } from "types/constants";
import useCoachingPeriod from "hooks/useCoachingPeriod";
import { byToDate } from "helpers/coachingHistoryHelper";

export default function useSummary(
    initialHistory: CoachingHistory[] = [],
    currentDate: Date,
    initialPeriod: CoachingPeriodEnum = CoachingPeriodEnum.yearToDate
) {
    const { period, periodLabel, setPeriod } = useCoachingPeriod(initialPeriod);
    
    // History
    const [ history, setHistory ] = useState(initialHistory);
    const allHistory = useMemo(() => history.filter(({ reportType }) => reportType === period), [history, period]);
    const curHistory = useMemo(() => allHistory.filter(byToDate(currentDate)), [allHistory, currentDate]);
    const prevHistory = useMemo(() => allHistory.filter(byToDate(currentDate, false)), [allHistory, currentDate]);

    return {
        period,
        periodLabel,
        setPeriod,
        setHistory,
        curHistory,
        prevHistory,
    }
}
