import React, { useCallback, useEffect, useMemo, useState, useContext } from "react";
import * as Yup from "yup";
import { Box, Button, Divider, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ChevronLeft, TickIcon } from "svgComponents";
import { Link, useHistory, useParams } from "react-router-dom";
import { AdminRoutings, AlertSeverity, AuthenticationTypeEnum } from "types/constants";
import { useFormik, FormikProvider, FormikHelpers } from "formik";
import { AdminRouteMemberParams, FcrResource, MemberFormProps, Team, UserProfile } from "types";
import { FormInput, FormPhoneInput, FormAvatarInput, FormSwitcher } from "components/Form";
import { getMemberFormData, getUserFullName } from "helpers/userProfileHelper";
import { getUserById, postUser, sendRegistrationEmail, uploadUserLogo } from "services/userServices";
import LoadingButton from "components/LoadingButton";
import { CompaniesContext, SnackbarContext } from "context";
import useOnCompanyChange from "hooks/useOnCompanyChange";
import { getResourceFromFile } from "helpers/resourceHelper";
import usePrevious from "hooks/usePrevious";
import PaperCard from "components/PaperCard";
import NavigationLink from "components/NavigationLink";
import { getAllCompanyTeams } from "services/teamServices";
import ConfirmationModal from "components/ConfirmationModal";
import { buildLevelHierarchyObjectsToLevel, buildLevelPath } from "helpers/companyHelper";

import TeamAssignment from "./TeamAssignment";
import useStyles from "./styles";

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    emailAddress: Yup.string().email("Must be a valid email").required("Required"),
});

export default function MemberPage() {
    const classes = useStyles();
    const history = useHistory<UserProfile | undefined>();
    const { userId } = useParams<AdminRouteMemberParams>();
    const prevUserId = usePrevious(userId);
    const { currentUser, currentCompany, companyLevels, fetchCompanyUsers } = useContext(CompaniesContext);
    const [teams, setTeams] = useState<Team[]>([]);
    const [member, setMember] = useState<UserProfile | undefined>(history.location.state);
    const [submitError, setSubmitError] = useState<string | React.ReactNode>();
    const [sending, setSending] = useState<boolean>(false);
    const [showSendEmailConfirmation, setShowSendEmailConfirmation] = useState<boolean>(false);
    const { openSnackbar } = useContext(SnackbarContext);
    const [isRefreshing, setIsRefreshing] = useState(false);

    useOnCompanyChange(() => history.push(AdminRoutings.members));

    const fetchUserData = useCallback(
        (userId: string) => {
            getUserById(userId)
                .then(setMember)
                .catch(() => history.push(AdminRoutings.addMember));
        },
        [history]
    );

    useEffect(() => {
        if (userId && userId !== prevUserId) fetchUserData(userId);
    }, [userId, prevUserId, fetchUserData]);

    useEffect(() => {
        const companyId = userId ? member?.companyId : currentCompany?.id;
        companyId && getAllCompanyTeams(companyId).then(setTeams);
    }, [userId, member?.companyId, currentCompany?.id]);

    const saveAvatar = useCallback(
        async (memberId: string, companyId: string, file?: File): Promise<FcrResource | undefined> => {
            if (file) {
                const resource = await getResourceFromFile(file, companyId, currentUser!.id);

                return uploadUserLogo(memberId, resource);
            }
        },
        [currentUser]
    );

    const handleSubmit = useCallback(
        ({ avatarFile, authorizationProvider, ...memberValues }: MemberFormProps, { setSubmitting }: FormikHelpers<MemberFormProps>) => {
            const existingTeam =
                memberValues.companyLevelId &&
                teams.find(
                    (team) =>
                        team.teamLeader &&
                        team.teamLeader.companyLevelId === memberValues.companyLevelId &&
                        team.teamLeader.id !== memberValues.id
                );

            if (memberValues.companyLevelId && existingTeam && existingTeam.teamLeader) {
                const existingTeamLeader = existingTeam.teamLeader;
                const levelHierarchy = buildLevelHierarchyObjectsToLevel(memberValues.companyLevelId, companyLevels);

                const message = (
                    <>
                        {getUserFullName(existingTeamLeader)} is already a Team Leader for the {buildLevelPath(levelHierarchy)} team. You
                        will first need to remove them as the Team Leader under the{" "}
                        <Link to={`${AdminRoutings.teams}/${existingTeam.id}`}>Teams Management section</Link>.
                    </>
                );

                setSubmitError(message);
                setSubmitting(false);
                setIsRefreshing(false);

                return;
            }

            setSubmitError("");

            postUser(authorizationProvider, memberValues)
                .then((updatedMember) => {
                    saveAvatar(updatedMember.id, updatedMember.companyId, avatarFile).then(() => {
                        fetchCompanyUsers();

                        if (isRefreshing) {
                            setMember(updatedMember);
                            history.push(`${AdminRoutings.members}/${updatedMember.id}`);
                        } else {
                            history.push(AdminRoutings.members);
                        }

                        setSubmitting(false);
                        setIsRefreshing(false);
                    });
                })
                .catch((error) => {
                    const errorMsg = error.response.status === 500 ? error.response.statusText : error.response.data;
                    setSubmitError(errorMsg);
                    setSubmitting(false);
                    setIsRefreshing(false);
                });
        },
        [teams, companyLevels, saveAvatar, isRefreshing, history, fetchCompanyUsers]
    );

    const initialValues = useMemo(
        () => getMemberFormData(member, currentCompany?.id ?? "", currentUser?.id ?? ""),
        [member, currentCompany?.id, currentUser?.id]
    );

    const formik = useFormik<MemberFormProps>({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    const leaderTeamName = useMemo(
        () => (userId ? teams.find((companyTeam) => companyTeam.teamLeaderId === userId)?.name : ""),
        [userId, teams]
    );

    const handleSendEmail = useCallback(() => {
        if (!userId || !member) return;
        if (showSendEmailConfirmation) setShowSendEmailConfirmation(false);
        setSending(true);
        sendRegistrationEmail(userId)
            .then(() => {
                setMember({ ...member, isInvitationSent: true });
                openSnackbar("Email was successfully sent.", AlertSeverity.success);
            })
            .catch((e) => openSnackbar(e.response.data || "Failed to send email.", AlertSeverity.error))
            .finally(() => setSending(false));
    }, [userId, member, showSendEmailConfirmation, openSnackbar]);

    const handleSendEmailClick = useCallback(() => {
        if (!member) return;

        if (member.isInvitationSent) {
            setShowSendEmailConfirmation(true);
        } else {
            handleSendEmail();
        }
    }, [member, handleSendEmail]);

    const handleOnEvaluatorChange = useCallback(() => {
        formik.setFieldValue("isEvaluator", false);
    }, [formik]);

    const showSendEmail =
        userId &&
        member &&
        member.isActive &&
        (member.userTeamMember || teams.findIndex((team) => team.teamLeaderId === member.id) > -1 || member.level === 1);

    const isOpenIdMember: boolean = useMemo(
        () =>
            currentCompany?.authenticationTypeId && userId ? currentCompany?.authenticationTypeId === AuthenticationTypeEnum.openId : false,
        [currentCompany?.authenticationTypeId, userId]
    );

    return (
        <FormikProvider value={formik}>
            {submitError && (
                <Box mb={4}>
                    <Alert severity="error" onClose={() => setSubmitError("")}>
                        {submitError}
                    </Alert>
                </Box>
            )}
            <Grid alignItems="center" container spacing={2}>
                <Grid item>
                    <NavigationLink href={AdminRoutings.members} hoverUnderline={false}>
                        <Typography variant="h1">
                            <Grid alignItems="center" component="span" container>
                                <Grid component="span" item>
                                    <ChevronLeft display="block" height={24} width={24} />
                                </Grid>
                                <Grid className={classes.titleGap} component="span" item>
                                    {!userId ? "New Member" : member && getUserFullName(member)}
                                </Grid>
                            </Grid>
                        </Typography>
                    </NavigationLink>
                    {leaderTeamName && (
                        <Box pl={4.5}>
                            <Typography variant="caption">Team Leader of "{leaderTeamName}"</Typography>
                        </Box>
                    )}
                </Grid>

                {showSendEmail && (
                    <Grid className={classes.alignRight} item>
                        <LoadingButton
                            color="primary"
                            disableElevation
                            loading={sending}
                            loadingLabel="Sending..."
                            size="large"
                            variant="outlined"
                            onClick={handleSendEmailClick}>
                            <Box component="span" lineHeight="30px">
                                Send Registration Email
                            </Box>
                        </LoadingButton>
                    </Grid>
                )}
                <Grid className={!showSendEmail ? classes.alignRight : ""} item>
                    <LoadingButton
                        color="primary"
                        disableElevation
                        loading={formik.isSubmitting && !isRefreshing}
                        loadingLabel={!userId ? "Creating..." : "Saving..."}
                        size="large"
                        startIcon={!userId ? null : <TickIcon />}
                        variant="contained"
                        onClick={() => {
                            isRefreshing && setIsRefreshing(false);
                            !formik.isSubmitting ? formik.submitForm() : undefined;
                        }}>
                        <Box component="span" lineHeight="30px">
                            {!userId ? "Create Member" : "Save updates"}
                        </Box>
                    </LoadingButton>
                </Grid>

                <Grid item>
                    <Link className={classes.link} to={AdminRoutings.members}>
                        <Button variant="outlined" size="large">
                            <Box component="span" lineHeight="30px">
                                Cancel
                            </Box>
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <Box mt={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <PaperCard>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid alignItems="center" container>
                                        <Box mr={4}>
                                            <Typography variant="h2">
                                                <Box fontWeight={500}>User Details</Box>
                                            </Typography>
                                        </Box>
                                        <Box pl={1.5}>
                                            <FormControlLabel
                                                control={<FormSwitcher name="isActive" />}
                                                label={<Typography>Active</Typography>}
                                            />
                                        </Box>
                                        <Box pl={1.5}>
                                            <FormControlLabel
                                                control={<FormSwitcher name="isEvaluator" />}
                                                label={<Typography>Evaluator</Typography>}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid className={classes.avatarColumn} container item justifyContent="center" md={4} xs={12}>
                                    <FormAvatarInput name="avatarFile" initialAvatarUrl={member?.avatar} />
                                </Grid>
                                <Grid className={classes.mainColumn} item md={8} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <FormInput
                                                name="firstName"
                                                label="First name"
                                                required={true}
                                                disabled={isOpenIdMember ?? true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormInput
                                                name="lastName"
                                                label="Last name"
                                                required={true}
                                                disabled={isOpenIdMember ?? true}
                                            />
                                        </Grid>
                                        <Box flex="0 0 100%" />
                                        <Grid item xs={12} md={4}>
                                            <FormInput name="title" label="Title" disabled={isOpenIdMember ?? true} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormInput name="businessUnit" label="Business unit" disabled={isOpenIdMember ?? true} />
                                        </Grid>
                                        <Box boxSizing="border-box" flex="0 0 100%" px={1} py={2.5}>
                                            <Divider />
                                        </Box>
                                        <Grid item xs={12} md={4}>
                                            <FormPhoneInput name="officePhone" label="Office phone" disabled={isOpenIdMember ?? true} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormPhoneInput name="mobilePhone" label="Cell phone" disabled={isOpenIdMember ?? true} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormInput
                                                name="emailAddress"
                                                label="Contact email"
                                                required={true}
                                                disabled={isOpenIdMember ?? true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormInput name="location" label="Location" disabled={isOpenIdMember ?? true} />
                                        </Grid>
                                        {isOpenIdMember && (
                                            <>
                                                <Box boxSizing="border-box" flex="0 0 100%" px={1} py={2.5}>
                                                    <Divider />
                                                </Box>
                                                <Grid item xs={12} md={4}>
                                                    <LoadingButton
                                                        color="primary"
                                                        disableElevation
                                                        type="submit"
                                                        loading={formik.isSubmitting && isRefreshing}
                                                        loadingLabel="Refreshing..."
                                                        size="large"
                                                        variant="contained"
                                                        onClick={() => {
                                                            !isRefreshing && setIsRefreshing(true);
                                                            !formik.isSubmitting ? formik.submitForm() : undefined;
                                                        }}>
                                                        <Box component="span" lineHeight="30px">
                                                            OpenID Refresh
                                                        </Box>
                                                    </LoadingButton>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </PaperCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TeamAssignment member={member} leaderTeamName={leaderTeamName} onEvaluatorChange={handleOnEvaluatorChange} />
                    </Grid>
                </Grid>
            </Box>

            <ConfirmationModal
                isOpen={showSendEmailConfirmation}
                header="Send Registration Email"
                body="Sending an invitation email to registered users will reset their email and send a temporary password. Continue?"
                confirmBtn="Continue"
                onConfirm={handleSendEmail}
                onClose={() => setShowSendEmailConfirmation(false)}
            />
        </FormikProvider>
    );
}
