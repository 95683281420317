import * as React from "react";

export default function SvgUser(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                stroke="none"
                strokeWidth="1"
                fillRule="evenodd"
                fill="currentColor"
                d="M8,8.75 C10.9634791,8.75 13.3795391,11.09378 13.4956328,14.0287929 L13.5,14.25 L12.5,14.25 C12.5,11.7647186 10.4852814,9.75 8,9.75 C5.58572667,9.75 3.61551091,11.6512365 3.50489799,14.0381643 L3.5,14.25 L2.5,14.25 C2.5,11.2124339 4.96243388,8.75 8,8.75 Z M8,1.75 C9.65685425,1.75 11,3.09314575 11,4.75 C11,6.40685425 9.65685425,7.75 8,7.75 C6.34314575,7.75 5,6.40685425 5,4.75 C5,3.09314575 6.34314575,1.75 8,1.75 Z M8,2.75 C6.8954305,2.75 6,3.6454305 6,4.75 C6,5.8545695 6.8954305,6.75 8,6.75 C9.1045695,6.75 10,5.8545695 10,4.75 C10,3.6454305 9.1045695,2.75 8,2.75 Z"
            />
        </svg>
    );
}
