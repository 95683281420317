import React, { useReducer, useContext } from "react";
import { Box, Button, Divider, Paper, Typography } from "@material-ui/core";
import CoachingReportSelector from "components/CoachingReportSelector";
import { ConfigurationContext } from "context";

import coachingReports from "./coachingReports.svg";

function CoachingReports() {
    const [isCoachingReportSelectorOpen, toggleCoachingReportSelector] = useReducer(state => !state, false);
    const { currentUser, fcrConfiguration } = useContext(ConfigurationContext);

    return (
        <>
            <Box px={7.5} pt={8}>
                <Paper>
                    <Box display="flex" justifyContent="space-between" alignItems="center" pt={2.5} pb={3} px={4}>
                        <Box fontWeight="600">
                            <Typography variant="h2">All Coaching Reports</Typography>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={toggleCoachingReportSelector}
                        >
                            <Box mr={1} fontSize="18px" lineHeight="30px">
                                +
                            </Box>
                            New Coaching Report
                        </Button>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent="center" pt={3} pb={4}>
                        <img src={coachingReports} alt="" />
                    </Box>
                </Paper>
            </Box>
            {isCoachingReportSelectorOpen && (
                <CoachingReportSelector
                    user={currentUser!}
                    isOpen={isCoachingReportSelectorOpen}
                    onClose={toggleCoachingReportSelector}
                    fcrConfiguration={fcrConfiguration}
                />
            )}
        </>
    )
}

export default CoachingReports;
