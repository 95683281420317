import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";

import RichText from "components/RichText";
import Slider from "components/Slider";
import RatingMeaning from "components/RatingMeaning";
import { FcrCoachingSkillObservation, FcrConfiguration } from "types";

import useStyles from "../styles";

interface SkillCoachedItemProps {
    skillCoached: FcrCoachingSkillObservation;
    fcrConfiguration: FcrConfiguration;
}

function SkillCoachedItem({ skillCoached: { id, fcrCoachingSkill, fcrCoachingSkillName, rating, observation }, fcrConfiguration }: SkillCoachedItemProps) {
    const classes = useStyles();
    const ratingObject = fcrCoachingSkill?.fcrCoachingSkillRatings?.find(
        fcrCoachingSkillRating => (rating >= fcrCoachingSkillRating.min) && (rating <= fcrCoachingSkillRating.max)
    );
    const ratingDescription = 
        ratingObject?.fcrCoachingSkillRatingLeadingInfos && 
        ratingObject?.fcrCoachingSkillRatingLeadingInfos[0];

    return fcrCoachingSkill ? (
        <Box pb={6} key={id}>
            <Paper className={classes.skillPaper}>
                <Box py={6} pl={5.5} pr={9}>
                    <Typography variant="h1">{fcrCoachingSkillName}</Typography>
                    <Box width="60%" pb={4}>
                        <Box pt={5} pb={fcrConfiguration.hideSkillRatingNumbers ? 5 : 9} fontSize={14} fontWeight={600} lineHeight={21}>
                            <Typography variant="h2">Overall Rating</Typography>
                        </Box>
                        <Slider
                            value={rating}
                            minRating={fcrCoachingSkill.minRating}
                            maxRating={fcrCoachingSkill.maxRating}
                            valueLabelDisplay={fcrConfiguration.hideSkillRatingNumbers ? "off" : "on"}
                            step={0.1}
                        />
                    </Box>
                    {ratingDescription && (
                        <Box mb={observation ? 5 : 0}>
                            <RatingMeaning title={ratingDescription.title} body={ratingDescription.body} />
                        </Box>
                    )}
                    {observation && (
                        <>
                            <Box fontWeight="600" pb={1.5}>
                                <Typography variant="body1">Coaching Notes</Typography>
                            </Box>
                            <Typography variant="body1" component="div" className={classes.observations}>
                                <RichText text={observation}/>
                            </Typography>
                        </>
                    )}
                </Box>
            </Paper>
        </Box>
    ) : null;
}

export default SkillCoachedItem;
