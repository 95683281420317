import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    labelWrap: {
        borderRadius: "4px",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    label: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    labelStatus: {
        paddingLeft: theme.spacing(1),
    },
}));

export default useStyles;
