import React from "react";
import clsx from "clsx";
import { Radio, FormControlLabel } from "@material-ui/core";

import useStyles from "./styles";

interface FormControlRadioButtonProps {
    label: string;
    value: string;
    checked: boolean;
    error?: boolean;
    icon?: JSX.Element | undefined;
}

/** Receive option icon as children and uses show alt icon logic to override if it's shown. For evaluation answer first usage. */
function FormControlRadioButton({ label, value, checked, error, icon }: FormControlRadioButtonProps) {
    const classes = useStyles();

    return (
        <>
            <FormControlLabel
                color="primary"
                className={clsx(classes.button, checked && classes.checkedButton, error && classes.errorButton)}
                label={label}
                value={value}
                control={
                    icon ? (
                        <Radio
                            color="primary"
                            className={clsx(classes.radio, error && classes.errorRadio)}
                            disableRipple
                            disableFocusRipple
                            checkedIcon={icon}
                            icon={icon}
                        />
                    ) : (
                        <Radio
                            color="primary"
                            className={clsx(classes.radio, error && classes.errorRadio)}
                            disableRipple
                            disableFocusRipple
                        />
                    )
                }
            />
        </>
    );
}

export default FormControlRadioButton;
