import * as React from "react";

function SvgActionable(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Icons-/-UI-/-Show" fill="currentColor">
                    <path
                        d="M8,3 C10.6666667,3 13.3333333,4.66666667 16,8 C13.3333333,11.3333333 10.6666667,13 8,13 C5.33333333,13 2.66666667,11.3333333 0,8 C2.66666667,4.66666667 5.33333333,3 8,3 Z M8,4 C5.91709834,4 3.75664342,5.22010297 1.52673344,7.73743358 L1.298,8 L1.52673344,8.26256642 C3.67405416,10.6866626 5.75696937,11.9078425 7.76824774,11.9949751 L8,12 C10.0829017,12 12.2433566,10.779897 14.4732666,8.26256642 L14.701,8 L14.4732666,7.73743358 C12.3259458,5.31333744 10.2430306,4.09215746 8.23175226,4.00502492 L8,4 Z M8,5 C9.65685425,5 11,6.34314575 11,8 C11,9.65685425 9.65685425,11 8,11 C6.34314575,11 5,9.65685425 5,8 C5,6.34314575 6.34314575,5 8,5 Z M8,6 C6.8954305,6 6,6.8954305 6,8 C6,9.1045695 6.8954305,10 8,10 C9.1045695,10 10,9.1045695 10,8 C10,6.8954305 9.1045695,6 8,6 Z"
                        id="Combined-Shape"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default SvgActionable;
