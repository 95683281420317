import React from "react";
import { Box, Paper } from "@material-ui/core";

import messages from "./messages.svg";

function Messages() {
    return (
        <Box px={7.5} pt={8}>
            <Paper>
                <Box display="flex" justifyContent="center" pt={3} pb={4}>
                    <img src={messages} alt="" />
                </Box>
            </Paper>
        </Box>
    )
}

export default Messages;
