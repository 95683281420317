import * as React from "react";

function SvgSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#success_svg__clip0)"
        strokeWidth={1.25}
        strokeMiterlimit={10}
      >
        <path d="M8.999 17.37a8.37 8.37 0 100-16.74 8.37 8.37 0 000 16.74z" />
        <path d="M12.658 6.07l-4.59 5.58-2.74-2.72" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="success_svg__clip0">
          <path fill="#fff" d="M0 0h17.99v17.99H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSuccess;
