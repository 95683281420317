import React, { useMemo } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { Box, Divider, Typography, CircularProgress } from "@material-ui/core";

import Message from "components/Message";
import PaperSection from "components/PaperSection";
import NoDataMessage from "components/NoDataMessage";
import { MessageType, TeamProfileRouteParams } from "types";
import { Routings } from "types/constants";

interface TeamProfileMessagesProps {
    messages: MessageType[];
    isRecentMessagesFetching?: boolean;
}

const TeamProfileMessages = ({ messages, isRecentMessagesFetching = false }: TeamProfileMessagesProps) => {
    const { url } = useRouteMatch();
    const { userId } = useParams<TeamProfileRouteParams>();

    const pathForRouting = useMemo(() => url === "/" ? "" : url, [url]);

    const renderMessages = () => {
        if (isRecentMessagesFetching) return (
            <Box display="flex" justifyContent="center" py={10}>
                <CircularProgress size={24} />
            </Box>
        );

        if (messages.length <= 0) return (
            <NoDataMessage message="There's nothing to report here yet." py={5} px={3} />
        );

        return (
            <Box display="flex" flexDirection="column" px={5}>
                {messages.map((message, index) => (
                    <React.Fragment key={index}>
                        <Box py={4}>
                            <Message message={message} />
                        </Box>
                        {index !== messages.length - 1 && (
                            <Divider />
                        )}
                    </React.Fragment>
                ))}
            </Box>
        );
    };

    return (
        <PaperSection title={
            <Box fontWeight={600} mr={1.5}>
                <Typography variant="h2">{userId ? "Messages" : "My Messages"}</Typography>
            </Box>
        } mb={{ xs: 3, sm: 6 }} href={(messages.length > 0) ? `${pathForRouting}${Routings.messages}` : undefined} label="View All" >
            {renderMessages()}
        </PaperSection>
    );
};

export default TeamProfileMessages;