import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, RadioGroup, Select, Typography } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RadioButton from "components/FormControlRadioButton";
import MultilineInput from "components/MultilineInput";
import RichText from "components/RichText";
import orderBy from "lodash/orderBy";
import React, { useCallback, useMemo } from "react";
import SvgCheckmark from "svgComponents/Checkmark";
import SvgCloseCross from "svgComponents/CloseCross";
import { EvaluationItemConfiguration, EvaluationItemValue } from "types/Certification";
import { ComponentTypeEvaluation, NULL_GUID } from "types/constants";
import { EvaluationItemResultSaveDto } from "types/Evaluation.dto";
import { v4 as uuidv4 } from "uuid";

import useStyles from "./styles";

interface TraineeEvaluationFormItemProps {
    showAnswers: boolean;
    disabled: boolean;
    evaluationResultId: string;
    item: EvaluationItemConfiguration;
    showError: boolean;
    evalItemResultSaveDtos: EvaluationItemResultSaveDto[];
    setEvalItemResultSaveDtos: React.Dispatch<React.SetStateAction<EvaluationItemResultSaveDto[]>>;
}

const TraineeEvaluationFormItem = ({
    evaluationResultId,
    showAnswers,
    disabled,
    showError,
    item,
    evalItemResultSaveDtos,
    setEvalItemResultSaveDtos,
}: TraineeEvaluationFormItemProps) => {
    const classes = useStyles();
    const sortedItemValues = useMemo(() => orderBy(item.evaluationItemValues, "order"), [item.evaluationItemValues]);

    const ElementHeaderWithBody = useMemo(
        () => (
            <Box pb={2} className={classes.darkBlueText}>
                <Box fontWeight="600" pb={1.5}>
                    <Typography
                        variant={item.evaluationItemTypeId === ComponentTypeEvaluation.staticText ? "h1" : "h3"}
                        color="inherit"
                        component="div">
                        <RichText text={item.elementHeader} />
                    </Typography>
                </Box>
                <Typography variant="body1" color="inherit" component="div">
                    <RichText text={item.elementBody} />
                </Typography>
            </Box>
        ),
        [classes.darkBlueText, item]
    );

    const otherResults = useMemo(
        () => evalItemResultSaveDtos?.filter(({ evaluationItemConfigurationId }) => evaluationItemConfigurationId !== item.id),
        [evalItemResultSaveDtos, item.id]
    );

    const results = useMemo(
        () => evalItemResultSaveDtos?.filter(({ evaluationItemConfigurationId }) => evaluationItemConfigurationId === item.id),
        [evalItemResultSaveDtos, item.id]
    );

    const getValue = useCallback(() => {
        if (!results?.length) {
            return "";
        }
        if (item.evaluationItemTypeId === ComponentTypeEvaluation.textEntry) {
            return results[0]?.input ?? "";
        }
        if (
            item.evaluationItemTypeId === ComponentTypeEvaluation.checkboxList ||
            item.evaluationItemTypeId === ComponentTypeEvaluation.dropdownList
        ) {
            return (results ?? []).map((i) => i.evaluationItemValueId);
        }
        const value = results[0]?.evaluationItemValueId ?? "";

        return value;
    }, [item.evaluationItemTypeId, results]);

    const value = useMemo(getValue, [getValue]);

    const handleChange = useCallback(
        (evalItemResultSaveDtoRest: Partial<EvaluationItemResultSaveDto>) => {
            const findFunc = (eir: EvaluationItemResultSaveDto) => eir.id === evalItemResultSaveDtoRest.id;
            const currentEvalItemResult = results.length > 0 ? results.find(findFunc) : evalItemResultSaveDtos?.find(findFunc);

            const updateEvalItemResult: EvaluationItemResultSaveDto = {
                id: currentEvalItemResult?.id || uuidv4(),
                evaluationItemConfigurationId: item.id,
                evaluationResultId: evaluationResultId,
                evaluationItemTypeId: item.evaluationItemTypeId,
                input: evalItemResultSaveDtoRest?.input ?? null,
                evaluationItemValueId: evalItemResultSaveDtoRest?.evaluationItemValueId || uuidv4(),
            };
            const newEvalItemResultsCollection = [...otherResults, updateEvalItemResult];
            setEvalItemResultSaveDtos(newEvalItemResultsCollection);
        },
        [item.id, evaluationResultId, setEvalItemResultSaveDtos, item.evaluationItemTypeId, otherResults, results, evalItemResultSaveDtos]
    );

    const handleChangeTextEntry = useCallback(
        (value: string) => {
            const newEvalItemResult: EvaluationItemResultSaveDto = {
                id: results[0]?.id ?? uuidv4(),
                evaluationItemConfigurationId: item.id,
                evaluationItemTypeId: ComponentTypeEvaluation.textEntry,
                evaluationItemValueId: results[0]?.evaluationItemValueId ?? uuidv4(),
                evaluationResultId: evaluationResultId,
                input: value,
            };
            setEvalItemResultSaveDtos([...otherResults, newEvalItemResult]);
        },
        [results, otherResults, item.id, evaluationResultId, setEvalItemResultSaveDtos]
    );

    const handleChangeValue = useCallback(
        ({ target }) => {
            handleChange({ evaluationItemValueId: target.value, input: null });
        },
        [handleChange]
    );

    const CorrectIcon = <SvgCheckmark className={classes.checkmarkIcon} />;

    const logicForCorrectAnswer = (itemValue: EvaluationItemValue) =>
        itemValue.value === 1 &&
        results.map((r) => r.evaluationItemConfigurationId).includes(itemValue.evaluationItemConfigurationId ?? "");

    const IncorrectIcon = <SvgCloseCross className={classes.crossIcon} />;

    const logicForIncorrectAnswer = (itemValue: EvaluationItemValue) =>
        itemValue.value === 0 &&
        results
            .filter((r) => r.evaluationItemValueId === itemValue.id)
            .map((r) => r.evaluationItemConfigurationId)
            .includes(itemValue.evaluationItemConfigurationId ?? "");

    const handleChangeMultiselectCheckbox = useCallback(
        ({ target }) => {
            if (value?.includes(target.value)) {
                const subsetOfResults = results?.filter((r) => r.evaluationItemValueId !== target.value) ?? [];
                const result = [...subsetOfResults, ...otherResults];
                // To Address the edge case of removing the only result which happens to be a checkbox, we need to tell teh parent component that this was a user initiated change so we don't reuse the server provide results
                const resultIsEmpty = result.length === 0;
                setEvalItemResultSaveDtos &&
                    setEvalItemResultSaveDtos(
                        resultIsEmpty
                            ? [
                                  {
                                      evaluationItemConfigurationId: NULL_GUID,
                                      evaluationItemTypeId: NULL_GUID,
                                      evaluationItemValueId: NULL_GUID,
                                      evaluationResultId: NULL_GUID,
                                      id: NULL_GUID,
                                      input: null,
                                  },
                              ]
                            : result
                    );
            } else {
                const newEvalItemResult: EvaluationItemResultSaveDto = {
                    id: uuidv4(),
                    evaluationItemConfigurationId: item.id,
                    evaluationItemTypeId: item.evaluationItemTypeId,
                    evaluationItemValueId: target.value,
                    evaluationResultId: evaluationResultId,
                    input: null,
                };
                setEvalItemResultSaveDtos([...otherResults, ...results, newEvalItemResult]);
            }
        },
        [evaluationResultId, setEvalItemResultSaveDtos, otherResults, results, value, item.id, item.evaluationItemTypeId]
    );

    const error = useMemo(() => {
        if (!showError || !item.isRequired) return false;
        return Array.isArray(value) ? !value.length : !value;
    }, [showError, item.isRequired, value]);

    const ErrorMessage = useMemo(
        () =>
            error && (
                <Box pt={1.5}>
                    <Typography className={classes.redText}>This field is required.</Typography>
                </Box>
            ),
        [error, classes.redText]
    );

    switch (item.evaluationItemTypeId) {
        case ComponentTypeEvaluation.textEntry:
            return (
                <Box pb={4}>
                    {ElementHeaderWithBody}
                    {showAnswers ? (
                        <RichText text={value as string} />
                    ) : (
                        <MultilineInput value={value as string} onChange={handleChangeTextEntry} error={error} disabled={disabled} />
                    )}
                    {ErrorMessage}
                </Box>
            );

        case ComponentTypeEvaluation.checkboxList:
            return (
                <Box pb={4}>
                    {ElementHeaderWithBody}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.checkbox}
                        px={3}
                        pt={2}
                        pb={2.5}
                        border={error ? 1 : 0}
                        borderColor={error ? "error.main" : ""}>
                        <FormGroup>
                            {sortedItemValues?.map((itemValue) => (
                                <React.Fragment key={itemValue.id}>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" />}
                                        checked={typeof value === "string" ? itemValue.id === value : value.includes(itemValue.id)}
                                        value={itemValue.id}
                                        onChange={handleChangeMultiselectCheckbox}
                                        disabled={disabled}
                                        label={
                                            <Typography
                                                className={showAnswers ? classes.spaceForAnswer : ""}
                                                variant="h3"
                                                color="inherit"
                                                component="div">
                                                <RichText text={`${itemValue.name}`} />
                                            </Typography>
                                        }
                                    />
                                    {showAnswers && (
                                        <div key={`${itemValue.id}-answers`} className={classes.dropDownAnswers}>
                                            {logicForCorrectAnswer(itemValue) && CorrectIcon}
                                            {logicForIncorrectAnswer(itemValue) && IncorrectIcon}
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </FormGroup>
                    </Box>
                    {ErrorMessage}
                </Box>
            );
        case ComponentTypeEvaluation.dropdownList:
            return showAnswers ? (
                <Box pb={4}>
                    {ElementHeaderWithBody}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.checkbox}
                        px={3}
                        pt={2}
                        pb={2.5}
                        border={error ? 1 : 0}
                        borderColor={error ? "error.main" : ""}>
                        <FormGroup>
                            {sortedItemValues?.map((itemValue) => (
                                <React.Fragment key={itemValue.id}>
                                    <FormControlLabel
                                        key={itemValue.id}
                                        control={<Checkbox color="primary" />}
                                        checked={typeof value === "string" ? itemValue.id === value : value.includes(itemValue.id)}
                                        value={itemValue.id}
                                        disabled={true}
                                        label={
                                            <Typography
                                                className={showAnswers ? classes.spaceForAnswer : ""}
                                                variant="h3"
                                                color="inherit"
                                                component="div">
                                                <RichText text={`${itemValue.name}`} />
                                            </Typography>
                                        }
                                    />
                                    {showAnswers && (
                                        <div key={`${itemValue.id}-answers`} className={classes.dropDownAnswers}>
                                            {logicForCorrectAnswer(itemValue) && CorrectIcon}
                                            {logicForIncorrectAnswer(itemValue) && IncorrectIcon}
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </FormGroup>
                    </Box>
                    {ErrorMessage}
                </Box>
            ) : (
                <Box pb={4}>
                    {ElementHeaderWithBody}
                    <Select
                        variant="outlined"
                        value={value}
                        onChange={handleChangeValue}
                        IconComponent={ExpandMore}
                        disabled={disabled}
                        error={error}
                        fullWidth>
                        {sortedItemValues?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {ErrorMessage}
                </Box>
            );
        case ComponentTypeEvaluation.staticText:
            return <Box pb={2}>{ElementHeaderWithBody}</Box>;
        case ComponentTypeEvaluation.radioButtonList:
            return (
                <Box>
                    {ElementHeaderWithBody}
                    <Box pb={4}>
                        <FormControl fullWidth disabled={disabled}>
                            <RadioGroup aria-label={item.id} row value={value} onChange={handleChangeValue}>
                                <Grid container spacing={3}>
                                    {sortedItemValues?.map((itemValue) => (
                                        <React.Fragment key={itemValue.id}>
                                            <Grid key={itemValue.id} item md={3} xs={6}>
                                                <RadioButton
                                                    key={itemValue.id}
                                                    label={itemValue.name}
                                                    value={itemValue.id}
                                                    checked={itemValue.id === value}
                                                    error={error}
                                                    icon={
                                                        showAnswers ? (
                                                            <div key={`${itemValue.id}-answers`} className={classes.radioButtonAnswer}>
                                                                {logicForCorrectAnswer(itemValue) && CorrectIcon}
                                                                {logicForIncorrectAnswer(itemValue) && IncorrectIcon}
                                                            </div>
                                                        ) : undefined
                                                    }
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                        {ErrorMessage}
                    </Box>
                </Box>
            );
        default:
            return <div />;
    }
};

export default TraineeEvaluationFormItem;
