import React, { useEffect } from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { UsersAutocomplete } from "components/UsersAutocomplete";
import { UserProfile } from "types";

import useStyles from "./styles";

interface CoachingSessionUserSelectProps {
    value: string | null;
    userProfiles: UserProfile[];
    loading: boolean;
    onChange: (userId: string | null) => void;
}

const CoachingSessionUserSelect = ({ value, userProfiles, loading, onChange }: CoachingSessionUserSelectProps) => {
    const classes = useStyles();

    useEffect(() => {
        if (userProfiles.length) onChange(userProfiles[0].id);
    }, [userProfiles, onChange]);

    return (
        <>
            <UsersAutocomplete
                className={classes.autocomplete}
                label="Assignee"
                noOptionsText="No matches"
                userProfiles={userProfiles}
                value={value}
                onChange={onChange}
                hasAvatar={true}
                popupIcon={<ExpandMore />}
                loading={loading}
            />
        </>
    );
}

export default CoachingSessionUserSelect;