import * as React from "react";

function GradientLow() {
    return (
        <linearGradient id="gradientLow">
            <stop stopColor="#E04F22"/>
            <stop offset="0.526042" stopColor="#EA7C1E"/>
            <stop offset="1" stopColor="#EE8718"/>
        </linearGradient>
    );
}

export default GradientLow;
